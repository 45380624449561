import gifts from '~/shared/gifts';

export default {
  id: 3,
  longMemory: true,
  name: 'Тауэрский мост',
  description: 'Разводной мост над Темзой, один из главных символов Лондона.',
  story: 'Тауэрский Мост — это разводной мост в центре Лондона над рекой Темзой, недалеко от Лондонского Тауэра. Открыт в 1894 году. Также является одним из символов Лондона и Британии. Представляет собой разводной мост длиной 244 м с двумя поставленными на промежуточные опоры башнями высотой 65 м. Центральный пролёт между башнями, длиной 61 м, разбит на два подъёмных крыла весом более тысячи тонн.',
  partingWords: 'Агент, ты совсем немного пробыл в школе, а уже столько всего достиг! Навыки развиваются по-разному. Помни, что всегда можно «подкачать» любой навык в тренировочном режиме: выполняешь упражнения – шкала заполняется. А теперь пора заработать самокат с турбодвигателем! Он поможет быстро перемещаться, оставаясь похожим на обычного школьника – ведь так легче затеряться в толпе.',
  textLosing: 'Не справился? Не беда! Попробуй потренироваться на тренажёрах и приходи снова!',
  textPartially: 'Увы, не все задания миссии были выполнены. Но ты все равно смог развить свои навыки. Попробуй потренироваться на тренажёрах и приходи снова!',
  textSuccess: 'Ура, все миссии в особняке Мистера Фоксмана выполнены! Ты делаешь большие успехи в развитии секретных навыков. В следующем задании ты сможешь показать, насколько хорошо ты их усвоил!',
  gift: gifts.scooter,
  imgs: [
    require('~/static/locations/london/tower-bridge.jpg')
  ]
};
