import React from 'react';
import { connect } from 'react-redux';

import Popup from '~/components/Popup/Popup';
import TooltipButton from '~/components/TooltipButton/TooltipButton';
import Statuses from '~/components/Statuses/Statuses';

import popupNames from '~/services/popup-names';

import { getPopupName } from '~/store/page/selector';

import * as pageActions from '~/store/page/actions';

import './profile-skills-popup.scss';

const ProfileSkillsPopup = (props) => {
  const { popupName, setPopup } = props;
  const closeHandler = () => setPopup(null);
  const isOpen = popupName === popupNames.profileSkills;
  
  return (
    <Popup isOpen={ isOpen } className="popupContent profile-skills-popup__popup-content"
           overlayClass="popupOverlay profile-skills-popup__popup-overlay">
      <div className="profile-skills-popup">
        <header className="profile-skills-popup__header">
          Статус агента
        </header>

        <div className="profile-skills-popup__desc">
          Статус – главный показатель твоего развития. Он зависит от пяти навыков, которые ты прокачиваешь на миссиях или в тренажерном зале.
        </div>

        <div className="profile-skills-popup__statuses">
          <Statuses />
        </div>

        <div className="profile-skills-popup__desc">
          Чем выше твой статус, тем больше баллов требуется для перехода на&nbsp;следующий уровень.
        </div>

        <div className="profile-skills-popup__close">
          <TooltipButton iconName='close-tooltip' onClick={ closeHandler }/>
        </div>
      </div>
    </Popup>
  );
};

function mapStateToProps(state) {
  return {
    popupName: getPopupName(state)
  };
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSkillsPopup);
