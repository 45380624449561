import React, { useState, memo } from 'react';
import { Line, Circle, Group } from 'react-konva';

import styleCircle from './styleCircle';
import * as Sizes from '../constants';
import { ROTATE_STROKE_COLOR } from '../constants/colors';

const Arrow = (props) => {
  const { rotate, setCursor, defaultPosition } = props;
  const [ isHover, setHover ] = useState(false);
  const handlerMouseOver = () => {
    setCursor('pointer');
    setHover(true);
  };
  const handlerMouseLeave = () => {
    setCursor('default');
    setHover(false);
  };

  return (
    <Group onMouseOver={ handlerMouseOver } onMouseLeave={ handlerMouseLeave }
           onTouchStart={ rotate } onClick={ rotate }>
      <Circle { ...getStyleCircle({ defaultPosition, isHover }) } />
      <Line points={ getArrowPoint(getStyleCircle({ defaultPosition, isHover })) } stroke='#505050' strokeWidth={2} />
      <Line points={ getArrowHead({ defaultPosition, isHover }) } stroke='#505050' strokeWidth={2} />
    </Group>
  )
};

const getStyleCircle = (props) => {
  const { defaultPosition, isHover } = props;
  const { x, y } = defaultPosition;
  return { ...styleCircle, x, y, stroke: isHover ? '#4C8EDB' : ROTATE_STROKE_COLOR };
};

const getArrowPoint = ({ x, y }) => {
  let result = [];

  for (let i = 50; i > -290; i -= 20) {
    result = [ ...result, 7 * Math.cos(i * Sizes.KF_RADIAN) + x, 7 * Math.sin(i * Sizes.KF_RADIAN) + y ];
  }
  return result;
};

const getArrowHead = (props) => {
  const arrow = getArrowPoint(getStyleCircle(props));
  const x = arrow[0];
  const y = arrow[1];
  return [x - 1, y - 5, x, y, x + 6, y - 2];
};

export default memo(Arrow);
