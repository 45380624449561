import React from 'react';
import cx from 'classnames';

import Eraser from '../Eraser';
import OkButton from '../OkButton';

import './words-panel.scss';

const WordsPanel = (props) => {
  const { word, onEraseHandler, hasPattern, onOkButtonClickHandler } = props;
  const hasWord = Boolean(word);
  const wordsPanelClasses = cx('words-panel', {
    'words-panel_filled': hasWord
  });

  return (
    <div className={ wordsPanelClasses }>
      { word || 'составь слово' }
      { hasWord && <Eraser onClick={ onEraseHandler }/>}
      { hasPattern && <OkButton onClick={ onOkButtonClickHandler }/> }
    </div>
  );
};

export default WordsPanel;
