export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    desktop: 20,
    tablet: 25,
    mobile: 30
  },
  outline: [
    {
      desktop: {
        x: 180.82,
        y: 57
      },
      tablet: {
        x: 249.57,
        y: 110.29
      },
      mobile: {
        x: 131.21,
        y: 78
      }
    },
    {
      desktop: {
        x: 244.5,
        y: 120.5
      },
      tablet: {
        x: 249.57,
        y: 166.28
      },
      mobile: {
        x: 173.39,
        y: 120.21
      }
    },
    {
      desktop: {
        x: 244.64,
        y: 182.82
      },
      tablet: {
        x: 305.57,
        y: 110.28
      },
      mobile: {
        x: 131.61,
        y: 120.21
      }
    },
    {
      desktop: {
        x: 306.93,
        y: 120.53
      },
      tablet: {
        x: 362.14,
        y: 110.28
      },
      mobile: {
        x: 131.61,
        y: 162.64
      }
    },
    {
      desktop: {
        x: 371.28,
        y: 119.82
      },
      tablet: {
        x: 305.57,
        y: 166.85
      },
      mobile: {
        x: 158.82,
        y: 162.64
      }
    },
    {
      desktop: {
        x: 306.93,
        y: 184.17
      },
      tablet: {
        x: 249.85,
        y: 166.85
      },
      mobile: {
        x: 158.82,
        y: 247.28
      }
    },
    {
      desktop: {
        x: 244.46,
        y: 183.46
      },
      tablet: {
        x: 249.85,
        y: 222.57
      },
      mobile: {
        x: 184.1,
        y: 222
      }
    },
    {
      desktop: {
        x: 244.46,
        y: 245.64
      },
      tablet: {
        x: 339.55,
        y: 222.55
      },
      mobile: {
        x: 218.97,
        y: 222
      }
    },
    {
      desktop: {
        x: 346.65,
        y: 245.64
      },
      tablet: {
        x: 419.85,
        y: 302.85
      },
      mobile: {
        x: 260.82,
        y: 263.85
      }
    },
    {
      desktop: {
        x: 436.96,
        y: 335.96
      },
      tablet: {
        x: 363.57,
        y: 359.14
      },
      mobile: {
        x: 303.03,
        y: 221.64
      }
    },
    {
      desktop: {
        x: 373.64,
        y: 399.28
      },
      tablet: {
        x: 307.43,
        y: 303
      },
      mobile: {
        x: 243.8,
        y: 162.43
      }
    },
    {
      desktop: {
        x: 310.36,
        y: 336
      },
      tablet: {
        x: 260.27,
        y: 303
      },
      mobile: {
        x: 174.03,
        y: 162.43
      }
    },
    {
      desktop: {
        x: 257.56,
        y: 336
      },
      tablet: {
        x: 227.57,
        y: 335.71
      },
      mobile: {
        x: 174.03,
        y: 120.64
      }
    },
    {
      desktop: {
        x: 220.64,
        y: 372.92
      },
      tablet: {
        x: 227.57,
        y: 222.85
      },
      mobile: {
        x: 215.82,
        y: 120.64
      }
    },
    {
      desktop: {
        x: 220.64,
        y: 246.46
      },
      tablet: {
        x: 193.28,
        y: 222.85
      },
      mobile: {
        x: 258.25,
        y: 78.21
      }
    },
    {
      desktop: {
        x: 180.82,
        y: 246.46
      },
      tablet: {
        x: 193.28,
        y: 166.28
      },
      mobile: {
        x: 215.82,
        y: 78.21
      }
    },
    {
      desktop: {
        x: 180.82,
        y: 182.82
      },
      tablet: {
        x: 249,
        y: 166.28
      },
      mobile: {
        x: 173.82,
        y: 120.21
      }
    },
    {
      desktop: {
        x: 244,
        y: 182.82
      },
      tablet: {
        x: 193.28,
        y: 110.58
      },
      mobile: {
        x: 173.82,
        y: 78.6
      }
    },
    {
      desktop: {
        x: 181,
        y: 119.8
      },
      tablet: {
        x: 193.28,
        y: 54
      },
      mobile: {
        x: 131.21,
        y: 36
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 257,
        y: 336,
        rotation: 90
      },
      tablet: {
        x: 260,
        y: 303,
        rotation: 90
      },
      mobile: {
        x: 183.39,
        y: 222,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 220.64,
        y: 372.92,
        rotation: 135
      },
      tablet: {
        x: 227.57,
        y: 335.71,
        rotation: 135
      },
      mobile: {
        x: 158.82,
        y: 247.28,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 373.64,
        y: 272,
        rotation: -45
      },
      tablet: {
        x: 363.57,
        y: 246,
        rotation: -45
      },
      mobile: {
        x: 260.82,
        y: 179,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 276.46,
        y: 151.64,
        rotation: -135
      },
      tablet: {
        x: 277.85,
        y: 138.57,
        rotation: -135
      },
      mobile: {
        x: 195.03,
        y: 99.43,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 149,
        y: 88.82,
        rotation: 45
      },
      tablet: {
        x: 165,
        y: 82.28,
        rotation: 45
      },
      mobile: {
        x: 110,
        y: 57.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 339.46,
        y: 88,
        rotation: -45
      },
      tablet: {
        x: 333.85,
        y: 82,
        rotation: -45
      },
      mobile: {
        x: 237.03,
        y: 57,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 149,
        y: 214.64,
        rotation: 45
      },
      tablet: {
        x: 165,
        y: 194.57,
        rotation: 45
      },
      mobile: {
        x: 110.39,
        y: 141.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
};
