import React, { useState }  from 'react';
import Icon from '~/components/Icon/Icon';
import cx from 'classnames';
import Achievement from '~/components/Achievement/Achievement';

import './profile-achievements.scss';

/* eslint-disable */
import sideForm from '~/static/icon/side-form.svg';
import star from '~/static/icon/achievements/star.svg';
import horse from '~/static/icon/achievements/horse.svg';
/* eslint-enable */

const ProfileAchievements = () => {
  const [ isOpened, setOpen ] = useState(false);

  const renderSideForms = (amount) => (
    amount.map(num => (
      <div
        className={ cx('profile-achievements__form', {
          [`profile-achievements__form_${num}`]: num
        }) }
        key={ num }
      >
        <Icon name="side-form" className="profile-achievements__form-icon" />
      </div>
    ))
  )

  const renderHeader = () => (
    <header className="profile-achievements__header">
        <div className="profile-achievements__title">
          Значки за достижения
        </div>

        <button className="profile-achievements__order">
          заказать значки
        </button>
    </header>
  )

  return (
    <div className={ cx("profile-achievements", {
      "profile-achievements_opened": isOpened
    }) }>
      <div className="profile-achievements__holder">
        { renderHeader() }

        <p className="profile-achievements__desc">
          За свои достижения ты получаешь значки. Их можно заказать и забрать на почте или в школе IQ007.
        </p>

        <ul className="profile-achievements__list">
          <li className="profile-achievements__item">
            <div className="profile-achievements__achievement">
              <Achievement
                iconName='star'
                name='Усидчивость'
              />
            </div>
          </li>

          <li className="profile-achievements__item">
            <div className="profile-achievements__achievement">
              <Achievement
                iconName='horse'
                name='Постоянство'
              />
            </div>
          </li>
        </ul>
      </div>

      <button
        onClick={ () => setOpen(!isOpened) }
        className="profile-achievements__more"
      >
        <span className="profile-achievements__more-text">
          { isOpened ? 'Скрыть' : 'Все достижения' }
        </span>

        <div className="profile-achievements__more-icon"></div>
      </button>

      { renderSideForms([ 1, 2 ]) }
    </div>
  )
}

export default ProfileAchievements;
