export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 402,
        y: 62
      },
      tablet: {
        x: 389,
        y: 59
      },
      mobile: {
        x: 279,
        y: 39
      }
    },
    {
      desktop: {
        x: 357,
        y: 107
      },
      tablet: {
        x: 349,
        y: 99
      },
      mobile: {
        x: 249,
        y: 69
      }
    },
    {
      desktop: {
        x: 402,
        y: 152
      },
      tablet: {
        x: 389,
        y: 139
      },
      mobile: {
        x: 279,
        y: 99
      }
    },
    {
      desktop: {
        x: 312.32,
        y: 241.68
      },
      tablet: {
        x: 309.28,
        y: 218.72
      },
      mobile: {
        x: 219.21,
        y: 158.79
      }
    },
    {
      desktop: {
        x: 274.82,
        y: 204.18
      },
      tablet: {
        x: 276.78,
        y: 186.22
      },
      mobile: {
        x: 194.71,
        y: 134.29
      }
    },
    {
      desktop: {
        x: 186,
        y: 293
      },
      tablet: {
        x: 198,
        y: 265
      },
      mobile: {
        x: 136,
        y: 193
      }
    },
    {
      desktop: {
        x: 140,
        y: 293
      },
      tablet: {
        x: 157,
        y: 265
      },
      mobile: {
        x: 105,
        y: 193
      }
    },
    {
      desktop: {
        x: 185.25,
        y: 338
      },
      tablet: {
        x: 197,
        y: 305
      },
      mobile: {
        x: 135,
        y: 223
      }
    },
    {
      desktop: {
        x: 229.01,
        y: 294
      },
      tablet: {
        x: 236,
        y: 266
      },
      mobile: {
        x: 164,
        y: 194
      }
    },
    {
      desktop: {
        x: 274.64,
        y: 294
      },
      tablet: {
        x: 276.57,
        y: 266
      },
      mobile: {
        x: 194.43,
        y: 194
      }
    },
    {
      desktop: {
        x: 274.64,
        y: 331.28
      },
      tablet: {
        x: 276.57,
        y: 299.14
      },
      mobile: {
        x: 194.43,
        y: 218.85
      }
    },
    {
      desktop: {
        x: 401.72,
        y: 331.28
      },
      tablet: {
        x: 388.86,
        y: 299.14
      },
      mobile: {
        x: 279.15,
        y: 218.85
      }
    },
    {
      desktop: {
        x: 447,
        y: 287
      },
      tablet: {
        x: 429,
        y: 259
      },
      mobile: {
        x: 309,
        y: 189
      }
    },
    {
      desktop: {
        x: 447,
        y: 196.75
      },
      tablet: {
        x: 429,
        y: 179
      },
      mobile: {
        x: 309,
        y: 129
      }
    },
    {
      desktop: {
        x: 402,
        y: 152
      },
      tablet: {
        x: 389,
        y: 139
      },
      mobile: {
        x: 279,
        y: 99
      }
    },
    {
      desktop: {
        x: 447,
        y: 107
      },
      tablet: {
        x: 429,
        y: 99
      },
      mobile: {
        x: 309,
        y: 69
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 401.92,
        y: 331.28,
        rotation: -135
      },
      tablet: {
        x: 389.71,
        y: 299.14,
        rotation: -135
      },
      mobile: {
        x: 279.28,
        y: 218.85,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 402,
        y: 332,
        rotation: -180
      },
      tablet: {
        x: 389,
        y: 299,
        rotation: 180
      },
      mobile: {
        x: 279,
        y: 219,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 185,
        y: 204,
        rotation: 0
      },
      tablet: {
        x: 197,
        y: 186,
        rotation: 0
      },
      mobile: {
        x: 135,
        y: 134,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 447,
        y: 242,
        rotation: 180
      },
      tablet: {
        x: 429,
        y: 219,
        rotation: 180
      },
      mobile: {
        x: 309,
        y: 159,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 140,
        y: 338,
        rotation: 90
      },
      tablet: {
        x: 157,
        y: 305,
        rotation: 90
      },
      mobile: {
        x: 105,
        y: 223,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 402,
        y: 196,
        rotation: 0
      },
      tablet: {
        x: 389,
        y: 179,
        rotation: 0
      },
      mobile: {
        x: 279,
        y: 129,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 357,
        y: 62,
        rotation: 0
      },
      tablet: {
        x: 349,
        y: 59,
        rotation: 0
      },
      mobile: {
        x: 249,
        y: 39,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
