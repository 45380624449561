export default [
  {
    base: 'авианосец',
    words: [
      'синева',
      'свинец',
      'сиена',
      'весна',
      'навес',
      'осина',
      'аванс',
      'саван',
      'сцена',
      'навис',
      'сени',
      'овес',
      'наос',
      'иена',
      'вина',
      'нива',
      'вино',
      'воин',
      'овин',
      'цвин',
      'вена',
      'сова',
      'овца',
      'вено',
      'цена',
      'аноа',
      'анис',
      'сани',
      'циан',
      'сван',
      'сено',
      'ноа',
      'сан',
      'ива',
      'ван',
      'ион',
      'сен',
      'оса',
      'вис',
      'нос',
      'сон',
      'вес',
      'сев',
      'ас',
      'ов',
      'си'
    ]
  },
  {
    base: 'желудочек',
    words: [
      'желудок',
      'кожеед',
      'жучок',
      'дележ',
      'лучок',
      'чулок',
      'лужок',
      'чело',
      'дуче',
      'желе',
      'чудо',
      'ложе',
      'едок',
      'удел',
      'укол',
      'дело',
      'дуло',
      'одул',
      'чжоу',
      'лек',
      'луо',
      'дуо',
      'дол',
      'док',
      'код',
      'кеч',
      'чек',
      'коч',
      'лук',
      'кол',
      'луч',
      'дож',
      'лед',
      'жук',
      'дек',
      'жок',
      'уд',
      'ук',
      'уж',
      'еж'
    ]
  },
  {
    base: 'АТМОСФЕРА',
    words: [
      'МЕТАФОРА',
      'МАЕСТРО',
      'МАТРОС',
      'АРОМАТ',
      'ТЕРМОС',
      'МАСТЕР',
      'СТРОФА',
      'МАТРАС',
      'ФОРМАТ',
      'СМОТР',
      'АРОМА',
      'СМЕТА',
      'АСТРА',
      'ОФЕРА',
      'СФЕРА',
      'ФОРМА',
      'ОСЕТР',
      'АРЕСТ',
      'МЕТРО',
      'ТЕРМО',
      'МЕСТО',
      'ОТАРА',
      'ФЕРМА',
      'СОФА',
      'РОСТ',
      'СОРТ',
      'ТОРС',
      'ТРОС',
      'РОСА',
      'ФОРА',
      'РОТА',
      'МОРЕ',
      'МОСТ',
      'МЕТР',
      'ФЕТР',
      'ФОРЕ',
      'МОРС',
      'СОФТ',
      'ФОРС',
      'АТОМ',
      'ФАРС',
      'ТОРФ',
      'ФОРТ',
      'ФТОР',
      'МАРС',
      'МАРТ',
      'АРФА',
      'РАСА',
      'СОМ',
      'СОР',
      'РОТ',
      'ОСА',
      'АС',
      'РЕ',
      'ОР'
    ]
  },
  {
    base: 'ЖАВОРОНОК',
    words: [
      'ВОРОНКА',
      'НОЖОВКА',
      'ВОРОНА',
      'КОРОВА',
      'КОРОНА',
      'КОРАН',
      'КРОНА',
      'НОРКА',
      'ВОЖАК',
      'ВОРОН',
      'НОЖКА',
      'ОКОВА',
      'РОЖОК',
      'ЖАНР',
      'КРАН',
      'НОРА',
      'ЖАКО',
      'КОЖА',
      'КРОН',
      'ОКНО',
      'РОЖА',
      'КОРА',
      'КОРЖ',
      'КРОВ',
      'НРАВ',
      'РОВ',
      'РАК',
      'РОК',
      'ОКО',
      'НОЖ',
      'АР',
      'ОР'
    ]
  },
  {
    base: 'ЛЕКАРСТВО',
    words: [
      'СТЕКЛОВАР',
      'СТВОРКА',
      'СВЕРТОК',
      'ВЕРСТАК',
      'СТРЕЛОК',
      'СЕКАТОР',
      'КЛАСТЕР',
      'СТАЛКЕР',
      'СТРЕЛКА',
      'СТЕКЛО',
      'ТРЕСКА',
      'СВЕРЛО',
      'ВЕКТОР',
      'КРЕСЛО',
      'РОЛЕКС',
      'ЛЕКТОР',
      'СВЕКОР',
      'КОРСЕТ',
      'КОСТЕР',
      'СЕКТОР',
      'СТРОКА',
      'ЛАТЕКС',
      'СТРЕЛА',
      'ВАЛЕТ',
      'СЕКТА',
      'СЕТКА',
      'ТЕСАК',
      'ОТВЕС',
      'ОТСЕВ',
      'СОВЕТ',
      'ОСКАЛ',
      'СТВОР',
      'ЛЕСОК',
      'КОВЕР',
      'ВЕСЛО',
      'КЛЕСТ',
      'ОТСЕК',
      'ТОСКА',
      'КВОТА',
      'СВОРА',
      'ОСЕТР',
      'АВТОР',
      'ОТВАР',
      'РВОТА',
      'ТОВАР',
      'ЛЕСКА',
      'КВЕСТ',
      'ОКРАС',
      'СТВОЛ',
      'АКТЕР',
      'КАТЕР',
      'ТЕРКА',
      'КРЕСТ',
      'ТРЕСК',
      'ОТВАЛ',
      'ОВЕС',
      'ЛЕТО',
      'ТЕЛО',
      'ОРЕЛ',
      'ОСЕЛ',
      'СЕЛО',
      'РОСТ',
      'СОРТ',
      'ТОРС',
      'ТРОС',
      'ТРЕК',
      'САЛО',
      'РОСА',
      'СКОТ',
      'СЛОТ',
      'СТОЛ',
      'СКОЛ',
      'КВАС',
      'КРОТ',
      'КОСА',
      'ЕЛКА',
      'СЕРА',
      'КРОВ',
      'ВОСК',
      'ВОЛК',
      'ЛЕС',
      'РАК',
      'АКТ',
      'СОР',
      'РОТ',
      'КОТ',
      'ОСА',
      'РЕВ',
      'ВЕС',
      'ЛЕВ',
      'АС',
      'РЕ',
      'ОР'
    ]
  },
  {
    base: 'МАГНИТОЛА',
    words: [
      'МАГНИТ',
      'ЛАМИНАТ',
      'НАГОТА',
      'МАЛИНА',
      'ГАЛИОТ',
      'МОГИЛА',
      'ЛАГМАН',
      'МАНГАЛ',
      'АНАЛОГ',
      'ГАЛАНТ',
      'НАЛОГИ',
      'МАГНИТ',
      'МАГНАТ',
      'НАЛИМ',
      'ЛИАНА',
      'НАЛОГ',
      'ТАЛОН',
      'ТАНГО',
      'АЛИОН',
      'МАНГО',
      'ГНОМ',
      'ГИМН',
      'ТМИН',
      'ИГЛА',
      'ЛИГА',
      'ТИНА',
      'ЛАМА',
      'МАТ',
      'ЛОМ',
      'МИГ',
      'ТОН'
    ]
  },
  {
    base: 'ДИАГРАММА',
    words: [
      'АРМАДА',
      'ГАММА',
      'МАГМА',
      'ГИДРА',
      'ГРИММ',
      'МАДАМ',
      'ДРАМА',
      'ГРАММ',
      'ГРАД',
      'ДАМА',
      'РАДА',
      'ИГРА',
      'ГРИМ',
      'РАМА',
      'МАМА',
      'ДРАМА',
      'ГАД',
      'МИГ',
      'МИР',
      'РИМ',
      'АД'
    ]
  },
  {
    base: 'ЛУКОМОРЬЕ',
    words: [
      'КОРОЛЬ',
      'КРОЛЬ',
      'ОРЕОЛ',
      'КОЛЕР',
      'КОЛЬЕ',
      'КУЛЕР',
      'МЕЛОК',
      'ОМУЛЬ',
      'ОРЕЛ',
      'КРЕМ',
      'РУЛЬ',
      'УКОР',
      'УРОК',
      'МОРЕ',
      'МОЛЬ',
      'КОРМ',
      'ЕЛЬ',
      'КОМ',
      'МОР',
      'ЛУК',
      'КОЛ',
      'РЕ',
      'УМ',
      'ОР'
    ]
  },
  {
    base: 'ВЕСТИБЮЛЬ',
    words: [
      'ЛЕСТЬ',
      'БИЛЕТ',
      'ВЕСТИ',
      'ВЕСТЬ',
      'ЛИТЬЕ',
      'ВИТЬЕ',
      'СТИЛЬ',
      'СЕТЬ',
      'ТЮЛЬ',
      'ЛИСТ',
      'СЛИВ',
      'СВЕТ',
      'СТЕБ',
      'БЮСТ',
      'СЛЕТ',
      'ИЮЛЬ',
      'БЕС',
      'БИТ',
      'БИС',
      'ЕЛЬ',
      'ЛЬЕ',
      'ЛЕС',
      'ЛЕТ',
      'СЕТ',
      'ЛИС',
      'СИЛ',
      'ВЕС',
      'ЛЕВ',
      'ИЛ',
      'СИ'
    ]
  },
  {
    base: 'МЫСЛИТЕЛЬ',
    words: [
      'МЫСЛЬ',
      'МЕСТЬ',
      'МЫТЬЕ',
      'МЕТИС',
      'ЛИТЬЕ',
      'СТИЛЬ',
      'МИЛЬ',
      'СЛИМ',
      'МСЬЕ',
      'СЕМЬ',
      'СЕТЬ',
      'ЛИСТ',
      'СЛЕТ',
      'ЕЛЬ',
      'ЛЬЕ',
      'ЛЕС',
      'МЕЛ',
      'ЛЕТ',
      'СЕТ',
      'МЫС',
      'ЛИС',
      'ИЛ',
      'СИ'
    ]
  },
  {
    base: 'РЕЗОНАТОР',
    words: [
      'ТЕНОР',
      'ТОНЕР',
      'ЗЕРНО',
      'ОЗЕРО',
      'РЕТРО',
      'РОТОН',
      'ЗОРРО',
      'РОТОР',
      'НАРЕЗ',
      'РОТАН',
      'ЗАТОР',
      'ЗЕРО',
      'НОТА',
      'НОРА',
      'ТЕРН',
      'РОТА',
      'ТАРО',
      'ТРОН',
      'АЗОН',
      'ЗОНА',
      'ТРОЕ',
      'ЕНОТ',
      'ОРЕО',
      'ЗОНТ',
      'ОЗОН',
      'РОЗА',
      'АЗОТ',
      'РЕНО',
      'АРТ',
      'РЕ',
      'ОР'
    ]
  },
  {
    base: 'СМОРОДИНА',
    words: [
      'ДОМИНО',
      'ДИНАМО',
      'РОДИНА',
      'АДМИН',
      'НАРОД',
      'МИНОР',
      'ДОМРА',
      'МОРДА',
      'РАДИО',
      'ДОНОР',
      'ДОНОС',
      'НОРМА',
      'РОМАН',
      'СОДА',
      'ИРОД',
      'НОРА',
      'РОСА',
      'ОДИН',
      'СИДР',
      'ОРДА',
      'МОРС',
      'ОДНА',
      'АНИС',
      'ОМАР',
      'МОДА',
      'СРАМ',
      'МИНА',
      'ДРОН',
      'СОМ',
      'СОР',
      'САД',
      'ДАР',
      'МИР',
      'ОСА',
      'НОС',
      'СОН',
      'АС',
      'ДО',
      'СИ',
      'АД',
      'ОР'
    ]
  },
  {
    base: 'АНТРЕСОЛЬ',
    words: [
      'ОТРАСЛЬ',
      'НАСТРЕЛ',
      'СЕНЬОРА',
      'СЕНАТОР',
      'ЛЕНОСТЬ',
      'РЕЛЬСА',
      'СЕНЬОР',
      'САЛЬТО',
      'СТАРЬЕ',
      'СТРЕЛА',
      'ТРАНС',
      'ТЕНОР',
      'ТОНЕР',
      'ТРОЛЬ',
      'ТАЛОН',
      'САЛОН',
      'СЕНАТ',
      'СТЕНА',
      'ОСЕНЬ',
      'ЛЕСТЬ',
      'АРЕСТ',
      'ЛЕНТА',
      'НАЛЕТ',
      'ОЛЕНЬ',
      'ТРЕЛЬ',
      'ЛЕТО',
      'ОРЕЛ',
      'ОСЕЛ',
      'СЕЛО',
      'СТОН',
      'ЛОСЬ',
      'СОЛЬ',
      'РОСТ',
      'СОРТ',
      'ТОРС',
      'ТРОС',
      'СЕТЬ',
      'САЛО',
      'НОРА',
      'ЛЕНЬ',
      'РОТА',
      'ТОРН',
      'ТРОН',
      'СЛОТ',
      'СТОЛ',
      'НОЛЬ',
      'СЛОН',
      'ЛАНЬ',
      'ЕНОТ',
      'РОЛЬ',
      'СЕНО',
      'СЛЕТ',
      'ТЕНЬ',
      'ЕЛЬ',
      'ЛЬЕ',
      'ЛЕС',
      'СЕТ',
      'СТО',
      'ОСЬ',
      'СОР',
      'РОТ',
      'ОСА',
      'НОС',
      'СОН',
      'АС',
      'РЕ',
      'ОР'
    ]
  },
  {
    base: 'КАРТОФЕЛЬ',
    words: [
      'КАРТЕЛЬ',
      'ЛЕКАРЬ',
      'ОФЕРТА',
      'ФАКТОР',
      'ЛЕКТОР',
      'ФОРЕЛЬ',
      'ТОКАРЬ',
      'КАФЕЛЬ',
      'КРОЛЬ',
      'ТРОЛЬ',
      'ОРАКЛ',
      'КОЛЕР',
      'КОЛЬЕ',
      'КОФТА',
      'ЛАРЕК',
      'ФАКЕЛ',
      'АКТЕР',
      'АРТЕК',
      'КАТЕР',
      'ТЕРКА',
      'ТРЕЛЬ',
      'ЛЕТО',
      'ТЕЛО',
      'ОРЕЛ',
      'ТРЕК',
      'ФЛОТ',
      'РОТА',
      'ФАКТ',
      'КОФЕ',
      'ОТЕК',
      'ТОЛК',
      'КРОТ',
      'КОРЬ',
      'ФЕТР',
      'ТОФА',
      'КОФА',
      'КОРА',
      'ЕЛКА',
      'КАРЕ',
      'КАФЕ',
      'КОЛА',
      'ЛЕРА',
      'ТОРФ',
      'ФОРТ',
      'ФТОР',
      'РОЛЬ',
      'КЕТА',
      'ФРАК',
      'ЛОР',
      'ЕЛЬ',
      'ЛЬЕ',
      'АРТ',
      'АКР',
      'РАК',
      'АКТ',
      'КАЛ',
      'ЛАК',
      'РОТ',
      'КОТ',
      'ТОК',
      'РОК',
      'КОЛ',
      'ФА',
      'ОР'
    ]
  },
  {
    base: 'ТРАНСПОРТ',
    words: [
      'НАРОСТ',
      'РАПОРТ',
      'СТРОПА',
      'ПАТРОН',
      'ТРАНС',
      'НАПОР',
      'ТРОПА',
      'СТОПА',
      'СТАРТ',
      'ТРАСТ',
      'СПОРТ',
      'СТОН',
      'РОСТ',
      'СОРТ',
      'ТОРС',
      'ТРОС',
      'ОСПА',
      'НОТА',
      'НОРА',
      'РОСА',
      'ПОРТ',
      'РОТА',
      'НОРТ',
      'ТРОН',
      'ПОСТ',
      'СТОП',
      'ТРАП',
      'ТОСТ',
      'НАСТ',
      'СТАН',
      'ТОРТ',
      'СПОР',
      'ПОРА',
      'АРТ',
      'СТО',
      'СОР',
      'РОТ',
      'ОСА',
      'НОС',
      'СОН',
      'АС',
      'ОР'
    ]
  },
  {
    base: 'ЛЕСОПИЛКА',
    words: [
      'СОПЕЛКА',
      'ОПИЛКА',
      'ПОИЛКА',
      'КЛИПСА',
      'СЛЕПОК',
      'ПЛЕСК',
      'СКЛЕП',
      'ПЕСИК',
      'СОПЛИ',
      'ЛЕСОК',
      'ОСЛИК',
      'КАПЛИ',
      'ПИЛКА',
      'ПЕКЛО',
      'ПЕСОК',
      'ЛЕСКА',
      'ПОЛКА',
      'ЛЕПКА',
      'СОПКА',
      'ПОЛЕ',
      'ОСЕЛ',
      'СЕЛО',
      'ОСПА',
      'ЛИСА',
      'СИЛА',
      'ЛИПА',
      'ОПАЛ',
      'САЛО',
      'КЛИП',
      'СКОЛ',
      'КЛОП',
      'ПОЛК',
      'КОСА',
      'ЕЛКА',
      'КОЛА',
      'ПИСК',
      'ИКС',
      'ПОЛ',
      'ЛЕС',
      'КАЛ',
      'ЛАК',
      'СОК',
      'ОСА',
      'ЛИС',
      'АС',
      'СИ'
    ]
  }
];
