import React from 'react';
import cx from 'classnames'

import './levelScale.scss'

const LevelScale = (props) => {
  const { level, points, maxPoints, isInProfile, isShort } = props;
  const scaleStyle = {
    width: `${ points / maxPoints * 100 }%`
  }

  const scaleClass = cx('level-scale', {
    'level-scale_profile': isInProfile
  })

  return (
    <div className={ scaleClass }>
      {
        !isInProfile &&
        <div className="level-scale__line">
          <div className="level-scale__level">
            { level } { isShort ? 'ур.' : 'уровень' }
          </div>

          <div className="level-scale__points">
            <span className="level-scale__points-value">
              { points }
            </span>

            <span className="level-scale__points-all">
              &nbsp;/&nbsp;{ maxPoints } баллов
            </span>
          </div>
        </div>
      }

      <div className="level-scale__scale">
        <div style={ scaleStyle } className="level-scale__scale-inner"></div>
      </div>
    </div>
  );
}

export default LevelScale
