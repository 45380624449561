import React from 'react';
import Page from '~/containers/Page/Page';
import Hero from '~/components/Hero/Hero';
import OpenMap from '~/components/OpenMap/OpenMap'
import TrainSkills from '~/components/TrainSkills/TrainSkills';

const MainPage = () => {
  return (
    <Page>
      <div className="main-page">
        <Hero />
        <OpenMap />
        <TrainSkills />
      </div>
    </Page>
  );
}

export default MainPage
