export default {
  shapes: [ 1, 6, 8, 5, 7, 3, 4 ],
  offset: {
    desktop: 30,
    tablet: 35,
    mobile: 40
  },
  outline: [
    {
      desktop: { x: 357.82, y: 49.82 },
      tablet: { x: 350.28, y: 44.28 },
      mobile: { x: 254.21, y: 30.21 }
    },
    {
      desktop: { x: 357.82, y: 113.18 },
      tablet: { x: 350.28, y: 99.5 },
      mobile: { x: 254.21, y: 71.79 }
    },
    {
      desktop: { x: 307.18, y: 62.54 },
      tablet: { x: 305.57, y: 54.79 },
      mobile: { x: 212.43, y: 30 }
    },
    {
      desktop: { x: 307.18, y: 189.82 },
      tablet: { x: 305.57, y: 167.43 },
      mobile: { x: 212.43, y: 114.74 }
    },
    {
      desktop: { x: 263, y: 234 },
      tablet: { x: 266, y: 207 },
      mobile: { x: 184, y: 144 }
    },
    {
      desktop: { x: 263, y: 323.28 },
      tablet: { x: 266, y: 286.14 },
      mobile: { x: 184, y: 202.85 }
    },
    {
      desktop: { x: 227.46, y: 358.82 },
      tablet: { x: 233.85, y: 318.28 },
      mobile: { x: 159.64, y: 227.21 }
    },
    {
      desktop: { x: 164.64, y: 359 },
      tablet: { x: 178.28, y: 318.28 },
      mobile: { x: 118.21, y: 227.21 }
    },
    {
      desktop: { x: 164.82, y: 422.46 },
      tablet: { x: 178.28, y: 374.85 },
      mobile: { x: 118.21, y: 269.64 }
    },
    {
      desktop: { x: 227, y: 360.28 },
      tablet: { x: 234, y: 319.14 },
      mobile: { x: 160, y: 227.85 }
    },
    {
      desktop: { x: 352.82, y: 360.28 },
      tablet: { x: 345.28, y: 319.14 },
      mobile: { x: 243.21, y: 227.85 }
    },
    {
      desktop: { x: 353, y: 422.82 },
      tablet: { x: 345.28, y: 374.57 },
      mobile: { x: 243.21, y: 269.43 }
    },
    {
      desktop: { x: 416.46, y: 422.64 },
      tablet: { x: 401.85, y: 374.57 },
      mobile: { x: 285.64, y: 269.43 }
    },
    {
      desktop: { x: 353.28, y: 359.46 },
      tablet: { x: 346.14, y: 318.85 },
      mobile: { x: 243.85, y: 227.64 }
    },
    {
      desktop: { x: 353.28, y: 233.72 },
      tablet: { x: 346.14, y: 206.86 },
      mobile: { x: 243.85, y: 144.15 }
    },
    {
      desktop: { x: 397.18, y: 189.82 },
      tablet: { x: 385.08, y: 167.92 },
      mobile: { x: 273.15, y: 114.85 }
    },
    {
      desktop: { x: 434.46, y: 189.82 },
      tablet: { x: 418.71, y: 167.92 },
      mobile: { x: 297.28, y: 114.85 }
    },
    {
      desktop: { x: 357.82, y: 113.18 },
      tablet: { x: 351.63, y: 100.85 },
      mobile: { x: 255.07, y: 72.64 }
    },
    {
      desktop: { x: 421.46, y: 113.46 },
      tablet: { x: 406.85, y: 100.85 },
      mobile: { x: 296.64, y: 72.64 }
    },
    {
      desktop: { x: 421.46, y: 49.82 },
      tablet: { x: 406.85, y: 44.28 },
      mobile: { x: 296.64, y: 30.21 }
    }
  ],
  outlines: [
    {
      desktop: { x: 326, y: 81.64 },
      tablet: { x: 322, y: 70.57 },
      mobile: { x: 233, y: 51.43 },
      rotation: 315
    },
    {
      desktop: { x: 434.46, y: 189.82 },
      tablet: { x: 418.71, y: 165.92 },
      mobile: { x: 297.28, y: 114.85 },
      rotation: 135
    },
    {
      desktop: { x: 263, y: 234 },
      tablet: { x: 266, y: 205 },
      mobile: { x: 184, y: 144 },
      rotation: 270
    },
    {
      desktop: { x: 353, y: 324 },
      tablet: { x: 346, y: 285 },
      mobile: { x: 244, y: 204 },
      rotation: 180
    },
    {
      desktop: { x: 353.28, y: 233 },
      tablet: { x: 346.14, y: 204 },
      mobile: { x: 243.85, y: 143 },
      rotation: 45
    },
    {
      desktop: { x: 196.64, y: 327 },
      tablet: { x: 206.57, y: 288 },
      mobile: { x: 139.43, y: 206 },
      rotation: 45
    },
    {
      desktop: { x: 321, y: 390.82 },
      tablet: { x: 317, y: 344.28 },
      mobile: { x: 222, y: 248.21 },
      rotation: 315
    }
  ]
};
