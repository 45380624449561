import getRandomIndex from '~/services/get-random-index';

const getFullArray = (arr, obj) => arr.reduce((acc, item) => [ ...acc, obj[item] ], []);

export default ({ figures }, baseShapes) => {
  const figure = figures[getRandomIndex(figures)];
  const shapes = getFullArray(figure.shapes, baseShapes);

  return { ...figure, shapes };
};
