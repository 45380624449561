import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

const templateLevels = {
  1: {
    seconds: 10,
    taskParams: { timer: [10 * 1000], picturesAmount: 1 },
    picturesAmount: 5
  },
  2: {
    seconds: 10,
    taskParams: { timer: [25 * 1000], picturesAmount: 3 },
    picturesAmount: 10
  },
  3: {
    seconds: 20,
    taskParams: { timer: [30 * 1000], picturesAmount: 5 },
    picturesAmount: 15
  },
  4: {
    seconds: 20,
    taskParams: { timer: [30 * 1000], picturesAmount: 6 },
    picturesAmount: 18
  }
};

export default getLevels(templateLevels, getLevelParams);
