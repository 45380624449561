export default (trainers, trainerIds) => {
  const usedTrainersId = Object.keys(trainers).filter(item => trainerIds.includes(Number(item)));
  let maxSkillsMission = {};

  usedTrainersId.forEach(item => {
    const { maxSkills } = trainers[item].params;

    Object.keys(maxSkills).forEach(item => {
      if (maxSkillsMission[item]) {
        maxSkillsMission[item] += maxSkills[item];
      }
      else {
        maxSkillsMission[item] = maxSkills[item];
      }
    });
  });

  return maxSkillsMission;
};
