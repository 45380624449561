export default {
  image: require('~/static/trainers/funnyPoints/backpack.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -86,  y: 185 },
    { id: 2,  x: -35,  y: 131 },
    { id: 3,  x: 29,   y: 118 },
    { id: 4,  x: 84,   y: 154 },
    { id: 5,  x: 95,   y: 215 },
    { id: 6,  x: 123,  y: 249 },
    { id: 7,  x: 234,  y: 354 },
    { id: 8,  x: 277,  y: 472 },
    { id: 9,  x: 296,  y: 580 },
    { id: 10, x: 295,  y: 678 },
    { id: 11, x: 178,  y: 700 },
    { id: 12, x: 144,  y: 737 },
    { id: 13, x: 33,   y: 758 },
    { id: 14, x: -108, y: 758 },
    { id: 15, x: -212, y: 725 },
    { id: 16, x: -245, y: 688 },
    { id: 17, x: -264, y: 513 },
    { id: 18, x: -236, y: 345 },
    { id: 19, x: -193, y: 281 },
    { id: 20, x: -149, y: 223 }
  ]
};
