import gifts from '~/shared/gifts';

export default {
  id: 8,
  name: 'Запретный город',
  longMemory: true,
  description: 'Запретный город в Пекине является крупнейшим и самым таинственным дворцовым комплексом мира.',
  partingWords: 'Мистер Фоксман очень обрадовался твоим успехам и принял решение по завершении данной миссии отдать в твоё распоряжение уникальное кольцо спецагента. Оно всегда будет при тебе и поможет в более сложных миссиях!',
  story: `Запретный город более 500 лет служил домом 24 императорам Китая. Свое название город получил в связи с тем, что в его пределы не пускали простых людей.
          Информация для спецагента:
          1. Королевский комплекс состоит из более чем 800 зданий с 9 999 комнатами.
          2. Все здания выполнены из окрашенного дерева. Чтобы справиться с риском пожара, гигантские котлы, наполненные водой, были размещены по всему дворцу.
          3. Запретный город был отрезан от остального города рвами и пурпурно-красными стенами.`,
  textLosing: 'Миссия не пройдена! Тренажёрный зал всегда помогал всем спецагентам развивать необходимые навыки. Потренируйся и возвращайся для завершения данной миссии.',
  textPartially: 'Увы, не все задания были выполнены, но твои навыки развиваются! Потренируйся в тренажёрном зале, чтобы завершить миссию и получить уникальное кольцо спецагента.',
  textSuccess: 'Отличный результат! Миссия выполнена. Мистер Фоксман очень рад твоему успеху. Уникальное кольцо спецагента уже ждёт тебя в твоём наборе агента!',
  gift: gifts.ring,
  imgs: [
    require('~/static/locations/pekin/forbidden-city_980x590.jpg'),
  ]
};
