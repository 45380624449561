import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

import patterns from './patterns';

const templateLevels = {
  1: { timer: 360 },
  2: { timer: 330 },
  3: { timer: 300 },
  4: { timer: 270 },
  5: { timer: 240 },
  6: { timer: 210 },
  7: { timer: 180 },
  8: { timer: 150 },
  9: { timer: 120 },
  10: { timer: 90 }
};

export default getLevels(templateLevels, getLevelParams, { name: 'patterns', data: patterns });
