import tenLettersWords from './basePatterns/tenLettersWords';
import nineLettersWords from './basePatterns/nineLettersWords';
import eightLettersWords from './basePatterns/eightLettersWords';

export default {
  1: tenLettersWords,
  2: tenLettersWords,
  3: tenLettersWords,
  4: nineLettersWords,
  5: nineLettersWords,
  6: nineLettersWords,
  7: eightLettersWords,
  8: eightLettersWords,
  9: eightLettersWords,
  10: eightLettersWords
};
