import texts from './texts';
import levels from './levels';
import places from './places';
import levelsInMission from './levelsInMission';

export default {
  id: 10,
  type: 'longMemory',
  isLongMemory: true,
  params: {
    ...texts,
    name: 'Долговременная память',
    hasTimer: true,
    doNotShowDescription: true,
    doNotShowVideo: true,
    startsImmediately: true,
    maxSkills: {
      focus: 1,
      memory: 2
    },
    skills: [ 'focus', 'memory' ],
    mainSkill: 'memory',
    levels,
    places,
    levelsInMission
  }
}
