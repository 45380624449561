import React from 'react';
import CanvasDraw from 'react-canvas-draw';

import './remember-step.scss';

const RememberStep = (props) => {
  const { correctCardsOrder, matrixImages, widthWorkspace, heightWorkspace } = props;
  const renderCard = (item, itemIndex) => (
    <div className='remember-step__card' key={ itemIndex }>
      <div className="remember-step__sizer" />
      <img className='remember-step__icon' src={ matrixImages[item - 1] } alt={ item } />
    </div>
  );

  return (
    <div className="remember-step">
      {
        correctCardsOrder.map((cardsRow, index) => (
          <div className="remember-step__cards-row" key={ index }>
            { cardsRow.map((item, itemIndex) => renderCard(item, itemIndex)) }
          </div>
        ))
      }
      <div className="remember-step__pregame-draw">
        <CanvasDraw hideGrid={ true } canvasWidth={ widthWorkspace } backgroundColor="transparent"
                    canvasHeight={ heightWorkspace } brushColor="#17348B"/>
      </div>
    </div>
  );
};

export default RememberStep;
