import React from 'react';
import cx from 'classnames';

import './trainerResultBox.scss';

const TrainerResultBox = ({ title, children, isSmall }) => {
  return (
    <div className={ cx('trainer-result-box', {
      'trainer-result-box_small': isSmall,
      'trainer-result-box_big': !isSmall
    }) }>
      <div className="trainer-result-box__inner">
        <div className="trainer-result-box__head">
          <div className="trainer-result-box__title">{ title }</div>
        </div>
        <div className="trainer-result-box__body">
          { children }
        </div>
      </div>
    </div>
  )
}

export default TrainerResultBox;
