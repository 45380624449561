import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './missionStatus.scss';

const MissionStatus = ({ status, currentTrainerIndex }) => {
  const currentTaskNumber = currentTrainerIndex + 1;
  const from = (status && status.length) || 0;

  return (
    <div className="mission-status">
      <div className="mission-status__label">
        Задание {`${ currentTaskNumber <= from ? currentTaskNumber : from } из ${ from }`}
      </div>
      <div className="mission-status__segments">
        {
          status && status.map((item, index) => (
            <span
              className={classNames('mission-status__segment', {
                [`mission-status__segment_${item}`]: item,
                'mission-status__segment_current': index + 1 === currentTaskNumber
              })}
              key={index}
            />
          ))
        }
      </div>
    </div>
  );
}

MissionStatus.propTypes = {
  missionStatus: PropTypes.arrayOf(PropTypes.string)
}

export default MissionStatus;
