export const TABS = [
  {
    title: 'Твой ответ',
    id: 'answer'
  },
  {
    title: 'Правильный ответ',
    id: 'correctAnswer'
  }
];
