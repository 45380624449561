import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import TrainerCardSkill from '~/components/TrainerCardSkill/TrainerCardSkill';
import TooltipButton from '~/components/TooltipButton/TooltipButton';
import Icon from '~/components/Icon/Icon';

import skillConfig from '~/shared/skillConfig';

import './trainerCardFront.scss';

/* eslint-disable */
import playGame from './assets/play-game.svg';
/* eslint-enable */

const TrainerCardFront = (props) => {
  const { title, skillPoints, id, hovered, previewUrl, switchTooltip, train, isMobile, eventCategory } = props;
  const cardClasses = cx("trainer-card-front", {
    "trainer-card-front_hovered": hovered || isMobile,
    "trainer-card-front_train": train
  });
  const styleCard = {
    background: `linear-gradient(180deg, rgba(245, 249, 255, 0.26) 0%,
                 #D8E3FF 93.37%), url(${ previewUrl }) top center/contain`
  };
  const handleClick = () => {
    if (train) {
      window.dataLayer.push({ event: 'click', eventCategory, eventAction: 'play', eventLabel: 'main' });
    }
  };

  const renderSkill = (skill, index) => (
    <TrainerCardSkill
      hovered={ hovered || isMobile }
      key={ index }
      skill={ skill }
      points={ `+${ skillPoints[skill] || 1 }` }
      name={ skillConfig.names[skill] }
    />
  );

  const renderTooltipButton = (iconName) => (
    <div className="trainer-card-front__button">
      <TooltipButton
        onClick={ switchTooltip }
        iconName={ iconName }
      />
    </div>
  );

  return (
    <Fragment>
      <div className={ cardClasses }>
        <header className="trainer-card-front__header">
          <h2 className="trainer-card-front__title">
            { title }
          </h2>
          { renderTooltipButton("show-tooltip") }
        </header>

        <div className="trainer-card-front__points">
          { Object.keys(skillPoints).map((skill, index) => renderSkill(skill, index)) }
        </div>

        <div className="trainer-card-front__image" style={ styleCard } />

        <Link to={ `/trainers/${id}/` } className="trainer-card-front__start" onClick={ handleClick }>
          <Icon name="play-game" className="trainer-card-front__icon" />
        </Link>
      </div>
    </Fragment>
  );
}

export default TrainerCardFront;
