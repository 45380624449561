import locations from './data/locations';
import trainers from './data/trainers';
import FetchService from '~/utils/fetchService';

import getQueryVariable from '~/services/get-query-variable';

const token = getQueryVariable('token');

const fetchInstance = new FetchService({
  baseURL: process.env.REACT_APP_EXPRESS_URL
});

const makeRequest = (url, method, data) => {
  const localMethod = method || 'get';
  const payload = {
    method: localMethod
  }
  if (data) {
    payload.data = data
    return fetchInstance.request(`${ url }/?token=${ token }`, payload);
  }

  return fetchInstance.request(url, payload);
}

const apiClient = {
  getLocations: () => Promise.resolve(locations),
  getTrainers: (offset = 0, limit = 100) => {
    let count = {};
    trainers.forEach(trainer => {
      if (trainer.type !== 'longMemory') {
        let { skills } = trainer.params;
        skills.forEach(item => {
          if (count[item]) {
            count[item]++
          } else {
            count[item] = 1
          }
        })
      }
    })

    return Promise.resolve({
      list: trainers.slice(offset, limit + offset),
      allCount: trainers.length,
      count
    })
  },
  fetchProfile: (token) => makeRequest(`/profile/?token=${ token }`),
  saveMissionParams: (data) => makeRequest('/saveMissionParams', 'post', data),
  saveTrainerProgress: (data) => makeRequest('/saveTrainerProgress', 'post', data),
  saveMissionProgress: (data) => makeRequest('/saveMissionProgress', 'post', data),
  generateTokens: ({ count, secret }) => makeRequest(`/admin/generateTokens/?count=${ count }&secret=${ secret }`)
};

export default apiClient;
