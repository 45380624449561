const miniTriangle = {
  desktop: [0, 45, 45, 90, 45, 0],
  tablet: [0, 40, 40, 80, 40, 0],
  mobile: [0, 30, 30, 60, 30, 0],
  variants: 1
};

const bigTriangle = {
  desktop: [0, 0, 0, 180, 90, 90],
  tablet: [0, 0, 0, 160, 80, 80],
  mobile: [0, 0, 0, 120, 60, 60],
  variants: 1
};

export default {
    1: {
      id: 1,
      desktop: [0, 45, 45, 0, 90, 45, 45, 90],
      tablet: [0, 40, 40, 0, 80, 40, 40, 80],
      mobile: [0, 30, 30, 0, 60, 30, 30, 60],
      fill: '#FFFC00',
      variants: 4
    },
    2: {
      id: 2,
      desktop: [0, 45, 45, 0, 45, 90, 0, 135],
      tablet: [0, 40, 40, 0, 40, 80, 0, 120],
      mobile: [0, 30, 30, 0, 30, 60, 0, 90],
      fill: '#6DE9FF',
      variants: 2
    },
    3: {
      ...miniTriangle,
      id: 3,
      fill: '#FFB400',
      reference: 4
    },
    4: {
      ...miniTriangle,
      id: 4,
      fill: '#7B5FC5',
      reference: 3
    },
    5: {
      id: 5,
      desktop: [0, 90, 90, 90, 90, 0],
      tablet: [0, 80, 80, 80, 80, 0],
      mobile: [0, 60, 60, 60, 60, 0],
      fill: '#FF4115',
      variants: 1
    },
    6: {
      ...bigTriangle,
      id: 6,
      fill: '#007DDD',
      reference: 7
    },
    7: {
      ...bigTriangle,
      id: 7,
      fill: '#91ED2F',
      reference: 6
    },
    8: {
      id: 8,
      desktop: [0, 0, 45, 45, 45, 135, 0, 90],
      tablet: [0, 0, 40, 40, 40, 120, 0, 80],
      mobile: [0, 0, 30, 30, 30, 90, 0, 60],
      fill: '#6DE9FF',
      variants: 2
    }
}
