export default {
  'imagination': {
    id: 1,
    name: 'Воображение',
    max: 7,
    value: 0
  },
  'focus': {
    id: 2,
    name: 'Внимание',
    max: 12,
    value: 0
  },
  'logic': {
    id: 3,
    name: 'Логика',
    max: 7,
    value: 0
  },
  'memory': {
    id: 4,
    name: 'Память',
    max: 15,
    value: 0
  },
  'mind': {
    id: 5,
    name: 'Мышление',
    max: 12,
    value: 0
  }
}
