import React from 'react';
import propTypes from './prop-types';

import getRandomIndex from '~/services/get-random-index';

import TrainerScreen from '~/components/TrainerScreen/TrainerScreen';

const TrainerLosing = (props) => {
  const { trainer, buttonText, onButtonClick, exitButton, mission } = props;
  const { params } = trainer;
  const { name, texts, textsInMission } = params;
  const isMission = Boolean(mission);
  const currentTexts = isMission ? textsInMission.losing : texts.losing;
  const content = currentTexts[getRandomIndex(currentTexts)];
  const title = 'Чуть-чуть не хватило!';

  return (
    <TrainerScreen label={ name } title={ title } content={ content } buttonOnClick={ onButtonClick }
                   buttonText={ buttonText } buttonClassName='button_size-m' exitButtonText={ exitButton.text }
                   isMission={ isMission } exitButtonOnClick={ exitButton.handler } />
  );
};

TrainerLosing.propTypes = propTypes;

TrainerLosing.defaultProps = {
  exitButton: null,
};

export default TrainerLosing;
