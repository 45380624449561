export default {
  shapes: [4, 6, 1, 5, 3, 2, 7],
  offset: {
    desktop: 50,
    tablet: 50,
    mobile: 40
  },
  outlines: [
    {
      id: 4,
      rotation: 315,
      desktop: {
        x: 242,
        y: 397.82
      },
      tablet: {
        x: 237,
        y: 360.28
      },
      mobile: {
        x: 176,
        y: 264.21
      }
    },
    {
      id: 6,
      rotation: 135,
      desktop: {
        x: 400.92,
        y: 240.28
      },
      tablet: {
        x: 378.71,
        y: 221.14
      },
      mobile: {
        x: 282.28,
        y: 160.85
      }
    },
    {
      id: 1,
      rotation: 45,
      desktop: {
        x: 305.64,
        y: 271
      },
      tablet: {
        x: 293.57,
        y: 248
      },
      mobile: {
        x: 218.43,
        y: 180
      }
    },
    {
      id: 5,
      rotation: 180,
      desktop: {
        x: 339,
        y: 329
      },
      tablet: {
        x: 324,
        y: 300
      },
      mobile: {
        x: 241,
        y: 220
      }
    },
    {
      id: 3,
      rotation: 225,
      desktop: {
        x: 304.82,
        y: 148.46
      },
      tablet: {
        x: 294.28,
        y: 136.85
      },
      mobile: {
        x: 218.21,
        y: 97.64
      }
    },
    {
      id: 2,
      rotation: 225,
      desktop: {
        x: 305.45,
        y: 335.73
      },
      tablet: {
        x: 293.8,
        y: 305.4
      },
      mobile: {
        x: 218.21,
        y: 223.85
      }
    },
    {
      id: 7,
      rotation: 225,
      desktop: {
        x: 235.64,
        y: 154.92
      },
      tablet: {
        x: 236.57,
        y: 138.71
      },
      mobile: {
        x: 173.43,
        y: 101.28
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 235.64,
        y: 27.64
      },
      tablet: {
        x: 236.57,
        y: 138.71
      },
      mobile: {
        x: 258.28,
        y: 16.43
      }
    },
    {
      desktop: {
        x: 362.92,
        y: 27.64
      },
      tablet: {
        x: 236.57,
        y: 25.57
      },
      mobile: {
        x: 173.43,
        y: 16.43
      }
    },
    {
      desktop: {
        x: 336.64,
        y: 53.92
      },
      tablet: {
        x: 349.71,
        y: 25.57
      },
      mobile: {
        x: 173.43,
        y: 101.28
      }
    },
    {
      desktop: {
        x: 336.82,
        y: 116.46
      },
      tablet: {
        x: 322.57,
        y: 52.71
      },
      mobile: {
        x: 197.43,
        y: 77.28
      }
    },
    {
      desktop: {
        x: 277.27,
        y: 116.63
      },
      tablet: {
        x: 322.57,
        y: 108.57
      },
      mobile: {
        x: 197.43,
        y: 160
      }
    },
    {
      desktop: {
        x: 400.92,
        y: 240.27999999999997
      },
      tablet: {
        x: 266.13,
        y: 108.57
      },
      mobile: {
        x: 181,
        y: 160
      }
    },
    {
      desktop: {
        x: 337.46,
        y: 303.71999999999997
      },
      tablet: {
        x: 378.39,
        y: 220.82
      },
      mobile: {
        x: 181,
        y: 220
      }
    },
    {
      desktop: {
        x: 337.46,
        y: 366.46
      },
      tablet: {
        x: 321.85,
        y: 277.35
      },
      mobile: {
        x: 197.22,
        y: 203.79000000000002
      }
    },
    {
      desktop: {
        x: 274.27,
        y: 366.46
      },
      tablet: {
        x: 321.85,
        y: 332.84999999999997
      },
      mobile: {
        x: 197.22,
        y: 285.43
      }
    },
    {
      desktop: {
        x: 337.46,
        y: 429.64
      },
      tablet: {
        x: 266.14,
        y: 332.84999999999997
      },
      mobile: {
        x: 239.64,
        y: 285.43
      }
    },
    {
      desktop: {
        x: 274,
        y: 429.82
      },
      tablet: {
        x: 321.85,
        y: 388.57
      },
      mobile: {
        x: 197.86,
        y: 243.64000000000001
      }
    },
    {
      desktop: {
        x: 273.82,
        y: 304.18
      },
      tablet: {
        x: 265.28999999999996,
        y: 388.57
      },
      mobile: {
        x: 239.64,
        y: 243.64000000000001
      }
    },
    {
      desktop: {
        x: 249,
        y: 329
      },
      tablet: {
        x: 265.28999999999996,
        y: 278.72
      },
      mobile: {
        x: 239.64,
        y: 202.43
      }
    },
    {
      desktop: {
        x: 249,
        y: 239
      },
      tablet: {
        x: 244,
        y: 300
      },
      mobile: {
        x: 281.75,
        y: 160.32
      }
    },
    {
      desktop: {
        x: 273.64,
        y: 239
      },
      tablet: {
        x: 244,
        y: 220
      },
      mobile: {
        x: 197.87,
        y: 76.43
      }
    },
    {
      desktop: {
        x: 273.64,
        y: 116.92
      },
      tablet: {
        x: 265.57,
        y: 220
      },
      mobile: {
        x: 239.43,
        y: 76.43
      }
    },
    {
      desktop: {
        x: 235.64,
        y: 154.92000000000002
      },
      tablet: {
        x: 265.57,
        y: 109.71000000000001
      },
      mobile: {
        x: 239.43,
        y: 35.28
      }
    }
  ]
};
