import getContent from './services/getContent';

import texts from './texts';
import levels from './levels';

export default  {
  id: 23,
  type: 'findSpy',
  eventCategory: 'FindSpy',
  videoAnnounceUrl: null,
  isCenteredContent: true,
  tooltipAnnounce: 'Логическое мышление помогает спецагенту быстро разобраться в сложной ситуации. Разивай этот навык, чтобы всегда выбирать только самое верное решение.',
  previewUrl: require('~/static/image/trainerPreviews/findSpy.jpg'),
  params: {
    ...texts,
    name: 'Вычисли шпиона',
    hasTimer: true,
    startsImmediately: true,
    maxSkills:  {
      mind: 1,
      logic: 2
    },
    skills: [ 'logic', 'mind' ],
    mainSkill: 'logic',
    isShowStage: true,
    levels,
    content: getContent()
  }
};
