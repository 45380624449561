import microscope from '../figures/microscope';
import animalHand from '../figures/animalHand';
import backpack from '../figures/backpack';
import cat from '../figures/cat';
import dog from '../figures/dog';
import giraffe from '../figures/giraffe';
import helicopter from '../figures/helicopter';
import iceCreme from '../figures/iceCreme';
import pike from '../figures/pike';
import turnip from '../figures/turnip';
import turtle from '../figures/turtle';

export default [
  microscope,
  animalHand,
  backpack,
  cat,
  dog,
  giraffe,
  helicopter,
  iceCreme,
  pike,
  turnip,
  turtle
];
