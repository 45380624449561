import React from 'react';
import { connect } from 'react-redux';

import GreatButtonPopup from '~/components/GreatButtonPopup/GreatButtonPopup';
import Popup from '~/components/Popup/Popup';
import LevelUpPanel from '../LevelUpPanel/LevelUpPanel';

import popupNames from '~/services/popup-names';

import { levelMaxValueSelector, prevLevelMaxValueSelector } from '~/store/profile/selector';
import { getPopupName } from '~/store/page/selector';

import './level-up-popup.scss';

const LevelUpPopup = (props) => {
  const { popupName } = props;
  const isOpen = popupName === popupNames.levelUp;
  
  return (
    <Popup className="level-up-popup scale" isOpen={ isOpen }>
      <div className="level-up-popup__holder">
        <div className="level-up-popup__title">Новый уровень!</div>
        <div className="level-up-popup__sub-title">
          Поздравляю, Фокси! Продолжай тренироваться дальше и получай новые уровни
        </div>
        <LevelUpPanel { ...props }/>
        <GreatButtonPopup className="level-up-popup__button" text="Здорово!"/>
      </div>
    </Popup>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    levelMaxValue: levelMaxValueSelector(state),
    prevLevelMaxValue: prevLevelMaxValueSelector(state),
    popupName: getPopupName(state)
  };
}

export default connect(mapStateToProps)(LevelUpPopup);
