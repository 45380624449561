import React, { Fragment } from 'react';

const defaultBubbleFilter = {
  width: 91.683,
  height: 63.8095,
  y: 0.634766,
  dy: 6,
  stdDeviation: 5,
};

export default {
  focus: {
    width: 70,
    height: 53,
    content: (
      <Fragment>
        <path d="M53.6718 25.2518C54.441 26.2775 54.441 27.3031 53.6718 28.0723C51.1077 31.6621 44.4411 37.8159 34.954 37.8159C25.4668 37.8159 18.8002 31.6621 16.2361 28.0723C15.4669 27.0467 15.4669 26.0211 16.2361 25.2518C19.0566 21.9185 25.2104 15.5083 34.954 15.5083C44.4411 15.7647 51.1077 21.6621 53.6718 25.2518Z" fill="#E31249"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.0067 21.666C47.0067 28.3217 41.6112 33.7172 34.9555 33.7172C28.2998 33.7172 22.9043 28.3217 22.9043 21.666C22.9043 20.752 23.006 19.8617 23.1988 19.006C26.2775 17.0479 30.1857 15.5132 34.9532 15.5132C39.6008 15.6388 43.5715 17.1182 46.7146 19.0168C46.9058 19.8692 47.0067 20.7558 47.0067 21.666Z" fill="#FFA6C2"/>
        <path d="M34.9526 29.8701C38.3513 29.8701 41.1065 27.115 41.1065 23.7163C41.1065 20.3177 38.3513 17.5625 34.9526 17.5625C31.554 17.5625 28.7988 20.3177 28.7988 23.7163C28.7988 27.115 31.554 29.8701 34.9526 29.8701Z" fill="#E31249"/>
      </Fragment>
    ),
    filter: {
      x: 0.65918,
      y: 0.508301,
      width: 68.5896,
      height: 52.3076
    }
  },
  imagination: {
    width: 62,
    height: 71,
    content: (
      <Fragment>
        <path d="M32.7817 55.8661L26.9404 40.5676L18.8739 35.5607L26.9404 30.5539L32.7817 15.2554L38.623 30.5539L46.6895 35.5607L38.623 40.2894L32.7817 55.8661Z" fill="#6F24E3"/>
        <path d="M19.435 52.806L15.2627 46.6866L19.435 40.5671L23.6074 46.6866L19.435 52.806Z" fill="#A183FF"/>
        <path d="M41.1279 25.2718L38.6245 21.6558L41.1279 18.0398L43.6313 21.6558L41.1279 25.2718Z" fill="#8A6EFF"/>
      </Fragment>
    ),
    filter: {
      x: 0.262695,
      y: 0.255371,
      width: 61.4268,
      height: 70.6108
    }
  },
  logic: {
    width: 70,
    height: 69,
    content: (
      <Fragment>
        <path d="M48.4681 35.0454C46.8994 35.3591 46.2719 36.6141 43.762 37.2416V27.202C43.762 25.947 42.8208 25.0058 41.5659 25.0058H36.8598C37.4873 22.1822 39.056 21.5548 39.3697 19.9861C39.6834 17.4762 36.8598 15.28 34.0362 15.28C31.2126 15.28 28.3889 17.4762 28.7027 19.9861C29.0164 21.5548 30.5851 22.1822 31.2126 25.0058H17.7219C16.467 25.0058 15.5258 25.947 15.5258 27.202V33.163C18.3494 32.5355 18.9769 30.9668 20.5455 30.9668C23.0554 30.6531 25.5653 33.163 25.5653 35.9866C25.5653 38.8102 23.3692 41.6339 20.5455 41.0064C18.9769 40.6927 18.3494 39.124 15.5258 38.8102V51.3597C15.5258 52.6146 16.467 53.5558 17.7219 53.5558H29.0164C28.389 51.3597 27.134 50.7322 26.8203 49.1635C26.5065 46.6536 29.3302 44.4575 32.1538 44.4575C34.9774 44.4575 37.801 46.6536 37.4873 49.1635C37.1736 50.4185 35.9186 51.046 35.2911 53.5558H42.1933C43.4483 53.5558 44.3895 52.6146 44.3895 51.3597V43.2026C46.8994 43.83 47.5268 45.085 49.0955 45.3987C51.6054 45.7124 54.1153 43.2026 54.1153 40.3789C53.1741 37.2416 50.9779 34.4179 48.4681 35.0454Z" fill="#FA9312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.3879 44.7723L24.6246 25.009H17.7203C16.4654 25.009 15.5242 25.9502 15.5242 27.2052V33.1662C17.0007 32.838 17.8767 32.2525 18.6008 31.7686C19.2614 31.327 19.7956 30.97 20.5439 30.97C23.0538 30.6563 25.5637 33.1662 25.5637 35.9898C25.5637 38.8134 23.3676 41.6371 20.5439 41.0096C19.9055 40.8819 19.423 40.5463 18.8848 40.1721C18.406 39.8391 17.8832 39.4756 17.1674 39.2006L26.7951 48.8282C26.7604 46.4626 29.4563 44.4607 32.1522 44.4607C34.9758 44.4607 37.7994 46.6568 37.4857 49.1667C37.3558 49.6865 37.0643 50.0987 36.7229 50.5816C36.24 51.2645 35.6571 52.0888 35.2896 53.559H42.1917C43.4467 53.559 44.3879 52.6178 44.3879 51.3629V44.7723Z" fill="#FFBC27"/>
      </Fragment>
    ),
    filter: {
      x: 0.52417,
      y: 0.280029,
      width: 68.5911,
      height: 68.279
    }
  },
  memory: {
    width: 70,
    height: 69,
    content: (
      <Fragment>
        <path d="M47.4605 49.1207C49.7115 49.1207 54.2134 43.9756 54.2134 39.4737C54.2134 32.3991 49.7115 29.8266 49.7115 29.8266C49.7115 29.8266 51.9624 22.7521 45.2095 21.4658C44.8879 17.9285 42.9585 15.356 40.7075 15.356H38.7781C37.4918 15.356 36.2056 16.6422 36.2056 18.2501V44.2972C36.2056 49.4423 36.2056 53.9443 41.9938 53.9443C45.2095 53.9443 47.4605 51.6933 47.4605 49.1207Z" fill="#2ECC62"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.5575 47.2799C52.4352 45.3788 54.2094 42.2991 54.2094 39.4729C54.2094 32.3984 49.7074 29.8258 49.7074 29.8258C49.7074 29.8258 51.9584 22.7513 45.2054 21.465C44.8839 17.9278 42.9545 15.3552 40.7035 15.3552H39.7806C39.917 19.8292 40.496 29.6173 42.9543 32.0757C43.8416 32.963 44.729 33.6055 45.5487 34.199C47.6998 35.7566 49.3857 36.9773 49.3857 41.4012C49.3857 43.0118 49.3857 45.3485 50.5575 47.2799Z" fill="#52E87F"/>
        <path d="M22.3792 49.1207C20.1282 49.1207 15.6262 43.9756 15.6262 39.4737C15.6262 32.3991 20.1282 29.8266 20.1282 29.8266C20.1282 29.8266 17.8772 22.7521 24.6302 21.4658C24.9517 17.9285 26.8811 15.356 29.1321 15.356H31.0616C32.3478 15.356 33.6341 16.6422 33.6341 18.2501V44.2972C33.6341 49.4423 33.6341 53.9443 27.8459 53.9443C24.6302 53.9443 22.3792 51.6933 22.3792 49.1207Z" fill="#2EB360"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.3555 15.356C30.1841 20.0629 29.5499 29.0876 27.2033 31.4342C26.316 32.3215 25.4287 32.964 24.6089 33.5575C22.4578 35.1151 20.7719 36.3358 20.7719 40.7597C20.7719 42.5329 20.7719 45.186 19.2085 47.2095C17.3568 45.3033 15.6262 42.2649 15.6262 39.4737C15.6262 32.3991 20.1282 29.8266 20.1282 29.8266C20.1282 29.8266 17.8772 22.7521 24.6302 21.4658C24.9517 17.9285 26.8811 15.356 29.1321 15.356H30.3555Z" fill="#2ECC62"/>
      </Fragment>
    ),
    filter: {
      x: 0.626221,
      y: 0.355225,
      width: 68.5872,
      height: 68.589
    }
  },
  mind: {
    width: 60,
    height: 69,
    content: (
      <Fragment>
        <path d="M22.3159 41.9102V46.5409C22.3159 50.5928 25.2101 52.6188 27.8149 53.1976C32.1562 54.3553 36.787 50.5928 36.787 46.8303V41.9102" fill="#489EFF"/>
        <path d="M29.5528 44.8062C37.5449 44.8062 44.0238 38.3273 44.0238 30.3351C44.0238 22.3429 37.5449 15.864 29.5528 15.864C21.5606 15.864 15.0817 22.3429 15.0817 30.3351C15.0817 38.3273 21.5606 44.8062 29.5528 44.8062Z" fill="#71BFFF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.3145 44.3119C32.1152 44.6338 30.8543 44.8055 29.5533 44.8055C28.2468 44.8055 26.9807 44.6323 25.7768 44.3077C25.5987 42.9479 25.5021 41.4582 25.5021 39.8879C25.5021 35.836 27.2386 33.2312 29.554 33.2312C31.8694 33.2312 33.6059 36.1254 33.6059 39.8879C33.6059 41.4361 33.5032 42.9329 33.3145 44.3119Z" fill="#C5E2FF"/>
      </Fragment>
    ),
    filter: {
      x: 0.081665,
      y: 0.864014,
      width: 58.9422,
      height: 67.5467
    }
  },
  bubbleFocus: {
    width: 93,
    height: 65,
    content: <path d="M10.916 38.4443C10.916 43.9672 15.3932 48.4443 20.916 48.4443H72.5991C78.1219 48.4443 82.5991 43.9672 82.5991 38.4443V21.1688C82.5991 15.646 78.1219 11.1688 72.5991 11.1688H59.9358C57.5964 11.1688 55.3311 10.3487 53.5339 8.85101L49.0309 5.09845C48.2728 4.46669 47.1672 4.48231 46.4273 5.13522L42.4244 8.66718C40.5974 10.2793 38.2447 11.1688 35.8082 11.1688H20.916C15.3932 11.1688 10.916 15.646 10.916 21.1688V38.4443Z" fill="#E24067"/>,
    filter: {
      ...defaultBubbleFilter,
      x: 0.916016,
      values: '0 0 0 0 0.886275 0 0 0 0 0.25098 0 0 0 0 0.403922 0 0 0 0.51 0'
    }
  },
  bubbleImagination: {
    width: 92,
    height: 65,
    content: <path d="M10.2402 38.4443C10.2402 43.9672 14.7174 48.4443 20.2402 48.4443H71.9233C77.4461 48.4443 81.9233 43.9672 81.9233 38.4443V21.1688C81.9233 15.646 77.4461 11.1688 71.9233 11.1688H59.26C56.9206 11.1688 54.6553 10.3487 52.8581 8.85101L48.3551 5.09845C47.597 4.46669 46.4914 4.48231 45.7515 5.13522L41.7486 8.66718C39.9216 10.2793 37.5689 11.1688 35.1324 11.1688H20.2402C14.7174 11.1688 10.2402 15.646 10.2402 21.1688V38.4443Z" fill="#8656C3"/>,
    filter: {
      ...defaultBubbleFilter,
      x: 0.240234,
      values: '0 0 0 0 0.54902 0 0 0 0 0.384314 0 0 0 0 0.756863 0 0 0 0.51 0'
    }
  },
  bubbleLogic: {
    width: 93,
    height: 65,
    content: <path d="M10.5039 38.4443C10.5039 43.9672 14.9811 48.4443 20.5039 48.4443H72.187C77.7098 48.4443 82.187 43.9672 82.187 38.4443V21.1688C82.187 15.646 77.7098 11.1688 72.187 11.1688H59.5237C57.1843 11.1688 54.919 10.3487 53.1218 8.85101L48.6188 5.09845C47.8607 4.46669 46.7551 4.48231 46.0152 5.13522L42.0123 8.66718C40.1853 10.2793 37.8326 11.1688 35.3961 11.1688H20.5039C14.9811 11.1688 10.5039 15.646 10.5039 21.1688V38.4443Z" fill="#F39811"/>,
    filter: {
      ...defaultBubbleFilter,
      x: 0.503906,
      values: '0 0 0 0 0.952941 0 0 0 0 0.6 0 0 0 0 0.0745098 0 0 0 0.65 0'
    }
  },
  bubbleMemory: {
    width: 93,
    height: 65,
    content: <path d="M10.2676 38.4443C10.2676 43.9672 14.7447 48.4443 20.2676 48.4443H71.9506C77.4735 48.4443 81.9506 43.9672 81.9506 38.4443V21.1688C81.9506 15.646 77.4735 11.1688 71.9506 11.1688H59.2874C56.948 11.1688 54.6826 10.3487 52.8855 8.85101L48.3825 5.09845C47.6244 4.46669 46.5188 4.48231 45.7788 5.13522L41.776 8.66718C39.949 10.2793 37.5963 11.1688 35.1597 11.1688H20.2676C14.7447 11.1688 10.2676 15.646 10.2676 21.1688V38.4443Z" fill="#2DB764"/>,
    filter: {
      ...defaultBubbleFilter,
      x: 0.267578,
      values: '0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.392157 0 0 0 0.51 0'
    }
  },
  bubbleMind: {
    width: 92,
    height: 65,
    content: <path d="M10.0312 38.4443C10.0312 43.9672 14.5084 48.4443 20.0312 48.4443H71.7143C77.2372 48.4443 81.7143 43.9672 81.7143 38.4443V21.1688C81.7143 15.646 77.2371 11.1688 71.7143 11.1688H59.051C56.7117 11.1688 54.4463 10.3487 52.6492 8.85101L48.1461 5.09845C47.388 4.46669 46.2825 4.48231 45.5425 5.13522L41.5396 8.66718C39.7126 10.2793 37.3599 11.1688 34.9234 11.1688H20.0312C14.5084 11.1688 10.0312 15.646 10.0312 21.1688V38.4443Z" fill="#479CFF"/>,
    filter: {
      ...defaultBubbleFilter,
      x: 0.03125,
      values: '0 0 0 0 0.278431 0 0 0 0 0.611765 0 0 0 0 1 0 0 0 0.55 0'
    }
  }
};
