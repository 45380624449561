export default ({ timer, amount }) => {
  const minutes = (timer[0] / 60) >> 0;
  const seconds = timer[0] % 60;
  const minutesText = minutes > 0 ? `${minutes} минут${getEnding(minutes)}` : '';
  const secondsText = seconds > 0 ? `${seconds} секунд${getEnding(seconds)}` : '';
  const tooltipContent = `За ${minutesText} ${secondsText} постарайся запомнить название каждой картинки — дословно! Для этого:<br/>
                        <b>1.</b> Один раз, выразительно, прочитай название картинки;<br/>
                        <b>2.</b> Посмотри на каждую картинку, подумай: почему она именно так называется? И запомни формулировку дословно.<br/>
                        <b>3.</b> На следующем экране выбери <b>одну</b> правильную фразу для каждой картинки.`;

  return {
    timer: timer.map(item => item * 1000),
    amount,
    tooltipContent,
    variants: {
      [amount]: {
        focus: 3,
        memory: 4
      },
      [amount - 1]: {
        focus: 2,
        memory: 3
      },
      [amount - 2]: {
        focus: 1,
        memory: 2
      }
    },
  };
}

function getEnding(value) {
  if(value < 10 || value > 20) {
    switch (value) {
      case 1:
        return 'у';

      case 2:
      case 3:
      case 4:
        return 'ы';

      default:
        return '';
    }
  }
  return '';
}
