export default {
  shapes: [ 1, 6, 7, 3, 2, 5, 4 ],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 40
  },
  outline: [
    {
      desktop: { x: 274.8, y: 53.2 },
      tablet: { x: 274.3, y: 50.7 },
      mobile: { x: 195.2, y: 32.8 },
    },
    {
      desktop: { x: 274.8, y: 117 },
      tablet: { x: 274.3, y: 107.3 },
      mobile: { x: 195.2, y: 75.3 },
    },
    {
      desktop: { x: 307, y: 117 },
      tablet: { x: 303, y: 107.3 },
      mobile: { x: 216, y: 75.3 },
    },
    {
      desktop: { x: 217, y: 207 },
      tablet: { x: 223, y: 187 },
      mobile: { x: 156, y: 135 },
    },
    {
      desktop: { x: 243, y: 207 },
      tablet: { x: 249, y: 187 },
      mobile: { x: 174, y: 135 },
    },
    {
      desktop: { x: 288, y: 252 },
      tablet: { x: 289, y: 227 },
      mobile: { x: 204, y: 165 },
    },
    {
      desktop: { x: 199.7, y: 252 },
      tablet: { x: 209.5, y: 227 },
      mobile: { x: 144.5, y: 165 },
    },
    {
      desktop: { x: 159.2, y: 211.5 },
      tablet: { x: 172.7, y: 190.2 },
      mobile: { x: 118.2, y: 138.7 },
    },
    {
      desktop: { x: 159.2, y: 275.2 },
      tablet: { x: 172.7, y: 246.6 },
      mobile: { x: 118.2, y: 181 },
    },
    {
      desktop: { x: 221.7, y: 275.2 },
      tablet: { x: 228.5, y: 246.6 },
      mobile: { x: 160, y: 181 },
    },
    {
      desktop: { x: 243, y: 297 },
      tablet: { x: 249, y: 267 },
      mobile: { x: 174, y: 195 },
    },
    {
      desktop: { x: 332.7, y: 297 },
      tablet: { x: 329, y: 267 },
      mobile: { x: 235, y: 195 },
    },
    {
      desktop: { x: 396, y: 360.3 },
      tablet: { x: 385, y: 323 },
      mobile: { x: 276, y: 235.8 },
    },
    {
      desktop: { x: 396, y: 405 },
      tablet: { x: 385, y: 363 },
      mobile: { x: 276, y: 266 },
    },
    {
      desktop: { x: 441, y: 359.7 },
      tablet: { x: 425, y: 323 },
      mobile: { x: 306, y: 236 },
    },
    {
      desktop: { x: 396.3, y: 315.3 },
      tablet: { x: 385.3, y: 283.4 },
      mobile: { x: 276.5, y: 206.5 },
    },
    {
      desktop: { x: 396.3, y: 233.6 },
      tablet: { x: 385.3, y: 210.8 },
      mobile: { x: 276.5, y: 152.5 },
    },
    {
      desktop: { x: 423, y: 207 },
      tablet: { x: 409, y: 187 },
      mobile: { x: 294, y: 135 },
    },
    {
      desktop: { x: 397, y: 207 },
      tablet: { x: 383, y: 187 },
      mobile: { x: 276, y: 135 },
    },
    {
      desktop: { x: 307, y: 117 },
      tablet: { x: 303, y: 107.3 },
      mobile: { x: 216, y: 75.3 },
    },
    {
      desktop: { x: 338.5, y: 117 },
      tablet: { x: 330.8, y: 107.3 },
      mobile: { x: 237.6, y: 75.3 },
    },
    {
      desktop: { x: 338.5, y: 53.2 },
      tablet: { x: 330.8, y: 50.7 },
      mobile: { x: 237.6, y: 32.8 },
    },
  ],
  outlines: [
    {
      desktop: { x: 243, y: 85 },
      tablet: { x: 246, y: 79 },
      mobile: { x: 174, y: 54 },
      rotation: 315
    },
    {
      desktop: { x: 217, y: 207 },
      tablet: { x: 223, y: 187 },
      mobile: { x: 156, y: 135 },
      rotation: 270
    },
    {
      desktop: { x: 423, y: 207 },
      tablet: { x: 409, y: 187 },
      mobile: { x: 294, y: 135 },
      rotation: 90
    },
    {
      desktop: { x: 127, y: 243 },
      tablet: { x: 145, y: 219 },
      mobile: { x: 97, y: 160 },
      rotation: 315
    },
    {
      desktop: { x: 334, y: 252 },
      tablet: { x: 329, y: 227 },
      mobile: { x: 234, y: 165 },
      rotation: 90
    },
    {
      desktop: { x: 460, y: 297 },
      tablet: { x: 442, y: 267 },
      mobile: { x: 319, y: 194 },
      rotation: 135
    },
    {
      desktop: { x: 441, y: 405 },
      tablet: { x: 425, y: 363 },
      mobile: { x: 306, y: 266 },
      rotation: 180
    }
  ]
}
