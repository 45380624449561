import React from 'react';

const GradientSVG = ({ startColor, endColor, gradientId }) => {
  const style = { height: 0, position: 'absolute' };
  return (
    <svg style={ style }>
      <defs>
        <linearGradient id={ gradientId } >
          <stop offset="0%" stopColor={ startColor } />
          <stop offset="100%" stopColor={ endColor } />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GradientSVG;
