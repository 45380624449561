import * as Sizes from '../constants';
import { MOBILE } from '../constants/screens';

import cloneArray from './cloneArray';
import { getWidthField } from './';
import generateOutlines from './generateOutlines';
import { getHeightShape, getWidthShape, maxHeightShape } from './shapes';


const leftShape = ({ shapes, x }, screenType) => {
  return shapes.reduce((acc, item) => acc + getWidthShape(item, screenType) + Sizes.MARGIN_SHAPE_SIDE[screenType], x);
}

const getWidthLineShapes = (shapes, screenType) => leftShape({ shapes, x: 0 }, screenType);

const getIndexesSplit = (shapes, widthCanvas, screenType) => {
  let indexes = [];
  let acc = [];

  shapes.forEach((item, index) => {
    acc.push(item);
    let widthLineShapes = getWidthLineShapes(acc, screenType);
    if (widthLineShapes > widthCanvas) {
      indexes.push(index);
      acc = [item];
    }
  });

  return [ 0, ...indexes ];
}

const leftLineShapes = (shapes, screenType) => {
  return (getWidthField() - getWidthLineShapes(shapes, screenType) - Sizes.MARGIN_SHAPE_SIDE[screenType]) * 0.5;
}

export default (defaultOutlines, defaultShapes, defaultOutline, widthCanvas, heightCanvas, offset, screenType, isOver) => {
  let shapes = cloneArray(defaultShapes);
  let outlines = cloneArray(defaultOutlines);
  let outline = cloneArray(defaultOutline);
  const top = Sizes.MARGIN_FIELD + Sizes.HEIGHT_FIELD[screenType];
  const indexesSplit = getIndexesSplit(shapes, widthCanvas, screenType);
  let heightLines = 0;

  for (let i = 0; i < indexesSplit.length; i++) {
    const limit = indexesSplit[i + 1] ? indexesSplit[i + 1] : shapes.length;
    const partShapes = shapes.slice(indexesSplit[i], limit);
    const maxHeightLine = maxHeightShape(partShapes);
    const lineX = leftLineShapes(partShapes, screenType);

    for (let j = indexesSplit[i]; j < limit; j++) {
      let y = top + heightLines + (maxHeightLine - getHeightShape(shapes[j], screenType)) * 0.5;
      let x = leftShape({ x: lineX, shapes: shapes.slice(indexesSplit[i], j) }, screenType);

      shapes[j] = {
        ...shapes[j], x, y: y - 40,
        closed: true,
        points: shapes[j][screenType],
        draggable: isOver ? false : true,
        rotation: 0,
        defaultPosition: {
          x, y: y - 40,
          rx: x + getWidthShape(shapes[j], screenType) - Sizes.ROTATE_BUTTON_SIZE / 2,
          ry: y - Sizes.ROTATE_BUTTON_SIZE
        }
      }
    };

    heightLines += maxHeightLine + Sizes.MARGIN_SHAPE_BOTTOM;
  }

  if (screenType === MOBILE) {
    heightLines += 80;
  }

  let { outlines: o, outline: ol } = generateOutlines(outlines, shapes, outline, offset, screenType);
  return { shapes, heightCanvas: heightCanvas + heightLines, outlines: o, outline: ol };
};
