import TrainerMemo from '~/trainers/TrainerMemo';
import TrainerAccuracy from '~/trainers/TrainerAccuracy';
import TrainerDynamicMemory from '~/trainers/TrainerDynamicMemory';
import TrainerLongMemory from '~/trainers/TrainerLongMemory';
import TrainerAnagram from '~/trainers/TrainerAnagram';
import TrainerFunnyPoints from '~/trainers/TrainerFunnyPoints';
import TrainerSudoku from '~/trainers/TrainerSudoku';
import TrainerTangram from '~/trainers/TrainerTangram';
import TrainerMnemonic from '~/trainers/TrainerMnemonic';
import TrainerIntellectualWorkouts from '~/trainers/TrainerIntellectualWorkouts';
import TrainerSecretCode from '~/trainers/TrainerSecretCode';
import TrainerFindSpy from '~/trainers/TrainerFindSpy';
import TrainerSpatial from '~/trainers/TrainerSpatial';
import TrainerNumberSeries from '~/trainers/TrainerNumberSeries';

export default {
  memo: TrainerMemo,
  accuracy: TrainerAccuracy,
  longMemory: TrainerLongMemory,
  dynamicMemory: TrainerDynamicMemory,
  funnyPoints: TrainerFunnyPoints,
  anagram: TrainerAnagram,
  sudoku: TrainerSudoku,
  tangram: TrainerTangram,
  mnemonic: TrainerMnemonic,
  intellectualWorkouts: TrainerIntellectualWorkouts,
  secretCode: TrainerSecretCode,
  findSpy: TrainerFindSpy,
  spatial: TrainerSpatial,
  numberSeries: TrainerNumberSeries
};
