import React from 'react';
import classNames from 'classnames'

import './socials.scss'
import Icon from '~/components/Icon/Icon'

/* eslint-disable */
import vk from './assets/vk.svg'
import fb from './assets/fb.svg'
/* eslint-enable */

const Socials = ({ isFooter }) => {
  let socialsClass = classNames('socials', {
    'socials_footer': isFooter
  })

  return (
    <div className={ socialsClass }>
      <div className="socials__item">
        <a className="socials__link" href='/'>
          <Icon name="vk" className="socials__icon" />
        </a>
      </div>

      <div className="socials__item">
        <a className="socials__link" href='/'>
          <Icon name="fb" className="socials__icon" />
        </a>
      </div>
    </div>
  );
}

export default Socials
