import gifts from '~/shared/gifts';

export default {
  id: 7,
  name: 'Великая Китайская стена',
  longMemory: true,
  description: 'Великая Китайская стена – одно из самых грандиозных строений, созданных за историю человечества.',
  partingWords: 'Итак, Китай – страна, полная неразгаданных тайн. Тебя уже ждёт новая интересная миссия! А чтобы быстро передвигаться от одной миссии к другой, тебе точно пригодятся ботинки спецагента, которые ты получишь после удачного завершения миссии.',
  story: `Великая Китайская стена – это неразгаданный секрет древности. Удивительно, как люди, не имея специальной тяжелой техники, смогли соорудить такое ограждение!
          Информация для спецагента:
          1. Великую Китайскую стену видно из космоса.
          2. Изначально протяженность стены составляла 21 190 км. В наши дни сохранилось меньше половины строения – 8 850 км.
          3. По мнению историков, со своей основной функцией – защитой – стена справлялась плохо.`,
  textLosing: 'Эх... Ну и сложная миссия оказалась! Не беда! В таких ситуациях очень выручает тренажёрный зал. Потренируй навыки и возвращайся!',
  textPartially: 'Увы, не все задания миссии были выполнены. Специально для этого в школе спецагента есть тренажёрный зал. Потренируйся и обязательно возвращайся! ',
  textSuccess: 'Мистер Фоксман не ошибся, доверив эту миссию тебе. Смотри, как выросли все твои навыки! Ты молодец! А сколько полезных предметов спецагента хранится в твоём наборе! ',
  gift: gifts.shoes,
  imgs: [
    require('~/static/locations/pekin/chinese-wall_980x590.jpg'),
  ]
};
