export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    tablet: 5,
    mobile: 15
  },
  outline: [
    {
      desktop: {
        x: 180.62,
        y: 54
      },
      tablet: {
        x: 192,
        y: 50
      },
      mobile: {
        x: 131,
        y: 34
      }
    },
    {
      desktop: {
        x: 225.37,
        y: 98.5
      },
      tablet: {
        x: 232,
        y: 90
      },
      mobile: {
        x: 161,
        y: 64
      }
    },
    {
      desktop: {
        x: 269.62,
        y: 54
      },
      tablet: {
        x: 272,
        y: 50
      },
      mobile: {
        x: 191,
        y: 34
      }
    },
    {
      desktop: {
        x: 269.62,
        y: 144.38
      },
      tablet: {
        x: 272,
        y: 130
      },
      mobile: {
        x: 191,
        y: 94
      }
    },
    {
      desktop: {
        x: 225,
        y: 189
      },
      tablet: {
        x: 232.5,
        y: 169.5
      },
      mobile: {
        x: 161.5,
        y: 123.5
      }
    },
    {
      desktop: {
        x: 305.28,
        y: 269.28
      },
      tablet: {
        x: 305.14,
        y: 242.14
      },
      mobile: {
        x: 215.85,
        y: 177.83
      }
    },
    {
      desktop: {
        x: 305.28,
        y: 397.18
      },
      tablet: {
        x: 305.14,
        y: 352.72
      },
      mobile: {
        x: 215.85,
        y: 261.79
      }
    },
    {
      desktop: {
        x: 367.93,
        y: 334.53
      },
      tablet: {
        x: 360.57,
        y: 297.28
      },
      mobile: {
        x: 257.43,
        y: 220.21
      }
    },
    {
      desktop: {
        x: 432.28,
        y: 333.82
      },
      tablet: {
        x: 417.14,
        y: 297.28
      },
      mobile: {
        x: 299.85,
        y: 220.21
      }
    },
    {
      desktop: {
        x: 367.93,
        y: 398.17
      },
      tablet: {
        x: 360.57,
        y: 353.85
      },
      mobile: {
        x: 257.43,
        y: 262.64
      }
    },
    {
      desktop: {
        x: 178,
        y: 397.28
      },
      tablet: {
        x: 192,
        y: 354.14
      },
      mobile: {
        x: 131,
        y: 262.85
      }
    },
    {
      desktop: {
        x: 216,
        y: 359.28
      },
      tablet: {
        x: 225,
        y: 321.14
      },
      mobile: {
        x: 156,
        y: 237.85
      }
    },
    {
      desktop: {
        x: 216,
        y: 305.64
      },
      tablet: {
        x: 225,
        y: 273.57
      },
      mobile: {
        x: 156,
        y: 202.43
      }
    },
    {
      desktop: {
        x: 153,
        y: 242.64
      },
      tablet: {
        x: 169,
        y: 217.57
      },
      mobile: {
        x: 114,
        y: 160.43
      }
    },
    {
      desktop: {
        x: 215.82,
        y: 179.82
      },
      tablet: {
        x: 224.28,
        y: 162.28
      },
      mobile: {
        x: 155.71,
        y: 118.71
      }
    },
    {
      desktop: {
        x: 180.5,
        y: 144.5
      },
      tablet: {
        x: 192,
        y: 130
      },
      mobile: {
        x: 131,
        y: 94
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 305.28,
        y: 270,
        rotation: -45
      },
      tablet: {
        x: 305.14,
        y: 241,
        rotation: -45
      },
      mobile: {
        x: 215.85,
        y: 178,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 216,
        y: 180,
        rotation: 0
      },
      tablet: {
        x: 225,
        y: 162,
        rotation: -0
      },
      mobile: {
        x: 156,
        y: 118,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 280.28,
        y: 242.64,
        rotation: -135
      },
      tablet: {
        x: 282.14,
        y: 217.57,
        rotation: -135
      },
      mobile: {
        x: 198.85,
        y: 160.43,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 224.62,
        y: 54,
        rotation: 0
      },
      tablet: {
        x: 232,
        y: 50,
        rotation: 0
      },
      mobile: {
        x: 161,
        y: 34,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 225.62,
        y: 144,
        rotation: 180
      },
      tablet: {
        x: 232,
        y: 130,
        rotation: 180
      },
      mobile: {
        x: 161,
        y: 94,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 400.46,
        y: 302,
        rotation: -45
      },
      tablet: {
        x: 388.85,
        y: 269,
        rotation: -45
      },
      mobile: {
        x: 278.64,
        y: 199,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 180,
        y: 99,
        rotation: 0
      },
      tablet: {
        x: 192,
        y: 90,
        rotation: 0
      },
      mobile: {
        x: 131,
        y: 64,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
