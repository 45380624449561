import getStyleProperties from '~/services/get-style-properties';

export default (params, refs) => {
  const { widthWorkspace, heightWorkspace } = params;
  let heightRefs = 0;

  if (refs) {
    heightRefs = refs.reduce((acc, ref) => {
      let outerHeight = 0;
      const isObjectRef = ref && !ref.tagName;
      const isNotEmptyRef = (isObjectRef && ref !== null && ref.current) || (!isObjectRef && ref);

      if (isNotEmptyRef) {
        const { marginTop, marginBottom, paddingTop, paddingBottom, height } = getStyleProperties(ref.current || ref);
        outerHeight = height + marginBottom + marginTop + paddingBottom + paddingTop;
      }

      return acc + outerHeight;
    }, 0);
  }

  const height = heightWorkspace - heightRefs;

  return { width: widthWorkspace, height };
};
