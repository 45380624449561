import { getScreenType } from './index';

const _getParamShape = (param, shape, screen = 'points') => {
  let result = 0;
  let minLine = 1000;
  let maxLine = 0;

  for (let i = param === 'width' ? 0 : 1; i < shape[screen].length; i += 2) {
    if (shape[screen][i] > maxLine) {
      maxLine = shape[screen][i];
    }
    if (shape[screen][i] < minLine) {
      minLine = shape[screen][i];
    }
  }
  result = maxLine - minLine;

  return result;
};

export const getWidthShape = (shape, screen) => _getParamShape('width', shape, screen);
export const getHeightShape = (shape, screen) => _getParamShape('height', shape, screen);
export const maxHeightShape = (shapes) => {
  const screenType = getScreenType();
  let max = 0;

  shapes.forEach(item => {
    const localMax = getHeightShape(item, screenType);
    if (localMax > max) {
      max = localMax;
    }
  });

  return max;
};
