export default {
  shapes: [ 1, 4, 7, 8, 6, 3, 5 ],
  offset: {
    tablet: 15,
    mobile: 30
  },
  outline: [
    {
      desktop: { x: 135.8, y: 119.2 },
      tablet: { x: 156.3, y: 111.7 },
      mobile: { x: 102.2, y: 76.8 },
    },
    {
      desktop: { x: 135.8, y: 182.8 },
      tablet: { x: 156.3, y: 168.3 },
      mobile: { x: 102.2, y: 119.2 },
    },
    {
      desktop: { x: 199, y: 182.8 },
      tablet: { x: 212, y: 168.3 },
      mobile: { x: 144, y: 119.2 },
    },
    {
      desktop: { x: 199, y: 278 },
      tablet: { x: 212, y: 251 },
      mobile: { x: 144, y: 180 },
    },
    {
      desktop: { x: 169, y: 278 },
      tablet: { x: 181, y: 251 },
      mobile: { x: 121, y: 180 },
    },
    {
      desktop: { x: 124, y: 323 },
      tablet: { x: 141, y: 291 },
      mobile: { x: 92, y: 210 },
    },
    {
      desktop: { x: 214, y: 323 },
      tablet: { x: 221, y: 291 },
      mobile: { x: 152, y: 210 },
    },
    {
      desktop: { x: 257.7, y: 279.3 },
      tablet: { x: 260, y: 252.1 },
      mobile: { x: 181.1, y: 180.9 },
    },
    {
      desktop: { x: 325.3, y: 279.3 },
      tablet: { x: 324.1, y: 252.1 },
      mobile: { x: 228.9, y: 180.9 },
    },
    {
      desktop: { x: 385.6, y: 339.6 },
      tablet: { x: 372.6, y: 300.5 },
      mobile: { x: 270.5, y: 222.5 },
    },
    {
      desktop: { x: 385.6, y: 401.7 },
      tablet: { x: 372.6, y: 356.5 },
      mobile: { x: 270.5, y: 264.5 },
    },
    {
      desktop: { x: 449.3, y: 338 },
      tablet: { x: 429.1, y: 300 },
      mobile: { x: 312.8, y: 222 },
    },
    {
      desktop: { x: 386.3, y: 275 },
      tablet: { x: 373.1, y: 244 },
      mobile: { x: 270.8, y: 180 },
    },
    {
      desktop: { x: 386.3, y: 213.2 },
      tablet: { x: 373.1, y: 188.7 },
      mobile: { x: 270.8, y: 138.8 },
    },
    {
      desktop: { x: 450, y: 213 },
      tablet: { x: 429.3, y: 188.7 },
      mobile: { x: 312.2, y: 138.8 },
    },
    {
      desktop: { x: 449.8, y: 149.5 },
      tablet: { x: 429.3, y: 132.1 },
      mobile: { x: 312.2, y: 96.4 },
    },
    {
      desktop: { x: 386.3, y: 213 },
      tablet: { x: 373.5, y: 188 },
      mobile: { x: 270.5, y: 138 },
    },
    {
      desktop: { x: 260, y: 213 },
      tablet: { x: 261, y: 188 },
      mobile: { x: 186, y: 138 },
    },
    {
      desktop: { x: 199.5, y: 152.5 },
      tablet: { x: 212.9, y: 139.9 },
      mobile: { x: 144.6, y: 96.5 },
    },
    {
      desktop: { x: 199.5, y: 119.8 },
      tablet: { x: 212.9, y: 112.3 },
      mobile: { x: 144.6, y: 77.2 },
    },
    {
      desktop: { x: 262.2, y: 119.8 },
      tablet: { x: 268.7, y: 112.3 },
      mobile: { x: 185.8, y: 77.2 },
    },
    {
      desktop: { x: 262.2, y: 56.2 },
      tablet: { x: 268.7, y: 55.7 },
      mobile: { x: 185.8, y: 34.8 },
    },
    {
      desktop: { x: 199.2, y: 119.2 },
      tablet: { x: 212.7, y: 111.7 },
      mobile: { x: 143.7, y: 76.8 },
    },
  ],
  outlines: [
    {
      desktop: { x: 104, y: 151 },
      tablet: { x: 127, y: 140 },
      mobile: { x: 81, y: 98 },
      rotation: 315
    },
    {
      desktop: { x: 230, y: 152 },
      tablet: { x: 240, y: 141 },
      mobile: { x: 165, y: 98 },
      rotation: 225
    },
    {
      desktop: { x: 326, y: 279 },
      tablet: { x: 326, y: 253 },
      mobile: { x: 229, y: 181 },
      rotation: 135
    },
    {
      desktop: { x: 124, y: 324 },
      tablet: { x: 141, y: 292 },
      mobile: { x: 92, y: 210 },
      rotation: 270
    },
    {
      desktop: { x: 259, y: 213 },
      tablet: { x: 261, y: 188 },
      mobile: { x: 186, y: 138 },
      rotation: 315
    },
    {
      desktop: { x: 418, y: 245 },
      tablet: { x: 402, y: 217 },
      mobile: { x: 291, y: 160 },
      rotation: 225
    },
    {
      desktop: { x: 322, y: 338 },
      tablet: { x: 317, y: 300 },
      mobile: { x: 228, y: 222 },
      rotation: 315
    }
  ]
};
