export default {
  shapes: [ 3, 5, 7, 8, 4, 1, 6 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 123, y: 74.82 },
      tablet: { x: 144, y: 67.28 },
      mobile: { x: 91, y: 42.21 }
    },
    {
      desktop: { x: 157, y: 108.82 },
      tablet: { x: 179, y: 102.28 },
      mobile: { x: 119, y: 70.21 }
    },
    {
      desktop: { x: 157, y: 198 },
      tablet: { x: 179, y: 182 },
      mobile: { x: 119, y: 130 }
    },
    {
      desktop: { x: 246.36, y: 198 },
      tablet: { x: 258.43, y: 182 },
      mobile: { x: 178.57, y: 130 }
    },
    {
      desktop: { x: 268, y: 219.64 },
      tablet: { x: 272, y: 195.57 },
      mobile: { x: 188, y: 139.43 }
    },
    {
      desktop: { x: 268, y: 309 },
      tablet: { x: 272, y: 275 },
      mobile: { x: 188, y: 199 }
    },
    {
      desktop: { x: 290.82, y: 331.82 },
      tablet: { x: 297.28, y: 300.28 },
      mobile: { x: 205.21, y: 216.21 }
    },
    {
      desktop: { x: 291, y: 394.82 },
      tablet: { x: 297.28, y: 355.57 },
      mobile: { x: 205.21, y: 257.43 }
    },
    {
      desktop: { x: 354.46, y: 394.64 },
      tablet: { x: 353.85, y: 355.57 },
      mobile: { x: 247.64, y: 257.43 }
    },
    {
      desktop: { x: 313, y: 353.18 },
      tablet: { x: 312, y: 313.72 },
      mobile: { x: 218, y: 227.79 }
    },
    {
      desktop: { x: 313.28, y: 197.82 },
      tablet: { x: 312.14, y: 181.92 },
      mobile: { x: 217.85, y: 129.85 }
    },
    {
      desktop: { x: 439.46, y: 197.82 },
      tablet: { x: 424.71, y: 181.92 },
      mobile: { x: 302.28, y: 129.85 }
    },
    {
      desktop: { x: 366.82, y: 125.46 },
      tablet: { x: 357.63, y: 114.85 },
      mobile: { x: 260.07, y: 87.64 }
    },
    {
      desktop: { x: 430.46, y: 125.46 },
      tablet: { x: 412.85, y: 114.85 },
      mobile: { x: 301.64, y: 87.64 }
    },
    {
      desktop: { x: 430.46, y: 61.82 },
      tablet: { x: 412.85, y: 58.28 },
      mobile: { x: 301.64, y: 45.21 }
    },
    {
      desktop: { x: 366.82, y: 61.82 },
      tablet: { x: 356.28, y: 58.28 },
      mobile: { x: 259.21, y: 45.21 }
    },
    {
      desktop: { x: 366.82, y: 125.46 },
      tablet: { x: 356.28, y: 113.5 },
      mobile: { x: 259.21, y: 86.79 }
    },
    {
      desktop: { x: 312.18, y: 70.54 },
      tablet: { x: 311.57, y: 68.79 },
      mobile: { x: 217.43, y: 45 }
    },
    {
      desktop: { x: 312.18, y: 137.64 },
      tablet: { x: 311.57, y: 122.57 },
      mobile: { x: 217.43, y: 84.43 }
    },
    {
      desktop: { x: 186.64, y: 137.64 },
      tablet: { x: 200.57, y: 122.57 },
      mobile: { x: 133.43, y: 84.43 }
    },
    {
      desktop: { x: 186.46, y: 74.64 },
      tablet: { x: 200.57, y: 67.28 },
      mobile: { x: 133.43, y: 42.21 }
    }
  ],
  outlines: [
    {
      desktop: { x: 218.46, y: 106.64 },
      tablet: { x: 228.85, y: 95.57 },
      mobile: { x: 154.64, y: 63.43 },
      rotation: 135
    },
    {
      desktop: { x: 247, y: 108 },
      tablet: { x: 259, y: 102 },
      mobile: { x: 179, y: 70 },
      rotation: 90
    },
    {
      desktop: { x: 186, y: 137.64 },
      tablet: { x: 199, y: 122.57 },
      mobile: { x: 133, y: 84.43 },
      rotation: 315
    },
    {
      desktop: { x: 268, y: 219 },
      tablet: { x: 272, y: 195 },
      mobile: { x: 188, y: 139 },
      rotation: 0
    },
    {
      desktop: { x: 259, y: 362.82 },
      tablet: { x: 269, y: 327.28 },
      mobile: { x: 184, y: 236.21 },
      rotation: 315
    },
    {
      desktop: { x: 335, y: 93.64 },
      tablet: { x: 328, y: 86.57 },
      mobile: { x: 238, y: 66.43 },
      rotation: 315
    },
    {
      desktop: { x: 439.46, y: 197.82 },
      tablet: { x: 424.71, y: 181.92 },
      mobile: { x: 302.28, y: 129.85 },
      rotation: 135
    }
  ]
};
