import { FETCH_TRAINERS, CHANGE_TRAINERS_FILTER, GENERATE_CURRENT_MISSION, LEVEL_UP_TRAINERS } from './constants';

import { getTrainersBySkill } from './selector';

import apiClient from '~/services/api-client';
import generateMission from '~/services/generate-mission';

import compute from './compute';

export function fetchTrainers(offset, limit) {
  return (dispatch, getState) => {
    const { skills } = getState().profile;
    const payload = apiClient.getTrainers(offset, limit).then((res) => compute(res, skills));

    return dispatch({
      type: FETCH_TRAINERS,
      payload
    }).catch((error) => console.log(error));
  }
};

export const changeFilter = (newFilter) => ({
  type: CHANGE_TRAINERS_FILTER,
  payload: newFilter
});

export const levelUpTrainers = (trainers) => ({
  type: LEVEL_UP_TRAINERS,
  payload: trainers
});

export const setCurrentMission = (missionId) => {
  return (dispatch, getState) => {
    const trainersBySkill = getTrainersBySkill(getState(), { missionId });
    const payload = generateMission(trainersBySkill);

    return dispatch({
      type: GENERATE_CURRENT_MISSION,
      payload
    });
  }
};

