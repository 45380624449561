import skills from './skills';

export default {
  name: 'Агент Фокс',
  gameLevel: 0,
  points: 0,
  skillLevel: 0,
  completedMissions: {},
  missions: {},
  runnedTrainersIds: [],
  skills,
  prevSkills: {},
  isPending: false,
  isFulfilled: false,
  isRejected: false,
  error: ''
};
