export default {
  texts: {
    description: [
      'Любому спецагенту необходимо обрабатывать большой объём информации. Сделать это проще, когда всё имеет чёткую структуру и последовательность. Развитие динамической памяти поможет тебе научиться выстраивать информацию в логические схемы, чтобы упростить процесс запоминания. Вперёд!',
      'Память спецагента – его тайное оружие. Играя в эту игру, ты узнаешь не один способ развития памяти, как у спецагента. Тренируйся, и сможешь запоминать важную информацию с первого раза!'
    ],
    losing: [
      'Настоящим спецагентом становится лишь тот, кто много тренируется! Попробуй ещё раз!',
      'Ещё немного, и у тебя всё получится! Повтори попытку!'
    ],
    victory: [
      'Как ты быстро начал выполнять это задание! Теперь ты готов к новой миссии!',
      'Тренировка помогает! Молодец! Мистер Фоксман ждёт тебя для выполнения новой миссии.'
    ]
  },
  textsInMission: {
    description: [
      'Чтобы добыть важную информацию, необходимо запомнить картинки и вернуть их строго на свои места. Ничего не перепутай!',
      'И вновь тайный шифр! Тебе предстоит все картинки вернуть на свои места. Запомни их расположение!'
    ],
    losing: [
      'Что-то перепуталось! Мистер Фоксман ждёт тебя в тренажёрном зале!',
      'Спецагент никогда не сдаётся. Потренируйся, и всё обязательно получится!'
    ],
    victory: [
      'Отлично! Все картинки на своих местах. Суперпамять у суперагента!',
      'Ты справился! Тебя уже ждёт новое задание!'
    ]
  }
};
