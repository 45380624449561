import getRandomIndex from '~/services/get-random-index';

const scenario = ['memory', 'logic', 'mind', 'focus', 'imagination', 10];

export default (trainersBySkill) => {
  return scenario.reduce((acc, item) => {
    let trainer = item;

    if (typeof item === 'string') {
      const trainersArray = trainersBySkill[item];
      const randomIndex = getRandomIndex(trainersArray);
      trainer = trainersArray[randomIndex];
    }

    return [ ...acc, trainer ];
  }, []);
};
