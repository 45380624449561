import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Page from '~/containers/Page/Page';
import MissionsEndTop from '~/components/MissionsEndTop/MissionsEndTop';
import MissionsEndLevel from '~/components/MissionsEndLevel/MissionsEndLevel';
import MissionsEndSkills from '~/components/MissionsEndSkills/MissionsEndSkills';

import * as pageActions from '~/store/page/actions';

import { skillLevelNameSelector, nextSkillLevelNameSelector,
         giftsStatusSelector, levelMaxValueSelector } from '~/store/profile/selector';
import { linkLastOpenMissionSelector } from '~/store/locations/selector';

import cloud from './assets/cloud.png';
import './missions-end-page.scss';

const MissionsEndPage = (props) => {
  const { gameLink, location, history } = props;

  if (location.pathname !== gameLink) {
    history.push(gameLink);
  }

  return (
    <Page theme="white">
      <div className="missions-end-page">
        <div className="missions-end-page__holder">
          <img className="missions-end-page__cloud1" alt="Облако1" src={ cloud } />
          <img className="missions-end-page__cloud2" alt="Облако2" src={ cloud } />
          <MissionsEndTop />
          <MissionsEndSkills { ...props }/>
          <MissionsEndLevel { ...props }/>
        </div>
      </div>
    </Page>
  );
}

function mapStateToProps(state) {
  const { profile } = state;
  return {
    profile,
    skillLevelName: skillLevelNameSelector(state),
    nextSkillLevelName: nextSkillLevelNameSelector(state),
    giftsStatus: giftsStatusSelector(state),
    levelMaxValue: levelMaxValueSelector(state),
    gameLink: linkLastOpenMissionSelector(state)
  };
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MissionsEndPage));
