import React, { useEffect } from 'react';

import Button from '~/components/Button/Button';

import scrollTop from '~/services/scroll-top';

import './fail-screen.scss';

const FailScreen = ({ buttonText, buttonOnClick }) => {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className="fail-screen">
      <div className="fail-screen__holder">
        <div className="fail-screen__text-holder">
          <div className="fail-screen__text">
            Увы, не все задания миссии были выполнены.<br/>
            Попробуй потренироваться на тренажёрах и приходи снова!
          </div>
          <Button link='/trainers' className="fail-screen__button">
            открыть тренажеры
          </Button>
        </div>
        <Button onClick={ buttonOnClick } theme='grey' className="fail-screen__button">
          { buttonText }
        </Button>
      </div>
    </div>
  )
};

export default FailScreen;
