import React, { Component, Fragment } from 'react';
import propTypes from './prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { withRouter } from 'react-router';
import cx from 'classnames';

import TrainerHeader from '~/components/TrainerHeader/TrainerHeader';
import TrainerFooter from '~/components/TrainerFooter/TrainerFooter';
import TrainerScreenWrapper from '~/components/TrainerScreenWrapper/TrainerScreenWrapper';
import Button from '~/components/Button/Button';

import TrainerLongMemoryTask from '~/trainers/TrainerLongMemory/TrainerLongMemoryTask';

import * as locationsActions from '~/store/locations/actions';
import * as profileActions from '~/store/profile/actions';
import * as pageActions from '~/store/page/actions';

import getMissionIdBySlug from '~/services/get-mission-id-by-slug';
import getStyleProperties from '~/services/get-style-properties';
import detectDevice from '~/services/detect-device';

import { levelMaxValueSelector } from '~/store/profile/selector';
import { getCurrentMission } from '~/store/trainers/selector';

import calculateMaxSkillsForMission from './utils/calculateMaxSkillsForMission';
import calculateLongMemoryData from './utils/calculateLongMemoryData';
import getDifficultyParams from '~/services/get-difficulty-params';
import trainersMap from '~/services/trainers-map';
import getEventLabel from './utils/get-event-label';
import { STEP_TEST, BUTTON_NEXT } from '~/utils/constants';

import scrollTop from '~/services/scroll-top';

import './trainerWrapper.scss';

class TrainerWrapper extends Component {
  state = {
    isTimerActive: false,
    isVideoViewed: false,
    resultButton: false,
    isPopupShow: true,
    popupName: 'description',
    activeStage: 1,
    earnedSkills: {},
    isFinal: false,
    isRefresh: false,
    widthWorkspace: 0,
    heightWorkspace: 0,
    nodeWorkspace: null,
    trainer: {}
  };

  componentDidMount() {
    const { trainerIds, currentTrainerIndex, longMemory, setShownLongMemoryTask,
            trainers, isInMission, hasMissionPopupShown } = this.props;

    scrollTop();

    window.addEventListener('resize', this.handleResize);

    if (isInMission && !hasMissionPopupShown) {
      this.setState({ popupName: 'missionDescription' });
    }

    /** Определяем показ попапов перед тренажером (описание и видео) */
    const trainerId = trainerIds[currentTrainerIndex];
    const trainer = trainers[trainerId];
    const { params } = trainer;

    if (params.doNotShowDescription) {
      this.setState({ popupName: 'video' });
    }

    if (params.doNotShowVideo) {
      this.setState({ isPopupShow: false, popupName: '' });
    }

    if (!longMemory) {
      setShownLongMemoryTask();
    }

    this.setState({ trainer });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isTimerActive !== prevState.isTimerActive || this.state.isPopupShow !== prevState.isPopupShow) {
      scrollTop();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { nodeWorkspace } = this.state;
    const { isMobile } = detectDevice();

    if (isMobile && nodeWorkspace) {
      this.setWorkspace({ node: nodeWorkspace });
    }
  }

  setWorkspace = ({ node=null }) => {
    const { isMobile } = detectDevice();
    const { paddingLeft, paddingTop } = getStyleProperties(node);
    const header = document.querySelector('.trainer-header');
    const footer = document.querySelector('.trainer-footer');
    const widthWorkspace = node.offsetWidth - 2 * paddingLeft;
    let heightWorkspace = node.offsetHeight - 2 * paddingTop;

    if (isMobile && footer && header) {
      heightWorkspace = window.innerHeight - 2 * paddingTop - header.clientHeight - footer.clientHeight;
    }

    this.setState({ widthWorkspace, heightWorkspace });
  }

  onStartTrainer = () => {  this.setState({ isTimerActive: true }); };

  onLongMemoryTaskShown = () => {
    const { setShownLongMemoryTask } = this.props;
    this.setState({ isTimerActive: false });
    setShownLongMemoryTask();
  }

  onFinishTrainer = (trainer) => {
    const { victory, needButton, reason, skills } = trainer;
    const { isInMission, currentTrainerIndex = 0, saveTrainerProgress, updateMissionStatus,
            upgradeSkill, downgrateSkill } = this.props;
    const { mainSkill } = this.state.trainer.params;
    const resultButton = needButton === undefined ? true : needButton;
    const popupName = reason === 'partially' ? 'partially' : victory ? 'victory' : 'losing';

    saveTrainerProgress({ skills, isInMission, mainSkill });

    if (skills) {
      upgradeSkill(mainSkill);
    }
    else {
      downgrateSkill(mainSkill);
    }

    if (isInMission) {
      updateMissionStatus(reason, currentTrainerIndex, skills, mainSkill);
    }

    this.setState({ isTimerActive: false, resultButton, popupName, earnedSkills: skills });
  };

  onFinishMission = () => {
    const { saveMissionProgress, trainerIds, missionSlug, locationSlug, saveMissionParams,
            missionSkills, missionStatus, openNextMission, missionId, currentMission } = this.props;
    const points = trainerIds.length;
    const missionSuccess = missionStatus.every((item) => item === 'partially' || item === 'complete');

    if (missionSuccess) {
      openNextMission(locationSlug, missionSlug);
      saveMissionParams({ trainerIds: currentMission }, missionId);
      saveMissionProgress({ points, missionId, missionSkills });
    }

    this.setState({ popupName: missionSuccess ? 'missionSuccess' : 'missionLosing' });
  }

  onTimeEnd = (isFinal) => { this.setState({ isTimerActive: false, isFinal }); };

  onStageChangeHandler = (stage) => {
    this.setState(({ activeStage }) => ({ activeStage: stage || activeStage + 1 }));
  };

  closeVideoPopup = (startsImmediately) => {
    this.setState({ isPopupShow: false }, () => {
      if (startsImmediately) {
        this.onStartTrainer();
      }
    });
  };

  togglePopup = () => {
    this.props.popPopup();
    this.setState(({ isPopupShow }) => ({ isPopupShow: !isPopupShow }));
  }

  changePopup = (type, isHard) => {
    let isPopupShow = true;
    const { longMemory, setMissionPopupShown } = this.props;
    const { trainer, isRefresh, popupName } = this.state;

    if (type === 'video') {
      const { runnedTrainersIds, saveRunnedTrainer } = this.props;

      if(isRefresh) this.discardRefresh();

      if (!runnedTrainersIds.includes(trainer.id) && trainer.videoAnnounceUrl) {
        saveRunnedTrainer(trainer.id)
      } else if(!isHard) {
        this.setState({ isPopupShow: false });
        this.onStartTrainer();
        return;
      }
    }

    if (popupName === 'missionDescription') {
      setMissionPopupShown(true);
      if (longMemory) {
        isPopupShow = false;
      }
    }

    this.setState({ popupName: type, isPopupShow });
  };

  refreshTrainer = () => {
    const { setTrainerStage } = this.props;
    setTrainerStage(STEP_TEST);
    this.setState({
      isTimerActive: false,
      isVideoViewed: false,
      resultButton: false,
      isPopupShow: true,
      popupName: 'description',
      isFinal: false,
      isRefresh: true,
      activeStage: 1
    })
  }

  discardRefresh = () => { this.setState({ isRefresh: false }) }

  goToPage = (url) => {
    const { replace } = this.props;
    replace(url);
  }

  goToLocationPage = () => {
    const { locationSlug, replace } = this.props;
    if (!locationSlug) return;
    replace(`/locations/${locationSlug}/`);
  };

  getStageText = () => {
    const { longMemoryData, longMemory, hasShownLongMemoryTask } = this.props;
    const { popupName, isPopupShow } = this.state;

    if (isPopupShow) {
      const variants = {
        losing: 'Задание не выполнено.',
        partially: 'Задание выполнено!',
        victory: 'Задание выполнено!',
        description: 'Прочти задание',
        missionLosing: 'Миссия провалена.',
        missionSuccess: 'Миссия выполнена!',
        missionDescription: 'Прочти напутствие'
      }
      return variants[popupName];
    } else if (!hasShownLongMemoryTask && longMemory && longMemoryData) {
      return 'Запоминай!'
    }
  }

  getStageAmount = () => {
    const { missionId, profile } = this.props;
    const { trainer } = this.state;
    const difficultyParams = getDifficultyParams(trainer, missionId, profile);
    const stageAmount = trainer.params.stageAmount || difficultyParams.stageAmount;
    const amountStages = stageAmount ? stageAmount : 1;

    return { amountStages, difficultyParams };
  };

  handleGiveUp = () => {
    const { isInMission, replace, goToNextTrainer, currentTrainerIndex, trainerIds,
            setTrainerStage, step } = this.props;
    const { activeStage, trainer, isTimerActive } = this.state;
    const { amountStages, difficultyParams } = this.getStageAmount();
    const { currentLevel } = difficultyParams;
    const { subStageAmount } = trainer.params;
    const eventLabel = getEventLabel({ currentLevel, amountStages, isTimerActive, activeStage, step, isInMission, subStageAmount });

    this.handleDataLayer({ eventAction: 'give_up', eventLabel });

    setTrainerStage(STEP_TEST);
    this.onFinishTrainer({ reason: 'fail', skills: null });

    if(isInMission) {
      if(trainerIds.length - 1 === currentTrainerIndex) {
        this.togglePopup();
        this.onFinishMission();
      }
      else {
        goToNextTrainer();
      }
    } else {
      replace(`/trainers/`);
    }
  }

  handleEndTrainer = () => {
    const { amountStages } = this.getStageAmount();
    const stageName = amountStages > 2 ? amountStages : '';

    this.handleDataLayer({ stageName: `page3_check${ stageName }` });
    this.togglePopup();
  };

  handleDataLayer = (params) => {
    const { event='click', eventAction='next', stageName, stageLevel='', eventLabel, eventValue } = params;
    const { trainer } = this.state;
    const { isInMission } = this.props;
    const { eventCategory } = trainer;
    const { difficultyParams } = this.getStageAmount();
    const { currentLevel } = difficultyParams;
    const suffixDataLayer = isInMission ? '_mission': '';
    const label = eventLabel || `${ stageName }${ stageLevel }_lvl${ currentLevel }${ suffixDataLayer }`;
    const result = { event, eventCategory, eventAction, eventLabel: label };

    if (eventValue) {
      result.eventValue = eventValue;
    }

    window.dataLayer.push(result);
  };

  createRefComponent = (node) => {
    if (node) {
      this.setWorkspace({ node });
      this.setState({ nodeWorkspace: node });
    }
  }

  handleHelpClick = () => {
    const { isInMission, step } = this.props;
    const { activeStage, trainer, isTimerActive } = this.state;
    const { amountStages, difficultyParams } = this.getStageAmount();
    const { currentLevel } = difficultyParams;
    const { subStageAmount } = trainer.params;
    const eventLabel = getEventLabel({ currentLevel, amountStages, isTimerActive, activeStage, step, isInMission, subStageAmount });

    this.handleDataLayer({ eventAction: 'help', eventLabel });
  };

  render() {
    const { trainers, trainerIds, currentTrainerIndex, hasShownLongMemoryTask, profile, levelMaxValue,
            longMemory, isInMission, locations, missionSlug, locationSlug, wins, missionStatus, missionSkills,
            completedMissions, saveLongMemoryData, longMemoryData, goToNextTrainer, setTrainerStage,
            missionId, adviceSkills } = this.props;
    const { popupName, isPopupShow, earnedSkills, isFinal, widthWorkspace, heightWorkspace } = this.state;
    const isLongMemoryViewing = !hasShownLongMemoryTask && longMemory && longMemoryData && !isPopupShow && popupName !== 'missionDescription';
    const trainerId = isLongMemoryViewing ? trainerIds[trainerIds.length - 1] : trainerIds[currentTrainerIndex];
    const trainer = trainers[trainerId];
    const { stageAmount, subStageAmount, isShowStage, notMaxWidth } = trainer.params;
    const difficultyParams = getDifficultyParams(trainer, missionId, profile);

    if (longMemory && !longMemoryData) {
      const { places } = trainers[10].params;
      const difficultyParamsLM = getDifficultyParams(trainers[10], missionId, profile);
      const { longMemoryTaskRememberedItems, longMemoryTaskParams } = calculateLongMemoryData({ ...difficultyParamsLM, places: places[locationSlug] });
      saveLongMemoryData({ longMemoryTaskRememberedItems, longMemoryTaskParams });
    }

    return (
      <Fragment>
        <div
          className={cx('trainer-wrapper', {
            'trainer-wrapper_show-popup': isPopupShow,
            'trainer-wrapper_mission': isInMission,
            'trainer-wrapper_video': isPopupShow && popupName === 'video' && trainer && hasShownLongMemoryTask,
          })}
        >
          {/* Показываем хэдер для тренажеров */}
          {
            trainer && (
              <TrainerHeader
                trainerParams={ isLongMemoryViewing ? longMemoryData.longMemoryTaskParams : trainer.params }
                timerIsActive={ this.state.isTimerActive }
                timerOnEnded={ this.onTimeEnd }
                isRefresh={ this.state.isRefresh }
                changeStage={ this.onStageChangeHandler }
                isInMission={ isInMission }
                currentTrainerIndex={ currentTrainerIndex }
                stageAmount={ stageAmount || difficultyParams.stageAmount }
                subStageAmount={ subStageAmount }
                isShowStage={ isShowStage }
                locationName={ locationSlug ? locations[locationSlug].city : null }
                missionName={ missionSlug ? locations[locationSlug].missions[missionSlug].name : null }
                missionStatus={ missionStatus }
                isPopupShow={ isPopupShow }
                isLongMemory={ isLongMemoryViewing }
                stageText={ this.getStageText() }
              />
            )
          }
          {/* /Показываем хэдер для тренажеров */}

          <div className={ cx("trainer-wrapper__main", {
            "trainer-wrapper__main_mission": popupName === 'missionSuccess' || popupName === 'missionLosing',
            'trainer-wrapper__main_centered': trainer.isCenteredContent && !isPopupShow
          }) }>
            {/* Показываем long memory task */}
            {
              isLongMemoryViewing && (
                <TrainerLongMemoryTask
                  setTrainerStage={ setTrainerStage }
                  isTimerActive={ this.state.isTimerActive }
                  onStartCb={ this.onStartTrainer }
                  className="trainer-wrapper__component"
                  config={ longMemoryData.longMemoryTaskRememberedItems }
                  onFinishCb={ this.onLongMemoryTaskShown }
                />
              )
            }
            {/* /Показываем long memory task */}

            {/* Показываем тренажеры */}
            {
              isPopupShow ?
              /* Попапы, связанные с тренажером */
              trainer && (
                <TrainerScreenWrapper
                  mission={ isInMission ? locations[locationSlug].missions[missionSlug] : null }
                  trainer={ { ...trainer, difficultyParams } || null }
                  popupName={ popupName }
                  currentTrainerIndex={ currentTrainerIndex }
                  trainerIds={ trainerIds }
                  refreshTrainer={ this.refreshTrainer }
                  goToLocationPage={ this.goToLocationPage }
                  goToTrainersPage={ () => this.goToPage('/trainers/') }
                  goToNextTrainer={ goToNextTrainer }
                  onFinishMission={ this.onFinishMission }
                  changePopup={ this.changePopup }
                  isPopupShow={ isPopupShow }
                  closeVideoPopup={ this.closeVideoPopup }
                  closeSuccessPopup={ this.togglePopup }
                  isLastTrainer={ currentTrainerIndex + 1 === trainerIds.length }
                  isWinMission={ wins === trainerIds.length }
                  profile={ profile }
                  levelMaxValue={ levelMaxValue }
                  missionSkills={ missionSkills }
                  earnedSkills={ earnedSkills }
                  missionStatus={ missionStatus }
                  adviceSkills={ adviceSkills }
                  completedMissions={ completedMissions }
                  maxSkillsForMission={ trainers ? calculateMaxSkillsForMission(trainers, trainerIds) : null }
                  handleDataLayer={ this.handleDataLayer }
                />
              )

              :

              trainer && hasShownLongMemoryTask &&
              [trainer].map((item) => {
                const Component = trainersMap[item.type];
                const classesComponent = cx('trainer-wrapper__component', {
                  'trainer-wrapper__component_not-max-width': notMaxWidth
                });

                return (
                  <div key={ item.id } ref={ this.createRefComponent } className={ classesComponent }>
                    <Component
                      isTimerActive={ this.state.isTimerActive }
                      params={ { ...trainer.params, difficultyParams } }
                      onStartCb={ this.onStartTrainer }
                      onFinishCb={ this.onFinishTrainer }
                      onShowPopup={ this.togglePopup }
                      onTimeEnd={ this.onTimeEnd }
                      isRefresh={ this.state.isRefresh }
                      isInMission={ isInMission }
                      onStageChange={ this.onStageChangeHandler }
                      activeStage={ this.state.activeStage }
                      resultButton={ this.state.resultButton }
                      longMemoryTaskData={ longMemoryData ? longMemoryData.longMemoryTaskRememberedItems : null }
                      isFinal={ isFinal }
                      isPopupShow={ isPopupShow }
                      locationSlug={ locationSlug }
                      widthWorkspace={ widthWorkspace }
                      heightWorkspace={ heightWorkspace }
                      handleDataLayer={ this.handleDataLayer }
                    />
                  </div>
                );
              })
            }
            {/* /Показываем тренажеры */}
          </div>

          {
            !isPopupShow &&
            <TrainerFooter
              isInMission={ isInMission }
              tooltipContent={ trainer ? difficultyParams.tooltipContent : '' }
              isMiniTooltip={ difficultyParams.isMiniTooltip }
              openVideo={ trainer.videoAnnounceUrl ? () => this.changePopup('video', true) : null }
              giveUpHandler={ this.handleGiveUp }
              handleHelpClick={ this.handleHelpClick }
              isLongMemoryViewing={ isLongMemoryViewing }
            >
              {
                this.state.resultButton && !this.state.isPopupShow && (
                  <Button
                    onClick={ this.handleEndTrainer }
                    className="trainer-wrapper__button button_center"
                    cy='to-results'
                  >
                    { BUTTON_NEXT }
                  </Button>
                )
              }
            </TrainerFooter>
          }

        </div>
      </Fragment>
    );
  }
}

TrainerWrapper.defaultProps = {
  locationSlug: '',
};

TrainerWrapper.propTypes = propTypes;

const mapDispatchToProps = {
  saveMissionProgress: profileActions.saveMissionProgress,
  saveMissionParams: profileActions.saveMissionParams,
  saveTrainerProgress: profileActions.saveTrainerProgress,
  goToNextTrainer: locationsActions.goToNextTrainer,
  setShownLongMemoryTask: locationsActions.setShownLongMemoryTask,
  replace,
  onFinish: locationsActions.onFinish,
  saveLongMemoryData: locationsActions.saveLongMemoryData,
  saveRunnedTrainer: profileActions.saveRunnedTrainer,
  setTrainerStage: locationsActions.setTrainerStage,
  setMissionPopupShown: locationsActions.setMissionPopupShown,
  openNextMission: locationsActions.openNextMission,
  upgradeSkill: profileActions.upgradeSkill,
  downgrateSkill: profileActions.downgrateSkill,
  popPopup: pageActions.popPopup
};

const mapStateToProps = (state, props) => ({
  locations: state.locations.data,
  trainers: state.trainers.data,
  currentTrainerIndex: state.locations.currentTrainerIndex,
  hasShownLongMemoryTask: state.locations.hasShownLongMemoryTask,
  profile: state.profile,
  levelMaxValue: levelMaxValueSelector(state),
  completedMissions: state.profile.completedMissions,
  longMemoryData: state.locations.longMemoryData,
  runnedTrainersIds: state.profile.runnedTrainersIds,
  hasMissionPopupShown: state.locations.hasMissionPopupShown,
  missionId: getMissionIdBySlug(state, props),
  currentMission: getCurrentMission(state),
  step: state.locations.currentTrainerStage
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrainerWrapper));
