import React from 'react';
import cx from 'classnames';
import Icon from '~/components/Icon/Icon';

/* eslint-disable */
import triangle from './assets/triangle.svg';
/* eslint-enable */

import './possiblePoints.scss'

const PossiblePoints = ({ points, total, warning }) => {
  return (
    <div
      className={cx("possible-points", {
        'possible-points_warning': warning,
      })}>
      { points } из { total }
      {
        warning &&
        <Icon
          className="possible-points__triangle"
          name="triangle"
        />
      }
    </div>
  )
}

export default PossiblePoints;
