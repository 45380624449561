export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 301,
        y: 81
      },
      tablet: {
        x: 302,
        y: 76
      },
      mobile: {
        x: 211,
        y: 53
      }
    },
    {
      desktop: {
        x: 256,
        y: 126
      },
      tablet: {
        x: 262,
        y: 116
      },
      mobile: {
        x: 241,
        y: 83
      }
    },
    {
      desktop: {
        x: 301,
        y: 171
      },
      tablet: {
        x: 301.5,
        y: 155.5
      },
      mobile: {
        x: 220,
        y: 104
      }
    },
    {
      desktop: {
        x: 224.5,
        y: 247.5
      },
      tablet: {
        x: 232.5,
        y: 224.5
      },
      mobile: {
        x: 220,
        y: 161.43
      }
    },
    {
      desktop: {
        x: 171.32,
        y: 194.32
      },
      tablet: {
        x: 186.28,
        y: 178.28
      },
      mobile: {
        x: 244.21,
        y: 161.43
      }
    },
    {
      desktop: {
        x: 108,
        y: 257.64
      },
      tablet: {
        x: 130,
        y: 234.57
      },
      mobile: {
        x: 244,
        y: 161.21
      }
    },
    {
      desktop: {
        x: 171,
        y: 257.46
      },
      tablet: {
        x: 186,
        y: 234.57
      },
      mobile: {
        x: 285.82,
        y: 160.61
      }
    },
    {
      desktop: {
        x: 171,
        y: 374
      },
      tablet: {
        x: 186,
        y: 338
      },
      mobile: {
        x: 328.85,
        y: 203.64
      }
    },
    {
      desktop: {
        x: 216,
        y: 329
      },
      tablet: {
        x: 226,
        y: 298
      },
      mobile: {
        x: 286.43,
        y: 203.64
      }
    },
    {
      desktop: {
        x: 305.36,
        y: 329
      },
      tablet: {
        x: 304.43,
        y: 298
      },
      mobile: {
        x: 244.85,
        y: 162.07
      }
    },
    {
      desktop: {
        x: 351.28,
        y: 374.92
      },
      tablet: {
        x: 344.14,
        y: 337.71
      },
      mobile: {
        x: 244.85,
        y: 246.28
      }
    },
    {
      desktop: {
        x: 351.28,
        y: 248.1
      },
      tablet: {
        x: 344.14,
        y: 225.42
      },
      mobile: {
        x: 214.57,
        y: 216
      }
    },
    {
      desktop: {
        x: 414.64,
        y: 311.46
      },
      tablet: {
        x: 399.57,
        y: 280.85
      },
      mobile: {
        x: 158,
        y: 216
      }
    },
    {
      desktop: {
        x: 478.28,
        y: 311.46
      },
      tablet: {
        x: 456.14,
        y: 280.85
      },
      mobile: {
        x: 127,
        y: 247
      }
    },
    {
      desktop: {
        x: 414.64,
        y: 247.82
      },
      tablet: {
        x: 399.57,
        y: 224.28
      },
      mobile: {
        x: 127,
        y: 169.43
      }
    },
    {
      desktop: {
        x: 351.28,
        y: 247.82
      },
      tablet: {
        x: 343,
        y: 224.28
      },
      mobile: {
        x: 85,
        y: 169.43
      }
    },
    {
      desktop: {
        x: 351.28,
        y: 247.64
      },
      tablet: {
        x: 343.28,
        y: 224.57
      },
      mobile: {
        x: 127.21,
        y: 127.21
      }
    },
    {
      desktop: {
        x: 314,
        y: 247.64
      },
      tablet: {
        x: 312,
        y: 224.57
      },
      mobile: {
        x: 161.5,
        y: 161.5
      }
    },
    {
      desktop: {
        x: 314,
        y: 158
      },
      tablet: {
        x: 312,
        y: 146
      },
      mobile: {
        x: 210.5,
        y: 112.5
      }
    },
    {
      desktop: {
        x: 346,
        y: 126
      },
      tablet: {
        x: 342,
        y: 116
      },
      mobile: {
        x: 181,
        y: 83
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 351,
        y: 247.82,
        rotation: 45
      },
      tablet: {
        x: 343,
        y: 224.28,
        rotation: 45
      },
      mobile: {
        x: 244,
        y: 161.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 171,
        y: 194,
        rotation: -0
      },
      tablet: {
        x: 186,
        y: 178,
        rotation: 0
      },
      mobile: {
        x: 127,
        y: 127,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 224,
        y: 247.64,
        rotation: 45
      },
      tablet: {
        x: 231,
        y: 224.57,
        rotation: 45
      },
      mobile: {
        x: 160,
        y: 161.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 224,
        y: 158,
        rotation: 0
      },
      tablet: {
        x: 232,
        y: 145,
        rotation: 0
      },
      mobile: {
        x: 160,
        y: 103,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 139.82,
        y: 289.46,
        rotation: 135
      },
      tablet: {
        x: 158.28,
        y: 262.85,
        rotation: 135
      },
      mobile: {
        x: 106.21,
        y: 190.64,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 306,
        y: 284,
        rotation: -90
      },
      tablet: {
        x: 305,
        y: 258,
        rotation: -90
      },
      mobile: {
        x: 216,
        y: 186,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 256,
        y: 81,
        rotation: 0
      },
      tablet: {
        x: 262,
        y: 76,
        rotation: 0
      },
      mobile: {
        x: 181,
        y: 53,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
