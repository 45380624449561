import React from 'react';
import { Link } from 'react-router-dom';

import TooltipButton from '~/components/TooltipButton/TooltipButton';

import './trainerCardBack.scss';

const TrainerCardBack = (props) => {
  const { tooltipAnnounce, id, switchTooltip, tooltip } = props;
  return (
    <div className='trainer-card-back'>
      <header className="trainer-card-back__header">
        <h2 className="trainer-card-back__title">
          Описание задания
        </h2>
        { renderTooltipButton({ iconName: "close-tooltip", switchTooltip }) }
      </header>

      <p className="trainer-card-back__text">
        { tooltipAnnounce }
      </p>

      {
        tooltip &&
        <Link to={`/trainers/${id}/`} className="trainer-card-back__start">
          Играть
        </Link>
      }
    </div>
  )
};

const renderTooltipButton = ({ iconName, switchTooltip }) => (
  <div className="trainer-card-back__button">
    <TooltipButton onClick={ switchTooltip } iconName={ iconName } />
  </div>
);

export default TrainerCardBack;
