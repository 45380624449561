export default {
  image: require('~/static/trainers/funnyPoints/stork.png'),
  width: 800,
  height: 919,
  offsetY: 30,
  offsetX: 10,
  points: [
    { id: 1,  x: -195, y: 291 },
    { id: 2,  x: -168, y: 322 },
    { id: 3,  x: -140, y: 340 },
    { id: 4,  x: -17,  y: 268 },
    { id: 5,  x: 82,   y: 222 },
    { id: 6,  x: 208,  y: 232 },
    { id: 7,  x: 303,  y: 261 },
    { id: 8,  x: 240,  y: 302 },
    { id: 9,  x: 188,  y: 305 },
    { id: 10, x: 225,  y: 328 },
    { id: 11, x: 160,  y: 342 },
    { id: 12, x: 99,   y: 337 },
    { id: 13, x: 133,  y: 362 },
    { id: 14, x: 51,   y: 380 },
    { id: 15, x: -31,  y: 386 },
    { id: 16, x: 20,   y: 430 },
    { id: 17, x: 70,   y: 506 },
    { id: 18, x: 169,  y: 537 },
    { id: 19, x: 191,  y: 581 },
    { id: 20, x: 185,  y: 608 },
    { id: 21, x: 159,  y: 619 },
    { id: 22, x: 110,  y: 609 },
    { id: 23, x: 54,   y: 527 },
    { id: 24, x: -32,  y: 497 },
    { id: 25, x: -88,  y: 462 },
    { id: 26, x: -67,  y: 544 },
    { id: 27, x: -65,  y: 627 },
    { id: 28, x: -98,  y: 604 },
    { id: 29, x: -78,  y: 659 },
    { id: 30, x: -73,  y: 723 },
    { id: 31, x: -105, y: 695 },
    { id: 32, x: -93,  y: 748 },
    { id: 33, x: -112, y: 816 },
    { id: 34, x: -172, y: 733 },
    { id: 35, x: -215, y: 621 },
    { id: 36, x: -198, y: 509 },
    { id: 37, x: -164, y: 370 },
    { id: 38, x: -189, y: 348 },
    { id: 39, x: -226, y: 336 },
    { id: 40, x: -310, y: 237 }
  ]
};
