export default {
  shapes: [ 4, 3, 6, 7, 5, 8, 1 ],
  offset: {
    mobile: 15
  },
  outline: [
    {
      desktop: { x: 226, y: 179.3 },
      tablet: { x: 233, y: 164 },
      mobile: { x: 162, y: 118 },
    },
    {
      desktop: { x: 136, y: 179.3 },
      tablet: { x: 193, y: 124 },
      mobile: { x: 132, y: 88 },
    },
    {
      desktop: { x: 181, y: 134.3 },
      tablet: { x: 153, y: 164 },
      mobile: { x: 102, y: 118 },
    },
    {
      desktop: { x: 226, y: 179.3 },
      tablet: { x: 233, y: 164 },
      mobile: { x: 162, y: 118 },
    },
    {
      desktop: { x: 181, y: 224.3 },
      tablet: { x: 193, y: 204 },
      mobile: { x: 132, y: 148 },
    },
    {
      desktop: { x: 226, y: 269.3 },
      tablet: { x: 232.5, y: 244 },
      mobile: { x: 162, y: 178 },
    },
    {
      desktop: { x: 136, y: 269.3 },
      tablet: { x: 153, y: 244 },
      mobile: { x: 102, y: 178 },
    },
    {
      desktop: { x: 181, y: 313.8 },
      tablet: { x: 193, y: 284 },
      mobile: { x: 132, y: 208 },
    },
    {
      desktop: { x: 226, y: 269.3 },
      tablet: { x: 232.5, y: 244 },
      mobile: { x: 162, y: 178 },
    },
    {
      desktop: { x: 271, y: 314 },
      tablet: { x: 273, y: 284 },
      mobile: { x: 192, y: 208 },
    },
    {
      desktop: { x: 317, y: 268 },
      tablet: { x: 312.6, y: 244 },
      mobile: { x: 222, y: 178 },
    },
    {
      desktop: { x: 317, y: 358 },
      tablet: { x: 313, y: 324 },
      mobile: { x: 222, y: 238 },
    },
    {
      desktop: { x: 362, y: 403 },
      tablet: { x: 353, y: 364 },
      mobile: { x: 252, y: 268 },
    },
    {
      desktop: { x: 362, y: 313 },
      tablet: { x: 353, y: 284 },
      mobile: { x: 252, y: 207 },
    },
    {
      desktop: { x: 317, y: 268 },
      tablet: { x: 312.6, y: 244 },
      mobile: { x: 222, y: 178 },
    },
    {
      desktop: { x: 361, y: 224 },
      tablet: { x: 353, y: 204 },
      mobile: { x: 252, y: 148 },
    },
    {
      desktop: { x: 406, y: 269 },
      tablet: { x: 393, y: 244 },
      mobile: { x: 282, y: 178 },
    },
    {
      desktop: { x: 451, y: 224 },
      tablet: { x: 433, y: 204 },
      mobile: { x: 312, y: 148 },
    },
    {
      desktop: { x: 406, y: 179 },
      tablet: { x: 393, y: 164 },
      mobile: { x: 282, y: 118 },
    },
    {
      desktop: { x: 361, y: 224 },
      tablet: { x: 353, y: 204 },
      mobile: { x: 252, y: 148 },
    },
    {
      desktop: { x: 316, y: 179 },
      tablet: { x: 312.6, y: 163.5 },
      mobile: { x: 222.5, y: 118.5 },
    },
    {
      desktop: { x: 380.2, y: 116.6 },
      tablet: { x: 369, y: 107 },
      mobile: { x: 264.8, y: 76 },
    },
    {
      desktop: { x: 317.5, y: 52 },
      tablet: { x: 312.6, y: 50.5 },
      mobile: { x: 222.4, y: 33.5 },
    },
    {
      desktop: { x: 316, y: 179 },
      tablet: { x: 312.6, y: 163.5 },
      mobile: { x: 222.5, y: 118.5 },
    },
    {
      desktop: { x: 271, y: 134.3 },
      tablet: { x: 273, y: 124 },
      mobile: { x: 192, y: 88 },
    },
  ],
  outlines: [
    {
      desktop: { x: 226, y: 134 },
      tablet: { x: 233, y: 124 },
      mobile: { x: 162, y: 88 },
      rotation: 90
    },
    {
      desktop: { x: 136, y: 314 },
      tablet: { x: 153, y: 284 },
      mobile: { x: 102, y: 208 },
      rotation: 270
    },
    {
      desktop: { x: 271, y: 314 },
      tablet: { x: 273, y: 284 },
      mobile: { x: 192, y: 208 },
      rotation: 180
    },
    {
      desktop: { x: 271, y: 134 },
      tablet: { x: 273, y: 124 },
      mobile: { x: 192, y: 88 },
      rotation: 0
    },
    {
      desktop: { x: 253, y: 115 },
      tablet: { x: 256, y: 107 },
      mobile: { x: 180, y: 76 },
      rotation: 315
    },
    {
      desktop: { x: 317, y: 268 },
      tablet: { x: 313, y: 244 },
      mobile: { x: 222, y: 178 },
      rotation: 0
    },
    {
      desktop: { x: 361, y: 179 },
      tablet: { x: 353, y: 164 },
      mobile: { x: 252, y: 118 },
      rotation: 0
    }
  ]
}
