import getRandomIndex from '~/services/get-random-index';

// Транспонирование всего поля
const transposing = ({ field, size }) => {
  for(let i = 0; i < size; i++) {
    for(let j = 0; j < i; j++) {
      const swapElement = field[i][j];
      field[i][j] = field[j][i];
      field[j][i] = swapElement;
    }
  }
  return field;
};

// Генерация рандомных индексов для строк или столбцов в 1 районе
const _generateRandomIndexes = (sizeZone) => {
  const zoneNumber = getRandomIndex(sizeZone - 1);
  const { N1: lineNumber1, N2: lineNumber2 } = _generateRandomZoneIndexes(sizeZone);

  const N1 = zoneNumber * sizeZone + lineNumber1;
  const N2 = zoneNumber * sizeZone + lineNumber2;

  return { N1, N2 };
}

// Генерация рандомных индексов районов
const _generateRandomZoneIndexes = (sizeZone) => {
  let N1 = getRandomIndex(sizeZone - 1);
  let N2 = getRandomIndex(sizeZone - 1);

  while(N1 === N2) N2 = getRandomIndex(sizeZone - 1);

  return { N1, N2 };
};

// Обмен двух строк в пределах одного района
const swapRowsSmall = ({ field, size }) => {
  const sizeZone = Math.sqrt(size);
  const { N1, N2 } = _generateRandomIndexes(sizeZone);
  const swapElement = field[N1];

  field[N1] = field[N2];
  field[N2] = swapElement;

  return field;
};

// Обмен двух столбцов в пределах одного района
const swapColumnsSmall = ({ field, size }) => {
  const sizeZone = Math.sqrt(size);
  const { N1, N2 } = _generateRandomIndexes(sizeZone);

  for(let i = 0; i < size; i ++) {
    const swapElement = field[i][N1];

    field[i][N1] = field[i][N2];
    field[i][N2] = swapElement;
  }

  return field;
};

// Обмен двух строк районов по горизонтали
const swapRowsZone = ({ field, size }) => {
  const sizeZone = Math.sqrt(size);
  const { N1: zoneNumber1, N2: zoneNumber2 } = _generateRandomZoneIndexes(sizeZone);

  for(let i = 0; i < sizeZone; i++) {
    const N1 = zoneNumber1 * sizeZone + i;
    const N2 = zoneNumber2 * sizeZone + i;
    const swapElement = field[N1];

    field[N1] = field[N2];
    field[N2] = swapElement;
  }

  return field;
};

// Обмен двух столбцов районов по вертикали
// TODO: оптимизировать
const swapColumnsZone = ({ field, size }) => {
  transposing({ field, size });
  swapRowsZone({ field, size });
  transposing({ field, size });

  return field;
};

export {
  transposing,
  swapRowsSmall,
  swapColumnsSmall,
  swapRowsZone,
  swapColumnsZone
};
