import React from 'react';
import classNames from 'classnames'


import './createdBy.scss'
import Icon from '~/components/Icon/Icon'

// eslint-disable-next-line
import jet from './assets/jet.svg'

const CreatedBy = ({ isFooter }) => {
  let createdByClass = classNames('created-by', {
    'created-by_footer': isFooter
  })

  return (
    <div className={createdByClass}>
      <div className="created-by__text">Сайт создан</div>

      <div className="created-by__jet">
        <a className="created-by__link" href='https://jetstyle.ru/'>
          <Icon name="jet" className="created-by__logo" />
        </a>
      </div>
    </div>
  );
}

export default CreatedBy
