export default ({ seconds }) => {
  const MAX_AMOUNT = 3;
  const tooltipContent = `<b>1.</b> Рассмотри картинку.
                          <br><br><b>2.</b> Сосчитай количество кубиков и запиши число.
                          <br><br><b>3.</b> Не забудь про кубики, которых не видно на картинке, но которые есть в составленных фигурах.`;
  return {
    timer: new Array(MAX_AMOUNT).fill(seconds * 1000),
    tooltipContent,
    winnerPoints: {
      mind: 1,
      imagination: 2
    }
  };
};
