import questions from '../questions';

import getContent from './getContent';

export default () => Object.keys(questions).reduce((acc, id) => {
  const newQuestion = {
    id, ...questions[id],
    image: getContent()[id - 1]
  };

  return [ ...acc, newQuestion ];
}, []);
