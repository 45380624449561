import React from 'react';
import Icon from '~/components/Icon/Icon';
import cx from 'classnames';

import './achievement.scss';

/* eslint-disable */
import form from './assets/form.svg';
/* eslint-enable */

const Achievement = ({ iconName, name }) => {
  const isBig = !!name;

  const item = () => (
    <div className="achievement__item">
      <Icon name={ iconName } className="achievement__icon" />
    </div>
  )

  return (
    <div className={ cx("achievement", {
      'achievement_big': isBig
    }) }>
      <div className="achievement__form">
        <Icon name="form" className="achievement__icon" />

        { isBig && item() }
      </div>

      { !isBig && item() }

      {
        name &&
        <div className="achievement__info">
          <div className="achievement__agent">
            Агент
          </div>

          <div className="achievement__name">
            { name }
          </div>
        </div>
      }
    </div>
  )
}

export default Achievement;
