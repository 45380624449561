import React from 'react';

import LevelUpPopup from '~/components/LevelUpPopup/LevelUpPopup';
import StatusUpPopup from '~/components/StatusUpPopup/StatusUpPopup';
import ProfileLevelPopup from '~/components/ProfileLevelPopup/ProfileLevelPopup';
import ProfileSkillsPopup from '~/components/ProfileSkillsPopup/ProfileSkillsPopup';

function GlobalPopups() {
  return (
    <>
      <LevelUpPopup />
      <StatusUpPopup />
      <ProfileLevelPopup />
      <ProfileSkillsPopup />
    </>
  );
}

export default GlobalPopups;
