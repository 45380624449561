export default {
  image: require('~/static/trainers/funnyPoints/pineapple.png'),
  width: 800,
  height: 919,
  points: [
    { id: 1,  x: -33,  y: 312 },
    { id: 2,  x: -118, y: 359 },
    { id: 3,  x: -188, y: 588 },
    { id: 4,  x: -154, y: 702 },
    { id: 5,  x: -81,  y: 745 },
    { id: 6,  x: -11,  y: 758 },
    { id: 7,  x: 72,   y: 749 },
    { id: 8,  x: 153,  y: 664 },
    { id: 9,  x: 193,  y: 427 },
    { id: 10, x: 139,  y: 348 },
  ]
}
