import React from 'react';
import propTypes from './prop-types';

import Button from '~/components/Button/Button';
import SuccessScreen from '~/components/SuccessScreen/SuccessScreen';
import ResultPanel from '~/components/ResultPanel/ResultPanel';

import { MAX_POINTS, MIN_POINTS } from './constants';

import getRandomIndex from '~/services/get-random-index';
import getPoints from '~/services/get-points';

import './trainer-success.scss';

const TrainerSuccess = (props) => {
  const { trainer, exitButton, profile, levelMaxValue, earnedSkills,
          mission, maxSkillsForMission, isLastTrainer } = props;
  const { texts, textsInMission } = trainer.params;
  const { skills, points } = profile;
  const isMission = Boolean(mission);
  const currentTexts = isMission ? textsInMission.victory : texts.victory;
  const text = currentTexts[getRandomIndex(currentTexts)];
  const exitButtonProps = { ...exitButton, isMission, isLastTrainer };
  const pointsTotal = { ...getPoints(skills, 'value'), game: points };
  const pointsOf = { ...getPoints(skills, 'max'), game: levelMaxValue };
  const pointsGot = { skills: earnedSkills, game: isMission ? MAX_POINTS : MIN_POINTS };

  return (
    <SuccessScreen text={ text }>
      <ResultPanel isMission={ isMission } gift={ null } maxSkillsForMission={ maxSkillsForMission }
                   pointsGot={ pointsGot }  pointsTotal={ pointsTotal } pointsOf={ pointsOf }
                   currentLevel={ profile.gameLevel }/>
      { renderSuccessButton(props) }
      { renderExitButton(exitButtonProps) }
    </SuccessScreen>
  );
};

const renderSuccessButton = (props) => {
  const { buttonText, buttonOnClick } = props;
  return (
    buttonText && (
      <div className="trainer-success__button">
         <Button className="button_size-m" onClick={ buttonOnClick }>
          { buttonText }
        </Button>
      </div>
    )
  );
};

const renderExitButton = (props) => {
  const { text, handler, isLastTrainer, isMission } = props;
  const theme = isLastTrainer && isMission ? 'orange' : 'grey-link';

  return (
    text && (
      <Button className="button_center trainer-screen__button trainer-screen__button_exit button_size-m"
              theme={ theme } onClick={ handler }>
        { text }
      </Button>
    )
  );
};

TrainerSuccess.propTypes = propTypes;

export default TrainerSuccess;
