export default {
  shapes: [ 1, 6, 7, 2, 3, 5, 4 ],
  offset: {
    tablet: 10,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 267, y: 40 },
      tablet: { x: 266, y: 41 },
      mobile: { x: 189, y: 24 },
    },
    {
      desktop: { x: 222, y: 85 },
      tablet: { x: 226, y: 81 },
      mobile: { x: 159, y: 54 },
    },
    {
      desktop: { x: 267, y: 130 },
      tablet: { x: 266, y: 121 },
      mobile: { x: 189, y: 84 },
    },
    {
      desktop: { x: 198, y: 130 },
      tablet: { x: 209, y: 121 },
      mobile: { x: 147, y: 84 },
    },
    {
      desktop: { x: 239, y: 171 },
      tablet: { x: 246, y: 158 },
      mobile: { x: 172, y: 109 },
    },
    {
      desktop: { x: 239, y: 238.7 },
      tablet: { x: 246, y: 214.8 },
      mobile: { x: 172, y: 158.2 },
    },
    {
      desktop: { x: 175.7, y: 302 },
      tablet: { x: 189.9, y: 271 },
      mobile: { x: 130.2, y: 200 },
    },
    {
      desktop: { x: 239, y: 365.3 },
      tablet: { x: 246, y: 327.2 },
      mobile: { x: 172, y: 242 },
    },
    {
      desktop: { x: 239, y: 417 },
      tablet: { x: 246, y: 374 },
      mobile: { x: 172, y: 277 },
    },
    {
      desktop: { x: 284, y: 372 },
      tablet: { x: 286, y: 334 },
      mobile: { x: 202, y: 247 },
    },
    {
      desktop: { x: 239.4, y: 327.5 },
      tablet: { x: 246.4, y: 294.5 },
      mobile: { x: 172.5, y: 217.5 },
    },
    {
      desktop: { x: 239.4, y: 298.5 },
      tablet: { x: 246.4, y: 271.2 },
      mobile: { x: 172.5, y: 193.8 },
    },
    {
      desktop: { x: 365.7, y: 298.3 },
      tablet: { x: 359.3, y: 271.2 },
      mobile: { x: 256.4, y: 193.8 },
    },
    {
      desktop: { x: 406.2, y: 338.7 },
      tablet: { x: 395.2, y: 305.7 },
      mobile: { x: 286.8, y: 224.3 },
    },
    {
      desktop: { x: 406, y: 275 },
      tablet: { x: 394.2, y: 249.2 },
      mobile: { x: 286.8, y: 181.8 },
    },
    {
      desktop: { x: 343.2, y: 275.2 },
      tablet: { x: 338.3, y: 250.3 },
      mobile: { x: 244.8, y: 181.8 },
    },
    {
      desktop: { x: 325.2, y: 257.2 },
      tablet: { x: 322.1, y: 234.1 },
      mobile: { x: 231.9, y: 168.9 },
    },
    {
      desktop: { x: 325.2, y: 192.8 },
      tablet: { x: 322.1, y: 177.7 },
      mobile: { x: 231.9, y: 126.9 },
    },
    {
      desktop: { x: 389, y: 257 },
      tablet: { x: 378.7, y: 234.3 },
      mobile: { x: 273.6, y: 168.6 },
    },
    {
      desktop: { x: 388.5, y: 192.5 },
      tablet: { x: 378.7, y: 177.7 },
      mobile: { x: 273.6, y: 126.2 },
    },
    {
      desktop: { x: 326, y: 130 },
      tablet: { x: 322, y: 121 },
      mobile: { x: 232, y: 84 },
    },
    {
      desktop: { x: 267, y: 130 },
      tablet: { x: 266, y: 121 },
      mobile: { x: 189, y: 84 },
    },
    {
      desktop: { x: 312, y: 85 },
      tablet: { x: 306, y: 81 },
      mobile: { x: 219, y: 54 },
    },
  ],
  outlines: [
    {
      desktop: { x: 222, y: 40 },
      tablet: { x: 226, y: 41 },
      mobile: { x: 159, y: 24 },
      rotation: 0
    },
    {
      desktop: { x: 198, y: 130 },
      tablet: { x: 209, y: 121 },
      mobile: { x: 147, y: 84 },
      rotation: 315
    },
    {
      desktop: { x: 366, y: 298 },
      tablet: { x: 359, y: 271 },
      mobile: { x: 257, y: 194 },
      rotation: 135
    },
    {
      desktop: { x: 421, y: 225 },
      tablet: { x: 407, y: 206 },
      mobile: { x: 295, y: 147 },
      rotation: 135
    },
    {
      desktop: { x: 438, y: 307 },
      tablet: { x: 423, y: 277 },
      mobile: { x: 308, y: 203 },
      rotation: 135
    },
    {
      desktop: { x: 303, y: 302 },
      tablet: { x: 303, y: 271 },
      mobile: { x: 215, y: 200 },
      rotation: 135
    },
    {
      desktop: { x: 284, y: 417 },
      tablet: { x: 286, y: 374 },
      mobile: { x: 202, y: 277 },
      rotation: 180
    }
  ]
}
