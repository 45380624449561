export default [
  {
    sequence: [1, 3, 2, 4, 3],
    answer: 5,
    actions: ['+2', '-1']
  },
  {
    sequence: [2, 4, 3, 5, 4],
    answer: 6,
    actions: ['+2', '-1']
  },
  {
    sequence: [3, 5, 4, 6, 5],
    answer: 7,
    actions: ['+2', '-1']
  },
  {
    sequence: [4, 6, 5, 7, 6],
    answer: 8,
    actions: ['+2', '-1']
  },
  {
    sequence: [5, 7, 6, 8, 7],
    answer: 9,
    actions: ['+2', '-1']
  },
  {
    sequence: [1, 4, 3, 6, 5],
    answer: 8,
    actions: ['+3', '-1']
  },
  {
    sequence: [2, 5, 4, 7, 6],
    answer: 9,
    actions: ['+3', '-1']
  },
  {
    sequence: [3, 6, 5, 8, 7],
    answer: 10,
    actions: ['+3', '-1']
  },
  {
    sequence: [3, 4, 2, 3, 1],
    answer: 2,
    actions: ['+1', '-2']
  },
  {
    sequence: [4, 5, 3, 4, 2],
    answer: 3,
    actions: ['+1', '-2']
  },
  {
    sequence: [5, 6, 4, 5, 3],
    answer: 4,
    actions: ['+1', '-2']
  },
  {
    sequence: [6, 7, 5, 6, 4],
    answer: 5,
    actions: ['+1', '-2']
  },
  {
    sequence: [7, 8, 6, 7, 5],
    answer: 6,
    actions: ['+1', '-2']
  },
  {
    sequence: [8, 9, 7, 8, 6],
    answer: 7,
    actions: ['+1', '-2']
  },
  {
    sequence: [9, 10, 8, 9, 7],
    answer: 8,
    actions: ['+1', '-2']
  },
  {
    sequence: [1, 4, 2, 5, 3],
    answer: 6,
    actions: ['+3', '-2']
  },
  {
    sequence: [2, 5, 3, 6, 4],
    answer: 7,
    actions: ['+3', '-2']
  },
  {
    sequence: [3, 6, 4, 7, 5],
    answer: 8,
    actions: ['+3', '-2']
  },
  {
    sequence: [4, 7, 5, 8, 6],
    answer: 9,
    actions: ['+3', '-2']
  },
  {
    sequence: [5, 8, 6, 9, 7],
    answer: 10,
    actions: ['+3', '-2']
  },
  {
    sequence: [1, 5, 3, 7, 5],
    answer: 9,
    actions: ['+4', '-2']
  },
  {
    sequence: [2, 6, 4, 8, 6],
    answer: 10,
    actions: ['+4', '-2']
  },
  {
    sequence: [3, 7, 5, 9, 7],
    answer: 11,
    actions: ['+4', '-2']
  },
  {
    sequence: [5, 3, 4, 2, 3],
    answer: 1,
    actions: ['-2', '+1']
  },
  {
    sequence: [6, 4, 5, 3, 4],
    answer: 2,
    actions: ['-2', '+1']
  },
  {
    sequence: [7, 5, 6, 4, 5],
    answer: 3,
    actions: ['-2', '+1']
  },
  {
    sequence: [8, 6, 7, 5, 6],
    answer: 4,
    actions: ['-2', '+1']
  },
  {
    sequence: [9, 7, 8, 6, 7],
    answer: 5,
    actions: ['-2', '+1']
  },
  {
    sequence: [8, 5, 6, 3, 4],
    answer: 1,
    actions: ['-3', '+1']
  },
  {
    sequence: [9, 6, 7, 4, 5],
    answer: 2,
    actions: ['-3', '+1']
  },
  {
    sequence: [1, 2, 4, 5, 7],
    answer: 8,
    actions: ['+1', '+2']
  },
  {
    sequence: [2, 3, 5, 6, 8],
    answer: 9,
    actions: ['+1', '+2']
  },
  {
    sequence: [3, 4, 6, 7, 9],
    answer: 10,
    actions: ['+1', '+2']
  },
  {
    sequence: [1, 3, 4, 6, 7],
    answer: 9,
    actions: ['+2', '+1']
  },
  {
    sequence: [2, 4, 5, 7, 8],
    answer: 10,
    actions: ['+2', '+1']
  },
  {
    sequence: [10, 9, 7, 6, 4],
    answer: 3,
    actions: ['-1', '-2']
  },
  {
    sequence: [9, 8, 6, 5, 3],
    answer: 2,
    actions: ['-1', '-2']
  },
  {
    sequence: [8, 7, 5, 4, 2],
    answer: 1,
    actions: ['-1', '-2']
  },
  {
    sequence: [7, 6, 4, 3, 1],
    answer: 0,
    actions: ['-1', '-2']
  },
  {
    sequence: [10, 8, 7, 5, 4],
    answer: 2,
    actions: ['-2', '-1']
  },
  {
    sequence: [9, 7, 6, 4, 3],
    answer: 1,
    actions: ['-2', '-1']
  },
  {
    sequence: [8, 6, 5, 3, 2],
    answer: 0,
    actions: ['-2', '-1']
  },
  {
    sequence: [1, 2, 5, 6, 9],
    answer: 10,
    actions: ['+1', '+3']
  },
  {
    sequence: [10, 9, 6, 5, 2],
    answer: 1,
    actions: ['-1', '-3']
  },
  {
    sequence: [2, 5, 1, 4, 0],
    answer: 3,
    actions: ['+3', '-4']
  },
  {
    sequence: [3, 6, 2, 5, 1],
    answer: 4,
    actions: ['+3', '-4']
  },
  {
    sequence: [4, 7, 3, 6, 2],
    answer: 5,
    actions: ['+3', '-4']
  },
  {
    sequence: [5, 8, 4, 7, 3],
    answer: 6,
    actions: ['+3', '-4']
  },
  {
    sequence: [6, 9, 5, 8, 4],
    answer: 7,
    actions: ['+3', '-4']
  },
  {
    sequence: [7, 10, 6, 9, 5],
    answer: 8,
    actions: ['+3', '-4']
  },
  {
    sequence: [8, 11, 7, 10, 6],
    answer: 9,
    actions: ['+3', '-4']
  },
  {
    sequence: [2, 6, 1, 5, 0],
    answer: 4,
    actions: ['+4', '-5']
  },
  {
    sequence: [3, 7, 2, 6, 1],
    answer: 5,
    actions: ['+4', '-5']
  },
  {
    sequence: [4, 8, 3, 7, 2],
    answer: 6,
    actions: ['+4', '-5']
  },
  {
    sequence: [5, 9, 4, 8, 3],
    answer: 7,
    actions: ['+4', '-5']
  },
  {
    sequence: [6, 10, 5, 9, 4],
    answer: 8,
    actions: ['+4', '-5']
  },
  {
    sequence: [1, 6, 2, 7, 3],
    answer: 8,
    actions: ['+5', '-4']
  },
  {
    sequence: [2, 7, 3, 8, 4],
    answer: 9,
    actions: ['+5', '-4']
  },
  {
    sequence: [3, 8, 4, 9, 5],
    answer: 10,
    actions: ['+5', '-4']
  },
  {
    sequence: [1, 7, 2, 8, 3],
    answer: 9,
    actions: ['+6', '-5']
  },
  {
    sequence: [2, 8, 3, 9, 4],
    answer: 10,
    actions: ['+6', '-5']
  },
  {
    sequence: [2, 4, 1, 3, 0],
    answer: 2,
    actions: ['+2', '-3']
  },
  {
    sequence: [3, 5, 2, 4, 1],
    answer: 3,
    actions: ['+2', '-3']
  },
  {
    sequence: [4, 6, 3, 5, 2],
    answer: 4,
    actions: ['+2', '-3']
  },
  {
    sequence: [5, 7, 4, 6, 3],
    answer: 5,
    actions: ['+2', '-3']
  },
  {
    sequence: [6, 8, 5, 7, 4],
    answer: 6,
    actions: ['+2', '-3']
  },
  {
    sequence: [7, 9, 6, 8, 5],
    answer: 7,
    actions: ['+2', '-3']
  },
  {
    sequence: [8, 10, 7, 9, 6],
    answer: 8,
    actions: ['+2', '-3']
  },
  {
    sequence: [3, 0, 4, 1, 5],
    answer: 2,
    actions: ['-3', '+4']
  },
  {
    sequence: [4, 1, 5, 2, 6],
    answer: 3,
    actions: ['-3', '+4']
  },
  {
    sequence: [5, 2, 6, 3, 7],
    answer: 4,
    actions: ['-3', '+4']
  },
  {
    sequence: [6, 3, 7, 4, 8],
    answer: 5,
    actions: ['-3', '+4']
  },
  {
    sequence: [7, 4, 8, 5, 9],
    answer: 6,
    actions: ['-3', '+4']
  },
  {
    sequence: [8, 5, 9, 6, 10],
    answer: 7,
    actions: ['-3', '+4']
  },
  {
    sequence: [3, 1, 5, 3, 7],
    answer: 5,
    actions: ['-2', '+4']
  },
  {
    sequence: [4, 2, 6, 4, 8],
    answer: 6,
    actions: ['-2', '+4']
  },
  {
    sequence: [5, 3, 7, 5, 9],
    answer: 7,
    actions: ['-2', '+4']
  },
  {
    sequence: [6, 4, 8, 6, 10],
    answer: 8,
    actions: ['-2', '+4']
  },
  {
    sequence: [7, 2, 6, 1, 5],
    answer: 0,
    actions: ['-5', '+4']
  },
  {
    sequence: [8, 3, 7, 2, 6],
    answer: 1,
    actions: ['-5', '+4']
  },
  {
    sequence: [9, 4, 8, 3, 7],
    answer: 2,
    actions: ['-5', '+4']
  },
  {
    sequence: [10, 5, 9, 4, 8],
    answer: 3,
    actions: ['-5', '+4']
  },
  {
    sequence: [2, 7, 1, 6, 0],
    answer: 5,
    actions: ['+5', '-6']
  },
  {
    sequence: [3, 8, 2, 7, 1],
    answer: 6,
    actions: ['+5', '-6']
  },
  {
    sequence: [4, 9, 3, 8, 2],
    answer: 7,
    actions: ['+5', '-6']
  },
  {
    sequence: [5, 10, 4, 9, 3],
    answer: 8,
    actions: ['+5', '-6']
  },
  {
    sequence: [0, 4, 2, 6, 4],
    answer: 8,
    actions: ['+4', '-2']
  },
  {
    sequence: [1, 5, 3, 7, 5],
    answer: 9,
    actions: ['+4', '-2']
  },
  {
    sequence: [2, 6, 4, 8, 6],
    answer: 10,
    actions: ['+4', '-2']
  },
  {
    sequence: [0, 3, 1, 4, 2],
    answer: 5,
    actions: ['+3', '-2']
  },
  {
    sequence: [1, 4, 2, 5, 3],
    answer: 6,
    actions: ['+3', '-2']
  },
  {
    sequence: [2, 5, 3, 6, 4],
    answer: 7,
    actions: ['+3', '-2']
  },
  {
    sequence: [3, 6, 4, 7, 5],
    answer: 8,
    actions: ['+3', '-2']
  },
  {
    sequence: [4, 7, 5, 8, 6],
    answer: 9,
    actions: ['+3', '-2']
  },
  {
    sequence: [5, 8, 6, 9, 7],
    answer: 10,
    actions: ['+3', '-2']
  }
];
