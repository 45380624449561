export default {
  texts: {
    description: [
      'Каждый агент в сложной ситуации должен быстро придумать несколько вариантов её решения.',
      'У наших самых крутых спецагентов на одну задачу всегда находится несколько решений. Хочешь так же? Тогда развивай вариативность мышления!',
      'Разгадать сложный шифр для наших агентов не составляет труда, ведь у них хорошо развита вариативность мышления. Оттачивай этот важный навык – и даже самые сложные задания тебе покажутся легкими!'
    ],
    losing: [
      'Попробуй ещё раз! В следующий раз всё получится.',
      'Эх... Совсем чуть-чуть. Не беда, тренируйся больше, и у тебя точно всё получится.'
    ],
    victory: [
      'Ты справился! Все слова собраны. Мистер Фоксман готов тебе поручить новую миссию.',
      'Отличный результат! Ты готов пройти миссию. Мистер Фоксман ждёт тебя!'
    ]
  },
  textsInMission: {
    description: [
      'Ты получил кодовое слово. Чтобы расшифровать это послание, собери как можно больше слов из букв данного слова. Тогда ты получишь важные сведения!',
      'Последняя разработка спецагентов – кодовый замок. Чтобы его открыть, подбери как можно больше слов из букв данного слова, и дверь будет открыта!'
    ],
    losing: [
      'Увы... Совсем чуть-чуть не хватило времени. Тебе предстоят тренировки, ведь спецагент должен уметь решать любые задания.',
      'Эх... Не хватило совсем немного. В тренажёрном зале ты сможешь развить данный навык и легко пройти миссию.'
    ],
    victory: [
      'Ты быстро справился! Мистер Фоксман гордится тобой!',
      'Как много слов ты придумал! Только настоящий спецагент может справиться с этим заданием. Продолжай, новые интересные задания ждут тебя!'
    ]
  }
};
