import React from 'react';
import cx from 'classnames';

import Fail from '~/components/Fail/Fail';
import Ok from '~/components/Ok/Ok';

import '~/services/dnd-polyfill';

import { ANSWER } from '../constants';

import './mnemonic-test.scss';

const MnemonicTest = (props) => {
  const { resultMode, currentMatrix, setCurrentMatrix } = props;
  const isShowRight = resultMode !== ANSWER;

  return (
    <div className="mnemonic-test">
      { renderCards({ ...props, setCurrentMatrix, currentMatrix, isShowRight }) }
    </div>
  );
};

const renderCards = (props) => {
  const { currentImages, setCurrentMatrix, currentMatrix, correctImages, isShowRight } = props;
  const images = isShowRight ? correctImages : currentImages;

  return images.map((cardsRow, index) => (
    <div className="mnemonic-test__cards-row" key={ index }>
      {
        cardsRow.map((item, itemIndex) => {
          return renderCard({ ...props, item, hIndex: index, vIndex: itemIndex, setCurrentMatrix, currentMatrix });
        })
      }
    </div>
  ));
};

const renderCard = (props) => {
  const { handlerDragParent, handlerDropParent, handlerDragOver, setCurrentMatrix, isFinish, correctImages, item,
          handlerDragLeave, hIndex, vIndex, currentMatrix, currentImages, setCurrentImages, isShowRight,
          decrementStep, handlerClickIconParent, selectedIndex } = props;
  const isRight = currentImages[hIndex][vIndex] === correctImages[hIndex][vIndex];
  const isEmpty = item === -1;
  const isCorrect = isFinish && isRight;
  const isIncorrect = isFinish && !isRight;
  const classesCard = cx('mnemonic-test__card', {
    'mnemonic-test__card_correct': isCorrect,
    'mnemonic-test__card_incorrect': isIncorrect,
    'mnemonic-test__card_checked': isShowRight
  });

  const handlerDrag = (event) => handlerDragParent(event, hIndex, vIndex);
  const handlerClickCard = () => {
    if (isFinish) {
      return;
    }

    const currentImage = currentMatrix[selectedIndex];
    const currentCard = currentImages[hIndex][vIndex];
    const newCurrentImages = [ ...currentImages ].map((item) => [ ...item ]);
    const newCurrentMatrix = [ ...currentMatrix ];

    if (currentCard === -1 && currentImage) {
      newCurrentImages[hIndex][vIndex] = currentImage;
      newCurrentMatrix.splice(selectedIndex, 1);

      decrementStep();
      handlerClickIconParent(null);
    }

    if (currentCard !== -1 && currentImage) {
      newCurrentImages[hIndex][vIndex] = currentImage;
      newCurrentMatrix[selectedIndex] = currentCard;
    }

    setCurrentMatrix(newCurrentMatrix);
    setCurrentImages(newCurrentImages);
  };

  const handlerDrop = (event) => {
    if (isFinish) {
      return;
    }

    const value = event.dataTransfer.getData('value');
    const { index, hIndex: hDragIndex, vIndex: vDragIndex, image } = JSON.parse(value);
    const dropImage = currentImages[hIndex][vIndex];

    if (hDragIndex !== undefined && image) {
      const newCurrentImages = [ ...currentImages ].map((item) => [ ...item ]);
      const swapItem = newCurrentImages[hIndex][vIndex];

      newCurrentImages[hIndex][vIndex] = newCurrentImages[hDragIndex][vDragIndex];
      newCurrentImages[hDragIndex][vDragIndex] = swapItem;

      setCurrentImages(newCurrentImages);
    }

    if (index !== undefined) {
      const newCurrentMatrix = [ ...currentMatrix ];

      if (dropImage === -1) {
        decrementStep();
        newCurrentMatrix.splice(index, 1);
      }
      else {
        const swapItem = newCurrentMatrix[index];
        newCurrentMatrix[index] = currentImages[hIndex][vIndex];
        currentImages[hIndex][vIndex] = swapItem;
      }

      setCurrentMatrix(newCurrentMatrix);
    }

    handlerDropParent(event, hIndex, vIndex);
  };

  const style = { backgroundImage: `url(${ item })` };

  return (
    <div className={ classesCard } key={ `${ hIndex }-${ vIndex }` } onDragStart={ handlerDrag } onDrop={ handlerDrop }
         draggable={ !isEmpty && !isFinish } onDragOver={ handlerDragOver } onDragLeave={ handlerDragLeave }
         onClick={ handlerClickCard }>
      {
        isEmpty ?
        <div className="mnemonic-test__card-empty"/> :
        <div className="mnemonic-test__icon" style={ style }/>
      }
      { isCorrect && !isShowRight && <Ok /> }
      { isIncorrect && !isShowRight && <Fail /> }
    </div>
  );
};

export default MnemonicTest;
