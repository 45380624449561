import React, { useState } from 'react';
import cx from 'classnames';
import { useSpring, animated as a } from 'react-spring';

import TrainerCardFront from '../TrainerCardFront/TrainerCardFront';
import TrainerCardBack from '../TrainerCardBack/TrainerCardBack';

import { DEFAULT_HEIGHT_CARD, HEIGHT_BUTTON_BACK } from './constants';

import './trainerCard.scss';

const TrainerCard = (props) => {
  const { id, className, title, skillPoints, tooltipAnnounce,
          previewUrl, train, isMobile, mobileHeight, eventCategory } = props;
  const [ tooltip, setTooltip ] = useState(false);
  const [ isCardHovered, setCardHovered ] = useState(false);

  const onMouseHandler = (isOver, e) => {
    if (tooltip) {
      return;
    }

    setCardHovered(isOver);
  };

  const switchTooltip = (e) => {
    const parentWrapper = e.target.closest('.trainer-card');

    if (parentWrapper) {
      const trainerCardFront = parentWrapper.querySelector('.trainer-card-front');
      parentWrapper.classList.remove('trainer-card_hovered');

      if (!isMobile) {
        const skillPanels = parentWrapper.querySelectorAll('.trainer-card-skill');

        trainerCardFront.classList.remove('trainer-card-front_hovered');
        skillPanels.forEach((item) => {
          item.classList.remove('trainer-card-skill_hovered');
        });
      }

      if (train && tooltip) {
        parentWrapper.style.height = `${ DEFAULT_HEIGHT_CARD }px`;
      }

      if (isMobile && tooltip) {
        parentWrapper.style.height = `${ mobileHeight }px`;
      }

      if((train || isMobile) && !tooltip) {
        const trainerCardBack = parentWrapper.querySelector('.trainer-card__back');
        parentWrapper.style.height = `${ trainerCardBack.offsetHeight + HEIGHT_BUTTON_BACK }px`;
      }
    }
    setTooltip(!tooltip);
  };

  const { transform, opacity } = useSpring({
    opacity: tooltip ? 1 : 0,
    transform: `perspective(1000px) rotateY(${ tooltip ? 180 : 0 }deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  const renderFront = () => (
    <a.div
      className={ cx(`trainer-card__front properties-length-${ Object.keys(skillPoints).length }`) }
      style={ isCardHovered ? { opacity: opacity.interpolate(o => 1 - o), transform } : { opacity: 1 }}
      onMouseOver={ (e) => onMouseHandler(true, e) }
      onMouseLeave={ (e) => onMouseHandler(false, e) }
    >
      <TrainerCardFront
        isMobile={ isMobile }
        title={ title }
        skillPoints={ skillPoints }
        id={ id }
        hovered={ isCardHovered }
        previewUrl={ previewUrl }
        switchTooltip={ switchTooltip }
        train={ train }
        eventCategory={ eventCategory }
      />
    </a.div>
  );

  const renderBack = () => (
    <a.div
      className="trainer-card__back"
      style={ isCardHovered ? { opacity, transform: transform.interpolate(t => `${t} rotateY(180deg)`) } : { opacity: 0 }}
    >
      <TrainerCardBack
        tooltipAnnounce={ tooltipAnnounce }
        id={ id }
        tooltip={ tooltip }
        switchTooltip={ switchTooltip }
      />
    </a.div>
  );

  const classesCard = cx("trainer-card", {
    [className]: className,
    "trainer-card_hovered": isCardHovered && !isMobile,
    "trainer-card_mobile": isMobile,
    "trainer-card_train": train
  });

  return (
    <div className={ classesCard } >
      <div className="trainer-card__wrapper">
        { renderBack() }
        { renderFront() }
      </div>
    </div>
  );
};

export default TrainerCard;
