import React from 'react';

import './feedback.scss'

const Feedback = (props) => {
  const { link } = props;
  return (
    <div className="feedback">
      <div className="feedback__text">
        Агент! Срочное задание — заполни секретную анкету для мистера Фоксмана!
      </div>

      <div className="feedback__write">
        <a className="feedback__link" target="_blank" rel="noopener noreferrer" href={ link }>
          Секретное задание
        </a>
      </div>
    </div>
  );
}

export default Feedback
