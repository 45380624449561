export default {
  texts: {
    description: [
      'Каждому спецагенту важно владеть только проверенной информацией, но враг всегда стремится обмануть спецагента. Это упражнение разовьёт логическое мышление, с помощью которого ты легко определишь любой обман!',
      'Каждый спецагент должен с лёгкостью отделять правду от вымысла. Тренируй логическое мышление, тогда ни один враг не сможет тебя обмануть!'
    ],
    losing: [
      'Эх... Промахнулся! Попробуй ещё раз, и у тебя обязательно получится!',
      'К сожалению, ты допустил ошибку. Спецагенты никогда не опускают рук и много тренируются! Тренируйся, и у тебя всё получится!'
    ],
    victory: [
      'У тебя отлично получается! Теперь ты легко отличишь правду от вымысла!',
      'Ты всё определил верно! Новые миссии ждут тебя!'
    ]
  },
  textsInMission: {
    description: [
      'В важную информацию попали ложные документы. Твоя задача – определить обман. Будь внимателен!',
      'Кто-то пытался нас запутать и добавил в важные факты ложные данные. Определи обман!'
    ],
    losing: [
      'К сожалению, ты не определил обман! Тебе нужно больше тренироваться. Мистер Фоксман ждёт тебя в тренажёрном зале!',
      'Враг оказался очень хитрым! Потренируй логику в тренажерном зале, чтобы больше не допускать таких промахов!'
    ],
    victory: [
      'Отлично! Теперь мы уверены в предоставленных фактах!',
      'Отличная работа, спецагент! Всегда важно получать верную и проверенную информацию!'
    ]
  }
};
