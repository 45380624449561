import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Page from '~/containers/Page/Page';

import LocationDescription from '~/components/LocationDescription/LocationDescription';
import MissionCard from '~/components/MissionCard/MissionCard';
import Feedback from '~/components/Feedback/Feedback';

import getBackgroundStyle from './services/get-background-style';

import './locationPage.scss';

const LocationPage = (props) => {
  const { location, completedMissions, history } = props;
  const { missions, background, slug, locked } = location;
  const isLondon = slug === 'london';

  if (locked) {
    history.push('/locations/');
  }

  const arrayMissions = Object.keys(missions).sort((a, b) => missions[a].id > missions[b].id ? 1 : -1);
  const renderItems = () => {
    return arrayMissions.map((item, index) => {
      const isCompleted = completedMissions[missions[item].id];

      return (
        <li className="location-page__mission" key={ index }>
          <div className="location-page__mission-holder">
            <MissionCard
              completed={ isCompleted }
              data={ missions[item] }
              locationSlug={ slug }
              slug={ item }
            />
          </div>
        </li>
      )
    })
  }

  return (
    <Page>
      <div className="location-page">
        <div className="location-page__city" style={ getBackgroundStyle(background) }>
          <div className="location-page__description">
            <LocationDescription location={ location } />
          </div>
        </div>

        <div className="location-page__holder">
          <div className="location-page__feedback-holder">
            { isLondon && <Feedback link="https://forms.gle/NEhwSyV7VQWA4qk88" /> }
          </div>
          <ul className="location-page__list">
            { renderItems() }
          </ul>
        </div>
      </div>
    </Page>
  );
}

function mapStateToProps(state, props) {
  return {
    location: state.locations.data[props.match.params.locationSlug],
    completedMissions: state.profile.completedMissions
  };
}

export default withRouter(connect(mapStateToProps)(LocationPage));
