export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 35
  },
  outline: [
    {
      desktop: {
        x: 301.14,
        y: 31
      },
      tablet: {
        x: 219,
        y: 33
      },
      mobile: {
        x: 151,
        y: 21
      }
    },
    {
      desktop: {
        x: 301.14,
        y: 121
      },
      tablet: {
        x: 259.3,
        y: 73.3
      },
      mobile: {
        x: 181,
        y: 51
      }
    },
    {
      desktop: {
        x: 263.78,
        y: 158.36
      },
      tablet: {
        x: 299,
        y: 33.61
      },
      mobile: {
        x: 211,
        y: 21
      }
    },
    {
      desktop: {
        x: 263.78,
        y: 166.64
      },
      tablet: {
        x: 299,
        y: 113
      },
      mobile: {
        x: 211,
        y: 81
      }
    },
    {
      desktop: {
        x: 353.4,
        y: 256.3
      },
      tablet: {
        x: 267.57,
        y: 144.43
      },
      mobile: {
        x: 187.43,
        y: 104.57
      }
    },
    {
      desktop: {
        x: 353.42,
        y: 382.28
      },
      tablet: {
        x: 267.57,
        y: 153.57
      },
      mobile: {
        x: 187.43,
        y: 111.43
      }
    },
    {
      desktop: {
        x: 226.14,
        y: 382.28
      },
      tablet: {
        x: 347.14,
        y: 233.15
      },
      mobile: {
        x: 246.85,
        y: 170.84
      }
    },
    {
      desktop: {
        x: 262.53,
        y: 345.89
      },
      tablet: {
        x: 347.14,
        y: 345.14
      },
      mobile: {
        x: 246.85,
        y: 255.85
      }
    },
    {
      desktop: {
        x: 200.53,
        y: 283.88
      },
      tablet: {
        x: 234,
        y: 345.14
      },
      mobile: {
        x: 162,
        y: 255.85
      }
    },
    {
      desktop: {
        x: 199.8,
        y: 221
      },
      tablet: {
        x: 266.93,
        y: 312.21
      },
      mobile: {
        x: 187.3,
        y: 230.55
      }
    },
    {
      desktop: {
        x: 255.46,
        y: 165.32
      },
      tablet: {
        x: 211.28,
        y: 256.57
      },
      mobile: {
        x: 145.21,
        y: 188.43
      }
    },
    {
      desktop: {
        x: 211.14,
        y: 121
      },
      tablet: {
        x: 211.28,
        y: 200.28
      },
      mobile: {
        x: 145.21,
        y: 146.21
      }
    },
    {
      desktop: {
        x: 211.14,
        y: 32
      },
      tablet: {
        x: 258.78,
        y: 152.78
      },
      mobile: {
        x: 180.71,
        y: 110.71
      }
    },
    {
      desktop: {
        x: 255.9,
        y: 76.5
      },
      tablet: {
        x: 219,
        y: 113
      },
      mobile: {
        x: 151,
        y: 81
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 263.14,
        y: 166,
        rotation: -0
      },
      tablet: {
        x: 267,
        y: 153,
        rotation: -0
      },
      mobile: {
        x: 187,
        y: 111,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 353.42,
        y: 255,
        rotation: -45
      },
      tablet: {
        x: 347.14,
        y: 232,
        rotation: -45
      },
      mobile: {
        x: 246.85,
        y: 171,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 327.42,
        y: 220.64,
        rotation: -135
      },
      tablet: {
        x: 324.14,
        y: 200.57,
        rotation: -135
      },
      mobile: {
        x: 229.85,
        y: 146.43,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 256.14,
        y: 31,
        rotation: 0
      },
      tablet: {
        x: 259,
        y: 33.61,
        rotation: 0
      },
      mobile: {
        x: 181,
        y: 21,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 256.14,
        y: 122,
        rotation: 180
      },
      tablet: {
        x: 259,
        y: 113,
        rotation: 180
      },
      mobile: {
        x: 181,
        y: 81,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 168,
        y: 251.36,
        rotation: 45
      },
      tablet: {
        x: 183,
        y: 228.28,
        rotation: 45
      },
      mobile: {
        x: 124,
        y: 167.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 211.14,
        y: 76,
        rotation: 0
      },
      tablet: {
        x: 219,
        y: 73,
        rotation: 0
      },
      mobile: {
        x: 151,
        y: 51,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
