import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Button from '~/components/Button/Button';
import Icon from '~/components/Icon/Icon';
import Geo from '~/components/Geo/Geo';
import LocationItems from '~/components/LocationItems/LocationItems';

import getMissionName from './services/get-mission-name';

/* eslint-disable */
import arrow from './assets/arrow.svg';
import cross from '~/static/icon/cross.svg';
/* eslint-enable */

import './locationCard.scss';

const LocationCard = ({ data, completedMissions }) => {
  const { locked, missions, background } = data;
  const cardClasses = cx('location-card', {
    'location-card_locked': locked
  });
  const missionsArray = Object.keys(missions).map((item) => missions[item]);
  const gifts = missionsArray.reduce((list, item) => {
    let gift = { ...item.gift };
    gift.isDisabled = !completedMissions[item.id];

    return [ ...list, gift ];
  }, []);

  return (
    <div className={ cardClasses }>
      <div className="location-card__holder">
        <div className="location-card__background">
          <img src={ background } className="location-card__background-img" alt="mission-background" />
        </div>

        { !locked && <div className="location-card__mask"></div> }

        <div className="location-card__content">
          { renderCardTop({ ...data, gifts }) }
          { renderCardBottom({ ...data, missionsArray }) }
        </div>

      </div>

      <div className="location-card__link location-card__link_mobile">
        { renderLink(data) }
      </div>
    </div>
  );
};

const renderLink = ({ locked, slug }) => {
  const name = locked ? 'cross' : 'arrow';
  const link = `/locations/${ locked ? '' : slug }`;

  return (
    <Button round={ true } link={ link }>
      <Icon name={ name } className={ `location-card__${ name }` }/>
    </Button>
  );
};

const renderCardTop = (props) => {
  const { gifts, distance, locked } = props;
  const contentTopClasses = cx('location-card__content-top', {
    'location-card__content-top_reverse': !locked
  });

  return (
    <div className={ contentTopClasses }>
      {
        !locked &&
        <div className="location-card__items">
          <LocationItems gifts={ gifts } />
        </div>
      }

      <div className="location-card__distance">
        <Geo distance={ distance } />
      </div>
    </div>
  );
};

const renderCardBottom = (data) => {
  const { city, country, locked, slug, missionsArray } = data;
  const missionCount = missionsArray.length;
  const linkControlClasses = cx('location-card__control-wrapper', {
    'location-card__control-wrapper_locked': locked
  });

  return (
    <Link className={ linkControlClasses } to={ `/locations/${ slug }/` }>
      <div className="location-card__control">
        <div className="location-card__info">
          <h2 className="location-card__title">
            { city }, { country }
          </h2>
          { locked ? renderLockedText() : renderUnlockedText(missionsArray, missionCount) }
        </div>

        <div className="location-card__link location-card__link_desktop">
          { renderLink(data) }
        </div>
      </div>
    </Link>
  );
};

const renderLockedText = () => (
  <div className="location-card__locked-text">
    Чтобы перейти сюда — заверши все миссии в предыдущем городе
  </div>
);

const renderUnlockedText = (missionsArray, missionCount) => (
  <p className="location-card__missions">
    <span className="location-card__text">
      Миссии:
    </span>

    <span>
      { missionsArray.map((item, index, ) => getMissionName(item, index, missionCount)) }
    </span>
  </p>
);

function mapStateToProps(state) {
  return {
    completedMissions: state.profile.completedMissions
  };
};

export default connect(mapStateToProps)(LocationCard);
