import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { MOBILE_HEIGHT_TABS, DESKTOP_HEIGHT_TABS } from '../constants';

import './PanAndZoomWrapper.scss';

class PanAndZoomWrapper extends PureComponent {
  state = {
    isPanned: false,
    panAnchor: { x: 0, y: 0 },
    currentAnchor: { x: 0, y: 0 },
    x: 0,
    y: 0
  };

  componentDidMount() {
    const { width, isMobile, offsetX, offsetY } = this.props;
    const { clientWidth } =  document.documentElement;
    const currentOffsetX = offsetX || 0;
    const currentOffsetY = offsetY || 0;
    let x = 0;

    if (isMobile) {
      x = -((width - clientWidth) * 0.5) - currentOffsetX;
    }

    this.subscribeEvents();
    this.setState({
      x, y: (() => {
        const rect = document.querySelector('.trainer-funny-points__image').getBoundingClientRect();
        const { top, height } = rect;
        const { clientHeight } = document.documentElement;
        return (clientHeight - height) / 2 - top - currentOffsetY;
      })()
    });
  }

  componentDidUpdate() {
    const { width, height, isMobile, isActive, offsetX, offsetY } = this.props;

    if (!isActive) {
      const currentOffsetX = offsetX || 0;
      const currentOffsetY = offsetY || 0;
      const { clientWidth, clientHeight } = document.documentElement;
      const x = isMobile ? -((width - clientWidth) * 0.5) : 0;
      const y = -((height - clientHeight) * 0.5);
      const heightTabs = isMobile ? MOBILE_HEIGHT_TABS : DESKTOP_HEIGHT_TABS;

      document.body.style.overflow = 'hidden';
      this.setState({ x: x - currentOffsetX, y: y - currentOffsetY - heightTabs });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    this.unsubscribeEvents();
  }

  subscribeEvents = () => {
    document.addEventListener('mouseup', this.onPanEnd);
    document.addEventListener('touchend', this.onPanEnd);
    document.addEventListener('mousemove', this.onPan);
    document.addEventListener('touchmove', this.onPan);
  }

  unsubscribeEvents = () => {
    document.removeEventListener('mouseup', this.onPanEnd);
    document.removeEventListener('touchend', this.onPanEnd);
    document.removeEventListener('mousemove', this.onPan);
    document.removeEventListener('touchmove', this.onPan);
  }

  onPanStart = (event) => {
    if (!this.props.isActive || event.target.classList.contains('trainer-funny-points__point')) return;

    const { x, y } = this.state;

    let { pageX, pageY } = event;
    if (event.type === 'touchstart') {
      pageX = event.touches[0].clientX;
      pageY = event.touches[0].clientY;
    }

    this.setState({
      isPanned: true,
      panAnchor: {
        x: pageX,
        y: pageY,
      },
      currentAnchor: { x, y }
    });
  };

  onPan = (event) => {
    const { panAnchor, currentAnchor, isPanned } = this.state;
    const { x, y } = panAnchor;
    const { x: cx, y: cy } = currentAnchor;

    if (!isPanned) {
      return;
    }

    const { width, height, scale } = this.props;
    let { pageX, pageY } = event;
    if (event.type === 'touchmove') {
      pageX = event.touches[0].clientX;
      pageY = event.touches[0].clientY;
    }

    if (Math.abs(pageX - x + cx) <= width * scale) {
      this.setState({ x: pageX - x + cx });
    }

    if (Math.abs(pageY - y + cy) <= height * scale) {
      this.setState({ y: pageY - y + cy });
    }
  };

  onPanEnd = () => {
    this.setState({ isPanned: false });
  };

  render() {
    const { x, y } = this.state;
    const { scale, width, height } = this.props;
    const styleTranslate = { width, height, transform: `translate(${x}px, ${y}px)` };
    const styleScale = {
      width,
      height,
      flexGrow: 20,
      transform: `scale(${scale})`,
      transition: 'transform .2s ease-in'
    };

    return (
      <div
        className="pan-and-zoom-wrapper"
        onMouseDown={this.onPanStart}
        onMouseMove={this.onPan}
        onMouseUp={this.onPanEnd}
        onTouchStart={this.onPanStart}
        onTouchMove={this.onPan}
        onTouchEnd={this.onPanEnd}
      >
        <div className="pan-and-zoom-wrapper__translate" style={styleTranslate}>
          <div className="pan-and-zoom-wrapper__scale" style={styleScale}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

PanAndZoomWrapper.propTypes = {
  scale: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default PanAndZoomWrapper;
