import React, { useEffect, useState } from 'react';
import XLSX from 'xlsx';

import Logo from '~/components/Logo/Logo';
import Page from '~/containers/Page/Page';

import apiClient from '~/services/api-client';
import getQueryVariable from '~/services/get-query-variable';

import './generate-tokens-page.scss';

const GenerateTokensPage = () => {
  const [ tokens, setTokens ] = useState(null);
  const [ isPending, setPending ] = useState(true);
  const title = tokens ? 'Скачать сгенерированные токены' : 'Проверьте данные в адресной строке!';

  const downloadAsExcelFile = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      tokens.map((account) => {
        const { id, name, token } = account;
        const serviceXLSX = {};

        serviceXLSX['Номер'] = id;
        serviceXLSX['Имя'] = name;
        serviceXLSX['Токен'] = token;

        return serviceXLSX;
      })
    );
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      'tokens'
    );
    XLSX.writeFile(
      workbook,
      'tokens.xls'
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      const count = getQueryVariable('count');
      const secret = getQueryVariable('secret');
      const { result } = await apiClient.generateTokens({ count, secret });

      setPending(false);
      result.length && setTokens(result);
    };
    fetchData();
  }, []);

  return (
    <Page notHeader={ true }>
      <div className="generate-tokens-page">
        <div className="generate-tokens-page__logo">
          <Logo />
        </div>
        <h1 className="generate-tokens-page__link" onClick={ tokens && downloadAsExcelFile }>
          { isPending ? 'Подождите, пока сгенерируются токены!' : title }
        </h1>
      </div>
    </Page>
  );
}

export default GenerateTokensPage;
