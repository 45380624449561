export default [
  'авария', 'август', 'акцент', 'альбом', 'анализ', 'ананас', 'аптека', 'апрель', 'анкета', 'арахис', 'аромат',
  'артист', 'ателье', 'балкон', 'бамбук', 'бардак', 'барсук', 'бедняк', 'бензин', 'богиня', 'боевик', 'буйвол',
  'бульон', 'бумага', 'ваниль', 'вампир', 'ведьма', 'винтик', 'власть', 'внучка', 'воздух', 'вокзал', 'волчок',
  'ворона', 'ворота', 'восток', 'вулкан', 'высота', 'выступ', 'гадюка', 'газель', 'газета', 'гарнир', 'гвоздь',
  'гепард', 'глазок', 'гитара', 'глобус', 'гипноз', 'глоток', 'глупец', 'гнездо', 'голова', 'голубь', 'горшок',
  'грабли', 'градус', 'гранат', 'грелка', 'дворец', 'дерево', 'десерт', 'диплом', 'добыча', 'доктор', 'дракон',
  'дружба', 'духота', 'дьявол', 'ералаш', 'ерунда', 'жадина', 'жалоба', 'жалюзи', 'жаркое', 'жасмин', 'жвачка',
  'железо', 'желток', 'жемчуг', 'жертва', 'жилище', 'журнал', 'задача', 'замена', 'заноза', 'звезда', 'звонок',
  'здание', 'зелень', 'земляк', 'злость', 'значок', 'знаток', 'измена', 'имбирь', 'кабина', 'каблук', 'какаду',
  'канава', 'капкан', 'каратэ', 'караул', 'карета', 'карлик', 'карман', 'картон', 'кашель', 'каштан', 'кувшин',
  'кузнец', 'куплет', 'курага', 'курица', 'куртка', 'лагерь', 'ладонь', 'ландыш', 'ластик', 'лентяй', 'лисица',
  'логика', 'львица', 'льдина', 'любовь', 'люлька', 'люстра', 'магнит', 'макака', 'малина', 'мамонт', 'массаж',
  'мастер', 'матрос', 'машина', 'мебель', 'медаль', 'медуза', 'микроб', 'миксер', 'минута', 'модель', 'молния',
  'молоко', 'момент', 'монета', 'мрамор', 'мудрец', 'музыка', 'павлин', 'палата', 'пальто', 'пальма', 'память',
  'панама', 'пастух', 'паштет', 'педаль', 'письмо', 'пиявка', 'платок', 'платье', 'плюшка', 'победа', 'погода',
  'погоня', 'пончик', 'правда', 'прикол', 'прилив', 'припев', 'провод', 'птенец', 'пыльца', 'работа', 'рабыня',
  'радуга', 'резина', 'ремонт', 'ремень', 'родник', 'рюкзак', 'рябина', 'свинья', 'сердце', 'сестра', 'сливки',
  'собака', 'солдат', 'солнце', 'сорока', 'стакан', 'страус', 'стрела', 'сугроб', 'судьба', 'сухарь', 'танцор',
  'творог', 'термос', 'теннис', 'тормоз', 'указка', 'улитка', 'ученик', 'фантик', 'фартук', 'фасоль', 'флажок',
  'фольга', 'футбол', 'хирург', 'хищник', 'царица', 'цветок', 'цемент', 'чайник', 'чеснок', 'шашлык', 'шерсть',
  'юбилей', 'яблоко', 'январь', 'янтарь', 'ястреб', 'береза', 'вахтер', 'грабеж', 'желудь', 'утенок'
];
