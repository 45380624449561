export default {
  image: require('~/static/trainers/funnyPoints/squid.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  offsetY: 25,
  points: [
    { id: 1,  x: 18,   y: 755 },
    { id: 2,  x: -1,   y: 810 },
    { id: 3,  x: -38,  y: 835 },
    { id: 4,  x: -69,  y: 796 },
    { id: 5,  x: -50,  y: 692 },
    { id: 6,  x: -106, y: 768 },
    { id: 7,  x: -158, y: 786 },
    { id: 8,  x: -165, y: 733 },
    { id: 9,  x: -119, y: 653 },
    { id: 10, x: -98,  y: 576 },
    { id: 11, x: -123, y: 515 },
    { id: 12, x: -131, y: 429 },
    { id: 13, x: -166, y: 397 },
    { id: 14, x: -191, y: 345 },
    { id: 15, x: -167, y: 294  },
    { id: 16, x: -134, y: 253 },
    { id: 17, x: -102, y: 218 },
    { id: 18, x: -59,  y: 189 },
    { id: 19, x: -42,  y: 154 },
    { id: 20, x: -17,  y: 118 },
    { id: 21, x: 18,   y: 95 },
    { id: 22, x: 53,   y: 119 },
    { id: 23, x: 78,   y: 154 },
    { id: 24, x: 95,   y: 189 },
    { id: 25, x: 138,  y: 218 },
    { id: 26, x: 171,  y: 253  },
    { id: 27, x: 204,  y: 294 },
    { id: 28, x: 226,  y: 345 },
    { id: 29, x: 203,  y: 397 },
    { id: 30, x: 166,  y: 429 },
    { id: 31, x: 158,  y: 514 },
    { id: 32, x: 133,  y: 575 },
    { id: 33, x: 155,  y: 652 },
    { id: 34, x: 200,  y: 732 },
    { id: 35, x: 194,  y: 787 },
    { id: 36, x: 142,  y: 768 },
    { id: 37, x: 86,   y: 692 },
    { id: 38, x: 104,  y: 796 },
    { id: 39, x: 73,   y: 835 },
    { id: 40, x: 37,   y: 810 }
  ]
};
