export default ({ padding, aspectRatioContent, widthContent, heightContent }) => {
  const aspectRatioZone = widthContent / heightContent;

  if (aspectRatioZone > aspectRatioContent) {
    return {
      width: `${ heightContent * aspectRatioContent - 2 * padding }px`,
      height: `${ heightContent - 2 * padding }px`
    };
  }

  return {
    width: `${ widthContent - 2 * padding }px`,
    height: `${ widthContent / aspectRatioContent - 2 * padding }px`
  };
};
