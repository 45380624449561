import level1 from './baseSequences/level1';
import level3 from './baseSequences/level3';
import level5 from './baseSequences/level5';
import level7 from './baseSequences/level7';
import level9 from './baseSequences/level9';

export default {
  1: level1,
  2: level1,
  3: level3,
  4: level3,
  5: level5,
  6: level5,
  7: level7,
  8: level7,
  9: level9,
  10: level9
};
