import foxman from '../figures/foxman';
import squid from '../figures/squid';
import mouse from '../figures/mouse';
import wolf from '../figures/wolf';
import snake from '../figures/snake';
import deer from '../figures/deer';
import pig from '../figures/pig';
import lion from '../figures/lion';
import stork from '../figures/stork';
import bear from '../figures/bear';

export default [
  foxman,
  squid,
  mouse,
  wolf,
  snake,
  deer,
  pig,
  lion,
  stork,
  bear
];
