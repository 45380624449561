export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    tablet: 5,
    mobile: 15
  },
  outline: [
    {
      desktop: {
        x: 226.32,
        y: 52.32
      },
      tablet: {
        x: 233.28,
        y: 51.28
      },
      mobile: {
        x: 163.22,
        y: 33.21
      }
    },
    {
      desktop: {
        x: 271,
        y: 97
      },
      tablet: {
        x: 177,
        y: 107.57
      },
      mobile: {
        x: 121,
        y: 75.43
      }
    },
    {
      desktop: {
        x: 271,
        y: 187
      },
      tablet: {
        x: 233,
        y: 107.57
      },
      mobile: {
        x: 163,
        y: 75.43
      }
    },
    {
      desktop: {
        x: 245,
        y: 213
      },
      tablet: {
        x: 233,
        y: 130
      },
      mobile: {
        x: 163,
        y: 93
      }
    },
    {
      desktop: {
        x: 424,
        y: 213
      },
      tablet: {
        x: 193,
        y: 170
      },
      mobile: {
        x: 133,
        y: 123
      }
    },
    {
      desktop: {
        x: 334.32,
        y: 302.68
      },
      tablet: {
        x: 193,
        y: 249.57
      },
      mobile: {
        x: 133,
        y: 182.4
      }
    },
    {
      desktop: {
        x: 371.92,
        y: 340.28
      },
      tablet: {
        x: 249.57,
        y: 306.14
      },
      mobile: {
        x: 175.5,
        y: 224.85
      }
    },
    {
      desktop: {
        x: 244.64,
        y: 340.28
      },
      tablet: {
        x: 362.71,
        y: 306.14
      },
      mobile: {
        x: 259.28,
        y: 224.85
      }
    },
    {
      desktop: {
        x: 181,
        y: 276.63
      },
      tablet: {
        x: 329.28,
        y: 272.72
      },
      mobile: {
        x: 233.21,
        y: 198.79
      }
    },
    {
      desktop: {
        x: 181,
        y: 187
      },
      tablet: {
        x: 409,
        y: 193
      },
      mobile: {
        x: 293,
        y: 139
      }
    },
    {
      desktop: {
        x: 226,
        y: 142
      },
      tablet: {
        x: 250,
        y: 193
      },
      mobile: {
        x: 177,
        y: 139
      }
    },
    {
      desktop: {
        x: 226,
        y: 115.46
      },
      tablet: {
        x: 273,
        y: 170
      },
      mobile: {
        x: 193,
        y: 123
      }
    },
    {
      desktop: {
        x: 163,
        y: 115.64
      },
      tablet: {
        x: 273,
        y: 91
      },
      mobile: {
        x: 193,
        y: 62.14
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 226,
        y: 52,
        rotation: -0
      },
      tablet: {
        x: 233,
        y: 51,
        rotation: -0
      },
      mobile: {
        x: 163,
        y: 33,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 371.92,
        y: 340.28,
        rotation: -135
      },
      tablet: {
        x: 362.71,
        y: 306.14,
        rotation: -135
      },
      mobile: {
        x: 259.28,
        y: 224.85,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 424,
        y: 213,
        rotation: -90
      },
      tablet: {
        x: 409,
        y: 193,
        rotation: -90
      },
      mobile: {
        x: 293,
        y: 139,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 308.28,
        y: 276.64,
        rotation: -135
      },
      tablet: {
        x: 306.14,
        y: 249.57,
        rotation: -135
      },
      mobile: {
        x: 217.85,
        y: 182.43,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 194.82,
        y: 147.46,
        rotation: 135
      },
      tablet: {
        x: 205.28,
        y: 135.85,
        rotation: 135
      },
      mobile: {
        x: 142.21,
        y: 96.64,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 226,
        y: 277,
        rotation: 180
      },
      tablet: {
        x: 233,
        y: 250,
        rotation: 180
      },
      mobile: {
        x: 163,
        y: 183,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 181,
        y: 142,
        rotation: 0
      },
      tablet: {
        x: 193,
        y: 130,
        rotation: 0
      },
      mobile: {
        x: 133,
        y: 93,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
