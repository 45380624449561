export default {
  shapes: [7, 6, 4, 2, 5, 3, 1],
  offset: {
    mobile: 20,
    tablet: 10
  },
  outlines: [
    {
      id: 7,
      rotation: 90,
      desktop: {
        x: 466,
        y: 131
      },
      tablet: {
        x: 452,
        y: 111
      },
      mobile: {
        x: 317,
        y: 87
      }
    },
    {
      id: 6,
      rotation: 270,
      desktop: {
        x: 197,
        y: 221
      },
      tablet: {
        x: 212,
        y: 191
      },
      mobile: {
        x: 138,
        y: 147
      }
    },
    {
      id: 4,
      rotation: 135,
      desktop: {
        x: 301.46,
        y: 252.4
      },
      tablet: {
        x: 296.85,
        y: 218.57
      },
      mobile: {
        x: 205.64,
        y: 167.43
      }
    },
    {
      id: 2,
      rotation: 270,
      desktop: {
        x: 331,
        y: 266
      },
      tablet: {
        x: 325,
        y: 231
      },
      mobile: {
        x: 225.5,
        y: 176
      }
    },
    {
      id: 5,
      rotation: 315,
      desktop: {
        x: 268,
        y: 283.64
      },
      tablet: {
        x: 268,
        y: 246.57
      },
      mobile: {
        x: 183.5,
        y: 188
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 199,
        y: 176
      },
      tablet: {
        x: 214,
        y: 151
      },
      mobile: {
        x: 139,
        y: 117
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 237,
        y: 251.8
      },
      tablet: {
        x: 240,
        y: 218.57
      },
      mobile: {
        x: 163,
        y: 167.43
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 466,
        y: 131
      },
      tablet: {
        x: 174,
        y: 151
      },
      mobile: {
        x: 198,
        y: 87
      }
    },
    {
      desktop: {
        x: 377,
        y: 220
      },
      tablet: {
        x: 213,
        y: 190
      },
      mobile: {
        x: 138.5,
        y: 146.5
      }
    },
    {
      desktop: {
        x: 421,
        y: 220
      },
      tablet: {
        x: 292,
        y: 111
      },
      mobile: {
        x: 109,
        y: 117
      }
    },
    {
      desktop: {
        x: 466,
        y: 266
      },
      tablet: {
        x: 452,
        y: 111
      },
      mobile: {
        x: 79,
        y: 147
      }
    },
    {
      desktop: {
        x: 377.64,
        y: 266
      },
      tablet: {
        x: 372,
        y: 191
      },
      mobile: {
        x: 142.79,
        y: 147
      }
    },
    {
      desktop: {
        x: 395.28,
        y: 283.64
      },
      tablet: {
        x: 405,
        y: 191
      },
      mobile: {
        x: 184.4,
        y: 188.64
      }
    },
    {
      desktop: {
        x: 331.64,
        y: 347.28
      },
      tablet: {
        x: 445,
        y: 231
      },
      mobile: {
        x: 225.93,
        y: 188.64
      }
    },
    {
      desktop: {
        x: 331.64,
        y: 283.62
      },
      tablet: {
        x: 365.57,
        y: 231
      },
      mobile: {
        x: 225.93,
        y: 230.43
      }
    },
    {
      desktop: {
        x: 269.03999999999996,
        y: 283.62
      },
      tablet: {
        x: 381.14,
        y: 246.57
      },
      mobile: {
        x: 268.35,
        y: 188
      }
    },
    {
      desktop: {
        x: 206.42000000000002,
        y: 221
      },
      tablet: {
        x: 324.57,
        y: 303.14
      },
      mobile: {
        x: 256.35,
        y: 176
      }
    },
    {
      desktop: {
        x: 109,
        y: 221
      },
      tablet: {
        x: 324.57,
        y: 246.85
      },
      mobile: {
        x: 315.5,
        y: 176
      }
    },
    {
      desktop: {
        x: 153.75,
        y: 176
      },
      tablet: {
        x: 268.5,
        y: 246.85
      },
      mobile: {
        x: 285.5,
        y: 146
      }
    },
    {
      desktop: {
        x: 198,
        y: 220
      },
      tablet: {
        x: 212.72,
        y: 191
      },
      mobile: {
        x: 258,
        y: 146
      }
    },
    {
      desktop: {
        x: 287,
        y: 131
      },
      tablet: {
        x: 134,
        y: 191
      },
      mobile: {
        x: 317,
        y: 87
      }
    }
  ]
};
