export default ({ pairs, movesLimit, timer }) => {
  const minutes = (timer / 60) >> 0;
  const seconds = timer % 60;
  const minutesText = minutes > 0 ? `${minutes} минут${getEnding(minutes)}` : '';
  const secondsText = seconds > 0 ? `${seconds} секунд${getEnding(seconds)}` : '';
  const tooltipContent = `У тебя есть ${minutesText} ${secondsText} и ${movesLimit} ходов, чтобы найти все пары
                          карточек — то есть 2 карточки с одинаковой картинкой. За один раз ты можешь открыть 2 картинки.
                          Если они не совпали, то закрываются обратно.`;

  return {
    pairs,
    movesLimit,
    timer: [timer * 1000],
    tooltipContent,
    winnerPoints: {
      focus: 2,
      memory: 1
    }
  };
};

function getEnding(value) {
  if(value < 10 || value > 20) {
    switch (value) {
      case 1:
        return 'а';

      case 2:
      case 3:
      case 4:
        return 'ы';

      default:
        return '';
    }
  }
  return '';
};
