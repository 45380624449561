import React, { memo } from 'react';
import { Line, Circle, Group } from 'react-konva';

import { ICON_SIZE } from '../constants';
import { WHITE_COLOR, BORDER_LOSE_COLOR } from '../constants/colors';

const LoseIcon = (props) => {
  return (
    <Group { ...props }>
      <Circle { ...getCircleBackStyle() } />
      <Circle { ...getCircleStyle() } />
      <Line { ...getLineStyle() } />
    </Group>
  );
};

const getCircleBackStyle = () => {
  return {
    radius: ICON_SIZE,
    fill: BORDER_LOSE_COLOR,
    y: ICON_SIZE + 5
  };
};

const getCircleStyle = () => {
  return {
    radius: 7,
    strokeWidth: 3,
    stroke: WHITE_COLOR,
    y: ICON_SIZE + 5
  };
};

const getLineStyle = () =>  {
  return {
    stroke: WHITE_COLOR,
    strokeWidth: 3,
    x: -9,
    y: 17,
    points: [5, 7, 15, -3]
  };
};

export default memo(LoseIcon);
