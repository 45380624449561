import PropTypes from 'prop-types';

export default {
  params: PropTypes.shape({
    difficultyParams: PropTypes.object,
    levels: PropTypes.objectOf(
      PropTypes.shape({
        questions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            image: PropTypes.string,
            text: PropTypes.string,
            options: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                text: PropTypes.text,
              }),
            ),
          }),
        ),
      }),
    ),
    skillPoints: PropTypes.object,
  }).isRequired,
  isTimerActive: PropTypes.bool.isRequired,
  onStartCb: PropTypes.func.isRequired,
  onFinishCb: PropTypes.func.isRequired,
};
