import React from 'react';
import Swiper from 'react-id-swiper';

import Icon from '~/components/Icon/Icon';
import SwiperArrow from '~/components/SwiperArrow/SwiperArrow';

import detectDevice from '~/services/detect-device';

import { START_GRADIENT, START_GRADIENT_MOBILE, END_GRADIENT, END_GRADIENT_MOBILE } from './constants';

import '~/static/styles/swiper.min.css';
import './locationAbout.scss';

// eslint-disable-next-line
import closeView from '~/static/icon/close-view.svg';

const LocationAbout = (props) => {
  const { infoDescription } = props;
  const { isMobile } = detectDevice();

  return (
    <div className="location-about">
      <Swiper { ...getParams() }>
        { renderImages({ ...props, isMobile }) }
      </Swiper>

      { renderCloseButton(props) }

      <p className="location-about__text">{ infoDescription }</p>
    </div>
  );
};

const renderCloseButton = ({ onRequestClose }) => {
  return (
    <button onClick={ onRequestClose } type="button" className='location-about__close-btn'>
      закрыть
      <Icon name="close-view" className="location-about__close-icon" />
    </button>
  );
};

const renderImages = ({ images, isMobile }) => {
  return (
    images.map((image) => {
      const startGradient = isMobile ? START_GRADIENT_MOBILE : START_GRADIENT;
      const endGradient = isMobile ? END_GRADIENT_MOBILE : END_GRADIENT;
      const background = `linear-gradient(180deg, rgba(26, 49, 110, 0) ${ startGradient }%, #1A316E ${ endGradient }%), url(${ image }) no-repeat top center`;
      const backgroundSize = 'cover';
      return <div className="location-about__slide" style={{ background, backgroundSize }}/>;
    })
  );
};

const getParams = () => ({
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    renderPrevButton: () => <button className="swiper-button-prev"><SwiperArrow color="#fff" /></button>,
    renderNextButton: () => <button className="swiper-button-next"><SwiperArrow color="#fff" /></button>,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    containerClass: 'location-about__swiper'
});

export default LocationAbout;
