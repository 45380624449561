export default {
  image: require('~/static/trainers/funnyPoints/binoculars.png'),
  width: 800,
  height: 919,
  offsetY: 30,
  points: [
    { id: 1,  x: 115, y: 175 },
    { id: 2,  x: 112, y: 258 },
    { id: 3,  x: 75,  y: 258 },
    { id: 4,  x: 46,  y: 517 },
    { id: 5,  x: 37,  y: 613 },
    { id: 6,  x: 282, y: 613 },
    { id: 7,  x: 271, y: 517 },
    { id: 8,  x: 237, y: 255 },
    { id: 9,  x: 196, y: 256 },
    { id: 10, x: 196, y: 175 }
  ]
}
