export default {
  shapes: [4, 5, 1, 7, 3, 6, 2],
  offset: {
    tablet: 30,
    mobile: 30
  },
  outlines: [
    {
      id: 4,
      rotation: 315,
      desktop: {
        x: 186,
        y: 217.4
      },
      tablet: {
        x: 189,
        y: 188.6
      },
      mobile: {
        x: 138.2,
        y: 150.5
      }
    },
    {
      id: 5,
      rotation: 45,
      desktop: {
        x: 396.64,
        y: 184.35
      },
      tablet: {
        x: 376.57,
        y: 159.45
      },
      mobile: {
        x: 279.43,
        y: 128.58
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 124,
        y: 217.64
      },
      tablet: {
        x: 133,
        y: 188.57
      },
      mobile: {
        x: 96,
        y: 150.43
      }
    },
    {
      id: 7,
      rotation: 90,
      desktop: {
        x: 335,
        y: 248
      },
      tablet: {
        x: 321,
        y: 216
      },
      mobile: {
        x: 237,
        y: 171
      }
    },
    {
      id: 3,
      rotation: 180,
      desktop: {
        x: 220.5,
        y: 168.5
      },
      tablet: {
        x: 218,
        y: 144.5
      },
      mobile: {
        x: 160,
        y: 117.2
      }
    },
    {
      id: 6,
      rotation: 270,
      desktop: {
        x: 244,
        y: 338
      },
      tablet: {
        x: 240.4,
        y: 296
      },
      mobile: {
        x: 177,
        y: 231
      }
    },
    {
      id: 2,
      rotation: 225,
      desktop: {
        x: 187.53,
        y: 218.99
      },
      tablet: {
        x: 189,
        y: 188.8
      },
      mobile: {
        x: 138.4,
        y: 150.7
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 220.07999999999998,
        y: 122.84
      },
      tablet: {
        x: 217.45000000000002,
        y: 103.95
      },
      mobile: {
        x: 130,
        y: 116.67
      }
    },
    {
      desktop: {
        x: 282.99,
        y: 123.53
      },
      tablet: {
        x: 178,
        y: 64.5
      },
      mobile: {
        x: 130,
        y: 57.2
      }
    },
    {
      desktop: {
        x: 219.38,
        y: 187.14
      },
      tablet: {
        x: 178,
        y: 143.23000000000002
      },
      mobile: {
        x: 159.85999999999999,
        y: 87.06
      }
    },
    {
      desktop: {
        x: 280.24,
        y: 248
      },
      tablet: {
        x: 161.3,
        y: 159.94
      },
      mobile: {
        x: 202.04,
        y: 87.06
      }
    },
    {
      desktop: {
        x: 460.28,
        y: 247.99
      },
      tablet: {
        x: 161.3,
        y: 216.3
      },
      mobile: {
        x: 159.6,
        y: 129.5
      }
    },
    {
      desktop: {
        x: 397.13,
        y: 311.13
      },
      tablet: {
        x: 240.5,
        y: 296
      },
      mobile: {
        x: 201.12,
        y: 171
      }
    },
    {
      desktop: {
        x: 424,
        y: 338
      },
      tablet: {
        x: 400.4,
        y: 296
      },
      mobile: {
        x: 321.84999999999997,
        y: 171.01
      }
    },
    {
      desktop: {
        x: 245,
        y: 338
      },
      tablet: {
        x: 376.78,
        y: 272.38
      },
      mobile: {
        x: 279.43,
        y: 213.43
      }
    },
    {
      desktop: {
        x: 155.85,
        y: 248.85
      },
      tablet: {
        x: 433.14,
        y: 216.02
      },
      mobile: {
        x: 297,
        y: 231
      }
    },
    {
      desktop: {
        x: 155.85,
        y: 185.6
      },
      tablet: {
        x: 272.97,
        y: 216
      },
      mobile: {
        x: 177,
        y: 231
      }
    },
    {
      desktop: {
        x: 175.5,
        y: 165.95999999999998
      },
      tablet: {
        x: 217.38,
        y: 160.42000000000002
      },
      mobile: {
        x: 117.21,
        y: 171.21
      }
    },
    {
      desktop: {
        x: 175.5,
        y: 78.5
      },
      tablet: {
        x: 273.85,
        y: 103.95
      },
      mobile: {
        x: 117.21,
        y: 129.46
      }
    }
  ]
};
