import React from 'react';
import cx from 'classnames';

import Icon from '~/components/Icon/Icon';

// eslint-disable-next-line
import geo from './assets/geo.svg';

import './geo.scss'

const Geo = ({ distance, location }) => {
  const classesGeo = cx('geo', {
    'geo_location': location
  });

  return (
    <div className={ classesGeo }>
      {
        distance &&
        <div className="geo__distance">
          <div className="geo__km">
            { distance } км
          </div>

          <div className="geo__from">
            от штаб квартиры
          </div>
        </div>
      }
      <Icon name="geo" className="geo__icon"/>
      { location && <div className="geo__location">{ location }</div> }
    </div>
  );
};

export default Geo;
