import React from 'react';

import Icon from '~/components/Icon/Icon';

/* eslint-disable */
import eraser from './assets/eraser.svg';
/* eslint-disable */

import './eraser.scss';

const Eraser = (props) => {
  const { onClick } = props;

  return (
    <button className="eraser" onClick={ onClick }>
      <Icon name="eraser" className="eraser__icon" />
    </button>
  )
}

export default Eraser;
