import React, { useEffect } from 'react';
import cx from 'classnames';

import { MARGIN } from '../constants';

import './editCell.scss';

const EditCell = (props) => {
  const { sizeZone, rowIndex, index, item, setCurrentCell, isOver, isActive, cellSize } = props;
  const isCorrect = isOver && item.rightValue;
  const isIncorrect = isOver && !item.rightValue;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    if (isOver) {
      setCurrentCell({ currentCol: null, currentRow: null });
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [ isOver ]);


  const classes = cx('edit-cell', {
    'edit-cell_radius-top-left': rowIndex === 0 && index === 0,
    'edit-cell_radius-top-right': rowIndex === 0 && index === sizeZone - 1,
    'edit-cell_radius-bottom-left': rowIndex === sizeZone - 1 && index === 0,
    'edit-cell_radius-bottom-right': rowIndex === sizeZone - 1 && index === sizeZone - 1,
    'edit-cell_active': isActive,
    'edit-cell_error': isIncorrect,
    'edit-cell_success': isCorrect
  });

  const handleClick = (e) => {
    const target = 'edit-cell__note';
    const { className } = e.target;
    let currentCol = null;
    let currentRow = null;

    if (className.includes(target)) {
      if (!isActive) {
        currentCol = index;
        currentRow = rowIndex;
      }
      setCurrentCell({ currentCol, currentRow });
    }
  };

  const onClickEditCell = (e) => {
    if (isOver) {
      return;
    }
    handleClick(e);
  }

  const handleClickOutside = (e) => {
    const targets = ['edit-cell__notes', 'edit-cell__note', 'tab-number', 'switcher'];
    const { className } = e.target;
    let check = false;

    targets.forEach(item => {
      if (className.includes(item)) {
        check = true;
      }
    });

    if (!check) {
      setCurrentCell({ currentCol: null, currentRow: null });
    }
  };

  const renderNotes = () => {
    return (
      <div className="edit-cell__notes">
        { [ ...item.notes ].map((note, index) => renderNote(note, index)) }
      </div>
    );
  };

  const renderNote = (note, index) => {
    const className = cx('edit-cell__note', {
      'edit-cell__note_mini': isActive || isOver
    });
    const sizeNote = (cellSize - MARGIN) / sizeZone - MARGIN / 2;
    const sizeNoteStyle= `${ sizeNote }px`;
    const noteStyle = { width: sizeNoteStyle, height: sizeNoteStyle };

    return <div key={ index } className={ className } style={ noteStyle }>{ note }</div>;
  };

  const currentSize = (isActive || isCorrect || isIncorrect) ? cellSize - MARGIN : cellSize;
  const cellStyle = { width: `${ currentSize }px`, height: `${ currentSize }px` }

  return (
    <div className={ classes } onClick={ onClickEditCell } style={ cellStyle }>
      { renderNotes() }
      { item.value }
    </div>
  );
}

export default EditCell;
