import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import propTypes from './prop-types';

import { BUTTON_NEXT, BUTTON_CHECK } from '~/utils/constants';

import getShuffleArray from '~/services/get-shuffle-array';

import Card from '~/components/Card/Card';
import Button from '~/components/Button/Button';
import Tabs from '~/components/Tabs/Tabs';

import './trainer-find-spy.scss';

const TrainerFindSpy = (props) => {
  const { params, isTimerActive, onFinishCb, onTimeEnd, onStartCb, onStageChange, handleDataLayer } = props;
  const { difficultyParams, content } = params;
  const { amount, winnerPoints } = difficultyParams;

  const currentContent = useMemo(() => getShuffleArray(content, amount), []);
  const [ chosenCard, setChosenCard ] = useState({ id: null, status: '' });
  const [ correctCard, setCorrectCard ] = useState(null);
  const [ currentGroup, setCurrentGroup ] = useState(0);
  const [ isFinish, setFinish ] = useState(false);
  const [ wins, setWins ] = useState(0);
  const [ isWin, setWin ] = useState(false);
  const [ button, setButton ] = useState({ type: 'check' });
  const [ isButtonHide, hideButton ] = useState(false);

  const isRenderButton = Boolean(chosenCard.id) || Boolean(correctCard);
  const isLast = currentGroup === amount - 1;

  useEffect(() => {
    if(!isTimerActive && !isFinish) {
      checkTrainer();
      handleDataLayer({
        event: 'timerTick',
        eventAction: 'next_force',
        stageName: 'page2_solve',
        stageLevel: currentGroup + 1
      });
    }
  }, [ isTimerActive, isLast ]);

  const onCardClickHandler = (id) => {
    if(button.type === 'next') {
      return;
    }
    setChosenCard({ id, status: 'active' })
  };

  const checkTrainer = () => {
    setFinish(true);
    onTimeEnd();
    const isCorrect = currentContent[currentGroup].answerId  === chosenCard.id;

    if (isCorrect) {
      setWins(wins + 1);
      setChosenCard({ ...chosenCard, status: 'correct' });
      setWin(true);
    }
    else {
      setChosenCard({ ...chosenCard, status: 'incorrect' });
      setCorrectCard(currentContent[currentGroup].answerId);
    }

    if (isLast) {
      checkVictory(isCorrect);
      hideButton(true);
    } else {
      setButton({ type: 'next'});
    }
  }

  const checkVictory = (lastWin) => {
    let reason = 'complete';
    let skills = winnerPoints;

    if (amount !== wins + Number(lastWin)) {
      reason = 'fail';
      skills = null;
    }

    onFinishCb({ victory: Boolean(skills), reason, skills });
  }

  const renderButton = () => {
    const onNextClickHandler = () => {
      if (!isLast) {
        onStageChange();
        onStartCb();
        setChosenCard({ id: null, status: '' });
        setCorrectCard(null);
        setFinish(false);
        setButton({ type: 'check' });
        setCurrentGroup(currentGroup + 1);
        setWin(false);
        handleDataLayer({ stageName: 'page3_check', stageLevel: currentGroup + 1 });
      }
    }
    const onCheckHandler = () => {
      checkTrainer();
      handleDataLayer({ stageName: 'page2_solve', stageLevel: currentGroup + 1 });
    }

    const text = button.type === 'check' ? BUTTON_CHECK : BUTTON_NEXT;
    const onButtonClickHandler = button.type === 'check' ? onCheckHandler : onNextClickHandler;
    const buttonClasses = cx('trainer-find-spy__button', {
      'trainer-find-spy__button_hidden': isButtonHide
    });

    return (
      <div className={ buttonClasses }>
        <Button className="button_center" onClick={ onButtonClickHandler }>
          { text }
        </Button>
      </div>
    );
  }

  const renderCards = (content) => {
    return content && content.images.map((item) => {
      const { id, src } = item;
      const status = id === chosenCard.id ? chosenCard.status :
        correctCard === id ? 'actual' : '';
      return (
        <div className="trainer-find-spy__item" key={ id }>
          <Card
            className="trainer-find-spy__card"
            onClick={ onCardClickHandler.bind(null, id) }
            status={ status }
            isFinish={ isFinish }>
            <img className="trainer-find-spy__img" src={ src } alt={ `Карточка номер ${ id }` }/>
          </Card>
        </div>
      )
    })
  }

  return (
    <section className="trainer-find-spy">
      {
        isFinish && isWin &&
        <div className="trainer-find-spy__tabs">
          <Tabs isCorrect={ true }/>
        </div>
      }
      <div className="trainer-find-spy__title">
        Какая картинка лишняя?
      </div>
      <div className="trainer-find-spy__list">
        { renderCards(currentContent[currentGroup]) }
      </div>
      { isRenderButton && renderButton() }
    </section>
  )
}

TrainerFindSpy.propTypes = propTypes;

export default TrainerFindSpy;
