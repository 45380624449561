export default {
  1: {
    answerId: 3,
    options: [
      { id: 1, text: 'Вот они… Котята!' },
      { id: 2, text: 'Где они… Опята!'  },
      { id: 3, text: 'Вот они… Опята!'  },
      { id: 4, text: 'Опята… Вот они!'  }
    ]
  },
  2: {
    answerId: 2,
    options: [
      { id: 1, text: 'Запрыгнул… Как сильный ветер!'           },
      { id: 2, text: 'Каким ветром спрашиваете? Так… сильным!' },
      { id: 3, text: 'Каким ветром говорите? Так… сильным!'    },
      { id: 4, text: 'Спрашиваете каким ветром? Так… сильным!' }
    ]
  },
  3: {
    answerId: 3,
    options: [
      { id: 1, text: 'Можешь сказать ей, что ты йоркширский терьер!..' },
      { id: 2, text: 'Не говори ей… что ты йоркширский терьер!'        },
      { id: 3, text: 'Скажи ей, что ты йоркширский терьер!..'          },
      { id: 4, text: 'Проговорись ей, что ты йоркширский терьер!..'    }
    ]
  },
  4: {
    answerId: 2,
    options: [
      { id: 1, text: 'Я вам покажу, где раки зимуют!'      },
      { id: 2, text: 'Я вам покажу, кто в доме хозяин!'    },
      { id: 3, text: 'Сейчас расскажу, кто в доме хозяин!' },
      { id: 4, text: 'Знаете, кто в доме хозяин?'          }
    ]
  },
  5: {
    answerId: 3,
    options: [
      { id: 1, text: 'Первый! Второй! Пошел!'          },
      { id: 2, text: 'Внимание! На старт! Шагом марш!' },
      { id: 3, text: 'На старт! Внимание! Марш!'       },
      { id: 4, text: 'На старт! Приготовились! Марш!'  }
    ]
  },
  6: {
    answerId: 3,
    options: [
      { id: 1, text: 'Колобок, а я тебя съем!'               },
      { id: 2, text: 'Колобок, колобок, сейчас я тебя съем!' },
      { id: 3, text: 'Колобок, колобок, я тебя съем!'        },
      { id: 4, text: 'А я тебя съем, колобок!'               }
    ]
  },
  7: {
    answerId: 3,
    options: [
      { id: 1, text: 'Пап, это что? Батут?'      },
      { id: 2, text: 'Мам, это точно? Батут?'    },
      { id: 3, text: 'Мам, это что? Батут?'      },
      { id: 4, text: 'Пап, смотри, я на батуте!' }
    ]
  },
  8: {
    answerId: 2,
    options: [
      { id: 1, text: 'Дождался! Каникулы!' },
      { id: 2, text: 'Ур-р-ра! Каникулы!'  },
      { id: 3, text: 'Каникулы! Ур-р-ра!'  },
      { id: 4, text: 'Ур-р-ра! Выходные!'  }
    ]
  },
  9: {
    answerId: 2,
    options: [
      { id: 1, text: 'Уморили!..' },
      { id: 2, text: 'Утомили!..' },
      { id: 3, text: 'Устал!..'   },
      { id: 4, text: 'Укачало!..' }
    ]
  },
  10: {
    answerId: 2,
    options: [
      { id: 1, text: 'Я тебе говорил, Федор, неправильно ты бутерброд ешь!'             },
      { id: 2, text: 'А я тебе говорил, Дядя Федор, неправильно ты бутерброд ешь!'      },
      { id: 3, text: 'Тебя предупреждали, Дядя Федор, неправильно ты бутерброд ешь!'    },
      { id: 4, text: 'Дядя Федор, сколько тебе говорить, неправильно ты бутерброд ешь!' }
    ]
  },
  11: {
    image: require('~/static/trainers/accuracy/55.png'),
    answerId: 1,
    options: [
      { id: 1, text: 'Дед Мороз приходил, всем подарки принес!'     },
      { id: 2, text: 'Приходил Дед Мороз, всем подарки приготовил!' },
      { id: 3, text: 'Дед Мороз заходил, всем подарки принес!'      },
      { id: 4, text: 'Дед Мороз, всем подарки принес!'              }
    ]
  },
  12: {
    answerId: 4,
    options: [
      { id: 1, text: 'А ты так можешь?'      },
      { id: 2, text: 'У тебя так получится?' },
      { id: 3, text: 'А ты так пробовал?'    },
      { id: 4, text: 'А вы так можете?'      }
    ]
  },
  13: {
    answerId: 3,
    options: [
      { id: 1, text: 'Прочитать страшилку?' },
      { id: 2, text: 'Прочитать анекдот?'   },
      { id: 3, text: 'Прочитать смешилку?'  },
      { id: 4, text: 'Прочитай мне сказку!' }
    ]
  },
  14: {
    answerId: 2,
    options: [
      { id: 1, text: 'Дави на газ… Ванька, давай!'  },
      { id: 2, text: 'Давай, Ванька… Дави на газ!'  },
      { id: 3, text: 'Ванька… Сильнее дави на газ!' },
      { id: 4, text: 'Жми на газ, Ванька!'          }
    ]
  },
  15: {
    answerId: 4,
    options: [
      { id: 1, text: 'Можно орешек попробовать?'         },
      { id: 2, text: 'Дадите орешек погрызть?'           },
      { id: 3, text: 'Орешек очень хочется попробовать!' },
      { id: 4, text: 'Орешек дадите попробовать?'        }
    ]
  },
  16: {
    answerId: 2,
    options: [
      { id: 1, text: 'Вот это известие!'   },
      { id: 2, text: 'Вот это новость!'    },
      { id: 3, text: 'Вот тебе и новости!' },
      { id: 4, text: 'Что за новости?!'    }
    ]
  },
  17: {
    answerId: 4,
    options: [
      { id: 1, text: 'Маленький, плачь, они не пошутили про сумочку!' },
      { id: 2, text: 'Они пошутили про сумочку, не плачь!'            },
      { id: 3, text: 'Не плачь, малыш, они пошутили про сумочку!'     },
      { id: 4, text: 'Не плачь, маленький, они пошутили про сумочку!' }
    ]
  },
  18: {
    answerId: 3,
    options: [
      { id: 1, text: 'Есть можно! Оценил!'    },
      { id: 2, text: 'Проверьте! Есть можно?' },
      { id: 3, text: 'Проверил! Есть можно!'  },
      { id: 4, text: 'Есть можно! Вкусно!'    }
    ]
  },
  19: {
    answerId: 2,
    options: [
      { id: 1, text: 'До свидания!'              },
      { id: 2, text: 'Первое свидание!'          },
      { id: 3, text: 'На прощание!'              },
      { id: 4, text: 'Приглашаю на свидание!'    }
    ]
  },
  20: {
    answerId: 1,
    options: [
      { id: 1, text: 'Вот это завтрак!' },
      { id: 2, text: 'Это на завтрак!'  },
      { id: 3, text: 'Все на завтрак!'  },
      { id: 4, text: 'Ням, вкусняшка'   }
    ]
  },
  21: {
    answerId: 3,
    options: [
      { id: 1, text: 'Равнодушие!'  },
      { id: 2, text: 'Бесчувствие!' },
      { id: 3, text: 'Безразличие!' },
      { id: 4, text: 'Скука'        }
    ]
  },
  22: {
    answerId: 2,
    options: [
      { id: 1, text: 'Я на свете всех быстрее!'   },
      { id: 2, text: 'И пусть весь мир подождет!' },
      { id: 3, text: 'Ути-ути-ути!'               },
      { id: 4, text: 'Весь мир меня подождет!'    }
    ]
  },
  23: {
    answerId: 4,
    options: [
      { id: 1, text: 'Ловкий мошенник!'                        },
      { id: 2, text: 'Какой ловкий  мошенник!'                 },
      { id: 3, text: 'Ловкость лап, и какое мошенничество!'    },
      { id: 4, text: 'Ловкость рук, и никакого мошенничества!' }
    ]
  },
  24: {
    answerId: 3,
    options: [
      { id: 1, text: 'Буду старшим!'        },
      { id: 2, text: 'Остался с младшим!'   },
      { id: 3, text: 'Остался за старшего!' },
      { id: 4, text: 'Я старший!'            }
    ]
  },
  25: {
    answerId: 3,
    options: [
      { id: 1, text: 'Я не я, когда голоден!'   },
      { id: 2, text: 'Я не ты, когда голоден!'  },
      { id: 3, text: 'Ты не ты, когда голоден!' },
      { id: 4, text: 'Ты голоден?'              }
    ]
  },
  26: {
    answerId: 4,
    options: [
      { id: 1, text: 'Идет бычок, качается!'      },
      { id: 2, text: 'Первые шаги очень нелегки!' },
      { id: 3, text: 'Как же трудны первые шаги!' },
      { id: 4, text: 'Очень нелегки первые шаги!' }
    ]
  },
  27: {
    answerId: 3,
    options: [
      { id: 1, text: 'С понедельника сажусь на диету!'    },
      { id: 2, text: 'Подскажите мне диету!'              },
      { id: 3, text: 'С завтрашнего дня сажусь на диету!' },
      { id: 4, text: 'Завтра съем конфету!'               }
    ]
  },
  28: {
    answerId: 1,
    options: [
      { id: 1, text: 'Такси заказывали?'    },
      { id: 2, text: 'Покатаемся на такси?' },
      { id: 3, text: 'Мопса заказывали?'    },
      { id: 4, text: 'Такси вызывали?'      }
    ]
  },
  29: {
    answerId: 3,
    options: [
      { id: 1, text: 'Неважно, что мы разные!'                 },
      { id: 2, text: 'Все-таки мы вместе!'                     },
      { id: 3, text: 'Мы такие разные, но все-таки мы вместе!' },
      { id: 4, text: 'Мы такие классные! И мы вместе!'         }
    ]
  },
  30: {
    answerId: 2,
    options: [
      { id: 1, text: 'Верность!'    },
      { id: 2, text: 'Преданность!' },
      { id: 3, text: 'Покорность!'  },
      { id: 4, text: 'Послушность!' }
    ]
  },
  31: {
    answerId: 1,
    options: [
      { id: 1, text: 'Важные переговоры!'  },
      { id: 2, text: 'Отважные разговоры!' },
      { id: 3, text: 'Важный договор!'     },
      { id: 4, text: 'Дай пять!'           }
    ]
  },
  32: {
    answerId: 4,
    options: [
      { id: 1, text: 'Моя территория!'    },
      { id: 2, text: 'Спящая территория!' },
      { id: 3, text: 'Чья территория?'    },
      { id: 4, text: 'Своя территория!'   }
    ]
  },
  33: {
    answerId: 2,
    options: [
      { id: 1, text: 'Спускайся!' },
      { id: 2, text: 'Сдавайся!'  },
      { id: 3, text: 'Забодаю!'   },
      { id: 4, text: 'Достану!'   }
    ]
  },
  34: {
    answerId: 3,
    options: [
      { id: 1, text: 'Ботинок великоват!'            },
      { id: 2, text: 'Великан!'                      },
      { id: 3, text: '43-й великоват!'               },
      { id: 4, text: '43-й великоват! Дайте меньше!' }
    ]
  },
  35: {
    answerId: 1,
    options: [
      { id: 1, text: 'Понедельник!' },
      { id: 2, text: 'Вторник!'     },
      { id: 3, text: 'Суббота!'     },
      { id: 4, text: 'Воскресенье!' }
    ]
  },
  36: {
    answerId: 3,
    options: [
      { id: 1, text: 'Спец по маскировке!' },
      { id: 2, text: 'Мастер кодировки!'   },
      { id: 3, text: 'Мастер маскировки!'  },
      { id: 4, text: 'Маскировщик!'        }
    ]
  },
  37: {
    answerId: 2,
    options: [
      { id: 1, text: 'Танго на льду!'   },
      { id: 2, text: 'Танцы на льду!'   },
      { id: 3, text: 'Мишки на льду!'   },
      { id: 4, text: 'Мишки на севере!' }
    ]
  },
  38: {
    answerId: 3,
    options: [
      { id: 1, text: 'Охрана превыше всего!'       },
      { id: 2, text: 'Я выше всех!'                },
      { id: 3, text: 'Безопасность превыше всего!' },
      { id: 4, text: 'Безопасность превыше!'       }
    ]
  },
  39: {
    answerId: 4,
    options: [
      { id: 1, text: 'Не хочу замуж, не хочу!' },
      { id: 2, text: 'Хочу замуж!'             },
      { id: 3, text: 'Замуж хочу!'             },
      { id: 4, text: 'Хочу замуж, замуж хочу!' }
    ]
  },
  40: {
    answerId: 3,
    options: [
      { id: 1, text: 'Верный друг!'   },
      { id: 2, text: 'Старые друзья!' },
      { id: 3, text: 'Лучшие друзья!' },
      { id: 4, text: 'Спасибо, друг!' }
    ]
  },
  41: {
    answerId: 2,
    options: [
      { id: 1, text: 'Слегка устал!'         },
      { id: 2, text: 'Легкий обморок!'       },
      { id: 3, text: 'Морока с вами!'        },
      { id: 4, text: 'Почитайте мне сказку!' }
    ]
  },
  42: {
    answerId: 1,
    options: [
      { id: 1, text: 'Вы плывите, ути-ути, плавно, в ряд!'    },
      { id: 2, text: 'Цыпа-цыпа-цыпа, в ряд!'                 },
      { id: 3, text: 'Намочились, ути-ути, встаньте в строй!' },
      { id: 4, text: 'Вы летите, ути-ути!'                    }
    ]
  },
  43: {
    answerId: 2,
    options: [
      { id: 1, text: 'Познакомимся?' },
      { id: 2, text: 'Потанцуем?'    },
      { id: 3, text: 'Поболтаем?'    },
      { id: 4, text: 'Поговорим?'    }
    ]
  },
  44: {
    answerId: 3,
    options: [
      { id: 1, text: 'Мастер!'          },
      { id: 2, text: 'Профи!'           },
      { id: 3, text: 'Профессионал!'    },
      { id: 4, text: 'Профессионально!' }
    ]
  },
  45: {
    answerId: 1,
    options: [
      { id: 1, text: 'Голливудская улыбка!'         },
      { id: 2, text: 'Идеальная улыбка!'            },
      { id: 3, text: 'Красивая улыбка!'             },
      { id: 4, text: 'Твоя улыбка как в Голливуде!' }
    ]
  },
  46: {
    answerId: 2,
    options: [
      { id: 1, text: 'Признание в любви!' },
      { id: 2, text: 'Признание!'         },
      { id: 3, text: 'Признался!'         },
      { id: 4, text: 'Любовь!'            }
    ]
  },
  47: {
    answerId: 4,
    options: [
      { id: 1, text: 'Поехали в далекие края!'             },
      { id: 2, text: 'Мы везем с собой кота!'              },
      { id: 3, text: 'Мы едем, едем, едем!'                },
      { id: 4, text: 'Мы едем, едем, едем в далекие края!' }
    ]
  },
  48: {
    answerId: 2,
    options: [
      { id: 1, text: 'А вот и я!'     },
      { id: 2, text: 'Сюрприз!'       },
      { id: 3, text: 'Испугался?'     },
      { id: 4, text: 'Давай дружить!' }
    ]
  },
  49: {
    answerId: 4,
    options: [
      { id: 1, text: 'Мир!'           },
      { id: 2, text: 'Благодать!'     },
      { id: 3, text: 'Спокойствие!'   },
      { id: 4, text: 'Умиротворение!' }
    ]
  },
  50: {
    answerId: 3,
    options: [
      { id: 1, text: 'Ой, где мама?'     },
      { id: 2, text: 'Ой, где папа?'     },
      { id: 3, text: 'Ой, мама пришла!'  },
      { id: 4, text: 'Ура, мама пришла!' }
    ]
  },
  51: {
    answerId: 3,
    options: [
      { id: 1, text: 'Спасайся кто может!'   },
      { id: 2, text: 'Сейчас догонит!'       },
      { id: 3, text: 'Нас не догонят!'       },
      { id: 4, text: 'Пусть сам нас боится!' }
    ]
  },
  52: {
    answerId: 2,
    options: [
      { id: 1, text: 'Гармоничность!' },
      { id: 2, text: 'Гармония!'      },
      { id: 3, text: 'Гармошка!'      },
      { id: 4, text: 'Гармоника!'     }
    ]
  },
  53: {
    answerId: 1,
    options: [
      { id: 1, text: 'Я шишка, шишка, шишка! Я вовсе не медведь!' },
      { id: 2, text: 'Я мишка, мишка, я вовсе не шишка!'          },
      { id: 3, text: 'Я мишка, полез за шишкой!'                  },
      { id: 4, text: 'Мишка, мишка, скинь шишку!'                 }
    ]
  },
  54: {
    answerId: 2,
    options: [
      { id: 1, text: 'Я самый знаменитый сыщик в мире!' },
      { id: 2, text: 'Я самый известный сыщик в мире!'  },
      { id: 3, text: 'Кто я????'                        },
      { id: 4, text: 'Дело раскрыто известным сыщиком!' }
    ]
  },
  55: {
    answerId: 2,
    options: [
      { id: 1, text: 'А ты так можешь?' },
      { id: 2, text: 'Удивление!'       },
      { id: 3, text: 'Удивился!'        },
      { id: 4, text: 'Вот чудо!'        }
    ]
  },
  56: {
    answerId: 3,
    options: [
      { id: 1, text: 'Споем?'            },
      { id: 2, text: 'Певец от природы!' },
      { id: 3, text: 'Певец от бога!'    },
      { id: 4, text: 'Певец!'            }
    ]
  },
  57: {
    answerId: 1,
    options: [
      { id: 1, text: 'Трудоголики!'    },
      { id: 2, text: 'Трудяги!'        },
      { id: 3, text: 'Работяги!'       },
      { id: 4, text: 'Тяжелая работа!' }
    ]
  },
  58: {
    answerId: 2,
    options: [
      { id: 1, text: 'Покажите документы!' },
      { id: 2, text: 'Ваши документы!'     },
      { id: 3, text: 'Ваши права!'         },
      { id: 4, text: 'Мои документы!'      }
    ]
  },
  59: {
    answerId: 1,
    options: [
      { id: 1, text: 'Суперагент!' },
      { id: 2, text: 'Супергерой!' },
      { id: 3, text: 'Агент 007!'  },
      { id: 4, text: 'Модель!'     }
    ]
  },
  60: {
    answerId: 3,
    options: [
      { id: 1, text: 'Бьют часы!'          },
      { id: 2, text: 'Часы бьют!'          },
      { id: 3, text: 'Когда часы 12 бьют!' },
      { id: 4, text: 'С Новым годом!'      }
    ]
  },
  61: {
    answerId: 2,
    options: [
      { id: 1, text: 'Кто последний?'     },
      { id: 2, text: 'Кто следующий?'     },
      { id: 3, text: 'За чем очередь?'    },
      { id: 4, text: 'Очередь за рыбкой?' }
    ]
  }
};
