import React, { Component } from 'react';
import cx from 'classnames';

import { Stage, Layer } from 'react-konva';
import Button from '~/components/Button/Button';
import Icon from '~/components/Icon/Icon';
import Tabs from '~/components/Tabs/Tabs';
import Field from './Field';

import * as Colors from './constants/colors';
import * as Sizes from './constants';
import { ANSWER } from './constants/names';

import getRandomFigure from './utils/getRandomFigure';
import isNearOutline from './utils/isNearOutline';
import isNearField from './utils/isNearField';
import getShapesPosition from './utils/getShapesPosition';
import { getScreenType, getCanvasParams } from './utils';
import { getHeightShape, getWidthShape } from './utils/shapes';
import { BUTTON_CHECK } from '~/utils/constants';

import './trainerTangram.scss';

// eslint-disable-next-line
import stop from '~/static/icon/stop.svg';

export default class TrainerTangram extends Component {
  state = {
    shapes: [],
    outlines: [],
    outline: [],
    defaultShapes: [],
    defaultOutlines: [],
    defaultOutline: [],
    widthCanvas: 0,
    heightCanvas: 0,
    offset: null,
    isWin: false,
    isOver: false,
    isShowRight: false,
    screenType: getScreenType(),
    resultMode: ANSWER
  }

  componentDidMount() {
    const { difficultyParams, baseShapes } = this.props.params;
    const { screenType } = this.state;
    const { shapes, outlines, outline, offset } = getRandomFigure(difficultyParams, baseShapes);
    const { widthCanvas, heightCanvas } = getCanvasParams();
    const { shapes: s, heightCanvas: hC, outlines: o, outline: ol } =
    getShapesPosition(outlines, shapes, outline, widthCanvas, heightCanvas, offset, screenType);

    this.setState({ outlines: o, shapes: s, heightCanvas: hC, widthCanvas, defaultShapes: shapes, offset,
                    defaultOutlines: outlines, defaultOutline: outline, outline: ol });
  }

  componentDidUpdate(prevProps) {
    const { isTimerActive, handleDataLayer } = this.props;

    if (prevProps.isTimerActive && !isTimerActive) {
      if (this.state.isOver) {
        handleDataLayer({ stageName: Sizes.stageName, eventAction: 'next_auto' });
      }
      else {
        handleDataLayer({ stageName: Sizes.stageName, eventAction: 'next_force', event: 'timerTick' });
      }
      this.checkVictory();
    }
  }

  rotatePoint = ({ x, y }, rad) => {
    const rcos = Math.cos(rad);
    const rsin = Math.sin(rad);
    return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
  }

  handleDragEnd = (e, field, index) => {
    const { target } = e;
    const { shapes, outlines, screenType } = this.state;
    let outlineIndex = index;
    let { defaultPosition } = shapes[index];
    let { x, y } = defaultPosition;
    let out = true;
    let cursor = 'default';
    let newShapes = shapes;
    const newCoords = isNearOutline(target, outlines[index], outlines, screenType);
    const inField = isNearField(field, target);

    if (newCoords) {
      outlineIndex = outlines.findIndex((item) => item.id === newCoords.id);
    }

    const { isEmpty } = outlines[outlineIndex];

    if (newCoords && isEmpty) {
      ({ x, y } = newCoords);
      cursor = 'grab';
      target.parent.moveToBottom();
      shapes[index].draggable = false;
      shapes[index].isActive = false;
    }

    if (newCoords && !isEmpty && inField) {
      ({ x, y } = target.getAttrs());
    }

    if (!newCoords) {
      out = false;
      if (inField) {
        ({ x, y } = target.getAttrs());
        newShapes = shapes.map((item) => {
          return item.out ? item : {
            ...item,
            x: item.defaultPosition.x,
            y: item.defaultPosition.y,
            rotation: 0
          };
        });
        newShapes[index].isActive = true;
      } else {
        newShapes[index].rotation = 0;
        newShapes[index].isActive = false;
      }
    }

    newShapes[index] = {
      ...newShapes[index], x, y,
      stroke: null,
      shadowColor: null,
      shadowBlur: 0,
      out,
      graggable: false,

    };
    document.body.style.cursor = cursor;

    this.setState({ shapes: newShapes, outlines }, () => {
      if (newCoords && isEmpty) {
        outlines[outlineIndex] = {
          ...outlines[outlineIndex],
          stroke: Colors.OUTLINE_COLOR,
          dash: null,
          isEmpty: false
        };

        if (outlines.every((item) => !item.isEmpty)) {
          this.props.onTimeEnd();
          this.setState({ isOver: true });
        }
      }
    });
  }

  handleDragMove = (e, index) => {
    const { shapes, outlines, screenType } = this.state;
    const { x, y } = e.target.attrs;
    let colorOutline = null;
    let newCoords = isNearOutline(e.target, outlines[index], outlines, screenType);
    let outlineIndex = index;

    if (newCoords) {
      outlineIndex = outlines.findIndex((item) => item.id === newCoords.id);
    }

    const { isEmpty } = outlines[outlineIndex];

    if (newCoords && isEmpty) {
      colorOutline = Colors.OUTLINE_ACTIVE_COLOR;
    }

    if (!newCoords || (newCoords && !isEmpty)) {
      const { reference } = shapes[index];
      if (reference) {
        const refIndex = outlines.findIndex((item) => item.id === reference);
        outlines[refIndex] = {
          ...outlines[refIndex],
          stroke: null
        };
      }
    }

    outlines[outlineIndex] = {
      ...outlines[outlineIndex],
      stroke: colorOutline,
      dash: Sizes.DASH_SIZE
    };

    shapes[index] = {
      ...shapes[index], x, y,
      stroke: Colors.SHAPE_BORDER_COLOR,
      shadowColor: Colors.SHAPE_SHADOW_COLOR,
      shadowBlur: 8,
      out: true
    };

    this.setState({ shapes, outlines });
  }

  finishTrainer = ({ reason, skills }) => {
    const { onFinishCb } = this.props;
    onFinishCb({ victory: !!skills, reason, skills });
  }

  checkVictory = () => {
    const { shapes, outlines } = this.state;
    const { difficultyParams } = this.props.params;
    const { winnerPoints } = difficultyParams;
    const isWin = outlines.every((item) => !item.isEmpty);
    const emptyOutlines = outlines.filter((item) => item.isEmpty).map((item) => item.id);
    let reason = 'complete';
    let skills = winnerPoints;

    const newShapes = shapes.map((item) => {
      const isOut = emptyOutlines.includes(item.id);
      const entity = isOut ? { x: item.defaultPosition.x, y: item.defaultPosition.y, rotation: 0 } : {};

      return { ...item, draggable: false, ...entity };
    });

    if (!isWin) {
      reason = 'fail';
      skills = null;
    }

    this.finishTrainer({ reason, skills });
    this.setState({ shapes: newShapes, isWin, isOver: true });
  }

  rotate = (index) => {
    const { shapes, screenType } = this.state;
    const shape = shapes[index];
    const { rotation } = shape;
    const topLeft = { x: -getWidthShape(shape, screenType) / 2, y: -getHeightShape(shape, screenType) / 2 };
    const current = this.rotatePoint(topLeft, shape.rotation * Sizes.KF_RADIAN);

    if (rotation !== Sizes.MAX_ROTATE_ANGLE) {
      shape.rotation += Sizes.ROTATE_ANGLE;
    }
    else {
      shape.rotation = 0;
    }

    const rotated = this.rotatePoint(topLeft, shape.rotation * Sizes.KF_RADIAN);
    const dx = rotated.x - current.x;
    const dy = rotated.y - current.y;

    shape.x += dx;
    shape.y += dy;

    this.setState({ shapes });
  }

  renderInCorrectIcon = () => (
    <div className="trainer-tangram__incorrect">
      <Icon name="stop" className="trainer-tangram__incorrect-icon" />
    </div>
  )

  render() {
    const { shapes, outlines, outline, widthCanvas, heightCanvas, isWin, isOver, screenType, resultMode } = this.state;
    const classes = cx('trainer-tangram', {
      'trainer-tangram_ended': isOver
    });
    const style = { width: widthCanvas };
    const handlerResultClick = (resultMode) => this.setState({ resultMode });

    return (
      <div className={ classes } style={ style }>
        { isOver && <Tabs isCorrect={ isWin } onClick={ handlerResultClick } selected={ resultMode } /> }
        <Stage width={ widthCanvas } height={ heightCanvas }>
          <Layer>
            <Field isOver={ isOver } isShowRight={ resultMode !== ANSWER } isWin={ isWin }
                   shapes={ shapes } outlines={ outlines } outline={ outline }
                   screenType={ screenType } widthCanvas={ widthCanvas } handleDragEnd={ this.handleDragEnd }
                   handleDragMove={ this.handleDragMove } rotate={ this.rotate } />
          </Layer>
        </Stage>
        {
          !isOver &&
          <Button className="trainer-tangram__button" onClick={ this.checkVictory }>
            { BUTTON_CHECK }
          </Button>
        }
      </div>
    );
  }
}
