import React from 'react';
import Icon from '~/components/Icon/Icon'
import cx from 'classnames';
import statuses from '~/shared/statuses';

import './statuses.scss';

/* eslint-disable */
import newAgent from './assets/newAgent.svg';
import specialAgent from './assets/specialAgent.svg';
import superAgent from './assets/superAgent.svg';
import seniorAgent from './assets/seniorAgent.svg';
import proAgent from './assets/proAgent.svg';
/* eslint-enable */


const Statuses = () => {
  const renderStatus = (status, index) => {
    const { name, iconName } = status;

    return (
      <li className="statuses__item" key={ index }>
        <div className="statuses__status">
          <div className="statuses__box">
            <Icon name={ iconName } className={ cx('statuses__icon', {
              'statuses__icon_pro': iconName === 'proAgent'
            }) } />
          </div>

          <div className="statuses__name">
            <div className="statuses__name-value">
              { name }
            </div>
          </div>
        </div>
      </li>
    )
  }

  return (
    <ul className="statuses">
      {
        statuses.map((status, index) => (renderStatus(status, index)))
      }
    </ul>
  )
}

export default Statuses;
