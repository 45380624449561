export default {
  shapes: [ 1, 7, 6, 2, 4, 5, 3 ],
  offset: {
    mobile: 30
  },
  outline: [
    {
      desktop: { x: 293, y: 20 },
      tablet: { x: 293, y: 25 },
      mobile: { x: 207, y: 14 },
    },
    {
      desktop: { x: 248, y: 65 },
      tablet: { x: 253, y: 65 },
      mobile: { x: 177, y: 44 },
    },
    {
      desktop: { x: 293, y: 110 },
      tablet: { x: 293, y: 105 },
      mobile: { x: 207, y: 74 },
    },
    {
      desktop: { x: 165.7, y: 237.3 },
      tablet: { x: 180, y: 218 },
      mobile: { x: 122, y: 159 },
    },
    {
      desktop: { x: 293, y: 237.3 },
      tablet: { x: 293, y: 218 },
      mobile: { x: 207, y: 159 },
    },
    {
      desktop: { x: 293, y: 245 },
      tablet: { x: 293, y: 227 },
      mobile: { x: 207, y: 167 },
    },
    {
      desktop: { x: 248, y: 289 },
      tablet: { x: 253, y: 267 },
      mobile: { x: 177, y: 197 },
    },
    {
      desktop: { x: 248, y: 379.4 },
      tablet: { x: 253, y: 346.4 },
      mobile: { x: 177, y: 256.6 },
    },
    {
      desktop: { x: 205.5, y: 421.8 },
      tablet: { x: 218.1, y: 381.3 },
      mobile: { x: 149.4, y: 284.2 },
    },
    {
      desktop: { x: 269.2, y: 421.8 },
      tablet: { x: 274.7, y: 381.3 },
      mobile: { x: 191.8, y: 284.2 },
    },
    {
      desktop: { x: 269.2, y: 358.3 },
      tablet: { x: 274.7, y: 325.3 },
      mobile: { x: 191.8, y: 242.2 },
    },
    {
      desktop: { x: 293, y: 334 },
      tablet: { x: 293, y: 307 },
      mobile: { x: 207, y: 227 },
    },
    {
      desktop: { x: 293, y: 290.3 },
      tablet: { x: 293, y: 265 },
      mobile: { x: 207, y: 194.8 },
    },
    {
      desktop: { x: 356, y: 353.3 },
      tablet: { x: 349, y: 321 },
      mobile: { x: 248, y: 236 },
    },
    {
      desktop: { x: 356, y: 415 },
      tablet: { x: 349, y: 373 },
      mobile: { x: 248, y: 274 },
    },
    {
      desktop: { x: 401, y: 369.7 },
      tablet: { x: 389, y: 333 },
      mobile: { x: 278, y: 244 },
    },
    {
      desktop: { x: 356.3, y: 325.3 },
      tablet: { x: 349.5, y: 293.5 },
      mobile: { x: 248.5, y: 214.5 },
    },
    {
      desktop: { x: 356.3, y: 226.7 },
      tablet: { x: 349.5, y: 208.6 },
      mobile: { x: 248.5, y: 152.5 },
    },
    {
      desktop: { x: 383, y: 200.1 },
      tablet: { x: 373, y: 185 },
      mobile: { x: 267, y: 134 },
    },
    {
      desktop: { x: 293, y: 110 },
      tablet: { x: 293, y: 105 },
      mobile: { x: 207, y: 74 },
    },
    {
      desktop: { x: 338, y: 65 },
      tablet: { x: 333, y: 65 },
      mobile: { x: 237, y: 44  },
    },
  ],
  outlines: [
    {
      desktop: { x: 248, y: 20 },
      tablet: { x: 253, y: 25 },
      mobile: { x: 177, y: 14 },
      rotation: 0
    },
    {
      desktop: { x: 293, y: 110 },
      tablet: { x: 293, y: 105 },
      mobile: { x: 207, y: 74 },
      rotation: 45
    },
    {
      desktop: { x: 293, y: 110 },
      tablet: { x: 293, y: 105 },
      mobile: { x: 207, y: 74 },
      rotation: 0
    },
    {
      desktop: { x: 293, y: 380 },
      tablet: { x: 293, y: 347 },
      mobile: { x: 207, y: 257 },
      rotation: 180
    },
    {
      desktop: { x: 237, y: 454 },
      tablet: { x: 246, y: 410 },
      mobile: { x: 170, y: 306 },
      rotation: 225
    },
    {
      desktop: { x: 420, y: 290 },
      tablet: { x: 406, y: 265 },
      mobile: { x: 291, y: 194 },
      rotation: 135
    },
    {
      desktop: { x: 401, y: 415 },
      tablet: { x: 389, y: 373 },
      mobile: { x: 278, y: 274 },
      rotation: 180
    }
  ]
};
