import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Ok from '~/components/Ok/Ok';
import Fail from '~/components/Fail/Fail';

import './card.scss';

const Card = (props) => {
  const { children, className, status, onClick, isFinish } = props;
  const cardClasses = cx('card', className, {
    [`card_${ status }`]: status,
    'card_disabled': isFinish
  });

  return (
    <div className={ cardClasses } onClick={ onClick }>
      { status === 'correct' && <Ok /> }
      { status === 'incorrect' && <Fail /> }
      { children }
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string
};

export default Card;
