export default {
  image: require('~/static/trainers/funnyPoints/dino2.png'),
  width: 800,
  height: 919,
  points: [
    { id: 1,  x: 12,   y: 199 },
    { id: 2,  x: 21,   y: 267 },
    { id: 3,  x: -23,  y: 312 },
    { id: 4,  x: -85,  y: 316 },
    { id: 5,  x: -133, y: 266 },
    { id: 6,  x: -169, y: 288 },
    { id: 7,  x: -196, y: 326 },
    { id: 8,  x: -200, y: 376 },
    { id: 9,  x: -176, y: 437 },
    { id: 10, x: -136, y: 471 },
    { id: 11, x: -82,  y: 490 },
    { id: 12, x: -20,  y: 494 },
    { id: 13, x: 35,   y: 489 },
    { id: 14, x: 86,   y: 470 },
    { id: 15, x: 128,  y: 443 },
    { id: 16, x: 163,  y: 405 },
    { id: 17, x: 186,  y: 364 },
    { id: 18, x: 199,  y: 314 },
    { id: 19, x: 196,  y: 264 },
    { id: 20, x: 182,  y: 210 },
    { id: 21, x: 138,  y: 152 },
    { id: 22, x: 183,  y: 182 },
    { id: 23, x: 225,  y: 239 },
    { id: 24, x: 248,  y: 305 },
    { id: 25, x: 254,  y: 359 },
    { id: 26, x: 254,  y: 415 },
    { id: 27, x: 239,  y: 472 },
    { id: 28, x: 215,  y: 518 },
    { id: 29, x: 180,  y: 555 },
    { id: 30, x: 129,  y: 587 }
  ]
};
