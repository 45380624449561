import React from 'react';

import Button from '~/components/Button/Button'
import AdviceSkill from '~/components/AdviceSkill/AdviceSkill'

import skillConfig from '~/shared/skillConfig'

import './advice.scss';

const Advice = ({ skills }) => {
  const renderSkill = (type) => {
    return (
      <li className="advice__item" key={ type }>
        <AdviceSkill name={ skillConfig.names[type] } type={ type }/>
      </li>
    );
  };

  return (
    <div className="advice">
      <p className="advice__text">
        Агент Фокс, ты хорошо справился с миссией, но некоторые навыки тебе нужно потренировать:
      </p>

      <ul className="advice__list">
        { skills.map((item) => renderSkill(item)) }
      </ul>

      <p className="advice__text">
        Переходи в тренировочный режим и выбирай тренажер!
      </p>

      <Button link="/trainers/" className="button_center button_size-m advice__exit-link">
        открыть тренажеры
      </Button>
    </div>
  );
};

export default Advice;
