export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    desktop: 60,
    tablet: 55,
    mobile: 50
  },
  outline: [
    {
      desktop: {
        x: 261.46,
        y: 54
      },
      tablet: {
        x: 264.85,
        y: 53
      },
      mobile: {
        x: 185.64,
        y: 35
      }
    },
    {
      desktop: {
        x: 197.82,
        y: 117.64
      },
      tablet: {
        x: 208.28,
        y: 109.57
      },
      mobile: {
        x: 143.21,
        y: 77.43
      }
    },
    {
      desktop: {
        x: 197.64,
        y: 307.28
      },
      tablet: {
        x: 208.57,
        y: 279.14
      },
      mobile: {
        x: 143.43,
        y: 201.85
      }
    },
    {
      desktop: {
        x: 324.82,
        y: 307.28
      },
      tablet: {
        x: 320.28,
        y: 279.14
      },
      mobile: {
        x: 228.21,
        y: 201.85
      }
    },
    {
      desktop: {
        x: 325,
        y: 370.82
      },
      tablet: {
        x: 320.28,
        y: 333.57
      },
      mobile: {
        x: 228.21,
        y: 244.43
      }
    },
    {
      desktop: {
        x: 388.28,
        y: 370.64
      },
      tablet: {
        x: 377.14,
        y: 333.57
      },
      mobile: {
        x: 269.85,
        y: 244.43
      }
    },
    {
      desktop: {
        x: 388.28,
        y: 307
      },
      tablet: {
        x: 377.14,
        y: 277
      },
      mobile: {
        x: 269.85,
        y: 201
      }
    },
    {
      desktop: {
        x: 451.46,
        y: 243.82
      },
      tablet: {
        x: 432.85,
        y: 221.28
      },
      mobile: {
        x: 311.64,
        y: 159.21
      }
    },
    {
      desktop: {
        x: 388.46,
        y: 244
      },
      tablet: {
        x: 376.85,
        y: 221.28
      },
      mobile: {
        x: 269.64,
        y: 159.21
      }
    },
    {
      desktop: {
        x: 388.46,
        y: 180.82
      },
      tablet: {
        x: 376.85,
        y: 165.28
      },
      mobile: {
        x: 269.64,
        y: 117.21
      }
    },
    {
      desktop: {
        x: 324.82,
        y: 180.82
      },
      tablet: {
        x: 320.28,
        y: 165.28
      },
      mobile: {
        x: 227.21,
        y: 117.21
      }
    },
    {
      desktop: {
        x: 324.82,
        y: 243.64
      },
      tablet: {
        x: 320.28,
        y: 221.57
      },
      mobile: {
        x: 227.21,
        y: 159.43
      }
    },
    {
      desktop: {
        x: 261.64,
        y: 243.64
      },
      tablet: {
        x: 264.57,
        y: 221.57
      },
      mobile: {
        x: 186.43,
        y: 159.43
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 261.46,
        y: 54,
        rotation: -45
      },
      tablet: {
        x: 264.85,
        y: 53,
        rotation: -45
      },
      mobile: {
        x: 185.64,
        y: 35,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 324.92,
        y: 307.28,
        rotation: -135
      },
      tablet: {
        x: 321.71,
        y: 279.14,
        rotation: -135
      },
      mobile: {
        x: 228.28,
        y: 201.85,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 261,
        y: 243.64,
        rotation: 45
      },
      tablet: {
        x: 264,
        y: 221.57,
        rotation: 45
      },
      mobile: {
        x: 185,
        y: 159.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 325.28,
        y: 180.64,
        rotation: -135
      },
      tablet: {
        x: 321.14,
        y: 165.57,
        rotation: -135
      },
      mobile: {
        x: 228.85,
        y: 118.43,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 293,
        y: 338.82,
        rotation: 45
      },
      tablet: {
        x: 292,
        y: 305.28,
        rotation: 45
      },
      mobile: {
        x: 207,
        y: 223.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 419.64,
        y: 212,
        rotation: -45
      },
      tablet: {
        x: 404.57,
        y: 193,
        rotation: -45
      },
      mobile: {
        x: 290.43,
        y: 138,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 293,
        y: 212.64,
        rotation: 45
      },
      tablet: {
        x: 292,
        y: 193.57,
        rotation: 45
      },
      mobile: {
        x: 206,
        y: 138.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
};
