import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import Popup from '~/components/Popup/Popup';

import foxman from '~/static/image/foxman.png';
import './give-up-popup.scss';

const GiveUpPopup = (props) => {
  const { giveUpHandler, isInMission, isOpen, backHandler } = props;
  const handlerClickGiveUp = () => {
    giveUpHandler();
    backHandler();
  };
  const giveUpText = isInMission ? 'Сдаться и перейти к следующему заданию' :
                                   'Сдаться и перейти в каталог тренажеров';

  return (
    <Popup className="give-up-popup" isOpen={ isOpen }>
      <div className="give-up-popup__holder">
        <div className="give-up-popup__title">Хочешь пропустить это задание?</div>
        {
          isInMission &&
          <div className="give-up-popup__text">Если сдашься, то это повлияет на прохождение миссии</div>
        }
        <Button className="give-up-popup__back-button" onClick={ backHandler }>вернуться к заданию</Button>
        <Button className="give-up-popup__give-up-button" theme='grey-link' onClick={ handlerClickGiveUp }>
          { giveUpText }
        </Button>
        <div className="give-up-popup__part">
          <Icon name="part" className="give-up-popup__part-icon" />
        </div>
      </div>
      { renderFoxman() }
    </Popup>
  );
};

const renderFoxman = () => (
  <div className="give-up-popup__foxman">
    <img src={ foxman } className="give-up-popup__foxman-img" alt="foxman" />
  </div>
);

GiveUpPopup.propTypes = {
  giveUpHandler: PropTypes.func,
  backHandler: PropTypes.func,
  isInMisison: PropTypes.bool,
  isOpen: PropTypes.bool
};

export default GiveUpPopup;
