export default {
  shapes: [7, 6, 4, 2, 5, 3, 1],
  offset: {
    mobile: 30,
    tablet: 10
  },
  outlines: [
    {
      id: 7,
      rotation: 135,
      desktop: {
        x: 461.25,
        y: 238.5
      },
      tablet: {
        x: 442.71,
        y: 221.14
      },
      mobile: {
        x: 308.71,
        y: 159.85
      }
    },
    {
      id: 6,
      rotation: 180,
      desktop: {
        x: 334,
        y: 291
      },
      tablet: {
        x: 330,
        y: 268
      },
      mobile: {
        x: 224.43,
        y: 195
      }
    },
    {
      id: 4,
      rotation: 270,
      desktop: {
        x: 134,
        y: 292
      },
      tablet: {
        x: 155,
        y: 271
      },
      mobile: {
        x: 85.43,
        y: 206
      }
    },
    {
      id: 2,
      rotation: 270,
      desktop: {
        x: 333,
        y: 284
      },
      tablet: {
        x: 329,
        y: 260
      },
      mobile: {
        x: 223.43,
        y: 189
      }
    },
    {
      id: 5,
      rotation: 180,
      desktop: {
        x: 300,
        y: 236
      },
      tablet: {
        x: 302,
        y: 216
      },
      mobile: {
        x: 195.43,
        y: 165
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 378,
        y: 289
      },
      tablet: {
        x: 369,
        y: 267
      },
      mobile: {
        x: 253.43,
        y: 193
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 115,
        y: 215.64
      },
      tablet: {
        x: 138,
        y: 203.57
      },
      mobile: {
        x: 73,
        y: 155.43
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 210,
        y: 183.82
      },
      tablet: {
        x: 329.78,
        y: 108.22
      },
      mobile: {
        x: 224.14000000000001,
        y: 75.29
      }
    },
    {
      desktop: {
        x: 146.82,
        y: 183.82
      },
      tablet: {
        x: 302,
        y: 136
      },
      mobile: {
        x: 194.43,
        y: 105
      }
    },
    {
      desktop: {
        x: 146.82,
        y: 247
      },
      tablet: {
        x: 222,
        y: 136
      },
      mobile: {
        x: 135.43,
        y: 105
      }
    },
    {
      desktop: {
        x: 134,
        y: 247
      },
      tablet: {
        x: 222,
        y: 175.29
      },
      mobile: {
        x: 135.43,
        y: 134.22
      }
    },
    {
      desktop: {
        x: 179.25,
        y: 292
      },
      tablet: {
        x: 166.28,
        y: 175.29
      },
      mobile: {
        x: 94.22,
        y: 134.22
      }
    },
    {
      desktop: {
        x: 224,
        y: 247
      },
      tablet: {
        x: 166.28,
        y: 231
      },
      mobile: {
        x: 94.22,
        y: 176
      }
    },
    {
      desktop: {
        x: 210.45999999999998,
        y: 247
      },
      tablet: {
        x: 155,
        y: 231
      },
      mobile: {
        x: 85.43,
        y: 176
      }
    },
    {
      desktop: {
        x: 210.45999999999998,
        y: 235.54000000000002
      },
      tablet: {
        x: 195,
        y: 271
      },
      mobile: {
        x: 115.43,
        y: 206
      }
    },
    {
      desktop: {
        x: 244.5,
        y: 201.5
      },
      tablet: {
        x: 235,
        y: 231
      },
      mobile: {
        x: 145.43,
        y: 176
      }
    },
    {
      desktop: {
        x: 332.38,
        y: 289.38
      },
      tablet: {
        x: 222.85,
        y: 231
      },
      mobile: {
        x: 136.64000000000001,
        y: 176
      }
    },
    {
      desktop: {
        x: 288,
        y: 334
      },
      tablet: {
        x: 222.85,
        y: 215.15
      },
      mobile: {
        x: 136.64000000000001,
        y: 163.79000000000002
      }
    },
    {
      desktop: {
        x: 378,
        y: 334
      },
      tablet: {
        x: 250,
        y: 188
      },
      mobile: {
        x: 164.93,
        y: 135.5
      }
    },
    {
      desktop: {
        x: 334,
        y: 290.24
      },
      tablet: {
        x: 329,
        y: 267
      },
      mobile: {
        x: 222.93,
        y: 193.5
      }
    },
    {
      desktop: {
        x: 334,
        y: 239.02
      },
      tablet: {
        x: 289,
        y: 307
      },
      mobile: {
        x: 193.43,
        y: 223
      }
    },
    {
      desktop: {
        x: 378,
        y: 284
      },
      tablet: {
        x: 369,
        y: 307
      },
      mobile: {
        x: 253.43,
        y: 223
      }
    },
    {
      desktop: {
        x: 468,
        y: 284
      },
      tablet: {
        x: 330,
        y: 268
      },
      mobile: {
        x: 224.43,
        y: 194
      }
    },
    {
      desktop: {
        x: 423.49,
        y: 238.5
      },
      tablet: {
        x: 330,
        y: 221
      },
      mobile: {
        x: 224.43,
        y: 160
      }
    },
    {
      desktop: {
        x: 461.25,
        y: 238.5
      },
      tablet: {
        x: 369,
        y: 260
      },
      mobile: {
        x: 253.43,
        y: 189
      }
    },
    {
      desktop: {
        x: 334,
        y: 111
      },
      tablet: {
        x: 449,
        y: 260
      },
      mobile: {
        x: 313.43,
        y: 189
      }
    },
    {
      desktop: {
        x: 299,
        y: 146
      },
      tablet: {
        x: 410.14,
        y: 221.14
      },
      mobile: {
        x: 284.28,
        y: 159.86
      }
    },
    {
      desktop: {
        x: 210,
        y: 146
      },
      tablet: {
        x: 442.71,
        y: 221.14
      },
      mobile: {
        x: 308.71000000000004,
        y: 159.86
      }
    }
  ]
};
