import React from 'react';

const PageNotFound = () => {
  return (
    <div className="404">
      Страница не найдена
    </div>
  );
}

export default PageNotFound
