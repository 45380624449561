export default {
  shapes: [ 4, 1, 6, 7, 5, 2, 3 ],
  offset: {
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 140, y: 266.82 },
      tablet: { x: 157, y: 241.85 },
      mobile: { x: 105, y: 176 },
    },
    {
      desktop: { x: 197.82, y: 266.82 },
      tablet: { x: 208.28, y: 241.85 },
      mobile: { x: 144.21, y: 176 },
    },
    {
      desktop: { x: 197.82, y: 204 },
      tablet: { x: 208.28, y: 186 },
      mobile: { x: 144.21, y: 135 },
    },
    {
      desktop: { x: 185, y: 204 },
      tablet: { x: 197, y: 186  },
      mobile: { x: 135, y: 135 },
    },
    {
      desktop: { x: 229.75, y: 159 },
      tablet: {  x: 237, y: 146 },
      mobile: { x: 165, y: 105 },
    },
    {
      desktop: { x: 275, y: 204 },
      tablet: { x: 277, y: 186 },
      mobile: { x: 195, y: 135 },
    },
    {
      desktop: { x: 261.46, y: 204 },
      tablet: { x: 264.85, y: 186 },
      mobile: { x: 186.64, y: 135 },
    },
    {
      desktop: { x: 261.46, y: 266.82 },
      tablet: { x: 264.85, y: 241.85 },
      mobile: { x: 186.64, y: 176 },
    },
    {
      desktop: { x: 319.46, y: 266.82 },
      tablet: { x: 316.28, y: 241.85 },
      mobile: { x: 224.85, y: 176 },
    },
    {
      desktop: { x: 327, y: 259.28 },
      tablet: { x: 325, y: 233.14 },
      mobile: { x: 231, y: 169.85 },
    },
    {
      desktop: { x: 327, y: 171 },
      tablet: { x: 325, y: 154 },
      mobile: { x: 231, y: 110 },
    },
    {
      desktop: { x: 371.5, y: 125.51 },
      tablet: { x: 364, y: 115 },
      mobile: { x: 260.5, y: 80.5 },
    },
    {
      desktop: { x: 327, y: 81.25 },
      tablet: { x: 325, y: 76 },
      mobile: { x: 231, y: 51 },
    },
    {
      desktop: { x: 372, y: 36 },
      tablet: { x: 365, y: 36 },
      mobile: { x: 261, y: 21 },
    },
    {
      desktop: { x: 372, y: 216 },
      tablet: { x: 365, y: 194 },
      mobile: { x: 261, y: 140 },
    },
    {
      desktop: { x: 357.28, y: 230.39 },
      tablet: { x: 349.14, y: 209.86 },
      mobile: { x: 249.85, y: 151.15 },
    },
    {
      desktop: { x: 357.28, y: 267  },
      tablet: { x: 349.14, y: 242 },
      mobile: { x: 249.85, y: 176 },
    },
    {
      desktop: { x: 447, y: 267 },
      tablet: { x: 429, y: 242 },
      mobile: { x: 309, y: 176 },
    },
    {
      desktop: { x: 357, y: 357 },
      tablet: { x: 349.14, y: 321.86 },
      mobile: { x: 249.85, y: 235.15 },
    },
    {
      desktop: { x: 357, y: 356.28 },
      tablet: { x: 349.14, y: 322.14 },
      mobile: { x: 249.85, y: 235.85 },
    },
    {
      desktop: { x: 230.54, y: 356.28 },
      tablet: { x: 236, y: 322.14 },
      mobile: { x: 165.15, y: 235.85 },
    },
    {
      desktop: { x: 230, y: 356.82 },
      tablet: { x: 236.64, y: 321.49 },
      mobile: { x: 165, y: 236 },
    },
  ],
  outlines: [
    {
      desktop: { x: 275, y: 159 },
      tablet: { x: 277, y: 146  },
      mobile: { x: 195, y: 105 },
      rotation: 90
    },
    {
      desktop: { x: 166, y: 235.64 },
      tablet: { x: 180, y: 213.57  },
      mobile: { x: 123, y: 155.43 },
      rotation: 315
    },
    {
      desktop: { x: 320, y: 266.82 },
      tablet: { x: 317, y: 241.85  },
      mobile: { x: 225, y: 176 },
      rotation: 90
    },
    {
      desktop: { x: 357.28, y: 229 },
      tablet: { x: 349.14, y: 209  },
      mobile: { x: 249.85, y: 151 },
      rotation: 45
    },
    {
      desktop: { x: 447, y: 357 },
      tablet: { x: 429, y: 322  },
      mobile: { x: 309, y: 236 },
      rotation: 180
    },
    {
      desktop: { x: 327, y: 125 },
      tablet: { x: 325, y: 114  },
      mobile: { x: 231, y: 80 },
      rotation: 0
    },
    {
      desktop: { x: 327, y: 36 },
      tablet: { x: 325, y: 36  },
      mobile: { x: 231, y: 21 },
      rotation: 0
    }
  ]
};
