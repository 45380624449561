export default [
  {
    src: require(`~/static/trainers/spatial/level3/1.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/2.png`),
    answer: 13
  },
  {
    src: require(`~/static/trainers/spatial/level3/3.png`),
    answer: 15
  },
  {
    src: require(`~/static/trainers/spatial/level3/4.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level3/5.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level3/6.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/7.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/8.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level3/9.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/10.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/11.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level3/12.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level3/13.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/14.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level3/15.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level3/16.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level3/17.png`),
    answer: 15
  },
  {
    src: require(`~/static/trainers/spatial/level3/18.png`),
    answer: 14
  },
  {
    src: require(`~/static/trainers/spatial/level3/19.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level3/20.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/21.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level3/22.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level3/23.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level3/24.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level3/25.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level3/26.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level3/27.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level3/28.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level3/29.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level3/30.png`),
    answer: 14
  }
];
