import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Icon from '~/components/Icon/Icon';
import ProfileGift from '~/components/ProfileGift/ProfileGift';

import gifts from '~/shared/gifts';

import scrollTo from '~/services/scroll-top';
import getPreviewCount from './services/get-preview-count';

import './profile-gifts.scss';

/* eslint-disable */
import sideForm from '~/static/icon/side-form.svg';
/* eslint-enable */

const ProfileGifts = ({ giftsStatus }) => {
  const giftsRef = useRef(null);
  const [ isOpened, setOpen ] = useState(false);
  const previewCount = getPreviewCount();
  const [ currentGifts, setCurrentGifts ] = useState(Object.keys(gifts).slice(0, previewCount));
  const classesGifts = cx('profile-gifts', {
    'profile-gifts_opened': isOpened
  });

  const openMoreGifts = () => {
    let giftNames = Object.keys(gifts);
    setOpen(!isOpened);

    if (isOpened) {
      scrollTo(giftsRef);
      giftNames = giftNames.slice(0, previewCount);
    }

    setCurrentGifts(giftNames);
  };

  const renderSideForms = (amount) => (
    amount.map((num) => {
      const classesGiftsForm = cx('profile-gifts__form', {
        [ `profile-gifts__form_${ num }` ]: num
      });

      return (
        <div className={ classesGiftsForm } key={ num }>
          <Icon name="side-form" className="profile-gifts__form-icon" />
        </div>
      );
    })
  );

  const renderHeader = () => (
    <header className="profile-gifts__header">
        <div className="profile-gifts__title">
          Мой набор агента
        </div>

        <Link className="profile-gifts__to-map" to='/locations/'>
          к локациям
        </Link>
    </header>
  );

  const renderGift = (gift) => {
    const isEarned = giftsStatus.find((item) => item.id === gift);

    return (
      <li className="profile-gifts__item" key={ gift } >
        <ProfileGift gift={ gifts[gift] } isLocked={ !isEarned }/>
      </li>
    );
  };

  return (
    <div className={ classesGifts } ref={ giftsRef }>
      <div className="profile-gifts__holder">
        { renderHeader() }

        <p className="profile-gifts__desc">
          Здесь ты видишь, какие предметы ты уже получил, а какие еще нужно собрать. При открытии новых локаций, здесь будут открываться новые предметы.
        </p>

        <div className="profile-gifts__list-holder">
          <ul className="profile-gifts__list">
            { currentGifts.map(renderGift) }
          </ul>
        </div>
      </div>

      <button onClick={ openMoreGifts } className="profile-gifts__more">
        <span className="profile-gifts__more-text">
        { isOpened ? 'Скрыть' : 'Все доступные предметы' }
        </span>

        <div className="profile-gifts__more-icon" />
      </button>

      { renderSideForms([ 1, 2 ]) }
    </div>
  )
}

export default ProfileGifts;
