import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import Tabs from '~/components/Tabs/Tabs';
import Button from '~/components/Button/Button';
import DynamicContent from '~/components/DynamicContent/DynamicContent';
import MnemonicRemember from './MnemonicRemember/MnemonicRemember';
import MnemonicTest from './MnemonicTest/MnemonicTest';
import MnemonicSlider from './MnemonicSlider/MnemonicSlider';

import { STEP_TEST, STEP_REMEMBER, BUTTON_NEXT, BUTTON_CHECK } from '~/utils/constants';
import { EXTRA_COUNT_IMAGES, ANSWER } from './constants';

import getShuffleArray from '~/services/get-shuffle-array';
import getImagesOrder from './services/get-images-order';
import getContentStyle from '~/services/get-content-style';
import { handlerDragOver, handlerDragLeave } from './services/drag-and-drop';

import usePrevious from '~/hooks/usePrevious';

import * as locationsActions from '~/store/locations/actions';

import './trainer-mnemonic.scss';

const TrainerMnemonic = (props) => {
  const { params, setTrainerStage, isTimerActive, onStartCb, step, handleDataLayer,
          onFinishCb, widthWorkspace, heightWorkspace } = props;
  const { difficultyParams } = params;
  const { amount, cardsInLine, winnerPoints } = difficultyParams;
  const isRemember = step === STEP_REMEMBER;

  const panelRef = useRef(null);
  const [ widthContent, setWidthContent ] = useState(0);
  const [ heightContent, setHeightContent ] = useState(0);
  const [ matrixImages, setMatrixImages ] = useState([]);
  const [ currentMatrix, setCurrentMatrix ] = useState([]);
  const [ correctImages, setCorrectImages ] = useState([]);
  const [ currentImages, setCurrentImages ] = useState([]);
  const [ isFinish, setFinish ] = useState(false);
  const [ isWin, setWin ] = useState(true);
  const [ resultMode, setResultMode ] = useState(ANSWER);
  const [ stepLeft, setStepLeft ] = useState(amount);
  const [ selectedIndex, setSelectedIndex ] = useState(null);
  const [ isClickNextButton, setClickNextButton ] = useState(false);
  const prevIsTimerActive = usePrevious(isTimerActive);
  const isRenderButton = (isRemember || (!isRemember && !isFinish));
  const stageName = isRemember ? 'page2A_task' : 'page2B_solve';

  useEffect(() => {
    generateCards();
    setTrainerStage(STEP_REMEMBER);
  }, []);

  useEffect(() => {
    if (isTimerActive !== prevIsTimerActive && !isTimerActive && isRemember) {
      const newMatrixImages = getShuffleArray(matrixImages);
      setMatrixImages(newMatrixImages);
      setCurrentMatrix(newMatrixImages);
      setTrainerStage(STEP_TEST);
      onStartCb();

      if (!isClickNextButton) {
        handleDataLayer({ event: 'timerTick', eventAction: 'next_force', stageName });
      }

      setClickNextButton(false);
    }

    if (isTimerActive !== prevIsTimerActive && !isTimerActive && !isRemember) {
      setFinish(true);
      checkVictory();

      if (!isClickNextButton) {
        handleDataLayer({ event: 'timerTick', eventAction: 'next_force', stageName });
      }

      setClickNextButton(false);
    }
  }, [ isTimerActive ]);

  const generateCards = () => {
    const matrixImages = getShuffleArray(params.matrixImages, amount + EXTRA_COUNT_IMAGES);
    const correctCards = getShuffleArray(matrixImages, amount);
    const { correctImagesOrder, currentImagesOrder } = getImagesOrder(correctCards, cardsInLine);

    setMatrixImages(matrixImages);
    setCorrectImages(correctImagesOrder);
    setCurrentImages(currentImagesOrder);
  };

  const handlerDragParent = (event, hIndex, vIndex) => {
    const image = currentImages[hIndex][vIndex];
    event.dataTransfer.setData('value', JSON.stringify({ hIndex, vIndex, image }));
  };

  const handlerDrop = (event, hIndex, vIndex) => {
    const value = event.dataTransfer.getData('value');
    const { image, hIndex: hDragIndex } = JSON.parse(value);

    if (hDragIndex === undefined) {
      const newCurrentImages = [ ...currentImages ].map((item) => [ ...item ]);

      newCurrentImages[hIndex][vIndex] = image;
      setCurrentImages(newCurrentImages);
      setSelectedIndex(null);
    }

    event.preventDefault();
  };

  const checkVictory = () => {
    let reason = 'complete';
    let skills = winnerPoints;

    if (JSON.stringify(correctImages) !== JSON.stringify(currentImages)) {
      reason = 'fail';
      skills = null;
      setWin(false);
    }

    onFinishCb({ victory: Boolean(skills), reason, skills });
  };

  const decrementStep = () => setStepLeft(stepLeft - 1);

  const getCardsStyle = () => getContentStyle({ padding: 0, widthContent, heightContent, aspectRatioContent: cardsInLine * cardsInLine / amount });

  const setContentSize = ({ width, height }) => {
    setWidthContent(width);
    setHeightContent(height);
  };

  const renderRememberStep = () => (
    <div className="trainer-mnemonic__step-holder" style={ getCardsStyle() }>
      <MnemonicRemember images={ correctImages } { ...props } />
    </div>
  );

  const renderTestStep = () => (
    <div className="trainer-mnemonic__step-holder" style={ getCardsStyle() }>
      <MnemonicTest currentImages={ currentImages } handlerDropParent={ handlerDrop } currentMatrix={ currentMatrix }
                    handlerDragParent={ handlerDragParent } setCurrentImages={ setCurrentImages }
                    correctImages={ correctImages } handlerDragLeave={ handlerDragLeave } resultMode={ resultMode }
                    handlerDragOver={ handlerDragOver } isFinish={ isFinish } decrementStep={ decrementStep }
                    handlerClickIconParent={ setSelectedIndex } selectedIndex={ selectedIndex }
                    cardsInLine={ cardsInLine } setCurrentMatrix={ setCurrentMatrix }/>
    </div>
  );

  return (
    <div className="trainer-mnemonic">
      { isFinish && renderTab({ isWin, resultMode, setResultMode }) }
      {
        !isFinish && !isRemember &&
        <div ref={ panelRef } className="trainer-mnemonic__slider">
          <MnemonicSlider selectedIndex={ selectedIndex } handlerClickIconParent={ setSelectedIndex } currentMatrix={ currentMatrix } />
        </div>
      }

      <DynamicContent widthWorkspace={ widthWorkspace } heightWorkspace={ heightWorkspace }
                      setSize={ setContentSize } refs={ [ panelRef ] }>
        { isRemember ? renderRememberStep() : renderTestStep() }
      </DynamicContent>

      { isRenderButton && renderButton({ ...props, isRemember, stepLeft, handleDataLayer, setClickNextButton, stageName }) }
    </div>
  );
};

const renderTab = (props) => {
  const { isWin, resultMode, setResultMode } = props;
  const handlerClickTab = (resultMode) => setResultMode(resultMode);

  return <Tabs isCorrect={ isWin } onClick={ handlerClickTab } selected={ resultMode } />;
};

const renderButton = (props) => {
  const { onStageChange, onTimeEnd, isRemember, stepLeft, handleDataLayer, setClickNextButton, stageName } = props;
  const buttonText = isRemember ? BUTTON_NEXT : BUTTON_CHECK;
  const handler = () => {
    setClickNextButton(true);
    handleDataLayer({ eventAction: 'next_self', stageName });
    onStageChange();
    onTimeEnd();
  };

  return (
    <div className="mnemonic-remember__next">
      <Button className="button_center" onClick={ handler } disabled={ !isRemember && stepLeft }>
        { buttonText }
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  setTrainerStage: locationsActions.setTrainerStage,
};

const mapStateToProps = (state) => ({
  step: state.locations.currentTrainerStage
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainerMnemonic);
