export default {
  texts: {
    description: [
      'Наши спецагенты быстро принимают правильное решение даже в самой сложной стрессовой ситуации. И помогает им в этом хорошо развитый навык – мышление. Научись этому и ты!',
      'Наши спецагенты умеют анализировать ситуацию, и это помогает им быть на несколько шагов впереди своих противников. Научись этому и ты!',
      'Умение предвидеть ситуацию на несколько шагов вперед позволяет нашим агентам быть всегда впереди противников. Развивай мышление – и ты всегда будешь впереди всех!'
    ],
    losing: [
      'Чтобы взламывать любые коды, надо много тренироваться! Попробуй ещё раз!',
      'Тренировка очень важна для любого спецагента. Тренируйся – и всё получится!'
    ],
    victory: [
      'Настоящий спецагент! Теперь ты справишься с любым кодовым замком!',
      'Теперь ты готов! Ты сможешь взломать любой сложный код!'
    ]
  },
  textsInMission: {
    description: [
      'Приготовься, у тебя будет совсем немного времени на то, чтобы открыть кодовый замок. Если ты допустишь ошибку, мы потеряем важные данные. Сконцентрируйся!'
    ],
    losing: [
      'К сожалению, данные были удалены. Программистам предстоит длительная работа по их восстановлению. Чтобы больше не допускать таких промахов, потренируй этот навык.',
      'Ну вот... Теперь предстоит долго восстанавливать все данные. Надо больше тренироваться!'
    ],
    victory: [
      'Мистер Фоксман благодарит тебя за столь успешную работу. Все данные сохранены и в полной безопасности!'
    ]
  }
};
