export default {
  shapes: [7, 4, 5, 3, 1, 6, 2],
  offset: {
    mobile: 50,
    tablet: 50,
    desktop: 60
  },
  outlines: [
    {
      id: 7,
      rotation: 135,
      desktop: {
        x: 317.94,
        y: 342.28
      },
      tablet: {
        x: 336.56,
        y: 319.99
      },
      mobile: {
        x: 239,
        y: 233.05
      }
    },
    {
      id: 4,
      rotation: 225,
      desktop: {
        x: 348,
        y: 184.1
      },
      tablet: {
        x: 363.14,
        y: 177.71
      },
      mobile: {
        x: 259.21,
        y: 129.28
      }
    },
    {
      id: 5,
      rotation: 135,
      desktop: {
        x: 317.92,
        y: 215.64
      },
      tablet: {
        x: 335.99,
        y: 206.42
      },
      mobile: {
        x: 239.85,
        y: 149.07
      }
    },
    {
      id: 3,
      rotation: 135,
      desktop: {
        x: 349.1,
        y: 310.64
      },
      tablet: {
        x: 363.71,
        y: 291.42
      },
      mobile: {
        x: 259.64,
        y: 212.07
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 284.64,
        y: 310.36
      },
      tablet: {
        x: 306.85,
        y: 291.42
      },
      mobile: {
        x: 216,
        y: 212.07
      }
    },
    {
      id: 6,
      rotation: 225,
      desktop: {
        x: 190.64,
        y: 215.92
      },
      tablet: {
        x: 222.57,
        y: 206.71
      },
      mobile: {
        x: 154.79,
        y: 150.28
      }
    },
    {
      id: 2,
      rotation: 45,
      desktop: {
        x: 348.1,
        y: 57
      },
      tablet: {
        x: 363.71,
        y: 64.85
      },
      mobile: {
        x: 259.64,
        y: 44.64
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 379.82,
        y: 88.72
      },
      tablet: {
        x: 391.41999999999996,
        y: 149.42000000000002
      },
      mobile: {
        x: 280.43,
        y: 65.86000000000001
      }
    },
    {
      desktop: {
        x: 190.64,
        y: 88.64
      },
      tablet: {
        x: 279.42,
        y: 149.81
      },
      mobile: {
        x: 154.79,
        y: 65.43
      }
    },
    {
      desktop: {
        x: 190.66,
        y: 342.06
      },
      tablet: {
        x: 279.42,
        y: 263.14
      },
      mobile: {
        x: 154.15,
        y: 232.49
      }
    },
    {
      desktop: {
        x: 380.1,
        y: 342.08
      },
      tablet: {
        x: 391.71,
        y: 263.14
      },
      mobile: {
        x: 279.64,
        y: 233.28
      }
    },
    {
      desktop: {
        x: 380.1,
        y: 278.63
      },
      tablet: {
        x: 391.71,
        y: 319.71
      },
      mobile: {
        x: 279.64,
        y: 190.86
      }
    },
    {
      desktop: {
        x: 254.27999999999997,
        y: 278.83
      },
      tablet: {
        x: 223.42,
        y: 319.99
      },
      mobile: {
        x: 197.43,
        y: 190.86
      }
    },
    {
      desktop: {
        x: 254.29999999999998,
        y: 152.26
      },
      tablet: {
        x: 222.57,
        y: 93.57000000000001
      },
      mobile: {
        x: 197.43,
        y: 108.28
      }
    },
    {
      desktop: {
        x: 380,
        y: 152.1
      },
      tablet: {
        x: 391.41999999999996,
        y: 93.14
      },
      mobile: {
        x: 280.43,
        y: 108.07000000000001
      }
    }
  ]
};
