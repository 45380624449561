export default [
  {
    sequence: [1, 2, 4, 8, 10, 20],
    answer: 22,
    actions: ['×2', '+2']
  },
  {
    sequence: [2, 4, 6, 12, 14, 28],
    answer: 30,
    actions: ['×2', '+2']
  },
  {
    sequence: [3, 6, 8, 16, 18, 36],
    answer: 38,
    actions: ['×2', '+2']
  },
  {
    sequence: [4, 8, 10, 20, 22, 44],
    answer: 46,
    actions: ['×2', '+2']
  },
  {
    sequence: [5, 10, 12, 24, 26, 52],
    answer: 54,
    actions: ['×2', '+2']
  },
  {
    sequence: [6, 12, 14, 28, 30, 60],
    answer: 62,
    actions: ['×2', '+2']
  },
  {
    sequence: [7, 14, 16, 32, 34, 68],
    answer: 70,
    actions: ['×2', '+2']
  },
  {
    sequence: [8, 16, 18, 36, 38, 76],
    answer: 78,
    actions: ['×2', '+2']
  },
  {
    sequence: [9, 18, 20, 40, 42, 84],
    answer: 86,
    actions: ['×2', '+2']
  },
  {
    sequence: [10, 20, 22, 44, 46, 92],
    answer: 94,
    actions: ['×2', '+2']
  },
  {
    sequence: [1, 2, 5, 10, 13, 26],
    answer: 29,
    actions: ['×2', '+3']
  },
  {
    sequence: [2, 4, 7, 14, 17, 34],
    answer: 37,
    actions: ['×2', '+3']
  },
  {
    sequence: [3, 6, 9, 18, 21, 42],
    answer: 45,
    actions: ['×2', '+3']
  },
  {
    sequence: [4, 8, 11, 22, 25, 50],
    answer: 53,
    actions: ['×2', '+3']
  },
  {
    sequence: [5, 10, 13, 26, 29, 58],
    answer: 61,
    actions: ['×2', '+3']
  },
  {
    sequence: [6, 12, 15, 30, 33, 66],
    answer: 69,
    actions: ['×2', '+3']
  },
  {
    sequence: [7, 14, 17, 34, 37, 74],
    answer: 77,
    actions: ['×2', '+3']
  },
  {
    sequence: [8, 16, 19, 38, 41, 82],
    answer: 85,
    actions: ['×2', '+3']
  },
  {
    sequence: [9, 18, 21, 42, 45, 90],
    answer: 93,
    actions: ['×2', '+3']
  },
  {
    sequence: [1, 2, 6, 12, 16, 32],
    answer: 36,
    actions: ['×2', '+4']
  },
  {
    sequence: [2, 4, 8, 16, 20, 40],
    answer: 44,
    actions: ['×2', '+4']
  },
  {
    sequence: [3, 6, 10, 20, 24, 48],
    answer: 52,
    actions: ['×2', '+4']
  },
  {
    sequence: [4, 8, 12, 24, 28, 56],
    answer: 60,
    actions: ['×2', '+4']
  },
  {
    sequence: [5, 10, 14, 28, 32, 64],
    answer: 68,
    actions: ['×2', '+4']
  },
  {
    sequence: [6, 12, 16, 32, 36, 72],
    answer: 76,
    actions: ['×2', '+4']
  },
  {
    sequence: [7, 14, 18, 36, 40, 80],
    answer: 84,
    actions: ['×2', '+4']
  },
  {
    sequence: [8, 16, 20, 40, 44, 88],
    answer: 92,
    actions: ['×2', '+4']
  },
  {
    sequence: [9, 18, 22, 44, 48, 96],
    answer: 100,
    actions: ['×2', '+4']
  },
  {
    sequence: [1, 2, 7, 14, 19, 38],
    answer: 43,
    actions: ['×2', '+5']
  },
  {
    sequence: [2, 4, 9, 18, 23, 46],
    answer: 51,
    actions: ['×2', '+5']
  },
  {
    sequence: [3, 6, 11, 22, 27, 54],
    answer: 59,
    actions: ['×2', '+5']
  },
  {
    sequence: [4, 8, 13, 26, 31, 62],
    answer: 67,
    actions: ['×2', '+5']
  },
  {
    sequence: [5, 10, 15, 30, 35, 70],
    answer: 75,
    actions: ['×2', '+5']
  },
  {
    sequence: [6, 12, 17, 34, 39, 78],
    answer: 83,
    actions: ['×2', '+5']
  },
  {
    sequence: [7, 14, 19, 38, 43, 86],
    answer: 91,
    actions: ['×2', '+5']
  },
  {
    sequence: [8, 16, 21, 42, 47, 94],
    answer: 99,
    actions: ['×2', '+5']
  },
  {
    sequence: [1, 2, 9, 18, 25, 50],
    answer: 57,
    actions: ['×2', '+7']
  },
  {
    sequence: [2, 4, 11, 22, 29, 58],
    answer: 65,
    actions: ['×2', '+7']
  },
  {
    sequence: [3, 6, 13, 26, 33, 66],
    answer: 73,
    actions: ['×2', '+7']
  },
  {
    sequence: [4, 8, 15, 30, 37, 74],
    answer: 81,
    actions: ['×2', '+7']
  },
  {
    sequence: [5, 10, 17, 34, 41, 82],
    answer: 89,
    actions: ['×2', '+7']
  },
  {
    sequence: [6, 12, 19, 38, 45, 90],
    answer: 97,
    actions: ['×2', '+7']
  },
  {
    sequence: [1, 2, 10, 20, 28, 56],
    answer: 64,
    actions: ['×2', '+8']
  },
  {
    sequence: [2, 4, 12, 24, 32, 64],
    answer: 72,
    actions: ['×2', '+8']
  },
  {
    sequence: [3, 6, 14, 28, 36, 72],
    answer: 80,
    actions: ['×2', '+8']
  },
  {
    sequence: [4, 8, 16, 32, 40, 80],
    answer: 88,
    actions: ['×2', '+8']
  },
  {
    sequence: [5, 10, 18, 36, 44, 88],
    answer: 96,
    actions: ['×2', '+8']
  },
  {
    sequence: [1, 2, 11, 22, 31, 62],
    answer: 71,
    actions: ['×2', '+9']
  },
  {
    sequence: [2, 4, 13, 26, 35, 70],
    answer: 79,
    actions: ['×2', '+9']
  },
  {
    sequence: [3, 6, 15, 30, 39, 78],
    answer: 87,
    actions: ['×2', '+9']
  },
  {
    sequence: [4, 8, 17, 34, 43, 86],
    answer: 95,
    actions: ['×2', '+9']
  },
  {
    sequence: [1, 2, 12, 24, 34, 68],
    answer: 78,
    actions: ['×2', '+10']
  },
  {
    sequence: [2, 4, 14, 28, 38, 76],
    answer: 86,
    actions: ['×2', '+10']
  },
  {
    sequence: [3, 6, 16, 32, 42, 84],
    answer: 94,
    actions: ['×2', '+10']
  },
  {
    sequence: [1, 3, 4, 12, 13, 39],
    answer: 40,
    actions: ['×3', '+1']
  },
  {
    sequence: [2, 6, 7, 21, 22, 66],
    answer: 67,
    actions: ['×3', '+1']
  },
  {
    sequence: [3, 9, 10, 30, 31, 93],
    answer: 94,
    actions: ['×3', '+1']
  },
  {
    sequence: [1, 3, 5, 15, 17, 51],
    answer: 53,
    actions: ['×3', '+2']
  },
  {
    sequence: [2, 6, 8, 24, 26, 78],
    answer: 80,
    actions: ['×3', '+2']
  },
  {
    sequence: [1, 3, 7, 21, 25, 75],
    answer: 79,
    actions: ['×3', '+2']
  },
  {
    sequence: [1, 4, 5, 20, 21, 84],
    answer: 85,
    actions: ['×4', '+1']
  },
  {
    sequence: [2, 4, 3, 6, 5, 10],
    answer: 9,
    actions: ['×2', '-1']
  },
  {
    sequence: [3, 6, 5, 10, 9, 18],
    answer: 17,
    actions: ['×2', '-1']
  },
  {
    sequence: [4, 8, 7, 14, 13, 26],
    answer: 25,
    actions: ['×2', '-1']
  },
  {
    sequence: [5, 10, 9, 18, 17, 34],
    answer: 33,
    actions: ['×2', '-1']
  },
  {
    sequence: [6, 12, 11, 22, 21, 42],
    answer: 41,
    actions: ['×2', '-1']
  },
  {
    sequence: [7, 14, 13, 26, 25, 50],
    answer: 49,
    actions: ['×2', '-1']
  },
  {
    sequence: [8, 16, 15, 30, 29, 58],
    answer: 57,
    actions: ['×2', '-1']
  },
  {
    sequence: [9, 18, 17, 34, 33, 66],
    answer: 65,
    actions: ['×2', '-1']
  },
  {
    sequence: [10, 20, 19, 38, 37, 74],
    answer: 73,
    actions: ['×2', '-1']
  },
  {
    sequence: [11, 22, 21, 42, 41, 82],
    answer: 81,
    actions: ['×2', '-1']
  },
  {
    sequence: [12, 24, 23, 46, 45, 90],
    answer: 89,
    actions: ['×2', '-1']
  },
  {
    sequence: [13, 26, 25, 50, 49, 98],
    answer: 97,
    actions: ['×2', '-1']
  },
  {
    sequence: [4, 8, 5, 10, 7, 14],
    answer: 11,
    actions: ['×2', '-3']
  },
  {
    sequence: [5, 10, 7, 14, 11, 22],
    answer: 19,
    actions: ['×2', '-3']
  },
  {
    sequence: [6, 12, 9, 18, 15, 30],
    answer: 27,
    actions: ['×2', '-3']
  },
  {
    sequence: [7, 14, 11, 22, 19, 38],
    answer: 35,
    actions: ['×2', '-3']
  },
  {
    sequence: [8, 16, 13, 26, 23, 46],
    answer: 43,
    actions: ['×2', '-3']
  },
  {
    sequence: [9, 18, 15, 30, 27, 54],
    answer: 51,
    actions: ['×2', '-3']
  },
  {
    sequence: [10, 20, 17, 34, 31, 62],
    answer: 59,
    actions: ['×2', '-3']
  },
  {
    sequence: [11, 22, 19, 38, 35, 70],
    answer: 67,
    actions: ['×2', '-3']
  },
  {
    sequence: [12, 24, 21, 42, 39, 78],
    answer: 75,
    actions: ['×2', '-3']
  },
  {
    sequence: [13, 26, 23, 46, 43, 86],
    answer: 83,
    actions: ['×2', '-3']
  },
  {
    sequence: [14, 28, 25, 50, 47, 94],
    answer: 91,
    actions: ['×2', '-3']
  },
  {
    sequence: [15, 30, 27, 54, 51, 102],
    answer: 99,
    actions: ['×2', '-3']
  },
  {
    sequence: [5, 10, 6, 12, 8, 16],
    answer: 12,
    actions: ['×2', '-4']
  },
  {
    sequence: [6, 12, 8, 16, 12, 24],
    answer: 20,
    actions: ['×2', '-4']
  },
  {
    sequence: [7, 14, 10, 20, 16, 32],
    answer: 28,
    actions: ['×2', '-4']
  },
  {
    sequence: [8, 16, 12, 24, 20, 40],
    answer: 36,
    actions: ['×2', '-4']
  },
  {
    sequence: [9, 18, 14, 28, 24, 48],
    answer: 44,
    actions: ['×2', '-4']
  },
  {
    sequence: [10, 20, 16, 32, 28, 56],
    answer: 52,
    actions: ['×2', '-4']
  },
  {
    sequence: [11, 22, 18, 36, 32, 64],
    answer: 60,
    actions: ['×2', '-4']
  },
  {
    sequence: [12, 24, 20, 40, 36, 72],
    answer: 68,
    actions: ['×2', '-4']
  },
  {
    sequence: [13, 26, 22, 44, 40, 80],
    answer: 76,
    actions: ['×2', '-4']
  },
  {
    sequence: [14, 28, 24, 48, 44, 88],
    answer: 84,
    actions: ['×2', '-4']
  },
  {
    sequence: [15, 30, 26, 52, 48, 96],
    answer: 92,
    actions: ['×2', '-4']
  },
  {
    sequence: [6, 12, 7, 14, 9, 18],
    answer: 13,
    actions: ['×2', '-5']
  },
  {
    sequence: [7, 14, 9, 18, 13, 26],
    answer: 21,
    actions: ['×2', '-5']
  },
  {
    sequence: [8, 16, 11, 22, 17, 34],
    answer: 29,
    actions: ['×2', '-5']
  },
  {
    sequence: [9, 18, 13, 26, 21, 42],
    answer: 37,
    actions: ['×2', '-5']
  },
  {
    sequence: [10, 20, 15, 30, 25, 50],
    answer: 45,
    actions: ['×2', '-5']
  },
  {
    sequence: [11, 22, 17, 34, 29, 58],
    answer: 53,
    actions: ['×2', '-5']
  },
  {
    sequence: [12, 24, 19, 38, 33, 66],
    answer: 61,
    actions: ['×2', '-5']
  },
  {
    sequence: [13, 26, 21, 42, 37, 74],
    answer: 69,
    actions: ['×2', '-5']
  },
  {
    sequence: [14, 28, 23, 46, 41, 82],
    answer: 77,
    actions: ['×2', '-5']
  },
  {
    sequence: [15, 30, 25, 50, 45, 90],
    answer: 85,
    actions: ['×2', '-5']
  },
  {
    sequence: [7, 14, 8, 16, 10, 20],
    answer: 14,
    actions: ['×2', '-6']
  },
  {
    sequence: [8, 16, 10, 20, 14, 28],
    answer: 22,
    actions: ['×2', '-6']
  },
  {
    sequence: [9, 18, 12, 24, 18, 36],
    answer: 30,
    actions: ['×2', '-6']
  },
  {
    sequence: [10, 20, 14, 28, 22, 44],
    answer: 38,
    actions: ['×2', '-6']
  },
  {
    sequence: [11, 22, 16, 32, 26, 52],
    answer: 46,
    actions: ['×2', '-6']
  },
  {
    sequence: [12, 24, 18, 36, 30, 60],
    answer: 54,
    actions: ['×2', '-6']
  },
  {
    sequence: [13, 26, 20, 40, 34, 68],
    answer: 62,
    actions: ['×2', '-6']
  },
  {
    sequence: [14, 28, 22, 44, 38, 76],
    answer: 70,
    actions: ['×2', '-6']
  },
  {
    sequence: [15, 30, 24, 48, 42, 84],
    answer: 78,
    actions: ['×2', '-6']
  },
  {
    sequence: [16, 32, 26, 52, 46, 92],
    answer: 86,
    actions: ['×2', '-6']
  },
  {
    sequence: [17, 34, 28, 56, 50, 100],
    answer: 94,
    actions: ['×2', '-6']
  },
  {
    sequence: [8, 16, 9, 18, 11, 22],
    answer: 15,
    actions: ['×2', '-7']
  },
  {
    sequence: [9, 18, 11, 22, 15, 30],
    answer: 23,
    actions: ['×2', '-7']
  },
  {
    sequence: [10, 20, 13, 26, 19, 38],
    answer: 31,
    actions: ['×2', '-7']
  },
  {
    sequence: [11, 22, 15, 30, 23, 46],
    answer: 39,
    actions: ['×2', '-7']
  },
  {
    sequence: [12, 24, 17, 34, 27, 54],
    answer: 47,
    actions: ['×2', '-7']
  },
  {
    sequence: [13, 26, 19, 38, 31, 62],
    answer: 55,
    actions: ['×2', '-7']
  },
  {
    sequence: [14, 28, 21, 42, 35, 70],
    answer: 63,
    actions: ['×2', '-7']
  },
  {
    sequence: [15, 30, 23, 46, 39, 78],
    answer: 71,
    actions: ['×2', '-7']
  },
  {
    sequence: [16, 32, 25, 50, 43, 86],
    answer: 79,
    actions: ['×2', '-7']
  },
  {
    sequence: [17, 34, 27, 54, 47, 94],
    answer: 87,
    actions: ['×2', '-7']
  },
  {
    sequence: [9, 18, 10, 20, 12, 24],
    answer: 16,
    actions: ['×2', '-8']
  },
  {
    sequence: [10, 20, 12, 24, 16, 32],
    answer: 24,
    actions: ['×2', '-8']
  },
  {
    sequence: [11, 22, 14, 28, 20, 40],
    answer: 32,
    actions: ['×2', '-8']
  },
  {
    sequence: [12, 24, 16, 32, 24, 48],
    answer: 40,
    actions: ['×2', '-8']
  },
  {
    sequence: [13, 26, 18, 36, 28, 56],
    answer: 48,
    actions: ['×2', '-8']
  },
  {
    sequence: [14, 28, 20, 40, 32, 64],
    answer: 56,
    actions: ['×2', '-8']
  },
  {
    sequence: [15, 30, 22, 44, 36, 72],
    answer: 64,
    actions: ['×2', '-8']
  },
  {
    sequence: [16, 32, 24, 48, 40, 80],
    answer: 72,
    actions: ['×2', '-8']
  },
  {
    sequence: [17, 34, 26, 52, 44, 88],
    answer: 80,
    actions: ['×2', '-8']
  },
  {
    sequence: [18, 36, 28, 56, 48, 96],
    answer: 88,
    actions: ['×2', '-8']
  },
  {
    sequence: [8, 16, 7, 14, 5, 10],
    answer: 1,
    actions: ['×2', '-9']
  },
  {
    sequence: [9, 18, 9, 18, 9, 18],
    answer: 9,
    actions: ['×2', '-9']
  },
  {
    sequence: [10, 20, 11, 22, 13, 26],
    answer: 17,
    actions: ['×2', '-9']
  },
  {
    sequence: [11, 22, 13, 26, 17, 34],
    answer: 25,
    actions: ['×2', '-9']
  },
  {
    sequence: [12, 24, 15, 30, 21, 42],
    answer: 33,
    actions: ['×2', '-9']
  },
  {
    sequence: [13, 26, 17, 34, 25, 50],
    answer: 41,
    actions: ['×2', '-9']
  },
  {
    sequence: [14, 28, 19, 38, 29, 58],
    answer: 49,
    actions: ['×2', '-9']
  },
  {
    sequence: [15, 30, 21, 42, 33, 66],
    answer: 57,
    actions: ['×2', '-9']
  },
  {
    sequence: [16, 32, 23, 46, 37, 74],
    answer: 65,
    actions: ['×2', '-9']
  },
  {
    sequence: [17, 34, 25, 50, 41, 82],
    answer: 73,
    actions: ['×2', '-9']
  },
  {
    sequence: [18, 36, 27, 54, 45, 90],
    answer: 81,
    actions: ['×2', '-9']
  },
  {
    sequence: [19, 38, 29, 58, 49, 98],
    answer: 89,
    actions: ['×2', '-9']
  },
  {
    sequence: [9, 18, 8, 16, 6, 12],
    answer: 2,
    actions: ['×2', '-10']
  },
  {
    sequence: [10, 20, 10, 20, 10, 20],
    answer: 10,
    actions: ['×2', '-10']
  },
  {
    sequence: [11, 22, 12, 24, 14, 28],
    answer: 18,
    actions: ['×2', '-10']
  },
  {
    sequence: [12, 24, 14, 28, 18, 36],
    answer: 26,
    actions: ['×2', '-10']
  },
  {
    sequence: [13, 26, 16, 32, 22, 44],
    answer: 34,
    actions: ['×2', '-10']
  },
  {
    sequence: [14, 28, 18, 36, 26, 52],
    answer: 42,
    actions: ['×2', '-10']
  },
  {
    sequence: [15, 30, 20, 40, 30, 60],
    answer: 50,
    actions: ['×2', '-10']
  },
  {
    sequence: [16, 32, 22, 44, 34, 68],
    answer: 58,
    actions: ['×2', '-10']
  },
  {
    sequence: [17, 34, 24, 48, 38, 76],
    answer: 66,
    actions: ['×2', '-10']
  },
  {
    sequence: [18, 36, 26, 52, 42, 84],
    answer: 74,
    actions: ['×2', '-10']
  },
  {
    sequence: [19, 38, 28, 56, 46, 92],
    answer: 82,
    actions: ['×2', '-10']
  },
  {
    sequence: [20, 40, 30, 60, 50, 100],
    answer: 90,
    actions: ['×2', '-10']
  },
  {
    sequence: [1, 3, 2, 6, 5, 15],
    answer: 14,
    actions: ['×3', '-1']
  },
  {
    sequence: [2, 6, 5, 15, 14, 42],
    answer: 41,
    actions: ['×3', '-1']
  },
  {
    sequence: [3, 9, 8, 24, 23, 69],
    answer: 68,
    actions: ['×3', '-1']
  },
  {
    sequence: [4, 12, 11, 33, 32, 96],
    answer: 95,
    actions: ['×3', '-1']
  },
  {
    sequence: [2, 6, 4, 12, 10, 30],
    answer: 28,
    actions: ['×3', '-2']
  },
  {
    sequence: [3, 9, 7, 21, 19, 57],
    answer: 55,
    actions: ['×3', '-2']
  },
  {
    sequence: [4, 12, 10, 30, 28, 84],
    answer: 82,
    actions: ['×3', '-2']
  },
  {
    sequence: [2, 6, 3, 9, 6, 18],
    answer: 15,
    actions: ['×3', '-3']
  },
  {
    sequence: [3, 9, 6, 18, 15, 45],
    answer: 42,
    actions: ['×3', '-3']
  },
  {
    sequence: [4, 12, 9, 27, 24, 72],
    answer: 69,
    actions: ['×3', '-3']
  },
  {
    sequence: [5, 15, 12, 36, 33, 99],
    answer: 96,
    actions: ['×3', '-3']
  },
  {
    sequence: [3, 9, 5, 15, 11, 33],
    answer: 29,
    actions: ['×3', '-4']
  },
  {
    sequence: [4, 12, 8, 24, 20, 60],
    answer: 56,
    actions: ['×3', '-4']
  },
  {
    sequence: [5, 15, 11, 33, 29, 87],
    answer: 83,
    actions: ['×3', '-4']
  },
  {
    sequence: [3, 9, 4, 12, 7, 21],
    answer: 16,
    actions: ['×3', '-5']
  },
  {
    sequence: [4, 12, 7, 21, 16, 48],
    answer: 43,
    actions: ['×3', '-5']
  },
  {
    sequence: [5, 15, 10, 30, 25, 75],
    answer: 70,
    actions: ['×3', '-5']
  },
  {
    sequence: [4, 12, 6, 18, 12, 36],
    answer: 30,
    actions: ['×3', '-6']
  },
  {
    sequence: [5, 15, 9, 27, 21, 63],
    answer: 57,
    actions: ['×3', '-6']
  },
  {
    sequence: [6, 18, 12, 36, 30, 90],
    answer: 84,
    actions: ['×3', '-6']
  },
  {
    sequence: [4, 12, 5, 15, 8, 24],
    answer: 17,
    actions: ['×3', '-7']
  },
  {
    sequence: [5, 15, 8, 24, 17, 51],
    answer: 44,
    actions: ['×3', '-7']
  },
  {
    sequence: [6, 18, 11, 33, 26, 78],
    answer: 71,
    actions: ['×3', '-7']
  },
  {
    sequence: [7, 21, 14, 42, 35, 105],
    answer: 98,
    actions: ['×3', '-7']
  },
  {
    sequence: [5, 15, 7, 21, 13, 39],
    answer: 31,
    actions: ['×3', '-8']
  },
  {
    sequence: [6, 18, 10, 30, 22, 66],
    answer: 58,
    actions: ['×3', '-8']
  },
  {
    sequence: [7, 21, 13, 39, 31, 93],
    answer: 85,
    actions: ['×3', '-8']
  },
  {
    sequence: [5, 15, 6, 18, 9, 27],
    answer: 18,
    actions: ['×3', '-9']
  },
  {
    sequence: [6, 18, 9, 27, 18, 54],
    answer: 45,
    actions: ['×3', '-9']
  },
  {
    sequence: [7, 21, 12, 36, 27, 81],
    answer: 72,
    actions: ['×3', '-9']
  },
  {
    sequence: [8, 24, 15, 45, 36, 108],
    answer: 99,
    actions: ['×3', '-9']
  },
  {
    sequence: [6, 18, 8, 24, 14, 42],
    answer: 32,
    actions: ['×3', '-10']
  },
  {
    sequence: [7, 21, 11, 33, 23, 69],
    answer: 59,
    actions: ['×3', '-10']
  },
  {
    sequence: [8, 24, 14, 42, 32, 96],
    answer: 86,
    actions: ['×3', '-10']
  },
  {
    sequence: [1, 4, 3, 12, 11, 44],
    answer: 43,
    actions: ['×4', '-1']
  },
  {
    sequence: [1, 4, 2, 8, 6, 24],
    answer: 22,
    actions: ['×4', '-2']
  },
  {
    sequence: [2, 8, 6, 24, 22, 88],
    answer: 86,
    actions: ['×4', '-2']
  },
  {
    sequence: [2, 8, 5, 20, 17, 68],
    answer: 65,
    actions: ['×4', '-3']
  },
  {
    sequence: [2, 8, 4, 16, 12, 48],
    answer: 44,
    actions: ['×4', '-4']
  },
  {
    sequence: [2, 8, 3, 12, 7, 28],
    answer: 23,
    actions: ['×4', '-5']
  },
  {
    sequence: [3, 12, 7, 28, 23, 92],
    answer: 87,
    actions: ['×4', '-5']
  },
  {
    sequence: [3, 12, 6, 24, 18, 72],
    answer: 66,
    actions: ['×4', '-6']
  },
  {
    sequence: [3, 12, 5, 20, 13, 52],
    answer: 45,
    actions: ['×4', '-7']
  },
  {
    sequence: [3, 12, 4, 16, 8, 32],
    answer: 24,
    actions: ['×4', '-8']
  },
  {
    sequence: [4, 16, 8, 32, 24, 96],
    answer: 88,
    actions: ['×4', '-8']
  },
  {
    sequence: [4, 16, 7, 28, 19, 76],
    answer: 67,
    actions: ['×4', '-9']
  },
  {
    sequence: [4, 16, 6, 24, 14, 56],
    answer: 46,
    actions: ['×4', '-10']
  },
  {
    sequence: [1, 2, 4, 5, 10, 11],
    answer: 22,
    actions: ['+1', '×2']
  },
  {
    sequence: [2, 3, 6, 7, 14, 15],
    answer: 30,
    actions: ['+1', '×2']
  },
  {
    sequence: [3, 4, 8, 9, 18, 19],
    answer: 38,
    actions: ['+1', '×2']
  },
  {
    sequence: [4, 5, 10, 11, 22, 23],
    answer: 46,
    actions: ['+1', '×2']
  },
  {
    sequence: [5, 6, 12, 13, 26, 27],
    answer: 54,
    actions: ['+1', '×2']
  },
  {
    sequence: [6, 7, 14, 15, 30, 31],
    answer: 62,
    actions: ['+1', '×2']
  },
  {
    sequence: [7, 8, 16, 17, 34, 35],
    answer: 70,
    actions: ['+1', '×2']
  },
  {
    sequence: [8, 9, 18, 19, 38, 39],
    answer: 78,
    actions: ['+1', '×2']
  },
  {
    sequence: [9, 10, 20, 21, 42, 43],
    answer: 86,
    actions: ['+1', '×2']
  },
  {
    sequence: [10, 11, 22, 23, 46, 47],
    answer: 94,
    actions: ['+1', '×2']
  },
  {
    sequence: [1, 3, 6, 8, 16, 18],
    answer: 36,
    actions: ['+2', '×2']
  },
  {
    sequence: [2, 4, 8, 10, 20, 22],
    answer: 44,
    actions: ['+2', '×2']
  },
  {
    sequence: [3, 5, 10, 12, 24, 26],
    answer: 52,
    actions: ['+2', '×2']
  },
  {
    sequence: [4, 6, 12, 14, 28, 30],
    answer: 60,
    actions: ['+2', '×2']
  },
  {
    sequence: [5, 7, 14, 16, 32, 34],
    answer: 68,
    actions: ['+2', '×2']
  },
  {
    sequence: [6, 8, 16, 18, 36, 38],
    answer: 76,
    actions: ['+2', '×2']
  },
  {
    sequence: [7, 9, 18, 20, 40, 42],
    answer: 84,
    actions: ['+2', '×2']
  },
  {
    sequence: [8, 10, 20, 22, 44, 46],
    answer: 92,
    actions: ['+2', '×2']
  },
  {
    sequence: [1, 4, 8, 11, 22, 25],
    answer: 50,
    actions: ['+3', '×2']
  },
  {
    sequence: [2, 5, 10, 13, 26, 29],
    answer: 58,
    actions: ['+3', '×2']
  },
  {
    sequence: [3, 6, 12, 15, 30, 33],
    answer: 66,
    actions: ['+3', '×2']
  },
  {
    sequence: [4, 7, 14, 17, 34, 37],
    answer: 74,
    actions: ['+3', '×2']
  },
  {
    sequence: [5, 8, 16, 19, 38, 41],
    answer: 82,
    actions: ['+3', '×2']
  },
  {
    sequence: [6, 9, 18, 21, 42, 45],
    answer: 90,
    actions: ['+3', '×2']
  },
  {
    sequence: [7, 10, 20, 23, 46, 49],
    answer: 98,
    actions: ['+3', '×2']
  },
  {
    sequence: [1, 5, 10, 14, 28, 32],
    answer: 64,
    actions: ['+4', '×2']
  },
  {
    sequence: [2, 6, 12, 16, 32, 36],
    answer: 72,
    actions: ['+4', '×2']
  },
  {
    sequence: [3, 7, 14, 18, 36, 40],
    answer: 80,
    actions: ['+4', '×2']
  },
  {
    sequence: [4, 8, 16, 20, 40, 44],
    answer: 88,
    actions: ['+4', '×2']
  },
  {
    sequence: [5, 9, 18, 22, 44, 48],
    answer: 96,
    actions: ['+4', '×2']
  },
  {
    sequence: [1, 6, 12, 17, 34, 39],
    answer: 78,
    actions: ['+5', '×2']
  },
  {
    sequence: [2, 7, 14, 19, 38, 43],
    answer: 86,
    actions: ['+5', '×2']
  },
  {
    sequence: [3, 8, 16, 21, 42, 47],
    answer: 94,
    actions: ['+5', '×2']
  },
  {
    sequence: [1, 7, 14, 20, 40, 46],
    answer: 92,
    actions: ['+6', '×2']
  }
];
