import texts from './texts';
import levels from './levels';
import levelsInMission from './levelsInMission';

import getContent from './services/getContent';

export default  {
  id: 20,
  type: 'mnemonic',
  eventCategory: 'SmartPictures',
  videoAnnounceUrl: null,
  isCenteredContent: true,
  tooltipAnnounce: 'Память спецагента — его тайное оружие. Тренируй свою память, ведь тебе предстоит запомнить большой объём секретных данных.',
  previewUrl: require('~/static/image/trainerPreviews/mnemonic.png'),
  params: {
    ...texts,
    name: 'Умные картинки',
    hasTimer: true,
    startsImmediately: true,
    maxSkills:  {
      memory: 2,
      imagination: 1
    },
    stageAmount: 2,
    skills: [ 'memory', 'imagination' ],
    mainSkill: 'memory',
    levels,
    matrixImages: getContent(),
    levelsInMission
  }
};
