import level1 from './basePatterns/level1';
import level2 from './basePatterns/level2';
import level3 from './basePatterns/level3';
import level4 from './basePatterns/level4';
import level5 from './basePatterns/level5';
import level6 from './basePatterns/level6';
import level7 from './basePatterns/level7';
import level8 from './basePatterns/level8';
import level9 from './basePatterns/level9';
import level10 from './basePatterns/level10';

export default {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
  7: level7,
  8: level8,
  9: level9,
  10: level10
};
