export default {
  shapes: [ 1, 4, 7, 5, 6, 8, 3 ],
  offset: {
    tablet: 5,
    mobile: 15
  },
  outline: [
    {
      desktop: { x: 79, y: 181 },
      tablet: { x: 104, y: 166 },
      mobile: { x: 64, y: 119 },
    },
    {
      desktop: { x: 124, y: 226 },
      tablet: { x: 144, y: 206 },
      mobile: { x: 94, y: 149 },
    },
    {
      desktop: { x: 168.7, y: 181.3 },
      tablet: { x: 183.2, y: 166.8 },
      mobile: { x: 124, y: 119 },
    },
    {
      desktop: { x: 183, y: 195.6 },
      tablet: { x: 195, y: 178.5 },
      mobile: { x: 133, y: 128.5 },
    },
    {
      desktop: { x: 183, y: 258 },
      tablet: { x: 195, y: 235 },
      mobile: { x: 133, y: 170 },
    },
    {
      desktop: { x: 152, y: 258 },
      tablet: { x: 171, y: 235 },
      mobile: { x: 112, y: 170 },
    },
    {
      desktop: { x: 152, y: 348 },
      tablet: { x: 171, y: 315 },
      mobile: { x: 112, y: 230 },
    },
    {
      desktop: { x: 240.7, y: 259.3 },
      tablet: { x: 250.9, y: 235.1 },
      mobile: { x: 171.2, y: 170.8 },
    },
    {
      desktop: { x: 309.5, y: 259.3 },
      tablet: { x: 308.1, y: 235.1 },
      mobile: { x: 217.9, y: 170.8 },
    },
    {
      desktop: { x: 373, y: 323 },
      tablet: { x: 364.3, y: 291.3 },
      mobile: { x: 260.2, y: 213.2 },
    },
    {
      desktop: { x: 436.5, y: 322.8 },
      tablet: { x: 420.8, y: 291.3 },
      mobile: { x: 302.6, y: 213.2 },
    },
    {
      desktop: { x: 373.3, y: 259.7 },
      tablet: { x: 365.1, y: 235.5 },
      mobile: { x: 260.8, y: 171.4 },
    },
    {
      desktop: { x: 373.3, y: 196 },
      tablet: { x: 365.1, y: 179 },
      mobile: { x: 260.8, y: 129 },
    },
    {
      desktop: { x: 463, y: 196 },
      tablet: { x: 445, y: 179 },
      mobile: { x: 320, y: 129 },
    },
    {
      desktop: { x: 508, y: 151 },
      tablet: { x: 485, y: 139 },
      mobile: { x: 350, y: 99 },
    },
    {
      desktop: { x: 418, y: 151 },
      tablet: { x: 405, y: 139 },
      mobile: { x: 289, y: 99 },
    },
    {
      desktop: { x: 373.3, y: 196 },
      tablet: { x: 365.1, y: 179 },
      mobile: { x: 260.8, y: 129 },
    },
    {
      desktop: { x: 247, y: 196 },
      tablet: { x: 252, y: 179 },
      mobile: { x: 176, y: 129 },
    },
    {
      desktop: { x: 183.8, y: 132.8 },
      tablet: { x: 195.3, y: 122.3 },
      mobile: { x: 133.2, y: 86.2 },
    },
    {
      desktop: { x: 120.2, y: 132.8 },
      tablet: { x: 138.7, y: 122.3 },
      mobile: { x: 90.8, y: 86.2 },
    },
    {
      desktop: { x: 123.7, y: 136.3 },
      tablet: { x: 143.2, y: 126.8 },
      mobile: { x: 93.8, y: 89.2 },
    },
  ],
  outlines: [
    {
      desktop: { x: 79, y: 136 },
      tablet: { x: 104, y: 126 },
      mobile: { x: 64, y: 89 },
      rotation: 0
    },
    {
      desktop: { x: 216, y: 165 },
      tablet: { x: 224, y: 151 },
      mobile: { x: 155, y: 108 },
      rotation: 135
    },
    {
      desktop: { x: 310, y: 259 },
      tablet: { x: 308, y: 235 },
      mobile: { x: 218, y: 171 },
      rotation: 135
    },
    {
      desktop: { x: 242, y: 348 },
      tablet: { x: 252, y: 314 },
      mobile: { x: 172, y: 230 },
      rotation: 180
    },
    {
      desktop: { x: 246, y: 196 },
      tablet: { x: 252, y: 179 },
      mobile: { x: 176, y: 129 },
      rotation: 315
    },
    {
      desktop: { x: 373, y: 196 },
      tablet: { x: 364, y: 179 },
      mobile: { x: 260, y: 129 },
      rotation: 270
    },
    {
      desktop: { x: 341, y: 291 },
      tablet: { x: 337, y: 264 },
      mobile: { x: 239, y: 192 },
      rotation: 315
    }
  ]
};
