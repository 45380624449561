export default {
  image: require('~/static/trainers/funnyPoints/lion.png'),
  width: 800,
  height: 919,
  offsetX: 10,
  offsetY: 20,
  points: [
    { id: 1,  x: -153, y: 506 },
    { id: 2,  x: -200, y: 489 },
    { id: 3,  x: -245, y: 460 },
    { id: 4,  x: -282, y: 415 },
    { id: 5,  x: -299, y: 353 },
    { id: 6,  x: -294, y: 288 },
    { id: 7,  x: -267, y: 223 },
    { id: 8,  x: -213, y: 172 },
    { id: 9,  x: -145, y: 142 },
    { id: 10, x: -84,  y: 140 },
    { id: 11, x: -14,  y: 156 },
    { id: 12, x: 45,   y: 204 },
    { id: 13, x: 82,   y: 278 },
    { id: 14, x: 84,   y: 353 },
    { id: 15, x: 63,   y: 414 },
    { id: 16, x: 18,   y: 467 },
    { id: 17, x: 62,   y: 486 },
    { id: 18, x: 111,  y: 487 },
    { id: 19, x: 168,  y: 490 },
    { id: 20, x: 214,  y: 510 },
    { id: 21, x: 221,  y: 566 },
    { id: 22, x: 221,  y: 627 },
    { id: 23, x: 221,  y: 678 },
    { id: 24, x: 215,  y: 737 },
    { id: 25, x: 210,  y: 797 },
    { id: 26, x: 143,  y: 806 },
    { id: 27, x: 154,  y: 790 },
    { id: 28, x: 149,  y: 692 },
    { id: 29, x: 72,   y: 697 },
    { id: 30, x: 67,   y: 801 },
    { id: 31, x: -10,  y: 801 },
    { id: 32, x: 6,    y: 783 },
    { id: 33, x: 8,    y: 692 },
    { id: 34, x: -47,  y: 640 },
    { id: 35, x: -47,  y: 676 },
    { id: 36, x: -84,  y: 636 },
    { id: 37, x: -96,  y: 790 },
    { id: 38, x: -189, y: 792 },
    { id: 39, x: -164, y: 769 },
    { id: 40, x: -154, y: 642 }
  ]
};
