export default (locations, profile) => {
  const { completedMissions } = profile;

  for (let i = 0; i < locations.length; i++) {
    const arrayMissions = Object.keys(locations[i].missions);

    if (locations[i].locked === undefined) {
      locations[i].locked = true;
    }

    for (let j = 0; j < arrayMissions.length; j++) {
      const currentMission = locations[i].missions[arrayMissions[j]];
      const { id } = currentMission;
      const nextMission = locations[i].missions[arrayMissions[j + 1]];
      const nextLocation = locations[i + 1];

      if (nextMission) {
        const isUnlockedNextMission = completedMissions[id] && nextMission;

        if (isUnlockedNextMission) {
          nextMission.locked = false;
        }
      }

      if (nextLocation) {
        const arrayMissionsNextLocation = Object.keys(nextLocation.missions);
        const firstMissionNextLocation = arrayMissionsNextLocation[0];
        const isUnlockedNextLocation = completedMissions[id] && !nextMission && nextLocation;

        if (isUnlockedNextLocation) {
          nextLocation.locked = false;
          if (firstMissionNextLocation) {
            nextLocation.missions[firstMissionNextLocation].locked = false;
          }
        }
      }

      if (currentMission.locked === undefined) {
        currentMission.locked = true;
      }
    }
  }

  return locations;
};
