export default [
  {
    base: 'булыжник',
    words: [
      'лыжник',
      'нулик',
      'жулик',
      'лыжи',
      'ужин',
      'клуб',
      'блик',
      'клин',
      'блин',
      'лик',
      'бык',
      'бук',
      'куб',
      'лук',
      'жук',
      'ил',
      'уж'
    ]
  },
  {
    base: 'волнушка',
    words: [
      'ловушка',
      'вулкан',
      'уловка',
      'школа',
      'клоун',
      'кулон',
      'уклон',
      'вокал',
      'шквал',
      'валун',
      'волан',
      'волна',
      'шалун',
      'лунка',
      'клуша',
      'внук',
      'ушан',
      'ноша',
      'клон',
      'укол',
      'овал',
      'улов',
      'шлак',
      'лука',
      'кола',
      'клан',
      'луна',
      'ковш',
      'ушко',
      'волк',
      'шов',
      'вша',
      'кон',
      'вол',
      'лов',
      'куш',
      'аул',
      'лак',
      'шок',
      'вал',
      'лук',
      'кол',
      'шоу'
    ]
  },
  {
    base: 'государь',
    words: [
      'градус',
      'сударь',
      'досуг',
      'угода',
      'грудь',
      'гусар',
      'груда',
      'угорь',
      'сода',
      'роса',
      'дуга',
      'град',
      'друг',
      'гора',
      'урод',
      'рагу',
      'угар',
      'гусь',
      'дура',
      'руда',
      'удар',
      'дурь',
      'гарь',
      'ора',
      'суд',
      'род',
      'гад',
      'год',
      'дог',
      'ось',
      'рог',
      'ода',
      'сор',
      'сад',
      'дар',
      'оса',
      'ас',
      'ар',
      'ад',
      'ус',
      'ор'
    ]
  },
  {
    base: 'антиквар',
    words: [
      'травинка',
      'вариант',
      'травина',
      'картина',
      'натирка',
      'тиранка',
      'ватикан',
      'травник',
      'ракита',
      'кантри',
      'ватник',
      'травка',
      'тиран',
      'враки',
      'актив',
      'таран',
      'карат',
      'карта',
      'варка',
      'ватка',
      'канва',
      'варан',
      'навар',
      'антик',
      'нитка',
      'врата',
      'трава',
      'квант',
      'ватин',
      'аркан',
      'канат',
      'накат',
      'вата',
      'кран',
      'кант',
      'танк',
      'арка',
      'карат',
      'икар',
      'икра',
      'вина',
      'нива',
      'винт',
      'арат',
      'таран',
      'рана',
      'крин',
      'тина',
      'тавр',
      'тир',
      'вар',
      'ива',
      'кит',
      'тик',
      'ара',
      'акр',
      'рак',
      'ар'
    ]
  },
  {
    base: 'астероид',
    words: [
      'стероид',
      'сирота',
      'острие',
      'радист',
      'сидор',
      'редис',
      'стадо',
      'сатир',
      'диета',
      'радио',
      'осетр',
      'арест',
      'ситро',
      'тодес',
      'адрес',
      'среда',
      'дите',
      'сода',
      'сота',
      'рост',
      'сорт',
      'торс',
      'трос',
      'ирод',
      'роса',
      'рота',
      'таро',
      'сидр',
      'орда',
      'тире',
      'сито',
      'сера',
      'тори',
      'трио',
      'иаст',
      'тир',
      'рис',
      'род',
      'сет',
      'ода',
      'сор',
      'рот',
      'тор',
      'сад',
      'дар',
      'еда',
      'оса',
      'ас',
      'си',
      'ар',
      'ад',
      'ре',
      'ор'
    ]
  },
  {
    base: 'вагончик',
    words: [
      'кочан',
      'книга',
      'гонка',
      'икона',
      'вагон',
      'новик',
      'очки',
      'вина',
      'нива',
      'вино',
      'воин',
      'очаг',
      'инок',
      'кино',
      'нога',
      'ива',
      'кон',
      'чан',
      'чин',
      'ион'
    ]
  },
  {
    base: 'ватрушка',
    words: [
      'травка',
      'вруша',
      'шкура',
      'турка',
      'карат',
      'карта',
      'ватка',
      'шавка',
      'врата',
      'трава',
      'курва',
      'рукав',
      'тушка',
      'штука',
      'шутка',
      'ракша',
      'вата',
      'каша',
      'арка',
      'кара',
      'утка',
      'арат',
      'тара',
      'увар',
      'туша',
      'аура',
      'кура',
      'рука',
      'аут',
      'вар',
      'вша',
      'куш',
      'шар',
      'ара',
      'акр',
      'кар',
      'рак',
      'акт',
      'шут',
      'тур',
      'ар'
    ]
  },
  {
    base: 'вернисаж',
    words: [
      'сирена',
      'ржание',
      'синева',
      'весна',
      'навес',
      'вираж',
      'сени',
      'жанр',
      'вина',
      'нива',
      'вена',
      'нерв',
      'сера',
      'анис',
      'сани',
      'жена',
      'сан',
      'рис',
      'вар',
      'ива',
      'жар',
      'жир',
      'вес',
      'ас',
      'си',
      'ар',
      'ре'
    ]
  },
  {
    base: 'верхушка',
    words: [
      'акушер',
      'вруша',
      'шкура',
      'курва',
      'рукав',
      'решка',
      'верх',
      'увар',
      'каре',
      'река',
      'вера',
      'кура',
      'рука',
      'крах',
      'хек',
      'вар',
      'вша',
      'куш',
      'шар',
      'акр',
      'рак',
      'век',
      'уха',
      'шах',
      'ар',
      'ре'
    ]
  },
  {
    base: 'ветрянка',
    words: [
      'кентавр',
      'нектар',
      'танкер',
      'неявка',
      'анкер',
      'нерка',
      'вятка',
      'ветка',
      'катер',
      'квант',
      'кран',
      'кант',
      'танкер',
      'трек',
      'явка',
      'вена',
      'нерв',
      'каре',
      'река',
      'вера',
      'кета',
      'акр',
      'рак',
      'акт',
      'век',
      'як',
      'ар',
      'ре'
    ]
  },
  {
    base: 'гармония',
    words: [
      'магия',
      'минор',
      'аргон',
      'орган',
      'армия',
      'норма',
      'мания',
      'нора',
      'гимн',
      'гора',
      'гном',
      'игра',
      'горн',
      'гром',
      'морг',
      'грим',
      'омар',
      'гиря',
      'мина',
      'нога',
      'ранг',
      'яма',
      'морг',
      'ром',
      'имя',
      'рог',
      'ион',
      'миг',
      'мир',
      'гам',
      'маг',
      'ми',
      'яр',
      'ар',
      'ор'
    ]
  },
  {
    base: 'дворняга',
    words: [
      'гранд',
      'наряд',
      'народ',
      'ягода',
      'дрова',
      'родня',
      'вагон',
      'враг',
      'двор',
      'нора',
      'град',
      'гора',
      'рога',
      'ядро',
      'орда',
      'вода',
      'горн',
      'нрав',
      'нога',
      'ранг',
      'дрон',
      'вор',
      'ров',
      'ряд',
      'ярд',
      'род',
      'год',
      'дно',
      'рог',
      'ода',
      'дар',
      'яд',
      'ад',
      'ор'
    ]
  },
  {
    base: 'бактерия',
    words: [
      'братия',
      'брикет',
      'ребята',
      'абрек',
      'бекар',
      'бирка',
      'батик',
      'рябка',
      'актер',
      'катер',
      'терка',
      'брат',
      'бяка',
      'брак',
      'краб',
      'трек',
      'икра',
      'батя',
      'тире',
      'бета',
      'каре',
      'река',
      'баит',
      'бита',
      'ария',
      'кета',
      'тир',
      'кит',
      'тик',
      'бит',
      'бар',
      'раб',
      'акр',
      'рак',
      'акт',
      'як',
      'яр',
      'ар',
      'ер',
      'ре'
    ]
  },
  {
    base: 'шиповник',
    words: [
      'пинок',
      'шпион',
      'пошив',
      'киви',
      'вино',
      'воин',
      'шпон',
      'пион',
      'пони',
      'пинк',
      'инок',
      'кино',
      'ковш',
      'пиво',
      'пики',
      'шов',
      'шик',
      'шип',
      'ион',
      'шок'
    ]
  },
  {
    base: 'жидкость',
    words: ['исток', 'кость', 'кисть', 'скот', 'сито', 'икс', 'иск', 'кит', 'код', 'кот', 'ток', 'сок', 'до', 'си']
  }
];
