export default {
  image: require('~/static/trainers/funnyPoints/pear.png'),
  width: 800,
  height: 919,
  offsetX: 30,
  offsetY: 25,
  points: [
    { id: 1,  x: 50,   y: 316 },
    { id: 2,  x: 2,    y: 359 },
    { id: 3,  x: -28,  y: 471 },
    { id: 4,  x: -67,  y: 543 },
    { id: 5,  x: -127, y: 597 },
    { id: 6,  x: -154, y: 700 },
    { id: 7,  x: -127, y: 752 },
    { id: 8,  x: -87,  y: 790 },
    { id: 9,  x: -33,  y: 811 },
    { id: 10, x: 30,   y: 823 }
  ]
}
