export default {
  shapes: [7, 4, 5, 3, 1, 2, 6],
  offset: {
    mobile: 10
  },
  outlines: [
    {
      id: 7,
      rotation: 90,
      desktop: {
        x: 434,
        y: 205
      },
      tablet: {
        x: 413,
        y: 187
      },
      mobile: {
        x: 297,
        y: 135
      }
    },
    {
      id: 4,
      rotation: 90,
      desktop: {
        x: 389.5,
        y: 115
      },
      tablet: {
        x: 373,
        y: 107
      },
      mobile: {
        x: 267,
        y: 75
      }
    },
    {
      id: 5,
      rotation: 270,
      desktop: {
        x: 166,
        y: 295
      },
      tablet: {
        x: 173,
        y: 267
      },
      mobile: {
        x: 117,
        y: 195
      }
    },
    {
      id: 3,
      rotation: 180,
      desktop: {
        x: 301,
        y: 295
      },
      tablet: {
        x: 293,
        y: 267
      },
      mobile: {
        x: 207,
        y: 195
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 255,
        y: 249
      },
      tablet: {
        x: 253,
        y: 227
      },
      mobile: {
        x: 177,
        y: 165
      }
    },
    {
      id: 2,
      rotation: 270,
      desktop: {
        x: 299,
        y: 205
      },
      tablet: {
        x: 293,
        y: 187
      },
      mobile: {
        x: 207,
        y: 135
      }
    },
    {
      id: 6,
      rotation: 270,
      desktop: {
        x: 166,
        y: 205
      },
      tablet: {
        x: 173,
        y: 187
      },
      mobile: {
        x: 117,
        y: 135
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 300.25,
        y: 159.25
      },
      tablet: {
        x: 253,
        y: 107
      },
      mobile: {
        x: 207,
        y: 105
      }
    },
    {
      desktop: {
        x: 344.25,
        y: 115
      },
      tablet: {
        x: 173,
        y: 187
      },
      mobile: {
        x: 237,
        y: 75
      }
    },
    {
      desktop: {
        x: 434,
        y: 205
      },
      tablet: {
        x: 293,
        y: 307
      },
      mobile: {
        x: 297,
        y: 135
      }
    },
    {
      desktop: {
        x: 300,
        y: 339
      },
      tablet: {
        x: 413,
        y: 187
      },
      mobile: {
        x: 207,
        y: 225
      }
    },
    {
      desktop: {
        x: 166,
        y: 205
      },
      tablet: {
        x: 333,
        y: 107
      },
      mobile: {
        x: 117,
        y: 135
      }
    },
    {
      desktop: {
        x: 256,
        y: 115
      },
      tablet: {
        x: 293,
        y: 147
      },
      mobile: {
        x: 177,
        y: 75
      }
    }
  ]
};
