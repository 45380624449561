import React from 'react';
import PropTypes from 'prop-types';

import Icon from '~/components/Icon/Icon';
import Timeline from '~/components/Timeline/Timeline';
import { Link } from 'react-router-dom';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import MissionStatus from '~/components/MissionStatus/MissionStatus';

import cx from 'classnames';

import './trainer-header.scss';

const TrainerHeader = (props) => {
  const { trainerParams, timerIsActive, timerOnEnded, changeStage, currentTrainerIndex,
          stageAmount, subStageAmount, isInMission, isRefresh, locationName, missionName,
          missionStatus, isPopupShow, stageText, isShowStage, isLongMemory } = props;
  const classesHeaderDown = cx('trainer-header__down', {
    'trainer-header__down_mobile': stageAmount && !isPopupShow && isShowStage
  });
  const classesHeaderExit = cx('trainer-header__exit', {
    'trainer-header__stage-condition': stageAmount
  });
  const link = isInMission ? '/locations/' : '/trainers/';
  const handleClick = () => {
    window.dataLayer.push({ event: 'click', eventCategory: 'user_mission', eventAction: 'finished_exit' });
  };

  return (
    <div className="trainer-header">
      <div className="trainer-header__holder">
        {
          isInMission &&
          <div className="trainer-header__up">
            <div className="trainer-header__left">
              <div className="trainer-header__exit-holder" onClick={ handleClick }>
                <Link className={ classesHeaderExit } to={ link }>
                  <Icon name="close" className="trainer-header__exit-icon" />
                </Link>
              </div>

              <div className="trainer-header__breadcrumbs">
                <Breadcrumbs locationName={ locationName } missionName={ missionName }/>
              </div>
            </div>

            <div className="trainer-header__right">
              {
                missionStatus &&
                <div className="trainer-header__status">
                  <MissionStatus status={ missionStatus } currentTrainerIndex={ currentTrainerIndex }/>
                </div>
              }
            </div>
          </div>
        }


        <div className={ classesHeaderDown }>
          <div className="trainer-header__timeline-holder">
            <Timeline isRefresh={ isRefresh } trainerParams={ trainerParams } isActive={ timerIsActive }
                      onEnded={ timerOnEnded } changeStage={ stageAmount && changeStage } stageAmount={ stageAmount }
                      subStageAmount={ subStageAmount } isPopupShow={ isPopupShow } stageText={ stageText }
                      isShowStage={ isShowStage } isLongMemory={ isLongMemory }/>
          </div>
        </div>
      </div>
    </div>
  );
}

TrainerHeader.propTypes = {
  trainerParams: PropTypes.shape({}).isRequired,
  timerIsActive: PropTypes.bool.isRequired,
  timerOnEnded: PropTypes.func.isRequired,
  changeStage: PropTypes.func,
  activeStage: PropTypes.number,
  stageAmount: PropTypes.number,
};

export default TrainerHeader;
