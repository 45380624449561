import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import propTypes from './prop-types';

import Page from '~/containers/Page/Page';
import TrainerWrapper from '~/components/TrainerWrapper/TrainerWrapper';

import * as locationsActions from '~/store/locations/actions';
import * as trainersActions from '~/store/trainers/actions';
import * as profileActions from '~/store/profile/actions';

import { getCurrentMission } from '~/store/trainers/selector';

import { STEP_TEST } from '~/utils/constants';
import getMissionIdBySlug from '~/services/get-mission-id-by-slug';

import './mission-page.scss';

class MissionsPage extends Component {
  state = {
    wins: 0,
    missionStatus: [],
    missionSkills: {},
    adviceSkills: []
  }

  componentDidMount() {
    const { location, generateMission, missionId, saveMissionParams, match, history } = this.props;

    if (location) {
      const { missionSlug, locationSlug } = match.params;
      const { missions } = location;
      const { locked } = missions[missionSlug];

      if (locked) {
        history.push(`/locations/${ locationSlug }`);
      } else {
        generateMission({ missionId });
        saveMissionParams({ isSkillLevels: true }, missionId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentMission } = this.props;

    if (prevProps.currentMission !== currentMission) {
      const missionStatus = new Array(currentMission.length).fill('default');
      this.setState({ missionStatus });
    }
  }

  componentWillUnmount() {
    const { setShownLongMemoryTask, finish, setMissionPopupShown, saveLongMemoryData, setTrainerStage } = this.props;
    setShownLongMemoryTask(false);
    setMissionPopupShown(false);
    saveLongMemoryData(null);
    setTrainerStage(STEP_TEST);
    finish();
  }

  updateMissionStatus = (status, index, skills, mainSkill) => {
    const { missionStatus, wins, missionSkills, adviceSkills } = this.state;
    let diff = {};
    const diffStatus = [ ...missionStatus ];
    diffStatus[index] = status;
    diff.missionStatus = diffStatus;

    if (status === 'complete' || status === 'partially') {
      diff.wins = wins + 1;
      diff.missionSkills = { ...missionSkills };

      Object.keys(skills).forEach(item => {
        if (diff.missionSkills[item]) {
          diff.missionSkills[item] += skills[item];
        } else {
          diff.missionSkills[item] = skills[item];
        }
      })
    } else {
      diff.adviceSkills = adviceSkills.includes(mainSkill) ? adviceSkills : [ ...adviceSkills, mainSkill ];
    }

    this.setState(diff);
  }

  render() {
    const { location, match, currentTrainerIndex, hasShownLongMemoryTask, currentMission } = this.props;
    const { missionSlug, trainerSlug, locationSlug } = match.params;
    const isSingleTrainer = Boolean(match.params.trainerSlug);
    const { wins, missionStatus, missionSkills, adviceSkills } = this.state;
    const isInMission = location && location.missions[missionSlug] && currentMission.length;
    const isShow = isInMission || isSingleTrainer;
    const props = isInMission ? {
      trainerIds: currentMission,
      longMemory: location.missions[missionSlug].longMemory, adviceSkills,
      key: `${currentTrainerIndex}_${hasShownLongMemoryTask}`,
      locationSlug, missionSlug, wins, missionStatus, missionSkills,
      updateMissionStatus: this.updateMissionStatus, isInMission: true
    } : {
      trainerIds: [ trainerSlug ]
    };

    return (
      <Page notHeader={ true } notFooter={ true }>
        <div className="mission-page">
          { isShow && <TrainerWrapper { ...props }/>}
        </div>
      </Page>
    );
  }
}

MissionsPage.propTypes = propTypes;

MissionsPage.defaultProps = {
  currentTrainerIndex: 0,
  hasShownLongMemoryTask: false,
};

const mapDispatchToProps = {
  setShownLongMemoryTask: locationsActions.setShownLongMemoryTask,
  finish: locationsActions.onFinish,
  setMissionPopupShown: locationsActions.setMissionPopupShown,
  saveLongMemoryData: locationsActions.saveLongMemoryData,
  setTrainerStage: locationsActions.setTrainerStage,
  generateMission: trainersActions.setCurrentMission,
  saveMissionParams: profileActions.saveMissionParams
};

const mapStateToProps = (state, props) => ({
  currentTrainerIndex: state.locations.currentTrainerIndex,
  hasShownLongMemoryTask: state.locations.hasShownLongMemoryTask,
  location: state.locations.data[props.match.params.locationSlug],
  currentMission: getCurrentMission(state),
  missionId: getMissionIdBySlug(state, props)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MissionsPage));
