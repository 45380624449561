import { createSelector } from 'reselect';
import * as entityNormalize from '~/utils/entityNormalize';

const locationsSelector = (state) => state.locations;
const profileSelector = (state) => state.profile;

export const getLocationsArray = createSelector(
  locationsSelector,
  (locations) => entityNormalize.toArray(locations.data, locations.ids)
);

export const linkLastOpenMissionSelector = createSelector(
  getLocationsArray,
  profileSelector,
  (locations, profile) => {
    let lastOpenLocation = {};
    let lastOpenMission = {};
    let isLastLocation = false;
    let isLastMission = false;

    locations.forEach((location, index) => {
      if (!location.locked) {
        lastOpenLocation = location;

        if (index === locations.length - 1) {
          isLastLocation = true;
        }
      }
    });

    const { missions, slug } = lastOpenLocation;
    const arrayMissions = Object.keys(missions).sort((a, b) => missions[a].id > missions[b].id ? 1 : -1);

    arrayMissions.forEach((mission, index) => {
      if (!missions[mission].locked) {
        lastOpenMission = { ...missions[mission], missionSlug: mission };

        if (index === arrayMissions.length - 1) {
          isLastMission = true;
        }
      }
    });

    const { id, missionSlug } = lastOpenMission;
    const { completedMissions } = profile;
    const isMissionsEnd = isLastLocation && isLastMission && completedMissions[id];
    const link = isMissionsEnd ? '/missions-end' : `/locations/${ slug }/${ missionSlug }`;

    return link;
  }
);
