export default ({ locations }, props) => {
  const { match } = props;
  const { missionSlug, locationSlug } = match.params;
  const location = locations.data[locationSlug];
  let missionId = null;

  if (location) {
    missionId = location.missions[missionSlug].id;
  }

  return missionId;
};
