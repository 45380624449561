export const HEADER_HEIGHT = 125;
export const FOOTER_HEIGHT = 64;
export const MARGIN_LINE_SHAPES = 80;
export const OFFSET_FIELD_X = 10;
export const OFFSET_FIELD_Y = -3;
export const MARGIN_FIELD = 60;
export const HEIGHT_FIELD = {
  desktop: 456,
  tablet: 414,
  mobile: 300
};
export const WIDTH_FIELD = 586;
export const MAX_ROTATE_ANGLE = 315;
export const MARGIN_TOP_WIN = 35;
export const DASH_SIZE = [4, 4];
export const MARGIN_SHAPE_BOTTOM = 10;
export const MARGIN_SHAPE_SIDE = {
  desktop: 50,
  tablet: 40,
  mobile: 10
};
export const TABLET = 768;
export const MOBILE = 480;
export const ROTATE_ANGLE = 45;
export const KF_RADIAN = 0.0174533;
export const ROTATE_BUTTON_SIZE = 38;
export const ICON_SIZE = 15;
export const MAX_COUNT_OUTLINES = 3;
export const stageName = 'page2_solve';
