import React from 'react'
import Icon from '~/components/Icon/Icon'
import classNames from 'classnames'

import './skillProgress.scss'

const SkillProgress = ({ skill, percent }) => {
  const fullRadius = 23;
  // радиус внутреннего круга
  const valueRadius = 22;
  // offset 100% для svg по формуле
  const strokeDasharray = valueRadius * 2 * Math.PI;
  // offset для значения
  const strokeDashoffset = strokeDasharray - percent * strokeDasharray;

  let skillProgressClass = classNames('skill-progress', {
    [`skill-progress_${ skill }`]: skill
  })

  return (
    <div className={skillProgressClass} >
      <svg className="skill-progress__bar" viewBox="0 0 48 48" >
        <circle
          r={fullRadius}
          className="skill-progress__circle skill-progress__circle_full"
          cx="24"
          cy="24"
        />

        <circle
          r={valueRadius}
          strokeDashoffset={strokeDashoffset}
          strokeDasharray={strokeDasharray}
          className="skill-progress__circle skill-progress__circle_value"
          cx="24"
          cy="24"
        />
      </svg>

      <div className="skill-progress__box">
        <Icon className="skill-progress__icon" name={skill} />
      </div>
    </div>
  )
}

export default SkillProgress
