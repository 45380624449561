import React, { memo } from 'react';
import { Line } from 'react-konva';

import { OUTLINE_COLOR } from '../constants/colors';
import { DASH_SIZE } from '../constants';

const ShapeOutline = (props) => {
  const { item } = props;
  const { out, defaultPosition } = item;
  const styles = {
    draggable: false,
    fill: null,
    shadowBlur: 0,
    dash: out ? DASH_SIZE : null,
    stroke: OUTLINE_COLOR
  };
  const params = { ...item, ...defaultPosition, ...styles, rotation: 0 };

  return <Line { ...params } />;
};

export default memo(ShapeOutline);
