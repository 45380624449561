export default {
  textsInMission: {
    description: [''],
    losing: [
      'К сожалению, передача важной информации сорвалась. Ты ошибся с местом встречи. В следующий раз всё обязательно получится!',
      'Эх... Ты пришёл не туда. В следующий раз будь внимательнее.'
    ],
    victory: [
      'Отлично! Ты вспомнил точное место встречи. Мистер Фоксман тобой очень доволен.',
      'Молодец! Место встречи определено верно!',
      'Передача важной информации состоялась. Место было выбрано абсолютно верно!'
    ]
  }
};
