import React from 'react';
import cx from 'classnames';

import Ok from '~/components/Ok/Ok';
import Fail from '~/components/Fail/Fail';

import './word.scss';

const Word = (props) => {
  const { word, needCheck } = props;
  const hasWord = Boolean(word);
  const wordClasses = cx('word', {
    'word_entered': hasWord,
    'word_correct': needCheck && hasWord,
    'word_wrong': needCheck && !hasWord
  })
  return (
    <div className={ wordClasses }>
      { needCheck && hasWord && <Ok className="word__result"/> }
      { needCheck && !hasWord && <Fail className="word__result"/> }
      { word }
    </div>
  )
}

export default Word;
