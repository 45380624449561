export default ({ seconds, wordsAmount, wordsArray }) => {
  const tooltipContent = 'Перед тобой несколько букв. Перенеси эти буквы в квадратики так, чтобы у тебя получилось слово. Используй все данные буквы.';
  return {
    timer: [seconds * 1000],
    isMiniTooltip: true,
    tooltipContent,
    wordsAmount,
    wordsArray,
    winnerPoints: {
      mind: 2,
      logic: 1
    }
  };
}
