import texts from './texts';
import levels from './levels';

export default  {
  id: 21,
  type: 'secretCode',
  eventCategory: 'WordsFromWord',
  videoAnnounceUrl: null,
  isCenteredContent: true,
  tooltipAnnounce: 'Видеть все множество решений в любой ситуации под силу только спецагенту. Развивай в себе это умение и ты!',
  previewUrl: require('~/static/image/trainerPreviews/secretCode.png'),
  params: {
    ...texts,
    name: 'Тайный шифр',
    hasTimer: true,
    startsImmediately: true,
    maxSkills:  {
      mind: 2,
      logic: 1
    },
    skills: [ 'logic', 'mind' ],
    mainSkill: 'mind',
    levels
  }
};
