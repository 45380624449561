import PropTypes from 'prop-types';

export default {
  params: PropTypes.shape({
    matrixImages: PropTypes.arrayOf(PropTypes.string),
    difficultyParams: PropTypes.object,
    levels: PropTypes.objectOf(PropTypes.shape({
      pairs: PropTypes.number,
      movesLimit: PropTypes.number,
    })),
    skillPoints: PropTypes.object,
  }).isRequired,
  isTimerActive: PropTypes.bool.isRequired,
  onStartCb: PropTypes.func.isRequired,
  onFinishCb: PropTypes.func.isRequired,
};
