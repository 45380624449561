import { createSelector } from 'reselect';
import { skillLevelNames, gameLevelValues } from './levels';
import { getLocationsArray } from '~/store/locations/selector';

const profileSelector = (state) => state.profile;

export const prevSkillLevelNameSelector = createSelector(
  profileSelector,
  (profile) => skillLevelNames[profile.skillLevel - 1] || null
);

export const skillLevelNameSelector = createSelector(
  profileSelector,
  (profile) => skillLevelNames[profile.skillLevel]
);

export const nextSkillLevelNameSelector = createSelector(
  profileSelector,
  (profile) => skillLevelNames[profile.skillLevel + 1] || null
);

export const levelMaxValueSelector = createSelector(
  profileSelector,
  (profile) => {
    const arrayLevelValues = Object.values(gameLevelValues);
    const next = gameLevelValues[profile.gameLevel + 1];
    return next || arrayLevelValues[arrayLevelValues.length - 1];
  }
);

export const prevLevelMaxValueSelector = createSelector(
  profileSelector,
  (profile) => {
    const arrayLevelValues = Object.values(gameLevelValues);
    const current = gameLevelValues[profile.gameLevel];
    return current || arrayLevelValues[arrayLevelValues.length - 1];
  }
);

export const giftsStatusSelector = createSelector(
  profileSelector,
  getLocationsArray,
  (profile, locations) => {
    const missions = locations.reduce((acc = [], item) => [ ...acc, ...Object.values(item.missions) ], []);
    const gifts = missions.filter(item => profile.completedMissions[item.id]).map(item => item.gift);
    return gifts;
  }
);
