import React from 'react';
import SkillWidget from '~/components/SkillWidget/SkillWidget'

import './greeting.scss';

const Greeting = () => {
  return (
    <div className="greeting">
      <header className="greeting__header">
        <h1 className="greeting__title">
          Привет, Агент Фокс!
        </h1>

        <p className="greeting__text">
          Рад тебя видеть в школе спецагентов! Здесь мы развиваем 5 навыков настоящего агента и крутого профессионала.
        </p>
      </header>

      <div className="greeting__skill">
        <SkillWidget />
      </div>
    </div>
  );
}

export default Greeting
