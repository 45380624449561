import React, { useState } from 'react';
import { Group } from 'react-konva';

import ShapeTransform from '../ShapeTransform';
import ShapeOutline from '../ShapeOutline/ShapeOutline';

const LineShapes = (props) => {
  const [ isSelected, setSelected ] = useState(null);

  return (
    <Group>
      { renderShapesOutline(props.shapes) }
      { renderShapes({ ...props, isSelected, setSelected }) }
    </Group>
  );
};

const renderShapesOutline = (shapes) => {
  return shapes.map((item, index) => <ShapeOutline key={ index } item={ item } />);
};

const renderShapes = (props) => {
  const { shapes, isSelected, setSelected, isOver, rotate, handleDragMove, handleDragEnd } = props;
  return (
    shapes.map((item, index) => (
      <ShapeTransform isSelected={ isSelected } setSelected={ setSelected } key={ index } index={ index }
                      isOver={ isOver } onDragMove={ (e) => handleDragMove(e, index) } params={ item }
                      onDragEnd={ (e) => handleDragEnd(e, index) } rotate={ () => rotate(index) } />
    ))
  );
};

export default LineShapes;
