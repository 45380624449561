import React from 'react';
import PropTypes from 'prop-types';

import icons from '~/services/get-icons';

const SvgWrapper = (props) => {
  const { type, isFilter, className } = props;
  const { width, height, content, filter } = icons[type];
  const { dy, stdDeviation, values } = filter;
  const FILTER_ID = `filter-${ type }`;
  const viewBox = `0 0 ${ width } ${ height }`;
  const gProps = isFilter ? { filter: `url(#${ FILTER_ID })` } : {};
  const filterProps = { ...filter, id: FILTER_ID, filterUnits: 'userSpaceOnUse', colorInterpolationFilters: 'sRGB' };

  return (
    <svg width={ width } height={ height } viewBox={ viewBox }
         className={ className }  fill="none" xmlns="http://www.w3.org/2000/svg">
      <g { ...gProps }>
        { content }
      </g>
      {
        isFilter &&
        <defs>
          <filter { ...filterProps }>
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy={ dy }/>
            <feGaussianBlur stdDeviation={ stdDeviation || 7.5 }/>
            <feColorMatrix type="matrix" values={ values || '0 0 0 0 0.174672 0 0 0 0 0.405768 0 0 0 0 1 0 0 0 0.8 0' }/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>
      }
    </svg>
  );
}

SvgWrapper.propTypes = {
  type: PropTypes.string,
  isFilter: PropTypes.bool,
  className: PropTypes.string
};

export default SvgWrapper;
