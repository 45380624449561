import level1 from './levelsContent/level1';
import level2 from './levelsContent/level2';
import level3 from './levelsContent/level3';
import level4 from './levelsContent/level4';
import level5 from './levelsContent/level5';

export default {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5
};
