export default {
  all: {
    active: true,
    text: "Все",
    count: 0
  },
  focus: {
    active: false,
    text: "Внимание",
    count: 0
  },
  memory: {
    active: false,
    text: "Память",
    count: 0
  },
  logic: {
    active: false,
    text: "Логика",
    count: 0
  },
  imagination: {
    active: false,
    text: "Воображение",
    count: 0
  },
  mind: {
    active: false,
    text: "Мышление",
    count: 0
  }
};
