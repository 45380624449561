export default [
  {
    sequence: [1, 21, 11, 31, 21],
    answer: 41,
    actions: ['+20', '-10']
  },
  {
    sequence: [2, 22, 12, 32, 22],
    answer: 42,
    actions: ['+20', '-10']
  },
  {
    sequence: [3, 23, 13, 33, 23],
    answer: 43,
    actions: ['+20', '-10']
  },
  {
    sequence: [4, 24, 14, 34, 24],
    answer: 44,
    actions: ['+20', '-10']
  },
  {
    sequence: [5, 25, 15, 35, 25],
    answer: 45,
    actions: ['+20', '-10']
  },
  {
    sequence: [6, 26, 16, 36, 26],
    answer: 46,
    actions: ['+20', '-10']
  },
  {
    sequence: [7, 27, 17, 37, 27],
    answer: 47,
    actions: ['+20', '-10']
  },
  {
    sequence: [8, 28, 18, 38, 28],
    answer: 48,
    actions: ['+20', '-10']
  },
  {
    sequence: [9, 29, 19, 39, 29],
    answer: 49,
    actions: ['+20', '-10']
  },
  {
    sequence: [10, 30, 20, 40, 30],
    answer: 50,
    actions: ['+20', '-10']
  },
  {
    sequence: [11, 31, 21, 41, 31],
    answer: 51,
    actions: ['+20', '-10']
  },
  {
    sequence: [12, 32, 22, 42, 32],
    answer: 52,
    actions: ['+20', '-10']
  },
  {
    sequence: [13, 33, 23, 43, 33],
    answer: 53,
    actions: ['+20', '-10']
  },
  {
    sequence: [14, 34, 24, 44, 34],
    answer: 54,
    actions: ['+20', '-10']
  },
  {
    sequence: [15, 35, 25, 45, 35],
    answer: 55,
    actions: ['+20', '-10']
  },
  {
    sequence: [16, 36, 26, 46, 36],
    answer: 56,
    actions: ['+20', '-10']
  },
  {
    sequence: [17, 37, 27, 47, 37],
    answer: 57,
    actions: ['+20', '-10']
  },
  {
    sequence: [18, 38, 28, 48, 38],
    answer: 58,
    actions: ['+20', '-10']
  },
  {
    sequence: [19, 39, 29, 49, 39],
    answer: 59,
    actions: ['+20', '-10']
  },
  {
    sequence: [20, 40, 30, 50, 40],
    answer: 60,
    actions: ['+20', '-10']
  },
  {
    sequence: [21, 41, 31, 51, 41],
    answer: 61,
    actions: ['+20', '-10']
  },
  {
    sequence: [22, 42, 32, 52, 42],
    answer: 62,
    actions: ['+20', '-10']
  },
  {
    sequence: [23, 43, 33, 53, 43],
    answer: 63,
    actions: ['+20', '-10']
  },
  {
    sequence: [24, 44, 34, 54, 44],
    answer: 64,
    actions: ['+20', '-10']
  },
  {
    sequence: [25, 45, 35, 55, 45],
    answer: 65,
    actions: ['+20', '-10']
  },
  {
    sequence: [26, 46, 36, 56, 46],
    answer: 66,
    actions: ['+20', '-10']
  },
  {
    sequence: [27, 47, 37, 57, 47],
    answer: 67,
    actions: ['+20', '-10']
  },
  {
    sequence: [28, 48, 38, 58, 48],
    answer: 68,
    actions: ['+20', '-10']
  },
  {
    sequence: [29, 49, 39, 59, 49],
    answer: 69,
    actions: ['+20', '-10']
  },
  {
    sequence: [30, 50, 40, 60, 50],
    answer: 70,
    actions: ['+20', '-10']
  },
  {
    sequence: [31, 51, 41, 61, 51],
    answer: 71,
    actions: ['+20', '-10']
  },
  {
    sequence: [32, 52, 42, 62, 52],
    answer: 72,
    actions: ['+20', '-10']
  },
  {
    sequence: [33, 53, 43, 63, 53],
    answer: 73,
    actions: ['+20', '-10']
  },
  {
    sequence: [34, 54, 44, 64, 54],
    answer: 74,
    actions: ['+20', '-10']
  },
  {
    sequence: [35, 55, 45, 65, 55],
    answer: 75,
    actions: ['+20', '-10']
  },
  {
    sequence: [36, 56, 46, 66, 56],
    answer: 76,
    actions: ['+20', '-10']
  },
  {
    sequence: [37, 57, 47, 67, 57],
    answer: 77,
    actions: ['+20', '-10']
  },
  {
    sequence: [38, 58, 48, 68, 58],
    answer: 78,
    actions: ['+20', '-10']
  },
  {
    sequence: [39, 59, 49, 69, 59],
    answer: 79,
    actions: ['+20', '-10']
  },
  {
    sequence: [40, 60, 50, 70, 60],
    answer: 80,
    actions: ['+20', '-10']
  },
  {
    sequence: [41, 61, 51, 71, 61],
    answer: 81,
    actions: ['+20', '-10']
  },
  {
    sequence: [42, 62, 52, 72, 62],
    answer: 82,
    actions: ['+20', '-10']
  },
  {
    sequence: [43, 63, 53, 73, 63],
    answer: 83,
    actions: ['+20', '-10']
  },
  {
    sequence: [44, 64, 54, 74, 64],
    answer: 84,
    actions: ['+20', '-10']
  },
  {
    sequence: [45, 65, 55, 75, 65],
    answer: 85,
    actions: ['+20', '-10']
  },
  {
    sequence: [46, 66, 56, 76, 66],
    answer: 86,
    actions: ['+20', '-10']
  },
  {
    sequence: [47, 67, 57, 77, 67],
    answer: 87,
    actions: ['+20', '-10']
  },
  {
    sequence: [48, 68, 58, 78, 68],
    answer: 88,
    actions: ['+20', '-10']
  },
  {
    sequence: [49, 69, 59, 79, 69],
    answer: 89,
    actions: ['+20', '-10']
  },
  {
    sequence: [50, 70, 60, 80, 70],
    answer: 90,
    actions: ['+20', '-10']
  },
  {
    sequence: [51, 71, 61, 81, 71],
    answer: 91,
    actions: ['+20', '-10']
  },
  {
    sequence: [52, 72, 62, 82, 72],
    answer: 92,
    actions: ['+20', '-10']
  },
  {
    sequence: [53, 73, 63, 83, 73],
    answer: 93,
    actions: ['+20', '-10']
  },
  {
    sequence: [54, 74, 64, 84, 74],
    answer: 94,
    actions: ['+20', '-10']
  },
  {
    sequence: [55, 75, 65, 85, 75],
    answer: 95,
    actions: ['+20', '-10']
  },
  {
    sequence: [56, 76, 66, 86, 76],
    answer: 96,
    actions: ['+20', '-10']
  },
  {
    sequence: [57, 77, 67, 87, 77],
    answer: 97,
    actions: ['+20', '-10']
  },
  {
    sequence: [58, 78, 68, 88, 78],
    answer: 98,
    actions: ['+20', '-10']
  },
  {
    sequence: [59, 79, 69, 89, 79],
    answer: 99,
    actions: ['+20', '-10']
  },
  {
    sequence: [1, 31, 21, 51, 41],
    answer: 71,
    actions: ['+30', '-10']
  },
  {
    sequence: [2, 32, 22, 52, 42],
    answer: 72,
    actions: ['+30', '-10']
  },
  {
    sequence: [3, 33, 23, 53, 43],
    answer: 73,
    actions: ['+30', '-10']
  },
  {
    sequence: [4, 34, 24, 54, 44],
    answer: 74,
    actions: ['+30', '-10']
  },
  {
    sequence: [5, 35, 25, 55, 45],
    answer: 75,
    actions: ['+30', '-10']
  },
  {
    sequence: [6, 36, 26, 56, 46],
    answer: 76,
    actions: ['+30', '-10']
  },
  {
    sequence: [7, 37, 27, 57, 47],
    answer: 77,
    actions: ['+30', '-10']
  },
  {
    sequence: [8, 38, 28, 58, 48],
    answer: 78,
    actions: ['+30', '-10']
  },
  {
    sequence: [9, 39, 29, 59, 49],
    answer: 79,
    actions: ['+30', '-10']
  },
  {
    sequence: [10, 40, 30, 60, 50],
    answer: 80,
    actions: ['+30', '-10']
  },
  {
    sequence: [11, 41, 31, 61, 51],
    answer: 81,
    actions: ['+30', '-10']
  },
  {
    sequence: [12, 42, 32, 62, 52],
    answer: 82,
    actions: ['+30', '-10']
  },
  {
    sequence: [13, 43, 33, 63, 53],
    answer: 83,
    actions: ['+30', '-10']
  },
  {
    sequence: [14, 44, 34, 64, 54],
    answer: 84,
    actions: ['+30', '-10']
  },
  {
    sequence: [15, 45, 35, 65, 55],
    answer: 85,
    actions: ['+30', '-10']
  },
  {
    sequence: [16, 46, 36, 66, 56],
    answer: 86,
    actions: ['+30', '-10']
  },
  {
    sequence: [17, 47, 37, 67, 57],
    answer: 87,
    actions: ['+30', '-10']
  },
  {
    sequence: [18, 48, 38, 68, 58],
    answer: 88,
    actions: ['+30', '-10']
  },
  {
    sequence: [19, 49, 39, 69, 59],
    answer: 89,
    actions: ['+30', '-10']
  },
  {
    sequence: [20, 50, 40, 70, 60],
    answer: 90,
    actions: ['+30', '-10']
  },
  {
    sequence: [21, 51, 41, 71, 61],
    answer: 91,
    actions: ['+30', '-10']
  },
  {
    sequence: [22, 52, 42, 72, 62],
    answer: 92,
    actions: ['+30', '-10']
  },
  {
    sequence: [23, 53, 43, 73, 63],
    answer: 93,
    actions: ['+30', '-10']
  },
  {
    sequence: [24, 54, 44, 74, 64],
    answer: 94,
    actions: ['+30', '-10']
  },
  {
    sequence: [25, 55, 45, 75, 65],
    answer: 95,
    actions: ['+30', '-10']
  },
  {
    sequence: [26, 56, 46, 76, 66],
    answer: 96,
    actions: ['+30', '-10']
  },
  {
    sequence: [27, 57, 47, 77, 67],
    answer: 97,
    actions: ['+30', '-10']
  },
  {
    sequence: [28, 58, 48, 78, 68],
    answer: 98,
    actions: ['+30', '-10']
  },
  {
    sequence: [29, 59, 49, 79, 69],
    answer: 99,
    actions: ['+30', '-10']
  },
  {
    sequence: [21, 31, 11, 21],
    answer: 1,
    actions: ['+10', '-20']
  },
  {
    sequence: [22, 32, 12, 22],
    answer: 2,
    actions: ['+10', '-20']
  },
  {
    sequence: [23, 33, 13, 23],
    answer: 3,
    actions: ['+10', '-20']
  },
  {
    sequence: [24, 34, 14, 24],
    answer: 4,
    actions: ['+10', '-20']
  },
  {
    sequence: [25, 35, 15, 25],
    answer: 5,
    actions: ['+10', '-20']
  },
  {
    sequence: [26, 36, 16, 26],
    answer: 6,
    actions: ['+10', '-20']
  },
  {
    sequence: [27, 37, 17, 27],
    answer: 7,
    actions: ['+10', '-20']
  },
  {
    sequence: [28, 38, 18, 28],
    answer: 8,
    actions: ['+10', '-20']
  },
  {
    sequence: [29, 39, 19, 29],
    answer: 9,
    actions: ['+10', '-20']
  },
  {
    sequence: [30, 40, 20, 30],
    answer: 10,
    actions: ['+10', '-20']
  },
  {
    sequence: [31, 41, 21, 31],
    answer: 11,
    actions: ['+10', '-20']
  },
  {
    sequence: [32, 42, 22, 32],
    answer: 12,
    actions: ['+10', '-20']
  },
  {
    sequence: [33, 43, 23, 33],
    answer: 13,
    actions: ['+10', '-20']
  },
  {
    sequence: [34, 44, 24, 34],
    answer: 14,
    actions: ['+10', '-20']
  },
  {
    sequence: [35, 45, 25, 35],
    answer: 15,
    actions: ['+10', '-20']
  },
  {
    sequence: [36, 46, 26, 36],
    answer: 16,
    actions: ['+10', '-20']
  },
  {
    sequence: [37, 47, 27, 37],
    answer: 17,
    actions: ['+10', '-20']
  },
  {
    sequence: [38, 48, 28, 38],
    answer: 18,
    actions: ['+10', '-20']
  },
  {
    sequence: [39, 49, 29, 39],
    answer: 19,
    actions: ['+10', '-20']
  },
  {
    sequence: [40, 50, 30, 40],
    answer: 20,
    actions: ['+10', '-20']
  },
  {
    sequence: [41, 51, 31, 41],
    answer: 21,
    actions: ['+10', '-20']
  },
  {
    sequence: [42, 52, 32, 42],
    answer: 22,
    actions: ['+10', '-20']
  },
  {
    sequence: [43, 53, 33, 43],
    answer: 23,
    actions: ['+10', '-20']
  },
  {
    sequence: [44, 54, 34, 44],
    answer: 24,
    actions: ['+10', '-20']
  },
  {
    sequence: [45, 55, 35, 45],
    answer: 25,
    actions: ['+10', '-20']
  },
  {
    sequence: [46, 56, 36, 46],
    answer: 26,
    actions: ['+10', '-20']
  },
  {
    sequence: [47, 57, 37, 47],
    answer: 27,
    actions: ['+10', '-20']
  },
  {
    sequence: [48, 58, 38, 48],
    answer: 28,
    actions: ['+10', '-20']
  },
  {
    sequence: [49, 59, 39, 49],
    answer: 29,
    actions: ['+10', '-20']
  },
  {
    sequence: [50, 60, 40, 50],
    answer: 30,
    actions: ['+10', '-20']
  },
  {
    sequence: [51, 61, 41, 51],
    answer: 31,
    actions: ['+10', '-20']
  },
  {
    sequence: [52, 62, 42, 52],
    answer: 32,
    actions: ['+10', '-20']
  },
  {
    sequence: [53, 63, 43, 53],
    answer: 33,
    actions: ['+10', '-20']
  },
  {
    sequence: [54, 64, 44, 54],
    answer: 34,
    actions: ['+10', '-20']
  },
  {
    sequence: [55, 65, 45, 55],
    answer: 35,
    actions: ['+10', '-20']
  },
  {
    sequence: [56, 66, 46, 56],
    answer: 36,
    actions: ['+10', '-20']
  },
  {
    sequence: [57, 67, 47, 57],
    answer: 37,
    actions: ['+10', '-20']
  },
  {
    sequence: [58, 68, 48, 58],
    answer: 38,
    actions: ['+10', '-20']
  },
  {
    sequence: [59, 69, 49, 59],
    answer: 39,
    actions: ['+10', '-20']
  },
  {
    sequence: [60, 70, 50, 60],
    answer: 40,
    actions: ['+10', '-20']
  },
  {
    sequence: [61, 71, 51, 61],
    answer: 41,
    actions: ['+10', '-20']
  },
  {
    sequence: [62, 72, 52, 62],
    answer: 42,
    actions: ['+10', '-20']
  },
  {
    sequence: [63, 73, 53, 63],
    answer: 43,
    actions: ['+10', '-20']
  },
  {
    sequence: [64, 74, 54, 64],
    answer: 44,
    actions: ['+10', '-20']
  },
  {
    sequence: [65, 75, 55, 65],
    answer: 45,
    actions: ['+10', '-20']
  },
  {
    sequence: [66, 76, 56, 66],
    answer: 46,
    actions: ['+10', '-20']
  },
  {
    sequence: [67, 77, 57, 67],
    answer: 47,
    actions: ['+10', '-20']
  },
  {
    sequence: [68, 78, 58, 68],
    answer: 48,
    actions: ['+10', '-20']
  },
  {
    sequence: [69, 79, 59, 69],
    answer: 49,
    actions: ['+10', '-20']
  },
  {
    sequence: [70, 80, 60, 70],
    answer: 50,
    actions: ['+10', '-20']
  },
  {
    sequence: [71, 81, 61, 71],
    answer: 51,
    actions: ['+10', '-20']
  },
  {
    sequence: [72, 82, 62, 72],
    answer: 52,
    actions: ['+10', '-20']
  },
  {
    sequence: [73, 83, 63, 73],
    answer: 53,
    actions: ['+10', '-20']
  },
  {
    sequence: [74, 84, 64, 74],
    answer: 54,
    actions: ['+10', '-20']
  },
  {
    sequence: [75, 85, 65, 75],
    answer: 55,
    actions: ['+10', '-20']
  },
  {
    sequence: [76, 86, 66, 76],
    answer: 56,
    actions: ['+10', '-20']
  },
  {
    sequence: [77, 87, 67, 77],
    answer: 57,
    actions: ['+10', '-20']
  },
  {
    sequence: [78, 88, 68, 78],
    answer: 58,
    actions: ['+10', '-20']
  },
  {
    sequence: [79, 89, 69, 79],
    answer: 59,
    actions: ['+10', '-20']
  },
  {
    sequence: [80, 90, 70, 80],
    answer: 60,
    actions: ['+10', '-20']
  },
  {
    sequence: [81, 91, 71, 81],
    answer: 61,
    actions: ['+10', '-20']
  },
  {
    sequence: [82, 92, 72, 82],
    answer: 62,
    actions: ['+10', '-20']
  },
  {
    sequence: [83, 93, 73, 83],
    answer: 63,
    actions: ['+10', '-20']
  },
  {
    sequence: [84, 94, 74, 84],
    answer: 64,
    actions: ['+10', '-20']
  },
  {
    sequence: [85, 95, 75, 85],
    answer: 65,
    actions: ['+10', '-20']
  },
  {
    sequence: [86, 96, 76, 86],
    answer: 66,
    actions: ['+10', '-20']
  },
  {
    sequence: [87, 97, 77, 87],
    answer: 67,
    actions: ['+10', '-20']
  },
  {
    sequence: [88, 98, 78, 88],
    answer: 68,
    actions: ['+10', '-20']
  },
  {
    sequence: [89, 99, 79, 89],
    answer: 69,
    actions: ['+10', '-20']
  },
  {
    sequence: [90, 100, 80, 90],
    answer: 70,
    actions: ['+10', '-20']
  },
  {
    sequence: [1, 31, 11, 41],
    answer: 21,
    actions: ['+30', '-20']
  },
  {
    sequence: [2, 32, 12, 42],
    answer: 22,
    actions: ['+30', '-20']
  },
  {
    sequence: [3, 33, 13, 43],
    answer: 23,
    actions: ['+30', '-20']
  },
  {
    sequence: [4, 34, 14, 44],
    answer: 24,
    actions: ['+30', '-20']
  },
  {
    sequence: [5, 35, 15, 45],
    answer: 25,
    actions: ['+30', '-20']
  },
  {
    sequence: [6, 36, 16, 46],
    answer: 26,
    actions: ['+30', '-20']
  },
  {
    sequence: [7, 37, 17, 47],
    answer: 27,
    actions: ['+30', '-20']
  },
  {
    sequence: [8, 38, 18, 48],
    answer: 28,
    actions: ['+30', '-20']
  },
  {
    sequence: [9, 39, 19, 49],
    answer: 29,
    actions: ['+30', '-20']
  },
  {
    sequence: [10, 40, 20, 50],
    answer: 30,
    actions: ['+30', '-20']
  },
  {
    sequence: [11, 41, 21, 51],
    answer: 31,
    actions: ['+30', '-20']
  },
  {
    sequence: [12, 42, 22, 52],
    answer: 32,
    actions: ['+30', '-20']
  },
  {
    sequence: [13, 43, 23, 53],
    answer: 33,
    actions: ['+30', '-20']
  },
  {
    sequence: [14, 44, 24, 54],
    answer: 34,
    actions: ['+30', '-20']
  },
  {
    sequence: [15, 45, 25, 55],
    answer: 35,
    actions: ['+30', '-20']
  },
  {
    sequence: [16, 46, 26, 56],
    answer: 36,
    actions: ['+30', '-20']
  },
  {
    sequence: [17, 47, 27, 57],
    answer: 37,
    actions: ['+30', '-20']
  },
  {
    sequence: [18, 48, 28, 58],
    answer: 38,
    actions: ['+30', '-20']
  },
  {
    sequence: [19, 49, 29, 59],
    answer: 39,
    actions: ['+30', '-20']
  },
  {
    sequence: [20, 50, 30, 60],
    answer: 40,
    actions: ['+30', '-20']
  },
  {
    sequence: [21, 51, 31, 61],
    answer: 41,
    actions: ['+30', '-20']
  },
  {
    sequence: [22, 52, 32, 62],
    answer: 42,
    actions: ['+30', '-20']
  },
  {
    sequence: [23, 53, 33, 63],
    answer: 43,
    actions: ['+30', '-20']
  },
  {
    sequence: [24, 54, 34, 64],
    answer: 44,
    actions: ['+30', '-20']
  },
  {
    sequence: [25, 55, 35, 65],
    answer: 45,
    actions: ['+30', '-20']
  },
  {
    sequence: [26, 56, 36, 66],
    answer: 46,
    actions: ['+30', '-20']
  },
  {
    sequence: [27, 57, 37, 67],
    answer: 47,
    actions: ['+30', '-20']
  },
  {
    sequence: [28, 58, 38, 68],
    answer: 48,
    actions: ['+30', '-20']
  },
  {
    sequence: [29, 59, 39, 69],
    answer: 49,
    actions: ['+30', '-20']
  },
  {
    sequence: [30, 60, 40, 70],
    answer: 50,
    actions: ['+30', '-20']
  },
  {
    sequence: [31, 61, 41, 71],
    answer: 51,
    actions: ['+30', '-20']
  },
  {
    sequence: [32, 62, 42, 72],
    answer: 52,
    actions: ['+30', '-20']
  },
  {
    sequence: [33, 63, 43, 73],
    answer: 53,
    actions: ['+30', '-20']
  },
  {
    sequence: [34, 64, 44, 74],
    answer: 54,
    actions: ['+30', '-20']
  },
  {
    sequence: [35, 65, 45, 75],
    answer: 55,
    actions: ['+30', '-20']
  },
  {
    sequence: [36, 66, 46, 76],
    answer: 56,
    actions: ['+30', '-20']
  },
  {
    sequence: [37, 67, 47, 77],
    answer: 57,
    actions: ['+30', '-20']
  },
  {
    sequence: [38, 68, 48, 78],
    answer: 58,
    actions: ['+30', '-20']
  },
  {
    sequence: [39, 69, 49, 79],
    answer: 59,
    actions: ['+30', '-20']
  },
  {
    sequence: [40, 70, 50, 80],
    answer: 60,
    actions: ['+30', '-20']
  },
  {
    sequence: [41, 71, 51, 81],
    answer: 61,
    actions: ['+30', '-20']
  },
  {
    sequence: [42, 72, 52, 82],
    answer: 62,
    actions: ['+30', '-20']
  },
  {
    sequence: [43, 73, 53, 83],
    answer: 63,
    actions: ['+30', '-20']
  },
  {
    sequence: [44, 74, 54, 84],
    answer: 64,
    actions: ['+30', '-20']
  },
  {
    sequence: [45, 75, 55, 85],
    answer: 65,
    actions: ['+30', '-20']
  },
  {
    sequence: [46, 76, 56, 86],
    answer: 66,
    actions: ['+30', '-20']
  },
  {
    sequence: [47, 77, 57, 87],
    answer: 67,
    actions: ['+30', '-20']
  },
  {
    sequence: [48, 78, 58, 88],
    answer: 68,
    actions: ['+30', '-20']
  },
  {
    sequence: [49, 79, 59, 89],
    answer: 69,
    actions: ['+30', '-20']
  },
  {
    sequence: [50, 80, 60, 90],
    answer: 70,
    actions: ['+30', '-20']
  },
  {
    sequence: [51, 81, 61, 91],
    answer: 71,
    actions: ['+30', '-20']
  },
  {
    sequence: [52, 82, 62, 92],
    answer: 72,
    actions: ['+30', '-20']
  },
  {
    sequence: [53, 83, 63, 93],
    answer: 73,
    actions: ['+30', '-20']
  },
  {
    sequence: [54, 84, 64, 94],
    answer: 74,
    actions: ['+30', '-20']
  },
  {
    sequence: [55, 85, 65, 95],
    answer: 75,
    actions: ['+30', '-20']
  },
  {
    sequence: [56, 86, 66, 96],
    answer: 76,
    actions: ['+30', '-20']
  },
  {
    sequence: [57, 87, 67, 97],
    answer: 77,
    actions: ['+30', '-20']
  },
  {
    sequence: [58, 88, 68, 98],
    answer: 78,
    actions: ['+30', '-20']
  },
  {
    sequence: [59, 89, 69, 99],
    answer: 79,
    actions: ['+30', '-20']
  },
  {
    sequence: [1, 41, 21, 61],
    answer: 41,
    actions: ['+40', '-20']
  },
  {
    sequence: [2, 42, 22, 62],
    answer: 42,
    actions: ['+40', '-20']
  },
  {
    sequence: [3, 43, 23, 63],
    answer: 43,
    actions: ['+40', '-20']
  },
  {
    sequence: [4, 44, 24, 64],
    answer: 44,
    actions: ['+40', '-20']
  },
  {
    sequence: [5, 45, 25, 65],
    answer: 45,
    actions: ['+40', '-20']
  },
  {
    sequence: [6, 46, 26, 66],
    answer: 46,
    actions: ['+40', '-20']
  },
  {
    sequence: [7, 47, 27, 67],
    answer: 47,
    actions: ['+40', '-20']
  },
  {
    sequence: [8, 48, 28, 68],
    answer: 48,
    actions: ['+40', '-20']
  },
  {
    sequence: [9, 49, 29, 69],
    answer: 49,
    actions: ['+40', '-20']
  },
  {
    sequence: [10, 50, 30, 70],
    answer: 50,
    actions: ['+40', '-20']
  },
  {
    sequence: [11, 51, 31, 71],
    answer: 51,
    actions: ['+40', '-20']
  },
  {
    sequence: [12, 52, 32, 72],
    answer: 52,
    actions: ['+40', '-20']
  },
  {
    sequence: [13, 53, 33, 73],
    answer: 53,
    actions: ['+40', '-20']
  },
  {
    sequence: [14, 54, 34, 74],
    answer: 54,
    actions: ['+40', '-20']
  },
  {
    sequence: [15, 55, 35, 75],
    answer: 55,
    actions: ['+40', '-20']
  },
  {
    sequence: [16, 56, 36, 76],
    answer: 56,
    actions: ['+40', '-20']
  },
  {
    sequence: [17, 57, 37, 77],
    answer: 57,
    actions: ['+40', '-20']
  },
  {
    sequence: [18, 58, 38, 78],
    answer: 58,
    actions: ['+40', '-20']
  },
  {
    sequence: [19, 59, 39, 79],
    answer: 59,
    actions: ['+40', '-20']
  },
  {
    sequence: [20, 60, 40, 80],
    answer: 60,
    actions: ['+40', '-20']
  },
  {
    sequence: [21, 61, 41, 81],
    answer: 61,
    actions: ['+40', '-20']
  },
  {
    sequence: [22, 62, 42, 82],
    answer: 62,
    actions: ['+40', '-20']
  },
  {
    sequence: [23, 63, 43, 83],
    answer: 63,
    actions: ['+40', '-20']
  },
  {
    sequence: [24, 64, 44, 84],
    answer: 64,
    actions: ['+40', '-20']
  },
  {
    sequence: [25, 65, 45, 85],
    answer: 65,
    actions: ['+40', '-20']
  },
  {
    sequence: [26, 66, 46, 86],
    answer: 66,
    actions: ['+40', '-20']
  },
  {
    sequence: [27, 67, 47, 87],
    answer: 67,
    actions: ['+40', '-20']
  },
  {
    sequence: [28, 68, 48, 88],
    answer: 68,
    actions: ['+40', '-20']
  },
  {
    sequence: [29, 69, 49, 89],
    answer: 69,
    actions: ['+40', '-20']
  },
  {
    sequence: [30, 70, 50, 90],
    answer: 70,
    actions: ['+40', '-20']
  },
  {
    sequence: [31, 71, 51, 91],
    answer: 71,
    actions: ['+40', '-20']
  },
  {
    sequence: [32, 72, 52, 92],
    answer: 72,
    actions: ['+40', '-20']
  },
  {
    sequence: [33, 73, 53, 93],
    answer: 73,
    actions: ['+40', '-20']
  },
  {
    sequence: [34, 74, 54, 94],
    answer: 74,
    actions: ['+40', '-20']
  },
  {
    sequence: [35, 75, 55, 95],
    answer: 75,
    actions: ['+40', '-20']
  },
  {
    sequence: [36, 76, 56, 96],
    answer: 76,
    actions: ['+40', '-20']
  },
  {
    sequence: [37, 77, 57, 97],
    answer: 77,
    actions: ['+40', '-20']
  },
  {
    sequence: [38, 78, 58, 98],
    answer: 78,
    actions: ['+40', '-20']
  },
  {
    sequence: [39, 79, 59, 99],
    answer: 79,
    actions: ['+40', '-20']
  },
  {
    sequence: [1, 51, 31, 81],
    answer: 61,
    actions: ['+50', '-20']
  },
  {
    sequence: [2, 52, 32, 82],
    answer: 62,
    actions: ['+50', '-20']
  },
  {
    sequence: [3, 53, 33, 83],
    answer: 63,
    actions: ['+50', '-20']
  },
  {
    sequence: [4, 54, 34, 84],
    answer: 64,
    actions: ['+50', '-20']
  },
  {
    sequence: [5, 55, 35, 85],
    answer: 65,
    actions: ['+50', '-20']
  },
  {
    sequence: [6, 56, 36, 86],
    answer: 66,
    actions: ['+50', '-20']
  },
  {
    sequence: [7, 57, 37, 87],
    answer: 67,
    actions: ['+50', '-20']
  },
  {
    sequence: [8, 58, 38, 88],
    answer: 68,
    actions: ['+50', '-20']
  },
  {
    sequence: [9, 59, 39, 89],
    answer: 69,
    actions: ['+50', '-20']
  },
  {
    sequence: [10, 60, 40, 90],
    answer: 70,
    actions: ['+50', '-20']
  },
  {
    sequence: [11, 61, 41, 91],
    answer: 71,
    actions: ['+50', '-20']
  },
  {
    sequence: [12, 62, 42, 92],
    answer: 72,
    actions: ['+50', '-20']
  },
  {
    sequence: [13, 63, 43, 93],
    answer: 73,
    actions: ['+50', '-20']
  },
  {
    sequence: [14, 64, 44, 94],
    answer: 74,
    actions: ['+50', '-20']
  },
  {
    sequence: [15, 65, 45, 95],
    answer: 75,
    actions: ['+50', '-20']
  },
  {
    sequence: [16, 66, 46, 96],
    answer: 76,
    actions: ['+50', '-20']
  },
  {
    sequence: [17, 67, 47, 97],
    answer: 77,
    actions: ['+50', '-20']
  },
  {
    sequence: [18, 68, 48, 98],
    answer: 78,
    actions: ['+50', '-20']
  },
  {
    sequence: [19, 69, 49, 99],
    answer: 79,
    actions: ['+50', '-20']
  },
  {
    sequence: [41, 51, 21, 31],
    answer: 1,
    actions: ['+10', '-30']
  },
  {
    sequence: [42, 52, 22, 32],
    answer: 2,
    actions: ['+10', '-30']
  },
  {
    sequence: [43, 53, 23, 33],
    answer: 3,
    actions: ['+10', '-30']
  },
  {
    sequence: [44, 54, 24, 34],
    answer: 4,
    actions: ['+10', '-30']
  },
  {
    sequence: [45, 55, 25, 35],
    answer: 5,
    actions: ['+10', '-30']
  },
  {
    sequence: [46, 56, 26, 36],
    answer: 6,
    actions: ['+10', '-30']
  },
  {
    sequence: [47, 57, 27, 37],
    answer: 7,
    actions: ['+10', '-30']
  },
  {
    sequence: [48, 58, 28, 38],
    answer: 8,
    actions: ['+10', '-30']
  },
  {
    sequence: [49, 59, 29, 39],
    answer: 9,
    actions: ['+10', '-30']
  },
  {
    sequence: [50, 60, 30, 40],
    answer: 10,
    actions: ['+10', '-30']
  },
  {
    sequence: [51, 61, 31, 41],
    answer: 11,
    actions: ['+10', '-30']
  },
  {
    sequence: [52, 62, 32, 42],
    answer: 12,
    actions: ['+10', '-30']
  },
  {
    sequence: [53, 63, 33, 43],
    answer: 13,
    actions: ['+10', '-30']
  },
  {
    sequence: [54, 64, 34, 44],
    answer: 14,
    actions: ['+10', '-30']
  },
  {
    sequence: [55, 65, 35, 45],
    answer: 15,
    actions: ['+10', '-30']
  },
  {
    sequence: [56, 66, 36, 46],
    answer: 16,
    actions: ['+10', '-30']
  },
  {
    sequence: [57, 67, 37, 47],
    answer: 17,
    actions: ['+10', '-30']
  },
  {
    sequence: [58, 68, 38, 48],
    answer: 18,
    actions: ['+10', '-30']
  },
  {
    sequence: [59, 69, 39, 49],
    answer: 19,
    actions: ['+10', '-30']
  },
  {
    sequence: [60, 70, 40, 50],
    answer: 20,
    actions: ['+10', '-30']
  },
  {
    sequence: [61, 71, 41, 51],
    answer: 21,
    actions: ['+10', '-30']
  },
  {
    sequence: [62, 72, 42, 52],
    answer: 22,
    actions: ['+10', '-30']
  },
  {
    sequence: [63, 73, 43, 53],
    answer: 23,
    actions: ['+10', '-30']
  },
  {
    sequence: [64, 74, 44, 54],
    answer: 24,
    actions: ['+10', '-30']
  },
  {
    sequence: [65, 75, 45, 55],
    answer: 25,
    actions: ['+10', '-30']
  },
  {
    sequence: [66, 76, 46, 56],
    answer: 26,
    actions: ['+10', '-30']
  },
  {
    sequence: [67, 77, 47, 57],
    answer: 27,
    actions: ['+10', '-30']
  },
  {
    sequence: [68, 78, 48, 58],
    answer: 28,
    actions: ['+10', '-30']
  },
  {
    sequence: [69, 79, 49, 59],
    answer: 29,
    actions: ['+10', '-30']
  },
  {
    sequence: [70, 80, 50, 60],
    answer: 30,
    actions: ['+10', '-30']
  },
  {
    sequence: [71, 81, 51, 61],
    answer: 31,
    actions: ['+10', '-30']
  },
  {
    sequence: [72, 82, 52, 62],
    answer: 32,
    actions: ['+10', '-30']
  },
  {
    sequence: [73, 83, 53, 63],
    answer: 33,
    actions: ['+10', '-30']
  },
  {
    sequence: [74, 84, 54, 64],
    answer: 34,
    actions: ['+10', '-30']
  },
  {
    sequence: [75, 85, 55, 65],
    answer: 35,
    actions: ['+10', '-30']
  },
  {
    sequence: [76, 86, 56, 66],
    answer: 36,
    actions: ['+10', '-30']
  },
  {
    sequence: [77, 87, 57, 67],
    answer: 37,
    actions: ['+10', '-30']
  },
  {
    sequence: [78, 88, 58, 68],
    answer: 38,
    actions: ['+10', '-30']
  },
  {
    sequence: [79, 89, 59, 69],
    answer: 39,
    actions: ['+10', '-30']
  },
  {
    sequence: [80, 90, 60, 70],
    answer: 40,
    actions: ['+10', '-30']
  },
  {
    sequence: [81, 91, 61, 71],
    answer: 41,
    actions: ['+10', '-30']
  },
  {
    sequence: [82, 92, 62, 72],
    answer: 42,
    actions: ['+10', '-30']
  },
  {
    sequence: [83, 93, 63, 73],
    answer: 43,
    actions: ['+10', '-30']
  },
  {
    sequence: [84, 94, 64, 74],
    answer: 44,
    actions: ['+10', '-30']
  },
  {
    sequence: [85, 95, 65, 75],
    answer: 45,
    actions: ['+10', '-30']
  },
  {
    sequence: [86, 96, 66, 76],
    answer: 46,
    actions: ['+10', '-30']
  },
  {
    sequence: [87, 97, 67, 77],
    answer: 47,
    actions: ['+10', '-30']
  },
  {
    sequence: [88, 98, 68, 78],
    answer: 48,
    actions: ['+10', '-30']
  },
  {
    sequence: [89, 99, 69, 79],
    answer: 49,
    actions: ['+10', '-30']
  },
  {
    sequence: [90, 100, 70, 80],
    answer: 50,
    actions: ['+10', '-30']
  },
  {
    sequence: [21, 41, 11, 31],
    answer: 1,
    actions: ['+20', '-30']
  },
  {
    sequence: [22, 42, 12, 32],
    answer: 2,
    actions: ['+20', '-30']
  },
  {
    sequence: [23, 43, 13, 33],
    answer: 3,
    actions: ['+20', '-30']
  },
  {
    sequence: [24, 44, 14, 34],
    answer: 4,
    actions: ['+20', '-30']
  },
  {
    sequence: [25, 45, 15, 35],
    answer: 5,
    actions: ['+20', '-30']
  },
  {
    sequence: [26, 46, 16, 36],
    answer: 6,
    actions: ['+20', '-30']
  },
  {
    sequence: [27, 47, 17, 37],
    answer: 7,
    actions: ['+20', '-30']
  },
  {
    sequence: [28, 48, 18, 38],
    answer: 8,
    actions: ['+20', '-30']
  },
  {
    sequence: [29, 49, 19, 39],
    answer: 9,
    actions: ['+20', '-30']
  },
  {
    sequence: [30, 50, 20, 40],
    answer: 10,
    actions: ['+20', '-30']
  },
  {
    sequence: [31, 51, 21, 41],
    answer: 11,
    actions: ['+20', '-30']
  },
  {
    sequence: [32, 52, 22, 42],
    answer: 12,
    actions: ['+20', '-30']
  },
  {
    sequence: [33, 53, 23, 43],
    answer: 13,
    actions: ['+20', '-30']
  },
  {
    sequence: [34, 54, 24, 44],
    answer: 14,
    actions: ['+20', '-30']
  },
  {
    sequence: [35, 55, 25, 45],
    answer: 15,
    actions: ['+20', '-30']
  },
  {
    sequence: [36, 56, 26, 46],
    answer: 16,
    actions: ['+20', '-30']
  },
  {
    sequence: [37, 57, 27, 47],
    answer: 17,
    actions: ['+20', '-30']
  },
  {
    sequence: [38, 58, 28, 48],
    answer: 18,
    actions: ['+20', '-30']
  },
  {
    sequence: [39, 59, 29, 49],
    answer: 19,
    actions: ['+20', '-30']
  },
  {
    sequence: [40, 60, 30, 50],
    answer: 20,
    actions: ['+20', '-30']
  },
  {
    sequence: [41, 61, 31, 51],
    answer: 21,
    actions: ['+20', '-30']
  },
  {
    sequence: [42, 62, 32, 52],
    answer: 22,
    actions: ['+20', '-30']
  },
  {
    sequence: [43, 63, 33, 53],
    answer: 23,
    actions: ['+20', '-30']
  },
  {
    sequence: [44, 64, 34, 54],
    answer: 24,
    actions: ['+20', '-30']
  },
  {
    sequence: [45, 65, 35, 55],
    answer: 25,
    actions: ['+20', '-30']
  },
  {
    sequence: [46, 66, 36, 56],
    answer: 26,
    actions: ['+20', '-30']
  },
  {
    sequence: [47, 67, 37, 57],
    answer: 27,
    actions: ['+20', '-30']
  },
  {
    sequence: [48, 68, 38, 58],
    answer: 28,
    actions: ['+20', '-30']
  },
  {
    sequence: [49, 69, 39, 59],
    answer: 29,
    actions: ['+20', '-30']
  },
  {
    sequence: [50, 70, 40, 60],
    answer: 30,
    actions: ['+20', '-30']
  },
  {
    sequence: [51, 71, 41, 61],
    answer: 31,
    actions: ['+20', '-30']
  },
  {
    sequence: [52, 72, 42, 62],
    answer: 32,
    actions: ['+20', '-30']
  },
  {
    sequence: [53, 73, 43, 63],
    answer: 33,
    actions: ['+20', '-30']
  },
  {
    sequence: [54, 74, 44, 64],
    answer: 34,
    actions: ['+20', '-30']
  },
  {
    sequence: [55, 75, 45, 65],
    answer: 35,
    actions: ['+20', '-30']
  },
  {
    sequence: [56, 76, 46, 66],
    answer: 36,
    actions: ['+20', '-30']
  },
  {
    sequence: [57, 77, 47, 67],
    answer: 37,
    actions: ['+20', '-30']
  },
  {
    sequence: [58, 78, 48, 68],
    answer: 38,
    actions: ['+20', '-30']
  },
  {
    sequence: [59, 79, 49, 69],
    answer: 39,
    actions: ['+20', '-30']
  },
  {
    sequence: [60, 80, 50, 70],
    answer: 40,
    actions: ['+20', '-30']
  },
  {
    sequence: [61, 81, 51, 71],
    answer: 41,
    actions: ['+20', '-30']
  },
  {
    sequence: [62, 82, 52, 72],
    answer: 42,
    actions: ['+20', '-30']
  },
  {
    sequence: [63, 83, 53, 73],
    answer: 43,
    actions: ['+20', '-30']
  },
  {
    sequence: [64, 84, 54, 74],
    answer: 44,
    actions: ['+20', '-30']
  },
  {
    sequence: [65, 85, 55, 75],
    answer: 45,
    actions: ['+20', '-30']
  },
  {
    sequence: [66, 86, 56, 76],
    answer: 46,
    actions: ['+20', '-30']
  },
  {
    sequence: [67, 87, 57, 77],
    answer: 47,
    actions: ['+20', '-30']
  },
  {
    sequence: [68, 88, 58, 78],
    answer: 48,
    actions: ['+20', '-30']
  },
  {
    sequence: [69, 89, 59, 79],
    answer: 49,
    actions: ['+20', '-30']
  },
  {
    sequence: [70, 90, 60, 80],
    answer: 50,
    actions: ['+20', '-30']
  },
  {
    sequence: [71, 91, 61, 81],
    answer: 51,
    actions: ['+20', '-30']
  },
  {
    sequence: [72, 92, 62, 82],
    answer: 52,
    actions: ['+20', '-30']
  },
  {
    sequence: [73, 93, 63, 83],
    answer: 53,
    actions: ['+20', '-30']
  },
  {
    sequence: [74, 94, 64, 84],
    answer: 54,
    actions: ['+20', '-30']
  },
  {
    sequence: [75, 95, 65, 85],
    answer: 55,
    actions: ['+20', '-30']
  },
  {
    sequence: [76, 96, 66, 86],
    answer: 56,
    actions: ['+20', '-30']
  },
  {
    sequence: [77, 97, 67, 87],
    answer: 57,
    actions: ['+20', '-30']
  },
  {
    sequence: [78, 98, 68, 88],
    answer: 58,
    actions: ['+20', '-30']
  },
  {
    sequence: [79, 99, 69, 89],
    answer: 59,
    actions: ['+20', '-30']
  },
  {
    sequence: [1, 41, 11, 51],
    answer: 21,
    actions: ['+40', '-30']
  },
  {
    sequence: [2, 42, 12, 52],
    answer: 22,
    actions: ['+40', '-30']
  },
  {
    sequence: [3, 43, 13, 53],
    answer: 23,
    actions: ['+40', '-30']
  },
  {
    sequence: [4, 44, 14, 54],
    answer: 24,
    actions: ['+40', '-30']
  },
  {
    sequence: [5, 45, 15, 55],
    answer: 25,
    actions: ['+40', '-30']
  },
  {
    sequence: [6, 46, 16, 56],
    answer: 26,
    actions: ['+40', '-30']
  },
  {
    sequence: [7, 47, 17, 57],
    answer: 27,
    actions: ['+40', '-30']
  },
  {
    sequence: [8, 48, 18, 58],
    answer: 28,
    actions: ['+40', '-30']
  },
  {
    sequence: [9, 49, 19, 59],
    answer: 29,
    actions: ['+40', '-30']
  },
  {
    sequence: [10, 50, 20, 60],
    answer: 30,
    actions: ['+40', '-30']
  },
  {
    sequence: [11, 51, 21, 61],
    answer: 31,
    actions: ['+40', '-30']
  },
  {
    sequence: [12, 52, 22, 62],
    answer: 32,
    actions: ['+40', '-30']
  },
  {
    sequence: [13, 53, 23, 63],
    answer: 33,
    actions: ['+40', '-30']
  },
  {
    sequence: [14, 54, 24, 64],
    answer: 34,
    actions: ['+40', '-30']
  },
  {
    sequence: [15, 55, 25, 65],
    answer: 35,
    actions: ['+40', '-30']
  },
  {
    sequence: [16, 56, 26, 66],
    answer: 36,
    actions: ['+40', '-30']
  },
  {
    sequence: [17, 57, 27, 67],
    answer: 37,
    actions: ['+40', '-30']
  },
  {
    sequence: [18, 58, 28, 68],
    answer: 38,
    actions: ['+40', '-30']
  },
  {
    sequence: [19, 59, 29, 69],
    answer: 39,
    actions: ['+40', '-30']
  },
  {
    sequence: [20, 60, 30, 70],
    answer: 40,
    actions: ['+40', '-30']
  },
  {
    sequence: [21, 61, 31, 71],
    answer: 41,
    actions: ['+40', '-30']
  },
  {
    sequence: [22, 62, 32, 72],
    answer: 42,
    actions: ['+40', '-30']
  },
  {
    sequence: [23, 63, 33, 73],
    answer: 43,
    actions: ['+40', '-30']
  },
  {
    sequence: [24, 64, 34, 74],
    answer: 44,
    actions: ['+40', '-30']
  },
  {
    sequence: [25, 65, 35, 75],
    answer: 45,
    actions: ['+40', '-30']
  },
  {
    sequence: [26, 66, 36, 76],
    answer: 46,
    actions: ['+40', '-30']
  },
  {
    sequence: [27, 67, 37, 77],
    answer: 47,
    actions: ['+40', '-30']
  },
  {
    sequence: [28, 68, 38, 78],
    answer: 48,
    actions: ['+40', '-30']
  },
  {
    sequence: [29, 69, 39, 79],
    answer: 49,
    actions: ['+40', '-30']
  },
  {
    sequence: [30, 70, 40, 80],
    answer: 50,
    actions: ['+40', '-30']
  },
  {
    sequence: [31, 71, 41, 81],
    answer: 51,
    actions: ['+40', '-30']
  },
  {
    sequence: [32, 72, 42, 82],
    answer: 52,
    actions: ['+40', '-30']
  },
  {
    sequence: [33, 73, 43, 83],
    answer: 53,
    actions: ['+40', '-30']
  },
  {
    sequence: [34, 74, 44, 84],
    answer: 54,
    actions: ['+40', '-30']
  },
  {
    sequence: [35, 75, 45, 85],
    answer: 55,
    actions: ['+40', '-30']
  },
  {
    sequence: [36, 76, 46, 86],
    answer: 56,
    actions: ['+40', '-30']
  },
  {
    sequence: [37, 77, 47, 87],
    answer: 57,
    actions: ['+40', '-30']
  },
  {
    sequence: [38, 78, 48, 88],
    answer: 58,
    actions: ['+40', '-30']
  },
  {
    sequence: [39, 79, 49, 89],
    answer: 59,
    actions: ['+40', '-30']
  },
  {
    sequence: [40, 80, 50, 90],
    answer: 60,
    actions: ['+40', '-30']
  },
  {
    sequence: [41, 81, 51, 91],
    answer: 61,
    actions: ['+40', '-30']
  },
  {
    sequence: [42, 82, 52, 92],
    answer: 62,
    actions: ['+40', '-30']
  },
  {
    sequence: [43, 83, 53, 93],
    answer: 63,
    actions: ['+40', '-30']
  },
  {
    sequence: [44, 84, 54, 94],
    answer: 64,
    actions: ['+40', '-30']
  },
  {
    sequence: [45, 85, 55, 95],
    answer: 65,
    actions: ['+40', '-30']
  },
  {
    sequence: [46, 86, 56, 96],
    answer: 66,
    actions: ['+40', '-30']
  },
  {
    sequence: [47, 87, 57, 97],
    answer: 67,
    actions: ['+40', '-30']
  },
  {
    sequence: [48, 88, 58, 98],
    answer: 68,
    actions: ['+40', '-30']
  },
  {
    sequence: [49, 89, 59, 99],
    answer: 69,
    actions: ['+40', '-30']
  },
  {
    sequence: [1, 51, 21, 71],
    answer: 41,
    actions: ['+50', '-30']
  },
  {
    sequence: [2, 52, 22, 72],
    answer: 42,
    actions: ['+50', '-30']
  },
  {
    sequence: [3, 53, 23, 73],
    answer: 43,
    actions: ['+50', '-30']
  },
  {
    sequence: [4, 54, 24, 74],
    answer: 44,
    actions: ['+50', '-30']
  },
  {
    sequence: [5, 55, 25, 75],
    answer: 45,
    actions: ['+50', '-30']
  },
  {
    sequence: [6, 56, 26, 76],
    answer: 46,
    actions: ['+50', '-30']
  },
  {
    sequence: [7, 57, 27, 77],
    answer: 47,
    actions: ['+50', '-30']
  },
  {
    sequence: [8, 58, 28, 78],
    answer: 48,
    actions: ['+50', '-30']
  },
  {
    sequence: [9, 59, 29, 79],
    answer: 49,
    actions: ['+50', '-30']
  },
  {
    sequence: [10, 60, 30, 80],
    answer: 50,
    actions: ['+50', '-30']
  },
  {
    sequence: [11, 61, 31, 81],
    answer: 51,
    actions: ['+50', '-30']
  },
  {
    sequence: [12, 62, 32, 82],
    answer: 52,
    actions: ['+50', '-30']
  },
  {
    sequence: [13, 63, 33, 83],
    answer: 53,
    actions: ['+50', '-30']
  },
  {
    sequence: [14, 64, 34, 84],
    answer: 54,
    actions: ['+50', '-30']
  },
  {
    sequence: [15, 65, 35, 85],
    answer: 55,
    actions: ['+50', '-30']
  },
  {
    sequence: [16, 66, 36, 86],
    answer: 56,
    actions: ['+50', '-30']
  },
  {
    sequence: [17, 67, 37, 87],
    answer: 57,
    actions: ['+50', '-30']
  },
  {
    sequence: [18, 68, 38, 88],
    answer: 58,
    actions: ['+50', '-30']
  },
  {
    sequence: [19, 69, 39, 89],
    answer: 59,
    actions: ['+50', '-30']
  },
  {
    sequence: [20, 70, 40, 90],
    answer: 60,
    actions: ['+50', '-30']
  },
  {
    sequence: [21, 71, 41, 91],
    answer: 61,
    actions: ['+50', '-30']
  },
  {
    sequence: [22, 72, 42, 92],
    answer: 62,
    actions: ['+50', '-30']
  },
  {
    sequence: [23, 73, 43, 93],
    answer: 63,
    actions: ['+50', '-30']
  },
  {
    sequence: [24, 74, 44, 94],
    answer: 64,
    actions: ['+50', '-30']
  },
  {
    sequence: [25, 75, 45, 95],
    answer: 65,
    actions: ['+50', '-30']
  },
  {
    sequence: [26, 76, 46, 96],
    answer: 66,
    actions: ['+50', '-30']
  },
  {
    sequence: [27, 77, 47, 97],
    answer: 67,
    actions: ['+50', '-30']
  },
  {
    sequence: [28, 78, 48, 98],
    answer: 68,
    actions: ['+50', '-30']
  },
  {
    sequence: [29, 79, 49, 99],
    answer: 69,
    actions: ['+50', '-30']
  },
  {
    sequence: [61, 71, 31, 41],
    answer: 1,
    actions: ['+10', '-40']
  },
  {
    sequence: [62, 72, 32, 42],
    answer: 2,
    actions: ['+10', '-40']
  },
  {
    sequence: [63, 73, 33, 43],
    answer: 3,
    actions: ['+10', '-40']
  },
  {
    sequence: [64, 74, 34, 44],
    answer: 4,
    actions: ['+10', '-40']
  },
  {
    sequence: [65, 75, 35, 45],
    answer: 5,
    actions: ['+10', '-40']
  },
  {
    sequence: [66, 76, 36, 46],
    answer: 6,
    actions: ['+10', '-40']
  },
  {
    sequence: [67, 77, 37, 47],
    answer: 7,
    actions: ['+10', '-40']
  },
  {
    sequence: [68, 78, 38, 48],
    answer: 8,
    actions: ['+10', '-40']
  },
  {
    sequence: [69, 79, 39, 49],
    answer: 9,
    actions: ['+10', '-40']
  },
  {
    sequence: [70, 80, 40, 50],
    answer: 10,
    actions: ['+10', '-40']
  },
  {
    sequence: [71, 81, 41, 51],
    answer: 11,
    actions: ['+10', '-40']
  },
  {
    sequence: [72, 82, 42, 52],
    answer: 12,
    actions: ['+10', '-40']
  },
  {
    sequence: [73, 83, 43, 53],
    answer: 13,
    actions: ['+10', '-40']
  },
  {
    sequence: [74, 84, 44, 54],
    answer: 14,
    actions: ['+10', '-40']
  },
  {
    sequence: [75, 85, 45, 55],
    answer: 15,
    actions: ['+10', '-40']
  },
  {
    sequence: [76, 86, 46, 56],
    answer: 16,
    actions: ['+10', '-40']
  },
  {
    sequence: [77, 87, 47, 57],
    answer: 17,
    actions: ['+10', '-40']
  },
  {
    sequence: [78, 88, 48, 58],
    answer: 18,
    actions: ['+10', '-40']
  },
  {
    sequence: [79, 89, 49, 59],
    answer: 19,
    actions: ['+10', '-40']
  },
  {
    sequence: [80, 90, 50, 60],
    answer: 20,
    actions: ['+10', '-40']
  },
  {
    sequence: [81, 91, 51, 61],
    answer: 21,
    actions: ['+10', '-40']
  },
  {
    sequence: [82, 92, 52, 62],
    answer: 22,
    actions: ['+10', '-40']
  },
  {
    sequence: [83, 93, 53, 63],
    answer: 23,
    actions: ['+10', '-40']
  },
  {
    sequence: [84, 94, 54, 64],
    answer: 24,
    actions: ['+10', '-40']
  },
  {
    sequence: [85, 95, 55, 65],
    answer: 25,
    actions: ['+10', '-40']
  },
  {
    sequence: [86, 96, 56, 66],
    answer: 26,
    actions: ['+10', '-40']
  },
  {
    sequence: [87, 97, 57, 67],
    answer: 27,
    actions: ['+10', '-40']
  },
  {
    sequence: [88, 98, 58, 68],
    answer: 28,
    actions: ['+10', '-40']
  },
  {
    sequence: [89, 99, 59, 69],
    answer: 29,
    actions: ['+10', '-40']
  },
  {
    sequence: [90, 100, 60, 70],
    answer: 30,
    actions: ['+10', '-40']
  },
  {
    sequence: [41, 61, 21, 41],
    answer: 1,
    actions: ['+20', '-40']
  },
  {
    sequence: [42, 62, 22, 42],
    answer: 2,
    actions: ['+20', '-40']
  },
  {
    sequence: [43, 63, 23, 43],
    answer: 3,
    actions: ['+20', '-40']
  },
  {
    sequence: [44, 64, 24, 44],
    answer: 4,
    actions: ['+20', '-40']
  },
  {
    sequence: [45, 65, 25, 45],
    answer: 5,
    actions: ['+20', '-40']
  },
  {
    sequence: [46, 66, 26, 46],
    answer: 6,
    actions: ['+20', '-40']
  },
  {
    sequence: [47, 67, 27, 47],
    answer: 7,
    actions: ['+20', '-40']
  },
  {
    sequence: [48, 68, 28, 48],
    answer: 8,
    actions: ['+20', '-40']
  },
  {
    sequence: [49, 69, 29, 49],
    answer: 9,
    actions: ['+20', '-40']
  },
  {
    sequence: [50, 70, 30, 50],
    answer: 10,
    actions: ['+20', '-40']
  },
  {
    sequence: [51, 71, 31, 51],
    answer: 11,
    actions: ['+20', '-40']
  },
  {
    sequence: [52, 72, 32, 52],
    answer: 12,
    actions: ['+20', '-40']
  },
  {
    sequence: [53, 73, 33, 53],
    answer: 13,
    actions: ['+20', '-40']
  },
  {
    sequence: [54, 74, 34, 54],
    answer: 14,
    actions: ['+20', '-40']
  },
  {
    sequence: [55, 75, 35, 55],
    answer: 15,
    actions: ['+20', '-40']
  },
  {
    sequence: [56, 76, 36, 56],
    answer: 16,
    actions: ['+20', '-40']
  },
  {
    sequence: [57, 77, 37, 57],
    answer: 17,
    actions: ['+20', '-40']
  },
  {
    sequence: [58, 78, 38, 58],
    answer: 18,
    actions: ['+20', '-40']
  },
  {
    sequence: [59, 79, 39, 59],
    answer: 19,
    actions: ['+20', '-40']
  },
  {
    sequence: [60, 80, 40, 60],
    answer: 20,
    actions: ['+20', '-40']
  },
  {
    sequence: [61, 81, 41, 61],
    answer: 21,
    actions: ['+20', '-40']
  },
  {
    sequence: [62, 82, 42, 62],
    answer: 22,
    actions: ['+20', '-40']
  },
  {
    sequence: [63, 83, 43, 63],
    answer: 23,
    actions: ['+20', '-40']
  },
  {
    sequence: [64, 84, 44, 64],
    answer: 24,
    actions: ['+20', '-40']
  },
  {
    sequence: [65, 85, 45, 65],
    answer: 25,
    actions: ['+20', '-40']
  },
  {
    sequence: [66, 86, 46, 66],
    answer: 26,
    actions: ['+20', '-40']
  },
  {
    sequence: [67, 87, 47, 67],
    answer: 27,
    actions: ['+20', '-40']
  },
  {
    sequence: [68, 88, 48, 68],
    answer: 28,
    actions: ['+20', '-40']
  },
  {
    sequence: [69, 89, 49, 69],
    answer: 29,
    actions: ['+20', '-40']
  },
  {
    sequence: [70, 90, 50, 70],
    answer: 30,
    actions: ['+20', '-40']
  },
  {
    sequence: [71, 91, 51, 71],
    answer: 31,
    actions: ['+20', '-40']
  },
  {
    sequence: [72, 92, 52, 72],
    answer: 32,
    actions: ['+20', '-40']
  },
  {
    sequence: [73, 93, 53, 73],
    answer: 33,
    actions: ['+20', '-40']
  },
  {
    sequence: [74, 94, 54, 74],
    answer: 34,
    actions: ['+20', '-40']
  },
  {
    sequence: [75, 95, 55, 75],
    answer: 35,
    actions: ['+20', '-40']
  },
  {
    sequence: [76, 96, 56, 76],
    answer: 36,
    actions: ['+20', '-40']
  },
  {
    sequence: [77, 97, 57, 77],
    answer: 37,
    actions: ['+20', '-40']
  },
  {
    sequence: [78, 98, 58, 78],
    answer: 38,
    actions: ['+20', '-40']
  },
  {
    sequence: [79, 99, 59, 79],
    answer: 39,
    actions: ['+20', '-40']
  },
  {
    sequence: [21, 51, 11, 41],
    answer: 1,
    actions: ['+30', '-40']
  },
  {
    sequence: [22, 52, 12, 42],
    answer: 2,
    actions: ['+30', '-40']
  },
  {
    sequence: [23, 53, 13, 43],
    answer: 3,
    actions: ['+30', '-40']
  },
  {
    sequence: [24, 54, 14, 44],
    answer: 4,
    actions: ['+30', '-40']
  },
  {
    sequence: [25, 55, 15, 45],
    answer: 5,
    actions: ['+30', '-40']
  },
  {
    sequence: [26, 56, 16, 46],
    answer: 6,
    actions: ['+30', '-40']
  },
  {
    sequence: [27, 57, 17, 47],
    answer: 7,
    actions: ['+30', '-40']
  },
  {
    sequence: [28, 58, 18, 48],
    answer: 8,
    actions: ['+30', '-40']
  },
  {
    sequence: [29, 59, 19, 49],
    answer: 9,
    actions: ['+30', '-40']
  },
  {
    sequence: [30, 60, 20, 50],
    answer: 10,
    actions: ['+30', '-40']
  },
  {
    sequence: [31, 61, 21, 51],
    answer: 11,
    actions: ['+30', '-40']
  },
  {
    sequence: [32, 62, 22, 52],
    answer: 12,
    actions: ['+30', '-40']
  },
  {
    sequence: [33, 63, 23, 53],
    answer: 13,
    actions: ['+30', '-40']
  },
  {
    sequence: [34, 64, 24, 54],
    answer: 14,
    actions: ['+30', '-40']
  },
  {
    sequence: [35, 65, 25, 55],
    answer: 15,
    actions: ['+30', '-40']
  },
  {
    sequence: [36, 66, 26, 56],
    answer: 16,
    actions: ['+30', '-40']
  },
  {
    sequence: [37, 67, 27, 57],
    answer: 17,
    actions: ['+30', '-40']
  },
  {
    sequence: [38, 68, 28, 58],
    answer: 18,
    actions: ['+30', '-40']
  },
  {
    sequence: [39, 69, 29, 59],
    answer: 19,
    actions: ['+30', '-40']
  },
  {
    sequence: [40, 70, 30, 60],
    answer: 20,
    actions: ['+30', '-40']
  },
  {
    sequence: [41, 71, 31, 61],
    answer: 21,
    actions: ['+30', '-40']
  },
  {
    sequence: [42, 72, 32, 62],
    answer: 22,
    actions: ['+30', '-40']
  },
  {
    sequence: [43, 73, 33, 63],
    answer: 23,
    actions: ['+30', '-40']
  },
  {
    sequence: [44, 74, 34, 64],
    answer: 24,
    actions: ['+30', '-40']
  },
  {
    sequence: [45, 75, 35, 65],
    answer: 25,
    actions: ['+30', '-40']
  },
  {
    sequence: [46, 76, 36, 66],
    answer: 26,
    actions: ['+30', '-40']
  },
  {
    sequence: [47, 77, 37, 67],
    answer: 27,
    actions: ['+30', '-40']
  },
  {
    sequence: [48, 78, 38, 68],
    answer: 28,
    actions: ['+30', '-40']
  },
  {
    sequence: [49, 79, 39, 69],
    answer: 29,
    actions: ['+30', '-40']
  },
  {
    sequence: [50, 80, 40, 70],
    answer: 30,
    actions: ['+30', '-40']
  },
  {
    sequence: [51, 81, 41, 71],
    answer: 31,
    actions: ['+30', '-40']
  },
  {
    sequence: [52, 82, 42, 72],
    answer: 32,
    actions: ['+30', '-40']
  },
  {
    sequence: [53, 83, 43, 73],
    answer: 33,
    actions: ['+30', '-40']
  },
  {
    sequence: [54, 84, 44, 74],
    answer: 34,
    actions: ['+30', '-40']
  },
  {
    sequence: [55, 85, 45, 75],
    answer: 35,
    actions: ['+30', '-40']
  },
  {
    sequence: [56, 86, 46, 76],
    answer: 36,
    actions: ['+30', '-40']
  },
  {
    sequence: [57, 87, 47, 77],
    answer: 37,
    actions: ['+30', '-40']
  },
  {
    sequence: [58, 88, 48, 78],
    answer: 38,
    actions: ['+30', '-40']
  },
  {
    sequence: [59, 89, 49, 79],
    answer: 39,
    actions: ['+30', '-40']
  },
  {
    sequence: [60, 90, 50, 80],
    answer: 40,
    actions: ['+30', '-40']
  },
  {
    sequence: [61, 91, 51, 81],
    answer: 41,
    actions: ['+30', '-40']
  },
  {
    sequence: [62, 92, 52, 82],
    answer: 42,
    actions: ['+30', '-40']
  },
  {
    sequence: [63, 93, 53, 83],
    answer: 43,
    actions: ['+30', '-40']
  },
  {
    sequence: [64, 94, 54, 84],
    answer: 44,
    actions: ['+30', '-40']
  },
  {
    sequence: [65, 95, 55, 85],
    answer: 45,
    actions: ['+30', '-40']
  },
  {
    sequence: [66, 96, 56, 86],
    answer: 46,
    actions: ['+30', '-40']
  },
  {
    sequence: [67, 97, 57, 87],
    answer: 47,
    actions: ['+30', '-40']
  },
  {
    sequence: [68, 98, 58, 88],
    answer: 48,
    actions: ['+30', '-40']
  },
  {
    sequence: [69, 99, 59, 89],
    answer: 49,
    actions: ['+30', '-40']
  },
  {
    sequence: [1, 51, 11, 61],
    answer: 21,
    actions: ['+50', '-40']
  },
  {
    sequence: [2, 52, 12, 62],
    answer: 22,
    actions: ['+50', '-40']
  },
  {
    sequence: [3, 53, 13, 63],
    answer: 23,
    actions: ['+50', '-40']
  },
  {
    sequence: [4, 54, 14, 64],
    answer: 24,
    actions: ['+50', '-40']
  },
  {
    sequence: [5, 55, 15, 65],
    answer: 25,
    actions: ['+50', '-40']
  },
  {
    sequence: [6, 56, 16, 66],
    answer: 26,
    actions: ['+50', '-40']
  },
  {
    sequence: [7, 57, 17, 67],
    answer: 27,
    actions: ['+50', '-40']
  },
  {
    sequence: [8, 58, 18, 68],
    answer: 28,
    actions: ['+50', '-40']
  },
  {
    sequence: [9, 59, 19, 69],
    answer: 29,
    actions: ['+50', '-40']
  },
  {
    sequence: [10, 60, 20, 70],
    answer: 30,
    actions: ['+50', '-40']
  },
  {
    sequence: [11, 61, 21, 71],
    answer: 31,
    actions: ['+50', '-40']
  },
  {
    sequence: [12, 62, 22, 72],
    answer: 32,
    actions: ['+50', '-40']
  },
  {
    sequence: [13, 63, 23, 73],
    answer: 33,
    actions: ['+50', '-40']
  },
  {
    sequence: [14, 64, 24, 74],
    answer: 34,
    actions: ['+50', '-40']
  },
  {
    sequence: [15, 65, 25, 75],
    answer: 35,
    actions: ['+50', '-40']
  },
  {
    sequence: [16, 66, 26, 76],
    answer: 36,
    actions: ['+50', '-40']
  },
  {
    sequence: [17, 67, 27, 77],
    answer: 37,
    actions: ['+50', '-40']
  },
  {
    sequence: [18, 68, 28, 78],
    answer: 38,
    actions: ['+50', '-40']
  },
  {
    sequence: [19, 69, 29, 79],
    answer: 39,
    actions: ['+50', '-40']
  },
  {
    sequence: [20, 70, 30, 80],
    answer: 40,
    actions: ['+50', '-40']
  },
  {
    sequence: [21, 71, 31, 81],
    answer: 41,
    actions: ['+50', '-40']
  },
  {
    sequence: [22, 72, 32, 82],
    answer: 42,
    actions: ['+50', '-40']
  },
  {
    sequence: [23, 73, 33, 83],
    answer: 43,
    actions: ['+50', '-40']
  },
  {
    sequence: [24, 74, 34, 84],
    answer: 44,
    actions: ['+50', '-40']
  },
  {
    sequence: [25, 75, 35, 85],
    answer: 45,
    actions: ['+50', '-40']
  },
  {
    sequence: [26, 76, 36, 86],
    answer: 46,
    actions: ['+50', '-40']
  },
  {
    sequence: [27, 77, 37, 87],
    answer: 47,
    actions: ['+50', '-40']
  },
  {
    sequence: [28, 78, 38, 88],
    answer: 48,
    actions: ['+50', '-40']
  },
  {
    sequence: [29, 79, 39, 89],
    answer: 49,
    actions: ['+50', '-40']
  },
  {
    sequence: [30, 80, 40, 90],
    answer: 50,
    actions: ['+50', '-40']
  },
  {
    sequence: [31, 81, 41, 91],
    answer: 51,
    actions: ['+50', '-40']
  },
  {
    sequence: [32, 82, 42, 92],
    answer: 52,
    actions: ['+50', '-40']
  },
  {
    sequence: [33, 83, 43, 93],
    answer: 53,
    actions: ['+50', '-40']
  },
  {
    sequence: [34, 84, 44, 94],
    answer: 54,
    actions: ['+50', '-40']
  },
  {
    sequence: [35, 85, 45, 95],
    answer: 55,
    actions: ['+50', '-40']
  },
  {
    sequence: [36, 86, 46, 96],
    answer: 56,
    actions: ['+50', '-40']
  },
  {
    sequence: [37, 87, 47, 97],
    answer: 57,
    actions: ['+50', '-40']
  },
  {
    sequence: [38, 88, 48, 98],
    answer: 58,
    actions: ['+50', '-40']
  },
  {
    sequence: [39, 89, 49, 99],
    answer: 59,
    actions: ['+50', '-40']
  },
  {
    sequence: [81, 91, 41, 51],
    answer: 1,
    actions: ['+10', '-50']
  },
  {
    sequence: [82, 92, 42, 52],
    answer: 2,
    actions: ['+10', '-50']
  },
  {
    sequence: [83, 93, 43, 53],
    answer: 3,
    actions: ['+10', '-50']
  },
  {
    sequence: [84, 94, 44, 54],
    answer: 4,
    actions: ['+10', '-50']
  },
  {
    sequence: [85, 95, 45, 55],
    answer: 5,
    actions: ['+10', '-50']
  },
  {
    sequence: [86, 96, 46, 56],
    answer: 6,
    actions: ['+10', '-50']
  },
  {
    sequence: [87, 97, 47, 57],
    answer: 7,
    actions: ['+10', '-50']
  },
  {
    sequence: [88, 98, 48, 58],
    answer: 8,
    actions: ['+10', '-50']
  },
  {
    sequence: [89, 99, 49, 59],
    answer: 9,
    actions: ['+10', '-50']
  },
  {
    sequence: [90, 100, 50, 60],
    answer: 10,
    actions: ['+10', '-50']
  },
  {
    sequence: [41, 71, 21, 51],
    answer: 1,
    actions: ['+30', '-50']
  },
  {
    sequence: [42, 72, 22, 52],
    answer: 2,
    actions: ['+30', '-50']
  },
  {
    sequence: [43, 73, 23, 53],
    answer: 3,
    actions: ['+30', '-50']
  },
  {
    sequence: [44, 74, 24, 54],
    answer: 4,
    actions: ['+30', '-50']
  },
  {
    sequence: [45, 75, 25, 55],
    answer: 5,
    actions: ['+30', '-50']
  },
  {
    sequence: [46, 76, 26, 56],
    answer: 6,
    actions: ['+30', '-50']
  },
  {
    sequence: [47, 77, 27, 57],
    answer: 7,
    actions: ['+30', '-50']
  },
  {
    sequence: [48, 78, 28, 58],
    answer: 8,
    actions: ['+30', '-50']
  },
  {
    sequence: [49, 79, 29, 59],
    answer: 9,
    actions: ['+30', '-50']
  },
  {
    sequence: [50, 80, 30, 60],
    answer: 10,
    actions: ['+30', '-50']
  },
  {
    sequence: [51, 81, 31, 61],
    answer: 11,
    actions: ['+30', '-50']
  },
  {
    sequence: [52, 82, 32, 62],
    answer: 12,
    actions: ['+30', '-50']
  },
  {
    sequence: [53, 83, 33, 63],
    answer: 13,
    actions: ['+30', '-50']
  },
  {
    sequence: [54, 84, 34, 64],
    answer: 14,
    actions: ['+30', '-50']
  },
  {
    sequence: [55, 85, 35, 65],
    answer: 15,
    actions: ['+30', '-50']
  },
  {
    sequence: [56, 86, 36, 66],
    answer: 16,
    actions: ['+30', '-50']
  },
  {
    sequence: [57, 87, 37, 67],
    answer: 17,
    actions: ['+30', '-50']
  },
  {
    sequence: [58, 88, 38, 68],
    answer: 18,
    actions: ['+30', '-50']
  },
  {
    sequence: [59, 89, 39, 69],
    answer: 19,
    actions: ['+30', '-50']
  },
  {
    sequence: [60, 90, 40, 70],
    answer: 20,
    actions: ['+30', '-50']
  },
  {
    sequence: [61, 91, 41, 71],
    answer: 21,
    actions: ['+30', '-50']
  },
  {
    sequence: [62, 92, 42, 72],
    answer: 22,
    actions: ['+30', '-50']
  },
  {
    sequence: [63, 93, 43, 73],
    answer: 23,
    actions: ['+30', '-50']
  },
  {
    sequence: [64, 94, 44, 74],
    answer: 24,
    actions: ['+30', '-50']
  },
  {
    sequence: [65, 95, 45, 75],
    answer: 25,
    actions: ['+30', '-50']
  },
  {
    sequence: [66, 96, 46, 76],
    answer: 26,
    actions: ['+30', '-50']
  },
  {
    sequence: [67, 97, 47, 77],
    answer: 27,
    actions: ['+30', '-50']
  },
  {
    sequence: [68, 98, 48, 78],
    answer: 28,
    actions: ['+30', '-50']
  },
  {
    sequence: [69, 99, 49, 79],
    answer: 29,
    actions: ['+30', '-50']
  },
  {
    sequence: [21, 61, 11, 51],
    answer: 1,
    actions: ['+40', '-50']
  },
  {
    sequence: [22, 62, 12, 52],
    answer: 2,
    actions: ['+40', '-50']
  },
  {
    sequence: [23, 63, 13, 53],
    answer: 3,
    actions: ['+40', '-50']
  },
  {
    sequence: [24, 64, 14, 54],
    answer: 4,
    actions: ['+40', '-50']
  },
  {
    sequence: [25, 65, 15, 55],
    answer: 5,
    actions: ['+40', '-50']
  },
  {
    sequence: [26, 66, 16, 56],
    answer: 6,
    actions: ['+40', '-50']
  },
  {
    sequence: [27, 67, 17, 57],
    answer: 7,
    actions: ['+40', '-50']
  },
  {
    sequence: [28, 68, 18, 58],
    answer: 8,
    actions: ['+40', '-50']
  },
  {
    sequence: [29, 69, 19, 59],
    answer: 9,
    actions: ['+40', '-50']
  },
  {
    sequence: [30, 70, 20, 60],
    answer: 10,
    actions: ['+40', '-50']
  },
  {
    sequence: [31, 71, 21, 61],
    answer: 11,
    actions: ['+40', '-50']
  },
  {
    sequence: [32, 72, 22, 62],
    answer: 12,
    actions: ['+40', '-50']
  },
  {
    sequence: [33, 73, 23, 63],
    answer: 13,
    actions: ['+40', '-50']
  },
  {
    sequence: [34, 74, 24, 64],
    answer: 14,
    actions: ['+40', '-50']
  },
  {
    sequence: [35, 75, 25, 65],
    answer: 15,
    actions: ['+40', '-50']
  },
  {
    sequence: [36, 76, 26, 66],
    answer: 16,
    actions: ['+40', '-50']
  },
  {
    sequence: [37, 77, 27, 67],
    answer: 17,
    actions: ['+40', '-50']
  },
  {
    sequence: [38, 78, 28, 68],
    answer: 18,
    actions: ['+40', '-50']
  },
  {
    sequence: [39, 79, 29, 69],
    answer: 19,
    actions: ['+40', '-50']
  },
  {
    sequence: [40, 80, 30, 70],
    answer: 20,
    actions: ['+40', '-50']
  },
  {
    sequence: [41, 81, 31, 71],
    answer: 21,
    actions: ['+40', '-50']
  },
  {
    sequence: [42, 82, 32, 72],
    answer: 22,
    actions: ['+40', '-50']
  },
  {
    sequence: [43, 83, 33, 73],
    answer: 23,
    actions: ['+40', '-50']
  },
  {
    sequence: [44, 84, 34, 74],
    answer: 24,
    actions: ['+40', '-50']
  },
  {
    sequence: [45, 85, 35, 75],
    answer: 25,
    actions: ['+40', '-50']
  },
  {
    sequence: [46, 86, 36, 76],
    answer: 26,
    actions: ['+40', '-50']
  },
  {
    sequence: [47, 87, 37, 77],
    answer: 27,
    actions: ['+40', '-50']
  },
  {
    sequence: [48, 88, 38, 78],
    answer: 28,
    actions: ['+40', '-50']
  },
  {
    sequence: [49, 89, 39, 79],
    answer: 29,
    actions: ['+40', '-50']
  },
  {
    sequence: [50, 90, 40, 80],
    answer: 30,
    actions: ['+40', '-50']
  },
  {
    sequence: [51, 91, 41, 81],
    answer: 31,
    actions: ['+40', '-50']
  },
  {
    sequence: [52, 92, 42, 82],
    answer: 32,
    actions: ['+40', '-50']
  },
  {
    sequence: [53, 93, 43, 83],
    answer: 33,
    actions: ['+40', '-50']
  },
  {
    sequence: [54, 94, 44, 84],
    answer: 34,
    actions: ['+40', '-50']
  },
  {
    sequence: [55, 95, 45, 85],
    answer: 35,
    actions: ['+40', '-50']
  },
  {
    sequence: [56, 96, 46, 86],
    answer: 36,
    actions: ['+40', '-50']
  },
  {
    sequence: [57, 97, 47, 87],
    answer: 37,
    actions: ['+40', '-50']
  },
  {
    sequence: [58, 98, 48, 88],
    answer: 38,
    actions: ['+40', '-50']
  },
  {
    sequence: [59, 99, 49, 89],
    answer: 39,
    actions: ['+40', '-50']
  },
  {
    sequence: [1, 11, 22, 32],
    answer: 43,
    actions: ['+10', '+11']
  },
  {
    sequence: [2, 12, 23, 33],
    answer: 44,
    actions: ['+10', '+11']
  },
  {
    sequence: [3, 13, 24, 34],
    answer: 45,
    actions: ['+10', '+11']
  },
  {
    sequence: [4, 14, 25, 35],
    answer: 46,
    actions: ['+10', '+11']
  },
  {
    sequence: [5, 15, 26, 36],
    answer: 47,
    actions: ['+10', '+11']
  },
  {
    sequence: [6, 16, 27, 37],
    answer: 48,
    actions: ['+10', '+11']
  },
  {
    sequence: [7, 17, 28, 38],
    answer: 49,
    actions: ['+10', '+11']
  },
  {
    sequence: [8, 18, 29, 39],
    answer: 50,
    actions: ['+10', '+11']
  },
  {
    sequence: [9, 19, 30, 40],
    answer: 51,
    actions: ['+10', '+11']
  },
  {
    sequence: [10, 20, 31, 41],
    answer: 52,
    actions: ['+10', '+11']
  },
  {
    sequence: [11, 21, 32, 42],
    answer: 53,
    actions: ['+10', '+11']
  },
  {
    sequence: [12, 22, 33, 43],
    answer: 54,
    actions: ['+10', '+11']
  },
  {
    sequence: [13, 23, 34, 44],
    answer: 55,
    actions: ['+10', '+11']
  },
  {
    sequence: [14, 24, 35, 45],
    answer: 56,
    actions: ['+10', '+11']
  },
  {
    sequence: [15, 25, 36, 46],
    answer: 57,
    actions: ['+10', '+11']
  },
  {
    sequence: [16, 26, 37, 47],
    answer: 58,
    actions: ['+10', '+11']
  },
  {
    sequence: [17, 27, 38, 48],
    answer: 59,
    actions: ['+10', '+11']
  },
  {
    sequence: [18, 28, 39, 49],
    answer: 60,
    actions: ['+10', '+11']
  },
  {
    sequence: [19, 29, 40, 50],
    answer: 61,
    actions: ['+10', '+11']
  },
  {
    sequence: [20, 30, 41, 51],
    answer: 62,
    actions: ['+10', '+11']
  },
  {
    sequence: [21, 31, 42, 52],
    answer: 63,
    actions: ['+10', '+11']
  },
  {
    sequence: [22, 32, 43, 53],
    answer: 64,
    actions: ['+10', '+11']
  },
  {
    sequence: [23, 33, 44, 54],
    answer: 65,
    actions: ['+10', '+11']
  },
  {
    sequence: [24, 34, 45, 55],
    answer: 66,
    actions: ['+10', '+11']
  },
  {
    sequence: [25, 35, 46, 56],
    answer: 67,
    actions: ['+10', '+11']
  },
  {
    sequence: [26, 36, 47, 57],
    answer: 68,
    actions: ['+10', '+11']
  },
  {
    sequence: [27, 37, 48, 58],
    answer: 69,
    actions: ['+10', '+11']
  },
  {
    sequence: [28, 38, 49, 59],
    answer: 70,
    actions: ['+10', '+11']
  },
  {
    sequence: [29, 39, 50, 60],
    answer: 71,
    actions: ['+10', '+11']
  },
  {
    sequence: [30, 40, 51, 61],
    answer: 72,
    actions: ['+10', '+11']
  },
  {
    sequence: [31, 41, 52, 62],
    answer: 73,
    actions: ['+10', '+11']
  },
  {
    sequence: [32, 42, 53, 63],
    answer: 74,
    actions: ['+10', '+11']
  },
  {
    sequence: [33, 43, 54, 64],
    answer: 75,
    actions: ['+10', '+11']
  },
  {
    sequence: [34, 44, 55, 65],
    answer: 76,
    actions: ['+10', '+11']
  },
  {
    sequence: [35, 45, 56, 66],
    answer: 77,
    actions: ['+10', '+11']
  },
  {
    sequence: [36, 46, 57, 67],
    answer: 78,
    actions: ['+10', '+11']
  },
  {
    sequence: [37, 47, 58, 68],
    answer: 79,
    actions: ['+10', '+11']
  },
  {
    sequence: [38, 48, 59, 69],
    answer: 80,
    actions: ['+10', '+11']
  },
  {
    sequence: [39, 49, 60, 70],
    answer: 81,
    actions: ['+10', '+11']
  },
  {
    sequence: [40, 50, 61, 71],
    answer: 82,
    actions: ['+10', '+11']
  },
  {
    sequence: [41, 51, 62, 72],
    answer: 83,
    actions: ['+10', '+11']
  },
  {
    sequence: [42, 52, 63, 73],
    answer: 84,
    actions: ['+10', '+11']
  },
  {
    sequence: [43, 53, 64, 74],
    answer: 85,
    actions: ['+10', '+11']
  },
  {
    sequence: [44, 54, 65, 75],
    answer: 86,
    actions: ['+10', '+11']
  },
  {
    sequence: [45, 55, 66, 76],
    answer: 87,
    actions: ['+10', '+11']
  },
  {
    sequence: [46, 56, 67, 77],
    answer: 88,
    actions: ['+10', '+11']
  },
  {
    sequence: [47, 57, 68, 78],
    answer: 89,
    actions: ['+10', '+11']
  },
  {
    sequence: [48, 58, 69, 79],
    answer: 90,
    actions: ['+10', '+11']
  },
  {
    sequence: [49, 59, 70, 80],
    answer: 91,
    actions: ['+10', '+11']
  },
  {
    sequence: [50, 60, 71, 81],
    answer: 92,
    actions: ['+10', '+11']
  },
  {
    sequence: [51, 61, 72, 82],
    answer: 93,
    actions: ['+10', '+11']
  },
  {
    sequence: [52, 62, 73, 83],
    answer: 94,
    actions: ['+10', '+11']
  },
  {
    sequence: [53, 63, 74, 84],
    answer: 95,
    actions: ['+10', '+11']
  },
  {
    sequence: [54, 64, 75, 85],
    answer: 96,
    actions: ['+10', '+11']
  },
  {
    sequence: [55, 65, 76, 86],
    answer: 97,
    actions: ['+10', '+11']
  },
  {
    sequence: [56, 66, 77, 87],
    answer: 98,
    actions: ['+10', '+11']
  },
  {
    sequence: [57, 67, 78, 88],
    answer: 99,
    actions: ['+10', '+11']
  },
  {
    sequence: [1, 21, 32, 52],
    answer: 63,
    actions: ['+20', '+11']
  },
  {
    sequence: [2, 22, 33, 53],
    answer: 64,
    actions: ['+20', '+11']
  },
  {
    sequence: [3, 23, 34, 54],
    answer: 65,
    actions: ['+20', '+11']
  },
  {
    sequence: [4, 24, 35, 55],
    answer: 66,
    actions: ['+20', '+11']
  },
  {
    sequence: [5, 25, 36, 56],
    answer: 67,
    actions: ['+20', '+11']
  },
  {
    sequence: [6, 26, 37, 57],
    answer: 68,
    actions: ['+20', '+11']
  },
  {
    sequence: [7, 27, 38, 58],
    answer: 69,
    actions: ['+20', '+11']
  },
  {
    sequence: [8, 28, 39, 59],
    answer: 70,
    actions: ['+20', '+11']
  },
  {
    sequence: [9, 29, 40, 60],
    answer: 71,
    actions: ['+20', '+11']
  },
  {
    sequence: [10, 30, 41, 61],
    answer: 72,
    actions: ['+20', '+11']
  },
  {
    sequence: [11, 31, 42, 62],
    answer: 73,
    actions: ['+20', '+11']
  },
  {
    sequence: [12, 32, 43, 63],
    answer: 74,
    actions: ['+20', '+11']
  },
  {
    sequence: [13, 33, 44, 64],
    answer: 75,
    actions: ['+20', '+11']
  },
  {
    sequence: [14, 34, 45, 65],
    answer: 76,
    actions: ['+20', '+11']
  },
  {
    sequence: [15, 35, 46, 66],
    answer: 77,
    actions: ['+20', '+11']
  },
  {
    sequence: [16, 36, 47, 67],
    answer: 78,
    actions: ['+20', '+11']
  },
  {
    sequence: [17, 37, 48, 68],
    answer: 79,
    actions: ['+20', '+11']
  },
  {
    sequence: [18, 38, 49, 69],
    answer: 80,
    actions: ['+20', '+11']
  },
  {
    sequence: [19, 39, 50, 70],
    answer: 81,
    actions: ['+20', '+11']
  },
  {
    sequence: [20, 40, 51, 71],
    answer: 82,
    actions: ['+20', '+11']
  },
  {
    sequence: [21, 41, 52, 72],
    answer: 83,
    actions: ['+20', '+11']
  },
  {
    sequence: [22, 42, 53, 73],
    answer: 84,
    actions: ['+20', '+11']
  },
  {
    sequence: [23, 43, 54, 74],
    answer: 85,
    actions: ['+20', '+11']
  },
  {
    sequence: [24, 44, 55, 75],
    answer: 86,
    actions: ['+20', '+11']
  },
  {
    sequence: [25, 45, 56, 76],
    answer: 87,
    actions: ['+20', '+11']
  },
  {
    sequence: [26, 46, 57, 77],
    answer: 88,
    actions: ['+20', '+11']
  },
  {
    sequence: [27, 47, 58, 78],
    answer: 89,
    actions: ['+20', '+11']
  },
  {
    sequence: [28, 48, 59, 79],
    answer: 90,
    actions: ['+20', '+11']
  },
  {
    sequence: [29, 49, 60, 80],
    answer: 91,
    actions: ['+20', '+11']
  },
  {
    sequence: [30, 50, 61, 81],
    answer: 92,
    actions: ['+20', '+11']
  },
  {
    sequence: [31, 51, 62, 82],
    answer: 93,
    actions: ['+20', '+11']
  },
  {
    sequence: [32, 52, 63, 83],
    answer: 94,
    actions: ['+20', '+11']
  },
  {
    sequence: [33, 53, 64, 84],
    answer: 95,
    actions: ['+20', '+11']
  },
  {
    sequence: [34, 54, 65, 85],
    answer: 96,
    actions: ['+20', '+11']
  },
  {
    sequence: [35, 55, 66, 86],
    answer: 97,
    actions: ['+20', '+11']
  },
  {
    sequence: [36, 56, 67, 87],
    answer: 98,
    actions: ['+20', '+11']
  },
  {
    sequence: [37, 57, 68, 88],
    answer: 99,
    actions: ['+20', '+11']
  },
  {
    sequence: [38, 58, 69, 89],
    answer: 100,
    actions: ['+20', '+11']
  },
  {
    sequence: [1, 31, 42, 72],
    answer: 83,
    actions: ['+30', '+11']
  },
  {
    sequence: [2, 32, 43, 73],
    answer: 84,
    actions: ['+30', '+11']
  },
  {
    sequence: [3, 33, 44, 74],
    answer: 85,
    actions: ['+30', '+11']
  },
  {
    sequence: [4, 34, 45, 75],
    answer: 86,
    actions: ['+30', '+11']
  },
  {
    sequence: [5, 35, 46, 76],
    answer: 87,
    actions: ['+30', '+11']
  },
  {
    sequence: [6, 36, 47, 77],
    answer: 88,
    actions: ['+30', '+11']
  },
  {
    sequence: [7, 37, 48, 78],
    answer: 89,
    actions: ['+30', '+11']
  },
  {
    sequence: [8, 38, 49, 79],
    answer: 90,
    actions: ['+30', '+11']
  },
  {
    sequence: [9, 39, 50, 80],
    answer: 91,
    actions: ['+30', '+11']
  },
  {
    sequence: [10, 40, 51, 81],
    answer: 92,
    actions: ['+30', '+11']
  },
  {
    sequence: [11, 41, 52, 82],
    answer: 93,
    actions: ['+30', '+11']
  },
  {
    sequence: [12, 42, 53, 83],
    answer: 94,
    actions: ['+30', '+11']
  },
  {
    sequence: [13, 43, 54, 84],
    answer: 95,
    actions: ['+30', '+11']
  },
  {
    sequence: [14, 44, 55, 85],
    answer: 96,
    actions: ['+30', '+11']
  },
  {
    sequence: [15, 45, 56, 86],
    answer: 97,
    actions: ['+30', '+11']
  },
  {
    sequence: [16, 46, 57, 87],
    answer: 98,
    actions: ['+30', '+11']
  },
  {
    sequence: [17, 47, 58, 88],
    answer: 99,
    actions: ['+30', '+11']
  },
  {
    sequence: [3, 13, 2, 12],
    answer: 1,
    actions: ['+10', '-11']
  },
  {
    sequence: [4, 14, 3, 13],
    answer: 2,
    actions: ['+10', '-11']
  },
  {
    sequence: [5, 15, 4, 14],
    answer: 3,
    actions: ['+10', '-11']
  },
  {
    sequence: [6, 16, 5, 15],
    answer: 4,
    actions: ['+10', '-11']
  },
  {
    sequence: [7, 17, 6, 16],
    answer: 5,
    actions: ['+10', '-11']
  },
  {
    sequence: [8, 18, 7, 17],
    answer: 6,
    actions: ['+10', '-11']
  },
  {
    sequence: [9, 19, 8, 18],
    answer: 7,
    actions: ['+10', '-11']
  },
  {
    sequence: [10, 20, 9, 19],
    answer: 8,
    actions: ['+10', '-11']
  },
  {
    sequence: [11, 21, 10, 20],
    answer: 9,
    actions: ['+10', '-11']
  },
  {
    sequence: [12, 22, 11, 21],
    answer: 10,
    actions: ['+10', '-11']
  },
  {
    sequence: [13, 23, 12, 22],
    answer: 11,
    actions: ['+10', '-11']
  },
  {
    sequence: [14, 24, 13, 23],
    answer: 12,
    actions: ['+10', '-11']
  },
  {
    sequence: [15, 25, 14, 24],
    answer: 13,
    actions: ['+10', '-11']
  },
  {
    sequence: [16, 26, 15, 25],
    answer: 14,
    actions: ['+10', '-11']
  },
  {
    sequence: [17, 27, 16, 26],
    answer: 15,
    actions: ['+10', '-11']
  },
  {
    sequence: [18, 28, 17, 27],
    answer: 16,
    actions: ['+10', '-11']
  },
  {
    sequence: [19, 29, 18, 28],
    answer: 17,
    actions: ['+10', '-11']
  },
  {
    sequence: [20, 30, 19, 29],
    answer: 18,
    actions: ['+10', '-11']
  },
  {
    sequence: [21, 31, 20, 30],
    answer: 19,
    actions: ['+10', '-11']
  },
  {
    sequence: [22, 32, 21, 31],
    answer: 20,
    actions: ['+10', '-11']
  },
  {
    sequence: [23, 33, 22, 32],
    answer: 21,
    actions: ['+10', '-11']
  },
  {
    sequence: [24, 34, 23, 33],
    answer: 22,
    actions: ['+10', '-11']
  },
  {
    sequence: [25, 35, 24, 34],
    answer: 23,
    actions: ['+10', '-11']
  },
  {
    sequence: [26, 36, 25, 35],
    answer: 24,
    actions: ['+10', '-11']
  },
  {
    sequence: [27, 37, 26, 36],
    answer: 25,
    actions: ['+10', '-11']
  },
  {
    sequence: [28, 38, 27, 37],
    answer: 26,
    actions: ['+10', '-11']
  },
  {
    sequence: [29, 39, 28, 38],
    answer: 27,
    actions: ['+10', '-11']
  },
  {
    sequence: [30, 40, 29, 39],
    answer: 28,
    actions: ['+10', '-11']
  },
  {
    sequence: [31, 41, 30, 40],
    answer: 29,
    actions: ['+10', '-11']
  },
  {
    sequence: [32, 42, 31, 41],
    answer: 30,
    actions: ['+10', '-11']
  },
  {
    sequence: [33, 43, 32, 42],
    answer: 31,
    actions: ['+10', '-11']
  },
  {
    sequence: [34, 44, 33, 43],
    answer: 32,
    actions: ['+10', '-11']
  },
  {
    sequence: [35, 45, 34, 44],
    answer: 33,
    actions: ['+10', '-11']
  },
  {
    sequence: [36, 46, 35, 45],
    answer: 34,
    actions: ['+10', '-11']
  },
  {
    sequence: [37, 47, 36, 46],
    answer: 35,
    actions: ['+10', '-11']
  },
  {
    sequence: [38, 48, 37, 47],
    answer: 36,
    actions: ['+10', '-11']
  },
  {
    sequence: [39, 49, 38, 48],
    answer: 37,
    actions: ['+10', '-11']
  },
  {
    sequence: [40, 50, 39, 49],
    answer: 38,
    actions: ['+10', '-11']
  },
  {
    sequence: [41, 51, 40, 50],
    answer: 39,
    actions: ['+10', '-11']
  },
  {
    sequence: [42, 52, 41, 51],
    answer: 40,
    actions: ['+10', '-11']
  },
  {
    sequence: [43, 53, 42, 52],
    answer: 41,
    actions: ['+10', '-11']
  },
  {
    sequence: [44, 54, 43, 53],
    answer: 42,
    actions: ['+10', '-11']
  },
  {
    sequence: [45, 55, 44, 54],
    answer: 43,
    actions: ['+10', '-11']
  },
  {
    sequence: [46, 56, 45, 55],
    answer: 44,
    actions: ['+10', '-11']
  },
  {
    sequence: [47, 57, 46, 56],
    answer: 45,
    actions: ['+10', '-11']
  },
  {
    sequence: [48, 58, 47, 57],
    answer: 46,
    actions: ['+10', '-11']
  },
  {
    sequence: [49, 59, 48, 58],
    answer: 47,
    actions: ['+10', '-11']
  },
  {
    sequence: [50, 60, 49, 59],
    answer: 48,
    actions: ['+10', '-11']
  },
  {
    sequence: [51, 61, 50, 60],
    answer: 49,
    actions: ['+10', '-11']
  },
  {
    sequence: [52, 62, 51, 61],
    answer: 50,
    actions: ['+10', '-11']
  },
  {
    sequence: [53, 63, 52, 62],
    answer: 51,
    actions: ['+10', '-11']
  },
  {
    sequence: [54, 64, 53, 63],
    answer: 52,
    actions: ['+10', '-11']
  },
  {
    sequence: [55, 65, 54, 64],
    answer: 53,
    actions: ['+10', '-11']
  },
  {
    sequence: [56, 66, 55, 65],
    answer: 54,
    actions: ['+10', '-11']
  },
  {
    sequence: [57, 67, 56, 66],
    answer: 55,
    actions: ['+10', '-11']
  },
  {
    sequence: [58, 68, 57, 67],
    answer: 56,
    actions: ['+10', '-11']
  },
  {
    sequence: [59, 69, 58, 68],
    answer: 57,
    actions: ['+10', '-11']
  },
  {
    sequence: [60, 70, 59, 69],
    answer: 58,
    actions: ['+10', '-11']
  },
  {
    sequence: [61, 71, 60, 70],
    answer: 59,
    actions: ['+10', '-11']
  },
  {
    sequence: [62, 72, 61, 71],
    answer: 60,
    actions: ['+10', '-11']
  },
  {
    sequence: [63, 73, 62, 72],
    answer: 61,
    actions: ['+10', '-11']
  },
  {
    sequence: [64, 74, 63, 73],
    answer: 62,
    actions: ['+10', '-11']
  },
  {
    sequence: [65, 75, 64, 74],
    answer: 63,
    actions: ['+10', '-11']
  },
  {
    sequence: [66, 76, 65, 75],
    answer: 64,
    actions: ['+10', '-11']
  },
  {
    sequence: [67, 77, 66, 76],
    answer: 65,
    actions: ['+10', '-11']
  },
  {
    sequence: [68, 78, 67, 77],
    answer: 66,
    actions: ['+10', '-11']
  },
  {
    sequence: [69, 79, 68, 78],
    answer: 67,
    actions: ['+10', '-11']
  },
  {
    sequence: [70, 80, 69, 79],
    answer: 68,
    actions: ['+10', '-11']
  },
  {
    sequence: [71, 81, 70, 80],
    answer: 69,
    actions: ['+10', '-11']
  },
  {
    sequence: [72, 82, 71, 81],
    answer: 70,
    actions: ['+10', '-11']
  },
  {
    sequence: [73, 83, 72, 82],
    answer: 71,
    actions: ['+10', '-11']
  },
  {
    sequence: [74, 84, 73, 83],
    answer: 72,
    actions: ['+10', '-11']
  },
  {
    sequence: [75, 85, 74, 84],
    answer: 73,
    actions: ['+10', '-11']
  },
  {
    sequence: [76, 86, 75, 85],
    answer: 74,
    actions: ['+10', '-11']
  },
  {
    sequence: [77, 87, 76, 86],
    answer: 75,
    actions: ['+10', '-11']
  },
  {
    sequence: [78, 88, 77, 87],
    answer: 76,
    actions: ['+10', '-11']
  },
  {
    sequence: [79, 89, 78, 88],
    answer: 77,
    actions: ['+10', '-11']
  },
  {
    sequence: [80, 90, 79, 89],
    answer: 78,
    actions: ['+10', '-11']
  },
  {
    sequence: [81, 91, 80, 90],
    answer: 79,
    actions: ['+10', '-11']
  },
  {
    sequence: [82, 92, 81, 91],
    answer: 80,
    actions: ['+10', '-11']
  },
  {
    sequence: [83, 93, 82, 92],
    answer: 81,
    actions: ['+10', '-11']
  },
  {
    sequence: [84, 94, 83, 93],
    answer: 82,
    actions: ['+10', '-11']
  },
  {
    sequence: [85, 95, 84, 94],
    answer: 83,
    actions: ['+10', '-11']
  },
  {
    sequence: [86, 96, 85, 95],
    answer: 84,
    actions: ['+10', '-11']
  },
  {
    sequence: [87, 97, 86, 96],
    answer: 85,
    actions: ['+10', '-11']
  },
  {
    sequence: [88, 98, 87, 97],
    answer: 86,
    actions: ['+10', '-11']
  },
  {
    sequence: [89, 99, 88, 98],
    answer: 87,
    actions: ['+10', '-11']
  },
  {
    sequence: [1, 21, 10, 30],
    answer: 19,
    actions: ['+20', '-11']
  },
  {
    sequence: [2, 22, 11, 31],
    answer: 20,
    actions: ['+20', '-11']
  },
  {
    sequence: [3, 23, 12, 32],
    answer: 21,
    actions: ['+20', '-11']
  },
  {
    sequence: [4, 24, 13, 33],
    answer: 22,
    actions: ['+20', '-11']
  },
  {
    sequence: [5, 25, 14, 34],
    answer: 23,
    actions: ['+20', '-11']
  },
  {
    sequence: [6, 26, 15, 35],
    answer: 24,
    actions: ['+20', '-11']
  },
  {
    sequence: [7, 27, 16, 36],
    answer: 25,
    actions: ['+20', '-11']
  },
  {
    sequence: [8, 28, 17, 37],
    answer: 26,
    actions: ['+20', '-11']
  },
  {
    sequence: [9, 29, 18, 38],
    answer: 27,
    actions: ['+20', '-11']
  },
  {
    sequence: [10, 30, 19, 39],
    answer: 28,
    actions: ['+20', '-11']
  },
  {
    sequence: [11, 31, 20, 40],
    answer: 29,
    actions: ['+20', '-11']
  },
  {
    sequence: [12, 32, 21, 41],
    answer: 30,
    actions: ['+20', '-11']
  },
  {
    sequence: [13, 33, 22, 42],
    answer: 31,
    actions: ['+20', '-11']
  },
  {
    sequence: [14, 34, 23, 43],
    answer: 32,
    actions: ['+20', '-11']
  },
  {
    sequence: [15, 35, 24, 44],
    answer: 33,
    actions: ['+20', '-11']
  },
  {
    sequence: [16, 36, 25, 45],
    answer: 34,
    actions: ['+20', '-11']
  },
  {
    sequence: [17, 37, 26, 46],
    answer: 35,
    actions: ['+20', '-11']
  },
  {
    sequence: [18, 38, 27, 47],
    answer: 36,
    actions: ['+20', '-11']
  },
  {
    sequence: [19, 39, 28, 48],
    answer: 37,
    actions: ['+20', '-11']
  },
  {
    sequence: [20, 40, 29, 49],
    answer: 38,
    actions: ['+20', '-11']
  },
  {
    sequence: [21, 41, 30, 50],
    answer: 39,
    actions: ['+20', '-11']
  },
  {
    sequence: [22, 42, 31, 51],
    answer: 40,
    actions: ['+20', '-11']
  },
  {
    sequence: [23, 43, 32, 52],
    answer: 41,
    actions: ['+20', '-11']
  },
  {
    sequence: [24, 44, 33, 53],
    answer: 42,
    actions: ['+20', '-11']
  },
  {
    sequence: [25, 45, 34, 54],
    answer: 43,
    actions: ['+20', '-11']
  },
  {
    sequence: [26, 46, 35, 55],
    answer: 44,
    actions: ['+20', '-11']
  },
  {
    sequence: [27, 47, 36, 56],
    answer: 45,
    actions: ['+20', '-11']
  },
  {
    sequence: [28, 48, 37, 57],
    answer: 46,
    actions: ['+20', '-11']
  },
  {
    sequence: [29, 49, 38, 58],
    answer: 47,
    actions: ['+20', '-11']
  },
  {
    sequence: [30, 50, 39, 59],
    answer: 48,
    actions: ['+20', '-11']
  },
  {
    sequence: [31, 51, 40, 60],
    answer: 49,
    actions: ['+20', '-11']
  },
  {
    sequence: [32, 52, 41, 61],
    answer: 50,
    actions: ['+20', '-11']
  },
  {
    sequence: [33, 53, 42, 62],
    answer: 51,
    actions: ['+20', '-11']
  },
  {
    sequence: [34, 54, 43, 63],
    answer: 52,
    actions: ['+20', '-11']
  },
  {
    sequence: [35, 55, 44, 64],
    answer: 53,
    actions: ['+20', '-11']
  },
  {
    sequence: [36, 56, 45, 65],
    answer: 54,
    actions: ['+20', '-11']
  },
  {
    sequence: [37, 57, 46, 66],
    answer: 55,
    actions: ['+20', '-11']
  },
  {
    sequence: [38, 58, 47, 67],
    answer: 56,
    actions: ['+20', '-11']
  },
  {
    sequence: [39, 59, 48, 68],
    answer: 57,
    actions: ['+20', '-11']
  },
  {
    sequence: [40, 60, 49, 69],
    answer: 58,
    actions: ['+20', '-11']
  },
  {
    sequence: [41, 61, 50, 70],
    answer: 59,
    actions: ['+20', '-11']
  },
  {
    sequence: [42, 62, 51, 71],
    answer: 60,
    actions: ['+20', '-11']
  },
  {
    sequence: [43, 63, 52, 72],
    answer: 61,
    actions: ['+20', '-11']
  },
  {
    sequence: [44, 64, 53, 73],
    answer: 62,
    actions: ['+20', '-11']
  },
  {
    sequence: [45, 65, 54, 74],
    answer: 63,
    actions: ['+20', '-11']
  },
  {
    sequence: [46, 66, 55, 75],
    answer: 64,
    actions: ['+20', '-11']
  },
  {
    sequence: [47, 67, 56, 76],
    answer: 65,
    actions: ['+20', '-11']
  },
  {
    sequence: [48, 68, 57, 77],
    answer: 66,
    actions: ['+20', '-11']
  },
  {
    sequence: [49, 69, 58, 78],
    answer: 67,
    actions: ['+20', '-11']
  },
  {
    sequence: [50, 70, 59, 79],
    answer: 68,
    actions: ['+20', '-11']
  },
  {
    sequence: [51, 71, 60, 80],
    answer: 69,
    actions: ['+20', '-11']
  },
  {
    sequence: [52, 72, 61, 81],
    answer: 70,
    actions: ['+20', '-11']
  },
  {
    sequence: [53, 73, 62, 82],
    answer: 71,
    actions: ['+20', '-11']
  },
  {
    sequence: [54, 74, 63, 83],
    answer: 72,
    actions: ['+20', '-11']
  },
  {
    sequence: [55, 75, 64, 84],
    answer: 73,
    actions: ['+20', '-11']
  },
  {
    sequence: [56, 76, 65, 85],
    answer: 74,
    actions: ['+20', '-11']
  },
  {
    sequence: [57, 77, 66, 86],
    answer: 75,
    actions: ['+20', '-11']
  },
  {
    sequence: [58, 78, 67, 87],
    answer: 76,
    actions: ['+20', '-11']
  },
  {
    sequence: [59, 79, 68, 88],
    answer: 77,
    actions: ['+20', '-11']
  },
  {
    sequence: [60, 80, 69, 89],
    answer: 78,
    actions: ['+20', '-11']
  },
  {
    sequence: [61, 81, 70, 90],
    answer: 79,
    actions: ['+20', '-11']
  },
  {
    sequence: [62, 82, 71, 91],
    answer: 80,
    actions: ['+20', '-11']
  },
  {
    sequence: [63, 83, 72, 92],
    answer: 81,
    actions: ['+20', '-11']
  },
  {
    sequence: [64, 84, 73, 93],
    answer: 82,
    actions: ['+20', '-11']
  },
  {
    sequence: [65, 85, 74, 94],
    answer: 83,
    actions: ['+20', '-11']
  },
  {
    sequence: [66, 86, 75, 95],
    answer: 84,
    actions: ['+20', '-11']
  },
  {
    sequence: [67, 87, 76, 96],
    answer: 85,
    actions: ['+20', '-11']
  },
  {
    sequence: [68, 88, 77, 97],
    answer: 86,
    actions: ['+20', '-11']
  },
  {
    sequence: [69, 89, 78, 98],
    answer: 87,
    actions: ['+20', '-11']
  },
  {
    sequence: [70, 90, 79, 99],
    answer: 88,
    actions: ['+20', '-11']
  },
  {
    sequence: [1, 31, 20, 50],
    answer: 39,
    actions: ['+30', '-11']
  },
  {
    sequence: [2, 32, 21, 51],
    answer: 40,
    actions: ['+30', '-11']
  },
  {
    sequence: [3, 33, 22, 52],
    answer: 41,
    actions: ['+30', '-11']
  },
  {
    sequence: [4, 34, 23, 53],
    answer: 42,
    actions: ['+30', '-11']
  },
  {
    sequence: [5, 35, 24, 54],
    answer: 43,
    actions: ['+30', '-11']
  },
  {
    sequence: [6, 36, 25, 55],
    answer: 44,
    actions: ['+30', '-11']
  },
  {
    sequence: [7, 37, 26, 56],
    answer: 45,
    actions: ['+30', '-11']
  },
  {
    sequence: [8, 38, 27, 57],
    answer: 46,
    actions: ['+30', '-11']
  },
  {
    sequence: [9, 39, 28, 58],
    answer: 47,
    actions: ['+30', '-11']
  },
  {
    sequence: [10, 40, 29, 59],
    answer: 48,
    actions: ['+30', '-11']
  },
  {
    sequence: [11, 41, 30, 60],
    answer: 49,
    actions: ['+30', '-11']
  },
  {
    sequence: [12, 42, 31, 61],
    answer: 50,
    actions: ['+30', '-11']
  },
  {
    sequence: [13, 43, 32, 62],
    answer: 51,
    actions: ['+30', '-11']
  },
  {
    sequence: [14, 44, 33, 63],
    answer: 52,
    actions: ['+30', '-11']
  },
  {
    sequence: [15, 45, 34, 64],
    answer: 53,
    actions: ['+30', '-11']
  },
  {
    sequence: [16, 46, 35, 65],
    answer: 54,
    actions: ['+30', '-11']
  },
  {
    sequence: [17, 47, 36, 66],
    answer: 55,
    actions: ['+30', '-11']
  },
  {
    sequence: [18, 48, 37, 67],
    answer: 56,
    actions: ['+30', '-11']
  },
  {
    sequence: [19, 49, 38, 68],
    answer: 57,
    actions: ['+30', '-11']
  },
  {
    sequence: [20, 50, 39, 69],
    answer: 58,
    actions: ['+30', '-11']
  },
  {
    sequence: [21, 51, 40, 70],
    answer: 59,
    actions: ['+30', '-11']
  },
  {
    sequence: [22, 52, 41, 71],
    answer: 60,
    actions: ['+30', '-11']
  },
  {
    sequence: [23, 53, 42, 72],
    answer: 61,
    actions: ['+30', '-11']
  },
  {
    sequence: [24, 54, 43, 73],
    answer: 62,
    actions: ['+30', '-11']
  },
  {
    sequence: [25, 55, 44, 74],
    answer: 63,
    actions: ['+30', '-11']
  },
  {
    sequence: [26, 56, 45, 75],
    answer: 64,
    actions: ['+30', '-11']
  },
  {
    sequence: [27, 57, 46, 76],
    answer: 65,
    actions: ['+30', '-11']
  },
  {
    sequence: [28, 58, 47, 77],
    answer: 66,
    actions: ['+30', '-11']
  },
  {
    sequence: [29, 59, 48, 78],
    answer: 67,
    actions: ['+30', '-11']
  },
  {
    sequence: [30, 60, 49, 79],
    answer: 68,
    actions: ['+30', '-11']
  },
  {
    sequence: [31, 61, 50, 80],
    answer: 69,
    actions: ['+30', '-11']
  },
  {
    sequence: [32, 62, 51, 81],
    answer: 70,
    actions: ['+30', '-11']
  },
  {
    sequence: [33, 63, 52, 82],
    answer: 71,
    actions: ['+30', '-11']
  },
  {
    sequence: [34, 64, 53, 83],
    answer: 72,
    actions: ['+30', '-11']
  },
  {
    sequence: [35, 65, 54, 84],
    answer: 73,
    actions: ['+30', '-11']
  },
  {
    sequence: [36, 66, 55, 85],
    answer: 74,
    actions: ['+30', '-11']
  },
  {
    sequence: [37, 67, 56, 86],
    answer: 75,
    actions: ['+30', '-11']
  },
  {
    sequence: [38, 68, 57, 87],
    answer: 76,
    actions: ['+30', '-11']
  },
  {
    sequence: [39, 69, 58, 88],
    answer: 77,
    actions: ['+30', '-11']
  },
  {
    sequence: [40, 70, 59, 89],
    answer: 78,
    actions: ['+30', '-11']
  },
  {
    sequence: [41, 71, 60, 90],
    answer: 79,
    actions: ['+30', '-11']
  },
  {
    sequence: [42, 72, 61, 91],
    answer: 80,
    actions: ['+30', '-11']
  },
  {
    sequence: [43, 73, 62, 92],
    answer: 81,
    actions: ['+30', '-11']
  },
  {
    sequence: [44, 74, 63, 93],
    answer: 82,
    actions: ['+30', '-11']
  },
  {
    sequence: [45, 75, 64, 94],
    answer: 83,
    actions: ['+30', '-11']
  },
  {
    sequence: [46, 76, 65, 95],
    answer: 84,
    actions: ['+30', '-11']
  },
  {
    sequence: [47, 77, 66, 96],
    answer: 85,
    actions: ['+30', '-11']
  },
  {
    sequence: [48, 78, 67, 97],
    answer: 86,
    actions: ['+30', '-11']
  },
  {
    sequence: [49, 79, 68, 98],
    answer: 87,
    actions: ['+30', '-11']
  },
  {
    sequence: [50, 80, 69, 99],
    answer: 88,
    actions: ['+30', '-11']
  },
  {
    sequence: [1, 41, 30, 70],
    answer: 59,
    actions: ['+40', '-11']
  },
  {
    sequence: [2, 42, 31, 71],
    answer: 60,
    actions: ['+40', '-11']
  },
  {
    sequence: [3, 43, 32, 72],
    answer: 61,
    actions: ['+40', '-11']
  },
  {
    sequence: [4, 44, 33, 73],
    answer: 62,
    actions: ['+40', '-11']
  },
  {
    sequence: [5, 45, 34, 74],
    answer: 63,
    actions: ['+40', '-11']
  },
  {
    sequence: [6, 46, 35, 75],
    answer: 64,
    actions: ['+40', '-11']
  },
  {
    sequence: [7, 47, 36, 76],
    answer: 65,
    actions: ['+40', '-11']
  },
  {
    sequence: [8, 48, 37, 77],
    answer: 66,
    actions: ['+40', '-11']
  },
  {
    sequence: [9, 49, 38, 78],
    answer: 67,
    actions: ['+40', '-11']
  },
  {
    sequence: [10, 50, 39, 79],
    answer: 68,
    actions: ['+40', '-11']
  },
  {
    sequence: [11, 51, 40, 80],
    answer: 69,
    actions: ['+40', '-11']
  },
  {
    sequence: [12, 52, 41, 81],
    answer: 70,
    actions: ['+40', '-11']
  },
  {
    sequence: [13, 53, 42, 82],
    answer: 71,
    actions: ['+40', '-11']
  },
  {
    sequence: [14, 54, 43, 83],
    answer: 72,
    actions: ['+40', '-11']
  },
  {
    sequence: [15, 55, 44, 84],
    answer: 73,
    actions: ['+40', '-11']
  },
  {
    sequence: [16, 56, 45, 85],
    answer: 74,
    actions: ['+40', '-11']
  },
  {
    sequence: [17, 57, 46, 86],
    answer: 75,
    actions: ['+40', '-11']
  },
  {
    sequence: [18, 58, 47, 87],
    answer: 76,
    actions: ['+40', '-11']
  },
  {
    sequence: [19, 59, 48, 88],
    answer: 77,
    actions: ['+40', '-11']
  },
  {
    sequence: [20, 60, 49, 89],
    answer: 78,
    actions: ['+40', '-11']
  },
  {
    sequence: [21, 61, 50, 90],
    answer: 79,
    actions: ['+40', '-11']
  },
  {
    sequence: [22, 62, 51, 91],
    answer: 80,
    actions: ['+40', '-11']
  },
  {
    sequence: [23, 63, 52, 92],
    answer: 81,
    actions: ['+40', '-11']
  },
  {
    sequence: [24, 64, 53, 93],
    answer: 82,
    actions: ['+40', '-11']
  },
  {
    sequence: [25, 65, 54, 94],
    answer: 83,
    actions: ['+40', '-11']
  },
  {
    sequence: [26, 66, 55, 95],
    answer: 84,
    actions: ['+40', '-11']
  },
  {
    sequence: [27, 67, 56, 96],
    answer: 85,
    actions: ['+40', '-11']
  },
  {
    sequence: [28, 68, 57, 97],
    answer: 86,
    actions: ['+40', '-11']
  },
  {
    sequence: [29, 69, 58, 98],
    answer: 87,
    actions: ['+40', '-11']
  },
  {
    sequence: [30, 70, 59, 99],
    answer: 88,
    actions: ['+40', '-11']
  },
  {
    sequence: [1, 51, 40, 90],
    answer: 79,
    actions: ['+50', '-11']
  },
  {
    sequence: [2, 52, 41, 91],
    answer: 80,
    actions: ['+50', '-11']
  },
  {
    sequence: [3, 53, 42, 92],
    answer: 81,
    actions: ['+50', '-11']
  },
  {
    sequence: [4, 54, 43, 93],
    answer: 82,
    actions: ['+50', '-11']
  },
  {
    sequence: [5, 55, 44, 94],
    answer: 83,
    actions: ['+50', '-11']
  },
  {
    sequence: [6, 56, 45, 95],
    answer: 84,
    actions: ['+50', '-11']
  },
  {
    sequence: [7, 57, 46, 96],
    answer: 85,
    actions: ['+50', '-11']
  },
  {
    sequence: [8, 58, 47, 97],
    answer: 86,
    actions: ['+50', '-11']
  },
  {
    sequence: [9, 59, 48, 98],
    answer: 87,
    actions: ['+50', '-11']
  },
  {
    sequence: [10, 60, 49, 99],
    answer: 88,
    actions: ['+50', '-11']
  },
  {
    sequence: [11, 1, 12, 2],
    answer: 13,
    actions: ['-10', '+11']
  },
  {
    sequence: [12, 2, 13, 3],
    answer: 14,
    actions: ['-10', '+11']
  },
  {
    sequence: [13, 3, 14, 4],
    answer: 15,
    actions: ['-10', '+11']
  },
  {
    sequence: [14, 4, 15, 5],
    answer: 16,
    actions: ['-10', '+11']
  },
  {
    sequence: [15, 5, 16, 6],
    answer: 17,
    actions: ['-10', '+11']
  },
  {
    sequence: [16, 6, 17, 7],
    answer: 18,
    actions: ['-10', '+11']
  },
  {
    sequence: [17, 7, 18, 8],
    answer: 19,
    actions: ['-10', '+11']
  },
  {
    sequence: [18, 8, 19, 9],
    answer: 20,
    actions: ['-10', '+11']
  },
  {
    sequence: [19, 9, 20, 10],
    answer: 21,
    actions: ['-10', '+11']
  },
  {
    sequence: [20, 10, 21, 11],
    answer: 22,
    actions: ['-10', '+11']
  },
  {
    sequence: [21, 11, 22, 12],
    answer: 23,
    actions: ['-10', '+11']
  },
  {
    sequence: [22, 12, 23, 13],
    answer: 24,
    actions: ['-10', '+11']
  },
  {
    sequence: [23, 13, 24, 14],
    answer: 25,
    actions: ['-10', '+11']
  },
  {
    sequence: [24, 14, 25, 15],
    answer: 26,
    actions: ['-10', '+11']
  },
  {
    sequence: [25, 15, 26, 16],
    answer: 27,
    actions: ['-10', '+11']
  },
  {
    sequence: [26, 16, 27, 17],
    answer: 28,
    actions: ['-10', '+11']
  },
  {
    sequence: [27, 17, 28, 18],
    answer: 29,
    actions: ['-10', '+11']
  },
  {
    sequence: [28, 18, 29, 19],
    answer: 30,
    actions: ['-10', '+11']
  },
  {
    sequence: [29, 19, 30, 20],
    answer: 31,
    actions: ['-10', '+11']
  },
  {
    sequence: [30, 20, 31, 21],
    answer: 32,
    actions: ['-10', '+11']
  },
  {
    sequence: [31, 21, 32, 22],
    answer: 33,
    actions: ['-10', '+11']
  },
  {
    sequence: [32, 22, 33, 23],
    answer: 34,
    actions: ['-10', '+11']
  },
  {
    sequence: [33, 23, 34, 24],
    answer: 35,
    actions: ['-10', '+11']
  },
  {
    sequence: [34, 24, 35, 25],
    answer: 36,
    actions: ['-10', '+11']
  },
  {
    sequence: [35, 25, 36, 26],
    answer: 37,
    actions: ['-10', '+11']
  },
  {
    sequence: [36, 26, 37, 27],
    answer: 38,
    actions: ['-10', '+11']
  },
  {
    sequence: [37, 27, 38, 28],
    answer: 39,
    actions: ['-10', '+11']
  },
  {
    sequence: [38, 28, 39, 29],
    answer: 40,
    actions: ['-10', '+11']
  },
  {
    sequence: [39, 29, 40, 30],
    answer: 41,
    actions: ['-10', '+11']
  },
  {
    sequence: [40, 30, 41, 31],
    answer: 42,
    actions: ['-10', '+11']
  },
  {
    sequence: [41, 31, 42, 32],
    answer: 43,
    actions: ['-10', '+11']
  },
  {
    sequence: [42, 32, 43, 33],
    answer: 44,
    actions: ['-10', '+11']
  },
  {
    sequence: [43, 33, 44, 34],
    answer: 45,
    actions: ['-10', '+11']
  },
  {
    sequence: [44, 34, 45, 35],
    answer: 46,
    actions: ['-10', '+11']
  },
  {
    sequence: [45, 35, 46, 36],
    answer: 47,
    actions: ['-10', '+11']
  },
  {
    sequence: [46, 36, 47, 37],
    answer: 48,
    actions: ['-10', '+11']
  },
  {
    sequence: [47, 37, 48, 38],
    answer: 49,
    actions: ['-10', '+11']
  },
  {
    sequence: [48, 38, 49, 39],
    answer: 50,
    actions: ['-10', '+11']
  },
  {
    sequence: [49, 39, 50, 40],
    answer: 51,
    actions: ['-10', '+11']
  },
  {
    sequence: [50, 40, 51, 41],
    answer: 52,
    actions: ['-10', '+11']
  },
  {
    sequence: [51, 41, 52, 42],
    answer: 53,
    actions: ['-10', '+11']
  },
  {
    sequence: [52, 42, 53, 43],
    answer: 54,
    actions: ['-10', '+11']
  },
  {
    sequence: [53, 43, 54, 44],
    answer: 55,
    actions: ['-10', '+11']
  },
  {
    sequence: [54, 44, 55, 45],
    answer: 56,
    actions: ['-10', '+11']
  },
  {
    sequence: [55, 45, 56, 46],
    answer: 57,
    actions: ['-10', '+11']
  },
  {
    sequence: [56, 46, 57, 47],
    answer: 58,
    actions: ['-10', '+11']
  },
  {
    sequence: [57, 47, 58, 48],
    answer: 59,
    actions: ['-10', '+11']
  },
  {
    sequence: [58, 48, 59, 49],
    answer: 60,
    actions: ['-10', '+11']
  },
  {
    sequence: [59, 49, 60, 50],
    answer: 61,
    actions: ['-10', '+11']
  },
  {
    sequence: [60, 50, 61, 51],
    answer: 62,
    actions: ['-10', '+11']
  },
  {
    sequence: [61, 51, 62, 52],
    answer: 63,
    actions: ['-10', '+11']
  },
  {
    sequence: [62, 52, 63, 53],
    answer: 64,
    actions: ['-10', '+11']
  },
  {
    sequence: [63, 53, 64, 54],
    answer: 65,
    actions: ['-10', '+11']
  },
  {
    sequence: [64, 54, 65, 55],
    answer: 66,
    actions: ['-10', '+11']
  },
  {
    sequence: [65, 55, 66, 56],
    answer: 67,
    actions: ['-10', '+11']
  },
  {
    sequence: [66, 56, 67, 57],
    answer: 68,
    actions: ['-10', '+11']
  },
  {
    sequence: [67, 57, 68, 58],
    answer: 69,
    actions: ['-10', '+11']
  },
  {
    sequence: [68, 58, 69, 59],
    answer: 70,
    actions: ['-10', '+11']
  },
  {
    sequence: [69, 59, 70, 60],
    answer: 71,
    actions: ['-10', '+11']
  },
  {
    sequence: [70, 60, 71, 61],
    answer: 72,
    actions: ['-10', '+11']
  },
  {
    sequence: [71, 61, 72, 62],
    answer: 73,
    actions: ['-10', '+11']
  },
  {
    sequence: [72, 62, 73, 63],
    answer: 74,
    actions: ['-10', '+11']
  },
  {
    sequence: [73, 63, 74, 64],
    answer: 75,
    actions: ['-10', '+11']
  },
  {
    sequence: [74, 64, 75, 65],
    answer: 76,
    actions: ['-10', '+11']
  },
  {
    sequence: [75, 65, 76, 66],
    answer: 77,
    actions: ['-10', '+11']
  },
  {
    sequence: [76, 66, 77, 67],
    answer: 78,
    actions: ['-10', '+11']
  },
  {
    sequence: [77, 67, 78, 68],
    answer: 79,
    actions: ['-10', '+11']
  },
  {
    sequence: [78, 68, 79, 69],
    answer: 80,
    actions: ['-10', '+11']
  },
  {
    sequence: [79, 69, 80, 70],
    answer: 81,
    actions: ['-10', '+11']
  },
  {
    sequence: [80, 70, 81, 71],
    answer: 82,
    actions: ['-10', '+11']
  },
  {
    sequence: [81, 71, 82, 72],
    answer: 83,
    actions: ['-10', '+11']
  },
  {
    sequence: [82, 72, 83, 73],
    answer: 84,
    actions: ['-10', '+11']
  },
  {
    sequence: [83, 73, 84, 74],
    answer: 85,
    actions: ['-10', '+11']
  },
  {
    sequence: [84, 74, 85, 75],
    answer: 86,
    actions: ['-10', '+11']
  },
  {
    sequence: [85, 75, 86, 76],
    answer: 87,
    actions: ['-10', '+11']
  },
  {
    sequence: [86, 76, 87, 77],
    answer: 88,
    actions: ['-10', '+11']
  },
  {
    sequence: [87, 77, 88, 78],
    answer: 89,
    actions: ['-10', '+11']
  },
  {
    sequence: [88, 78, 89, 79],
    answer: 90,
    actions: ['-10', '+11']
  },
  {
    sequence: [89, 79, 90, 80],
    answer: 91,
    actions: ['-10', '+11']
  },
  {
    sequence: [90, 80, 91, 81],
    answer: 92,
    actions: ['-10', '+11']
  }
];
