export default {
  shapes: [ 2, 3, 7, 1, 6, 5, 4 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 103, y: 208 },
      tablet: { x: 125, y: 189 },
      mobile: { x: 81, y: 137 },
    },
    {
      desktop: { x: 193.82, y: 208 },
      tablet: { x: 204.28, y: 189 },
      mobile: { x: 140.21, y: 137 },
    },
    {
      desktop: { x: 193.64, y: 145 },
      tablet: { x: 204.28, y: 133.28 },
      mobile: { x: 140.21, y: 95.21 },
    },
    {
      desktop: { x: 257.46, y: 144.82 },
      tablet: { x: 260.85, y: 133.28 },
      mobile: { x: 182.64, y: 95.21 },
    },
    {
      desktop: { x: 193.82, y: 208  },
      tablet: { x: 204.28, y: 189 },
      mobile: { x: 140.21, y: 137 },
    },
    {
      desktop: { x: 238, y: 253 },
      tablet: { x: 245, y: 229 },
      mobile: { x: 171, y: 167 },
    },
    {
      desktop: { x: 265.82, y: 253 },
      tablet: { x: 269.28, y: 229 },
      mobile: { x: 189.21, y: 167 },
    },
    {
      desktop: { x: 265.82, y: 189.82 },
      tablet: { x: 269.28, y: 173.28 },
      mobile: { x: 189.21, y: 125.21 },
    },
    {
      desktop: { x: 329.46, y: 189.82 },
      tablet: { x: 325.85, y: 173.28 },
      mobile: { x: 231.64, y: 125.21 },
    },
    {
      desktop: { x: 329.46, y: 253.46 },
      tablet: { x: 325.85, y: 229.85 },
      mobile: { x: 231.64, y: 167.64 },
    },
    {
      desktop: { x: 355.64, y: 279.64 },
      tablet: { x: 348.57, y: 252.57 },
      mobile: { x: 248.43, y: 184.43 },
    },
    {
      desktop: { x: 418.96, y: 279.64 },
      tablet: { x: 404.72, y: 252.57 },
      mobile: { x: 291.21, y: 184.43 },
    },
    {
      desktop: { x: 418.78, y: 216.18 },
      tablet: { x: 404.72, y: 196.28 },
      mobile: { x: 291.21, y: 142.21 },
    },
    {
      desktop: { x: 482.6, y: 216 },
      tablet: { x: 461.28, y: 196.28  },
      mobile: { x: 333.64, y: 142.21 },
    },
    {
      desktop: { x: 418.96, y: 279.64 },
      tablet: { x: 405, y: 252.57 },
      mobile: { x: 291.43 , y: 184.43 },
    },
    {
      desktop: { x: 482.28, y: 279.64 },
      tablet: { x: 461.57, y: 252.57 },
      mobile: { x: 332.85, y: 184.43 },
    },
    {
      desktop: { x: 418.64, y: 343.28 },
      tablet: { x: 405, y: 309.14 },
      mobile: { x: 290.64, y: 226.64 },
    },
    {
      desktop: { x: 418.36, y: 343 },
      tablet: { x: 404.86, y: 309 },
      mobile: {  x: 291, y: 227 },
    },
    {
      desktop: { x: 239, y: 343 },
      tablet: { x: 245, y: 309 },
      mobile: { x: 171, y: 227 },
    },
    {
      desktop: { x: 149, y: 253 },
      tablet: { x: 245, y: 309 },
      mobile: { x: 126, y: 182 },
    },
  ],
  outlines: [
    {
      desktop: { x: 103, y: 253 },
      tablet: { x: 125, y: 229 },
      mobile: { x: 81, y: 167 },
      rotation: 270
    },
    {
      desktop: { x: 225.64, y: 113 },
      tablet: { x: 232.57, y: 105 },
      mobile: { x: 161.43, y: 74 },
      rotation: 45
    },
    {
      desktop: { x: 329, y: 253 },
      tablet: { x: 325, y: 229 },
      mobile: { x: 231, y: 167 },
      rotation: 90
    },
    {
      desktop: { x: 234, y: 221.64 },
      tablet: { x: 241, y: 201.57 },
      mobile: { x: 168, y: 146.43 },
      rotation: 315
    },
    {
      desktop: { x: 239, y: 343 },
      tablet: { x: 245, y: 309 },
      mobile: { x: 171, y: 227 },
      rotation: 270
    },
    {
      desktop: { x: 418.64, y: 216 },
      tablet: { x: 405, y: 196 },
      mobile: { x: 290.43, y: 142 },
      rotation: 45
    },
    {
      desktop: { x: 450.64, y: 184 },
      tablet: { x: 433, y: 168 },
      mobile: { x: 312.43, y: 121 },
      rotation: 45
    }
  ]
};
