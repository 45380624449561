import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Spring } from 'react-spring/renderprops';

import './CircleProgress.scss';

const CircleProgress = (props) => {
  const { value, color, className, gradientId, classNameCircle,
          width = 120, height = 120, strokeWidth = 12, isAnimated } = props;
  const DIAMETER = width - strokeWidth;
  const RADIUS = DIAMETER / 2;
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

  const progress = value / 100;
  const dashoffset = CIRCUMFERENCE * (1 - progress);
  const classes = cx('circle-progress', {
    [className]: className
  });
  const classesCircleMeter = cx('circle-progress__meter', classNameCircle);
  const springProps = {
    from: { value: isAnimated ? CIRCUMFERENCE : CIRCUMFERENCE - dashoffset },
    to: { value: CIRCUMFERENCE - dashoffset }
  };

  return (
    <Spring { ...springProps }>
      {
        styles => (
          <svg
            className={ classes }
            width={ width }
            height={ height }
            viewBox={ `0 0 ${ width } ${ height }` }
          >
            <circle className={ classesCircleMeter } cx={ width / 2 } cy={ height / 2 } r={ RADIUS } strokeWidth={ strokeWidth }/>
            <circle
              className="circle-progress__value"
              cx={ width / 2 }
              cy={ height / 2 }
              r={ RADIUS }
              strokeWidth={ strokeWidth }
              style={{
                strokeDashoffset: `${ styles.value }`,
                strokeDasharray: `${ CIRCUMFERENCE }`,
                stroke: gradientId ? `url(#${gradientId})`: color
              }}
            />
          </svg>
        )
      }
    </Spring>
  );
};

CircleProgress.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string.isRequired,
  gradientId: PropTypes.string,
  isProfile: PropTypes.bool
};

export default CircleProgress;
