export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    desktop: 30,
    tablet: 35,
    mobile: 40
  },
  outline: [
    [
      {
        desktop: {
          x: 399,
          y: 162
        },
        tablet: {
          x: 227.28,
          y: 146.72
        },
        mobile: {
          x: 278,
          y: 105
        }
      },
      {
        desktop: {
          x: 309,
          y: 72
        },
        tablet: {
          x: 307,
          y: 67
        },
        mobile: {
          x: 218,
          y: 45
        }
      },
      {
        desktop: {
          x: 218.82,
          y: 162.13
        },
        tablet: {
          x: 387,
          y: 147
        },
        mobile: {
          x: 158.21,
          y: 104.79
        }
      },
      {
        desktop: {
          x: 218.82,
          y: 223.66
        },
        tablet: {
          x: 387,
          y: 307
        },
        mobile: {
          x: 158.21,
          y: 146.43
        }
      },
      {
        desktop: {
          x: 282.64,
          y: 287.46
        },
        tablet: {
          x: 347,
          y: 347
        },
        mobile: {
          x: 200.43,
          y: 188.64
        }
      },
      {
        desktop: {
          x: 345.46,
          y: 287.46
        },
        tablet: {
          x: 267,
          y: 347
        },
        mobile: {
          x: 241.64,
          y: 188.64
        }
      },
      {
        desktop: {
          x: 354,
          y: 296
        },
        tablet: {
          x: 347,
          y: 267
        },
        mobile: {
          x: 248,
          y: 195
        }
      },
      {
        desktop: {
          x: 264,
          y: 386
        },
        tablet: {
          x: 338.85,
          y: 258.85
        },
        mobile: {
          x: 188,
          y: 255
        }
      },
      {
        desktop: {
          x: 354,
          y: 386
        },
        tablet: {
          x: 283.57,
          y: 258.85
        },
        mobile: {
          x: 248,
          y: 255
        }
      },
      {
        desktop: {
          x: 399,
          y: 340.73
        },
        tablet: {
          x: 227.28,
          y: 202.57
        },
        mobile: {
          x: 278,
          y: 225
        }
      }
    ],
    [
      {
        desktop: {
          x: 310.01,
          y: 162
        },
        tablet: {
          x: 283.85,
          y: 202.57
        },
        mobile: {
          x: 218.61,
          y: 164.39
        }
      },
      {
        desktop: {
          x: 282.46,
          y: 162
        },
        tablet: {
          x: 307.64,
          y: 226.36
        },
        mobile: {
          x: 248,
          y: 135
        }
      },
      {
        desktop: {
          x: 282.46,
          y: 223.68
        },
        tablet: {
          x: 347,
          y: 187
        },
        mobile: {
          x: 218,
          y: 105
        }
      },
      {
        desktop: {
          x: 309.41,
          y: 250.59
        },
        tablet: {
          x: 307,
          y: 147
        },
        mobile: {
          x: 200.64,
          y: 105
        }
      },
      {
        desktop: {
          x: 354.13,
          y: 205.87
        },
        tablet: {
          x: 283.85,
          y: 147
        },
        mobile: {
          x: 200.64,
          y: 146.43
        }
      }
    ]
  ],
  outlines: [
    {
      desktop: {
        x: 219,
        y: 223.82,
        rotation: 45
      },
      tablet: {
        x: 227,
        y: 202.28,
        rotation: 45
      },
      mobile: {
        x: 158,
        y: 146.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 219,
        y: 162,
        rotation: 90
      },
      tablet: {
        x: 227,
        y: 147,
        rotation: 90
      },
      mobile: {
        x: 158,
        y: 105,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 399,
        y: 341,
        rotation: -180
      },
      tablet: {
        x: 387,
        y: 307,
        rotation: 180
      },
      mobile: {
        x: 278,
        y: 225,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 264,
        y: 296,
        rotation: 0
      },
      tablet: {
        x: 267,
        y: 267,
        rotation: 0
      },
      mobile: {
        x: 188,
        y: 195,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 309,
        y: 206,
        rotation: 90
      },
      tablet: {
        x: 307,
        y: 187,
        rotation: 90
      },
      mobile: {
        x: 218,
        y: 135,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 399,
        y: 386,
        rotation: -180
      },
      tablet: {
        x: 387,
        y: 347,
        rotation: 180
      },
      mobile: {
        x: 278,
        y: 255,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 187,
        y: 192.64,
        rotation: 45
      },
      tablet: {
        x: 199,
        y: 174.57,
        rotation: 45
      },
      mobile: {
        x: 137,
        y: 125.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
};
