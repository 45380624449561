export const SIZE_CELL = {
  desktop: 63,
  tablet: 56,
  mobile: 29
};
export const MARGIN = 4;
export const MOBILE = 580;
export const TABLET = 768;
export const DESKTOP = 1024;
export const ANSWER = 'answer';
export const SHUFFLE_COUNT = 6;
export const DEFAULT_FIELD = [
  [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ],
  [ 4, 5, 6, 7, 8, 9, 1, 2, 3 ],
  [ 7, 8, 9, 1, 2, 3, 4, 5, 6 ],
  [ 2, 3, 4, 5, 6, 7, 8, 9, 1 ],
  [ 5, 6, 7, 8, 9, 1, 2, 3, 4 ],
  [ 8, 9, 1, 2, 3, 4, 5, 6, 7 ],
  [ 3, 4, 5, 6, 7, 8, 9, 1, 2 ],
  [ 6, 7, 8, 9, 1, 2, 3, 4, 5 ],
  [ 9, 1, 2, 3, 4, 5, 6, 7, 8 ]
];
export const stageName = 'page2_solve';
