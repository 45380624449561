import React from 'react';
import propTypes from './prop-types';

import getRandomIndex from '~/services/get-random-index';

import TrainerScreen from '~/components/TrainerScreen/TrainerScreen';

const TrainerDescription = (props) => {
  const { trainer, onClick, cyButton, text, title, isMission, isVideo, isMissionPopup, handleDataLayer } = props;
  const { params, videoAnnounceUrl } = trainer;
  const { name, gift, texts, textsInMission } = params;
  const videoPreview = isVideo && videoAnnounceUrl ? videoAnnounceUrl : null;
  const label = text ? 'Миссия' : 'Задание';
  const buttonText = `Начать${ isMissionPopup ? ' миссию' : '' }`;
  const handleClick = () => {
    onClick('video');
    if (isMissionPopup) {
      window.dataLayer.push({ event: 'click', eventCategory: 'user_mission', eventAction: 'started' });
      return;
    }

    handleDataLayer({ eventAction: 'start', stageName: 'page1' });
  };
  let targetText = text;

  if (!text) {
    const currentTexts = isMission ? textsInMission.description : texts.description;
    targetText = currentTexts[getRandomIndex(currentTexts)];
  }

  return (
    <TrainerScreen label={ label } title={ title || name } content={ targetText }
                   buttonOnClick={ handleClick } buttonText={ buttonText }
                   buttonClassName="button_size-m" gift={ gift && gift.name } cyButton={ cyButton }
                   videoPreview={ videoPreview } onClick={ onClick } isMission={ isMission }/>
  );
};

TrainerDescription.propTypes = propTypes;

export default TrainerDescription;
