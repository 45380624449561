export default {
  texts: {
    description: [
      'Чтобы представить свой путь на самокате как можно точнее и днём, и в темноте, нужно пространственное воображение и новое упражнение на его развитие – «Весёлые точки».',
      'Передвижения агентов по городу всегда извилисты и сложны, но любой спецагент должен уметь простраивать свой путь на карте от одной точки до другой как можно быстрее. Попробуй это сделать и ты!'
    ],
    losing: [
      'Спецагент никогда не опускает руки! Тренируйся ещё, и всё обязательно получится!',
      'Совсем немного тебе не хватило! Попробуй ещё раз!'
    ],
    victory: [
      'Отличный результат! Ты был быстр и точен! В новой миссии тебя точно ждёт успех!',
      'Молодец! Ты становишься очень быстрым спецагентом.'
    ]
  },
  textsInMission: {
    description: [
      'Тебе предстоит составить маршрут передвижения по городу. Для этого соедини все точки. Действуй как можно быстрее – время идёт!',
      'Тебе предстоит переместиться из одной точки в другую как можно быстрее. Проложи идеальный маршрут, соединив точки в нужном порядке.'
    ],
    losing: [
      'Эх... Совсем чуть-чуть не успел! Мистер Фоксман ждёт тебя в тренажёрном зале, где ты точно сможешь отработать важный навык!',
      'И у спецагентов иногда что-то не получается! Но тем они и отличаются от простых людей, что никогда не сдаются! Потренируйся в тренажёрном зале, и всё точно получится!'
    ],
    victory: [
      'Как быстро ты соединил все точки! Настоящий спецагент!',
      'Отличный результат! Только классный спецагент может выполнить это задание!'
    ]
  }
};
