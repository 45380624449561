export default {
  image: require('~/static/trainers/funnyPoints/foxman.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -197, y: 781 },
    { id: 2,  x: -169, y: 749 },
    { id: 3,  x: -171, y: 721 },
    { id: 4,  x: -94,  y: 603 },
    { id: 5,  x: -64,  y: 576 },
    { id: 6,  x: -48,  y: 497 },
    { id: 7,  x: -97,  y: 482 },
    { id: 8,  x: -154, y: 456 },
    { id: 9,  x: -206, y: 410 },
    { id: 10, x: -165, y: 369 },
    { id: 11, x: -148, y: 330 },
    { id: 12, x: -136, y: 287 },
    { id: 13, x: -179, y: 263 },
    { id: 14, x: -195, y: 222 },
    { id: 15, x: -187, y: 53  },
    { id: 16, x: -93,  y: 155 },
    { id: 17, x: -70,  y: 207 },
    { id: 18, x: -23,  y: 192 },
    { id: 19, x: -53,  y: 155 },
    { id: 20, x: -5,   y: 170 },
    { id: 21, x: 2,    y: 149 },
    { id: 22, x: 53,   y: 183 },
    { id: 23, x: 101,  y: 202 },
    { id: 24, x: 130,  y: 227 },
    { id: 25, x: 153,  y: 185 },
    { id: 26, x: 252,  y: 72  },
    { id: 27, x: 258,  y: 223 },
    { id: 28, x: 223,  y: 296 },
    { id: 29, x: 187,  y: 326 },
    { id: 30, x: 198,  y: 358 },
    { id: 31, x: 222,  y: 389 },
    { id: 32, x: 257,  y: 406 },
    { id: 33, x: 178,  y: 459 },
    { id: 34, x: 129,  y: 483 },
    { id: 35, x: 85,   y: 499 },
    { id: 36, x: 99,   y: 578 },
    { id: 37, x: 130,  y: 601 },
    { id: 38, x: 201,  y: 735 },
    { id: 39, x: 201,  y: 763 },
    { id: 40, x: 220,  y: 773 }
  ]
};
