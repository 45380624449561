export default {
  shapes: [4, 7, 6, 1, 5, 3, 2],
  offset: {
    mobile: 35,
    tablet: 90,
    desktop: 25
  },
  outlines: [
    {
      id: 4,
      rotation: 315,
      desktop: {
        x: 350,
        y: 212.82
      },
      tablet: {
        x: 354.5,
        y: 195.5
      },
      mobile: {
        x: 245.43,
        y: 144.21
      }
    },
    {
      id: 7,
      rotation: 225,
      desktop: {
        x: 166.64,
        y: 269.92
      },
      tablet: {
        x: 190.57,
        y: 248.71
      },
      mobile: {
        x: 125.85,
        y: 181.28
      }
    },
    {
      id: 6,
      rotation: 315,
      desktop: {
        x: 255,
        y: 180.64
      },
      tablet: {
        x: 270,
        y: 167.57
      },
      mobile: {
        x: 182.43,
        y: 123.43
      }
    },
    {
      id: 1,
      rotation: 45,
      desktop: {
        x: 135.64,
        y: 125
      },
      tablet: {
        x: 107,
        y: 177.57
      },
      mobile: {
        x: 105.43,
        y: 86
      }
    },
    {
      id: 5,
      rotation: 225,
      desktop: {
        x: 255.64,
        y: 307.28
      },
      tablet: {
        x: 271.57,
        y: 280.14
      },
      mobile: {
        x: 182.85,
        y: 207.85
      }
    },
    {
      id: 3,
      rotation: 225,
      desktop: {
        x: 135.82,
        y: 340.46
      },
      tablet: {
        x: 163.28,
        y: 308.85
      },
      mobile: {
        x: 105.64,
        y: 229.64
      }
    },
    {
      id: 2,
      rotation: 225,
      desktop: {
        x: 412.82,
        y: 213.28
      },
      tablet: {
        x: 409.28,
        y: 197.14
      },
      mobile: {
        x: 330.07,
        y: 60
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 382.28,
        y: 181.46
      },
      tablet: {
        x: 384.5,
        y: 169
      },
      mobile: {
        x: 308.85,
        y: 81.21
      }
    },
    {
      desktop: {
        x: 444.64,
        y: 181.46
      },
      tablet: {
        x: 437.5,
        y: 169
      },
      mobile: {
        x: 351.28,
        y: 81.21
      }
    },
    {
      desktop: {
        x: 508.28,
        y: 117.82
      },
      tablet: {
        x: 494,
        y: 112.3
      },
      mobile: {
        x: 308.85,
        y: 123.64
      }
    },
    {
      desktop: {
        x: 444.64,
        y: 117.82
      },
      tablet: {
        x: 437.5,
        y: 112.3
      },
      mobile: {
        x: 267.28,
        y: 123.64
      }
    },
    {
      desktop: {
        x: 381.82,
        y: 180.64
      },
      tablet: {
        x: 382.28,
        y: 167.57
      },
      mobile: {
        x: 309.06,
        y: 165.42
      }
    },
    {
      desktop: {
        x: 255.88,
        y: 180.64
      },
      tablet: {
        x: 271.5,
        y: 167.57
      },
      mobile: {
        x: 267.28,
        y: 165.42
      }
    },
    {
      desktop: {
        x: 293.92,
        y: 142.64
      },
      tablet: {
        x: 303.71,
        y: 135.57
      },
      mobile: {
        x: 267.28,
        y: 208.28
      }
    },
    {
      desktop: {
        x: 166.64,
        y: 142.64
      },
      tablet: {
        x: 190.57,
        y: 135.57
      },
      mobile: {
        x: 224.42,
        y: 165.42
      }
    },
    {
      desktop: {
        x: 166.64,
        y: 156.82
      },
      tablet: {
        x: 190.57,
        y: 149.28
      },
      mobile: {
        x: 141.7,
        y: 165.42
      }
    },
    {
      desktop: {
        x: 103.82,
        y: 156.82
      },
      tablet: {
        x: 135.28,
        y: 149.28
      },
      mobile: {
        x: 126.85,
        y: 180.28
      }
    },
    {
      desktop: {
        x: 103.82,
        y: 220.46
      },
      tablet: {
        x: 135.28,
        y: 205.85
      },
      mobile: {
        x: 126.85,
        y: 208.42
      }
    },
    {
      desktop: {
        x: 166.64,
        y: 220.46
      },
      tablet: {
        x: 190.57,
        y: 205.85
      },
      mobile: {
        x: 84.42,
        y: 208.42
      }
    },
    {
      desktop: {
        x: 166.64,
        y: 246
      },
      tablet: {
        x: 190.57,
        y: 225
      },
      mobile: {
        x: 125.85,
        y: 167
      }
    },
    {
      desktop: {
        x: 104,
        y: 308.64
      },
      tablet: {
        x: 135,
        y: 280.57
      },
      mobile: {
        x: 125.85,
        y: 149.64
      }
    },
    {
      desktop: {
        x: 167.82,
        y: 308.46
      },
      tablet: {
        x: 191.57,
        y: 280.57
      },
      mobile: {
        x: 84.21,
        y: 149.64
      }
    },
    {
      desktop: {
        x: 167.71,
        y: 268.85
      },
      tablet: {
        x: 191.57,
        y: 247.71
      },
      mobile: {
        x: 84.21,
        y: 107.21
      }
    },
    {
      desktop: {
        x: 192.92,
        y: 243.64
      },
      tablet: {
        x: 215.71,
        y: 223.57
      },
      mobile: {
        x: 125.85,
        y: 107.21
      }
    },
    {
      desktop: {
        x: 318,
        y: 243.64
      },
      tablet: {
        x: 326,
        y: 223.57
      },
      mobile: {
        x: 125.85,
        y: 96.42
      }
    },
    {
      desktop: {
        x: 382.28,
        y: 307.92
      },
      tablet: {
        x: 383.14,
        y: 280.71
      },
      mobile: {
        x: 210.7,
        y: 96.42
      }
    },
    {
      desktop: {
        x: 382.28,
        y: 244.82
      },
      tablet: {
        x: 383.14,
        y: 223.78
      },
      mobile: {
        x: 183.7,
        y: 123.42
      }
    },
    {
      desktop: {
        x: 445.46,
        y: 244.64
      },
      tablet: {
        x: 439.35,
        y: 223.78
      },
      mobile: {
        x: 266.64,
        y: 123.42
      }
    }
  ]
};
