import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ResultPanel from '~/components/ResultPanel/ResultPanel';
import GiftGotPopup from '~/components/GiftGotPopup/GiftGotPopup';
import SuccessScreen from '~/components/SuccessScreen/SuccessScreen';
import Button from '~/components/Button/Button';
import Feedback from '~/components/Feedback/Feedback';
import FailScreen from '~/components/FailScreen/FailScreen';

import * as pageActions from '~/store/page/actions';

import { getPopupName } from '~/store/page/selector';
import { giftsStatusSelector } from '~/store/profile/selector';

import getPoints from '~/services/get-points';
import popupNames from '~/services/popup-names';

import './mission-result.scss';

const BRIDGE_MISSION_ID = 3;

const MissionResult = (props) => {
  const { isWinMission, buttonText, buttonOnClick, mission, profile, levelMaxValue, setPopup, popupName, adviceSkills,
          earnedSkills, maxSkillsForMission, text, missionStatus, myGifts } = props;
  const { gift } = mission;
  const { eventLabel } = gift;
  const { skills, gameLevel, points } = profile;
  const emptySkills = !isWinMission && Object.keys(earnedSkills).length === 0;
  const gamePoints = missionStatus.filter((item) => item === 'complete' || item === 'partially').length;
  const pointsTotal = { ...getPoints(skills, 'value'), game: points };
  const pointsOf = { ...getPoints(skills, 'max'), game: levelMaxValue };
  const pointsGot = { skills: earnedSkills, game: gamePoints };
  const handleDataLayerMission = ({ eventAction, label }) => {
    window.dataLayer.push({ event: 'finished', eventCategory: 'user_mission', eventAction, eventLabel: label });
  };
  const isFeedback = isWinMission && mission.id === BRIDGE_MISSION_ID;

  useEffect(() => {
    if (isWinMission && !myGifts.includes(gift.id)) {
      setPopup(popupNames.gift)
      window.dataLayer.push({ event: 'get', eventCategory: 'user_prop', eventAction: 'get_item', eventLabel });
    }

    let eventAction = 'finished_success';
    let label = '';

    if (!isWinMission) {
      eventAction = 'finished_failed';

      if (emptySkills) {
        label = 'got_points_no';
      } else {
        label = 'got_points_yes';
      }
    }

    handleDataLayerMission({ eventAction, label });
  }, []);

  return (
    emptySkills ?
    <FailScreen buttonText={ buttonText } buttonOnClick={ buttonOnClick } /> :
    <SuccessScreen text={ text }>
      <div className="mission-result">
        <ResultPanel isWinMission={ isWinMission } isMission={ true } endMission={ true } gift={ gift }
                     maxSkillsForMission={ maxSkillsForMission } pointsGot={ pointsGot } pointsTotal={ pointsTotal }
                     pointsOf={ pointsOf } currentLevel={ gameLevel } adviceSkills={ adviceSkills }/>
        {
          isFeedback &&
          <div className="mission-result__feedback-holder">
            <Feedback link="https://forms.gle/CximJLzxeL362SFz7" />
          </div>
        }
        {
          buttonText && (
            <div className="mission-result__button">
              <Button onClick={ buttonOnClick } theme='grey'>
                { buttonText }
              </Button>
            </div>
          )
        }
        <GiftGotPopup gift={ mission.gift } isOpen={ popupName === popupNames.gift }/>
      </div>
    </SuccessScreen>
  )
};

function mapStateToProps(state) {
  return {
    popupName: getPopupName(state),
    myGifts: giftsStatusSelector(state).map((item) => item.id)
  };
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionResult);
