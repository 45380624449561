export default {
  texts: {
    description: [
      'Любой спецагент должен уметь складывать из частей единое целое, чтобы прочесть, например, тайное послание.',
      'Каждый спецагент должен подмечать даже самые несущественные детали – для этого наши спецагенты тренируют такой навык, как концентрация внимания.'
    ],
    losing: [
      'Увы, картинка не сложилась. Попробуй ещё раз!',
      'Попробуй ещё раз, и всё обязательно получится!'
    ],
    victory: [
      'Мистер Фоксман в восторге от твоей работы и готов поручить тебе самую сложную миссию!',
      'Теперь ты готов! Сможешь открыть любую дверь и разблокировать любой замок! Мистер Фоксман уже подготовил для тебя интересную миссию.'
    ]
  },
  textsInMission: {
    description: [
      'Наш враг разбил на части ключи, которые открывают тайную дверь. Многие спецагенты не справились с этим заданием. Ты – наша последняя надежда. Собери ключи, чтобы мы получили доступ к супервещам спецагентов!',
      'Ключ от тайных дверей разлетелся на мелкие кусочки. Собери его! Иначе спецагенты не смогут использовать свои супервещи!'
    ],
    losing: [
      'Ключи не собраны – за работу пришлось взяться самому мистеру Фоксману. А всем спецагентам он велел больше тренироваться.',
      'Мистер Фоксман отправил всех спецагентов в тренажёрный зал – тренироваться. А сам разблокировал дверь!'
    ],
    victory: [
      'Ура! Дверь открыта! Мистер Фоксман ни минуты не сомневался в тебе.',
      'Теперь тебе не страшно, даже если тайное послание разорвут на кусочки – ты всё соберёшь! Молодец, так держать!',
      'Не все способны на это, но ты справился! Молодец! Так держать!'
    ]
  }
};
