import PropTypes from 'prop-types';

export default {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationSlug: PropTypes.string,
      missionSlug: PropTypes.string,
    }),
  }).isRequired,
  trainers: PropTypes.arrayOf(PropTypes.shape()),
  locations: PropTypes.arrayOf(PropTypes.shape()),
  currentTrainerIndex: PropTypes.number,
  hasShownLongMemoryTask: PropTypes.bool,
};
