export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    desktop: 10,
    tablet: 15,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 317,
        y: 59
      },
      tablet: {
        x: 314,
        y: 62
      },
      mobile: {
        x: 222,
        y: 37
      }
    },
    {
      desktop: {
        x: 272,
        y: 104
      },
      tablet: {
        x: 274,
        y: 102
      },
      mobile: {
        x: 252,
        y: 67
      }
    },
    {
      desktop: {
        x: 306.18,
        y: 138.18
      },
      tablet: {
        x: 304.57,
        y: 132.57
      },
      mobile: {
        x: 222.21,
        y: 96.79
      }
    },
    {
      desktop: {
        x: 306.18,
        y: 149.82
      },
      tablet: {
        x: 304.57,
        y: 142.43
      },
      mobile: {
        x: 257.64,
        y: 132.22
      }
    },
    {
      desktop: {
        x: 217.81,
        y: 238.19
      },
      tablet: {
        x: 225.57,
        y: 221.43
      },
      mobile: {
        x: 257.85,
        y: 257.85
      }
    },
    {
      desktop: {
        x: 217.64,
        y: 176
      },
      tablet: {
        x: 225.57,
        y: 166
      },
      mobile: {
        x: 173,
        y: 257.85
      }
    },
    {
      desktop: {
        x: 154,
        y: 239.64
      },
      tablet: {
        x: 169,
        y: 222.57
      },
      mobile: {
        x: 186,
        y: 244.85
      }
    },
    {
      desktop: {
        x: 217.46,
        y: 239.46
      },
      tablet: {
        x: 225.57,
        y: 222.57
      },
      mobile: {
        x: 186,
        y: 186
      }
    },
    {
      desktop: {
        x: 262,
        y: 284
      },
      tablet: {
        x: 265,
        y: 262
      },
      mobile: {
        x: 156.43,
        y: 156.43
      }
    },
    {
      desktop: {
        x: 262,
        y: 372.28
      },
      tablet: {
        x: 265,
        y: 340.14
      },
      mobile: {
        x: 114,
        y: 156.43
      }
    },
    {
      desktop: {
        x: 242,
        y: 392.28
      },
      tablet: {
        x: 248,
        y: 357.14
      },
      mobile: {
        x: 156.43,
        y: 114
      }
    },
    {
      desktop: {
        x: 369.28,
        y: 392.28
      },
      tablet: {
        x: 361.14,
        y: 357.14
      },
      mobile: {
        x: 156.43,
        y: 155.57
      }
    },
    {
      desktop: {
        x: 369.5,
        y: 201.35
      },
      tablet: {
        x: 360.85,
        y: 188.28
      },
      mobile: {
        x: 215.43,
        y: 96.57
      }
    },
    {
      desktop: {
        x: 317.09,
        y: 148.91
      },
      tablet: {
        x: 314.28,
        y: 141.72
      },
      mobile: {
        x: 215.43,
        y: 90.43
      }
    },
    {
      desktop: {
        x: 362,
        y: 104
      },
      tablet: {
        x: 354,
        y: 102
      },
      mobile: {
        x: 192,
        y: 67
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 369.46,
        y: 202,
        rotation: -45
      },
      tablet: {
        x: 360.85,
        y: 188,
        rotation: -45
      },
      mobile: {
        x: 257.64,
        y: 132,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 369.28,
        y: 265,
        rotation: -45
      },
      tablet: {
        x: 361.14,
        y: 244,
        rotation: -45
      },
      mobile: {
        x: 257.85,
        y: 173,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 307,
        y: 329,
        rotation: -180
      },
      tablet: {
        x: 305,
        y: 302,
        rotation: 180
      },
      mobile: {
        x: 216,
        y: 216,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 242.54,
        y: 201.64,
        rotation: 45
      },
      tablet: {
        x: 248,
        y: 188.57,
        rotation: 45
      },
      mobile: {
        x: 173,
        y: 132.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 185.82,
        y: 271.46,
        rotation: 135
      },
      tablet: {
        x: 197.28,
        y: 250.85,
        rotation: 135
      },
      mobile: {
        x: 135.21,
        y: 177.64,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 307,
        y: 373,
        rotation: -180
      },
      tablet: {
        x: 305,
        y: 341,
        rotation: 180
      },
      mobile: {
        x: 216,
        y: 246,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 272,
        y: 59,
        rotation: 0
      },
      tablet: {
        x: 274,
        y: 62,
        rotation: 0
      },
      mobile: {
        x: 192,
        y: 37,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
