export default {
  image: require('~/static/trainers/funnyPoints/giraffe.png'),
  width: 800,
  height: 919,
  offsetY: 15,
  points: [
    { id: 1,  x: -107, y: 253 },
    { id: 2,  x: -211, y: 259 },
    { id: 3,  x: -253, y: 232 },
    { id: 4,  x: -246, y: 178 },
    { id: 5,  x: -207, y: 190 },
    { id: 6,  x: -153, y: 145 },
    { id: 7,  x: -106, y: 138 },
    { id: 8,  x: -62,  y: 173 },
    { id: 9,  x: -59,  y: 220 },
    { id: 10, x: -73,  y: 461 },
    { id: 11, x: -42,  y: 463 },
    { id: 12, x: 34,   y: 420 },
    { id: 13, x: 102,  y: 412 },
    { id: 14, x: 163,  y: 439 },
    { id: 15, x: 187,  y: 501 },
    { id: 16, x: 170,  y: 576 },
    { id: 17, x: 167,  y: 629 },
    { id: 18, x: 180,  y: 670 },
    { id: 19, x: 147,  y: 808 },
    { id: 20, x: 123,  y: 808 }
  ]
};
