export default [
  'аист', 'база', 'банк', 'бант', 'беда', 'бинт', 'блин', 'бобр', 'боец', 'бокс', 'болт', 'борщ', 'боль', 'брат',
  'буря', 'бусы', 'ваза', 'веер', 'вина', 'винт', 'вкус', 'волк', 'внук', 'вода', 'воля', 'вошь', 'враг', 'вред',
  'воск', 'весы', 'врач', 'герб', 'гимн', 'гипс', 'гиря', 'глаз', 'гном', 'горб', 'град', 'граф', 'грач', 'гриб',
  'груз', 'губа', 'гусь', 'дело', 'двор', 'дата', 'джин', 'джип', 'день', 'джем', 'диск', 'дитя', 'дочь', 'друг',
  'дуга', 'духи', 'душа', 'дыня', 'дядя', 'енот', 'жаба', 'жара', 'желе', 'жена', 'заря', 'заяц', 'звон', 'звук',
  'змея', 'зонт', 'зима', 'игла', 'идея', 'изюм', 'ирис', 'итог', 'июнь', 'июль', 'квас', 'карп', 'кафе', 'каша',
  'кедр', 'кеды', 'кино', 'клад', 'клей', 'клещ', 'клуб', 'клык', 'клюв', 'кожа', 'коза', 'конь', 'корж', 'кофе',
  'край', 'крем', 'крик', 'куст', 'куча', 'коса', 'крот', 'кадр', 'кекс', 'кран', 'леди', 'лень', 'лето', 'лифт',
  'лицо', 'лото', 'лужа', 'луна', 'лупа', 'лапа', 'лист', 'мать', 'метр', 'мост', 'мышь', 'мама', 'март', 'мазь',
  'маяк', 'меню', 'мозг', 'море', 'муха', 'мята', 'мясо', 'небо', 'нога', 'ноль', 'нора', 'нота', 'ночь', 'няня',
  'обед', 'овал', 'овца', 'окно', 'орех', 'отец', 'очки', 'папа', 'парк', 'паук', 'пена', 'перо', 'пища', 'план',
  'плащ', 'плач', 'плед', 'плов', 'пляж', 'пони', 'пояс', 'пруд', 'пуля', 'путь', 'пыль', 'пюре', 'река', 'репа',
  'речь', 'роза', 'ромб', 'роса', 'рука', 'руль', 'рысь', 'сажа', 'сало', 'сани', 'сейф', 'сено', 'слон', 'слух',
  'снег', 'смех', 'сова', 'свет', 'слог', 'стая', 'стих', 'стол', 'стул', 'танк', 'тень', 'тигр', 'торт', 'труд',
  'туча', 'угол', 'удав', 'ужас', 'ужин', 'узор', 'укол', 'укус', 'утка', 'урна', 'утюг', 'фара', 'фарш', 'флаг',
  'хлеб', 'царь', 'цель', 'цена', 'цепь', 'цирк', 'шанс', 'шнур', 'шуба', 'щека', 'щука', 'юмор', 'юбка', 'яйцо',
  'язык', 'яхта', 'осел'
];
