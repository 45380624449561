export default {
  shapes: [4, 7, 6, 1, 5, 3, 2],
  offset: {
    mobile: 15
  },
  outlines: [
    {
      id: 4,
      rotation: 90,
      desktop: {
        x: 186,
        y: 198
      },
      tablet: {
        x: 198,
        y: 170
      },
      mobile: {
        x: 147,
        y: 128
      }
    },
    {
      id: 7,
      rotation: 225,
      desktop: {
        x: 184.64,
        y: 368.92
      },
      tablet: {
        x: 197.57,
        y: 322.71
      },
      mobile: {
        x: 146.43,
        y: 242
      }
    },
    {
      id: 6,
      rotation: 180,
      desktop: {
        x: 400,
        y: 332
      },
      tablet: {
        x: 389,
        y: 290
      },
      mobile: {
        x: 289.18,
        y: 218
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 214,
        y: 90
      },
      tablet: {
        x: 239,
        y: 74
      },
      mobile: {
        x: 177,
        y: 56
      }
    },
    {
      id: 5,
      rotation: 90,
      desktop: {
        x: 490,
        y: 97
      },
      tablet: {
        x: 468,
        y: 85
      },
      mobile: {
        x: 348.18,
        y: 82
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 356,
        y: 242
      },
      tablet: {
        x: 350,
        y: 209.5
      },
      mobile: {
        x: 260,
        y: 157
      }
    },
    {
      id: 2,
      rotation: 225,
      desktop: {
        x: 215.82,
        y: 274.28
      },
      tablet: {
        x: 225.7,
        y: 238
      },
      mobile: {
        x: 167.21,
        y: 178.85
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 214,
        y: 135
      },
      tablet: {
        x: 278.15,
        y: 153.15
      },
      mobile: {
        x: 207,
        y: 56
      }
    },
    {
      desktop: {
        x: 259,
        y: 90
      },
      tablet: {
        x: 239,
        y: 114
      },
      mobile: {
        x: 237,
        y: 86
      }
    },
    {
      desktop: {
        x: 304,
        y: 135
      },
      tablet: {
        x: 279,
        y: 74
      },
      mobile: {
        x: 207.79000000000002,
        y: 115.21000000000001
      }
    },
    {
      desktop: {
        x: 260.18,
        y: 178.82
      },
      tablet: {
        x: 319,
        y: 114
      },
      mobile: {
        x: 230.85,
        y: 115.21000000000001
      }
    },
    {
      desktop: {
        x: 311.28,
        y: 178.82
      },
      tablet: {
        x: 279.85,
        y: 153.15
      },
      mobile: {
        x: 188.92000000000002,
        y: 157.15
      }
    },
    {
      desktop: {
        x: 248.46,
        y: 241.64
      },
      tablet: {
        x: 310.55,
        y: 153.15
      },
      mobile: {
        x: 230.05,
        y: 157.15
      }
    },
    {
      desktop: {
        x: 310.36,
        y: 241.64
      },
      tablet: {
        x: 254.13,
        y: 209.57
      },
      mobile: {
        x: 288.18,
        y: 99
      }
    },
    {
      desktop: {
        x: 400,
        y: 152
      },
      tablet: {
        x: 309.43,
        y: 209.57
      },
      mobile: {
        x: 288.18,
        y: 82
      }
    },
    {
      desktop: {
        x: 400,
        y: 97
      },
      tablet: {
        x: 388,
        y: 131
      },
      mobile: {
        x: 348.18,
        y: 142
      }
    },
    {
      desktop: {
        x: 490,
        y: 187
      },
      tablet: {
        x: 388,
        y: 85
      },
      mobile: {
        x: 289.18,
        y: 142
      }
    },
    {
      desktop: {
        x: 400,
        y: 187
      },
      tablet: {
        x: 468,
        y: 165
      },
      mobile: {
        x: 289.18,
        y: 218
      }
    },
    {
      desktop: {
        x: 400,
        y: 332
      },
      tablet: {
        x: 389,
        y: 165
      },
      mobile: {
        x: 258.18,
        y: 187
      }
    },
    {
      desktop: {
        x: 355,
        y: 287
      },
      tablet: {
        x: 389,
        y: 290
      },
      mobile: {
        x: 201.43,
        y: 187
      }
    },
    {
      desktop: {
        x: 266.56,
        y: 287
      },
      tablet: {
        x: 348.5,
        y: 249.5
      },
      mobile: {
        x: 146.43,
        y: 242
      }
    },
    {
      desktop: {
        x: 184.64,
        y: 368.92
      },
      tablet: {
        x: 270.77,
        y: 249.5
      },
      mobile: {
        x: 146.43,
        y: 158
      }
    },
    {
      desktop: {
        x: 184.64,
        y: 243
      },
      tablet: {
        x: 197.57,
        y: 322.71000000000004
      },
      mobile: {
        x: 87,
        y: 158
      }
    },
    {
      desktop: {
        x: 96,
        y: 243
      },
      tablet: {
        x: 197.57,
        y: 210
      },
      mobile: {
        x: 117,
        y: 128
      }
    },
    {
      desktop: {
        x: 140.75,
        y: 198
      },
      tablet: {
        x: 118,
        y: 210
      },
      mobile: {
        x: 146.32,
        y: 157.32
      }
    },
    {
      desktop: {
        x: 184.7,
        y: 241.7
      },
      tablet: {
        x: 158,
        y: 170
      },
      mobile: {
        x: 188.43,
        y: 115.21000000000001
      }
    },
    {
      desktop: {
        x: 247.64,
        y: 178.82
      },
      tablet: {
        x: 197.57,
        y: 209.57
      },
      mobile: {
        x: 206.20999999999998,
        y: 115.21000000000001
      }
    },
    {
      desktop: {
        x: 257.82,
        y: 178.82
      },
      tablet: {
        x: 253.98,
        y: 153.15
      },
      mobile: {
        x: 177,
        y: 86
      }
    }
  ]
};
