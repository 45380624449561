import React, { Component, Fragment } from 'react';
import propTypes from './propTypes'
import cx from 'classnames';
import Swiper from 'react-id-swiper';

import Tabs from '~/components/Tabs/Tabs';
import MovesCounter from '~/components/MovesCounter/MovesCounter';
import Button from '~/components/Button/Button';
import Fail from '~/components/Fail/Fail';
import Ok from '~/components/Ok/Ok';
import Icon from '~/components/Icon/Icon';

import scrollTop from '~/services/scroll-top';
import getShuffleArray from '~/services/get-shuffle-array';

import { BUTTON_NEXT, BUTTON_CHECK } from '~/utils/constants';

/* eslint-disable */
import arrowNext from './assets/arrowNext.svg';
import arrowPrev from './assets/arrowPrev.svg';
/* eslint-enable */

import './TrainerLongMemory.scss';

class TrainerLongMemory extends Component {
  state = {
    isActive: true,
    movesCount: 0,
    selectedOptionIds: [],
    done: false,
    success: false,
    step: 0,
    placesForChoice: [],
    answerTab: 'answer'
  };

  componentDidMount() {
    this.props.onStartCb();
    this.initPlaces();
  }

  componentDidUpdate(prevProps, prevState) {
    /** Завершаем этап тестирования по завершению времени */
    if (prevProps.isTimerActive && !this.props.isTimerActive && this.state.isActive) {
      this.checkAnswers();
    }

    if (prevState.step !== this.state.step) {
      scrollTop();
    }
  }

  initPlaces = () => {
    const { params, longMemoryTaskData, locationSlug } = this.props;
    const { difficultyParams } = params;
    const questionPictures = longMemoryTaskData.items;
    const { places } = params;
    const currentPlaces = places[locationSlug];
    const filteredPlaces = currentPlaces.filter(item => !questionPictures.find(picture => picture.id === item.id));
    const slicedPlaces = filteredPlaces.slice(0, difficultyParams.picturesAmount - questionPictures.length);
    const placesForChoice = [ ...slicedPlaces, ...questionPictures ];
    const sortedPlaces = getShuffleArray(placesForChoice);

    this.setState({ placesForChoice: sortedPlaces })
  }

  handleSelectOption = optionId => {
    const { params } = this.props;
    const { difficultyParams } = params;
    const { selectedOptionIds } = this.state;
    const selectedIndex = selectedOptionIds.findIndex(item => item === optionId);
    const isSelected = selectedIndex >= 0

    if (isSelected) {
      selectedOptionIds.splice(selectedIndex, 1)
      const newMovesCount = this.state.movesCount - 1;
      this.setState({
        selectedOptionIds,
        movesCount: newMovesCount
      });
    } else {
      if (this.state.movesCount < difficultyParams.movesLimit) {
        const newSelectedOptionIds = [ ...selectedOptionIds, optionId ]

        this.setState({
          selectedOptionIds: newSelectedOptionIds,
          movesCount: this.state.movesCount + 1
        });
      }
    }
  };

  checkAnswers = () => {
    const { params, longMemoryTaskData } = this.props;
    const { selectedOptionIds } = this.state;
    const questionPictures = longMemoryTaskData.items;
    const success = questionPictures.every(picture => selectedOptionIds.find(item => item === picture.id));
    const { difficultyParams } = params;
    const { winnerPoints } = difficultyParams;

    if (success) {
      this.finishTrainer(winnerPoints, 'complete');
    } else {
      this.finishTrainer(null, 'fail');
    }

    this.setState({
      done: true,
      isActive: false,
      success
    })
  }

  finishTrainer = (skills, reason) => {
    const { onFinishCb } = this.props;
    onFinishCb({ victory: !!skills, needButton: false, reason, skills });
  };

  infoGoNext = () => {
    if (this.swiper) this.swiper.slideNext();
  };

  infoGoPrev = () => {
    if (this.swiper) this.swiper.slidePrev();
  };

  renderFinalSlide = ({ id, image, name, text }) => (
    <div className="trainer-long-memory__info-slide" key={ id }>
      <div className="trainer-long-memory__info-slide-image">
        <img
          src={ image }
          alt={ name }
          className="trainer-long-memory__info-slide-img"
        />
      </div>
      <div className="trainer-long-memory__info-slide-block">
        <p className="trainer-long-memory__info-slide-title">{ name }</p>
        <div className="trainer-long-memory__info-slide-text">{ text }</div>
      </div>
    </div>
  )

  renderNextButton = (onClick) => (
    <div className="trainer-long-memory__next">
      <Button
        theme="orange"
        onClick={ onClick }
      >
        { BUTTON_NEXT }
      </Button>
    </div>
  )

  renderOption = ({ id, image }) => {
    const { done, isActive, selectedOptionIds, answerTab } = this.state;
    const isSelected = selectedOptionIds.includes(id);
    const { longMemoryTaskData } = this.props;
    const questionPictures = longMemoryTaskData.items;

    let isCorrect = false;
    if (done) {
      isCorrect = !!questionPictures.find(picture => id === picture.id);
    }

    const isNotSelected = answerTab === 'answer' ? done && !isSelected : done && !isCorrect;
    const isCorrectSelection = done && isSelected && isCorrect && answerTab === 'answer';
    const isIncorrectSelection = done && isSelected && !isCorrect && answerTab === 'answer';
    const isCorrectOption = done && isCorrect && answerTab === 'correctAnswer';
    const classesOptionWrapper = cx('trainer-long-memory__option-wrapper', {
      'trainer-long-memory__option-wrapper_single': questionPictures.length === 1
    });

    return (
      <div className={ classesOptionWrapper } key={ id }>
        <div
          className={cx('trainer-long-memory__option-holder', {
            'trainer-long-memory__option-holder_selected': isSelected && answerTab === 'answer',
            'trainer-long-memory__option-holder_not-selected': isNotSelected,
            'trainer-long-memory__option-holder_selected-correct': isCorrectSelection,
            'trainer-long-memory__option-holder_selected-incorrect': isIncorrectSelection,
            'trainer-long-memory__option-holder_correct': isCorrectOption
           })}
        >
          <button
            className='trainer-long-memory__option'
            onClick={ () => this.handleSelectOption(id) }
            style={ { backgroundImage: `url(${image}` } }
            disabled={ !isActive || done }
          >
          </button>
        </div>

        {
          isIncorrectSelection && <Fail />
        }

        {
          isCorrectSelection && <Ok />
        }
      </div>
    )
  }

  changeAnswerTab = (tab) => {
    this.setState({ answerTab: tab })
  }

  render() {
    const { params, onShowPopup, longMemoryTaskData } = this.props;
    const { difficultyParams } = params;
    const questionPicture = longMemoryTaskData.items[0]
    const { movesCount, done, success, step, placesForChoice } = this.state;

    const swiperParams = {
      spaceBetween: 30,
      loop: true
    };

    return (
      <section className="trainer-long-memory">
        <div className="trainer-long-memory__container">
          {
            step === 0 && (
            <Fragment>
              {
                done ?
                <div className="trainer-long-memory__tabs">
                  <Tabs
                    onClick={ this.changeAnswerTab }
                    isCorrect={ success }
                    selected={ this.state.answerTab }
                  />
                </div>
                :
                <div className="trainer-long-memory__task">
                  Вспомни, какая картинка была изображена в начале миссии.
                </div>
              }

              <div className="trainer-long-memory__options">
                {
                  placesForChoice.map(item => this.renderOption(item))
                }

                {
                  (this.state.movesCount === difficultyParams.movesLimit) && !done && (
                    <div className="trainer-long-memory__check-answers">
                      <Button
                        onClick={ this.checkAnswers }
                        className="trainer-long-memory__button button_center"
                      >
                        { BUTTON_CHECK }
                      </Button>
                    </div>
                  )
                }
              </div>

              {
                done && this.renderNextButton(() => this.setState({ step: 1 }))
              }
            </Fragment>
          )
        }

          {
            step === 1 && (
              <Fragment>
                <div className="trainer-long-memory__info">
                  <Swiper
                    ref={ node => { if (node) this.swiper = node.swiper } }
                    { ...swiperParams }
                  >
                    { this.renderFinalSlide(questionPicture) }
                    { placesForChoice.filter(item => item.id !== questionPicture.id).map(item => this.renderFinalSlide(item)) }
                  </Swiper>

                  <button
                    className="trainer-long-memory__slider-nav trainer-long-memory__slider-nav_prev"
                    onClick={ this.infoGoPrev }
                  >
                    <Icon name="arrowPrev" className="trainer-long-memory__slider-nav-icon" />
                  </button>
                  <button
                    className="trainer-long-memory__slider-nav trainer-long-memory__slider-nav_next"
                    onClick={ this.infoGoNext }
                  >
                    <Icon name="arrowNext" className="trainer-long-memory__slider-nav-icon" />
                  </button>
                </div>

                {
                  this.renderNextButton(() => onShowPopup({ hasShownLongMemoryTest: true }))
                }
              </Fragment>
            )
          }
        </div>

        {
          step === 0 &&
          <div className="trainer-long-memory__counter">
            <MovesCounter count={ difficultyParams.movesLimit - Math.floor(movesCount) } />
          </div>
        }
      </section>
    );
  }
}

TrainerLongMemory.propTypes = propTypes;

export default TrainerLongMemory;
