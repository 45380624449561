import chineseWall from './missions/chinese-wall';
import forbiddenCity from './missions/forbidden-city';
import stadium from './missions/stadium';

export default {
  id: 3,
  slug: 'pekin',
  distance: 8142,
  city: 'Пекин',
  accusativeCity: 'Пекин',
  country: 'Китай',
  missions: {
    chineseWall,
    forbiddenCity,
    stadium
  },
  background: require('~/static/locations/pekin/beijing-background.jpg'),
  images: [
    require('~/static/locations/pekin/background1_2880.jpg'),
    require('~/static/locations/pekin/background2_2880.jpg')
  ],
  description: 'Мистер Фоксман рад приветствовать тебя в одной из самых древних стран – в Китае. Новые интереснейшие миссии ждут тебя! Здесь ты не только разовьёшь свои навыки, но и вновь пополнишь свой набор агента новыми предметами.',
  infoDescription: 'Представь себе огромный город, население которого превышает население многих небольших стран. Пекин – город невероятный. Здесь, в самом сердце древней страны, встречаются и сливаются воедино восточная культура и западные инновации.'
};
