export default [
  {
    title: 'Тест на скорость чтения',
    url: 'https://iq007.ru/tests/read'
  },
  {
    title: 'Тест на устный счет',
    url: 'https://iq007.ru/tests/count'
  },
  {
    title: 'Сеть школ IQ007',
    url: 'https://iq007.ru'
  },
];
