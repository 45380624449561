export default {
  image: require('~/static/trainers/funnyPoints/dog.png'),
  width: 800,
  height: 919,
  points: [
    { id: 1,  x: -109, y: 383 },
    { id: 2,  x: -182, y: 387 },
    { id: 3,  x: -250, y: 325 },
    { id: 4,  x: -265, y: 243 },
    { id: 5,  x: -253, y: 195 },
    { id: 6,  x: -189, y: 189 },
    { id: 7,  x: -129, y: 233 },
    { id: 8,  x: -107, y: 173 },
    { id: 9,  x: -66,  y: 143 },
    { id: 10, x: -3,   y: 177 },
    { id: 11, x: 41,   y: 242 },
    { id: 12, x: 87,   y: 309 },
    { id: 13, x: 106,  y: 373 },
    { id: 14, x: 70,   y: 431 },
    { id: 15, x: 109,  y: 492 },
    { id: 16, x: 145,  y: 554 },
    { id: 17, x: 170,  y: 613 },
    { id: 18, x: 178,  y: 666 },
    { id: 19, x: 149,  y: 708 },
    { id: 20, x: 12,   y: 718 }
  ]
};
