import React from 'react';

import './question.scss';

const Question = ({ image, options, answerId }) => {
  const text = options.find(option => option.id === answerId).text;

  return (
    <div className="question">
      <div className="question__holder">
        <div className="question__image-box">
          <img src={ image } alt="" className="question__image-box" />
        </div>
        <div className="question__text">{ text }</div>
      </div>
    </div>
  )
}

export default Question;
