import React from 'react';
import Page from '~/containers/Page/Page';

import './not-auth-page.scss';

const NotAuthPage = () => {
  return (
    <Page isNotAuth={ true }>
      <div className="not-auth-page">
        <h1 className="not-auth-page__title">
          Воспользуйтесь вашей уникальной авторизационной ссылкой!<br/>
          Нет ссылки? Обратитесь за ней в вашу школу <a href="https://iq007.ru/" target="_blank" rel="noopener noreferrer">IQ007</a> или напишите нам на <a href="mailto:info@iq007.ru">info@iq007.ru</a>
        </h1>
      </div>
    </Page>
  );
}

export default NotAuthPage;
