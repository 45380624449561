export default {
  image: require('~/static/trainers/funnyPoints/piranha.png'),
  width: 800,
  height: 919,
  offsetX: 10,
  points: [
    { id: 1,  x: -107, y: 476 },
    { id: 2,  x: -195, y: 458 },
    { id: 3,  x: -309, y: 458 },
    { id: 4,  x: -259, y: 403 },
    { id: 5,  x: -184, y: 358 },
    { id: 6,  x: -98,  y: 332 },
    { id: 7,  x: -41,  y: 263 },
    { id: 8,  x: 56,   y: 214 },
    { id: 9,  x: 26,   y: 277 },
    { id: 10, x: 27,   y: 334 },
    { id: 11, x: 98,   y: 357 },
    { id: 12, x: 158,  y: 395 },
    { id: 13, x: 221,  y: 450 },
    { id: 14, x: 274,  y: 392  },
    { id: 15, x: 372,  y: 366 },
    { id: 16, x: 300,  y: 492 },
    { id: 17, x: 316,  y: 513 },
    { id: 18, x: 348,  y: 529 },
    { id: 19, x: 278,  y: 531 },
    { id: 20, x: 212,  y: 505 },
    { id: 21, x: 142,  y: 560 },
    { id: 22, x: 83,   y: 594 },
    { id: 23, x: 2,    y: 624 },
    { id: 24, x: -87,  y: 645 },
    { id: 25, x: -165, y: 651 },
    { id: 26, x: -249, y: 642 },
    { id: 27, x: -324, y: 619 },
    { id: 28, x: -376, y: 575 },
    { id: 29, x: -235, y: 541 },
    { id: 30, x: -167, y: 520 }
  ]
};
