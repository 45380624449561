export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 371.96,
        y: 135.19
      },
      tablet: {
        x: 403,
        y: 84
      },
      mobile: {
        x: 289.79,
        y: 59
      }
    },
    {
      desktop: {
        x: 415.89,
        y: 91
      },
      tablet: {
        x: 363.28,
        y: 123.72
      },
      mobile: {
        x: 260,
        y: 88.79
      }
    },
    {
      desktop: {
        x: 461.14,
        y: 136
      },
      tablet: {
        x: 363.28,
        y: 179.57
      },
      mobile: {
        x: 260,
        y: 130.43
      }
    },
    {
      desktop: {
        x: 435.6,
        y: 136
      },
      tablet: {
        x: 250.85,
        y: 179.57
      },
      mobile: {
        x: 175.64,
        y: 130.43
      }
    },
    {
      desktop: {
        x: 435.6,
        y: 199.1
      },
      tablet: {
        x: 194.57,
        y: 123.28
      },
      mobile: {
        x: 132.82,
        y: 87.61
      }
    },
    {
      desktop: {
        x: 353.29,
        y: 281.4
      },
      tablet: {
        x: 138,
        y: 123.28
      },
      mobile: {
        x: 91,
        y: 88.21
      }
    },
    {
      desktop: {
        x: 398.14,
        y: 326
      },
      tablet: {
        x: 265,
        y: 250.57
      },
      mobile: {
        x: 185,
        y: 182.43
      }
    },
    {
      desktop: {
        x: 310.14,
        y: 326
      },
      tablet: {
        x: 265,
        y: 330
      },
      mobile: {
        x: 185,
        y: 242
      }
    },
    {
      desktop: {
        x: 349.14,
        y: 365
      },
      tablet: {
        x: 345,
        y: 330
      },
      mobile: {
        x: 245,
        y: 242
      }
    },
    {
      desktop: {
        x: 259.14,
        y: 365
      },
      tablet: {
        x: 307,
        y: 292
      },
      mobile: {
        x: 218,
        y: 215
      }
    },
    {
      desktop: {
        x: 259.14,
        y: 275.64
      },
      tablet: {
        x: 386,
        y: 292
      },
      mobile: {
        x: 276,
        y: 215
      }
    },
    {
      desktop: {
        x: 119,
        y: 135.68
      },
      tablet: {
        x: 346.64,
        y: 252.64
      },
      mobile: {
        x: 246.85,
        y: 185.85
      }
    },
    {
      desktop: {
        x: 182.64,
        y: 135.68
      },
      tablet: {
        x: 419.5,
        y: 179.75
      },
      mobile: {
        x: 302.45,
        y: 130.27
      }
    },
    {
      desktop: {
        x: 245.6,
        y: 198.64
      },
      tablet: {
        x: 419.85,
        y: 124
      },
      mobile: {
        x: 302.43,
        y: 89
      }
    },
    {
      desktop: {
        x: 371.96,
        y: 198.64
      },
      tablet: {
        x: 443,
        y: 124
      },
      mobile: {
        x: 319.79,
        y: 89
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 119,
        y: 135.68,
        rotation: 45
      },
      tablet: {
        x: 138,
        y: 123.28,
        rotation: 45
      },
      mobile: {
        x: 91,
        y: 88.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 182.14,
        y: 198.64,
        rotation: 45
      },
      tablet: {
        x: 194,
        y: 179.57,
        rotation: 45
      },
      mobile: {
        x: 133,
        y: 130.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 308.78,
        y: 325.92,
        rotation: 135
      },
      tablet: {
        x: 306.57,
        y: 292.71,
        rotation: 135
      },
      mobile: {
        x: 217.43,
        y: 215.28,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 349.14,
        y: 275,
        rotation: -90
      },
      tablet: {
        x: 345,
        y: 250,
        rotation: -90
      },
      mobile: {
        x: 245,
        y: 182,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 461.14,
        y: 91,
        rotation: -90
      },
      tablet: {
        x: 443,
        y: 84,
        rotation: -90
      },
      mobile: {
        x: 319.79,
        y: 59,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 398.14,
        y: 281,
        rotation: -90
      },
      tablet: {
        x: 386,
        y: 252,
        rotation: -90
      },
      mobile: {
        x: 276,
        y: 185,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 340.14,
        y: 167.64,
        rotation: 45
      },
      tablet: {
        x: 335,
        y: 151.57,
        rotation: 45
      },
      mobile: {
        x: 238.79,
        y: 109.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
};
