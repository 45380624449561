export default {
  shapes: [ 5, 1, 6, 3, 7, 8, 4 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 104, y: 161.64 },
      tablet: { x: 128, y: 147.57 },
      mobile: { x: 81, y: 105.43 },
    },
    {
      desktop: { x: 167.64, y: 98 },
      tablet: { x: 184.57, y: 91 },
      mobile: { x: 123.43, y: 63 },
    },
    {
      desktop: { x: 231.28, y: 161.64 },
      tablet: { x: 241.14, y: 147.57 },
      mobile: { x: 165.85, y: 105.43 },
    },
    {
      desktop: { x: 230.46, y: 161.64 },
      tablet: {  x: 240.85, y: 203  },
      mobile: { x: 165.64, y: 105.43 },
    },
    {
      desktop: { x: 230.46, y: 223.82 },
      tablet: {  x: 240.85, y: 203  },
      mobile: { x: 165.64, y: 147 },
    },
    {
      desktop: { x: 319.55, y: 223.82 },
      tablet: { x: 344, y: 203 },
      mobile: { x: 243, y: 147 },
    },
    {
      desktop: { x: 319.55, y: 223.82 },
      tablet: { x: 344.28, y: 203.28 },
      mobile: { x: 243.21, y: 147.21 },
    },
    {
      desktop: { x: 408.64, y: 223.82 },
      tablet: { x: 400.57, y: 203.28 },
      mobile: { x: 284.82, y: 146.61 },
    },
    {
      desktop: { x: 472.28, y: 287.46 },
      tablet: { x: 457.14, y: 259.85 },
      mobile: { x: 327.85, y: 189.64 },
    },
    {
      desktop: { x: 409.46, y: 287.46 },
      tablet: { x: 400.85, y: 259.85 },
      mobile: { x: 285.64, y: 189.64 },
    },
    {
      desktop: { x: 436, y: 314 },
      tablet: { x: 424, y: 283 },
      mobile: { x: 303, y: 207 },
    },
    {
      desktop: { x: 482, y: 314 },
      tablet: { x: 458, y: 283 },
      mobile: { x: 332, y: 207 },
    },
    {
      desktop: { x: 437.25, y: 359 },
      tablet: { x: 418, y: 323 },
      mobile: { x: 302, y: 237 },
    },
    {
      desktop: { x: 392, y: 314 },
      tablet: { x: 378, y: 283 },
      mobile: { x: 272, y: 207 },
    },
    {
      desktop: { x: 302, y: 314 },
      tablet: { x: 293, y: 283 },
      mobile: { x: 204, y: 207 },
    },
    {
      desktop: { x: 257.25, y: 359 },
      tablet: { x: 253, y: 323 },
      mobile: { x: 174, y: 237 },
    },
    {
      desktop: { x: 212, y: 314 },
      tablet: { x: 213, y: 283 },
      mobile: { x: 144, y: 207 },
    },
    {
      desktop: { x: 256, y: 314 },
      tablet: { x: 264, y: 283 },
      mobile: { x: 183, y: 207 },
    },
    {
      desktop: { x: 167.64, y: 224.46 },
      tablet: { x: 184.85, y: 203.85 },
      mobile: { x: 123.64, y: 147.64 },
    },
    {
      desktop: { x: 166.82, y: 224.46 },
      tablet: { x: 184.28, y: 203.85 },
      mobile: { x: 123.21, y: 147.64 },
    },
    {
      desktop: { x: 166.82, y: 161.64 },
      tablet: { x: 184.28, y: 147.57 },
      mobile: { x: 123.21, y: 105.43 },
    }
  ],
  outlines: [
    {
      desktop: { x: 167.64, y: 225.28 },
      tablet: { x: 184.57, y: 204.14  },
      mobile: { x: 123.43, y: 147.85 },
      rotation: 225
    },
    {
      desktop: { x: 135, y: 192.64 },
      tablet: { x: 156, y: 175.57  },
      mobile: { x: 102, y: 126.43 },
      rotation: 315
    },
    {
      desktop: { x: 347, y: 223.82 },
      tablet: { x: 344, y: 203  },
      mobile: { x: 243, y: 147 },
      rotation: 90
    },
    {
      desktop: { x: 212, y: 359 },
      tablet: { x: 213, y: 323  },
      mobile: { x: 144, y: 237 },
      rotation: 270
    },
    {
      desktop: { x: 256, y: 314 },
      tablet: { x: 264, y: 283  },
      mobile: { x: 183, y: 207 },
      rotation: 270
    },
    {
      desktop: { x: 345, y: 223.82 },
      tablet: { x: 344, y: 203.28  },
      mobile: { x: 243, y: 147.21 },
      rotation: 315
    },
    {
      desktop: { x: 392, y: 359 },
      tablet: { x: 378, y: 323  },
      mobile: { x: 272, y: 237 },
      rotation: 270
    }
  ]
}
