export default {
  texts: {
    description: [
      'Любой спецагент знает: записывать секретные данные нельзя – их могут украсть, поэтому всё приходится запоминать. И твое тайное оружие – мнемотехника.',
      'Память спецагента – это ещё одно секретное оружие. Каждый день ему приходится запоминать логины, пароли, координаты... Это упражнение поможет тебе развить память как у спецагента!',
      'Спецагента окружает большое количество информации! А чтобы память никогда не подводила, они развивают её с помощью мнемотехники.'
    ],
    losing: [
      'Некоторые картинки не на своих местах! Будь внимательнее, и у тебя всё получится!',
      'Используй секретные техники для запоминания, и у тебя всё получится!'
    ],
    victory: [
      'Все картинки на своих местах. Какой ты молодец! Скорее приступай к новой миссии!',
      'Отличный результат! Тебя ждут для выполнения сложной миссии!'
    ]
  },
  textsInMission: {
    description: [
      'Тебе предстоит разгадать тайное послание. Для этого запомни все картинки в том порядке, который тебе будет показан. Ты уже владеешь приёмами запоминания? Используй их.',
      'За картинками скрыто тайное послание, но чтобы его прочитать необходимо запомнить их расположение. Используй приёмы запоминания, которые ты знаешь, и у тебя всё получится!'
    ],
    losing: [
      'К сожалению, ты не прошёл проверку мистера Фоксмана. На важное задание был отправлен другой спецагент. Надо больше тренироваться!',
      'Мистер Фоксман велел больше тренироваться, ведь память спецагента – его тайное оружие!'
    ],
    victory: [
      'Отлично! Послание передано мистеру Фоксману. Ты становишься незаменимым спецагентом!',
      'Да у тебя отличная память – ты всё вспомнил абсолютно верно!',
      'Память спецагента – его тайное оружие. И, похоже, этим оружием ты владеешь в совершенстве!',
      'Молодец, отличная работа! Если какие-то предметы ещё могут утащить вражеские шпионы, то супернавыки у тебя никто не отнимет.'
    ]
  }
};
