export default {
  shapes: [4, 5, 1, 3, 6, 2, 7],
  offset: {
    mobile: 20,
    tablet: 10
  },
  outlines: [
    {
      id: 4,
      rotation: 225,
      desktop: {
        x: 183.82,
        y: 225.8
      },
      tablet: {
        x: 196.28,
        y: 204.65
      },
      mobile: {
        x: 136.21,
        y: 159.64
      }
    },
    {
      id: 5,
      rotation: 135,
      desktop: {
        x: 368.22,
        y: 194
      },
      tablet: {
        x: 360.08,
        y: 176.37
      },
      mobile: {
        x: 257.85,
        y: 139.43
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 271.72,
        y: 99.64
      },
      tablet: {
        x: 274.08,
        y: 92.57
      },
      mobile: {
        x: 194,
        y: 77.43
      }
    },
    {
      id: 3,
      rotation: 270,
      desktop: {
        x: 214.57,
        y: 175.4
      },
      tablet: {
        x: 223.53,
        y: 159.8
      },
      mobile: {
        x: 156,
        y: 127
      }
    },
    {
      id: 6,
      rotation: 0,
      desktop: {
        x: 254.22,
        y: 206
      },
      tablet: {
        x: 259.08,
        y: 187.8
      },
      mobile: {
        x: 179,
        y: 145
      }
    },
    {
      id: 2,
      rotation: 135,
      desktop: {
        x: 399.21,
        y: 288.46
      },
      tablet: {
        x: 388.32,
        y: 260.67
      },
      mobile: {
        x: 278.85,
        y: 202.64
      }
    },
    {
      id: 7,
      rotation: 270,
      desktop: {
        x: 254.22,
        y: 386
      },
      tablet: {
        x: 259.08,
        y: 347.8
      },
      mobile: {
        x: 179,
        y: 265
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 254.22,
        y: 207.28
      },
      tablet: {
        x: 358.94,
        y: 64.28
      },
      mobile: {
        x: 257.64,
        y: 56.21
      }
    },
    {
      desktop: {
        x: 254.22,
        y: 386
      },
      tablet: {
        x: 302.37,
        y: 64.28
      },
      mobile: {
        x: 215.21,
        y: 56.21
      }
    },
    {
      desktop: {
        x: 434.22,
        y: 386
      },
      tablet: {
        x: 302.37,
        y: 119.80000000000001
      },
      mobile: {
        x: 215.21,
        y: 97
      }
    },
    {
      desktop: {
        x: 367.39,
        y: 319.16999999999996
      },
      tablet: {
        x: 224.57,
        y: 119.80000000000001
      },
      mobile: {
        x: 156.43,
        y: 97
      }
    },
    {
      desktop: {
        x: 367.39,
        y: 256.64
      },
      tablet: {
        x: 168,
        y: 176.36
      },
      mobile: {
        x: 115,
        y: 138.42
      }
    },
    {
      desktop: {
        x: 304.58000000000004,
        y: 193.82999999999998
      },
      tablet: {
        x: 224.57,
        y: 176.36
      },
      mobile: {
        x: 157.43,
        y: 138.42
      }
    },
    {
      desktop: {
        x: 304.58000000000004,
        y: 131.45999999999998
      },
      tablet: {
        x: 224.57,
        y: 120.83
      },
      mobile: {
        x: 157.43,
        y: 98.42
      }
    },
    {
      desktop: {
        x: 367.18,
        y: 131.45999999999998
      },
      tablet: {
        x: 263.52,
        y: 159.79000000000002
      },
      mobile: {
        x: 185.71,
        y: 126.71000000000001
      }
    },
    {
      desktop: {
        x: 367.18,
        y: 67.82
      },
      tablet: {
        x: 246.95,
        y: 176.36
      },
      mobile: {
        x: 173,
        y: 139.42
      }
    },
    {
      desktop: {
        x: 303.53999999999996,
        y: 67.82
      },
      tablet: {
        x: 259.08,
        y: 188.5
      },
      mobile: {
        x: 179,
        y: 145.42
      }
    },
    {
      desktop: {
        x: 303.53999999999996,
        y: 130.39999999999998
      },
      tablet: {
        x: 259.08,
        y: 347.79999999999995
      },
      mobile: {
        x: 179,
        y: 265
      }
    },
    {
      desktop: {
        x: 215.57999999999998,
        y: 130.39999999999998
      },
      tablet: {
        x: 419.08000000000004,
        y: 347.79999999999995
      },
      mobile: {
        x: 299,
        y: 265
      }
    },
    {
      desktop: {
        x: 152,
        y: 193.98
      },
      tablet: {
        x: 360.03999999999996,
        y: 288.75
      },
      mobile: {
        x: 257.64,
        y: 223.64000000000001
      }
    },
    {
      desktop: {
        x: 215.82,
        y: 193.8
      },
      tablet: {
        x: 360.03999999999996,
        y: 232.38
      },
      mobile: {
        x: 257.64,
        y: 181.42
      }
    },
    {
      desktop: {
        x: 215.64,
        y: 131.47
      },
      tablet: {
        x: 303.52,
        y: 175.86
      },
      mobile: {
        x: 215.43,
        y: 139.21
      }
    },
    {
      desktop: {
        x: 259.68,
        y: 175.26
      },
      tablet: {
        x: 303.52,
        y: 120.85
      },
      mobile: {
        x: 215.43,
        y: 98.64
      }
    },
    {
      desktop: {
        x: 240.94,
        y: 194
      },
      tablet: {
        x: 358.94,
        y: 120.85
      },
      mobile: {
        x: 257.64,
        y: 98.64
      }
    }
  ]
};
