export default {
  image: require('~/static/trainers/funnyPoints/helicopter.png'),
  width: 800,
  height: 919,
  offsetX: 10,
  points: [
    { id: 1,  x: -43,  y: 292 },
    { id: 2,  x: -42,  y: 363 },
    { id: 3,  x: -127, y: 393 },
    { id: 4,  x: -197, y: 474 },
    { id: 5,  x: -215, y: 563 },
    { id: 6,  x: -184, y: 644 },
    { id: 7,  x: -115, y: 680 },
    { id: 8,  x: -24,  y: 684 },
    { id: 9,  x: 50,   y: 658 },
    { id: 10, x: 101,  y: 613 },
    { id: 11, x: 141,  y: 559 },
    { id: 12, x: 157,  y: 484 },
    { id: 13, x: 213,  y: 468 },
    { id: 14, x: 273,  y: 431 },
    { id: 15, x: 209,  y: 447 },
    { id: 16, x: 147,  y: 453 },
    { id: 17, x: 104,  y: 414 },
    { id: 18, x: 46,   y: 379 },
    { id: 19, x: -21,  y: 363 },
    { id: 20, x: -24,  y: 292 }
  ]
};
