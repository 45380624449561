import level1 from './baseQuestions/level1';
import level3 from './baseQuestions/level3';
import level5 from './baseQuestions/level5';

export default {
  1: level1,
  2: level1,
  3: level3,
  4: level3,
  5: level5,
  6: level5,
};
