import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

import patterns from './patterns';

const templateLevels = {
  1: { seconds: 300 },
  2: { seconds: 300 },
  3: { seconds: 300 },
  4: { seconds: 300 },
  5: { seconds: 300 },
  6: { seconds: 300 },
  7: { seconds: 300 },
  8: { seconds: 300 },
  9: { seconds: 300 },
  10: { seconds: 300 }
};

export default getLevels(templateLevels, getLevelParams, { name: 'patterns', data: patterns });
