import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

import patterns from './patterns';

const templateLevels = {
  1: {
    timer: [25, 35, 25, 35, 25, 35],
    cardsInLine: 3
  },
  2: {
    timer: [15, 45, 15, 45, 15, 45],
    cardsInLine: 3
  },
  3: {
    timer: [15, 45, 15, 45, 15, 45],
    cardsInLine: 4
  },
  4: {
    timer: [30, 60, 30, 60, 30, 60],
    cardsInLine: 5
  },
  5: {
    timer: [30, 60, 30, 60, 30, 60],
    cardsInLine: 5
  },
  6: {
    timer: [25, 35, 25, 35, 25, 35],
    cardsInLine: 3
  },
  7: {
    timer: [30, 40, 30, 40, 30, 40],
    cardsInLine: 3
  },
  8: {
    timer: [35, 40, 35, 40, 35, 40],
    cardsInLine: 4
  },
  9: {
    timer: [40, 50, 40, 50, 40, 50],
    cardsInLine: 5
  },
  10: {
    timer: [45, 55, 45, 55, 45, 55],
    cardsInLine: 5
  }
};

export default getLevels(templateLevels, getLevelParams, { name: 'patterns', data: patterns });
