import React from 'react';
import cx from 'classnames';
import container from './assets/container.png';
import Icon from '~/components/Icon/Icon';

/* eslint-disable */
import locker from './assets/locker.svg';
/* eslint-enable */

import './profile-gift.scss';

const ProfileGift = ({ gift, isLocked }) => {
  const { name } = gift;
  const src = isLocked ? gift.disableImage : gift.image;
  const classes = cx('profile-gift', {
    'profile-gift_locked': isLocked
  });

  return (
    <div className={ classes }>
      <div className="profile-gift__holder">
        { !isLocked && renderNotLocked() }

        <div className="profile-gift__gift">
          <img className="profile-gift__img" alt={ name } src={ src } />
        </div>

        { isLocked && renderLocked() }
      </div>

      <div className="profile-gift__name">
        { name }
      </div>
    </div>
  )
}

function renderLocked() {
  return (
    <div className="profile-gift__locked">
      <div className="profile-gift__mask"></div>
      <div className="profile-gift__locker">
        <Icon name="locker" className="profile-gift__locker-icon" />
      </div>
    </div>
  );
}

function renderNotLocked() {
  return <img className="profile-gift__container" alt="container" src={ container } />;
}

export default ProfileGift;
