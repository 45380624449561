import gifts from '~/shared/gifts';

export default {
  id: 2,
  name: 'Биг-Бен',
  longMemory: true,
  description: 'Всемирно известная башня, хотя на самом деле, Биг-Бен – это название её часового колокола.',
  partingWords: 'Смотри, как подрос уровень и все навыки! Вот это прогресс – а всего-то прошёл первую миссию. А с Поясом ты выглядишь как настоящий ученик секретной школы Мистера Фоксмана. Но где твоё высокотехнологичное оборудование? В этой миссии тебе удастся добыть Часы – необходимый атрибут агента. Конечно, это не обычные часы: в них спрятано множество функций, которые выручат тебя в более сложных миссиях.',
  story: 'Биг-Бен – это колокол, весящий 13,5 тонн и расположенный за циферблатами, однако очень часто так называют всю башню, ставшую символом Лондона. Биг-Бен расположен на башне святого Стефана – знаменитейшей части Вестминстерского дворца.',
  textLosing: 'Не справился? Не беда! Попробуй потренироваться на тренажёрах и приходи снова!',
  textPartially: 'Увы, не все задания миссии были выполнены. Но ты все равно смог развить свои навыки. Попробуй потренироваться на тренажёрах и приходи снова!',
  textSuccess: 'Вторая успешно выполненная миссия – это круто! Посмотри, как выросли твои навыки и какие у тебя теперь крутые Часы. Стартовый набор агента-ученика почти собран!',
  gift: gifts.watch,
  imgs: [
    require('~/static/locations/london/big-ban.jpg'),
  ]
};
