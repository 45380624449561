import React from 'react';
import { connect } from 'react-redux';

import StatusUpPanel from '~/components/StatusUpPanel/StatusUpPanel';
import Popup from '~/components/Popup/Popup';

import popupNames from '~/services/popup-names';

import { prevSkillLevelNameSelector, skillLevelNameSelector } from '~/store/profile/selector';
import { getPopupName } from '~/store/page/selector';

import './status-up-popup.scss';

const StatusUpPopup = (props) => {
  const { popupName, profile, status, prevStatus } = props;
  const { skills, prevSkills } = profile;
  const isOpen = popupName === popupNames.statusUp;

  return (
    <Popup className="status-up-popup scale" isOpen={ isOpen }>
      <div className="status-up-popup__holder">
        <div className="status-up-popup__title">Новый статус!</div>
        <StatusUpPanel prevSkills={ prevSkills } skills={ skills } status={ status } prevStatus={ prevStatus }/>
      </div>
    </Popup>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    popupName: getPopupName(state),
    status: skillLevelNameSelector(state),
    prevStatus: prevSkillLevelNameSelector(state)
  };
}

export default connect(mapStateToProps)(StatusUpPopup);
