export default {
  image: require('~/static/trainers/funnyPoints/snake.png'),
  width: 800,
  height: 919,
  offsetY: 30,
  offsetX: 10,
  points: [
    { id: 1,  x: -211, y: 460 },
    { id: 2,  x: -242, y: 444 },
    { id: 3,  x: -254, y: 411 },
    { id: 4,  x: -238, y: 335 },
    { id: 5,  x: -245, y: 296 },
    { id: 6,  x: -262, y: 252 },
    { id: 7,  x: -244, y: 203 },
    { id: 8,  x: -201, y: 185 },
    { id: 9,  x: -152, y: 191 },
    { id: 10, x: -91,  y: 188 },
    { id: 11, x: -5,   y: 187 },
    { id: 12, x: 65,   y: 180 },
    { id: 13, x: 148,  y: 167 },
    { id: 14, x: 240,  y: 162 },
    { id: 15, x: 293,  y: 197 },
    { id: 16, x: 318,  y: 256 },
    { id: 17, x: 321,  y: 320 },
    { id: 18, x: 294,  y: 381 },
    { id: 19, x: 243,  y: 422 },
    { id: 20, x: 172,  y: 448 },
    { id: 21, x: 100,  y: 454 },
    { id: 22, x: 51,   y: 470 },
    { id: 23, x: 51,   y: 507 },
    { id: 24, x: 104,  y: 518 },
    { id: 25, x: 171,  y: 516 },
    { id: 26, x: 237,  y: 524 },
    { id: 27, x: 286,  y: 559 },
    { id: 28, x: 304,  y: 609 },
    { id: 29, x: 299,  y: 681 },
    { id: 30, x: 268,  y: 732 },
    { id: 31, x: 217,  y: 768 },
    { id: 32, x: 144,  y: 796 },
    { id: 33, x: 68,   y: 808 },
    { id: 34, x: -14,  y: 804 },
    { id: 35, x: -101, y: 790 },
    { id: 36, x: -184, y: 760 },
    { id: 37, x: -251, y: 717 },
    { id: 38, x: -302, y: 657 },
    { id: 39, x: -330, y: 591 },
    { id: 40, x: -320, y: 524 }
  ]
};
