import pineapple from '../figures/pineapple';
import tomato from '../figures/tomato';
import strawberry from '../figures/strawberry';
import radio from '../figures/radio';
import binoculars from '../figures/binoculars';
import amanita from '../figures/amanita';
import eggplant from '../figures/eggplant';
import pear from '../figures/pear';
import banana from '../figures/banana';
import stump from '../figures/stump';
import pepper from '../figures/pepper';
import garnet from '../figures/garnet';
import glasses from '../figures/glasses';
import clock from '../figures/clock';
import apple from '../figures/apple';

export default [
  pineapple,
  tomato,
  strawberry,
  binoculars,
  amanita,
  eggplant,
  pear,
  banana,
  stump,
  pepper,
  garnet,
  glasses,
  clock,
  apple,
  radio
];
