export default {
  image: require('~/static/trainers/funnyPoints/bird.png'),
  width: 800,
  height: 919,
  offsetX: 30,
  offsetY: 30,
  points: [
    { id: 1,  x: -122, y: 494 },
    { id: 2,  x: -162, y: 476 },
    { id: 3,  x: -192, y: 443 },
    { id: 4,  x: -207, y: 400 },
    { id: 5,  x: -213, y: 348 },
    { id: 6,  x: -196, y: 291 },
    { id: 7,  x: -160, y: 247 },
    { id: 8,  x: -106, y: 213 },
    { id: 9,  x: -43,  y: 205 },
    { id: 10, x: 8,    y: 218 },
    { id: 11, x: 57,   y: 257 },
    { id: 12, x: 82,   y: 315 },
    { id: 13, x: 84,   y: 369 },
    { id: 14, x: 72,   y: 405 },
    { id: 15, x: 57,   y: 439 },
    { id: 16, x: 87,   y: 471 },
    { id: 17, x: 124,  y: 495 },
    { id: 18, x: 163,  y: 513 },
    { id: 19, x: 213,  y: 525 },
    { id: 20, x: 254,  y: 519 },
    { id: 21, x: 292,  y: 506 },
    { id: 22, x: 234,  y: 575 },
    { id: 23, x: 192,  y: 597 },
    { id: 24, x: 157,  y: 636 },
    { id: 25, x: 103,  y: 665 },
    { id: 26, x: 40,   y: 671 },
    { id: 27, x: -27,  y: 661 },
    { id: 28, x: -82,  y: 634 },
    { id: 29, x: -111, y: 591 },
    { id: 30, x: -114, y: 536 }
  ]
};
