export default (templateLevels, getLevelParams, entity) => {
  let levels = {};

  Object.keys(templateLevels).forEach((level) => {
    levels[level] = getLevelParams(templateLevels[level]);
    if (entity) {
      const { data, name } = entity;
      levels[level][name] = data[level];
    }
  });

  return levels;
};
