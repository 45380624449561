export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    desktop: 60,
    tablet: 60,
    mobile: 55
  },
  outline: [
    [
      {
        desktop: {
          x: 379,
          y: 313
        },
        tablet: {
          x: 257.3,
          y: 90.57
        },
        mobile: {
          x: 178.5,
          y: 63.43
        }
      },
      {
        desktop: {
          x: 379.28,
          y: 97.64
        },
        tablet: {
          x: 369.28,
          y: 90.57
        },
        mobile: {
          x: 263.85,
          y: 63.43
        }
      },
      {
        desktop: {
          x: 253.3,
          y: 97.64
        },
        tablet: {
          x: 369,
          y: 282.85
        },
        mobile: {
          x: 264,
          y: 207
        }
      },
      {
        desktop: {
          x: 208,
          y: 143
        },
        tablet: {
          x: 328.77,
          y: 322.99
        },
        mobile: {
          x: 234.15,
          y: 236.85
        }
      },
      {
        desktop: {
          x: 207.64,
          y: 358.28
        },
        tablet: {
          x: 216.57,
          y: 322.99
        },
        mobile: {
          x: 149.43,
          y: 236.85
        }
      },
      {
        desktop: {
          x: 334,
          y: 358.28
        },
        tablet: {
          x: 217,
          y: 130.85
        },
        mobile: {
          x: 149,
          y: 93
        }
      }
    ],
    [
      {
        desktop: {
          x: 289.32,
          y: 312.68
        },
        tablet: {
          x: 257,
          y: 170.85
        },
        mobile: {
          x: 179,
          y: 123
        }
      },
      {
        desktop: {
          x: 253,
          y: 276.36
        },
        tablet: {
          x: 257,
          y: 250.28
        },
        mobile: {
          x: 179,
          y: 181.57
        }
      },
      {
        desktop: {
          x: 253,
          y: 188
        },
        tablet: {
          x: 289.28,
          y: 282.57
        },
        mobile: {
          x: 204.7,
          y: 207.3
        }
      },
      {
        desktop: {
          x: 297.68,
          y: 143.32
        },
        tablet: {
          x: 329,
          y: 242.85
        },
        mobile: {
          x: 234,
          y: 178
        }
      },
      {
        desktop: {
          x: 334,
          y: 179.64
        },
        tablet: {
          x: 329,
          y: 163.42
        },
        mobile: {
          x: 234,
          y: 118.43
        }
      },
      {
        desktop: {
          x: 334,
          y: 268
        },
        tablet: {
          x: 296.72,
          y: 131.14
        },
        mobile: {
          x: 208.79,
          y: 93.21
        }
      }
    ]
  ],
  outlines: [
    {
      desktop: {
        x: 208,
        y: 143,
        rotation: 0
      },
      tablet: {
        x: 217,
        y: 130.85,
        rotation: 0
      },
      mobile: {
        x: 149,
        y: 93,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 252,
        y: 97.64,
        rotation: 45
      },
      tablet: {
        x: 256.15,
        y: 90.57,
        rotation: 45
      },
      mobile: {
        x: 179,
        y: 63.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 334.92,
        y: 358.28,
        rotation: -135
      },
      tablet: {
        x: 329.71,
        y: 322.99,
        rotation: -135
      },
      mobile: {
        x: 234.28,
        y: 236.85,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 289,
        y: 223,
        rotation: 0
      },
      tablet: {
        x: 289,
        y: 202.85,
        rotation: 0
      },
      mobile: {
        x: 204,
        y: 148,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 289,
        y: 358,
        rotation: 90
      },
      tablet: {
        x: 289,
        y: 322.85,
        rotation: 90
      },
      mobile: {
        x: 204,
        y: 237,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 379,
        y: 269,
        rotation: -180
      },
      tablet: {
        x: 369,
        y: 242.85,
        rotation: 180
      },
      mobile: {
        x: 264,
        y: 178,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 208,
        y: 98,
        rotation: 0
      },
      tablet: {
        x: 217,
        y: 90.85,
        rotation: 0
      },
      mobile: {
        x: 149,
        y: 63,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
