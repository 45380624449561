export default [
  {
    src: require(`~/static/trainers/spatial/level1/1.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level1/2.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/3.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/4.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/5.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/6.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level1/7.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level1/8.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/9.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level1/10.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level1/11.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level1/12.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/13.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/14.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level1/15.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/16.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level1/17.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level1/18.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/19.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/20.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level1/21.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/22.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level1/23.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/24.png`),
    answer: 4
  },
  {
    src: require(`~/static/trainers/spatial/level1/25.png`),
    answer: 4
  },
  {
    src: require(`~/static/trainers/spatial/level1/26.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level1/27.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level1/28.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/29.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/30.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level1/31.png`),
    answer: 5
  }
];
