export default {
  shapes: [7, 6, 4, 2, 5, 3, 1],
  offset: {
    mobile: 15
  },
  outlines: [
    {
      id: 7,
      rotation: 90,
      desktop: {
        x: 474,
        y: 257
      },
      tablet: {
        x: 441,
        y: 229
      },
      mobile: {
        x: 319,
        y: 171
      }
    },
    {
      id: 6,
      rotation: 270,
      desktop: {
        x: 204,
        y: 347
      },
      tablet: {
        x: 201,
        y: 309
      },
      mobile: {
        x: 139,
        y: 231
      }
    },
    {
      id: 4,
      rotation: 315,
      desktop: {
        x: 175,
        y: 182.82
      },
      tablet: {
        x: 174,
        y: 161.28
      },
      mobile: {
        x: 117,
        y: 120.21
      }
    },
    {
      id: 2,
      rotation: 0,
      desktop: {
        x: 162,
        y: 151
      },
      tablet: {
        x: 163,
        y: 133
      },
      mobile: {
        x: 109,
        y: 99
      }
    },
    {
      id: 5,
      rotation: 45,
      desktop: {
        x: 204.64,
        y: 220
      },
      tablet: {
        x: 201.57,
        y: 196
      },
      mobile: {
        x: 139.43,
        y: 146
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 253,
        y: 239
      },
      tablet: {
        x: 243,
        y: 213
      },
      mobile: {
        x: 169,
        y: 159
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 144,
        y: 120.64
      },
      tablet: {
        x: 145,
        y: 105.57
      },
      mobile: {
        x: 96,
        y: 78.43
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 175.82,
        y: 88.82
      },
      tablet: {
        x: 173.28,
        y: 77.28
      },
      mobile: {
        x: 117.21000000000001,
        y: 57.21
      }
    },
    {
      desktop: {
        x: 175.82,
        y: 152.45999999999998
      },
      tablet: {
        x: 173.28,
        y: 133.85
      },
      mobile: {
        x: 159.64,
        y: 57.21
      }
    },
    {
      desktop: {
        x: 206.51,
        y: 152.45999999999998
      },
      tablet: {
        x: 202.15,
        y: 133.85
      },
      mobile: {
        x: 159.64,
        y: 99.64
      }
    },
    {
      desktop: {
        x: 162,
        y: 196
      },
      tablet: {
        x: 163,
        y: 173
      },
      mobile: {
        x: 139,
        y: 99.64
      }
    },
    {
      desktop: {
        x: 162,
        y: 283.64
      },
      tablet: {
        x: 163,
        y: 252.56
      },
      mobile: {
        x: 180.64,
        y: 141.42
      }
    },
    {
      desktop: {
        x: 141,
        y: 283.64
      },
      tablet: {
        x: 145,
        y: 252.56
      },
      mobile: {
        x: 139,
        y: 141.42
      }
    },
    {
      desktop: {
        x: 204.36,
        y: 347
      },
      tablet: {
        x: 201.43,
        y: 309
      },
      mobile: {
        x: 139,
        y: 159
      }
    },
    {
      desktop: {
        x: 384,
        y: 347
      },
      tablet: {
        x: 361,
        y: 309
      },
      mobile: {
        x: 168.43,
        y: 188.42000000000002
      }
    },
    {
      desktop: {
        x: 474,
        y: 257
      },
      tablet: {
        x: 441,
        y: 229
      },
      mobile: {
        x: 181.57,
        y: 188.42000000000002
      }
    },
    {
      desktop: {
        x: 294,
        y: 257
      },
      tablet: {
        x: 281,
        y: 229
      },
      mobile: {
        x: 199,
        y: 171
      }
    },
    {
      desktop: {
        x: 267.36,
        y: 283.64
      },
      tablet: {
        x: 257.43,
        y: 252.56
      },
      mobile: {
        x: 319,
        y: 171
      }
    },
    {
      desktop: {
        x: 252.64,
        y: 283.64
      },
      tablet: {
        x: 242.57,
        y: 252.56
      },
      mobile: {
        x: 259,
        y: 231
      }
    },
    {
      desktop: {
        x: 208,
        y: 239.25
      },
      tablet: {
        x: 203,
        y: 213
      },
      mobile: {
        x: 139.6,
        y: 231
      }
    },
    {
      desktop: {
        x: 208,
        y: 214.81
      },
      tablet: {
        x: 203,
        y: 189.56
      },
      mobile: {
        x: 97,
        y: 188.42000000000002
      }
    },
    {
      desktop: {
        x: 270.46000000000004,
        y: 214.64
      },
      tablet: {
        x: 258.85,
        y: 189.56
      },
      mobile: {
        x: 109,
        y: 188.42000000000002
      }
    },
    {
      desktop: {
        x: 208.28,
        y: 152.45999999999998
      },
      tablet: {
        x: 203.14,
        y: 133.85
      },
      mobile: {
        x: 109,
        y: 129
      }
    },
    {
      desktop: {
        x: 239.45999999999998,
        y: 152.45999999999998
      },
      tablet: {
        x: 229.85,
        y: 133.85
      },
      mobile: {
        x: 138.21,
        y: 99.64
      }
    },
    {
      desktop: {
        x: 239.45999999999998,
        y: 88.82
      },
      tablet: {
        x: 229.85,
        y: 77.28
      },
      mobile: {
        x: 117.21000000000001,
        y: 99.64
      }
    }
  ]
};
