import React from 'react';
import { connect } from 'react-redux';

import Button from '~/components/Button/Button';

import * as pageActions from '~/store/page/actions';

const GreatButtonPopup = (props) => {
  const { text, onClick, className, popPopup } = props;
  const handlerClick = () => {
    popPopup();
    if (onClick) {
      onClick();
    }
  };

  return (
  <Button theme="light-blue" className={ className } onClick={ handlerClick }>
    { text }
  </Button>
  );
};

const mapDispatchToProps = {
  popPopup: pageActions.popPopup
};

export default connect(null, mapDispatchToProps)(GreatButtonPopup);
