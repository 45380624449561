export default {
  shapes: [5, 7, 6, 2, 1, 3, 4],
  offset: {
    mobile: 10,
    tablet: 20
  },
  outlines: [
    {
      id: 5,
      rotation: 90,
      desktop: {
        x: 339.73,
        y: 114.6
      },
      tablet: {
        x: 322.43,
        y: 118
      },
      mobile: {
        x: 230,
        y: 87.43
      }
    },
    {
      id: 7,
      rotation: 315,
      desktop: {
        x: 249.73,
        y: 114.64
      },
      tablet: {
        x: 242.43,
        y: 118
      },
      mobile: {
        x: 170,
        y: 87.43
      }
    },
    {
      id: 6,
      rotation: 0,
      desktop: {
        x: 376.73,
        y: 114.5
      },
      tablet: {
        x: 355.43,
        y: 118
      },
      mobile: {
        x: 254.8,
        y: 87.5
      }
    },
    {
      id: 2,
      rotation: 225,
      desktop: {
        x: 218.26,
        y: 298.99
      },
      tablet: {
        x: 214.71,
        y: 282.14
      },
      mobile: {
        x: 149.21,
        y: 210.28
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 154.73,
        y: 146.54
      },
      tablet: {
        x: 158.43,
        y: 146.26
      },
      mobile: {
        x: 107,
        y: 108.65
      }
    },
    {
      id: 3,
      rotation: 225,
      desktop: {
        x: 154.82,
        y: 210.23
      },
      tablet: {
        x: 158.5,
        y: 202.8
      },
      mobile: {
        x: 107.21,
        y: 151.07
      }
    },
    {
      id: 4,
      rotation: 225,
      desktop: {
        x: 373.55,
        y: 360
      },
      tablet: {
        x: 355.71,
        y: 332.85
      },
      mobile: {
        x: 257.21,
        y: 246.07
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 405.37,
        y: 265.86
      },
      tablet: {
        x: 130.22,
        y: 174.52
      },
      mobile: {
        x: 254.75,
        y: 87.44
      }
    },
    {
      desktop: {
        x: 466.73,
        y: 204.5
      },
      tablet: {
        x: 186.75,
        y: 117.98
      },
      mobile: {
        x: 314.8,
        y: 147.5
      }
    },
    {
      desktop: {
        x: 376.87,
        y: 114.64
      },
      tablet: {
        x: 355.40999999999997,
        y: 117.98
      },
      mobile: {
        x: 278.43,
        y: 183.87
      }
    },
    {
      desktop: {
        x: 186.77,
        y: 114.66
      },
      tablet: {
        x: 435.42999999999995,
        y: 198
      },
      mobile: {
        x: 278.43,
        y: 224.85
      }
    },
    {
      desktop: {
        x: 123,
        y: 178.48000000000002
      },
      tablet: {
        x: 384,
        y: 249.44
      },
      mobile: {
        x: 236,
        y: 224.85
      }
    },
    {
      desktop: {
        x: 249.73000000000002,
        y: 178.3
      },
      tablet: {
        x: 384,
        y: 304.57
      },
      mobile: {
        x: 254.8,
        y: 206.05
      }
    },
    {
      desktop: {
        x: 249.73000000000002,
        y: 203.87
      },
      tablet: {
        x: 327.43,
        y: 304.57
      },
      mobile: {
        x: 254.8,
        y: 172.23000000000002
      }
    },
    {
      desktop: {
        x: 186.44,
        y: 267.17
      },
      tablet: {
        x: 355.43,
        y: 276.57
      },
      mobile: {
        x: 230,
        y: 147.43
      }
    },
    {
      desktop: {
        x: 250.07999999999998,
        y: 267.17
      },
      tablet: {
        x: 355.43,
        y: 231
      },
      mobile: {
        x: 212.07,
        y: 147.43
      }
    },
    {
      desktop: {
        x: 312.64,
        y: 204.6
      },
      tablet: {
        x: 322.43,
        y: 198
      },
      mobile: {
        x: 170.43,
        y: 189.07
      }
    },
    {
      desktop: {
        x: 339.69,
        y: 204.6
      },
      tablet: {
        x: 298.85,
        y: 198
      },
      mobile: {
        x: 128,
        y: 189.07
      }
    },
    {
      desktop: {
        x: 376.73,
        y: 241.64
      },
      tablet: {
        x: 243,
        y: 253.86
      },
      mobile: {
        x: 170,
        y: 147.07
      }
    },
    {
      desktop: {
        x: 376.73,
        y: 293.18
      },
      tablet: {
        x: 186.43,
        y: 253.86
      },
      mobile: {
        x: 170,
        y: 129.87
      }
    },
    {
      desktop: {
        x: 341.73,
        y: 328.18
      },
      tablet: {
        x: 242.43,
        y: 197.86
      },
      mobile: {
        x: 86,
        y: 129.85
      }
    },
    {
      desktop: {
        x: 405.55,
        y: 328
      },
      tablet: {
        x: 242.43,
        y: 174.55
      },
      mobile: {
        x: 128.43,
        y: 87.44
      }
    }
  ]
};
