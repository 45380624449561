export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 324,
        y: 88
      },
      tablet: {
        x: 395.57,
        y: 83
      },
      mobile: {
        x: 223,
        y: 58
      }
    },
    {
      desktop: {
        x: 368.82,
        y: 132.82
      },
      tablet: {
        x: 355.78,
        y: 122.78
      },
      mobile: {
        x: 252.71,
        y: 87.71
      }
    },
    {
      desktop: {
        x: 405.64,
        y: 96
      },
      tablet: {
        x: 316,
        y: 83
      },
      mobile: {
        x: 277.43,
        y: 63
      }
    },
    {
      desktop: {
        x: 469.28,
        y: 159.64
      },
      tablet: {
        x: 164,
        y: 235.14
      },
      mobile: {
        x: 319.85,
        y: 105.43
      }
    },
    {
      desktop: {
        x: 342.36,
        y: 159.64
      },
      tablet: {
        x: 189.72,
        y: 235.14
      },
      mobile: {
        x: 235.57,
        y: 105.43
      }
    },
    {
      desktop: {
        x: 324.91,
        y: 177.09
      },
      tablet: {
        x: 134,
        y: 290.85
      },
      mobile: {
        x: 223.61,
        y: 117.39
      }
    },
    {
      desktop: {
        x: 343.5,
        y: 195.64
      },
      tablet: {
        x: 190.57,
        y: 290.85
      },
      mobile: {
        x: 234.64,
        y: 128.43
      }
    },
    {
      desktop: {
        x: 406.92,
        y: 195.64
      },
      tablet: {
        x: 246.28,
        y: 235.14
      },
      mobile: {
        x: 278.28,
        y: 128.43
      }
    },
    {
      desktop: {
        x: 281.15,
        y: 321.4
      },
      tablet: {
        x: 276.57,
        y: 235.14
      },
      mobile: {
        x: 194.35,
        y: 212.35
      }
    },
    {
      desktop: {
        x: 326,
        y: 366
      },
      tablet: {
        x: 276.57,
        y: 290.43
      },
      mobile: {
        x: 224,
        y: 242
      }
    },
    {
      desktop: {
        x: 236,
        y: 366
      },
      tablet: {
        x: 237,
        y: 330
      },
      mobile: {
        x: 164,
        y: 242
      }
    },
    {
      desktop: {
        x: 279.64,
        y: 322.12
      },
      tablet: {
        x: 317,
        y: 330
      },
      mobile: {
        x: 193.43,
        y: 212.57
      }
    },
    {
      desktop: {
        x: 279.64,
        y: 259.28
      },
      tablet: {
        x: 277.14,
        y: 290.14
      },
      mobile: {
        x: 193.43,
        y: 171.85
      }
    },
    {
      desktop: {
        x: 242.82,
        y: 259.28
      },
      tablet: {
        x: 389.71,
        y: 177.57
      },
      mobile: {
        x: 179.21,
        y: 171.85
      }
    },
    {
      desktop: {
        x: 180.35,
        y: 321.75
      },
      tablet: {
        x: 331.85,
        y: 177.57
      },
      mobile: {
        x: 137.43,
        y: 213.64
      }
    },
    {
      desktop: {
        x: 116,
        y: 322.46
      },
      tablet: {
        x: 316.64,
        y: 162.36
      },
      mobile: {
        x: 95,
        y: 213.64
      }
    },
    {
      desktop: {
        x: 179.18,
        y: 259.28
      },
      tablet: {
        x: 339.43,
        y: 139.57
      },
      mobile: {
        x: 136.79,
        y: 171.85
      }
    },
    {
      desktop: {
        x: 153,
        y: 259.28
      },
      tablet: {
        x: 452.14,
        y: 139.57
      },
      mobile: {
        x: 109,
        y: 171.85
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 279.64,
        y: 322.92,
        rotation: 135
      },
      tablet: {
        x: 276.57,
        y: 290.71,
        rotation: 135
      },
      mobile: {
        x: 193.43,
        y: 213.28,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 280.28,
        y: 132,
        rotation: -45
      },
      tablet: {
        x: 277.14,
        y: 122,
        rotation: -45
      },
      mobile: {
        x: 193.85,
        y: 87,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 405.64,
        y: 223.28,
        rotation: 135
      },
      tablet: {
        x: 395.57,
        y: 196.14,
        rotation: 135
      },
      mobile: {
        x: 277.43,
        y: 147.85,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 326,
        y: 321,
        rotation: -90
      },
      tablet: {
        x: 317,
        y: 290,
        rotation: -90
      },
      mobile: {
        x: 224,
        y: 212,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 248,
        y: 163.82,
        rotation: 45
      },
      tablet: {
        x: 248,
        y: 150.28,
        rotation: 45
      },
      mobile: {
        x: 172,
        y: 108.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 147.82,
        y: 354.28,
        rotation: 135
      },
      tablet: {
        x: 162.28,
        y: 319.14,
        rotation: 135
      },
      mobile: {
        x: 116.21,
        y: 234.85,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 279,
        y: 88,
        rotation: 0
      },
      tablet: {
        x: 276,
        y: 83,
        rotation: 0
      },
      mobile: {
        x: 193,
        y: 58,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
