import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import navList from '~/services/get-nav-list';

import './navigation.scss';

const Navigation = (props) => {
  const { theme, isHeader, onRequestClose } = props;
  let navigationClass = cx('navigation', {
    'navigation_header': isHeader,
    [`navigation_${ theme }`]: theme
  })

  return (
    <nav className={navigationClass}>
      <ul className="navigation__list">
        { renderNavList(onRequestClose) }
      </ul>
    </nav>
  );
}

const renderNavList = (onRequestClose) => {
  return (
    navList.map((item, index) => {
      const { isHide, link, name } = item;
      const classes = cx('navigation__item', {
        'navigation__item_mobile': isHide
      });
      
      return (
        <li className={ classes } key={ index } onClick={ onRequestClose }>
          <Link className="navigation__link" to={ link }>
            { name }
          </Link>
        </li>
      );
    })
  );
};

export default Navigation;
