import { animateScroll } from 'react-scroll';

const PADDING_TOP = 40;

export default (ref) => {
  if (ref) {
    animateScroll.scrollTo(ref.current.offsetTop - PADDING_TOP);
  } else {
    animateScroll.scrollToTop();
  }
};
