import figure82 from '../figures/figure82';
import figure85 from '../figures/figure85';
import figure79 from '../figures/figure79';
import figure91 from '../figures/figure91';
import figure94 from '../figures/figure94';
import figure88 from '../figures/figure88';
import figure101 from '../figures/figure101';
import figure104 from '../figures/figure104';
import figure98 from '../figures/figure98';
import figure110 from '../figures/figure110';
import figure107 from '../figures/figure107';
import figure97 from '../figures/figure97';
import figure113 from '../figures/figure113';
import figure119 from '../figures/figure119';
import figure122 from '../figures/figure122';
import figure116 from '../figures/figure116';
import figure31 from '../figures/figure31';
import figure32 from '../figures/figure32';
import figure33 from '../figures/figure33';
import figure34 from '../figures/figure34';
import figure35 from '../figures/figure35';
import figure36 from '../figures/figure36';

export default [
  figure82,
  figure85,
  figure79,
  figure91,
  figure94,
  figure88,
  figure101,
  figure104,
  figure98,
  figure110,
  figure107,
  figure97,
  figure113,
  figure119,
  figure122,
  figure116,
  figure31,
  figure32,
  figure33,
  figure34,
  figure35,
  figure36
];
