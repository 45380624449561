import useragent from 'express-useragent';

export default () => {
  let isMobile = false;
  let isTablet = false;

  try {
    const { userAgent } = window.navigator;
    const ua = useragent.parse(userAgent);
    ({ isMobile, isTablet } = ua);
  } catch (err) {
    console.log(err);
  }

  return { isMobile, isTablet, width: document.documentElement.clientWidth };
};
