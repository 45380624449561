import React from 'react';
import Swiper from 'react-id-swiper';
import SwiperArrow from '~/components/SwiperArrow/SwiperArrow'
import classNames from 'classnames'

import '~/static/styles/swiper.min.css'
import './missionCardSlider.scss';

const MissionCardSlider = ({ locked, id, imgs }) => {
  const renderArrows = (way) => {
    return <button className={`swiper-button-${way} swiper-button-${way}-${id}`}><SwiperArrow color="#C8D3EA" /></button>
  }

  const params = {
    containerClass: `mission-card-slider__swiper`,
    slidesPerView: '1',
    effect: 'fade',
    allowTouchMove: false,
    navigation: {
      nextEl: `.swiper-button-next-${id}`,
      prevEl: `.swiper-button-prev-${id}`,
    },
    renderPrevButton: () => !locked && imgs.lenght > 1 ? renderArrows('prev') : null,
    renderNextButton: () => !locked && imgs.lenght > 1 ? renderArrows('next') : null,
  }

  let missionCardSlider = classNames('mission-card-slider', {
    'mission-card-slider_locked': locked
  })

  const renderImg = (img, index) => (
    <div key={ index } className="mission-card-slider__slide">
      <img className="mission-card-slider__img" src={ img } alt="mission-view" />
    </div>
  )

  return (
    <div className={ missionCardSlider }>
      <Swiper { ...params }>
        {
          imgs.map((item, index) => renderImg(item, index))
        }
      </Swiper>

      <div className="mission-card-slider__back"></div>

      {
        locked ?
        <div className="mission-card-slider__img-filter"></div>
        : null
      }
    </div>
  );
}

export default MissionCardSlider;
