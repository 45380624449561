export default {
  shapes: [ 8, 7, 1, 6, 3, 5, 4 ],
  offset: {
    desktop: 10,
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 217, y: 75.18 },
      tablet: { x: 225, y: 71 },
      mobile: { x: 155, y: 48 },
    },
    {
      desktop: { x: 261, y: 119.18 },
      tablet: { x: 265, y: 111 },
      mobile: { x: 185, y: 77.14 },
    },
    {
      desktop: { x: 261, y: 76.18 },
      tablet: { x: 265, y: 71 },
      mobile: { x: 185, y: 48 },
    },
    {
      desktop: { x: 284.82, y: 100 },
      tablet: { x: 280.28, y: 86.29 },
      mobile: { x: 197.21, y: 60.21 },
    },
    {
      desktop: { x: 284.82, y: 37 },
      tablet: { x: 280.28, y: 32.28 },
      mobile: { x: 197.21, y: 19.21 },
    },
    {
      desktop: { x: 348.46, y: 37 },
      tablet: { x: 336.85, y: 32.28 },
      mobile: { x: 239.64, y: 19.21 },
    },
    {
      desktop: { x: 348.46, y: 100.64 },
      tablet: { x: 336.85, y: 88.85 },
      mobile: { x: 239.64, y: 61.64 },
    },
    {
      desktop: { x: 285.46, y: 100.64 },
      tablet: { x: 282.85, y: 88.85 },
      mobile: { x: 198.64, y: 61.64 },
    },
    {
      desktop: { x: 350.64, y: 165.82 },
      tablet: { x: 344.07, y: 150.07 },
      mobile: { x: 245, y: 108 },
    },
    {
      desktop: { x: 351.28, y: 165.18 },
      tablet: { x: 345.14, y: 149 },
      mobile: { x: 244.85, y: 108.15 },
    },
    {
      desktop: { x: 351.28, y: 292.46 },
      tablet: { x: 345.14, y: 262.14 },
      mobile: { x: 244.85, y: 192.85 },
    },
    {
      desktop: { x: 350.72, y: 292.46 },
      tablet: { x: 281.57, y: 325.43 },
      mobile: { x: 244.15, y: 192.85 },
    },
    {
      desktop: { x: 286.64, y: 356.54 },
      tablet: { x: 281.57, y: 325.43 },
      mobile: { x: 198.43, y: 238.57 },
    },
    {
      desktop: { x: 286.82, y: 419.64 },
      tablet: { x: 281.57, y: 381.57 },
      mobile: { x: 198.43, y: 280.43 },
    },
    {
      desktop: { x: 223, y: 419.82 },
      tablet: { x: 225, y: 381.57 },
      mobile: { x: 156, y: 280.43 },
    },
    {
      desktop: { x: 261, y: 381.82 },
      tablet: { x: 265, y: 341.57 },
      mobile: { x: 185, y: 251.43 },
    },
    {
      desktop: { x: 261, y: 292.46 },
      tablet: { x: 265, y: 262.14 },
      mobile: { x: 185, y: 193 },
    },
    {
      desktop: { x: 260.72, y: 292.46 },
      tablet: { x: 264.86, y: 262.14 },
      mobile: { x: 185, y: 193 },
    },
    {
      desktop: { x: 216.25, y: 337.18 },
      tablet: { x: 225, y: 302 },
      mobile: { x: 156, y: 222 },
    },
    {
      desktop: { x: 171, y: 292.18 },
      tablet: { x: 185, y: 262 },
      mobile: { x: 126, y: 192 },
    },
    {
      desktop: { x: 224.28, y: 292.18 },
      tablet: { x: 232.14, y: 262 },
      mobile: { x: 160.85, y: 192 },
    },
    {
      desktop: { x: 261, y: 255.46 },
      tablet: { x: 265, y: 229.14 },
      mobile: { x: 185, y: 167.85 },
    },
    {
      desktop: { x: 261, y: 209.18 },
      tablet: { x: 265, y: 191 },
      mobile: { x: 185, y: 138 },
    },
    {
      desktop: { x: 217, y: 165.18 },
      tablet: { x: 225, y: 151 },
      mobile: { x: 155, y: 108 },
    }
  ],
  outlines: [
    {
      desktop: { x: 217, y: 75 },
      tablet: { x: 225, y: 71 },
      mobile: { x: 155, y: 48 },
      rotation: 0
    },
    {
      desktop: { x: 261, y: 76 },
      tablet: { x: 265 , y: 71 },
      mobile: { x: 185, y: 48 },
      rotation: 0
    },
    {
      desktop: { x: 253, y: 68.64 },
      tablet: { x: 252 , y: 60.57 },
      mobile: { x: 176, y: 40.43 },
      rotation: 315
    },
    {
      desktop: { x: 351.28, y: 165 },
      tablet: { x: 345.14 , y: 149 },
      mobile: { x: 244.85, y: 108 },
      rotation: 45
    },
    {
      desktop: { x: 171, y: 337 },
      tablet: { x: 185 , y: 302 },
      mobile: { x: 126, y: 222 },
      rotation: 270
    },
    {
      desktop: { x: 351, y: 382 },
      tablet: { x: 345 , y: 342 },
      mobile: { x: 245, y: 252 },
      rotation: 180
    },
    {
      desktop: { x: 254.82, y: 451.46 },
      tablet: { x: 253.28 , y: 409.85 },
      mobile: { x: 177.21, y: 301.64 },
      rotation: 225
    }
  ]
}
