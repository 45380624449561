import levels from './levels';
import texts from './texts';
import levelsInMission from './levelsInMission';

import getContent from './services/getContent';

export default {
  id: 5,
  type: 'memo',
  eventCategory: 'Memo',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Запомнить всё с одного взгляда – к этому стремятся все спецагенты. Тренируй этот навык и ты!',
  previewUrl: require('~/static/image/trainerPreviews/memo.png'),
  params: {
    ...texts,
    name: 'Карточки МЕМО',
    hasTimer: true,
    matrixImages: getContent(),
    maxSkills: {
      focus: 2,
      memory: 1,
    },
    skills: [ 'focus', 'memory' ],
    mainSkill: 'focus',
    levels,
    levelsInMission
  }
}
