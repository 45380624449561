import React from 'react';
import Page from '~/containers/Page/Page';

import text from './text';

import './policyPage.scss';

const PolicyPage = () => {
  return (
    <Page>
      <div className="policy-page">
        <h1 className="policy-page__title">
          Политика конфиденциальности
        </h1>

        <div className="policy-page__holder">
          <p className="policy-page__text" dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
      </div>
    </Page>
  );
}

export default PolicyPage;
