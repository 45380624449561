export default {
  shapes: [4, 5, 1, 7, 3, 6, 8],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 30
  },
  outlines: [
    {
      id: 4,
      rotation: 0,
      desktop: {
        x: 126,
        y: 266
      },
      tablet: {
        x: 156,
        y: 265
      },
      mobile: {
        x: 87,
        y: 183
      }
    },
    {
      id: 5,
      rotation: 0,
      desktop: {
        x: 295,
        y: 178
      },
      tablet: {
        x: 307,
        y: 186
      },
      mobile: {
        x: 199,
        y: 124
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 352,
        y: 146.64
      },
      tablet: {
        x: 358,
        y: 158.57
      },
      mobile: {
        x: 237,
        y: 104.43
      }
    },
    {
      id: 7,
      rotation: 135,
      desktop: {
        x: 296.92,
        y: 268.28
      },
      tablet: {
        x: 308.85,
        y: 266
      },
      mobile: {
        x: 201.28,
        y: 184
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 385,
        y: 71
      },
      tablet: {
        x: 387,
        y: 91
      },
      mobile: {
        x: 259,
        y: 54
      }
    },
    {
      id: 6,
      rotation: 90,
      desktop: {
        x: 386,
        y: 178
      },
      tablet: {
        x: 388,
        y: 186
      },
      mobile: {
        x: 260,
        y: 124
      }
    },
    {
      id: 8,
      rotation: 315,
      desktop: {
        x: 321,
        y: 266.82
      },
      tablet: {
        x: 330,
        y: 265.28
      },
      mobile: {
        x: 216,
        y: 183.21
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 383.82,
        y: 114.83
      },
      tablet: {
        x: 442.85,
        y: 130.28
      },
      mobile: {
        x: 229,
        y: 54
      }
    },
    {
      desktop: {
        x: 447.46,
        y: 114.82
      },
      tablet: {
        x: 386.28,
        y: 130.28
      },
      mobile: {
        x: 199,
        y: 84
      }
    },
    {
      desktop: {
        x: 447.46,
        y: 178.46
      },
      tablet: {
        x: 347,
        y: 91
      },
      mobile: {
        x: 258.21,
        y: 83.21
      }
    },
    {
      desktop: {
        x: 385,
        y: 178.46
      },
      tablet: {
        x: 307,
        y: 131
      },
      mobile: {
        x: 300.64,
        y: 83.21
      }
    },
    {
      desktop: {
        x: 385,
        y: 267.18
      },
      tablet: {
        x: 386.28,
        y: 131
      },
      mobile: {
        x: 300.64,
        y: 125.64
      }
    },
    {
      desktop: {
        x: 448.28,
        y: 330.46
      },
      tablet: {
        x: 386.28,
        y: 186
      },
      mobile: {
        x: 259,
        y: 125.64
      }
    },
    {
      desktop: {
        x: 384.64,
        y: 330.46
      },
      tablet: {
        x: 228.85,
        y: 186
      },
      mobile: {
        x: 259,
        y: 183.79
      }
    },
    {
      desktop: {
        x: 322.18,
        y: 268
      },
      tablet: {
        x: 195.72,
        y: 152.86
      },
      mobile: {
        x: 300.85,
        y: 225.64
      }
    },
    {
      desktop: {
        x: 171,
        y: 268.28
      },
      tablet: {
        x: 195.72,
        y: 265.28
      },
      mobile: {
        x: 258.43,
        y: 225.64
      }
    },
    {
      desktop: {
        x: 171,
        y: 356
      },
      tablet: {
        x: 156,
        y: 305
      },
      mobile: {
        x: 216.79,
        y: 184
      }
    },
    {
      desktop: {
        x: 126,
        y: 311.25
      },
      tablet: {
        x: 196,
        y: 345
      },
      mobile: {
        x: 117,
        y: 184
      }
    },
    {
      desktop: {
        x: 169.64,
        y: 267.37
      },
      tablet: {
        x: 196,
        y: 266
      },
      mobile: {
        x: 117,
        y: 243
      }
    },
    {
      desktop: {
        x: 169.64,
        y: 141
      },
      tablet: {
        x: 330.72,
        y: 266
      },
      mobile: {
        x: 87,
        y: 213
      }
    },
    {
      desktop: {
        x: 206.64,
        y: 178
      },
      tablet: {
        x: 386.57,
        y: 321.85
      },
      mobile: {
        x: 116.43,
        y: 183.57
      }
    },
    {
      desktop: {
        x: 383.82,
        y: 178
      },
      tablet: {
        x: 443.14,
        y: 321.85
      },
      mobile: {
        x: 116.43,
        y: 99.15
      }
    },
    {
      desktop: {
        x: 383.82,
        y: 116
      },
      tablet: {
        x: 387,
        y: 265.72
      },
      mobile: {
        x: 141.28,
        y: 124
      }
    },
    {
      desktop: {
        x: 295,
        y: 116
      },
      tablet: {
        x: 387,
        y: 186.85
      },
      mobile: {
        x: 258.21,
        y: 124
      }
    },
    {
      desktop: {
        x: 339.75,
        y: 71
      },
      tablet: {
        x: 442.85,
        y: 186.85
      },
      mobile: {
        x: 258.21,
        y: 84
      }
    }
  ]
};
