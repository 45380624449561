import React, { useState } from 'react';
import cx from 'classnames';

import Button from '~/components/Button/Button';
import Icon from "~/components/Icon/Icon";
import Switcher from '../Switcher';

import { DESKTOP } from '../constants/screens';

/* eslint-disable-next-line */
import trash from './assets/trash.svg';
/* eslint-disable-next-line */
import hoverTrash from './assets/hoverTrash.svg';

import './tabNumber.scss';

const TabNumber = (props) => {
  const { cell, isEntry, switcher, pasteNumber, removeNumber, screenType } = props;
  const isTouchable = (('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch));
  const [isMouseDown, setMouseDown] = useState(false);
  const switcherNames = [
    'запись в ячейку',
    'заметки'
  ];
  let options;
  let notes = new Set(cell.notes);
  let value = cell.value;

  const getNewOptions = (options, index) => {
    if (index === undefined) {
      return cell.options.map(item => ({ ...item, ...options }));
    }
    return cell.options.map((item, itemIndex) => itemIndex === index ?
                                                 ({ ...item, ...options }) :
                                                 ({ ...item, selected: false }));
  }

  const removeCellOptions = (item, index) => {
    if (!isEntry) {
      notes.delete(item.value);
      options = cell.options.map((item, itemIndex) => itemIndex === index ?
                                                      ({ ...item, marked: false }) :
                                                      ({ ...item }));
    }
    else {
      value = null;
      options = getNewOptions({ selected: false }, index);
    }

    removeNumber({ ...cell, options, value, notes });
  }

  const setCellOptions = (item, index) => {
    if (!isEntry) {
      options = cell.options.map((item, itemIndex) => itemIndex === index ?
                                                      ({ ...item, marked: true, selected: false }) :
                                                      ({ ...item, selected: false }));
      notes.add(item.value);
      value = null;
    }
    else {
      options = cell.options.map((item, itemIndex) => itemIndex === index ?
                                                      ({ ...item, selected: true, marked: false }) :
                                                      ({ ...item, selected: false, marked: false }));
      notes = new Set();
      value = item.value;
    }

    pasteNumber({ ...cell, options, value, notes });
  }

  const resetCell = () => {
    if (!isEntry) {
      notes = new Set();
      options = getNewOptions({ marked: false });
    }
    else {
      options = getNewOptions({ selected: false });
      value = null;
    }

    removeNumber({ ...cell, options, value, notes });
  }

  const handlerClickCell = (item, index) => {
    if (item.active) {
      if ((item.selected && isEntry) || (item.marked && !isEntry)) {
        removeCellOptions(item, index);
      }
      else {
        setCellOptions(item, index);
      }
    }
  }

  const renderCells = () => {
    return cell.options.map((item, index) => {
      const className = cx("tab-number__cell-item", {
        "tab-number__cell-item_active": (isEntry && item.selected) || (!isEntry && item.marked),
        "tab-number__cell-item_deactive": !item.active
      });

      return (
        <div className={ className } key={ index } onClick={ () => handlerClickCell(item, index) }>
          { item.value }
        </div>
      );
    });
  }

  const renderTrashIcon = () => {
    const name = isMouseDown ? 'hoverTrash' : 'trash';
    const title = !isEntry ? 'Очистить заметки' : 'Очистить ячейку';

    return (
      <div className="tab-number__container-util" onMouseOver={ () => setMouseDown(true) }
           onMouseLeave={ () => setMouseDown(false) }>
        <Button className="tab-number__util" round={ true } onClick={ resetCell }>
          <Icon className="tab-number__util-icon" name={ name } />
        </Button>
        {
          isMouseDown &&
          !isTouchable &&
          screenType === DESKTOP &&
          <div className="tab-number__title">{ title }</div>
        }
      </div>
    );
  }

  return (
    <div className="tab-number">
      <div className="tab-number__title-container">
        <Switcher isActive={ isEntry } action={ switcher } data={ switcherNames } />
      </div>
      <div className="tab-number__content">
        <div className="tab-number__cell-container">
          { renderCells() }
          { renderTrashIcon() }
        </div>
      </div>
    </div>
  );
}

export default TabNumber;
