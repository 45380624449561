import React from 'react';
import { connect } from 'react-redux';

import Page from '~/containers/Page/Page';
import ProfilePoints from '~/components/ProfilePoints/ProfilePoints';
import ProfileSkills from '~/components/ProfileSkills/ProfileSkills';
import ProfileGifts from '~/components/ProfileGifts/ProfileGifts';
import ProfileAchievements from '~/components/ProfileAchievements/ProfileAchievements';

import { skillLevelNameSelector, levelMaxValueSelector,
         nextSkillLevelNameSelector, giftsStatusSelector } from '~/store/profile/selector';

import fox from './assets/fox.png'

import './profilePage.scss';

const ProfilePage = (props) => {
  const { profile, skillLevelName, levelMaxValue, nextSkillLevelName, giftsStatus } = props;
  const renderHeader = (name) => (
    <header className="profile-page__header">
      <h1 className="profile-page__title">
        Кабинет агента
      </h1>

      <div className="profile-page__name">
        { name }
      </div>
    </header>
  )

  const renderFox = () => (
    <div className="profile-page__fox">
      <img className="profile-page__fox-img" src={ fox } alt="fox" />
    </div>
  )

  const { name, gameLevel, points, skills, skillLevel } = profile;
  return (
    <Page>
      <div className="profile-page">
        <div className="profile-page__background"></div>

        <div className="profile-page__holder">
          { renderHeader(name) }

          <main className="profile-page__main">
            <div className="profile-page__box profile-page__game">
              <div className="profile-page__points">
                <ProfilePoints
                  gameLevel={ gameLevel }
                  points={ points }
                  skillLevelName={ skillLevelName }
                  maxPoints={ levelMaxValue }
                  skillLevel={ skillLevel }
                />
              </div>

              { renderFox() }
            </div>

            <div className="profile-page__box profile-page__skills">
              <ProfileSkills
                skills={ skills }
                skillLevelName={ skillLevelName }
                nextSkillLevelName={ nextSkillLevelName }
              />
            </div>

            <div className="profile-page__box profile-page__gifts">
              <ProfileGifts
                giftsStatus={ giftsStatus }
              />
            </div>

            <div className="profile-page__box profile-page__achievements">
              <ProfileAchievements />
            </div>
          </main>
        </div>
      </div>
    </Page>
  );
}

function mapStateToProps(state) {
  const { profile } = state;
  return {
    profile,
    skillLevelName: skillLevelNameSelector(state),
    levelMaxValue: levelMaxValueSelector(state),
    nextSkillLevelName: nextSkillLevelNameSelector(state),
    giftsStatus: giftsStatusSelector(state)
  };
}

export default connect(mapStateToProps)(ProfilePage);
