export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    desktop: 20,
    tablet: 20,
    mobile: 30
  },
  outline: [
    [
      {
        desktop: {
          x: 271.79,
          y: 86.53
        },
        tablet: {
          x: 331.14,
          y: 81.28
        },
        mobile: {
          x: 234.64,
          y: 56.21
        }
      },
      {
        desktop: {
          x: 336.15,
          y: 85.8
        },
        tablet: {
          x: 274.57,
          y: 81.28
        },
        mobile: {
          x: 192.43,
          y: 56.21
        }
      },
      {
        desktop: {
          x: 273.17,
          y: 148.77
        },
        tablet: {
          x: 218.07,
          y: 137.78
        },
        mobile: {
          x: 150.2,
          y: 98.45
        }
      },
      {
        desktop: {
          x: 335.67,
          y: 148.77
        },
        tablet: {
          x: 266.72,
          y: 186.43
        },
        mobile: {
          x: 186.89,
          y: 135.11
        }
      },
      {
        desktop: {
          x: 335.65,
          y: 86.35
        },
        tablet: {
          x: 227.28,
          y: 225.86
        },
        mobile: {
          x: 157.21,
          y: 164.79
        }
      },
      {
        desktop: {
          x: 399.28,
          y: 149.78
        },
        tablet: {
          x: 227.28,
          y: 281.43
        },
        mobile: {
          x: 157.21,
          y: 206.19
        }
      },
      {
        desktop: {
          x: 336.28,
          y: 212.78
        },
        tablet: {
          x: 307,
          y: 361.14
        },
        mobile: {
          x: 217,
          y: 266
        }
      },
      {
        desktop: {
          x: 336.28,
          y: 248.6
        },
        tablet: {
          x: 386.64,
          y: 281.5
        },
        mobile: {
          x: 276.61,
          y: 206.39
        }
      },
      {
        desktop: {
          x: 398.91,
          y: 311.23
        },
        tablet: {
          x: 331.14,
          y: 225.99
        },
        mobile: {
          x: 235.07,
          y: 164.85
        }
      },
      {
        desktop: {
          x: 309,
          y: 401.14
        },
        tablet: {
          x: 331.14,
          y: 193.71
        },
        mobile: {
          x: 235.07,
          y: 140.21
        }
      },
      {
        desktop: {
          x: 218.82,
          y: 310.95
        },
        tablet: {
          x: 387.14,
          y: 137.71
        },
        mobile: {
          x: 276.85,
          y: 98.43
        }
      },
      {
        desktop: {
          x: 218.82,
          y: 248.32
        },
        tablet: {
          x: 330.8,
          y: 81.65
        },
        mobile: {
          x: 234.4,
          y: 56.47
        }
      },
      {
        desktop: {
          x: 263.56,
          y: 203.33
        },
        tablet: {
          x: 330.5,
          y: 137.78
        },
        mobile: {
          x: 234.4,
          y: 98.43
        }
      },
      {
        desktop: {
          x: 209.27,
          y: 149.05
        },
        tablet: {
          x: 274.57,
          y: 137.78
        },
        mobile: {
          x: 192.43,
          y: 98.43
        }
      },
    ],
    [
      {
        desktop: {
          x: 282.46,
          y: 248.14
        },
        tablet: {
          x: 283.85,
          y: 226.14
        },
        mobile: {
          x: 199.64,
          y: 165
        }
      },
      {
        desktop: {
          x: 308.36,
          y: 248.14
        },
        tablet: {
          x: 306.43,
          y: 226.14
        },
        mobile: {
          x: 199.64,
          y: 206
        }
      },
      {
        desktop: {
          x: 335.9,
          y: 275.68
        },
        tablet: {
          x: 330.28,
          y: 249.99
        },
        mobile: {
          x: 234.21,
          y: 206
        }
      },
      {
        desktop: {
          x: 336,
          y: 311.14
        },
        tablet: {
          x: 330.28,
          y: 281.14
        },
        mobile: {
          x: 234.21,
          y: 182.43
        }
      },
      {
        desktop: {
          x: 282.46,
          y: 311.14
        },
        tablet: {
          x: 283.85,
          y: 281.14
        },
        mobile: {
          x: 216.79,
          y: 165
        }
      }
    ]
  ],
  outlines: [
    {
      desktop: {
        x: 399,
        y: 311.14,
        rotation: -90
      },
      tablet: {
        x: 387,
        y: 281.14,
        rotation: -90
      },
      mobile: {
        x: 277,
        y: 206,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 209,
        y: 148.78,
        rotation: 45
      },
      tablet: {
        x: 218,
        y: 137.71,
        rotation: 45
      },
      mobile: {
        x: 150.21,
        y: 98.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 272,
        y: 149.78,
        rotation: 45
      },
      tablet: {
        x: 274,
        y: 137.71,
        rotation: 45
      },
      mobile: {
        x: 192,
        y: 98.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 309,
        y: 203.14,
        rotation: -90
      },
      tablet: {
        x: 307,
        y: 186.14,
        rotation: -90
      },
      mobile: {
        x: 217,
        y: 135,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 304,
        y: 279.96,
        rotation: 45
      },
      tablet: {
        x: 302,
        y: 253.43,
        rotation: 45
      },
      mobile: {
        x: 213,
        y: 185.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 304.32,
        y: 54,
        rotation: -45
      },
      tablet: {
        x: 302.85,
        y: 53,
        rotation: -45
      },
      mobile: {
        x: 213.64,
        y: 35,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 187,
        y: 279.78,
        rotation: 45
      },
      tablet: {
        x: 199,
        y: 253.71,
        rotation: 45
      },
      mobile: {
        x: 136,
        y: 185.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
};
