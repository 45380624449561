export default {
  shapes: [ 5, 1, 2, 7, 6, 4, 3 ],
  offset: {
    mobile: 10
  },
  outline: [
    {
      desktop: { x: 222, y: 39.7 },
      tablet: { x: 230, y: 40.8 },
      mobile: { x: 159, y: 25 },
    },
    {
      desktop: { x: 158.3, y: 103.4 },
      tablet: { x: 173.5, y: 97.5 },
      mobile: { x: 116.6, y: 67.6 },
    },
    {
      desktop: { x: 221.8, y: 103.4 },
      tablet: { x: 229.3, y: 97.5 },
      mobile: { x: 158.7, y: 67.5 },
    },
    {
      desktop: { x: 221.8, y: 167 },
      tablet: { x: 229.3, y: 153.1 },
      mobile: { x: 158.7, y: 109.3 },
    },
    {
      desktop: { x: 158.3, y: 230.5 },
      tablet: { x: 173.2, y: 209.2 },
      mobile: { x: 117, y: 151 },
    },
    {
      desktop: { x: 248.2, y: 320.3 },
      tablet: { x: 253.7, y: 289 },
      mobile: { x: 176.8, y: 211 },
    },
    {
      desktop: { x: 184.5, y: 383.8 },
      tablet: { x: 197.2, y: 345.3 },
      mobile: { x: 134.4, y: 253.2 },
    },
    {
      desktop: { x: 248.2, y: 383.8 },
      tablet: { x: 253.7, y: 345.3 },
      mobile: { x: 176.8, y: 253.2 },
    },
    {
      desktop: { x: 248.2, y: 320.3 },
      tablet: { x: 253.7, y: 289 },
      mobile: { x: 176.8, y: 211 },
    },
    {
      desktop: { x: 339, y: 320 },
      tablet: { x: 333, y: 289 },
      mobile: { x: 237, y: 211 },
    },
    {
      desktop: { x: 339, y: 410 },
      tablet: { x: 333, y: 369 },
      mobile: { x: 237, y: 271 },
    },
    {
      desktop: { x: 384, y: 364.8 },
      tablet: { x: 373, y: 329 },
      mobile: { x: 267, y: 241 },
    },
    {
      desktop: { x: 339, y: 320 },
      tablet: { x: 333, y: 289 },
      mobile: { x: 237, y: 211 },
    },
    {
      desktop: { x: 428, y: 320 },
      tablet: { x: 413, y: 289 },
      mobile: { x: 297, y: 211 },
    },
    {
      desktop: { x: 338, y: 230 },
      tablet: { x: 333, y: 209 },
      mobile: { x: 237, y: 151 },
    },
    {
      desktop: { x: 222.5, y: 230 },
      tablet: { x: 230, y: 209 },
      mobile: { x: 159.4, y: 151 },
    },
    {
      desktop: { x: 285.5, y: 167 },
      tablet: { x: 285.8, y: 153.2 },
      mobile: { x: 201.2, y: 109.3 },
    },
    {
      desktop: { x: 285.5, y: 103.2 },
      tablet: { x: 285.8, y: 96.7},
      mobile: { x: 201.2, y: 67.4 },
    },
  ],
  outlines: [
    {
      desktop: { x: 222, y: 167 },
      tablet: { x: 230, y: 154 },
      mobile: { x: 159, y: 110 },
      rotation: 225
    },
    {
      desktop: { x: 190, y: 135 },
      tablet: { x: 201, y: 125 },
      mobile: { x: 138, y: 89 },
      rotation: 315
    },
    {
      desktop: { x: 190, y: 262 },
      tablet: { x: 201, y: 238 },
      mobile: { x: 138, y: 173 },
      rotation: 225
    },
    {
      desktop: { x: 338, y: 230 },
      tablet: { x: 333, y: 209 },
      mobile: { x: 237, y: 151 },
      rotation: 90
    },
    {
      desktop: { x: 248, y: 320 },
      tablet: { x: 253, y: 289 },
      mobile: { x: 177, y: 211 },
      rotation: 270
    },
    {
      desktop: { x: 216, y: 416 },
      tablet: { x: 225, y: 374 },
      mobile: { x: 156, y: 274 },
      rotation: 225
    },
    {
      desktop: { x: 384, y: 410 },
      tablet: { x: 373, y: 369 },
      mobile: { x: 267, y: 271 },
      rotation: 180
    }
  ]
}
