export default {
  texts: {
    description: [
      'Спецагент должен уметь разгадывать сложные коды и комбинации. Американская игра судоку тебе в этом поможет.',
      'Любой спецагент должен научиться доводить начатое дело до конца. Игра Судоку тебя научит не останавливаться, даже когда кажется, что вариантов решения нет.'
    ],
    losing: [
      'В следующий раз всё точно получится! Повтори попытку!',
      'Никогда не сдавайся! Тренируйся, и всё получится!'
    ],
    victory: [
      'Отличный результат! Можешь пробовать свои силы в миссии!',
      'Теперь ты сможешь взломать любую дверь с шифром! Ты готов. Мистер Фоксман готов поручить тебе миссию.'
    ]
  },
  textsInMission: {
    description: [
      'Тебе предстоит расшифровать очень сложный код. Будь внимателен – любая ошибка будет стоить очень дорого.',
      'Чтобы открыть тайную дверь, нужно расшифровать числовой код. Будь внимателен, иначе дверь заблокируется навсегда!'
    ],
    losing: [
      'Не беда, код был очень сложен. Мистер Фоксман ждёт тебя в тренажёрном зале, чтобы натренировать этот навык.',
      'Тебе чуть-чуть не хватило времени. Тренируйся, и всё обязательно получится!',
      'Ты расположил цифры неверно. Потренируй этот навык в тренажёрном зале!'
    ],
    victory: [
      'Ты уже занимался расшифровкой кодов? Да у тебя талант!',
      'Расшифровка кода прошла успешно! Теперь ты справишься с любым кодовым замком!',
      'Ты определил положение цифр абсолютно верно! Действуй дальше!',
      'Все цифры на своих местах! Отличный спецагент появился в нашей команде!'
    ]
  }
};
