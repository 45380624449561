export default {
  shapes: [4, 5, 1, 2, 7, 3, 6],
  offset: {
    mobile: 20,
    tablet: 20
  },
  outlines: [
    {
      id: 4,
      rotation: 135,
      desktop: {
        x: 171.46,
        y: 209.64
      },
      tablet: {
        x: 173.85,
        y: 185.57
      },
      mobile: {
        x: 112.64,
        y: 144.43
      }
    },
    {
      id: 5,
      rotation: 45,
      desktop: {
        x: 317.64,
        y: 186
      },
      tablet: {
        x: 303.72,
        y: 164
      },
      mobile: {
        x: 208.43,
        y: 126
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 343,
        y: 191.64
      },
      tablet: {
        x: 326,
        y: 169.87
      },
      mobile: {
        x: 225,
        y: 130.43
      }
    },
    {
      id: 2,
      rotation: 180,
      desktop: {
        x: 184,
        y: 313
      },
      tablet: {
        x: 185,
        y: 278
      },
      mobile: {
        x: 120,
        y: 211
      }
    },
    {
      id: 7,
      rotation: 270,
      desktop: {
        x: 139,
        y: 313
      },
      tablet: {
        x: 145,
        y: 278
      },
      mobile: {
        x: 90,
        y: 211
      }
    },
    {
      id: 3,
      rotation: 180,
      desktop: {
        x: 229,
        y: 179
      },
      tablet: {
        x: 225,
        y: 160
      },
      mobile: {
        x: 150,
        y: 123
      }
    },
    {
      id: 6,
      rotation: 270,
      desktop: {
        x: 317,
        y: 313
      },
      tablet: {
        x: 302,
        y: 278
      },
      mobile: {
        x: 207,
        y: 211
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 184,
        y: 178
      },
      tablet: {
        x: 381.83,
        y: 198.2
      },
      mobile: {
        x: 120,
        y: 181
      }
    },
    {
      desktop: {
        x: 184,
        y: 89
      },
      tablet: {
        x: 354.3,
        y: 198.2
      },
      mobile: {
        x: 150,
        y: 151
      }
    },
    {
      desktop: {
        x: 229,
        y: 133.8
      },
      tablet: {
        x: 354.3,
        y: 141.59
      },
      mobile: {
        x: 167.43,
        y: 168.43
      }
    },
    {
      desktop: {
        x: 184,
        y: 179
      },
      tablet: {
        x: 410.84,
        y: 141.59
      },
      mobile: {
        x: 249.57,
        y: 168.43
      }
    },
    {
      desktop: {
        x: 184,
        y: 268
      },
      tablet: {
        x: 410.84,
        y: 198.2
      },
      mobile: {
        x: 266.37,
        y: 151.63
      }
    },
    {
      desktop: {
        x: 229,
        y: 223
      },
      tablet: {
        x: 382.2,
        y: 198.2
      },
      mobile: {
        x: 246.21,
        y: 151.71
      }
    },
    {
      desktop: {
        x: 255.64,
        y: 249.64
      },
      tablet: {
        x: 462,
        y: 278
      },
      mobile: {
        x: 246.21,
        y: 109.29
      }
    },
    {
      desktop: {
        x: 380.36,
        y: 249.64
      },
      tablet: {
        x: 145,
        y: 278
      },
      mobile: {
        x: 288.64,
        y: 109.29
      }
    },
    {
      desktop: {
        x: 406.54,
        y: 223.46
      },
      tablet: {
        x: 145,
        y: 213.28
      },
      mobile: {
        x: 288.64,
        y: 151.71
      }
    },
    {
      desktop: {
        x: 374.82,
        y: 223.46
      },
      tablet: {
        x: 89,
        y: 157.28
      },
      mobile: {
        x: 267.62,
        y: 151.64
      }
    },
    {
      desktop: {
        x: 374.82,
        y: 159.82
      },
      tablet: {
        x: 145.57,
        y: 157.28
      },
      mobile: {
        x: 327,
        y: 211
      }
    },
    {
      desktop: {
        x: 438.46,
        y: 159.82
      },
      tablet: {
        x: 145.57,
        y: 197.43
      },
      mobile: {
        x: 90,
        y: 211
      }
    },
    {
      desktop: {
        x: 438.46,
        y: 223.46
      },
      tablet: {
        x: 185,
        y: 158
      },
      mobile: {
        x: 90,
        y: 164.21
      }
    },
    {
      desktop: {
        x: 407.46,
        y: 223.46
      },
      tablet: {
        x: 185,
        y: 80
      },
      mobile: {
        x: 49,
        y: 123.21
      }
    },
    {
      desktop: {
        x: 497,
        y: 313
      },
      tablet: {
        x: 225,
        y: 120
      },
      mobile: {
        x: 91.43,
        y: 123.21
      }
    },
    {
      desktop: {
        x: 139,
        y: 313
      },
      tablet: {
        x: 185,
        y: 160
      },
      mobile: {
        x: 91.43,
        y: 149.57
      }
    },
    {
      desktop: {
        x: 139,
        y: 240.82
      },
      tablet: {
        x: 185,
        y: 238
      },
      mobile: {
        x: 120,
        y: 121
      }
    },
    {
      desktop: {
        x: 76,
        y: 177.82
      },
      tablet: {
        x: 225,
        y: 198
      },
      mobile: {
        x: 120,
        y: 63
      }
    },
    {
      desktop: {
        x: 139.46,
        y: 177.64
      },
      tablet: {
        x: 247.57,
        y: 220.57
      },
      mobile: {
        x: 150,
        y: 93
      }
    },
    {
      desktop: {
        x: 139.59,
        y: 222.41
      },
      tablet: {
        x: 359.43,
        y: 220.57
      },
      mobile: {
        x: 120,
        y: 123
      }
    }
  ]
};
