export default {
  shapes: [ 3, 1, 4, 5, 8, 6, 7 ],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 35
  },
  outline: [
    {
      desktop: { x: 166, y: 63.5 },
      tablet: { x: 181.3, y: 59.8 },
      mobile: { x: 122.2, y: 42.8 },
    },
    {
      desktop: { x: 166, y: 127 },
      tablet: { x: 181.3, y: 116.3 },
      mobile: { x: 122.2, y: 85.2 },
    },
    {
      desktop: { x: 227.5, y: 126.8 },
      tablet: { x: 237.2, y: 116.3 },
      mobile: { x: 164.3, y: 85.2 },
    },
    {
      desktop: { x: 266, y: 165.2 },
      tablet: { x: 273, y: 152 },
      mobile: { x: 191, y: 112 },
    },
    {
      desktop: { x: 266, y: 255 },
      tablet: { x: 273, y: 232 },
      mobile: { x: 191, y: 172 },
    },
    {
      desktop: { x: 286.5, y: 275.5 },
      tablet: { x: 293, y: 252 },
      mobile: { x: 203.5, y: 184.5 },
    },
    {
      desktop: { x: 159.7, y: 402.3 },
      tablet: { x: 179.9, y: 365.1 },
      mobile: { x: 119.1, y: 268.9 },
    },
    {
      desktop: { x: 414, y: 402 },
      tablet: { x: 406, y: 365.1 },
      mobile: { x: 288, y: 269 },
    },
    {
      desktop: { x: 311, y: 299 },
      tablet: { x: 313, y: 272 },
      mobile: { x: 221, y: 202 },
    },
    {
      desktop: { x: 311, y: 210 },
      tablet: { x: 313, y: 192 },
      mobile: { x: 221, y: 141.2 },
    },
    {
      desktop: { x: 291.1, y: 190.1 },
      tablet: { x: 293.1, y: 172.1 },
      mobile: { x: 206.2, y: 126.7 },
    },
    {
      desktop: { x: 354, y: 127.2 },
      tablet: { x: 349.5, y: 115.7 },
      mobile: { x: 248, y: 84.8 },
    },
    {
      desktop: { x: 417, y: 127 },
      tablet: { x: 405.3, y: 115.7 },
      mobile: { x: 290.2, y: 85 },
    },
    {
      desktop: { x: 417, y: 63.5 },
      tablet: { x: 405.3, y: 59.2 },
      mobile: { x: 290.2, y: 42.3 },
    },
    {
      desktop: { x: 353.8, y: 126.6 },
      tablet: { x: 349, y: 115.5 },
      mobile: { x: 248.2, y: 84.4 },
    },
    {
      desktop: { x: 293.3, y: 126.7 },
      tablet: { x: 293.6, y: 115.6 },
      mobile: { x: 206.3, y: 84.4 },
    },
    {
      desktop: { x: 337, y: 83 },
      tablet: { x: 333.2, y: 76 },
      mobile: { x: 235.8, y: 55 },
    },
    {
      desktop: { x: 292, y: 38 },
      tablet: { x: 293.2, y: 36 },
      mobile: { x: 205.8, y: 25 },
    },
    {
      desktop: { x: 247, y: 83 },
      tablet: { x: 253.2, y: 76 },
      mobile: { x: 175.8, y: 55 },
    },
    {
      desktop: { x: 290.7, y: 126.7 },
      tablet: { x: 292.8, y: 115.6 },
      mobile: { x: 205.3, y: 84.4 },
    },
    {
      desktop: { x: 229.3, y: 126.7 },
      tablet: { x: 237, y: 115.5 },
      mobile: { x: 163.8, y: 84.4 },
    },
  ],
  outlines: [
    {
      desktop: { x: 134, y: 95 },
      tablet: { x: 153, y: 88 },
      mobile: { x: 101, y: 64 },
      rotation: 315
    },
    {
      desktop: { x: 247, y: 38 },
      tablet: { x: 253, y: 36 },
      mobile: { x: 176, y: 25 },
      rotation: 0
    },
    {
      desktop: { x: 385, y: 159 },
      tablet: { x: 377, y: 144 },
      mobile: { x: 269, y: 106 },
      rotation: 225
    },
    {
      desktop: { x: 291, y: 63 },
      tablet: { x: 293, y: 59 },
      mobile: { x: 206, y: 42 },
      rotation: 45
    },
    {
      desktop: { x: 266, y: 165 },
      tablet: { x: 273, y: 151 },
      mobile: { x: 191, y: 112 },
      rotation: 0
    },
    {
      desktop: { x: 287, y: 275 },
      tablet: { x: 293, y: 252 },
      mobile: { x: 204, y: 184 },
      rotation: 45
    },
    {
      desktop: { x: 414, y: 402 },
      tablet: { x: 406, y: 365 },
      mobile: { x: 288, y: 269 },
      rotation: 135
    }
  ]
};
