import React from 'react';
import './resultSkill.scss'
import Icon from '~/components/Icon/Icon'

const ResultSkill = ({ type, title, allPoints, value }) => {
  return (
    <div className="result-skill">
      <div className="result-skill__name">
        { title }
      </div>

      <div className="result-skill__icon-box">
        <Icon className="result-skill__icon" name={ type } />
      </div>

      <div className="result-skill__values">
        <span className="result-skill__value">{ value } </span>
        <span className="result-skill__all">/ { allPoints }</span>
      </div>
    </div>
  );
}

export default ResultSkill
