export default {
  shapes: [ 1, 5, 7, 2, 6, 3, 4 ],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 35
  },
  outline: [
    {
      desktop: { x: 229, y: 87 },
      tablet: { x: 238, y: 81 },
      mobile: { x: 167, y: 55 }
    },
    {
      desktop: { x: 184, y: 132 },
      tablet: { x: 198, y: 121 },
      mobile: { x: 137, y: 85 }
    },
    {
      desktop: { x: 229, y: 177 },
      tablet: { x: 238, y: 161 },
      mobile: { x: 167, y: 115 }
    },
    {
      desktop: { x: 250.64, y: 155.37 },
      tablet: { x: 255.57, y: 143.43 },
      mobile: { x: 178.43, y: 103.57 }
    },
    {
      desktop: { x: 250.64, y: 192.37 },
      tablet: { x: 255.57, y: 175.43 },
      mobile: { x: 178.43, y: 126.57 }
    },
    {
      desktop: { x: 206, y: 238 },
      tablet: { x: 216, y: 215 },
      mobile: { x: 149, y: 156 }
    },
    {
      desktop: { x: 206, y: 325.82 },
      tablet: { x: 216, y: 294.28 },
      mobile: { x: 149, y: 215.21 }
    },
    {
      desktop: { x: 143.64, y: 326 },
      tablet: { x: 160.28, y: 294.28 },
      mobile: { x: 108.21, y: 215.21 }
    },
    {
      desktop: { x: 143.82, y: 389.46 },
      tablet: { x: 160.28, y: 350.85 },
      mobile: { x: 108.21, y: 257.64 }
    },
    {
      desktop: { x: 206.28, y: 327 },
      tablet: { x: 216.14, y: 295 },
      mobile: { x: 149.85, y: 216 }
    },
    {
      desktop: { x: 385.01, y: 327 },
      tablet: { x: 375, y: 295 },
      mobile: { x: 268, y: 216 }
    },
    {
      desktop: { x: 429.25, y: 371 },
      tablet: { x: 414, y: 334 },
      mobile: { x: 297, y: 245 }
    },
    {
      desktop: { x: 474, y: 326 },
      tablet: { x: 454, y: 294 },
      mobile: { x: 327, y: 215 }
    },
    {
      desktop: { x: 385, y: 326 },
      tablet: { x: 375, y: 294 },
      mobile: { x: 268, y: 215 }
    },
    {
      desktop: { x: 296.28, y: 237.28 },
      tablet: { x: 296.14, y: 215.14 },
      mobile: { x: 209.35, y: 156.35 }
    },
    {
      desktop: { x: 377.92, y: 155.64 },
      tablet: { x: 368.71, y: 142.57 },
      mobile: { x: 263.28, y: 102.43 }
    },
    {
      desktop: { x: 340, y: 155.64 },
      tablet: { x: 335, y: 142.57 },
      mobile: { x: 238, y: 102.43 }
    },
    {
      desktop: { x: 340, y: 66 },
      tablet: { x: 335, y: 63 },
      mobile: { x: 238, y: 43 }
    },
    {
      desktop: { x: 274, y: 132 },
      tablet: { x: 277.5, y: 120.5 },
      mobile: { x: 196.5, y: 84.5 }
    }
  ],
  outlines: [
    {
      desktop: { x: 184, y: 87 },
      tablet: { x: 198, y: 81 },
      mobile: { x: 137, y: 55 },
      rotation: 0
    },
    {
      desktop: { x: 250, y: 66 },
      tablet: { x: 255, y: 63 },
      mobile: { x: 178, y: 43 },
      rotation: 0
    },
    {
      desktop: { x: 250.64, y: 282.92 },
      tablet: { x: 255.57, y: 255.71 },
      mobile: { x: 178.43, y: 187.28 },
      rotation: 225
    },
    {
      desktop: { x: 206, y: 192 },
      tablet: { x: 216, y: 175 },
      mobile: { x: 149, y: 126 },
      rotation: 0
    },
    {
      desktop: { x: 206, y: 327 },
      tablet: { x: 216, y: 295 },
      mobile: { x: 149, y: 216 },
      rotation: 270
    },
    {
      desktop: { x: 175.64, y: 294 },
      tablet: { x: 188.57, y: 266 },
      mobile: { x: 129.43, y: 194 },
      rotation: 45
    },
    {
      desktop: { x: 384, y: 371 },
      tablet: { x: 374, y: 334 },
      mobile: { x: 267, y: 245 },
      rotation: 270
    }
  ]
};
