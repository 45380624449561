export default {
  image: require('~/static/trainers/funnyPoints/dino.png'),
  width: 800,
  height: 919,
  offsetX: 30,
  offsetY: 20,
  points: [
    { id: 1,  x: 47,   y: 364 },
    { id: 2,  x: -164, y: 424 },
    { id: 3,  x: -215, y: 348 },
    { id: 4,  x: -233, y: 190 },
    { id: 5,  x: -134, y: 141 },
    { id: 6,  x: -16,  y: 129 },
    { id: 7,  x: 50,   y: 146 },
    { id: 8,  x: 106,  y: 167 },
    { id: 9,  x: 132,  y: 270 },
    { id: 10, x: 126,  y: 348 },
    { id: 11, x: 112,  y: 423 },
    { id: 12, x: 97,   y: 489 },
    { id: 13, x: 87,   y: 552 },
    { id: 14, x: 102,  y: 608 },
    { id: 15, x: 141,  y: 641 },
    { id: 16, x: 188,  y: 642 },
    { id: 17, x: 233,  y: 620 },
    { id: 18, x: 286,  y: 556 },
    { id: 19, x: 269,  y: 619 },
    { id: 20, x: 220,  y: 660 },
    { id: 21, x: 169,  y: 681 },
    { id: 22, x: 115,  y: 697 },
    { id: 23, x: 45,   y: 706 },
    { id: 24, x: -15,  y: 699 },
    { id: 25, x: -58,  y: 676 },
    { id: 26, x: -88,  y: 638 },
    { id: 27, x: -102, y: 581 },
    { id: 28, x: -93,  y: 533 },
    { id: 29, x: -57,  y: 443 },
    { id: 30, x: -30,  y: 388 }
  ]
};
