import React from 'react';
import cx from 'classnames';

import Ok from '~/components/Ok/Ok';
import Fail from '~/components/Fail/Fail';
import Icon from '~/components/Icon/Icon';
import Button from '~/components/Button/Button';

import { DEFAULT_VALUE, NUMBERS } from './constants';

/* eslint-disable-next-line */
import clear from './assets/clear.svg';

import './number-panel.scss';

const NumberPanel = (props) => {
  const { isFinish, isWin, onChange, currentValue } = props;
  const isCorrect = isFinish && isWin;
  const isIncorrect = isFinish && !isWin;
  const classesField = cx('number-panel__field', {
    'number-panel__field_empty': !currentValue,
    'number-panel__field_correct': isCorrect,
    'number-panel__field_incorrect': isIncorrect
  });
  const classesNumbers = cx('number-panel__numbers', {
    'number-panel__numbers_ended': isFinish
  });
  const viewValue = isFinish ? Number(currentValue) : currentValue;

  return (
    <div className="number-panel">
      <div className={ classesField }>
        { viewValue.toString() || DEFAULT_VALUE }
        { isIncorrect && <Fail /> }
        { isCorrect && <Ok /> }
        { currentValue && !isFinish && renderClearIcon(onChange) }
      </div>
      {
        <div className={ classesNumbers }>
          { renderNumbers(props) }
        </div>
      }
    </div>
  );
};

const renderNumbers = (props) => {
  const { currentValue, onChange, isFinish } = props;
  return NUMBERS.map((item, index) => {
    const handlerClick = () => {
      if (isFinish) {
        return;
      }

      const value = currentValue + item;
      onChange(value);
    };

    return (
      <div className="number-panel__number" key={ index } onClick={ handlerClick }>{ item }</div>
    );
  });
};

const renderClearIcon = (onChange) => {
  const handlerClick = () => onChange('');
  return (
    <Button className="number-panel__clear-button" round={ true } onClick={ handlerClick }>
      <Icon className="number-panel__icon" name="clear"/>
    </Button>
  );
};

export default NumberPanel;
