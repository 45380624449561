import React from 'react';
import cx from 'classnames';

import Icon from '~/components/Icon/Icon';

// eslint-disable-next-line
import stop from '~/static/icon/stop.svg';

import './fail.scss';

const Fail = ({ className }) => {
  const classes = cx('fail', className);
  return (
    <div className={ classes }>
      <Icon name="stop" className="fail__icon" />
    </div>
  )
}

export default Fail;
