import React from 'react';

export default ({ name }, index, missionCount) => {
  const getString = (symbol) => (
    <span key={ index }>
      {' '}{ name } { symbol }
    </span>
  );
  return index < missionCount - 1 ? getString('-') : getString();
};
