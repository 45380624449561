export default {
  texts: {
    description: [
      'Любой суперагент должен много знать и уметь использовать свои знания в нужное время в нужном месте. Для этого надо иметь гибкий ум, хорошую память и, конечно, сами знания. Как быстро ты ответишь на вопросы? Проверь себя!',
      'Спецагент должен уметь мгновенно принимать решения в сложившихся обстоятельствах, поэтому скорость его мыслительных процессов должна быть высокой. Тренируйся – и сможешь быстро отвечать на любые вопросы!',
      'Интеллектуальные разминки – универсальное упражнение, которое круто развивает мышление и упрощает решение любых задач: в жизни, в школе и на спецоперациях.'
    ],
    losing: [
      'Немного не хватило! Попробуй ещё раз, и всё обязательно получится!',
      'Эх... Не удалось избежать ошибок. Чем больше тренируешься в тренажёрном зале, тем проще будет тебе в миссии!'
    ],
    victory: [
      'Отличный результат! Всё верно! Мистер Фоксман готов поручить тебе выполнение мисссии!',
      'Теперь ты пройдешь любое испытание! Ты абсолютно готов! Новая миссия ждёт тебя!'
    ]
  },
  textsInMission: {
    description: [
      'Важную информацию готовы передать только настоящему спецагенту. Тебя ждёт проверка – отвечай на вопросы быстро и правильно, иначе ты упустишь важные сведения!',
      'Ты должен получить важные сведения. Для этого ответь правильно и быстро на все вопросы!'
    ],
    losing: [
      'Надо немного подготовиться! Мистер Фоксман специально для тебя уже подготовил вопросы в тренажёрном зале!',
      'Очень хитрые вопросы подготовили для тебя! Потренируйся в тренажёрном зале, и всё обязательно получится!'
    ],
    victory: [
      'Отлично! Важные сведения были переданы. Ты настоящий спецагент!',
      'Сведения тобой получены в полном объеме! Самый крутой спецагент!'
    ]
  }
};
