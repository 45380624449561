export default {
  image: require('~/static/trainers/funnyPoints/strawberry.png'),
  width: 800,
  height: 919,
  offsetX: 30,
  // offsetY: -50,
  points: [
    { id: 1,  x: -40,  y: 284 },
    { id: 2,  x: -136, y: 347 },
    { id: 3,  x: -167, y: 492 },
    { id: 4,  x: -125, y: 627 },
    { id: 5,  x: -32,  y: 720 },
    { id: 6,  x: 92,   y: 729 },
    { id: 7,  x: 185,  y: 648 },
    { id: 8,  x: 246,  y: 539 },
    { id: 9,  x: 269,  y: 440 },
    { id: 10, x: 236,  y: 344 }
  ]
}
