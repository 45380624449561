export default ({ timer, cardsInLine }) => {
  const tooltipContent = `<b>1.</b> Рассмотри картинки.<br/>
                        <b>2.</b> Найди закономерность расположения одинаковых картинок. Проведи по ним курсором, соединив линией.<br/>
                        <b>3.</b> Проговори вслух название и цвет предмета.<br/>
                        <b>4.</b> На следующем экране вспомни закономерность и повтори расположение картинок в пустой таблице.`;
  return {
    timer: timer.map(item => item * 1000),
    cardsInLine,
    tooltipContent,
    winnerPoints: {
      logic: 2,
      memory: 1
    }
  };
}
