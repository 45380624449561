export default [
  {
    src: require(`~/static/trainers/spatial/level2/1.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level2/2.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/3.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/4.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/5.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level2/6.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level2/7.png`),
    answer: 4
  },
  {
    src: require(`~/static/trainers/spatial/level2/8.png`),
    answer: 4
  },
  {
    src: require(`~/static/trainers/spatial/level2/9.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level2/10.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level2/11.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level2/12.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level2/13.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/14.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/15.png`),
    answer: 4
  },
  {
    src: require(`~/static/trainers/spatial/level2/16.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/17.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level2/18.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level2/19.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level2/20.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/21.png`),
    answer: 7
  },
  {
    src: require(`~/static/trainers/spatial/level2/22.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level2/23.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level2/24.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level2/25.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level2/26.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level2/27.png`),
    answer: 9
  },
  {
    src: require(`~/static/trainers/spatial/level2/28.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level2/29.png`),
    answer: 6
  },
  {
    src: require(`~/static/trainers/spatial/level2/30.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level2/31.png`),
    answer: 5
  },
  {
    src: require(`~/static/trainers/spatial/level2/32.png`),
    answer: 5
  }
];
