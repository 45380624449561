import React from 'react';
import { connect } from 'react-redux';
import Avatar from '~/components/Avatar/Avatar';
import { skillLevelNameSelector } from '~/store/profile/selector';
import { Link } from 'react-router-dom';

import './profileWidget.scss';

const ProfileWidget = ({ skillLevelName, profile }) => {
  const { name, gameLevel } = profile;

  return (
    <div className="profile-widget">
      <Link
        className="profile-widget__link"
        to="/profile/"
      >
        <div className="profile-widget__avatar">
          <Avatar />
        </div>

        <div className="profile-widget__info">
          <div className="profile-widget__name">
            { name }
          </div>
          <div className="profile-widget__level">
          { gameLevel } ур. { skillLevelName }
          </div>
        </div>
      </Link>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    profile: state.profile,
    skillLevelName: skillLevelNameSelector(state)
  };
}

export default connect(mapStateToProps)(ProfileWidget);
