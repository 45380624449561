import PropTypes from 'prop-types';

export default {
  params: PropTypes.shape({
    difficultyParams: PropTypes.object,
    levels: PropTypes.objectOf(
      PropTypes.shape({
        points: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
          }),
        ),
      }),
    ),
    skillPoints: PropTypes.object,
  }).isRequired,
  isTimerActive: PropTypes.bool.isRequired,
  onStartCb: PropTypes.func.isRequired,
  onFinishCb: PropTypes.func.isRequired,
};
