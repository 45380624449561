export default ({ amount, seconds }) => {
  const tooltipContent = `<b>1.</b> Прочитай предложение.
                          <br><br><b>2.</b> Выбери правильный вариант ответа.`;

  return {
    timer: new Array(amount).fill(seconds * 1000),
    tooltipContent,
    isMiniTooltip: true,
    amount,
    stageAmount: amount,
    winnerPoints: {
      mind: 2,
      memory: 1
    }
  };
};
