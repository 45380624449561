import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import propTypes from './prop-types';
import cx from 'classnames';

import Button from '~/components/Button/Button';
import Option from './Option';
import Question from './Question';

import { STEP_TEST, STEP_REMEMBER, BUTTON_NEXT, BUTTON_CHECK } from '~/utils/constants';

import getShuffleArray from '~/services/get-shuffle-array';

import * as locationsActions from '~/store/locations/actions';

import './TrainerAccuracy.scss';

/**
 * Схема работы такая:
 * 1. Таймер начинается после закрытия видео-инструкции
 * 2. В компонент приходит props.isTimerActive = true
 * 3. this.state.step = 'remember'
 * 4. Когда таймер завершается, приходит props.isTimerActive = false, ловим это,
 *    при условии что this.state.step === 'remember'  и
 *    меняем this.state.step = 'test', вызываем onStartCb
 * 5. Когда таймер завершается, приходит props.isTimerActive = false.
 *    Ловим это и вызываем onFinishCb
 */
class TrainerAccuracy extends Component {
  state = {
    isActive: true,
    selectedOptions: {},
    questions: this.createQuestions(),
    answered: false,
    answerTab: 'answer',
    isClickNextButton: false
  };

  createQuestions() {
    const { params } = this.props;
    const { difficultyParams, questions } = params;
    const { amount } = difficultyParams;
    const currentQuestions = getShuffleArray(questions, amount).map((item) => ({
      ...item, options: getShuffleArray(item.options)
    }));

    return currentQuestions;
  }

  componentDidMount() {
    this.props.setTrainerStage(STEP_REMEMBER);
  }

  componentDidUpdate(prevProps) {
    const { isRefresh, setTrainerStage, step, isTimerActive, onStartCb, handleDataLayer } = this.props;

    /** Переключаемся на этап тестирования */
    if (prevProps.isTimerActive && !isTimerActive && step === STEP_REMEMBER) {
      if (!this.state.isClickNextButton) {
        handleDataLayer({ event: 'timerTick', eventAction: 'next_force', stageName: 'page2A_task' });
      }

      setTrainerStage(STEP_TEST);
      onStartCb();
      this.setState({ isClickNextButton: false });
    }

    /** Завершаем этап тестирования по завершению времени */
    if (prevProps.isTimerActive && !isTimerActive && step === STEP_TEST && !this.state.isClickNextButton) {
      handleDataLayer({ event: 'timerTick', eventAction: 'next_force', stageName: 'page2B_solve' });
      this.setState({ isActive: false }, () => this.checkResults());
    }

    if (prevProps.isRefresh !== isRefresh) {
      setTrainerStage(STEP_REMEMBER);
      this.setState({
        isActive: true,
        selectedOptions: {},
        questions: this.createQuestions(),
        answered: false
      });
    }
  }

  selectOption = (cardId, optionId) => {
    this.setState(prevState => ({
      selectedOptions: {
        ...prevState.selectedOptions,
        [cardId]: optionId,
      },
    }));
  };

  finishTrainer = (skills, reason) => {
    const { onFinishCb } = this.props;

    this.setState({ answered: true });
    onFinishCb({ victory: Boolean(skills), reason, skills });
  };

  checkResults = () => {
    const { params, handleDataLayer } = this.props;
    const { difficultyParams } = params;
    const { variants } = difficultyParams;
    const { selectedOptions, answered, questions } = this.state;

    const correctAnswersCount = Object.keys(selectedOptions).reduce((acc, key) => {
      const item = selectedOptions[key];
      const correctItem = questions.find((item) => item.id === key);
      const isCorrect = item === correctItem.answerId;
      return acc + (isCorrect ? 1 : 0)
    }, 0)

    const skills = variants[correctAnswersCount] || null;
    let reason = null;

    if (!skills) {
      reason = 'fail'
    } else if (correctAnswersCount === difficultyParams.amount) {
      reason = 'complete'
    } else {
      reason = 'partially'
    }

    handleDataLayer({ eventAction: 'next_self', stageName: 'page2B_solve' });

    if (!answered) {
      this.finishTrainer(skills, reason);
    }
  };

  renderQuestion = ({ id, image, options, answerId }) => (
    <Question
      key={ id }
      image={ image }
      options={ options }
      answerId={ answerId }
    />
  )

  renderTestQuestion = (item) => {
    const { image, id, options } = item;

    return (
      <div className="trainer-accuracy__card trainer-accuracy__card_test" key={ id }>
        <div className="trainer-accuracy__card-image">
          <img src={ image } alt="" className="trainer-accuracy__card-img" />
        </div>
        <div className="trainer-accuracy__card-options">
          {
            options.map(option => this.renderTestOption(option, item))
          }
        </div>
      </div>
    )
  }

  renderTestOption = ({ id, text }, question) => {
    const { selectedOptions, answered } = this.state;
    return (
      <Option
        key={ `${ id}_${ question.id }` }
        id={ id }
        text={ text }
        selectedOptions={ selectedOptions }
        isCheck={ answered }
        selectOption={ this.selectOption }
        question={ question }
        isCorrrectAnswerView={ this.state.answerTab === 'correctAnswer' }
      />
    )
  }

  nextStep = () => {
    const { onStageChange, onTimeEnd, handleDataLayer } = this.props;

    onStageChange();
    onTimeEnd();
    this.setState({ isClickNextButton: true });
    handleDataLayer({ eventAction: 'next_self', stageName: 'page2A_task' });
  }

  changeAnswerTab = (tab) => {
    this.setState({ answerTab: tab })
  }

  checkEndTrainer = () => {
    this.setState({ isClickNextButton: true }, () => this.checkResults());
  }

  render() {
    const { className, step } = this.props;
    const { selectedOptions, isActive, questions, answered } = this.state;
    const isNofFullAnswered = Object.keys(selectedOptions).length < questions.length;
    const classes = cx('trainer-accuracy', {
      [ className ]: className,
    });

    return (
      <section className={ classes }>
        <div className="trainer-accuracy__container">
          {
            step === STEP_REMEMBER && (
              <Fragment>
                <div className="trainer-accuracy__cards">
                  {
                    questions.map(item => this.renderQuestion(item))
                  }
                </div>

                <div className="trainer-accuracy__next">
                  <Button
                    onClick={ this.nextStep }
                    className="trainer-dynamic-memory__button button_center"
                  >
                    { BUTTON_NEXT }
                  </Button>
                </div>
              </Fragment>
            )
          }

          {
            step === STEP_TEST && (
              <Fragment>
                <div className="trainer-accuracy__cards">
                  {
                    questions.map(item => this.renderTestQuestion(item))
                  }
                </div>
                <div className="trainer-accuracy__check-answers">
                  {
                    !answered &&
                    <Button
                      onClick={ this.checkEndTrainer }
                      className="trainer-accuracy__button button_center"
                      disabled={ isNofFullAnswered || !isActive }
                    >
                      { BUTTON_CHECK }
                    </Button>
                  }

                </div>
              </Fragment>
            )
          }
        </div>
      </section>
    );
  }
}

TrainerAccuracy.propTypes = propTypes;

const mapDispatchToProps = {
  setTrainerStage: locationsActions.setTrainerStage,
};

const mapStateToProps = state => ({
  step: state.locations.currentTrainerStage
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainerAccuracy);
