import { TABLET, MOBILE, DESKTOP } from '../constants/screens';
import * as Sizes from '../constants';

export const getScreenType = () => {
  const widthClient = document.documentElement.clientWidth;
  let screenType = DESKTOP;

  if (widthClient < Sizes.TABLET && widthClient >= Sizes.MOBILE) {
    screenType = TABLET;
  }
  if (widthClient < Sizes.MOBILE) {
    screenType = MOBILE;
  }

  return screenType;
};

export const getWidthField = () => {
  const clientWidth = document.documentElement.clientWidth;
  const screenType = getScreenType();

  let width = Sizes.WIDTH_FIELD;
  if (screenType === TABLET) {
    width = clientWidth - 2 * Sizes.MARGIN_FIELD;
  }
  if (screenType === MOBILE) {
    width = clientWidth;
  }

  return width;
};

export const getCanvasParams = () => {
  const screenType = getScreenType();
  const clientWidth = document.documentElement.clientWidth;
  const heightCanvas = screenType === MOBILE ? Sizes.HEIGHT_FIELD[screenType] : Sizes.HEIGHT_FIELD[screenType] + Sizes.MARGIN_FIELD;
  const widthCanvas = screenType === MOBILE ? clientWidth - 20 : clientWidth - 2 * Sizes.MARGIN_FIELD;

  return { widthCanvas, heightCanvas };
};
