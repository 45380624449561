import gifts from '~/shared/gifts';

export default {
  id: 6,
  name: 'Москва-Сити',
  longMemory: true,
  description: 'Москва-Сити – развивающийся деловой район в Москве на Пресненской набережной.',
  partingWords: 'Твои навыки выросли, а предметов для прохождения миссий становится всё больше! Ты становишься самым крутым спецагентом! В работе спецагента очень важно быть незамеченным. В этом тебе поможет плащ спецагента. Пройди миссию, и ты будешь легко уходить от слежки и сливаться с толпой.',
  story: `Москва-Сити состоит из 12 объектов, планируется к постройке ещё 12.
          Информация для спецагента:
          1. Башня «Москва» – самый популярный небоскрёб в Европе.
          2. Башня «Евразия» – самое высокое здание в Европе из металлоконструкций. Она возвышается над землей на 309 метров. Башня носит заочное название «Стальная вершина».
          3. В башне «Федерация» находится самый высокий в мире бассейн. Он находится на высоте 250 м, на 60-м этаже.`,
  textLosing: 'Миссия оказалась непростой, но тем интереснее! Настоящий спецагент не любит простых заданий. Потренируй навыки и возвращайся! Миссию ты точно пройдешь!',
  textPartially: 'Миссия не сразу покорилась! Мистер Фоксман рекомендует отправиться в тренажёрный зал и вспомнить все приёмы и навыки, которыми ты владеешь. Возвращайся, и всё точно получится!',
  textSuccess: 'Отличный результат! Это была последняя миссия в Москве. Посмотри, сколько предметов агента ты заработал в этой точке сбора! А как выросли твои навыки! Не теряй время, отправляйся в новую точку сбора. Мистер Фоксман уже ждёт тебя!',
  gift: gifts.cloak,
  imgs: [
    require('~/static/locations/moscow/moscow-city_980х590.jpg'),
  ]
};
