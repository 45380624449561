import React from 'react';
import cx from 'classnames';

import './adviceSkill.scss'

const AdviceSkill = ({ name, type }) => {
  const className = cx('advice-skill', {
    [`advice-skill_${type}`]: type
  })

  return (
    <div className={ className }>
      <div className="advice-skill__name">
        { name.toLowerCase() }
      </div>
    </div>
  )
}

export default AdviceSkill;
