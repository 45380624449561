import * as types from './constants';
import * as entityNormalize from '~/utils/entityNormalize';

import { STEP_TEST } from '~/utils/constants';

const initialState = {
  isPending: false,
  isFulfilled: false,
  isRejected: false,
  data: {},
  ids: [],
  currentTrainerIndex: 0,
  currentTrainerStage: STEP_TEST,
  hasShownLongMemoryTask: false,
  longMemoryData: null,
  hasMissionPopupShown: false
};

function locations(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case `${types.FETCH_LOCATIONS}_PENDING`:
      return {
        ...state,
        isPending: true
      };

    case `${types.FETCH_LOCATIONS}_FULFILLED`:
      const entity = entityNormalize.toObject(payload, 'slug');

      return { ...state,
        isPending: false,
        isFulfilled: true,
        ...entity
      };

    case `${types.FETCH_LOCATIONS}_REJECTED`:
      return { ...state,
        isRejected: true,
        isPending: false,
        error: payload
      };

    case types.GO_TO_NEXT_TRAINER:
      return {
        ...state,
        currentTrainerIndex: state.currentTrainerIndex + 1,
        currentTrainerStage: STEP_TEST
      };

    case types.SET_SHOWN_LONG_MEMORY_TASK:
      return {
        ...state,
        hasShownLongMemoryTask: payload,
      };

    case types.SAVE_LONG_MEMORY_DATA:
      return {
        ...state,
        longMemoryData: payload,
      };

    case types.ON_FINISH:
      return {
        ...state,
        hasShownLongMemoryTask: false,
        currentTrainerIndex: 0
      };

    case types.SET_TRAINER_STAGE:
      return {
        ...state,
        currentTrainerStage: payload
      };

    case types.SET_MISSION_POPUP_SHOWN:
      return {
        ...state,
        hasMissionPopupShown: payload
      };

    case types.OPEN_NEXT_MISSION: {
      const { data } = state;
      const { locationName, missionName } = payload;
      const currentLocation = data[locationName];
      const currentMissions = currentLocation.missions;
      let newLocation = {};

      if (locationName && missionName) {
        newLocation = {
          [locationName]: {
            ...currentLocation,
            missions: {
              ...currentMissions,
              [missionName]: {
                ...currentMissions[missionName],
                locked: false
              }
            },
            locked: false
          }
        };
      }

      return {
        ...state,
        data: {
          ...data,
          ...newLocation
        }
      };
    }

    default:
      return state
  }
}

export default locations;
