import React, { memo } from 'react';
import { Line, Circle, Group } from 'react-konva';

import { ICON_SIZE } from '../constants';
import { BORDER_WIN_COLOR, WHITE_COLOR } from '../constants/colors';

const WinIcon = (props) => {
  const styleLeftLine = { ...getLineStyle(), points: [8, 8, 2, 2] };
  const styleRightLine = { ...getLineStyle(), points: [8, 8, 17, -3], x: -10 };

  return (
    <Group { ...props }>
      <Circle { ...getCircleStyle() } />
      <Line { ...styleLeftLine } />
      <Line { ...styleRightLine } />
    </Group>
  );
};

const getCircleStyle = () => {
  return {
    radius: ICON_SIZE,
    fill: BORDER_WIN_COLOR,
    y: ICON_SIZE + 5
  };
};

const getLineStyle = () => {
  return {
    stroke: WHITE_COLOR,
    strokeWidth: 3,
    x: -9,
    y: 17
  };
};

export default memo(WinIcon);
