export default {
  image: require('~/static/trainers/funnyPoints/turtle.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -283, y: 391 },
    { id: 2,  x: -316, y: 347 },
    { id: 3,  x: -268, y: 314 },
    { id: 4,  x: -202, y: 311 },
    { id: 5,  x: -165, y: 362 },
    { id: 6,  x: -148, y: 427 },
    { id: 7,  x: -123, y: 368 },
    { id: 8,  x: -62,  y: 311 },
    { id: 9,  x: 25,   y: 273 },
    { id: 10, x: 122,  y: 268 },
    { id: 11, x: 207,  y: 293 },
    { id: 12, x: 263,  y: 355 },
    { id: 13, x: 291,  y: 422 },
    { id: 14, x: 292,  y: 487 },
    { id: 15, x: 260,  y: 522 },
    { id: 16, x: 186,  y: 530 },
    { id: 17, x: 106,  y: 533 },
    { id: 18, x: 20,   y: 533 },
    { id: 19, x: -65,  y: 518 },
    { id: 20, x: -119, y: 478 }
  ]
};
