import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router';

import Icon from '~/components/Icon/Icon';

import { STEP_TEST } from '~/utils/constants';
import formatDuration from './services/format-duration';
import getDifficultyParams from '~/services/get-difficulty-params';
import getMissionIdBySlug from '~/services/get-mission-id-by-slug';

import propTypes from './prop-types';

// eslint-disable-next-line
import clock from './assets/clock.svg';

import './timeline.scss';

class Timeline extends Component {
  state = {
    timePassed: 0,
    minutes: '00',
    seconds: '00',
    percent: '0',
    taskStep: -1,
    isTransitionActive: false,
    isFinish: false,
    difficultyParams: {}
  };

  get status() {
    const { isFinish } = this.state;
    const { step, isPopupShow, stageText } = this.props;

    switch (true) {
      case isPopupShow:
        return stageText;

      case isFinish:
        return 'Проверка задания';

      case step === STEP_TEST:
        return 'Выполняй';

      default:
        return 'Запоминай';
    }
  };

  componentDidMount() {
    const { isActive, trainerParams, missionId, profile } = this.props;
    const difficultyParams = getDifficultyParams({ params: trainerParams }, missionId, profile);

    if (isActive) {
      this.startTimer();
    } else {
      /** Вычисляем длительность */
      const duration = this.getDuration();
      this.setState({ ...formatDuration(duration, 0) });
    }

    this.setState({ difficultyParams });
  }

  componentDidUpdate(prevProps) {
    const { isActive, isRefresh, trainerParams, isLongMemory, missionId, profile } = this.props;

    if (prevProps.isRefresh !== isRefresh) {
      const duration = this.getDuration();
      if (isRefresh) {
        const difficultyParams = getDifficultyParams({ params: trainerParams }, missionId, profile);
        this.setState({ ...formatDuration(duration, 0), timePassed: 0, taskStep: -1, isFinish: false, difficultyParams });
      }
    }

    if (prevProps.isActive !== isActive) {
      let isFinish = false;

      if (isActive) {
        this.startTimer();
      } else {
        clearInterval(this.timerId);
        isFinish = true;
      }

      this.setState({ isFinish });
    }

    // Исключение для долговременной памяти в миссии
    if (prevProps.trainerParams !== trainerParams && isLongMemory) {
      this.setState({ difficultyParams: trainerParams });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
    clearInterval(this.noTransitionId);
  }

  pauseTime = () => {
    clearInterval(this.timerId)
  }

  getDuration = () => {
    const { timer = null } = this.props.trainerParams;
    const { difficultyParams } = this.state;

    let duration;
    try {
      if (difficultyParams) {
        duration = difficultyParams.timer[0];
      } else if (timer) {
        duration = timer[0];
      }
    } catch {
      duration = 1000;
    }
    return duration;
  }

  startTimer() {
    const { trainerParams, changeStage, subStageAmount, stageAmount } = this.props;
    const { difficultyParams } = this.state;
    const { timer = null } = trainerParams;

    /** Останавливаем текущий таймер */
    clearInterval(this.timerId);
    if (Number.isInteger(this.state.taskStep) && difficultyParams) {
      this.setState({ ...formatDuration(difficultyParams.timer[this.state.taskStep + 1], 0) });
    }

    this.noTransitionId = setTimeout(() => this.setState({ isTransitionActive: true }), 1000);
    this.setState({ timePassed: 0, taskStep: this.state.taskStep + 1 },
      () => {
        let duration;
        try {
          if (difficultyParams) {
            duration = difficultyParams.timer[this.state.taskStep];
          } else if (timer) {
            duration = timer[this.state.taskStep];
          }
        } catch {
          duration = 1000;
        }

        /** Записываем длительность в компонент */
        this.duration = duration;
        let timePassedNext;

        /** Запускаем интервальный таймер */
        this.timerId = setInterval(() => {
          this.setState(({ timePassed, taskStep }) => {
            timePassedNext = timePassed + 1000;
            if (timePassedNext > this.duration) {
              const isStepLeft = stageAmount * (subStageAmount || 1) > taskStep + 1;
              let isEndOfStage = !(subStageAmount && ((taskStep + 1) % subStageAmount !== 0));

              if (changeStage && isStepLeft && isEndOfStage) {
                changeStage();
              }

              this.endTimer(!isStepLeft);
              return {
                isTransitionActive: false,
              };
            }
            return {
              timePassed: timePassedNext,
              ...formatDuration(this.duration, timePassedNext),
            };
          });
        }, 1000);
      },
    );
  }

  endTimer(isFinal) {
    clearInterval(this.timerId);
    this.props.onEnded(isFinal);
  }

  render() {
    const { taskStep, percent, minutes, seconds, isTransitionActive, isFinish } = this.state;
    const { subStageAmount, stageAmount, isPopupShow, isShowStage } = this.props;
    const classesStages = cx('timeline__stages', {
      'timeline__stages_mobile': stageAmount && !isPopupShow
    });
    const classesLabel = cx('timeline__label', {
      'timeline__label_mobile': stageAmount && !isPopupShow
    });
    const classesTimeline = cx({
      'timeline__line': true,
      'timeline__line_green': percent <= 50,
      'timeline__line_orange': percent > 50,
      'timeline__line_no-transition': !isTransitionActive
    });

    return (
      <div className="timeline">
        {
          !isPopupShow && !isFinish &&
          <div className="timeline__pane">
            <div key={ taskStep } className={ classesTimeline } style={ { width: `${100 - percent}%` } }/>
          </div>
        }

        <div className={ classesLabel }>
          <span className="timeline__label-text">
            {
              !isPopupShow && !isFinish &&
              <Icon name="clock" className="timeline__clock" />
            }

            <span>
              { this.status }
            </span>

            {
              !isPopupShow && !isFinish &&
              <span className="timeline__label-timer">
                {`${minutes}:${seconds}`}
              </span>
            }
          </span>
        </div>

        {
          stageAmount && !isPopupShow && isShowStage &&
          <div className={ classesStages }>
            Часть { taskStep + 1 } из { (subStageAmount || 1) * stageAmount }
          </div>
        }
      </div>
    );
  }
}

Timeline.propTypes = propTypes;

const mapStateToProps = (state, props) => {
  const { locations, profile } = state;
  return {
    missionId: getMissionIdBySlug(state, props),
    step: locations.currentTrainerStage,
    profile
  };
};

export default withRouter(connect(mapStateToProps)(Timeline));
