import React from 'react';
import classNames from 'classnames'

import './contacts.scss'

const Contacts = ({ isFooter }) => {
  const phone = "8 (804) 333-8-007";
  const email = "info@iq007.ru";

  let contactsClass = classNames('contacts', {
    'contacts_footer': isFooter
  })

  return (
    <div className={ contactsClass }>
      <div className="contacts__phone">
        <a href={`tel:${phone}`} className="contacts__link">
          {phone}
        </a>
      </div>

      <div className="contacts__email">
        <a href={`mailto:${email}`} className="contacts__link">
          {email}
        </a>
      </div>
    </div>
  );
}

export default Contacts
