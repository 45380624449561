export default {
  shapes: [ 1, 4, 5, 7, 6, 2, 3 ],
  offset: {
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 246, y: 139 },
      tablet: { x: 247, y: 127 },
      mobile: { x: 178, y: 91 },
    },
    {
      desktop: { x: 354.8, y: 247.1 },
      tablet: { x: 343, y: 223 },
      mobile: { x: 249.6, y: 162.5 },
    },
    {
      desktop: { x: 329, y: 273 },
      tablet: { x: 319.5, y: 246.5 },
      mobile: { x: 232, y: 180 },
    },
    {
      desktop: { x: 285, y: 229 },
      tablet: { x: 280, y: 207 },
      mobile: { x: 202, y: 150 },
    },
    {
      desktop: { x: 196, y: 318 },
      tablet: { x: 201, y: 286 },
      mobile: { x: 142, y: 210 },
    },
    {
      desktop: { x: 139, y: 318 },
      tablet: { x: 155, y: 286 },
      mobile: { x: 102, y: 210 },
    },
    {
      desktop: { x: 184.2, y: 363 },
      tablet: { x: 195, y: 326 },
      mobile: { x: 132, y: 240 },
    },
    {
      desktop: { x: 228, y: 319 },
      tablet: { x: 234, y: 287 },
      mobile: { x: 162, y: 210 },
    },
    {
      desktop: { x: 285, y: 319 },
      tablet: { x: 280, y: 287 },
      mobile: { x: 202, y: 210 },
    },
    {
      desktop: { x: 329.3, y: 363 },
      tablet: { x: 319, y: 326 },
      mobile: { x: 232, y: 240 },
    },
    {
      desktop: { x: 418, y: 363 },
      tablet: { x: 399, y: 326 },
      mobile: { x: 292, y: 240 },
    },
    {
      desktop: { x: 418, y: 183.6 },
      tablet: { x: 399, y: 166.6 },
      mobile: { x: 292, y: 120.4 },
    },
    {
      desktop: { x: 402.3, y: 183.6 },
      tablet: { x: 382.4, y: 166.6 },
      mobile: { x: 283.6, y: 120.4 },
    },
    {
      desktop: { x: 447, y: 139 },
      tablet: { x: 422, y: 127 },
      mobile: { x: 313, y: 91 },
    },
    {
      desktop: { x: 402, y: 94 },
      tablet: { x: 382, y: 87 },
      mobile: { x: 283, y: 61 },
    },
    {
      desktop: { x: 357, y: 139 },
      tablet: { x: 342, y: 127 },
      mobile: { x: 253, y: 91 },
    },
    {
      desktop: { x: 401.7, y: 183.6 },
      tablet: { x: 381.6, y: 166.6 },
      mobile: { x: 282.4, y: 120.4 },
    },
    {
      desktop: { x: 291.3, y: 183.6 },
      tablet: { x: 287.4, y: 166.6 },
      mobile: { x: 208.6, y: 120.4 },
    },
    {
      desktop: { x: 336, y: 139 },
      tablet: { x: 327, y: 127 },
      mobile: { x: 238, y: 91 },
    },
  ],
  outlines: [
    {
      desktop: { x: 357, y: 94 },
      tablet: { x: 342, y: 87 },
      mobile: { x: 253, y: 61 },
      rotation: 0
    },
    {
      desktop: { x: 246, y: 184 },
      tablet: { x: 247, y: 167 },
      mobile: { x: 178, y: 121 },
      rotation: 270
    },
    {
      desktop: { x: 355, y: 120 },
      tablet: { x: 343, y: 110 },
      mobile: { x: 250, y: 78 },
      rotation: 45
    },
    {
      desktop: { x: 418, y: 364 },
      tablet: { x: 399, y: 327 },
      mobile: { x: 292, y: 240 },
      rotation: 180
    },
    {
      desktop: { x: 195, y: 319 },
      tablet: { x: 200, y: 287 },
      mobile: { x: 142, y: 210 },
      rotation: 270
    },
    {
      desktop: { x: 284, y: 364 },
      tablet: { x: 279, y: 327 },
      mobile: { x: 202, y: 240 },
      rotation: 270
    },
    {
      desktop: { x: 139, y: 364 },
      tablet: { x: 155, y: 327 },
      mobile: { x: 102, y: 240 },
      rotation: 270
    }
  ]
};
