export default {
  image: require('~/static/trainers/funnyPoints/soda.png'),
  width: 800,
  height: 919,
  offsetY: 10,
  points: [
    { id: 1,  x: -190, y: 223 },
    { id: 2,  x: -228, y: 264 },
    { id: 3,  x: -213, y: 289 },
    { id: 4,  x: -192, y: 285 },
    { id: 5,  x: -160, y: 338 },
    { id: 6,  x: -125, y: 400 },
    { id: 7,  x: -101, y: 455 },
    { id: 8,  x: -89,  y: 521 },
    { id: 9,  x: -84,  y: 580 },
    { id: 10, x: -68,  y: 656 },
    { id: 11, x: -35,  y: 732 },
    { id: 12, x: 11,   y: 796 },
    { id: 13, x: 54,   y: 835 },
    { id: 14, x: 103,  y: 825 },
    { id: 15, x: 148,  y: 805 },
    { id: 16, x: 185,  y: 784 },
    { id: 17, x: 222,  y: 752 },
    { id: 18, x: 253,  y: 710 },
    { id: 19, x: 239,  y: 653 },
    { id: 20, x: 202,  y: 586 },
    { id: 21, x: 146,  y: 523 },
    { id: 22, x: 89,   y: 474 },
    { id: 23, x: 38,   y: 444 },
    { id: 24, x: -15,  y: 404 },
    { id: 25, x: -56,  y: 355 },
    { id: 26, x: -95,  y: 298 },
    { id: 27, x: -126, y: 245 },
    { id: 28, x: -112, y: 229 },
    { id: 29, x: -125, y: 203 },
    { id: 30, x: -164, y: 208 }
  ]
};
