import React from 'react';
import PropTypes from 'prop-types';

import VideoPopup from '~/components/VideoPopup/VideoPopup';
import TrainerSuccess from '~/components/TrainerSuccess/TrainerSuccess';
import TrainerDescription from '~/components/TrainerDescription/TrainerDescription';
import TrainerLosing from '~/components/TrainerLosing/TrainerLosing';
import MissionResult from '~/components/MissionResult/MissionResult';

import { END_MISSION_TEXT, OTHER_TRAINER_TEXT, NEXT_TASK_TEXT, REFRESH_TRAINER_TEXT } from './utils/constants';

const TrainerScreenWrapper = (props) => {
  const { mission, goToTrainersPage, changePopup, onFinishMission, popupName, handleDataLayer } = props;
  const isMission = Boolean(mission);
  const exitObj = {
    text: END_MISSION_TEXT,
    handler: () => {
      onFinishMission();
      changePopup("missionLosing");
    }
  };
  const otherTrainerObj = {
    text: OTHER_TRAINER_TEXT,
    handler: (event) => {
      goToTrainersPage();

      if (event) {
        const stageName = popupName === 'losing' ? 'page4B_regret' : 'page4A_congratulation';
        const eventValue = popupName === 'losing' ? 0 : 1;

        handleDataLayer({ stageName, eventValue, eventAction: 'another' });
      }
    }
  };

  return renderPopup({ ...props, isMission, exitObj, otherTrainerObj });
}

const renderPopup = (props) => {
  const { trainer, mission, popupName, profile, levelMaxValue, earnedSkills, missionSkills, otherTrainerObj,
          goToLocationPage, changePopup, isPopupShow, maxSkillsForMission, closeVideoPopup, isLastTrainer,
          isWinMission, completedMissions, missionStatus, isMission, exitObj, handleDataLayer, adviceSkills } = props;
  const nextButton = getNextButton(props);
  const endMissionText = isMission ? isWinMission ? mission.textSuccess : mission.textLosing : '';

  switch(popupName) {
    case 'missionDescription':
      return <TrainerDescription
        title={ mission.name }
        trainer={ trainer }
        text={ mission.partingWords }
        onClick={ () => changePopup('description') }
        isPopupShow={ isPopupShow }
        cyButton='start'
        isMission={ isMission }
        isVideo={ false }
        isMissionPopup={ true }
        handleDataLayer={ handleDataLayer }
      />;
    case 'description':
      return <TrainerDescription
        trainer={ trainer }
        onClick={ changePopup }
        isPopupShow={ isPopupShow }
        cyButton='start'
        isMission={ isMission }
        popupName={ popupName }
        isVideo={ true }
        handleDataLayer={ handleDataLayer }
      />;
    case 'video':
      return  <VideoPopup
        videoAnnounceUrl={ trainer.videoAnnounceUrl }
        onSkipButtonClick={ closeVideoPopup }
        isPopupShow={ isPopupShow }
        startsImmediately={ trainer.params.startsImmediately }
        cyButton='skip-video'
      />
    case 'losing':
      return <TrainerLosing
        buttonText={ nextButton.title }
        trainer={ trainer }
        isPopupShow={ isPopupShow }
        onButtonClick={ nextButton.handler }
        mission={ mission }
        exitButton={ isMission ? exitObj : otherTrainerObj }
      />;
    case 'victory':
    case 'partially':
      return <TrainerSuccess
        gift={ mission ? mission.gift : null }
        trainer={ trainer }
        buttonText={ nextButton.title }
        isPopupShow={ isPopupShow }
        maxSkillsForMission={ maxSkillsForMission }
        partially={ popupName === 'partially' }
        buttonOnClick={ nextButton.handler }
        mission={ mission }
        profile={ profile }
        levelMaxValue={ levelMaxValue }
        earnedSkills={ earnedSkills }
        isLastTrainer={ isLastTrainer }
        exitButton={
          isLastTrainer && isWinMission ? null : (mission ? exitObj : otherTrainerObj)
        }
      />;
    case 'missionLosing':
    case 'missionSuccess':
      return <MissionResult
        isWinMission={ isWinMission }
        isPopupShow={ isPopupShow }
        text={ endMissionText }
        mission={ mission }
        buttonText={ END_MISSION_TEXT }
        buttonOnClick={ goToLocationPage }
        profile={ profile }
        levelMaxValue={ levelMaxValue }
        earnedSkills={ missionSkills }
        isGamePointsCanBeEarned={ !completedMissions[mission.id] }
        maxSkillsForMission={ maxSkillsForMission }
        missionStatus={ missionStatus }
        adviceSkills={ adviceSkills }
      />;
    default:
      return null;
  }
};

const getNextButton = (props) => {
  const { mission, isLastTrainer, goToNextTrainer, isWinMission, handleDataLayer,
          onFinishMission, popupName, refreshTrainer } = props;
  const goodPopupNames = [ 'losing', 'partially', 'victory' ];
  let title = '';
  let handler = null;
  const stageName = popupName === 'losing' ? 'page4B_regret' : 'page4A_congratulation';
  const eventValue = popupName === 'losing' ? 0 : 1;
  const handleDataAgain = () => handleDataLayer({ stageName, eventValue, eventAction: 'again' });

  if (mission) {
    if (!isLastTrainer) {
      title = NEXT_TASK_TEXT;
      handler = () => {
        goToNextTrainer();
        handleDataAgain();
      }
    }

    if (isLastTrainer && isWinMission) {
      title = END_MISSION_TEXT;
      handler = () => {
        onFinishMission();
        handleDataAgain();
      }
    }
  }

  if (!mission && goodPopupNames.includes(popupName)) {
    title = REFRESH_TRAINER_TEXT;
    handler = () => {
      refreshTrainer();
      handleDataAgain();
    }
  }

  return { title, handler };
};

TrainerScreenWrapper.propTypes = {
  trainer: PropTypes.shape({}),
  popupName: PropTypes.string,
  currentTrainerIndex: PropTypes.number,
  completedMissions: PropTypes.shape({}),
  maxSkillsForMission: PropTypes.shape({})
}

export default TrainerScreenWrapper
