import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import Button from '~/components/Button/Button';
import TrainerCard from '~/components/TrainerCard/TrainerCard';

import detectDevice from '~/services/detect-device';

import { getTrainersArray } from '~/store/trainers/selector';

import './trainSkills.scss';

const TrainSkills = (props) => {
  const handleClick = () => {
    window.dataLayer.push({ event: 'click', eventCategory: 'Train', eventAction: 'catalog', eventLabel: 'main' });
  };

  return (
    <section className="train-skills">
      <h2 className="train-skills__title">Тренируй навыки</h2>

      <p className="train-skills__text">
        Регулярные тренировки навыков сделают тебя лучше всех в любой сфере
      </p>

      <div className="train-skills__list">
        { renderTrainers(props) }
      </div>

      <div className="train-skills__link">
        <Button link="/trainers/" onClick={ handleClick }>
          еще тренажеры
        </Button>
      </div>
    </section>
  );
};

const renderTrainers = ({ trainers }) => {
  const { isMobile } = detectDevice();
  const classesCard = cx('train-skills__item-card', {
    'train-skills__item-card_mobile': isMobile
  });

  return trainers.map((item, index) => {
    const { id, previewUrl, tooltipAnnounce, params, eventCategory } = item;
    const { name, maxSkills } = params;

    return (
      <TrainerCard className={ classesCard } key={ index } id={ id } title={ name } skillPoints={ maxSkills }
                   previewUrl={ previewUrl } tooltipAnnounce={ tooltipAnnounce } train={ true }
                   isMobile={ isMobile } mobileHeight={ 292 } eventCategory={ eventCategory }/>
    );
  });
};

function mapStateToProps(state) {
  return {
    trainers: getTrainersArray(state).slice(0, 3)
  };
};

export default connect(mapStateToProps)(TrainSkills);
