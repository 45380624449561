export const skillLevelNames = {
  0: 'Новичок',
  1: 'Спецагент',
  2: 'Суперагент',
  3: 'Старший агент',
  4: 'Наставник'
}

export const skillLevelLabels = {
  0: 'get_novichok',
  1: 'get_specagent',
  2: 'get_superagent',
  3: 'get_starshij_agent',
  4: 'get_nastavnik'
};

export const gameLevelValues = {
  0: 0,
  1: 8,
  2: 10,
  3: 15,
  4: 20
}
