import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import GreatButtonPopup from '~/components/GreatButtonPopup/GreatButtonPopup';
import Popup from '~/components/Popup/Popup';
import GiftRays from '~/components/GiftRays/GiftRays';
import GiftGot from '~/components/GiftGot/GiftGot';

import './giftGotPopup.scss';

const GiftGotPopup = (props) => {
  const { gift, isOpen } = props;
  const [ isShow, setShow ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 300);
  }, []);

  const classes = cx('gift-got-popup', 'scale', {
    'gift-got-popup_visible': isShow
  });

  return (
    <Popup className={ classes } isOpen={ isOpen }>
      <div className="gift-got-popup__holder">
        <div className="gift-got-popup__text-box">
          <div className="gift-got-popup__title">Ура!</div>
          <div className="gift-got-popup__sub-title">Ты заработал { gift.name }!</div>
        </div>
        <div className="gift-got-popup__gift">
          <GiftGot gift={ gift } withContainer={ true }/>
        </div>
        <div className="gift-got-popup__rays">
          <GiftRays />
        </div>
        <GreatButtonPopup className="gift-got-popup__button" text="Забрать"/>
      </div>
    </Popup>
  );
};

export default GiftGotPopup;
