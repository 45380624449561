export default {
  image: require('~/static/trainers/funnyPoints/wolf.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -141, y: 735 },
    { id: 2,  x: -173, y: 698 },
    { id: 3,  x: -201, y: 646 },
    { id: 4,  x: -217, y: 572 },
    { id: 5,  x: -228, y: 612 },
    { id: 6,  x: -238, y: 564 },
    { id: 7,  x: -230, y: 502 },
    { id: 8,  x: -196, y: 439 },
    { id: 9,  x: -267, y: 464 },
    { id: 10, x: -219, y: 411 },
    { id: 11, x: -179, y: 361 },
    { id: 12, x: -153, y: 290 },
    { id: 13, x: -110, y: 134 },
    { id: 14, x: -64,  y: 144 },
    { id: 15, x: -60,  y: 180 },
    { id: 16, x: -43,  y: 154 },
    { id: 17, x: -20,  y: 163 },
    { id: 18, x: -26,  y: 242 },
    { id: 19, x: -27,  y: 314 },
    { id: 20, x: -26,  y: 391 },
    { id: 21, x: -23,  y: 457 },
    { id: 22, x: -15,  y: 511 },
    { id: 23, x: -3,   y: 575 },
    { id: 24, x: 13,   y: 629 },
    { id: 25, x: 38,   y: 685 },
    { id: 26, x: 69,   y: 733 },
    { id: 27, x: 89,   y: 759 },
    { id: 28, x: 135,  y: 750 },
    { id: 29, x: 178,  y: 712 },
    { id: 30, x: 206,  y: 666 },
    { id: 31, x: 207,  y: 601 },
    { id: 32, x: 182,  y: 542 },
    { id: 33, x: 137,  y: 487 },
    { id: 34, x: 221,  y: 523 },
    { id: 35, x: 265,  y: 575 },
    { id: 36, x: 294,  y: 656 },
    { id: 37, x: 268,  y: 696 },
    { id: 38, x: 292,  y: 713 },
    { id: 39, x: 226,  y: 764 },
    { id: 40, x: 91,   y: 785 }
  ]
};
