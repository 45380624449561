export default {
  4: {
    315: {
      45: {
        desktop: [63.5, -63.5],
        tablet: [56.5, -56.5],
        mobile: [42.5, -42.5]
      },
      135: {
        desktop: [127.5, 0],
        tablet: [113, 0],
        mobile: [85, 0]
      },
      225: {
        desktop: [63.5, 63.5],
        tablet: [56.5, 56.5],
        mobile: [42.5, 42.5]
      }
    },
    0: {
      90: {
        desktop: [90, 0],
        tablet: [80, 0],
        mobile: [60, 0]
      },
      180: {
        desktop: [90, 90],
        tablet: [80, 80],
        mobile: [60, 60]
      },
      270: {
        desktop: [0, 90],
        tablet: [0, 80],
        mobile: [0, 60]
      }
    }
  },
  2: {
    0: {
      180: {
        desktop: [45, 134.5],
        tablet: [40, 120],
        mobile: [30, 90]
      }
    },
    45: {
      225: {
        desktop: [-62.5, 127],
        tablet: [-55.5, 113.5],
        mobile: [-43.5, 84.5]
      }
    },
    90: {
      270: {
        desktop: [-134, 45],
        tablet: [-120, 40],
        mobile: [-90, 30]
      }
    },
    135: {
      315: {
        desktop: [-127, -62.5],
        tablet: [-113.5, -55.5],
        mobile: [-84, -43.5]
      }
    },
    180: {
      0: {
        desktop: [-45, -134.5],
        tablet: [-40, -120],
        mobile: [-30, -90]
      }
    },
    225: {
      45: {
        desktop: [62.5, -127],
        tablet: [55.5, -113.5],
        mobile: [43.5, -84.5]
      },
    },
    270: {
      90: {
        desktop: [135, -45],
        tablet: [120, -40],
        mobile: [90, -30]
      }
    },
    315: {
      135: {
        desktop: [127, 62.5],
        tablet: [113.5, 55.5],
        mobile: [84.5, 43.5]
      }
    }
  }
};
