export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    [
      {
        desktop: {
          x: 232.35,
          y: 70
        },
        tablet: {
          x: 319,
          y: 67
        },
        mobile: {
          x: 137,
          y: 75
        }
      },
      {
        desktop: {
          x: 187,
          y: 115.6
        },
        tablet: {
          x: 239,
          y: 147
        },
        mobile: {
          x: 167,
          y: 45
        }
      },
      {
        desktop: {
          x: 187,
          y: 295.36
        },
        tablet: {
          x: 247.28,
          y: 155.28
        },
        mobile: {
          x: 227,
          y: 45
        }
      },
      {
        desktop: {
          x: 277,
          y: 385.36
        },
        tablet: {
          x: 302.57,
          y: 155.28
        },
        mobile: {
          x: 167,
          y: 105
        }
      },
      {
        desktop: {
          x: 366.46,
          y: 295.89
        },
        tablet: {
          x: 358.85,
          y: 211.57
        },
        mobile: {
          x: 173.2,
          y: 111.2
        }
      },
      {
        desktop: {
          x: 366.46,
          y: 232
        },
        tablet: {
          x: 358.85,
          y: 267.15
        },
        mobile: {
          x: 213.82,
          y: 110.61
        }
      },
      {
        desktop: {
          x: 303.64,
          y: 169.18
        },
        tablet: {
          x: 279,
          y: 347
        },
        mobile: {
          x: 256.64,
          y: 153.43
        }
      },
      {
        desktop: {
          x: 240.82,
          y: 169.18
        },
        tablet: {
          x: 199,
          y: 267
        },
        mobile: {
          x: 256.64,
          y: 195.36
        }
      },
      {
        desktop: {
          x: 231.64,
          y: 160
        },
        tablet: {
          x: 199,
          y: 107
        },
        mobile: {
          x: 197,
          y: 255
        }
      },
      {
        desktop: {
          x: 321.64,
          y: 70
        },
        tablet: {
          x: 239,
          y: 67
        },
        mobile: {
          x: 137,
          y: 195
        }
      },
    ],
    [
      {
        desktop: {
          x: 276.59,
          y: 205.77
        },
        tablet: {
          x: 302.28,
          y: 211.57
        },
        mobile: {
          x: 214.21,
          y: 153.43
        }
      },
      {
        desktop: {
          x: 231.87,
          y: 250.49
        },
        tablet: {
          x: 278.36,
          y: 187.64
        },
        mobile: {
          x: 196.39,
          y: 135.61
        }
      },
      {
        desktop: {
          x: 277,
          y: 295.36
        },
        tablet: {
          x: 239,
          y: 227
        },
        mobile: {
          x: 167,
          y: 165
        }
      },
      {
        desktop: {
          x: 302.82,
          y: 295.36
        },
        tablet: {
          x: 279,
          y: 267
        },
        mobile: {
          x: 197,
          y: 195
        }
      },
      {
        desktop: {
          x: 302.82,
          y: 232
        },
        tablet: {
          x: 302.28,
          y: 267
        },
        mobile: {
          x: 214.21,
          y: 195
        }
      }
    ]
  ],
  outlines: [
    {
      desktop: {
        x: 240,
        y: 169.18,
        rotation: 45
      },
      tablet: {
        x: 246,
        y: 155.28,
        rotation: 45
      },
      mobile: {
        x: 172,
        y: 111.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 367,
        y: 295.36,
        rotation: -90
      },
      tablet: {
        x: 359,
        y: 267,
        rotation: -90
      },
      mobile: {
        x: 257,
        y: 195,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 187,
        y: 115.36,
        rotation: 0
      },
      tablet: {
        x: 199,
        y: 107,
        rotation: 0
      },
      mobile: {
        x: 137,
        y: 75,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 321.64,
        y: 160,
        rotation: -180
      },
      tablet: {
        x: 319,
        y: 147,
        rotation: 180
      },
      mobile: {
        x: 227,
        y: 105,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 277,
        y: 250.36,
        rotation: -90
      },
      tablet: {
        x: 279,
        y: 227,
        rotation: -90
      },
      mobile: {
        x: 197,
        y: 165,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 187,
        y: 70.36,
        rotation: 0
      },
      tablet: {
        x: 199,
        y: 67,
        rotation: 0
      },
      mobile: {
        x: 137,
        y: 45,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 271,
        y: 264,
        rotation: 45
      },
      tablet: {
        x: 274,
        y: 239.57,
        rotation: 45
      },
      mobile: {
        x: 193,
        y: 174.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
};
