import * as types from './constants';
import initialState from './initialState';

function profile(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SAVE_RUNNED_TRAINER:
      return {
        ...state,
        runnedTrainersIds: [ ...state.runnedTrainersIds, payload ]
      };

    case types.SAVE_PREV_SKILLS:
      return {
        ...state,
        prevSkills: payload
      };

    case `${types.FETCH_PROFILE}_PENDING`:
    case `${types.SAVE_TRAINER_PROGRESS}_PENDING`:
    case `${types.SAVE_MISSION_PROGRESS}_PENDING`:
    case `${types.SAVE_MISSION_PARAMS}_PENDING`:
      return {
        ...state,
        isPending: true
      };

    case `${types.FETCH_PROFILE}_FULFILLED`:
    case `${types.SAVE_TRAINER_PROGRESS}_FULFILLED`:
    case `${types.SAVE_MISSION_PROGRESS}_FULFILLED`:
    case `${types.SAVE_MISSION_PARAMS}_FULFILLED`:
      return {
        ...state,
        isRejected: false,
        isPending: false,
        ...payload,
        error: ''
      };

    case `${types.FETCH_PROFILE}_REJECTED`:
    case `${types.SAVE_TRAINER_PROGRESS}_REJECTED`:
    case `${types.SAVE_MISSION_PROGRESS}_REJECTED`:
    case `${types.SAVE_MISSION_PARAMS}_REJECTED`:
      return {
        ...state,
        isRejected: true,
        isPending: false,
        error: payload
      };

    case types.UPGRADE_SKILL: {
      const { skills } = state;
      const { skill, success, level } = payload;
      const currentSkill = skills[skill];

      return {
        ...state,
        skills: {
          ...skills,
          [skill]: {
            ...currentSkill,
            success,
            level
          }
        }
      };
    }

    case types.DOWNGRATE_SKILL: {
      const { skills } = state;
      const currentSkill = skills[payload];

      return {
        ...state,
        skills: {
          ...skills,
          [payload]: {
            ...currentSkill,
            success: 0
          }
        }
      };
    }

    default:
      return state
  }
}

export default profile;
