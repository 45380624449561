export default {
  image: require('~/static/trainers/funnyPoints/polter.png'),
  width: 800,
  height: 919,
  offsetY: 15,
  points: [
    { id: 1,  x: -289, y: 467 },
    { id: 2,  x: -325, y: 454 },
    { id: 3,  x: -310, y: 417 },
    { id: 4,  x: -265, y: 426 },
    { id: 5,  x: -239, y: 477 },
    { id: 6,  x: -216, y: 519 },
    { id: 7,  x: -157, y: 539 },
    { id: 8,  x: -181, y: 483 },
    { id: 9,  x: -217, y: 430 },
    { id: 10, x: -236, y: 363 },
    { id: 11, x: -234, y: 303 },
    { id: 12, x: -214, y: 242 },
    { id: 13, x: -180, y: 194 },
    { id: 14, x: -134, y: 159 },
    { id: 15, x: -72,  y: 152 },
    { id: 16, x: -17,  y: 156 },
    { id: 17, x: 24,   y: 180 },
    { id: 18, x: 60,   y: 217 },
    { id: 19, x: 79,   y: 263 },
    { id: 20, x: 87,   y: 320 },
    { id: 21, x: 84,   y: 380 },
    { id: 22, x: 67,   y: 436 },
    { id: 23, x: 57,   y: 499 },
    { id: 24, x: 93,   y: 473 },
    { id: 25, x: 113,  y: 423 },
    { id: 26, x: 123,  y: 369 },
    { id: 27, x: 138,  y: 310 },
    { id: 28, x: 186,  y: 312 },
    { id: 29, x: 190,  y: 352 },
    { id: 30, x: 160,  y: 360 }
  ]
};
