export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    desktop: 60,
    tablet: 60,
    mobile: 60
  },
  outline: [
    {
      desktop: {
        x: 366,
        y: 100
      },
      tablet: {
        x: 389.14,
        y: 171.57
      },
      mobile: {
        x: 253,
        y: 62
      }
    },
    {
      desktop: {
        x: 321.25,
        y: 145
      },
      tablet: {
        x: 277.14,
        y: 171.57
      },
      mobile: {
        x: 223,
        y: 92
      }
    },
    {
      desktop: {
        x: 232.64,
        y: 145
      },
      tablet: {
        x: 236.57,
        y: 131
      },
      mobile: {
        x: 163.43,
        y: 92
      }
    },
    {
      desktop: {
        x: 273.28,
        y: 185.64
      },
      tablet: {
        x: 315,
        y: 131
      },
      mobile: {
        x: 195.85,
        y: 124.43
      }
    },
    {
      desktop: {
        x: 399.28,
        y: 185.64
      },
      tablet: {
        x: 355,
        y: 91
      },
      mobile: {
        x: 279.85,
        y: 124.43
      }
    },
    {
      desktop: {
        x: 399.28,
        y: 311.68
      },
      tablet: {
        x: 196.57,
        y: 91
      },
      mobile: {
        x: 279.85,
        y: 208.15
      }
    },
    {
      desktop: {
        x: 355,
        y: 356
      },
      tablet: {
        x: 196.57,
        y: 204.14
      },
      mobile: {
        x: 250,
        y: 238
      }
    },
    {
      desktop: {
        x: 265.36,
        y: 356
      },
      tablet: {
        x: 308.57,
        y: 204.14
      },
      mobile: {
        x: 190.6,
        y: 238
      }
    },
    {
      desktop: {
        x: 202,
        y: 292.64
      },
      tablet: {
        x: 348.22,
        y: 243.78
      },
      mobile: {
        x: 148,
        y: 195.43
      }
    },
    {
      desktop: {
        x: 328.36,
        y: 292.64
      },
      tablet: {
        x: 325.43,
        y: 266.57
      },
      mobile: {
        x: 232.57,
        y: 195.43
      }
    },
    {
      desktop: {
        x: 353.68,
        y: 267.32
      },
      tablet: {
        x: 213,
        y: 266.57
      },
      mobile: {
        x: 249.29,
        y: 178.71
      }
    },
    {
      desktop: {
        x: 313.64,
        y: 227.28
      },
      tablet: {
        x: 269.43,
        y: 323
      },
      mobile: {
        x: 217.43,
        y: 146.85
      }
    },
    {
      desktop: {
        x: 187.64,
        y: 227.28
      },
      tablet: {
        x: 349,
        y: 323
      },
      mobile: {
        x: 133.43,
        y: 146.85
      }
    },
    {
      desktop: {
        x: 187.64,
        y: 100
      },
      tablet: {
        x: 389.14,
        y: 282.83
      },
      mobile: {
        x: 133.43,
        y: 62
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 314.92,
        y: 227.28,
        rotation: -135
      },
      tablet: {
        x: 309.71,
        y: 204.14,
        rotation: -135
      },
      mobile: {
        x: 218.28,
        y: 146.85,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 272,
        y: 185.64,
        rotation: 45
      },
      tablet: {
        x: 276,
        y: 171.57,
        rotation: 45
      },
      mobile: {
        x: 195,
        y: 124.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 265.64,
        y: 229,
        rotation: -45
      },
      tablet: {
        x: 269.57,
        y: 210,
        rotation: -45
      },
      mobile: {
        x: 190.43,
        y: 153,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 355,
        y: 311,
        rotation: -90
      },
      tablet: {
        x: 349,
        y: 283,
        rotation: -90
      },
      mobile: {
        x: 250,
        y: 208,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 276,
        y: 145,
        rotation: 90
      },
      tablet: {
        x: 275,
        y: 131,
        rotation: 90
      },
      mobile: {
        x: 193,
        y: 92,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 322,
        y: 100,
        rotation: -90
      },
      tablet: {
        x: 316,
        y: 91,
        rotation: -90
      },
      mobile: {
        x: 223,
        y: 62,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 309,
        y: 267,
        rotation: 0
      },
      tablet: {
        x: 309,
        y: 243,
        rotation: 0
      },
      mobile: {
        x: 220,
        y: 178,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
