import React from 'react';

import Popup from '~/components/Popup/Popup';
import Menu from '~/components/Menu/Menu';

import './menu-popup.scss';

const MenuPopup = (props) => {
  const { closeHandler, isOpen } = props;

  return (
    <Popup className="menu-popup" overlayClass="menu-popup__overlay" isOpen={ isOpen }>
      <Menu onRequestClose={ closeHandler } />
    </Popup>
  );
};

export default MenuPopup;
