export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 194.64,
        y: 26
      },
      tablet: {
        x: 205.57,
        y: 38
      },
      mobile: {
        x: 142.03,
        y: 30
      }
    },
    {
      desktop: {
        x: 131,
        y: 89.64
      },
      tablet: {
        x: 149,
        y: 94.57
      },
      mobile: {
        x: 99.61,
        y: 72.43
      }
    },
    {
      desktop: {
        x: 194.81,
        y: 87.17
      },
      tablet: {
        x: 205.57,
        y: 94.57
      },
      mobile: {
        x: 142.03,
        y: 70.61
      }
    },
    {
      desktop: {
        x: 321.28,
        y: 213.68
      },
      tablet: {
        x: 205.57,
        y: 156.43
      },
      mobile: {
        x: 225.85,
        y: 154.5
      }
    },
    {
      desktop: {
        x: 321.28,
        y: 340
      },
      tablet: {
        x: 166,
        y: 196
      },
      mobile: {
        x: 225.85,
        y: 238
      }
    },
    {
      desktop: {
        x: 409,
        y: 340
      },
      tablet: {
        x: 206,
        y: 236
      },
      mobile: {
        x: 285,
        y: 238
      }
    },
    {
      desktop: {
        x: 455,
        y: 385
      },
      tablet: {
        x: 206,
        y: 207.57
      },
      mobile: {
        x: 315,
        y: 268
      }
    },
    {
      desktop: {
        x: 239,
        y: 385.64
      },
      tablet: {
        x: 262.22,
        y: 263.78
      },
      mobile: {
        x: 171,
        y: 268.43
      }
    },
    {
      desktop: {
        x: 257.82,
        y: 366.82
      },
      tablet: {
        x: 223,
        y: 303
      },
      mobile: {
        x: 183.71,
        y: 255.71
      }
    },
    {
      desktop: {
        x: 213,
        y: 322
      },
      tablet: {
        x: 262.28,
        y: 342.28
      },
      mobile: {
        x: 155,
        y: 227
      }
    },
    {
      desktop: {
        x: 257.68,
        y: 277.32
      },
      tablet: {
        x: 245,
        y: 359.57
      },
      mobile: {
        x: 184.29,
        y: 197.71
      }
    },
    {
      desktop: {
        x: 195,
        y: 214.64
      },
      tablet: {
        x: 437,
        y: 359
      },
      mobile: {
        x: 142,
        y: 155.43
      }
    },
    {
      desktop: {
        x: 195,
        y: 248
      },
      tablet: {
        x: 397,
        y: 319
      },
      mobile: {
        x: 142,
        y: 184
      }
    },
    {
      desktop: {
        x: 150,
        y: 203.25
      },
      tablet: {
        x: 318.14,
        y: 319
      },
      mobile: {
        x: 112,
        y: 154
      }
    },
    {
      desktop: {
        x: 194.64,
        y: 158.36
      },
      tablet: {
        x: 318.14,
        y: 206.57
      },
      mobile: {
        x: 141.43,
        y: 124.57
      }
    },
    {
      desktop: {
        x: 194.64,
        y: 89.46
      },
      tablet: {
        x: 205.57,
        y: 94
      },
      mobile: {
        x: 141.43,
        y: 72.43
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 194,
        y: 213.64,
        rotation: 45
      },
      tablet: {
        x: 205,
        y: 206.57,
        rotation: 45
      },
      mobile: {
        x: 141,
        y: 154.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 321.92,
        y: 214.28,
        rotation: -135
      },
      tablet: {
        x: 318.71,
        y: 207.14,
        rotation: -135
      },
      mobile: {
        x: 226.28,
        y: 154.85,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 302.64,
        y: 449.28,
        rotation: 135
      },
      tablet: {
        x: 301.57,
        y: 416.14,
        rotation: 135
      },
      mobile: {
        x: 213.43,
        y: 310.85,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 150,
        y: 158,
        rotation: -0
      },
      tablet: {
        x: 166,
        y: 156,
        rotation: -0
      },
      mobile: {
        x: 112,
        y: 124,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 162.82,
        y: 121.46,
        rotation: 135
      },
      tablet: {
        x: 177.28,
        y: 122.85,
        rotation: 135
      },
      mobile: {
        x: 120.82,
        y: 93.64,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 455,
        y: 340,
        rotation: -90
      },
      tablet: {
        x: 437,
        y: 319,
        rotation: 90
      },
      mobile: {
        x: 315,
        y: 238,
        rotation: 90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 213,
        y: 277,
        rotation: 0
      },
      tablet: {
        x: 223,
        y: 263,
        rotation: 0
      },
      mobile: {
        x: 155,
        y: 197,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
