export default [
  { text: 'Воды в реке больше, чем в бокале? ', answer: true },
  { text: 'Гитара меньше флейты? ', answer: false },
  { text: 'Семь больше десяти? ', answer: false },
  { text: 'Пять котят больше, чем один пушистый котенок? ', answer: true },
  { text: 'Автобус выше машины «Лада»? ', answer: true },
  { text: 'Конь выше жеребенка? ', answer: true },
  { text: 'Один магнитик больше, чем три магнитика? ', answer: false },
  { text: 'Один миллион рублей больше, чем один рубль? ', answer: true },
  { text: 'Два метра желтой ткани больше, чем 6 метров зеленой ткани? ', answer: false },
  { text: 'Двадцать красных шариков больше, чем 2 белых шарика? ', answer: true },
  { text: 'Яблок больше в полной вазе, чем в руке? ', answer: true },
  { text: 'Медведь больше синички? ', answer: true },
  { text: 'Синица больше, чем медведь? ', answer: false },
  { text: 'Океан шире реки? ', answer: true },
  { text: 'Река шире океана? ', answer: false },
  { text: 'Ведро воды меньше, чем стакан воды? ', answer: false },
  { text: 'Одна машина больше, чем сто машин? ', answer: false },
  { text: 'Один слоник больше, чем два больших слона? ', answer: false },
  { text: 'В слове «Саша» меньше букв, чем в слове «Маша»? ', answer: false },
  { text: 'Если на пляже долго загорать, то солнце сожжет кожу и будет больно? ', answer: true },
  { text: 'Лед холодный, а чай горячий? ', answer: true },
  { text: 'На пляже песок горячий, а вода холоднее? ', answer: true },
  { text: 'Огонь сжигает лес, а вода тушит огонь? ', answer: true },
  { text: 'Мост для машин широкий, а для людей – узкий? ', answer: true },
  { text: 'Море большое, а озеро меньше? ', answer: true },
  { text: 'Территория страны Россия больше, чем территория страны Китай? ', answer: true },
  { text: 'Банан горький, а лук сладкий? ', answer: false },
  { text: 'Железо мягкое, а кисель твёрдый? ', answer: false },
  { text: 'У вишни косточка твердая, а мякоть мягкая? ', answer: true },
  { text: 'Снегопад делает сугробы, а дождь делает лужи? ', answer: true },
  { text: 'После января всегда наступает февраль? ', answer: true },
  { text: 'С января начинается новый год? ', answer: true },
  { text: 'Хлеб мягкий, а сухарик твердый? ', answer: true },
  { text: 'Один кусок хлеба меньше целой булки хлеба? ', answer: true },
  { text: 'Тыква большая, а картошка маленькая? ', answer: true },
  { text: 'Куст смородины меньше яблони? ', answer: true },
  { text: 'Колоновидная яблоня состоит из одной ветки? ', answer: true },
  { text: 'На одной яблоне яблок больше, чем ягод виктории на одном кустике? ', answer: true },
  { text: 'Лист клена весной зеленый, а осенью – желтый? ', answer: true },
  { text: 'Катер быстрее перевезет людей на другой берег, чем лодка с веслами? ', answer: true },
  { text: 'В квартире лучше когда тепло, чем холодно? ', answer: true },
  { text: 'В двухлитровую кастрюлю никак не поместятся 5 литров воды? ', answer: true },
  { text: 'В один стакан можно налить 5 литров воды? ', answer: false },
  { text: 'Зимой идёт снег, а летом – дождь? ', answer: true },
  { text: 'Щавель кислый, лук горький? ', answer: true },
  { text: 'Сырая морковь твердая, а вареная – мягкая? ', answer: true },
  { text: 'Капуста большая, а дом больше? ', answer: true },
  { text: 'Капуста большая, а редис маленький? ', answer: true },
  { text: 'Киви – это китайский крыжовник? ', answer: true },
  { text: 'Арбуз бывает сладкий и соленый? ', answer: true },
  { text: 'Арбуз бывает круглый и квадратный? ', answer: true },
  { text: 'Один килограмм меньше одной тонны? ', answer: true },
  { text: 'Лук сладкий, а мандарин горький? ', answer: false },
  { text: 'Борщ горячий, а лед холодный? ', answer: true },
  { text: 'Конфета сладкая, лимон кислый? ', answer: true },
  { text: 'Солнце горячее, мороженое холодное? ', answer: true },
  { text: 'Холодец холодный, чай горячий? ', answer: true },
  { text: 'Снег мягкий, льдинка твердая? ', answer: true },
  { text: 'Льдинка холодная, а снег теплый? ', answer: false },
  { text: 'Льдинка острая, и снежинка тоже? ', answer: false },
  { text: 'Лыжи скользят, санки тормозят? ', answer: false },
  { text: 'Лыжня узкая, дорога широкая? ', answer: true },
  { text: 'В небо надо смотреть вверх, а на землю – вниз? ', answer: true },
  { text: 'Одно ухо правое, а другое ухо уже левое? ', answer: true },
  { text: 'Голова волосатая, а мячик лысый? ', answer: true },
  { text: 'Варежки греют руки, а снег охлаждает руки? ', answer: true },
  { text: 'Мороз за нос «щиплет», замораживает, а солнце греет? ', answer: true },
  { text: 'Солнце раздевает человека, ветер заставляет одеться? ', answer: true },
  { text: 'Дуб высокий, а сирень низкая? ', answer: true },
  { text: 'Дуб большой, а желудь маленький? ', answer: true },
  { text: 'Ранка одна, а микробов в ней много? ', answer: true },
  { text: 'Луковица желтая, а перышки на ней вырастают зеленые? ', answer: true },
  { text: 'Корова черная, ест траву зеленую, а молоко дает белое? ', answer: true },
  { text: 'Один метр меньше одного сантиметра? ', answer: false },
  { text: 'Самолет летит быстрее, вертолет летит медленнее? ', answer: true },
  { text: 'Самолет быстрее гусеницы? ', answer: true },
  { text: 'Утка плавает медленнее, катер плавает быстрее? ', answer: true },
  { text: 'Град с неба падает жесткий, а снег падает мягкий? ', answer: true },
  { text: 'Пять тетрадей меньше, чем одна тетрадь? ', answer: false },
  { text: 'Слива маленькая, гранат большой? ', answer: true },
  { text: 'Гранат красный, банан желтый? ', answer: true },
  { text: 'Инжир сладкий, редька не сладкая? ', answer: true },
  { text: 'Банан мягкий, яблоко твердое? ', answer: true },
  { text: 'Варенец сладкий, кефир кислый? ', answer: true },
  { text: 'Грейпфрут кислый, дыня сладкая? ', answer: true },
  { text: 'Дыня сладкая, крыжовник кислый? ', answer: true },
  { text: 'Желе мягкое, гранат твердый? ', answer: true },
  { text: 'Земляника мелкая, хурма крупная? ', answer: true },
  { text: 'Зефир мягкий, а яблоко твердое? ', answer: true },
  { text: 'Сахар сладкий, а перец горький? ', answer: true },
  { text: 'Торт сладкий, а киви кислый? ', answer: true },
  { text: 'Хурма оранжевая, а гранат красный? ', answer: true },
  { text: 'Кактус колючий, кролик пушистый? ', answer: true },
  { text: 'Заяц прыгает, ёжик бегает? ', answer: true },
  { text: 'Маленькие тигрята – забавные «милашки», а взрослый тигр – опасный зверь? ', answer: true },
  { text: 'Один рубль меньше двух рублей? ', answer: true },
  { text: 'Неделя больше одного дня? ', answer: true },
  { text: 'Перина маленькая, а подушка большая? ', answer: false },
  { text: 'Лягушка мычит, а корова квакает? ', answer: false },
  { text: 'Птица – курица, а птенец – петух? ', answer: false },
  { text: 'Птица – утка, а птенец – гусенок? ', answer: false },
  { text: 'Корова защищается рогами, а кошка – усами? ', answer: false },
  { text: 'Кошка защищается когтями, а кот – рогами? ', answer: false },
  { text: 'Много лошадей – табун, а много коров – отара? ', answer: false },
  { text: 'Много коров – стадо, а много овец – косяк? ', answer: false },
  { text: 'Много овец – отара, а много рыб – стая? ', answer: false },
  { text: 'Много собак – свора, а много птиц – стадо? ', answer: false },
  { text: 'Курица кудахчет, а воробей стрекочет? ', answer: false },
  { text: 'Воробей чирикает, а сорока рычит? ', answer: false },
  { text: 'Лев рычит, а кузнечик мычит? ', answer: false },
  { text: 'Рыба молчит, а лошадь лает? ', answer: false },
  { text: 'Корова мычит, а кошка стрекочет? ', answer: false },
  { text: 'Гусь крякает, а утка гогочет? ', answer: false },
  { text: 'Медведь насекомое, а муравей хищный зверь? ', answer: false },
  { text: 'Супермаркет большой, мегамаркет маленький? ', answer: false },
  { text: 'На дубе – шишки, а на ели – желуди? ', answer: false },
  { text: 'Шьют оладьи, а пекут вещи? ', answer: false },
  { text: 'Сосулька горячая, а пирожок холодный? ', answer: false },
  { text: 'Игла тупая, а молоток острый? ', answer: false },
  { text: 'На шампурах готовят суп, а в кастрюле – шашлык? ', answer: false },
  { text: 'У человека две руки и три ноги? ', answer: false },
  { text: 'Первый месяц года – январь, а последний – ноябрь? ', answer: false },
  { text: 'Первый день недели – понедельник, а последний – пятница? ', answer: false },
  { text: 'Днем темно, а ночью светло? ', answer: false },
  { text: 'Леопард полосатый, а тигр в пятнышко? ', answer: false },
  { text: 'Тигр в полосочку, а зебра в кружочек? ', answer: false },
  { text: 'В неделе семь суток, а в сутках 23 часа? ', answer: false },
  { text: 'В России – рубли, а в Америке – гривны? ', answer: false },
  { text: 'В Америке – юани, а в Китае – доллары? ', answer: false },
  { text: 'Арбуз маленький, а земляника большая? ', answer: false },
  { text: 'Щи со свеклой, а борщ – без свеклы? ', answer: false },
  { text: 'Сова ранняя птица, а жаворонок – ночная? ', answer: false },
  { text: 'У тигра кисточки на ушах, а у рыси полоски? ', answer: false },
  { text: 'Доску выстругал маляр, а стену выкрасил столяр? ', answer: false },
  { text: 'В доме свет провел шахтер, в шахте трудится монтер? ', answer: false },
  { text: 'Гепард медленный, а улитка быстрая? ', answer: false },
  { text: 'Один килограмм металла тяжелее, чем один килограмм пуха? ', answer: false },
  { text: 'У кенгуру – горб, а у верблюда сумка? ', answer: false },
  { text: 'У пингвина шея длинная, а у жирафа короткая? ', answer: false },
  { text: 'Бодрствуют с закрытыми глазами, а спят с открытыми? ', answer: false },
  { text: 'Внучка старенькая, а бабушка молоденькая? ', answer: false },
  { text: 'Внук старенький, а дедушка молоденький? ', answer: false },
  { text: 'Заяц храбрый, а лиса трусливая? ', answer: false },
  { text: 'Корова – хищница, а волк – травоядный? ', answer: false },
  { text: 'Корова стучит, а дятел мычит? ', answer: false },
  { text: 'Гитара маленькая, а укулеле большая? ', answer: false },
  { text: 'Суп холодный, а мороженое горячее? ', answer: false },
  { text: 'Дорога широкая, а тропинка узкая? ', answer: true },
  { text: 'Апельсин горький, а лук сладкий? ', answer: false },
  { text: 'Яблоко твердое, а слива мягкая? ', answer: true },
  { text: 'Капуста большая, а картошка маленькая? ', answer: true },
  { text: 'Чеснок сладкий, а персик горький? ', answer: false },
  { text: 'Слива маленькая, а арбуз большой? ', answer: true },
  { text: 'Персик мягкий, а яблоко жесткое? ', answer: true },
  { text: 'Зимой жарко, а летом холодно? ', answer: false },
  { text: 'Куст низкий, а дерево высокое? ', answer: true },
  { text: 'Пять груш больше, чем пять яблок? ', answer: false },
  { text: 'Один меньше, чем много? ', answer: true },
  { text: 'Лужа большая, а море маленькое? ', answer: false },
  { text: 'Картофель крупный, а редис мелкий? ', answer: true },
  { text: 'Перец внутри пустой, а морковь полная? ', answer: true },
  { text: 'Помидор мягкий, а огурец твердый? ', answer: true },
  { text: 'Персик большой, а абрикос маленький? ', answer: true },
  { text: 'Апельсин круглый, а банан длинный? ', answer: true },
  { text: 'Лимон кислый, а персик сладкий? ', answer: true },
  { text: 'Куст высокий, а дерево низкое? ', answer: false },
  { text: 'Кошка колючая, а ёжик пушистый? ', answer: false }
];
