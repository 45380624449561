import kremlin from './missions/kremlin';
import ostankino from './missions/ostankino';
import moscowCity from './missions/moscow-city';

export default {
  id: 2,
  slug: 'moscow',
  distance: 2501,
  city: 'Москва',
  accusativeCity: 'Москву',
  country: 'Россия',
  missions: {
    kremlin,
    ostankino,
    moscowCity
  },
  background: require('~/static/locations/moscow/moscow-background.jpg'),
  images: [
    require('~/static/locations/moscow/background1_2880.jpg'),
    require('~/static/locations/moscow/background2_2880.jpg'),
    require('~/static/locations/moscow/background3_2880.jpg')
  ],
  description: 'Знаешь, что Мистер Фоксман был наставником самых известных шпионов и дипломатов? Некоторых из них нам даже нельзя здесь называть. Но уж они-то свои секретные навыки не забывают. Пришло время показать, насколько ты успел развить свои!',
  infoDescription: 'Древний город Москва — столица России — место невероятно интересное. Исторический центр тут соседствует с современными небоскрёбами, а панельные пятиэтажки советских времён могут стоять рядом со сверкающим стеклом и сталью бизнес-центров. Этот город стал домом для многих миллионов жителей, и он по праву входит в число самых интересных мегаполисов мира.'
};
