export default (arr) => [ ...arr.map(item => {
  for (let key in item) {
    if (Array.isArray(item[key])) {
      item[key] = [ ...item[key] ];
    }
    else if (typeof(item[key]) === 'object') {
      item[key] = { ...item[key] };
    }
  }

  return { ...item };
}) ];
