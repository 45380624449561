import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

import words from './words';

const templateLevels = {
  1: {
    seconds: 60,
    wordsAmount: 4
  },
  2: {
    seconds: 60,
    wordsAmount: 6
  },
  3: {
    seconds: 100,
    wordsAmount: 4
  },
  4: {
    seconds: 100,
    wordsAmount: 6
  },
  5: {
    seconds: 120,
    wordsAmount: 4
  },
  6: {
    seconds: 120,
    wordsAmount: 6
  },
  7: {
    seconds: 150,
    wordsAmount: 4
  },
  8: {
    seconds: 150,
    wordsAmount: 6
  },
  9: {
    seconds: 300,
    wordsAmount: 5
  },
  10: {
    seconds: 300,
    wordsAmount: 5
  }
};

export default getLevels(templateLevels, getLevelParams, { name: 'wordsArray', data: words});
