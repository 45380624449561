import React from 'react';

import './lines.scss';

const Lines = (props) => {
  const { resultMode, difficultyParams, clickedPoints } = props;
  return (
    <svg className="lines">
      {
        resultMode ?
        renderPolyline(difficultyParams.points) :
        (clickedPoints.length > 0 && renderPolyline(clickedPoints))
      }
    </svg>
  )
}

const renderPolyline = (points) => {
  const currentPoints = points.reduce((acc, curLine) => `${acc} ${curLine.x}, ${curLine.y}`, '');
  return (
    <polyline
      className="lines__line lines__line_black"
      points={ currentPoints }
    />
  );
}

export default Lines;
