export default [
  {
    name: 'organicFood',
    count: 35
  },
  {
    name: 'cuteAnimals',
    count: 50
  },
  {
    name: 'cuteAnimals2',
    count: 50
  }
];
