import * as transforms from './transforms';

import getRandomIndex from '~/services/get-random-index';
import getOptionsCell from './get-options-cell';

import { SHUFFLE_COUNT, DEFAULT_FIELD } from '../constants';

const shuffleField = () => {
  let field = [ ...DEFAULT_FIELD.map(item => [ ...item ]) ];

  for (let i = 0; i < SHUFFLE_COUNT; i++) {
    const arrayTransforms = Object.keys(transforms);
    const randomTransform = arrayTransforms[getRandomIndex(arrayTransforms)];

    field = transforms[randomTransform]({ field, size: field.length });
  }

  return field;
};

export default (pattern, numbers) => {
  let field = shuffleField();

  for (let i = 0; i < field.length; i++) {
    for (let j = 0; j < field.length; j++) {
      if (!pattern[i * field.length + j]) {
        field[i][j] = {
          value: null
        };
        field[i][j].notes = new Set();
      }
    }
  }

  for (let i = 0; i < field.length; i++) {
    for (let j = 0; j < field.length; j++) {
      if (!pattern[i * field.length + j]) {
        field[i][j].options = getOptionsCell(i, j, field, numbers);
      }
    }
  }

  return field;
};
