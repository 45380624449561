export default [
  {
    name: 'Карта локаций',
    link: '/locations/'
  },
  {
    name: 'тренажеры',
    link: '/trainers/'
  }
];
