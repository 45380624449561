export default [
  {
    id: 1,
    image: require('~/static/trainers/longMemory/pekin/chinese-wall.jpg'),
    name: 'Великая Китайская стена',
    text: 'Великая Китайская стена великой считается по праву, ведь это самая большая постройка людей за всю историю человечества! Длина стены около 9 тысяч километров. Если пройти такое расстояние, то на это уйдет почти  200 дней! Ширина стены до 9 метров,  высота — до 10 метров. Началось строительство стены еще за 220 лет до нашей эры! Строили Великую стену почти миллион человек.'
  },
  {
    id: 2,
    image: require('~/static/trainers/longMemory/pekin/forbidden-city.jpg'),
    name: 'Запретный город',
    text: 'Запретный город — это крупнейший на планете дворцовый комплекс. С 1420 по 1912 годы Запретный город был не только местом жительства императоров и членов их семей, но и центром китайского правительства. В нем расположены 980 сооружений! А еще в Запретном городе были библиотеки и театры. Назвали этого место так, потому что никто не мог войти во дворец или покинуть его без высочайшего дозволения. В наши дни насладиться величием Запретного города может любой желающий.'
  },
  {
    id: 3,
    image: require('~/static/trainers/longMemory/pekin/beijing-national-stadium.jpg'),
    name: 'Стадион «Птичье гнездо»',
    text: 'Национальный стадион, также известный как «Птичье гнездо», — грандиозный стадион, созданный к проведению Летних Олимпийских игр 2008 года. Он считается самой большой стальной конструкцией в мире, созданной для спортивных мероприятий. При этом он абсолютно безопасен даже при мощном землетрясении.'
  },
  {
    id: 4,
    image: require('~/static/trainers/longMemory/pekin/temple-heaven.jpg'),
    name: 'Храм небес',
    text: 'Комплекс «Храм небес» был построен в 1420 году. В течение почти 500 лет раз в году, в день зимнего солнцестояния, императоры, сыновья неба, прибывали сюда, чтобы после строгого поста принести небу щедрые дары. Молитвами о процветании государства властитель заклинал ветер, дождь, жару и холод— это было залогом хорошего урожая. В наше время территория храма открыта для посещения туристов со всего мира и местных жителей.'
  },
  {
    id: 5,
    image: require('~/static/trainers/longMemory/pekin/imperial-palace.jpg'),
    name: 'Летний императорский дворец',
    text: 'Летний императорский дворец — это один из самых выдающихся императорских садов в Китае. Парк служил резиденцией и садом для китайских императоров, а озеро играло важную роль в водоснабжении Пекина и транспортным сообщением с его окрестностями. Говорят, что один раз побывав здесь, вы посетили все красивые места в Китае.'
  },
  {
    id: 6,
    image: require('~/static/trainers/longMemory/pekin/art-zone.jpg'),
    name: 'Арт-зона 798',
    text: 'Арт-зона 798 — это квартал искусств в Пекине. Здесь находятся много студий, галерей, выставок и мастерских. Изюминка места в том, что все эти объекты располагаются в цехах и подсобках заброшенного завода. Арт-зону уже посетили миллионы людей, включая мировых звезд кино и глав государств. Примечательно, что вход на территорию зоны свободный и бесплатный.'
  },
  {
    id: 7,
    image: require('~/static/trainers/longMemory/pekin/pool.jpg'),
    name: '«Водный куб»',
    text: 'Национальный плавательный комплекс в народе называют «Водным кубом». Снаружи здание как будто сделано из мыльных пузырей. Построили его к Олимпиаде 2008 года в Пекине. Комплекс был предназначен для прыжков в воду, синхронного и других видов плавания. Во время Олимпийских игр здесь было установлено 25 мировых рекордов! А еще «Водный куб» работает от солнечной энергии и является самым экологичным аквапарком в мире.'
  },
  {
    id: 8,
    image: require('~/static/trainers/longMemory/pekin/zoo.jpg'),
    name: 'Пекинский зоопарк',
    text: 'Пекинский зоопарк — настоящая национальная гордость! Зоопарк пользуется популярностью как у жителей Пекина, так и у туристов. Конечно, здесь много детей. На территории зоопарка создано несколько тематических секций: павильон гигантской панды, холм обезьян, гора медведей, холм львов и тигров, озеро водяных птиц, африканская зона, вольер жирафов, павильон золотых обезьян и другие. Здесь разводят различных животных, ведется большая научная и образовательная работа.'
  },
  {
    id: 9,
    image: require('~/static/trainers/longMemory/pekin/confucius.jpg'),
    name: 'Храм Конфуция',
    text: 'Храм построили в 1306 году в честь великого философа-ученого. Всё внешнее и внутреннее оформление огромного храма направлено на воспевание ученых и учения. У входа можно прочитать мудрые высказывания и стихотворения, начертанные на многочисленных камнях-барабанах.'
  },
  {
    id: 10,
    image: require('~/static/trainers/longMemory/pekin/trausers.jpg'),
    name: 'Штаб-квартира центрального китайского телевидения (CCTV)',
    text: 'В Пекине присутствуют не только памятники древности, но и современные небоскребы, поражающие своей высотой и необычной конструкцией. Например, штаб-квартиру центрального китайского телевидения (CCTV) по праву можно назвать современной достопримечательностью китайской столицы. Здание выглядит как две гигантские буквы L, соединенные вверху. Пекинцы прозвали столь неординарную конструкцию «штанами», которыми всё же гордятся. Это необычное здание высотой 54 этажа очень устойчиво и способно выдержать любое землетрясение.'
  },
  {
    id: 11,
    image: require('~/static/trainers/longMemory/pekin/wangjing-soho.jpg'),
    name: 'Небоскребы Ваньцзин Сохо',
    text: 'Есть в Пекине район Ваньцзин, в котором 3 неординарных офисных здания были возведены для различных международных компаний. В возведенных алюминиево-стеклянных громадах нет ни одного прямого угла. Глядя издали на эти фантастические строения, в которых не видно традиционных окон, трудно представить себе внутреннюю планировку. Китайцы сравнивают здания с прудовыми карпами — у них эти рыбы ассоциируются с богатством и удачей.'
  },
  {
    id: 12,
    image: require('~/static/trainers/longMemory/pekin/centre-of-art.jpg'),
    name: 'Национальный центр исполнительских искусств',
    text: 'Национальный центр исполнительских искусств был построен к пекинской Олимпиаде 2008 года. Это грандиозное сооружение расположено посреди искусственного водоема. Благодаря отражению в воде, кажется, что строение имеет эллипсоидную форму. Вот что интересно: чтобы попасть в здание, нужно пройти по длинному подводному туннелю. Подземная часть центра уходит под воду на глубину около 33 метров (задумайтесь, это почти целый десятиэтажный дом). Национальный центр исполнительских искусств вмещает в себя три главных зрительских зала: оперный, концертный и театральный. Именно на закате, в огнях вечерней подсветки, кажется, как будто светящийся мерцающими звездочками инопланетный корабль спустился с небес и приземлился посреди озера.'
  },
  {
    id: 13,
    image: require('~/static/trainers/longMemory/pekin/17-bridge.jpg'),
    name: 'Семнадцатиарочный мост',
    text: 'Семнадцатиарочный мост расположен недалеко от центра Пекина и является самым большим мостом в Летнем дворце. Его длина составляет сто пятьдесят метров, а ширина — семь метров. Он был построен в восемнадцатом веке при императоре Цяньлуне во времена правления династии Цин. Семнадцатиарочный мост является имитацией известного пекинского моста Марко Поло. Он украшен белыми мраморными балюстрадами с вырезанными 544 львами в разных позах. Этот мост похож на радугу, связывающую мир людей со сказочной страной.'
  },
  {
    id: 14,
    image: require('~/static/trainers/longMemory/pekin/happy-valley.jpg'),
    name: 'Парк развлечений «Долина счастья»',
    text: 'Парк развлечений «Долина счастья» стал альтернативой Диснейленду и начал принимать посетителей с 2006 года. Площадь — 500 тысяч м². Разделен на 6 зон, каждая из которых имеет свою тематику и соответствующий внешний вид. Древняя Греция, Майя, Дикий мир, Атлантида, Муравьиная страна, Сказочное королевство включают в себя около сотни аттракционов. Помимо этого в парке есть кафе, магазины, места для отдыха.'
  },
  {
    id: 15,
    image: require('~/static/trainers/longMemory/pekin/park-ruine.jpg'),
    name: 'Парк руин',
    text: 'Садово-дворцовый комплекс был заложен в 1707 году. Он включал дворцы, религиозные постройки, искусственные водоемы. Был разрушен в середине девятнадцатого века. Не восстанавливался, но некоторые детали ландшафта хорошо прорисовываются и по сей день. Даже развалины дворца, возведенного в европейском стиле, выглядят аккуратными и живописными. Парк посещаем туристами наравне с другими красотами города.'
  },
  {
    id: 16,
    image: require('~/static/trainers/longMemory/pekin/beyhay.jpg'),
    name: 'Парк Бэйхай',
    text: 'Парк Бэйхай находится рядом с Запретным городом. Площадь его около 69 гектар. Одноименное озеро занимает примерно половину территории. Парк имеет долгую историю, начиная с десятого века, но широкую публику сюда допустили только в 1925 году. Здесь немало знаковых объектов, например: 40-метровая ступа Бай Та, буддийский храм Юйнань, Павильон 5 драконов, камни Тайху и «сад в саду» под названием Зал Цзинсинь.'
  },
  {
    id: 17,
    image: require('~/static/trainers/longMemory/pekin/garden.jpg'),
    name: 'Пекинский ботанический сад',
    text: 'Территория смешанная — горно-равнинная. Площадь — около 130 гектар. Сад разделен на зоны: места для экспериментов, туристические сегменты и те, куда доступ ограничен. Растений — порядка 3 тысяч видов. Сад бонсай может похвастаться деревьями, которым больше тысячи лет. А персиковых деревьев собрано 60 сортов. В саду работают кафе и рестораны, а также проложены дорожки с подробными указателями для удобства туристов.'
  },
  {
    id: 18,
    image: require('~/static/trainers/longMemory/pekin/mao-dzedun.jpg'),
    name: 'Мавзолей Мао Цзэдуна',
    text: 'В центре Пекина находится одна из самых посещаемых достопримечательностей Китая — мавзолей вождя Мао Цзэдуна. Здесь под красным флагом, в саркофаге из хрусталя покоится тело бывшего коммунистического лидера и создателя современного китайского государства. Каждый год посмотреть на него и возложить здесь цветы приезжают более 10 миллионов китайцев.'
  }
];
