const handlerDragOver = (event) => {
  event.preventDefault();
  event.target.classList.add('ondragover');
};

const handlerDragLeave = (event) => {
  event.preventDefault();
  event.target.classList.remove('ondragover');
};

export { handlerDragOver, handlerDragLeave };
