export default [
  {
    text: 'Правда ли, что медведь зимой в берлоге сосет лапу? ',
    answer: true
  },
  {
    text: 'Правда ли, что яблоки темнеют на срезе? ',
    answer: true
  },
  {
    text: 'Правда ли, что дальтоник не различает цвета? ',
    answer: true
  },
  {
    text: 'Правда ли, что львы и тигры относятся к семейству кошачьих? ',
    answer: true
  },
  {
    text: 'Правда ли, что самое большое озеро с пресной водой в мире – это Байкал? ',
    answer: true
  },
  {
    text: 'Правда ли, что бледная поганка – это самый ядовитый гриб? ',
    answer: true
  },
  {
    text: 'Правда ли, что волк-вожак идет последним, а первым больной волк? ',
    answer: true
  },
  {
    text: 'Правда ли, что картошку привез в Россию Пётр Первый? ',
    answer: true
  },
  {
    text: 'Правда ли, что кенгуру носит детеныша в «сумке» на животе? ',
    answer: true
  },
  {
    text: 'Правда ли, что русские воины в атаке кричат «ура!»? ',
    answer: true
  },
  {
    text: 'Правда ли, что японские воины в атаке кричат «банзай!»? ',
    answer: true
  },
  {
    text: 'Правда ли, что сталактиты в пещере растут сверху вниз? ',
    answer: true
  },
  {
    text: 'Правда ли, что сталагмиты растут в пещере снизу вверх? ',
    answer: true
  },
  {
    text: 'Правда ли, что скворечники нужны птицам для гнезда? ',
    answer: true
  },
  {
    text: 'Правда ли, что у небесных тел под названием комета есть хвост? ',
    answer: true
  },
  {
    text: 'Правда ли, что после укуса пчела погибает? ',
    answer: true
  },
  {
    text: 'Правда ли, что часы идут, а с места не сойдут? ',
    answer: true
  },
  {
    text: 'Правда ли, что 1642 метра – это глубина озера Байкал? ',
    answer: true
  },
  {
    text: 'Правда ли, что 10 миллионов раз в год моргает человек? ',
    answer: true
  },
  {
    text: 'Правда ли, что в шахматах самая короткая игра называется «дурацкий мат»? ',
    answer: true
  },
  {
    text: 'Правда ли, что в шахматах есть «детский мат»? ',
    answer: true
  },
  {
    text: 'Правда ли, что космонавта защищает скафандр? ',
    answer: true
  },
  {
    text: 'Правда ли, что на Земле два полюса холода: Южный и Северный? ',
    answer: true
  },
  {
    text: 'Правда ли, что человек может прожить без воды трое суток? ',
    answer: true
  },
  {
    text: 'Правда ли, что колибри – это самая маленькая птичка на Земле? ',
    answer: true
  },
  {
    text: 'Правда ли, что птица колибри может лететь назад? ',
    answer: true
  },
  {
    text: 'Правда ли, что вороны кладут орехи на дорогу, чтобы машина раздавила? ',
    answer: true
  },
  {
    text: 'Правда ли, что детеныш осьминога рождается размером с блоху? ',
    answer: true
  },
  {
    text: 'Правда ли, что рекорд полета курицы – 13 секунд? ',
    answer: true
  },
  {
    text: 'Правда ли, что рекорд отжимания от пола равен 46001? ',
    answer: true
  },
  {
    text: 'Правда ли, что далматинцы-детеныши не имеют пятен? ',
    answer: true
  },
  {
    text:
      'Правда ли, что часы «Ролекс» отличаются от подделок тем, что их секундная стрелка ходит плавно, а не тикает? ',
    answer: true
  },
  {
    text: 'Правда ли, что под микроскопом видно,что у комара есть зубы? ',
    answer: true
  },
  {
    text:
      'Правда ли, что японские обезьяны научились бросать монетки в автоматы, съедая вылетевшие чипсы, соки и т. п.? ',
    answer: true
  },
  {
    text: 'Правда ли, что активный курильщик в среднем теряет 22 года жизни? ',
    answer: true
  },
  {
    text: 'Правда ли, что паук тарантул может жить без еды около двух лет? ',
    answer: true
  },
  {
    text: 'Правда ли, что более 600 тысяч человек в год умирают после укусов комаров? ',
    answer: true
  },
  {
    text: 'Правда ли, что самолёт – это самый безопасный транспорт? ',
    answer: true
  },
  {
    text: 'Правда ли, что сейчас на Земле более 7 миллиардов человек? ',
    answer: true
  },
  {
    text: 'Правда ли, что Земля держится на трех китах? ',
    answer: false
  },
  {
    text: 'Правда ли, что из космоса Земля кажется желтой? ',
    answer: false
  },
  {
    text: 'Правда ли, что из космоса Земля кажется голубой? ',
    answer: true
  },
  {
    text: 'Правда ли, что Земля образовалась тысячу лет назад? ',
    answer: false
  },
  {
    text: 'Правда ли, что Земля образовалась один миллиард лет назад? ',
    answer: false
  },
  {
    text: 'Правда ли, что Земля образовалась миллион лет назад? ',
    answer: false
  },
  {
    text: 'Правда ли, что Земля образовалась 4,6 миллиардов лет назад? ',
    answer: true
  },
  {
    text: 'Правда ли, что самые высокие горы на Земле – Гималаи? ',
    answer: true
  },
  {
    text: 'Правда ли, что самая высокая гора на Земле – Эльбрус? ',
    answer: false
  },
  {
    text: 'Правда ли, что самая высокая гора на Земле – Килиманджаро? ',
    answer: false
  },
  {
    text: 'Правда ли, что вулканы опасны и для самолетов? ',
    answer: true
  },
  {
    text: 'Правда ли, что вулканы есть на других планетах? ',
    answer: true
  },
  {
    text: 'Правда ли, что первобытные люди рисовали в пещерах смайлики? ',
    answer: false
  },
  {
    text: 'Правда ли, что люди, которые исследуют пещеры, называются пещерными людьми? ',
    answer: false
  },
  {
    text: 'Правда ли, что река начинается с лужи? ',
    answer: false
  },
  {
    text: 'Правда ли, что река начинается с маленьких ручейков? ',
    answer: true
  },
  {
    text: 'Правда ли, что мышь это детеныш крысы? ',
    answer: false
  },
  {
    text: 'Правда ли, что все реки заканчиваются в море? ',
    answer: false
  },
  {
    text: 'Правда ли, что некоторые реки впадают в озера? ',
    answer: true
  },
  {
    text: 'Правда ли, что некоторые реки заканчиваются, уходя просто под землю? ',
    answer: true
  },
  {
    text: 'Правда ли, что облака из ваты? ',
    answer: false
  },
  {
    text: 'Правда ли, что анаконда – это самый большой крокодил? ',
    answer: false
  },
  {
    text: 'Правда ли, что анаконда – это самая большая змея? ',
    answer: true
  },
  {
    text: 'Правда ли, что айсберг – это самая большая птица в мире? ',
    answer: false
  },
  {
    text: 'Правда ли, что айсберг – это глыба льда, дрейфующая по морю? ',
    answer: true
  },
  {
    text: 'Правда ли, что эскимо – это люди, живущие на севере? ',
    answer: false
  },
  {
    text: 'Правда ли, что тефлон – это маленький телефон? ',
    answer: false
  },
  {
    text: 'Правда ли, что мосты могут качаться от ветра? ',
    answer: true
  },
  {
    text: 'Правда ли, что люди могут жить в космосе? ',
    answer: true
  },
  {
    text: 'Правда ли, что в Австралии есть подземный город? ',
    answer: true
  },
  {
    text: 'Правда ли, что гоночный автомобиль быстрее самолета? ',
    answer: false
  },
  {
    text: 'Правда ли, что самолет быстрее гоночной машины? ',
    answer: true
  },
  {
    text: 'Правда ли, что крылья у самолета для того, чтобы не напугать птиц? ',
    answer: false
  },
  {
    text: 'Правда ли, что если научиться быстро махать руками, то взлетишь? ',
    answer: false
  },
  {
    text: 'Правда ли, что дирижабль похож на громадный воздушный шар? ',
    answer: true
  },
  {
    text: 'Правда ли, что первыми пассажирами воздушного шара были петух и овца? ',
    answer: true
  },
  {
    text: 'Правда ли, что бензин для автомобиля то же, что и пища для человека? ',
    answer: true
  },
  {
    text: 'Правда ли, что первый беговел изобрели 200 лет назад? ',
    answer: true
  },
  {
    text: 'Правда ли, что беговел изобрели раньше, чем велосипед? ',
    answer: true
  },
  {
    text: 'Правда ли, что мотоциклы умеют летать? ',
    answer: false
  },
  {
    text: 'Правда ли, что первые приборы для измерения времени показывали только часы, без минут? ',
    answer: true
  },
  {
    text: 'Правда ли, что в некоторых часах живет настоящая кукушка? ',
    answer: false
  },
  {
    text: 'Правда ли, что у человека сердце с правой стороны? ',
    answer: false
  },
  {
    text: 'Правда ли, что взрослый человек может и дышать, и глотать одновременно? ',
    answer: false
  },
  {
    text: 'Правда ли, что если вы проживете 70 лет, то проживете 10 лет понедельников? ',
    answer: true
  },
  {
    text: 'Правда ли, что правши живут дольше левшей? ',
    answer: false
  },
  {
    text: 'Правда ли, что левши живут дольше правшей? ',
    answer: false
  },
  {
    text: 'Правда ли, что правши живут меньше левшей? ',
    answer: false
  },
  {
    text: 'Правда ли, что левши живут меньше правшей? ',
    answer: false
  },
  {
    text: 'Правда ли, что футболисты живут дольше боксеров? ',
    answer: false
  },
  {
    text: 'Правда ли, что кусаются только самки комаров? ',
    answer: true
  },
  {
    text: 'Правда ли, что существует такая профессия, как дегустатор собачьей еды? ',
    answer: true
  },
  {
    text: 'Правда ли, что на Юпитере может пойти град из алмазов? ',
    answer: true
  },
  {
    text: 'Правда ли, что на Сатурне может пойти град из алмазов? ',
    answer: true
  },
  {
    text: 'Правда ли, что девочки носят платья, а мальчики носят костюмы? ',
    answer: true
  },
  {
    text: 'Правда ли, что конфета-леденец тает во рту? ',
    answer: true
  },
  {
    text: 'Правда ли, что чашка, ложка, тарелка – это посуда? ',
    answer: true
  },
  {
    text: 'Правда ли, что много овец называется «стадо»? ',
    answer: true
  },
  {
    text: 'Правда ли, что запятая ставится в конце предложения? ',
    answer: false
  },
  {
    text: 'Правда ли, что точка ставится в конце предложения? ',
    answer: true
  },
  {
    text: 'Правда ли, что оторвавшаяся в океане льдина – это айсберг? ',
    answer: true
  },
  {
    text: 'Правда ли, что дождь и снег всегда падают с неба? ',
    answer: true
  },
  {
    text: 'Правда ли, что в резиновых сапогах ноги не промокнут? ',
    answer: true
  },
  {
    text: 'Правда ли, что кошки мяукают, коровы мычат, конь ржёт, лиса лает, утёнок крякает? ',
    answer: true
  },
  {
    text: 'Правда ли, что дырки в сыре – от газов? ',
    answer: true
  },
  {
    text: 'Правда ли, что букварь – это учебная книга для первоклассников? ',
    answer: true
  },
  {
    text: 'Правда ли, что фуражка или панамка защищают голову от теплового удара (от лучей солнца)? ',
    answer: true
  },
  {
    text: 'Правда ли, что надутый шарик не тонет в воде? ',
    answer: true
  },
  {
    text: 'Правда ли, что мухоморы – это ядовитые грибы? ',
    answer: true
  },
  {
    text: 'Правда ли, что уха – это суп из рыбы? ',
    answer: true
  },
  {
    text: 'Правда ли, что ушами человек слышит звуки? ',
    answer: true
  },
  {
    text: 'Правда ли, что уши нам даны, чтобы ими шевелить? ',
    answer: false
  },
  {
    text: 'Правда ли, что у рыбы сом есть усы? ',
    answer: true
  },
  {
    text: 'Правда ли, что есть грибы под названием лисички? ',
    answer: true
  },
  {
    text: 'Правда ли, что есть гриб под названием белый, хотя шляпка у него коричневых оттенков? ',
    answer: true
  },
  {
    text: 'Правда ли, что у белого гриба шляпка белая? ',
    answer: false
  },
  {
    text: 'Правда ли, что поезд «Сапсан» назван в честь птицы сокола? ',
    answer: true
  },
  {
    text: 'Правда ли, что киты-это млекопитающие? ',
    answer: true
  },
  {
    text: 'Правда ли, что богомол похож на палочку? ',
    answer: true
  },
  {
    text: 'Правда ли, что растения размножаются семенами, корнями, черенками? ',
    answer: true
  },
  {
    text: 'Правда ли, что существуют электронные книги? ',
    answer: true
  },
  {
    text: 'Правда ли, что антиквары – это люди, собирающие старинные предметы? ',
    answer: true
  },
  {
    text: 'Правда ли, что панда любит побеги бамбука? ',
    answer: true
  },
  {
    text: 'Правда ли, что жираф – это самое низенькое животное? ',
    answer: false
  },
  {
    text: 'Правда ли, что жираф питается ветками с деревьев? ',
    answer: true
  },
  {
    text: 'Правда ли, что обезьяна может висеть на одной лапе? ',
    answer: true
  },
  {
    text: 'Правда ли, что у жирафа самая длинная шея? ',
    answer: true
  },
  {
    text: 'Правда ли, что взрослый африканский слон достигает всего 10 сантиметров в высоту? ',
    answer: false
  },
  {
    text: 'Правда ли, что рыбы добывают кислород из воды через жабры? ',
    answer: true
  },
  {
    text: 'Правда ли, что человек, который бросил фантик в лифте, чистюля? ',
    answer: false
  },
  {
    text: 'Правда ли, что много рыб и птиц будут жить долго, если поедят пластиковый мусор? ',
    answer: false
  },
  {
    text: 'Правда ли, что многие медузы обжигают и ядовиты? ',
    answer: true
  },
  {
    text: 'Правда ли, что обезьяны-ревуны громко кричат и их слышно за 3 километра? ',
    answer: true
  },
  {
    text: 'Правда ли, что обезьяны-ревуны громко плачут и их слезы льются на 3 километра? ',
    answer: false
  },
  {
    text: 'Правда ли, что самые маленькие динозавры были размером с курицу? ',
    answer: true
  },
  {
    text: 'Правда ли, что тюлени, моржи, дельфины, киты имеют легкие и поэтому не могут постоянно быть в воде? ',
    answer: true
  },
  {
    text: 'Правда ли, что дельфины могут не выныривать всю жизнь? ',
    answer: false
  },
  {
    text: 'Правда ли, что таиландская летучая мышь пигмей весит всего около двух граммов? ',
    answer: true
  },
  {
    text: 'Правда ли, что у птицы альбатроса-крикуна размах крыльев более трех метров? ',
    answer: true
  },
  {
    text: 'Правда ли, что альбатрос ест людей? ',
    answer: false
  },
  {
    text: 'Правда ли, что кожистая черепаха весит до 500 килограммов, а длина у неё – до двух метров? ',
    answer: true
  },
  {
    text: 'Правда ли, что самые крупные вараны 3 метра в длину? ',
    answer: true
  },
  {
    text: 'Правда ли, что самые крупные вараны 7 метров в длину? ',
    answer: false
  },
  {
    text: 'Правда ли, что индейцы изготавливают 50 отравленных стрел из одной маленькой самой ядовитой лягушки? ',
    answer: true
  },
  {
    text: 'Правда ли, что существует более 24 тысяч видов рыб? ',
    answer: true
  },
  {
    text: 'Правда ли, что рыбы-пираньи обнаружены в водоемах России? ',
    answer: true
  },
  {
    text: 'Правда ли, что пиранья – это домашняя птица? ',
    answer: false
  },
  {
    text: 'Правда ли, что в Красное море нужно заходить в специальной обуви, чтобы не наступить на морского ежа? ',
    answer: true
  },
  {
    text: 'Правда ли, что самый длинный клюв – у пеликана, до 50 сантиметров? ',
    answer: true
  },
  {
    text: 'Правда ли, что бабочка проходит 4 стадии развития? ',
    answer: true
  },
  {
    text: 'Правда ли, что травоядные динозавры достигали 40 метров в длину? ',
    answer: true
  },
  {
    text: 'Правда ли, что самый маленький морской конек размером всего 2 сантиметра? ',
    answer: true
  },
  {
    text: 'Правда ли, что ящерицы могут отдать хвост и убежать? ',
    answer: true
  },
  {
    text: 'Правда ли, что некоторые морские звезды могут вновь наращивать утраченные щупальца? ',
    answer: true
  },
  {
    text: 'Правда ли, что из оторванного щупальца может вырасти новая морская звезда? ',
    answer: true
  },
  {
    text: 'Правда ли, что самые крупные яйца – у страусов? ',
    answer: true
  },
  {
    text: 'Правда ли, что гигантская морская звезда может быть в диаметре до 1 метра? ',
    answer: true
  },
  {
    text: 'Правда ли, что у птицы снегирь красные грудка и брюшко? ',
    answer: true
  },
  {
    text: 'Правда ли, что самая большая страна в мире – это Россия? ',
    answer: true
  },
  {
    text: 'Правда ли, что в Москве на Красной площади находится храм Василия Блаженного? ',
    answer: true
  },
  {
    text: 'Правда ли, что в Москве есть нулевой меридиан? ',
    answer: true
  },
  {
    text: 'Правда ли, что город Москва – это столица России? ',
    answer: true
  },
  {
    text: 'Правда ли, что зебры ходят в морской тельняшке? ',
    answer: false
  },
  {
    text: 'Правда ли, что в России в городе Волгоград есть высокий памятник «Родина-мать»? ',
    answer: true
  },
  {
    text: 'Правда ли, что у дерева клен семена летают как вертолетики и крутятся, падая вниз? ',
    answer: true
  },
  {
    text: 'Правда ли, что после садика ребенок идет учиться в школу в первый класс? ',
    answer: true
  },
  {
    text: 'Правда ли, что после садика ребенок идет в армию? ',
    answer: false
  },
  {
    text: 'Правда ли, что люди утром завтракают, в обед – обедают, а вечером – ужинают? ',
    answer: true
  },
  {
    text: 'Правда ли, что скамейки в парках – для отдыха? ',
    answer: true
  },
  {
    text: 'Правда ли, что кошки мяукают, а птички щебечут? ',
    answer: true
  },
  {
    text: 'Правда ли, что попугаи могут копировать слова, говорить? ',
    answer: true
  },
  {
    text: 'Правда ли, что поэты пишут стихи, а писатели пишут прозу? ',
    answer: true
  },
  {
    text: 'Правда ли, что если книга лежит на полу, то это уже беспорядок? ',
    answer: true
  },
  {
    text: 'Правда ли, что паук тарантул может жить без еды около пяти лет? ',
    answer: false
  },
  {
    text: 'Правда ли, что более 600 тысяч человек в год умирают после укусов комаров? ',
    answer: true
  },
  {
    text: 'Правда ли, что самолет – это самый безопасный транспорт? ',
    answer: true
  },
  {
    text: 'Правда ли, что сейчас на Земле более 7 миллиардов человек? ',
    answer: true
  },
  {
    text: 'Правда ли, что в океане вода соленая, а айсберг – из пресной воды? ',
    answer: true
  },
  {
    text: 'Правда ли, что в океане вода соленая, а рыбы в ней плавают несоленые? ',
    answer: true
  },
  {
    text: 'Правда ли, что в Космосе есть космический мусор? ',
    answer: true
  },
  {
    text: 'Правда ли, что на нашей Земле нет океанов? ',
    answer: false
  },
  {
    text: 'Правда ли, что на планете Земля есть океаны? ',
    answer: true
  },
  {
    text: 'Правда ли, что шоколад бывает на вкус горьким? ',
    answer: true
  },
  {
    text: 'Правда ли, что арахис – это орех? ',
    answer: false
  },
  {
    text: 'Правда ли, что кешью – это не орех? ',
    answer: false
  },
  {
    text: 'Правда ли, что под одеялом спать теплее? ',
    answer: true
  },
  {
    text: 'Правда ли, что 1 Июня – это Международный день детей? ',
    answer: true
  },
  {
    text: 'Правда ли, что 8 Марта – это Международный женский день? ',
    answer: true
  },
  {
    text: 'Правда ли, что диван бывает раскладной? ',
    answer: true
  },
  {
    text: 'Правда ли, что под водой водолазы обнаруживают затопленные города? ',
    answer: true
  },
  {
    text: 'Правда ли, что дельфины имеют свой разговорный язык звуков? ',
    answer: true
  },
  {
    text:
      'Правда ли, что дельфин выпрыгивает из воды и всем телом падает на воду, чтобы созвать стаю дельфинов на охоту? ',
    answer: true
  },
  {
    text: 'Правда ли, что язык – это самая сильная мышца в теле человека? ',
    answer: true
  },
  {
    text: 'Правда ли, что нельзя чихнуть с открытыми глазами? ',
    answer: true
  },
  {
    text: 'Правда ли, что за всю жизнь в среднем человек съедает 27 тонн еды, а это 6 слонов? ',
    answer: true
  },
  {
    text: 'Правда ли, что плотный шар из стекла при ударе отскочит выше резинового мяча? ',
    answer: true
  },
  {
    text: 'Правда ли, что пчела более озлоблена в ветреный день? ',
    answer: true
  },
  {
    text: 'Правда ли, что у жирафа язык 50 сантиметров, в среднем? ',
    answer: true
  },
  {
    text: 'Правда ли, что акула – это единственная рыба, моргающая двумя глазами одновременно? ',
    answer: true
  },
  {
    text: 'Правда ли, что самое высокое дерево планеты (115,61 м) – это секвойя? ',
    answer: true
  },
  {
    text: 'Правда ли, что верблюжье молоко никогда не сворачивается? ',
    answer: true
  },
  {
    text: 'Правда ли, что у верблюда 3 века на глазах? ',
    answer: true
  },
  {
    text: 'Правда ли, что страусы эму и кенгуру не умеют пятиться назад? ',
    answer: true
  },
  {
    text: 'Правда ли, что коровы дают больше молока под приятную музыку? ',
    answer: true
  },
  {
    text: 'Правда ли, что комары любят запах бананов? ',
    answer: true
  },
  {
    text: 'Правда ли, что уши зеленого кузнечика – это дырочки в задних ногах? ',
    answer: true
  },
  {
    text: 'Правда ли, что пингвин умеет плавать, но не умеет летать? ',
    answer: true
  },
  {
    text: 'Правда ли, что страус не умеет ни летать, ни плавать? ',
    answer: true
  },
  {
    text: 'Правда ли, что 3000 лет назад египтяне жили не больше 30 лет? ',
    answer: true
  },
  {
    text: 'Правда ли, что ножницы изобрел Леонардо да Винчи? ',
    answer: true
  },
  {
    text: 'Правда ли, что в Португалии считается неприличным писать красными чернилами? ',
    answer: true
  },
  {
    text: 'Правда ли, что слово «астронавт» – от греческих слов «звезда» и «моряк»? ',
    answer: true
  },
  {
    text: 'Правда ли, что крылья колибри могут биться 200 раз в секунду? ',
    answer: true
  },
  {
    text: 'Правда ли, что яичница готовится на любом предмете при 70 градусах? ',
    answer: true
  },
  {
    text: 'Правда ли, что Солнце может вместить 1 миллион планет Земля? ',
    answer: true
  },
  {
    text: 'Правда ли, что кукуруза выращивается везде, кроме Антарктиды? ',
    answer: true
  },
  {
    text: 'Правда ли, что каждый день мы теряем от 50 до 100 волос? ',
    answer: true
  },
  {
    text: 'Правда ли, что обезьяны и крысы смеются от щекотки? ',
    answer: true
  },
  {
    text: 'Правда ли, что баклажаны содержат никотин? ',
    answer: true
  },
  {
    text: 'Правда ли, что 775 миллионов человек не умеют читать? ',
    answer: true
  },
  {
    text: 'Правда ли, что в золотой олимпийской медали 6 грамм золота? ',
    answer: true
  },
  {
    text: 'Правда ли, что крекеры убивают зубы? ',
    answer: true
  },
  {
    text: 'Правда ли, что наши волосы содержат золото? ',
    answer: true
  },
  {
    text: 'Правда ли, что пульт от телевизора – это самый грязный предмет? ',
    answer: true
  },
  {
    text: 'Правда ли, что из млекопитающих на Земле больше всего летучих мышей? ',
    answer: true
  },
  {
    text: 'Правда ли, что существует 5000 видов млекопитающих и 1 тысяча из них – это летучие мыши? ',
    answer: true
  },
  {
    text: 'Правда ли, что некоторые акулы могут прожить 500 лет? ',
    answer: true
  },
  {
    text: 'Правда ли, что среднее расстояние между Землей и Луной 384 тысячи километров? ',
    answer: true
  },
  {
    text: 'Правда ли, что деревьев на земле более 3 триллионов? ',
    answer: true
  },
  {
    text: 'Правда ли, что гепарды не умеют рычать, а мяукают? ',
    answer: true
  },
  {
    text: 'Правда ли, что у пингвинов есть колени? ',
    answer: true
  },
  {
    text: 'Правда ли, что сейчас на Земле более 7 миллиардов человек? ',
    answer: true
  },
  {
    text: 'Правда ли, что арахис – это не орехи, а бобовые? ',
    answer: true
  },
  {
    text:
      'Правда ли, что в британской Колумбии живут «морские» волки, которые заплывают в море в поисках морепродуктов? ',
    answer: true
  },
  {
    text: 'Правда ли, что в Европейской комиссии работает переводчик, знающий 47 языков? ',
    answer: true
  },
  {
    text: 'Правда ли, что попкорн был известен за 64 700 лет до нашей эры? ',
    answer: true
  },
  {
    text: 'Правда ли, что у кукурузного початка всегда четное число рядов = 16? ',
    answer: true
  },
  {
    text: 'Правда ли, что у кузнечика 5 лапок? ',
    answer: false
  },
  {
    text: 'Правда ли, что зонт может служить вместо парашюта? ',
    answer: false
  },
  {
    text: 'Правда ли, что королева моложе принцессы? ',
    answer: false
  },
  {
    text: 'Правда ли, что русалки существуют? ',
    answer: false
  },
  {
    text: 'Правда ли, что горчица сладкая? ',
    answer: false
  },
  {
    text: 'Правда ли, что у троллейбуса три штанговых токоприемника? ',
    answer: false
  },
  {
    text: 'Правда ли, что «Монополия» – это игра в карты? ',
    answer: false
  },
  {
    text: 'Правда ли, что у девочек есть кадык? ',
    answer: false
  },
  {
    text: 'Правда ли, что хозяин белого медведя – снежный человек? ',
    answer: false
  },
  {
    text: 'Правда ли, что мы используем только 10% своего мозга? ',
    answer: false
  },
  {
    text: 'Правда ли, что киты самые долгоживущие позвоночные на Земле? ',
    answer: false
  },
  {
    text: 'Правда ли, что любые инфекции можно вылечить антибиотиками? ',
    answer: false
  },
  {
    text: 'Правда ли, что собаки видят все в черно-белом цвете? ',
    answer: false
  },
  {
    text: 'Правда ли, что средняя продолжительность жизни человека 100 лет? ',
    answer: false
  },
  {
    text: 'Правда ли, что возраст дерева можно определить по количеству веток на нем? ',
    answer: false
  },
  {
    text: 'Правда ли, что самая длинная река в мире – Нил? ',
    answer: false
  },
  {
    text: 'Правда ли, что египтяне месили раствор для изготовления кирпичей, руками, как тесто? ',
    answer: false
  },
  {
    text: 'Правда ли, что пони – это детеныш лошади? ',
    answer: false
  },
  {
    text: 'Правда ли, что аптека и оптика – это одно и то же, только с ошибкой? ',
    answer: false
  },
  {
    text: 'Правда ли, что лакомка – это изобретатель лака? ',
    answer: false
  },
  {
    text: 'Правда ли, что у осьминогов два сердца? ',
    answer: false
  },
  {
    text: 'Правда ли, что сказку «Конек-горбунок» написал А. С. Пушкин? ',
    answer: false
  },
  {
    text: 'Правда ли, что самые первые машины были двухколесными? ',
    answer: false
  },
  {
    text: 'Правда ли, что блины выпекают только из ржаной муки? ',
    answer: false
  },
  {
    text: 'Правда ли, что слоны умеют высоко прыгать? ',
    answer: false
  },
  {
    text: 'Правда ли, что белые медведи, ловя рыбу в проруби, свой черный нос прикрывают лапой? ',
    answer: false
  },
  {
    text: 'Правда ли, что у насекомых, так же как и у животных, кровь красная? ',
    answer: false
  },
  {
    text: 'Правда ли, что Марс самая большая планета в Солнечной системе? ',
    answer: false
  },
  {
    text: 'Правда ли, что вода в озере Байкал такая же соленая, как в море? ',
    answer: false
  },
  {
    text: 'Правда ли, что белые медведи любят охотиться на пингвинов? ',
    answer: false
  },
  {
    text: 'Правда ли, что рассерженная божья коровка может укусить? ',
    answer: false
  },
  {
    text: 'Правда ли, что в шахматах ферзь главнее королевы? ',
    answer: false
  },
  {
    text: 'Правда ли, что бабочки пробуют пищу на вкус лапками? ',
    answer: true
  },
  {
    text: 'Правда ли, что у всех собак язык розовый? ',
    answer: false
  },
  {
    text: 'Правда ли, что на вершине Эвереста растут цветы? ',
    answer: false
  },
  {
    text: 'Правда ли, что птица альбатрос может спать в полете на скорости 40 километров в час? ',
    answer: true
  },
  {
    text: 'Правда ли, что жираф может обходиться без воды дольше, чем верблюд? ',
    answer: true
  },
  {
    text: 'Правда ли, что кровь у насекомых бесцветная или желтоватая? ',
    answer: true
  },
  {
    text: 'Правда ли, что дельфины спят с одним открытым глазом? ',
    answer: true
  },
  {
    text: 'Правда ли, что если начнут драться лев и белый медведь, то победит лев? ',
    answer: false
  },
  {
    text: 'Правда ли, что муравьи не спят? ',
    answer: true
  },
  {
    text: 'Правда ли, что крокодилы могут притворяться бревнами, спасаясь от врагов? ',
    answer: false
  },
  {
    text: 'Правда ли, что для производства стекла используют глину? ',
    answer: false
  },
  {
    text: 'Правда ли, что кончик меча у меч-рыбы отравлен смертоносным мышьяком? ',
    answer: false
  },
  {
    text: 'Правда ли, что меч-рыба совершенно безвредна, а мышьяк – минерал, добываемый в земле? ',
    answer: true
  },
  {
    text: 'Правда ли, что блоха не может ползать? ',
    answer: true
  },
  {
    text: 'Правда ли, что скорость блохи в начале прыжка больше, чем скорость космической ракеты на орбите? ',
    answer: true
  },
  {
    text: 'Правда ли, что у насекомых нет легких? ',
    answer: true
  },
  {
    text: 'Правда ли, что белые мишки, охотясь на тюленей, свой черный нос прикрывают лапой? ',
    answer: false
  },
  {
    text: 'Правда ли, что крокодилы любят глотать камни? ',
    answer: true
  },
  {
    text: 'Правда ли, что крабы поднимаются к поверхности на дельфинах? ',
    answer: false
  },
  {
    text: 'Правда ли, что пчелы убивают людей больше, чем все ядовитые змеи вместе взятые? ',
    answer: true
  },
  {
    text: 'Правда ли, что акулы не болеют? ',
    answer: true
  },
  {
    text: 'Правда ли, что змеи не моргают, потому что у них есть прозрачные слипшиеся веки? ',
    answer: true
  },
  {
    text: 'Правда ли что даже на глазах у шмеля растут волосы, которые помогают собирать пыльцу? ',
    answer: true
  },
  {
    text: 'Правда ли, что дикобраз стреляет своими иглами во врагов? ',
    answer: false
  },
  {
    text: 'Правда ли, что таракан может жить без головы, пока не умрет с голода? ',
    answer: true
  },
  {
    text: 'Правда ли, что у тигров не только полосатый мех, но и полосатая кожа? ',
    answer: true
  },
  {
    text: 'Правда ли что кости у летучей мыши настолько тонкие, что она не может стоять? ',
    answer: true
  },
  {
    text: 'Правда ли что самое большое животное на Земле – медведь? ',
    answer: false
  }
];
