export default {
  texts: {
    description: [
      'Любой спецагент умеет подбирать комбинации паролей и создавать свои суперсекретные коды. Это упражнение поможет тебе развить комбинаторные способности. Ты сможешь создавать свои секретные логины и пароли. Попробуй!',
      'Наши спецагенты владеют большим объёмом информации и должны уметь её сохранять и применять. Для этого они упражняются в выполнении заданий по сбору слов из различных букв. А ты сможешь?'
    ],
    losing: [
      'Не получилось – не беда! Мистер Фоксман уверен, что у тебя обязательно получится!',
      'Тренировка очень важна для любого спецагента! Тренируйся – и всё получится.'
    ],
    victory: [
      'Было сложно, но ты справился! Вперёд к новым миссиям!',
      'Ты становишься примером для других агентов! Тебя ждут новые миссии!'
    ]
  },
  textsInMission: {
    description: [
      'Чтобы двигаться дальше в миссии, тебе предстоит собрать очень сложную комбинацию. Из перемешанных букв собери целое слово.',
      'Мистер Фоксман поручил тебе расшифровку важного  послания. Собери из отдельных букв слово, и задание будет выполнено!'
    ],
    losing: [
      'Не все буквы удалось сложить в слова! Потренируйся в тренажёрном зале, и у тебя всё получится!',
      'Сложное задание, надо потренироваться! Мистер Фоксман уже подготовил для тебя слова в тренажёрном зале!'
    ],
    victory: [
      'Как быстро ты складываешь слова! Отличный результат!',
      'Как быстро ты распознал все слова! Настоящий спецагент!'
    ]
  }
};
