import levels from './levels';
import texts from './texts';
import levelsInMission from './levelsInMission';

import getQuestions from './services/getQuestions';

export default {
  id: 6,
  type: 'accuracy',
  eventCategory: 'Accuracy',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Очень важно не только всё запомнить, но и воспроизвести дословно. Тренируйся, этот навык тебе точно пригодится!',
  previewUrl: require('~/static/image/trainerPreviews/accuracy.png'),
  params: {
    ...texts,
    name: 'Меткость с первого взгляда',
    hasTimer: true,
    startsImmediately: true,
    notMaxWidth: true,
    maxSkills: {
      focus: 3,
      memory: 4
    },
    stageAmount: 2,
    skills: [ 'focus', 'memory' ],
    mainSkill: 'memory',
    levels,
    questions: getQuestions(),
    levelsInMission
  }
}
