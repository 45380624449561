import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import './button.scss';

const Button = (
  {
    className, link, onClick, theme,
    children, round, disabled, locked,
    replay, result, giftClose, cy
  }
) => {
  const classNameComp = () => {
    return cx('button', {
      'button_round': round,
      'button_theme-orange': !theme,
      [`button_theme-${theme}`]: theme,
      [className]: className,
      'button_locked' : locked,
      'button_replay' : replay,
      'button_result' : result,
      'button_gift-close' : giftClose
    });
  }

  const renderButton = () => {
    return (
      <button
        className={ classNameComp() }
        onClick={ onClick }
        disabled={ disabled }
        data-cy={ cy }
      >
        { children }
      </button>
    );
  };

  const renderLink = () => {
    return (
      <Link
        className={ classNameComp() }
        to={ link }
        onClick={ onClick }
        data-cy={ cy }
      >
        {children}
      </Link>
    );
  };

  const isLink = link;
  return isLink ? renderLink() : renderButton();
}

Button.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
};

export default Button;
