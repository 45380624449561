export default [
  {
    sequence: [5, 4, 3, 2],
    answer: 1,
    actions: ['-1']
  },
  {
    sequence: [6, 5, 4, 3],
    answer: 2,
    actions: ['-1']
  },
  {
    sequence: [7, 6, 5, 4],
    answer: 3,
    actions: ['-1']
  },
  {
    sequence: [8, 7, 6, 5],
    answer: 4,
    actions: ['-1']
  },
  {
    sequence: [9, 8, 7, 6],
    answer: 5,
    actions: ['-1']
  },
  {
    sequence: [10, 9, 8, 7],
    answer: 6,
    actions: ['-1']
  },
  {
    sequence: [11, 10, 9, 8],
    answer: 7,
    actions: ['-1']
  },
  {
    sequence: [9, 7, 5, 3],
    answer: 1,
    actions: ['-2']
  },
  {
    sequence: [10, 8, 6, 4],
    answer: 2,
    actions: ['-2']
  },
  {
    sequence: [11, 9, 7, 5],
    answer: 3,
    actions: ['-2']
  },
  {
    sequence: [12, 10, 8, 6],
    answer: 4,
    actions: ['-2']
  },
  {
    sequence: [13, 11, 9, 7],
    answer: 5,
    actions: ['-2']
  },
  {
    sequence: [13, 10, 7, 4],
    answer: 1,
    actions: ['-3']
  },
  {
    sequence: [14, 11, 8, 5],
    answer: 2,
    actions: ['-3']
  },
  {
    sequence: [15, 12, 9, 6],
    answer: 3,
    actions: ['-3']
  },
  {
    sequence: [17, 13, 9, 5],
    answer: 1,
    actions: ['-4']
  },
  {
    sequence: [1, 2, 3, 4],
    answer: 5,
    actions: ['+1']
  },
  {
    sequence: [2, 3, 4, 5],
    answer: 6,
    actions: ['+1']
  },
  {
    sequence: [3, 4, 5, 6],
    answer: 7,
    actions: ['+1']
  },
  {
    sequence: [4, 5, 6, 7],
    answer: 8,
    actions: ['+1']
  },
  {
    sequence: [5, 6, 7, 8],
    answer: 9,
    actions: ['+1']
  },
  {
    sequence: [6, 7, 8, 9],
    answer: 10,
    actions: ['+1']
  },
  {
    sequence: [7, 8, 9, 10],
    answer: 11,
    actions: ['+1']
  },
  {
    sequence: [8, 9, 10, 11],
    answer: 12,
    actions: ['+1']
  },
  {
    sequence: [1, 3, 5, 7],
    answer: 9,
    actions: ['+2']
  },
  {
    sequence: [2, 4, 6, 8],
    answer: 10,
    actions: ['+2']
  },
  {
    sequence: [3, 5, 7, 9],
    answer: 11,
    actions: ['+2']
  },
  {
    sequence: [4, 6, 8, 10],
    answer: 12,
    actions: ['+2']
  },
  {
    sequence: [5, 7, 9, 11],
    answer: 13,
    actions: ['+2']
  },
  {
    sequence: [6, 8, 10, 12],
    answer: 14,
    actions: ['+2']
  },
  {
    sequence: [1, 4, 7, 10],
    answer: 13,
    actions: ['+3']
  },
  {
    sequence: [2, 5, 8, 11],
    answer: 14,
    actions: ['+3']
  },
  {
    sequence: [3, 6, 9, 12],
    answer: 15,
    actions: ['+3']
  },
  {
    sequence: [4, 7, 10, 13],
    answer: 16,
    actions: ['+3']
  },
  {
    sequence: [1, 5, 9, 13],
    answer: 17,
    actions: ['+4']
  },
  {
    sequence: [2, 6, 10, 14],
    answer: 18,
    actions: ['+4']
  },
  {
    sequence: [1, 6, 11, 16],
    answer: 21,
    actions: ['+5']
  },
  {
    sequence: [2, 7, 12, 17],
    answer: 22,
    actions: ['+5']
  },
  {
    sequence: [3, 8, 13, 18],
    answer: 23,
    actions: ['+5']
  },
  {
    sequence: [4, 9, 14, 19],
    answer: 24,
    actions: ['+5']
  },
  {
    sequence: [1, 2, 4, 7],
    answer: 11,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [2, 3, 5, 8],
    answer: 12,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [3, 4, 6, 9],
    answer: 13,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [4, 5, 7, 10],
    answer: 14,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [5, 6, 8, 11],
    answer: 15,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [6, 7, 9, 12],
    answer: 16,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [7, 8, 10, 13],
    answer: 17,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [8, 9, 11, 14],
    answer: 18,
    actions: ['+1', '+2', '+3', '+4']
  },
  {
    sequence: [1, 3, 6, 10],
    answer: 15,
    actions: ['+2', '+3', '+4', '+5']
  },
  {
    sequence: [2, 4, 7, 11],
    answer: 16,
    actions: ['+2', '+3', '+4', '+5']
  },
  {
    sequence: [3, 5, 8, 12],
    answer: 17,
    actions: ['+2', '+3', '+4', '+5']
  },
  {
    sequence: [4, 6, 9, 13],
    answer: 18,
    actions: ['+2', '+3', '+4', '+5']
  },
  {
    sequence: [5, 7, 10, 14],
    answer: 19,
    actions: ['+2', '+3', '+4', '+5']
  },
  {
    sequence: [6, 8, 11, 15],
    answer: 20,
    actions: ['+2', '+3', '+4', '+5']
  },
  {
    sequence: [1, 4, 8, 13],
    answer: 19,
    actions: ['+3', '+4', '+5', '+6']
  },
  {
    sequence: [2, 5, 9, 14],
    answer: 20,
    actions: ['+3', '+4', '+5', '+6']
  },
  {
    sequence: [10, 9, 7, 4],
    answer: 0,
    actions: ['-1', '-2', '-3', '-4']
  },
  {
    sequence: [11, 10, 8, 5],
    answer: 1,
    actions: ['-1', '-2', '-3', '-4']
  },
  {
    sequence: [12, 11, 9, 6],
    answer: 2,
    actions: ['-1', '-2', '-3', '-4']
  },
  {
    sequence: [14, 12, 9, 5],
    answer: 0,
    actions: ['-2', '-3', '-4', '-5']
  }
];
