import React from 'react'

import './switcher.scss'

const Switcher = ({ isActive, action, data }) => {
  return (
    <div className="switcher">
      <div className="switcher__title-container">
        <div
          className={ `switcher__title-item ${ isActive ? "switcher__title-item_active" : "" }`}
          onClick={ () => action(true) }>
            { data[0] }
        </div>
        <div
          className={ `switcher__title-item ${ !isActive ? "switcher__title-item_active" : "" }` }
          onClick={ () => action(false) }>
            { data[1] }
        </div>
      </div>
    </div>
  )
}

export default Switcher
