import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import LevelUpScale from '~/components/LevelUpScale/LevelUpScale';

import detectDevice from '~/services/detect-device';
import timeout from '~/services/timeout';

import { MINI_MARGIN, DEFAULT_MARGIN, MINI_MARGIN_MOBILE, DEFAULT_MARGIN_MOBILE } from './constants';

import './level-up-panel.scss';

const LevelUpPanel = (props) => {
  const { profile, levelMaxValue, prevLevelMaxValue } = props;
  const { gameLevel } = profile;
  const prevLevel = gameLevel - 1;
  const { isMobile, isTablet } = detectDevice();
  const margin = isMobile && !isTablet ? DEFAULT_MARGIN_MOBILE : DEFAULT_MARGIN;
  const miniMargin = isMobile && !isTablet ? MINI_MARGIN_MOBILE : MINI_MARGIN;

  const [ isLevelup, setLevelup ] = useState(false);
  const levelAnimation = useSpring({
    from: margin,
    to: async (next) => {
      await next(miniMargin);
      await timeout(400);
      await next(margin);
      setLevelup(true);
    },
    delay: 1000
  });

  const style = isLevelup ? margin : levelAnimation;

  return (
    <div className="level-up-panel">
      <div className="level-up-panel__circle">
        <animated.div className="level-up-panel__level" style={ style }>
          { isLevelup ? gameLevel : prevLevel }
        </animated.div>
      </div>
      <LevelUpScale level={ gameLevel } maxPoints={ levelMaxValue } prevMaxPoints={ prevLevelMaxValue }/>
    </div>
  );
};

export default LevelUpPanel;
