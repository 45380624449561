export default ({ seconds, taskParams, picturesAmount, level }) => {
  const { picturesAmount: placesAmount } = taskParams;
  const isOne = placesAmount === 1;
  const textAnnounce = isOne ? 'Агент, запомни место встречи! В конце миссии тебе предстоит его узнать!' :
                               'Агент, запомни эти локации! В конце миссии тебе предстоит их узнать!';
  const tooltipContent = `Агент Фокс, запомни это место. Для этого:
                          <br><br><b>1.</b> Рассмотри внимательно картинку.
                          <br><br><b>2.</b> Опиши её: кто или что на ней изображено.
                          <br><br><b>3.</b> Ещё раз посмотри и запомни.
                          <br><br> В конце миссии выбери правильную картинку.`;

  return {
    level,
    timer: [seconds * 1000],
    taskParams,
    picturesAmount,
    movesLimit: taskParams.picturesAmount,
    tooltipContent,
    textAnnounce,
    winnerPoints: {
      focus: 1,
      memory: 2
    }
  };
}
