import React from 'react';
import { connect } from 'react-redux';

import Popup from '~/components/Popup/Popup';
import LevelScale from '~/components/LevelScale/LevelScale';
import TooltipButton from '~/components/TooltipButton/TooltipButton';

import popupNames from '~/services/popup-names';

import { getPopupName } from '~/store/page/selector';
import { levelMaxValueSelector } from '~/store/profile/selector';

import * as pageActions from '~/store/page/actions';

import './profile-level-popup.scss';

const ProfileLevelPopup = (props) => {
  const { profile, popupName, levelMaxValue, setPopup } = props;
  const { gameLevel, points } = profile;
  const closeHandler = () => setPopup(null);
  const isOpen = popupName === popupNames.profileLevel;

  return (
    <Popup isOpen={ isOpen } className="popupContent profile-level-popup__popup-content"
           overlayClass="popupOverlay profile-level-popup__popup-overlay">
      <div className="profile-level-popup">
        <header className="profile-level-popup__header">
          Игровой уровень
        </header>

        <div className="profile-level-popup__stats">
          <div className="profile-level-popup__level">
            { gameLevel } уровень
          </div>

          <div className="profile-level-popup__value">
            { points } / { levelMaxValue } баллов
          </div>
        </div>

        <div className="profile-level-popup__scale">
          <LevelScale points={ points } maxPoints={ levelMaxValue } isInProfile={ true } isShort={ true }/>
        </div>

        <p className="profile-level-popup__desc">
          Твой игровой уровень зависит от баллов, которые ты получаешь за выполнение заданий в миссиях.
        </p>

        <p className="profile-level-popup__desc">
          Твой уровень виден всем другим агентам. Получай баллы и прокачивай свой уровень.
        </p>

        <div className="profile-level-popup__close">
          <TooltipButton iconName='close-tooltip' onClick={ closeHandler }/>
        </div>
      </div>
    </Popup>
  );
};

function mapStateToProps(state) {
  return {
    popupName: getPopupName(state),
    profile: state.profile,
    levelMaxValue: levelMaxValueSelector(state),
  };
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLevelPopup);
