export default {
  shapes: [ 1, 5, 6, 2, 7, 4, 3 ],
  offset: {
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 322, y: 55 },
      tablet: { x: 317, y: 49 },
      mobile: { x: 226, y: 31 }
    },
    {
      desktop: { x: 277, y: 100 },
      tablet: { x: 277, y: 89 },
      mobile: { x: 196, y: 61 }
    },
    {
      desktop: { x: 322, y: 145 },
      tablet: { x: 317, y: 129 },
      mobile: { x: 226, y: 91 }
    },
    {
      desktop: { x: 332, y: 135 },
      tablet: { x: 327, y: 119 },
      mobile: { x: 232, y: 85 }
    },
    {
      desktop: { x: 332, y: 158.64 },
      tablet: { x: 327, y: 139.57 },
      mobile: { x: 232, y: 100.43 }
    },
    {
      desktop: { x: 205, y: 158.64 },
      tablet: { x: 214, y: 139.57 },
      mobile: { x: 148, y: 100.43 }
    },
    {
      desktop: { x: 267.64, y: 221.28 },
      tablet: { x: 270.57, y: 196.14 },
      mobile: { x: 190.43, y: 142.85 }
    },
    {
      desktop: { x: 267.64, y: 258.36 },
      tablet: { x: 270.57, y: 229.43 },
      mobile: { x: 190.43, y: 167.57 }
    },
    {
      desktop: { x: 223.05, y: 303.2 },
      tablet: { x: 231, y: 269 },
      mobile: { x: 161, y: 197 }
    },
    {
      desktop: { x: 204.75, y: 285 },
      tablet: { x: 214, y: 252 },
      mobile: { x: 148, y: 184 }
    },
    {
      desktop: { x: 160, y: 330 },
      tablet: { x: 174, y: 292 },
      mobile: { x: 118, y: 214 }
    },
    {
      desktop: { x: 249.9, y: 330 },
      tablet: { x: 254, y: 292 },
      mobile: { x: 178, y: 214 }
    },
    {
      desktop: { x: 267.64, y: 348.28 },
      tablet: { x: 270.57, y: 309.14 },
      mobile: { x: 190.43, y: 226.85 }
    },
    {
      desktop: { x: 394.47, y: 348.28 },
      tablet: { x: 382.85, y: 309.14 },
      mobile: { x: 274.64, y: 226.85 }
    },
    {
      desktop: { x: 395.17, y: 286.35 },
      tablet: { x: 382.85, y: 252.57 },
      mobile: { x: 274.64, y: 184.43 }
    },
    {
      desktop: { x: 332.41, y: 223.59 },
      tablet: { x: 327.64, y: 197.36 },
      mobile: { x: 233.11, y: 142.89 }
    },
    {
      desktop: { x: 422, y: 134 },
      tablet: { x: 407, y: 118 },
      mobile: { x: 292, y: 84 }
    },
    {
      desktop: { x: 333, y: 134 },
      tablet: { x: 328, y: 118 },
      mobile: { x: 233, y: 84 }
    },
    {
      desktop: { x: 367, y: 100 },
      tablet: { x: 357, y: 89 },
      mobile: { x: 256, y: 61 }
    }
  ],
  outlines: [
    {
      desktop: { x: 277, y: 55 },
      tablet: { x: 277, y: 49 },
      mobile: { x: 196, y: 31 },
      rotation: 0
    },
    {
      desktop: { x: 422, y: 224 },
      tablet: { x: 407, y: 198 },
      mobile: { x: 292, y: 144 },
      rotation: 180
    },
    {
      desktop: { x: 205, y: 158.64 },
      tablet: { x: 214, y: 139.57 },
      mobile: { x: 148, y: 100.43 },
      rotation: 315
    },
    {
      desktop: { x: 299, y: 253.82 },
      tablet: { x: 298, y: 224.28 },
      mobile: { x: 211, y: 163.21 },
      rotation: 315
    },
    {
      desktop: { x: 394.92, y: 348.28 },
      tablet: { x: 383.71, y: 309.14 },
      mobile: { x: 275.28, y: 226.85 },
      rotation: 135
    },
    {
      desktop: { x: 223, y: 258 },
      tablet: { x: 231, y: 229 },
      mobile: { x: 161, y: 167 },
      rotation: 0
    },
    {
      desktop: { x: 250, y: 285 },
      tablet: { x: 254, y: 252 },
      mobile: { x: 178, y: 184 },
      rotation: 90
    }
  ]
};
