import React from 'react';
import Page from '~/containers/Page/Page';
import Locations from '~/components/Locations/Locations'

import fox from './assets/foxman-parachute.png'

import './locationsPage.scss';

const LocationsPage = ({ theme }) => {
  return (
    <Page theme={ theme }>
      <div className="locations-page">
        <div className="locations-page__holder">
          <div className="locations-page__cloud locations-page__cloud_1"></div>

          <div className="locations-page__greeting-cloud locations-page__cloud_2">
            <div className="locations-page__greeting">
              <h1 className="locations-page__title">
                Карта локаций
              </h1>

              <p className="locations-page__text">
                Привет Агент Фокс!<br />
                Это карта городов и миссий. В каждом городе ты найдешь 3 миссии, а в каждой миссии тебя ждут 6 заданий.
              </p>
            </div>
          </div>

          <div className="locations-page__cloud locations-page__cloud_3"></div>

          <div className="locations-page__fox-box">
            <img className="locations-page__fox" alt="fox" src={fox} />
          </div>

          <div className="locations-page__cloud locations-page__cloud_4"></div>

          <div className="locations-page__cloud locations-page__cloud_5"></div>

          <div className="locations-page__cloud locations-page__cloud_6"></div>

          <div className="locations-page__list-box">
            <Locations />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default LocationsPage
