import React from 'react';
import { Group, Line, Rect } from 'react-konva';

import LineShapes from '../LineShapes';
import WinIcon from '../WinIcon';
import LoseIcon from '../LoseIcon';
import FigureOutline from '../FigureOutline/FigureOutline';

import * as Sizes from '../constants';
import * as Colors from '../constants/colors';
import { TABLET, MOBILE } from '../constants/screens';

import styleField from './styleField';

const Field = (props) => {
  const { widthCanvas, handleDragEnd, handleDragMove, rotate,
          shapes, isShowRight, isOver, isWin, screenType } = props;
  const X = (widthCanvas - getSizesField(screenType).width) * 0.5;
  const isOutline = ((isOver && !isWin) || (!isOver));
  const isLoser = !isWin && isOver && !isShowRight;
  const dragEndHandler = (e, index) => handleDragEnd(e, { ...getStyleField(props), x: X, y: 0 }, index);

  return (
    <Group x={ X }>
      <Rect { ...getStyleField(props) } />
      { isWin && <WinIcon y={ Sizes.OFFSET_FIELD_Y } x={ getStyleField(props).width - Sizes.OFFSET_FIELD_X } /> }
      { isLoser && <LoseIcon y={ Sizes.OFFSET_FIELD_Y } x={ getStyleField(props).width - Sizes.OFFSET_FIELD_X } /> }
      { isOutline && <FigureOutline { ...props } /> }
      { renderOutlines(props) }
      <LineShapes isOver={ isOver } shapes={ shapes } handleDragEnd={ dragEndHandler } rotate={ rotate }
                  handleDragMove={ handleDragMove } widthCanvas={ widthCanvas } screenType={ screenType } />
    </Group>
  );
};

const getStyleField = (props) => {
  const { isWin, isOver, isShowRight, screenType } = props;
  const stroke = isWin ? Colors.BORDER_WIN_COLOR :
                 isOver && isShowRight ? Colors.BORDER_RIGHT_COLOR :
                 isOver && !isShowRight ? Colors.BORDER_LOSE_COLOR : null;
  let style = { ...styleField, ...getSizesField(screenType), stroke, strokeWidth: 3, y: 10 };

  if (screenType === MOBILE) {
    style.cornerRadius = 0;
  }

  return style;
};

const getSizesField = (screenType) => {
  const clientWidth = document.documentElement.clientWidth;
  let width = Sizes.WIDTH_FIELD;
  if (screenType === TABLET) width = clientWidth - 2 * Sizes.MARGIN_FIELD - Sizes.OFFSET_FIELD_X;
  if (screenType === MOBILE) width = clientWidth - 3 * Sizes.OFFSET_FIELD_X;

  return { width, height: Sizes.HEIGHT_FIELD[screenType] };
};

const renderOutlines = (props) => {
  const { outlines, shapes, isOver, isShowRight } = props;
  return outlines.map((item, index) => {
    const { x: xo, y: yo } = item
    const { x, y, fill } = shapes[index]

    if (isOver && isShowRight && x !== xo && y !== yo) {
      item = {
        ...item,
        fill: `${fill}4D`,
      };
    }

    if (isOver && !isShowRight) {
      item = {
        ...item,
        fill: null,
        opacity: 1
      };
    }

    return <Line key={ index } { ...item } closed />
  });
};

export default Field;
