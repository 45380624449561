export default [
  'агент', 'адрес', 'акула', 'аллея', 'алмаз', 'ангел', 'афиша', 'базар', 'багаж', 'банан', 'баран', 'барин',
  'барон', 'басня', 'батон', 'батут', 'башня', 'белка', 'белок', 'берег', 'берет', 'билет', 'бисер', 'блоха',
  'блеск', 'бомба', 'вагон', 'ванна', 'вафля', 'ведро', 'весна', 'ветер', 'ветка', 'вечер', 'взрыв', 'вилка',
  'вишня', 'влага', 'гараж', 'гайка', 'гений', 'герой', 'голод', 'голос', 'город', 'горох', 'груша', 'гуашь',
  'губка', 'гудок', 'гуляш', 'дверь', 'десна', 'длина', 'диван', 'диета', 'дождь', 'дочка', 'драка', 'дрова',
  'дудка', 'дупло', 'жажда', 'жених', 'жетон', 'живот', 'жизнь', 'жилец', 'жираф', 'забор', 'завод', 'завуч',
  'закат', 'замок', 'зверь', 'зебра', 'земля', 'зефир', 'игрок', 'икота', 'икона', 'индюк', 'искра', 'испуг',
  'казак', 'какао', 'камин', 'каска', 'касса', 'каток', 'кепка', 'кефир', 'кисть', 'класс', 'книга', 'лавка',
  'лампа', 'лазер', 'лапша', 'левша', 'лидер', 'лилия', 'леший', 'лимон', 'лодка', 'ложка', 'лопух', 'магия',
  'майка', 'малыш', 'манго', 'маска', 'масло', 'месяц', 'метро', 'метла', 'мечта', 'минус', 'мираж', 'молот',
  'мороз', 'моряк', 'насос', 'наряд', 'наука', 'нефть', 'нитка', 'нищий', 'номер', 'носок', 'обман', 'обрез',
  'обруч', 'овраг', 'огонь', 'озеро', 'окунь', 'олень', 'омлет', 'орден', 'осень', 'осина', 'отдел', 'отдых',
  'отряд', 'пакет', 'палец', 'палач', 'панда', 'парик', 'парта', 'парус', 'паста', 'пасха', 'пауза', 'пачка',
  'певец', 'пение', 'перец', 'песок', 'петля', 'петух', 'песня', 'печка', 'пешка', 'пирог', 'питон', 'радио',
  'ребро', 'ребус', 'редис', 'режим', 'речка', 'рояль', 'рубин', 'рубль', 'рукав', 'ручей', 'ручка', 'рыбак',
  'салют', 'сахар', 'свеча', 'свист', 'синяк', 'сироп', 'склад', 'скрип', 'слава', 'слива', 'слуга', 'смена',
  'смерч', 'смола', 'смысл', 'собор', 'совет', 'спина', 'спирт', 'спорт', 'спуск', 'среда', 'ссора', 'стадо',
  'старт', 'ствол', 'стена', 'столб', 'страх', 'струя', 'сумма', 'суфле', 'схема', 'сцена', 'сюжет', 'тайна',
  'такса', 'такси', 'талия', 'талон', 'танец', 'театр', 'текст', 'тесто', 'ткань', 'толпа', 'томат', 'точка',
  'трава', 'уголь', 'удача', 'укроп', 'улица', 'халат', 'халва', 'хвост', 'химия', 'хобот', 'хобби', 'хомяк',
  'цапля', 'центр', 'цифра', 'чайка', 'чашка', 'червь', 'шакал', 'шалаш', 'шалун', 'шапка', 'шарик', 'шишка',
  'школа', 'шланг', 'шляпа', 'шмель', 'шпион', 'штаны', 'штамп', 'штраф', 'шутка', 'эклер', 'экран', 'юноша',
  'ябеда', 'ягода', 'ягуар', 'якорь'
];
