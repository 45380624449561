import React from 'react';
import { useSpring, animated } from 'react-spring';
import cx from 'classnames';

import SvgWrapper from '~/components/SvgWrapper/SvgWrapper';
import MaxSkillIcon from '~/components/MaxSkillIcon/MaxSkillIcon';
import CircleProgress from '~/components/CircleProgress/CircleProgress';
import StrokeGradient from '~/components/StrokeGradient/StrokeGradient';

import detectBrowser from '~/services/detect-browser';

import './skill.scss';

const Skill = (props) => {
  const { type, name, max, value, startColor, endColor, amountColor, classNameCircle,
          isAnimated, className, notFilter, classNameCircleHolder } = props;
  const { isFirefox, isSafari } = detectBrowser();
  const gradientId = `strokeGradient-${ type }`;
  const circleValue = (1 - value / max) * 100;
  const classesSkill = cx('skill', className);
  const isMaxValue = value === max;
  const isFilter = !(isSafari || isFirefox || notFilter);
  const pointsAnimation = useSpring({
    from: { val: 0 },
    to: { val: max },
    delay: 2800
  });

  return (
    <div className={ classesSkill }>
      <div className="skill__progress">
        <StrokeGradient startColor={ startColor } endColor={ endColor } gradientId={ gradientId } />

        <div className="skill__icon-box">
          <SvgWrapper className="skill__icon" type={ type } isFilter={ isFilter } />
        </div>

        <CircleProgress className={ classNameCircleHolder } classNameCircle={ classNameCircle }
                        value={ circleValue } gradientId={ gradientId }/>
      </div>

      <div className="skill__name">
        { name }
      </div>

      <div style={ { color: amountColor } } className="skill__amount">
        {
          isMaxValue ?
          <MaxSkillIcon fill={ amountColor }/> :
          <>
            <span className="skill__value">{ value } / </span>
            <animated.span className="skill__max">
              { isAnimated ? pointsAnimation.val.interpolate((val) => Math.floor(val)) : max }
            </animated.span>
          </>
        }
      </div>
    </div>
  );
}

export default Skill;
