import React from 'react';
import cx from 'classnames';

import Icon from '~/components/Icon/Icon';
import SvgWrapper from '~/components/SvgWrapper/SvgWrapper';

import detectBrowser from '~/services/detect-browser';

import './trainerSkillBubble.scss';

const TrainerSkillBubble = (props) => {
  const { iconName, iconNamePuzzle, skill, isMission } = props;
  const { isFirefox } = detectBrowser();
  const classesSkillBubble = cx("trainer-skill-bubble", {
    "trainer-skill-bubble_mission": isMission
  });

  return (
    <div className={ classesSkillBubble }>
      <SvgWrapper className="trainer-skill-bubble__icon" type={ iconName } isFilter={ !isFirefox } />
      <div className="trainer-skill-bubble__content">
        <Icon className="trainer-skill-bubble__subicon trainer-skill-bubble__subicon_puzzle" name={ iconNamePuzzle }/>
        <span className="trainer-skill-bubble__value">+{ skill }</span>
      </div>
    </div>
  );
};

export default TrainerSkillBubble;
