export default {
  image: require('~/static/trainers/funnyPoints/pepper.png'),
  width: 800,
  height: 919,
  offsetX: 30,
  offsetY: 30,
  points: [
    { id: 1,  x: -30,  y: 241 },
    { id: 2,  x: -126, y: 271 },
    { id: 3,  x: -232, y: 648 },
    { id: 4,  x: -129, y: 731 },
    { id: 5,  x: -116, y: 788 },
    { id: 6,  x: -63,  y: 811 },
    { id: 7,  x: -13,  y: 794 },
    { id: 8,  x: 112,  y: 800 },
    { id: 9,  x: 299,  y: 484 },
    { id: 10, x: 258,  y: 368 }
  ]
}
