export default ({ seconds, figures }) => {
  const tooltipContent = 'Расставь все фигуры так, чтобы детали не накладывались друг на друга и в контуре заполнилось всё пространство.';
  return {
    timer: [seconds * 1000],
    figures,
    isMiniTooltip: true,
    tooltipContent,
    winnerPoints: {
      focus: 3,
      mind: 1
    }
  };
}
