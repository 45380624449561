export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    tablet: 5,
    mobile: 15
  },
  outline: [
    [
      {
        desktop: {
          x: 206.82,
          y: 185.82
        },
        tablet: {
          x: 440.14,
          y: 248.57
        },
        mobile: {
          x: 150.21,
          y: 97.21
        }
      },
      {
        desktop: {
          x: 206.82,
          y: 148.82
        },
        tablet: {
          x: 382.85,
          y: 248.57
        },
        mobile: {
          x: 192.43,
          y: 97.21
        }
      },
      {
        desktop: {
          x: 269.65,
          y: 148.82
        },
        tablet: {
          x: 271.57,
          y: 137.28
        },
        mobile: {
          x: 275.64,
          y: 180.43
        }
      },
      {
        desktop: {
          x: 395.46,
          y: 274.64
        },
        tablet: {
          x: 215.28,
          y: 137.28
        },
        mobile: {
          x: 317.85,
          y: 180.43
        }
      },
      {
        desktop: {
          x: 459.28,
          y: 274.64
        },
        tablet: {
          x: 215.28,
          y: 169.28
        },
        mobile: {
          x: 274.28,
          y: 224
        }
      },
      {
        desktop: {
          x: 395.92,
          y: 338
        },
        tablet: {
          x: 159.57,
          y: 225
        },
        mobile: {
          x: 157,
          y: 224
        }
      },
      {
        desktop: {
          x: 216,
          y: 338
        },
        tablet: {
          x: 145,
          y: 225
        },
        mobile: {
          x: 97,
          y: 164
        }
      },
      {
        desktop: {
          x: 126,
          y: 248
        },
        tablet: {
          x: 225.22,
          y: 305.21
        },
        mobile: {
          x: 108.43,
          y: 164
        }
      },
      {
        desktop: {
          x: 144.64,
          y: 248
        },
        tablet: {
          x: 383.48,
          y: 305.21
        },
        mobile: {
          x: 150.21,
          y: 122.21
        }
      }
    ],
    [
      {
        desktop: {
          x: 305.18,
          y: 248
        },
        tablet: {
          x: 215.57,
          y: 225
        },
        mobile: {
          x: 150.43,
          y: 139.64
        }
      },
      {
        desktop: {
          x: 269.64,
          y: 212.46
        },
        tablet: {
          x: 215.57,
          y: 193.85
        },
        mobile: {
          x: 192.43,
          y: 139.64
        }
      },
      {
        desktop: {
          x: 207.72,
          y: 212.46
        },
        tablet: {
          x: 271.57,
          y: 193.85
        },
        mobile: {
          x: 216.79,
          y: 164
        }
      },
      {
        desktop: {
          x: 207.82,
          y: 248
        },
        tablet: {
          x: 302.72,
          y: 225
        },
        mobile: {
          x: 150.43,
          y: 164
        }
      }
    ]
  ],
  outlines: [
    {
      desktop: {
        x: 216,
        y: 338,
        rotation: 90
      },
      tablet: {
        x: 224,
        y: 305.21,
        rotation: 90
      },
      mobile: {
        x: 157,
        y: 224,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 306,
        y: 248,
        rotation: -90
      },
      tablet: {
        x: 305,
        y: 225,
        rotation: -90
      },
      mobile: {
        x: 217,
        y: 164,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 395.64,
        y: 211,
        rotation: -45
      },
      tablet: {
        x: 383.57,
        y: 192,
        rotation: -45
      },
      mobile: {
        x: 275.43,
        y: 138,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 300,
        y: 242.82,
        rotation: 45
      },
      tablet: {
        x: 299,
        y: 221.28,
        rotation: 45
      },
      mobile: {
        x: 213,
        y: 160.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 175.82,
        y: 280.46,
        rotation: 135
      },
      tablet: {
        x: 187.28,
        y: 253.85,
        rotation: 135
      },
      mobile: {
        x: 129.21,
        y: 185.64,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 365.28,
        y: 244.46,
        rotation: -135
      },
      tablet: {
        x: 356.14,
        y: 221.85,
        rotation: -135
      },
      mobile: {
        x: 255.85,
        y: 160.64,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 175,
        y: 180.64,
        rotation: 45
      },
      tablet: {
        x: 187,
        y: 165.57,
        rotation: 45
      },
      mobile: {
        x: 129,
        y: 118.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
}
