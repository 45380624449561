import React from 'react';

import Popup from '~/components/Popup/Popup';
import LocationAbout from '~/components/LocationAbout/LocationAbout';

const LocationAboutPopup = (props) => {
  const { location, closeHandler, isOpen } = props;

  return (
    <Popup className="location-about-popup" isOpen={ isOpen }>
      <LocationAbout onRequestClose={ closeHandler } { ...location } />
    </Popup>
  )
}

export default LocationAboutPopup;
