export default {
  shapes: [ 1, 2, 5, 6, 7, 4, 3 ],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 40
  },
  outline: [
    {
      desktop: { x: 320, y: 15 },
      tablet: { x: 322, y: 19 },
      mobile: { x: 228, y: 14 }
    },
    {
      desktop: { x: 275, y: 60 },
      tablet: { x: 282, y: 59 },
      mobile: { x: 198, y: 44 }
    },
    {
      desktop: { x: 310.83, y: 95.83 },
      tablet: { x: 308.28, y: 85.28 },
      mobile: { x: 218.21, y: 64.21 }
    },
    {
      desktop: { x: 311.53, y: 159.35 },
      tablet: { x: 308.28, y: 141.57 },
      mobile: { x: 218.21, y: 106.43 }
    },
    {
      desktop: { x: 327.41, y: 175.23 },
      tablet: { x: 322.14, y: 155.43 },
      mobile: { x: 228.11, y: 116.32 }
    },
    {
      desktop: { x: 264, y: 238.64 },
      tablet: { x: 266, y: 211.57 },
      mobile: { x: 186, y: 158.43 }
    },
    {
      desktop: { x: 282.68, y: 257.32 },
      tablet: { x: 287.72, y: 233.28 },
      mobile: { x: 201.79, y: 174.21 }
    },
    {
      desktop: { x: 193.08, y: 346.92 },
      tablet: { x: 208.72, y: 312.28 },
      mobile: { x: 142.79, y: 233.21 }
    },
    {
      desktop: { x: 163.64, y: 347 },
      tablet: { x: 179.28, y: 312.28 },
      mobile: { x: 122.21, y: 233.21 }
    },
    {
      desktop: { x: 163.82, y: 410.46 },
      tablet: { x: 179.28, y: 368.85 },
      mobile: { x: 122.21, y: 275.64 }
    },
    {
      desktop: { x: 227.28, y: 347 },
      tablet: { x: 235.14, y: 313 },
      mobile: { x: 163.85, y: 234 }
    },
    {
      desktop: { x: 372.36, y: 347 },
      tablet: { x: 366.28, y: 313 },
      mobile: { x: 261.21, y: 234 }
    },
    {
      desktop: { x: 373, y: 409.82 },
      tablet: { x: 366.28, y: 367.57 },
      mobile: { x: 261.21, y: 275.43 }
    },
    {
      desktop: { x: 436.46, y: 409.64 },
      tablet: { x: 422.85, y: 367.57 },
      mobile: { x: 303.64, y: 275.43 }
    },
    {
      desktop: { x: 391.28, y: 364.46 },
      tablet: { x: 379.14, y: 323.85 },
      mobile: { x: 270.85, y: 242.64 }
    },
    {
      desktop: { x: 391.28, y: 238.64 },
      tablet: { x: 379.14, y: 211.57 },
      mobile: { x: 270.85, y: 158.43 }
    },
    {
      desktop: { x: 374.46, y: 221.82 },
      tablet: { x: 364.85, y: 197.28 },
      mobile: { x: 260.64, y: 148.21 }
    },
    {
      desktop: { x: 375.17, y: 159.35 },
      tablet: { x: 364.85, y: 141.57 },
      mobile: { x: 260.64, y: 106.43 }
    },
    {
      desktop: { x: 320.41, y: 104.59 },
      tablet: { x: 322.14, y: 98.86 },
      mobile: { x: 228.11, y: 73.89 }
    },
    {
      desktop: { x: 365, y: 60 },
      tablet: { x: 362, y: 59 },
      mobile: { x: 258, y: 44 }
    }
  ],
  outlines: [
    {
      desktop: { x: 275, y: 15 },
      tablet: { x: 282, y: 19 },
      mobile: { x: 198, y: 14 },
      rotation: 0
    },
    {
      desktop: { x: 279, y: 126.82 },
      tablet: { x: 280, y: 113.28 },
      mobile: { x: 197, y: 85.21 },
      rotation: 315
    },
    {
      desktop: { x: 327.64, y: 302.28 },
      tablet: { x: 322.57, y: 268.14 },
      mobile: { x: 228.43, y: 200.85 },
      rotation: 225
    },
    {
      desktop: { x: 264, y: 238.64 },
      tablet: { x: 266, y: 211.57 },
      mobile: { x: 186, y: 158.43 },
      rotation: 315
    },
    {
      desktop: { x: 193, y: 347 },
      tablet: { x: 208, y: 313 },
      mobile: { x: 142, y: 234 },
      rotation: 270
    },
    {
      desktop: { x: 195.64, y: 315 },
      tablet: { x: 207.57, y: 284 },
      mobile: { x: 143.43, y: 212 },
      rotation: 45
    },
    {
      desktop: { x: 341, y: 377.82 },
      tablet: { x: 338, y: 339.28 },
      mobile: { x: 240, y: 254.21 },
      rotation: 315
    }
  ]
};
