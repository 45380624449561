import React from 'react';
import cx from 'classnames';

import './points.scss';

const Points = (props) => {
  const { points, clickedPoints, resultMode, isActive, answerTab, pointClickHandler } = props;

  return (
    <div className="points">
      {
        points.map((item, index) => {
          let wrong = false;
          const isActivePoint = clickedPoints.some(point => point.id === item.id);
          const isDisabled = !isActive || resultMode || isActivePoint;
          const style = { marginLeft: item.x, top: item.y };
          const handlerPointClick = () => pointClickHandler(item);

          if (!isActive) {
            wrong = clickedPoints[index] ? clickedPoints[index].wrong : true;
          }

          const className = cx('points__point', {
            'points__point_active': isActivePoint || answerTab !== 'answer',
            'points__point_wrong': wrong && answerTab === 'answer'
          });

          return (
            <button
              type="button"
              className={ className }
              disabled={ isDisabled }
              key={ `${ item.x }_${ item.y }` }
              style={ style }
              data-cy="funny-point"
              data-cy-funny-point-id={ index }
              onClick={ handlerPointClick }
            />
          );
        })
      }
    </div>
  );
}

export default Points;
