import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

import content from './content';

const templateLevels = {
  1: { seconds: 20 },
  2: { seconds: 10 },
  3: { seconds: 20 },
  4: { seconds: 10 },
  5: { seconds: 30 },
  6: { seconds: 15 },
  7: { seconds: 30 },
  8: { seconds: 15 },
  9: { seconds: 40 },
  10: { seconds: 20 }
};

export default getLevels(templateLevels, getLevelParams, { name: 'content', data: content });
