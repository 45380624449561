import React from 'react';
import cx from 'classnames';

import './giftGot.scss';

const GiftGot = ({ gift, isName, withContainer, giftFraction }) => {
  const isFraction = giftFraction || giftFraction === 0;
  const fractionStyle = {
    height: `${(1 - giftFraction) * 100}%`
  }

  if (1 - giftFraction > 0 && giftFraction !== 0) {
    fractionStyle.borderRadius = '15px 15px 0 0'
  }

  return (
    <div className={ cx('gift-got', {
      'gift-got_disabled': gift.isDisabled,
      'gift-got_with-container': withContainer,
      'gift-got_fractioned': isFraction
    }) }>
      {
        isFraction ?
        <div
          className="gift-got__fraction"
          style={ fractionStyle }
        ></div>
        : null
      }

      <div className="gift-got__img-box">
        <img
          src={ gift.image }
          alt={ gift.name }
          className="gift-got__image"
        />
      </div>

      <div className="gift-got__item"></div>

      {
        isName ? <p className="gift-got__name">{ gift.name }</p> : null
      }
    </div>
  )
}

export default GiftGot;
