export default {
  image: require('~/static/trainers/funnyPoints/turnip.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: 74, y: 357 },
    { id: 2,  x: 145, y: 355 },
    { id: 3,  x: 226, y: 387 },
    { id: 4,  x: 269,  y: 439 },
    { id: 5,  x: 272,   y: 517 },
    { id: 6,  x: 244,  y: 588 },
    { id: 7,  x: 196,  y: 637 },
    { id: 8,  x: 146,  y: 667 },
    { id: 9,  x: 115,  y: 704 },
    { id: 10, x: 105,  y: 746 },
    { id: 11, x: 98,  y: 796 },
    { id: 12, x: 78,   y: 753 },
    { id: 13, x: 40,    y: 722 },
    { id: 14, x: -16, y: 693 },
    { id: 15, x: -74,   y: 672 },
    { id: 16, x: -154, y: 633 },
    { id: 17, x: -184,  y: 547 },
    { id: 18, x: -169,   y: 466 },
    { id: 19, x: -116,   y: 411 },
    { id: 20, x: -55,   y: 382 }
  ]
};
