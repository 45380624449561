import React from 'react';
import cx from 'classnames';

import './letter.scss';

const Letter = (props) => {
  const { letter, isDisabled, onClick } = props;
  const letterClass = cx('letter', {
    'letter_disabled': isDisabled
  })

  const onLetterClickHandler = () => {
    onClick(letter);
  }

  return (
    <div className={ letterClass } onClick={ onLetterClickHandler }>
      <div className="letter__holder">
        { letter }
      </div>
    </div>
  )
}

export default Letter;
