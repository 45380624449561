import getShuffleArray from '~/services/get-shuffle-array';

export default function (longMemoryParams) {
  let longMemoryTaskRememberedItems = {};
  let longMemoryTaskParams = {};
  const { taskParams, places, textAnnounce } = longMemoryParams;
  const { timer } = taskParams;
  const currentPlaces = getShuffleArray(places, taskParams.picturesAmount);

  longMemoryTaskRememberedItems = {
    items: currentPlaces,
    text: textAnnounce
  };

  longMemoryTaskParams = {
    ...longMemoryParams,
    timer
  };

  return { longMemoryTaskRememberedItems, longMemoryTaskParams };
}
