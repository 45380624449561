import React from 'react';
import Icon from '~/components/Icon/Icon';

import './tooltipButton.scss';

/* eslint-disable */
import showTooltip from './assets/show-tooltip.svg';
import closeTooltip from './assets/close-tooltip.svg'
/* eslint-enable */

const TooltipButton = ({ onClick, iconName }) => (
  <button
    className="tooltip-button"
    onClick={ onClick }
  >
    <Icon name={ iconName } className="tooltip-button__icon" />
  </button>
)

export default TooltipButton;
