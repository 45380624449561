import gifts from '~/shared/gifts';

export default {
  id: 1,
  name: 'Лондонский глаз',
  longMemory: true,
  gift: gifts.belt,
  description: 'Колесо обозрения «Лондонский глаз» по праву можно назвать визитной карточкой Лондона.',
  story: '«Лондонский глаз» вращается со скоростью 0,9 километра в час, без остановки. Пассажиры входят и выходят из него на ходу. Из-за суеверия на колесе обозрения отсутствует 13-я капсула. За один круг «Лондонский глаз» может перевезти 800 человек, что сопоставимо количеству пассажиров 11 лондонских автобусов.',
  partingWords: 'Помимо секретных навыков, которые ты будешь развивать в нашей школе, выполняя задания первых миссий, ты соберёшь набор агента-новичка и начнёшь осматривать Лондон. В этой миссии ты получишь Пояс для гаджетов, когда выполнишь все задания!',
  textLosing: 'Не справился? Не беда! Попробуй потренироваться на тренажёрах и приходи снова!',
  textPartially: 'Увы, не все задания миссии были выполнены. Но ты все равно смог развить свои навыки. Попробуй потренироваться на тренажёрах и приходи снова!',
  textSuccess: 'Мистер Фоксман любит, когда ученики опрятно выглядят и быстро учатся. Смотри, какой теперь у тебя костюм и как увеличились твои уровни навыков! Ты молодец!',
  imgs: [
    require('~/static/locations/london/london-eye.jpg')
  ],
  locked: false
};
