import * as types from './constants';

import apiClient from '~/services/api-client';

import compute from './compute';

export const fetchLocations = () => {
  return (dispatch, getState) => {
    const { profile } = getState();
    const payload = apiClient.getLocations().then((res) => compute(res, profile));

    return dispatch({
      type: types.FETCH_LOCATIONS,
      payload
    }).catch((error) => console.log(error));
  };
};

export const openNextMission = (locationName, missionName) => {
  return (dispatch, getState) => {
    let payload = null;
    const { data, ids: locationNames } = getState().locations;
    const location = data[locationName];
    const missionNames = Object.keys(location.missions);
    const missionIndex = missionNames.findIndex((name) => name === missionName);
    const nextMissionName = missionNames[missionIndex + 1];
    const locationIndex = locationNames.findIndex((name) => name === locationName);
    const nextLocationName = locationNames[locationIndex + 1];

    if (nextMissionName) {
      payload = {
        locationName,
        missionName: nextMissionName
      };
    }
    else if (nextLocationName) {
      const nextLocationMissionName = Object.keys(data[nextLocationName].missions)[0];
      payload = {
        locationName: nextLocationName,
        missionName: nextLocationMissionName
      };
    }

    if (!payload) {
      return;
    }

    return dispatch({
      type: types.OPEN_NEXT_MISSION,
      payload
    });
  };
};

export const goToNextTrainer = () => ({
  type: types.GO_TO_NEXT_TRAINER
});

export const setShownLongMemoryTask = (payload = true) => ({
  type: types.SET_SHOWN_LONG_MEMORY_TASK,
  payload
});

export const saveLongMemoryData = (payload) => ({
  type: types.SAVE_LONG_MEMORY_DATA,
  payload
});

export const onFinish = () => ({
  type: types.ON_FINISH
});

export const setTrainerStage = (payload) => ({
  type: types.SET_TRAINER_STAGE,
  payload
});

export const setMissionPopupShown = (payload) => ({
  type: types.SET_MISSION_POPUP_SHOWN,
  payload
});
