export default {
  image: require('~/static/trainers/funnyPoints/banana.png'),
  width: 800,
  height: 919,
  points: [
    { id: 1,  x: 145,  y: 194 },
    { id: 2,  x: 195,  y: 185 },
    { id: 3,  x: 185,  y: 262 },
    { id: 4,  x: 195,  y: 491 },
    { id: 5,  x: 41,   y: 635 },
    { id: 6,  x: -241, y: 654 },
    { id: 7,  x: -240, y: 609 },
    { id: 8,  x: -90,  y: 537 },
    { id: 9,  x: 57,   y: 412 },
    { id: 10, x: 116,  y: 305 }
  ]
}
