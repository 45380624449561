import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '~/components/Header/Header';
import Footer from '~/components/Footer/Footer';

import getQueryVariable from '~/services/get-query-variable';

import scrollTop from '~/services/scroll-top';

import "@babel/polyfill";
import 'normalize.css';
import '~/static/styles/index.scss';
import './page.scss';

const Page = (props) => {
  const { theme, children, notHeader, profile, history, location, notFooter, isNotAuth } = props;
  const { token: profileToken, created_at } = profile;
  const token = getQueryVariable('token');
  const { pathname, search } = location;
  const isAdmin = pathname === '/admin/generateTokens/';
  const isRender = token || isAdmin || (!token && !profileToken);

  useEffect(() => {
    scrollTop();
    if (!token && !isAdmin && !isNotAuth) {
      history.replace(`${ pathname }?utm_source=guest-links&utm_medium=promo&utm_campaign=first-launch&utm_term=${ created_at }&utm_content=${ profileToken }&token=${ profileToken }`);
    }
  }, [ search ]);

  return (
    isRender &&
    <div className="page">
      { !notHeader && <Header theme={ theme } /> }

      <div className="page__main">
        <div className="page__holder">
          { children }
        </div>
      </div>

      { !notFooter && <Footer /> }
    </div>
  );
};

const mapStateToProps = (state) => ({ profile: state.profile });

export default withRouter(connect(mapStateToProps)(Page));
