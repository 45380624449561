import React from 'react';
import cx from 'classnames';

import { TABS } from './constants';

import './tabs.scss';

const Tabs = (props) => {
  const { onClick, isCorrect, selected } = props;

  const renderTab = ({ title, id }) => {
    const classesTab = cx('tabs__tab', {
      'tabs__tab_selected': selected === id,
      'tabs__tab_correct': isCorrect
    });
    const handlerClick = !isCorrect ? () => onClick(id) : null;

    return (
      <button className={ classesTab } key={ id } onClick={ handlerClick }>
        { title }
      </button>
    );
  };

  return (
    <div className="tabs">
      <div className="tabs__holder">
        {
          isCorrect ?
          renderTab({ id: 'correct', title: 'Верно' }) :
          TABS.map(item => renderTab(item))
        }
      </div>
    </div>
  );
};

export default Tabs;
