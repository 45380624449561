export default [
  {
    id: 1,
    image: require('~/static/trainers/longMemory/moscow/kremlin.jpg'),
    name: 'Московский Кремль',
    text: 'Символ российской столицы — Московский Кремль — невероятно интересная древняя крепость. Его возвели для защиты от врагов более 500 лет назад. В наши дни он стал самым узнаваемым символом не только Москвы, но и всей России. Именно Кремль является самой древней постройкой в Москве. Каждая из звёзд, венчающих кремлёвские башни, весит больше тонны! Московский Кремль — самая крупная во всей Европе крепость.'
  },
  {
    id: 2,
    image: require('~/static/trainers/longMemory/moscow/moscow-city.jpg'),
    name: 'Бизнес-центр «Москва-Сити»',
    text: 'Бизнес-центр «Москва-Сити» — крупнейший деловой, культурный и архитектурный центр с мировым значением. В этом комплексе имеется все необходимое для бизнеса, отдыха и развлечений. Высота самого высокого небоскреба — 75 этажей! Каждое здание здесь уникально!'
  },
  {
    id: 3,
    image: require('~/static/trainers/longMemory/moscow/ostankino.jpg'),
    name: 'Останкинская башня',
    text: 'Высота Останкинской башни — 540 м, это самое высокое здание в Европе! Такая огромная конструкция весит примерно 55 тысяч тонн! Столько же весят 11 тысяч слонов! Останкинская башня построена из металла и железобетона, который с годами становится только крепче. На высоте птичьего полета расположены смотровая площадка и ресторан с тремя вращающимися залами.'
  },
  {
    id: 4,
    image: require('~/static/trainers/longMemory/moscow/red-square.jpg'),
    name: 'Красная площадь',
    text: 'Красную площадь в Москве называют красной не только потому, что она красивая, она еще и самая важная площадь нашей страны. Если бы она умела говорить, то рассказала бы о себе много интересного: о сражениях и гуляньях, о царях и страшных преступниках, о простых людях и о многом другом. В наши дни на Красной площади проводятся парады, праздничные демонстрации и концерты. Взглянуть на площадь приезжают со всего мира.'
  },
  {
    id: 5,
    image: require('~/static/trainers/longMemory/moscow/arbat.jpg'),
    name: 'Арбат',
    text: 'Арбат — одна из самых знаменитых и древнейших улиц Москвы, которой уже более 500 лет. Здесь можно увидеть много красивых зданий с богатой историей. В наше время это пешеходная зона с большим количеством сувенирных лавок, где туристов встречают художники, музыканты, певцы.'
  },
  {
    id: 6,
    image: require('~/static/trainers/longMemory/moscow/victory-park.jpg'),
    name: 'Парк Победы',
    text: 'Парк Победы — один из крупнейших парков в мире. Парк Победы посвящён победе в Великой Отечественной войне. На Площади Победителей возвышается мемориальный обелиск. Высота его составляет 141,8 метра. Эта цифра напоминает о 1418 днях и ночах Великой Отечественной войны. Ни один праздник не обходит стороной Парк Победы. Здесь часто проходят концерты, народные гуляния и т. п.'
  },
  {
    id: 7,
    image: require('~/static/trainers/longMemory/moscow/sparrow-hills.jpg'),
    name: 'Воробьевы горы',
    text: 'Воробьевы горы получили свое название от села Воробьево, которое существовало здесь в средние века. Воробьевы горы считают одним из тех семи холмов, на которых построена Москва. Отсюда открывается отличная панорама города. Сюда приезжают свадебные кортежи, здесь вдохновляются видами поэты и писатели, тренируются спортсмены-лыжники. Самый главный университет нашей страны — МГУ — также расположен на Воробьевых горах.'
  },
  {
    id: 8,
    image: require('~/static/trainers/longMemory/moscow/basils-cathedral.jpg'),
    name: 'Собор (Храм) Василия Блаженного',
    text: 'На Красной площади величественно красуется один из самых главных символов России и Москвы — собор Покрова Божией Матери на Рву, а Храм Василия Блаженного — название народное. Связано это с тем, что в нем хранятся мощи русского святого Василия Блаженного. Собор похож на сказочный терем, а его 10 куполов напоминают яркие леденцы. Построили этот волшебный собор в честь победы над Казанским ханством по приказу самого Ивана Грозного. Началось строительство в далеком 1555 году.'
  },
  {
    id: 9,
    image: require('~/static/trainers/longMemory/moscow/vdnh.jpg'),
    name: 'Выставка достижений народного хозяйства (ВДНХ)',
    text: 'Территория Выставки достижений народного хозяйства (ВДНХ) состоит из большого парка, в котором расположены выставочные павильоны. В них регулярно проходят самые различные выставки и концерты. Между зданиями расположены широкие аллеи, окруженные клумбами и деревьями. На ВДНХ есть знаменитые фонтаны, статуи, аттракционы, модели космического корабля и самолетов.'
  },
  {
    id: 10,
    image: require('~/static/trainers/longMemory/moscow/tomb.jpg'),
    name: 'Мавзолей Ленина',
    text: 'В Москве прямо на Красной площади возведен самый настоящий склеп, в котором лежит тело советского вождя Владимира Ильича Ленина. В 2017 году отмечали 100 лет со дня Великой октябрьской революции, символом которой стал Владимир Ильич Ленин. Мавзолей Ленина за год посещает почти полмиллиона человек!'
  },
  {
    id: 11,
    image: require('~/static/trainers/longMemory/moscow/moskvarium.jpg'),
    name: 'Москвариум',
    text: 'Москвариум — самый большой океанариум в России. Внутри здания размещены сотни аквариумов: от маленьких до гигантских. Некоторые из них настолько огромные, что в них могут жить даже акулы и косатки! А аквариум с акулами находится прямо над пешеходным коридором, и проплывающих над тобой акул можно разглядеть на расстоянии вытянутой руки! Тут можно увидеть и других водных обитателей — осьминогов, морских звезд, крокодилов, раков и креветок.'
  },
  {
    id: 12,
    image: require('~/static/trainers/longMemory/moscow/tretiyakovskaya.jpg'),
    name: 'Третьяковская галерея',
    text: 'Этот музей основал богатый московский купец Павел Третьяков более 150 лет назад. Он очень ценил искусство и хотел, чтобы картины известных художников мог увидеть любой желающий. Денег у купца было много и он покупал скульптуры, статуэтки, портреты, натюрморты и пейзажи, созданные лучшими мастерами. Когда его коллекция стала огромной, он подарил ее городу. Главное богатство галереи – картины, многие из которых ты можешь встретить в школьных учебниках.'
  },
  {
    id: 13,
    image: require('~/static/trainers/longMemory/moscow/GUM.jpg'),
    name: 'Государственный универсальный магазин',
    text: 'Государственный универсальный магазин, сокращенно ГУМ, расположен прямо на Красной площади. Современное здание открылось более 130 лет назад. Во времена СССР ГУМ имел невероятную популярность у советских граждан. Очереди в нем иногда бывали настолько длинными, что для их регулирования привлекали специальные наряды милиции. Здесь впервые в нашей стране стали применять жалобную книгу и ценники на товары. В наши дни ГУМ — это территория дорогих бутиков, исторических магазинов и авторских шоурумов.'
  },
  {
    id: 14,
    image: require('~/static/trainers/longMemory/moscow/musiycosmonavtiki.jpg'),
    name: 'Музей космонавтики',
    text: 'Это удивительный музей, в котором  можно узнать о том, как человек осваивал космос. Тут можно попробовать еду космонавтов из тюбиков, увидеть настоящие космические мотоциклы, луноходы и даже скафандры — костюмы, в которых космонавты и астронавты выходят в космос! А еще здесь можно потрогать настоящие метеориты — камни, прилетевшие к нам из глубокого космоса. Есть в музее и личные вещи космонавтов, к примеру, Юрия Гагарина — первого человека, полетевшего в космос.'
  },
  {
    id: 15,
    image: require('~/static/trainers/longMemory/moscow/zoo.jpg'),
    name: 'Московский зоопарк',
    text: 'Московский зоопарк — первый зоопарк в мире, обустроенный в стране с морозными зимами. Открылся он больше 150 лет назад и не прекращал работу даже в голодные военные годы. Сейчас на территории зоопарка обитает около семи с половиной тысяч самых различных животных. Здесь можно полюбоваться на диковинных птиц, грозных хищников, змей и крокодилов. Крупные животные, такие как слоны, белые медведи, жирафы, зебры и верблюды, содержатся в открытых вольерах на улице. Для всех животных созданы комфортные условия.'
  },
  {
    id: 16,
    image: require('~/static/trainers/longMemory/moscow/historymuseum.jpg'),
    name: 'Государственный исторический музей',
    text: 'Государственный исторический музей, сокращенно ГИМ, — самый крупный в России. Он расположен в самом сердце нашей Родины — это доказывает даже его адрес: г. Москва, Красная площадь, дом № 1. Каждый зал музея — архитектурный шедевр. Во всем музее находятся более 22 тысяч экспонатов, на знакомство с ними уйдет целых 4-5 часов!'
  },
  {
    id: 17,
    image: require('~/static/trainers/longMemory/moscow/apple.jpg'),
    name: 'Музей техники Apple',
    text: 'В этом удивительном музее можно проследить эволюцию мыши, клавиатуры, приставки, цифровой камеры и прочих аксессуаров. На выставке ты увидишь первые компьютеры, первый ноутбук с «яблоком» и другие устройства. Здесь практически все экспонаты находятся в исправном состоянии и в свободном доступе. Каждый посетитель сможет испытать устройства, поиграв в первые компьютерные игры.'
  },
  {
    id: 18,
    image: require('~/static/trainers/longMemory/moscow/circus.jpg'),
    name: 'Большой московский государственный цирк',
    text: 'Это самый большой стационарный цирк в Европе и России. Его огромную крышу видно издалека. Главная изюминка цирка — конный, водный, ледовый, иллюзионный и световой манежи, которые можно быстро менять в зависимости от представления. А еще один манеж — для репетиций — спрятали от глаз посетителей в глубине закулисья. В этом цирке выступают самые известные дрессировщики со своими грациозными животными, акробаты, клоуны и другие артисты.'
  },
  {
    id: 19,
    image: require('~/static/trainers/longMemory/moscow/planetarium.jpg'),
    name: 'Московский планетарий',
    text: 'Московский планетарий увлекает своих гостей от самого порога… Тут даже гардероб необычный — вешалки с одеждой поднимаются под потолок по рельсам, а нужный номерок ищет специальная программа. В планетарии находятся несколько залов, и каждый со своей изюминкой. Например, в зале, посвященном солнечной системе, повсюду макеты планет и спутников. И вся вселенная как на ладони…А в большом звездном зале можно увидеть панораму из 200 тысяч огоньков — мерцающих звезд.'
  },
  {
    id: 20,
    image: require('~/static/trainers/longMemory/moscow/bolshoy.jpg'),
    name: 'Большой театр',
    text: 'Государственный академический Большой театр России — символ величия культуры России. Именно на его сцене были поставлены первые русские оперы и балеты. Большой театр имеет солидный вес не только в нашей стране, но и за рубежом, ведь на его сцене всегда выступали лучшие певцы и танцоры своей эпохи.'
  }
];
