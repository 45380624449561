import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

const templateLevels = {
  1: {
    timer: [60, 240],
    amount: 6
  },
  2: {
    timer: [90, 360],
    amount: 9
  },
  3: {
    timer: [120, 480],
    amount: 12
  },
  4: {
    timer: [180, 720],
    amount: 15
  },
  5: {
    timer: [240, 960],
    amount: 18
  },
  6: {
    timer: [300, 1200],
    amount: 21
  }
};

export default getLevels(templateLevels, getLevelParams);
