import React, { useRef, Fragment } from 'react';
import Swiper from 'react-id-swiper';
import cx from 'classnames';

import Icon from '~/components/Icon/Icon';

import { MAX_AMOUT_IMAGES, MAX_AMOUT_IMAGES_MOBILE } from './constants';

import detectDevice from '~/services/detect-device';

/* eslint-disable */
import mnemonicArrow from './assets/mnemonicArrow.svg';
/* eslint-disable */

import './mnemonic-slider.scss';

const MnemonicSlider = (props) => {
  const swiper = useRef(null);
  const { currentMatrix } = props;
  const { width } = detectDevice();
  const amount = currentMatrix.length;
  const maxAmount = width < 768 ? MAX_AMOUT_IMAGES_MOBILE : MAX_AMOUT_IMAGES;
  const isSwiper = amount > maxAmount;

  return (
    isSwiper ?
    <div className="mnemonic-slider">
      <Swiper ref={ swiper } { ...getParamsSlider() }>
        { renderCards({ ...props, isSwiper }) }
      </Swiper>
      { renderControls(swiper) }
    </div> :
    <div className="mnemonic-slider">
      { renderCards({ ...props, isSwiper }) }
    </div>
  );
};

const renderControls = (swiper) => {
  const goNext = () => {
    swiper && swiper.current.swiper.slideNext();
  };

  const goPrev = () => {
    swiper && swiper.current.swiper.slidePrev();
  };

  return (
    <Fragment>
      <button className="mnemonic-slider__arrow mnemonic-slider__arrow_prev" onClick={ goPrev }>
        <Icon name="mnemonicArrow" className="mnemonic-slider__arrow-icon"/>
      </button>
      <button className="mnemonic-slider__arrow mnemonic-slider__arrow_next" onClick={ goNext }>
        <Icon name="mnemonicArrow" className="mnemonic-slider__arrow-icon"/>
      </button>
    </Fragment>
  );
};

const renderCards = (props) => props.currentMatrix.map((image, index) => renderCard({ ...props, image, index }));

const renderCard = (props) => {
  const { index, image, isFinish, isSwiper, handlerClickIconParent, selectedIndex } = props;
  const handlerDrag = (event) => {
    event.dataTransfer.setData('value', JSON.stringify({ image, index }));
  };
  const classesSlide = cx('mnemonic-slider__slide', {
    'mnemonic-slider__slide_not-swiper': !isSwiper,
    'mnemonic-slider__slide_active': selectedIndex === index
  });
  const handlerClickIcon = () => handlerClickIconParent(index);

  return (
    <div className={ classesSlide } draggable={ !isFinish } key={ index } onDragStart={ handlerDrag }
         onClick={ handlerClickIcon }>
      <img className="mnemonic-slider__icon" src={ image } alt={ `Карточка номер ${ index + 1 }` } />
    </div>
  );
};

const getParamsSlider = () => {
  return {
    slidesPerView: 'auto',
    containerClass: 'mnemonic-slider__swiper',
    spaceBetween: 10,
    allowTouchMove: false,
    shouldSwiperUpdate: true,
    navigation: {
      nextEl: '.mnemonic-slider__arrow_next',
      prevEl: '.mnemonic-slider__arrow_prev'
    }
  };
};

export default MnemonicSlider;
