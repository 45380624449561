export default {
  image: require('~/static/trainers/funnyPoints/cat.png'),
  width: 800,
  height: 919,
  points: [
    { id: 1,  x: -98,  y: 337 },
    { id: 2,  x: -151, y: 267 },
    { id: 3,  x: -142, y: 210 },
    { id: 4,  x: -149, y: 156 },
    { id: 5,  x: -133, y: 100 },
    { id: 6,  x: -98,  y: 153 },
    { id: 7,  x: -42,  y: 135 },
    { id: 8,  x: 24,   y: 150 },
    { id: 9,  x: 77,   y: 111 },
    { id: 10, x: 78,   y: 173 },
    { id: 11, x: 62,   y: 218 },
    { id: 12, x: 55,   y: 309 },
    { id: 13, x: 64,   y: 383 },
    { id: 14, x: 101,  y: 455 },
    { id: 15, x: 144,  y: 515 },
    { id: 16, x: 188,  y: 576 },
    { id: 17, x: 217,  y: 639 },
    { id: 18, x: 212,  y: 692 },
    { id: 19, x: 181,  y: 735 },
    { id: 20, x: 130,  y: 760 }
  ]
};
