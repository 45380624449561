import questions from './questions';

import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

const templateLevels = {
  1: {
    seconds: 20,
    amount: 7
  },
  2: {
    seconds: 15,
    amount: 10
  },
  3: {
    seconds: 13,
    amount: 12
  },
  4: {
    seconds: 10,
    amount: 15
  },
  5: {
    seconds: 11,
    amount: 17
  },
  6: {
    seconds: 9,
    amount: 20
  }
};

export default getLevels(templateLevels, getLevelParams, { name: 'questions', data: questions });
