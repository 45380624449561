import tableAngles from './tableAngles';

export default (s, o, outlines, screenType) => {
  // Расстояние при котором нужно показывать подсказку
  const d = 20;
  const CIRCLE_ANGLE = 360;
  const { variants, reference, id } = s.getAttrs();
  const goodAngles = [];
  // Контур фигуры похожей на текущую
  const refOutline = outlines.find((item) => item.id === reference);

  // Вычисление валидных углов вращения для вставки фигуры в контур
  for (let i = 1; i <= variants; i++) {
    const angle = o.rotation + (CIRCLE_ANGLE / variants) * i;
    goodAngles.push(angle >= CIRCLE_ANGLE ? angle - CIRCLE_ANGLE : angle);
  }

  // Совпадение угла похожей фигуры с текущей
  const isGoodAngleRef = refOutline && s.rotation() === refOutline.rotation;
  // Проверка на валидный угол текущей фигуры
  const isGoodAngle = goodAngles.includes(s.rotation());

  if (isGoodAngle || isGoodAngleRef) {
    const getNewCoord = (value, index, inc) => {
      if (variants > 1 && o.rotation !== s.rotation()) {
        const offset = tableAngles[variants][o.rotation][s.rotation()][screenType][index];
        return inc ? value + offset : value - offset;
      }
      return value;
    }

    // Координаты для текущего контура и фигуры
    let { x, y } = o;
    let currentId = id;
    const shapeX = getNewCoord(s.x(), 0);
    const shapeY = getNewCoord(s.y(), 1);

    if (isGoodAngleRef) {
      const { x: refX, y : refY } = refOutline;
      const isDistRef = (Math.abs(shapeX - refX) < Math.abs(shapeX - x) || refX === x) &&
                        (Math.abs(shapeY - refY) < Math.abs(shapeY - y) || refY === y);

      if ((isGoodAngle && isDistRef) || !isGoodAngle) {
        // При совпадении угла похожей фигуры и контура меняем координаты текущей фигуры на координаты похожей
        ({ x, y } = refOutline);
        currentId = reference;
      }
    }

    const newX = getNewCoord(x, 0, true);
    const newY = getNewCoord(y, 1, true);
    // Находится ли наша фигура на нужном расстоянии от контура
    const isNear = Math.abs(shapeX - x) <= d && Math.abs(shapeY - y) <= d;

    return isNear ? { x: newX, y: newY, id: currentId } : null;
  }

  return null;
};
