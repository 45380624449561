export default {
  shapes: [4, 7, 6, 1, 5, 3, 2],
  offset: {
    mobile: 20,
    tablet: 10
  },
  outlines: [
    {
      id: 4,
      rotation: 315,
      desktop: {
        x: 306,
        y: 342.82
      },
      tablet: {
        x: 300,
        y: 303.28
      },
      mobile: {
        x: 208,
        y: 222.21
      }
    },
    {
      id: 7,
      rotation: 225,
      desktop: {
        x: 293.64,
        y: 267.5
      },
      tablet: {
        x: 297.57,
        y: 246.71
      },
      mobile: {
        x: 206.7,
        y: 180
      }
    },
    {
      id: 6,
      rotation: 45,
      desktop: {
        x: 294.28,
        y: 140
      },
      tablet: {
        x: 298.14,
        y: 133
      },
      mobile: {
        x: 207,
        y: 95
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 311,
        y: 51
      },
      tablet: {
        x: 307,
        y: 54
      },
      mobile: {
        x: 216,
        y: 36
      }
    },
    {
      id: 5,
      rotation: 135,
      desktop: {
        x: 420.28,
        y: 266.64
      },
      tablet: {
        x: 410.14,
        y: 245.57
      },
      mobile: {
        x: 291.85,
        y: 179.43
      }
    },
    {
      id: 3,
      rotation: 270,
      desktop: {
        x: 142,
        y: 375
      },
      tablet: {
        x: 163,
        y: 341
      },
      mobile: {
        x: 106,
        y: 251
      }
    },
    {
      id: 2,
      rotation: 225,
      desktop: {
        x: 199.82,
        y: 362.28
      },
      tablet: {
        x: 213.28,
        y: 330.14
      },
      mobile: {
        x: 143.21,
        y: 242.85
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 401,
        y: 96
      },
      tablet: {
        x: 347,
        y: 54
      },
      mobile: {
        x: 276,
        y: 66
      }
    },
    {
      desktop: {
        x: 356,
        y: 51
      },
      tablet: {
        x: 307,
        y: 94
      },
      mobile: {
        x: 246,
        y: 36
      }
    },
    {
      desktop: {
        x: 311,
        y: 96
      },
      tablet: {
        x: 346.57,
        y: 133.57
      },
      mobile: {
        x: 216,
        y: 66
      }
    },
    {
      desktop: {
        x: 355.19,
        y: 140.22
      },
      tablet: {
        x: 297.58,
        y: 133.57
      },
      mobile: {
        x: 245.15,
        y: 95.15
      }
    },
    {
      desktop: {
        x: 294.06,
        y: 140.22
      },
      tablet: {
        x: 185,
        y: 246.14
      },
      mobile: {
        x: 206.85,
        y: 95.15
      }
    },
    {
      desktop: {
        x: 167,
        y: 267.28
      },
      tablet: {
        x: 240.72,
        y: 246.14
      },
      mobile: {
        x: 122.15,
        y: 179.85
      }
    },
    {
      desktop: {
        x: 231.18,
        y: 267.28
      },
      tablet: {
        x: 185.85,
        y: 301
      },
      mobile: {
        x: 164.79,
        y: 179.85
      }
    },
    {
      desktop: {
        x: 168.46,
        y: 330
      },
      tablet: {
        x: 163,
        y: 301
      },
      mobile: {
        x: 123.64,
        y: 221
      }
    },
    {
      desktop: {
        x: 142,
        y: 330
      },
      tablet: {
        x: 203,
        y: 341
      },
      mobile: {
        x: 106,
        y: 221
      }
    },
    {
      desktop: {
        x: 187.25,
        y: 375
      },
      tablet: {
        x: 297.4,
        y: 246
      },
      mobile: {
        x: 136,
        y: 251
      }
    },
    {
      desktop: {
        x: 294.23,
        y: 267.87
      },
      tablet: {
        x: 328.28,
        y: 276.85
      },
      mobile: {
        x: 207.32,
        y: 179.75
      }
    },
    {
      desktop: {
        x: 337.82,
        y: 311.46
      },
      tablet: {
        x: 328.28,
        y: 331.57
      },
      mobile: {
        x: 229.21,
        y: 201.64
      }
    },
    {
      desktop: {
        x: 338,
        y: 374.82
      },
      tablet: {
        x: 384.85,
        y: 331.57
      },
      mobile: {
        x: 229.21,
        y: 243.43
      }
    },
    {
      desktop: {
        x: 401.46,
        y: 374.64
      },
      tablet: {
        x: 353.57,
        y: 300.28
      },
      mobile: {
        x: 271.64,
        y: 243.43
      }
    },
    {
      desktop: {
        x: 356.64,
        y: 329.82
      },
      tablet: {
        x: 353.57,
        y: 190.71
      },
      mobile: {
        x: 249.43,
        y: 221.21
      }
    },
    {
      desktop: {
        x: 356.64,
        y: 204.5
      },
      tablet: {
        x: 410.71,
        y: 133.57
      },
      mobile: {
        x: 249.43,
        y: 137.27
      }
    },
    {
      desktop: {
        x: 420.92,
        y: 140.22
      },
      tablet: {
        x: 347.43,
        y: 133.57
      },
      mobile: {
        x: 291.55,
        y: 95.15
      }
    },
    {
      desktop: {
        x: 356.72,
        y: 140.22
      },
      tablet: {
        x: 387,
        y: 94
      },
      mobile: {
        x: 246.85,
        y: 95.15
      }
    }
  ]
};
