export default {
  image: require('~/static/trainers/funnyPoints/tomato.png'),
  width: 800,
  height: 919,
  offsetX: 30,
  points: [
    { id: 1,  x: -101, y: 313 },
    { id: 2,  x: -189, y: 346 },
    { id: 3,  x: -258, y: 443 },
    { id: 4,  x: -246, y: 580 },
    { id: 5,  x: -128, y: 684 },
    { id: 6,  x: 174,  y: 683 },
    { id: 7,  x: 286,  y: 578 },
    { id: 8,  x: 310,  y: 465 },
    { id: 9,  x: 273,  y: 356 },
    { id: 10, x: 159,  y: 304 }
  ]
}
