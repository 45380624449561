import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GiveUpPopup from '../GiveUpPopup/GiveUpPopup';
import Tooltip from '../Tooltip/Tooltip';
import GiveUp from '../GiveUp/GiveUp';

import popupNames from '~/services/popup-names';

import { getPopupName } from '~/store/page/selector';

import * as pageActions from '~/store/page/actions';

/* eslint-disable */
import question from './assets/question.svg';
/* eslint-enable */

import './trainer-footer.scss';

const TrainerFooter = (props) => {
  const { tooltipContent, isMiniTooltip, children, openVideo, handleHelpClick, setPopup,
          giveUpHandler, isLongMemoryViewing, isInMission, popupName } = props;
  const backHandler = () => setPopup(null);
  const setPopupHandler = () => setPopup(popupNames.giveUp);

  return (
    <div className="trainer-footer">
      <div className="trainer-footer__holder">
        <div className="trainer-footer__segment">
          { !isLongMemoryViewing && <GiveUp onClick={ setPopupHandler }/> }
          <GiveUpPopup isInMission={ isInMission } giveUpHandler={ giveUpHandler }
                       isOpen={ popupName === popupNames.giveUp } backHandler={ backHandler }/>
        </div>

        <div className="trainer-footer__button">
          { children }
        </div>

        <div className="trainer-footer__segment trainer-footer__help">
          <Tooltip content={ tooltipContent } isMiniTooltip={ isMiniTooltip }
                   openVideo={ openVideo } handleHelpClick={ handleHelpClick } />
        </div>
      </div>
    </div>
  );
}

TrainerFooter.propTypes = {
  tooltipContent: PropTypes.string,
  giveUpHandler: PropTypes.func,
  isLongMemoryViewing: PropTypes.bool,
  isMiniTooltip: PropTypes.bool,
  isInMission: PropTypes.bool,
  children: PropTypes.node,
  openVideo: PropTypes.func
};

function mapStateToProps(state) {
  return {
    popupName: getPopupName(state)
  };
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainerFooter);
