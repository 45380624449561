export default [
  {
    name: 'Новичок',
    iconName: 'newAgent',
    circleToFull: 0
  },
  {
    name: 'Спецагент',
    iconName: 'specialAgent',
    circleToFull: 1
  },
  {
    name: 'Суперагент',
    iconName: 'superAgent',
    circleToFull: 2
  },
  {
    name: 'Старший агент',
    iconName: 'seniorAgent',
    circleToFull: 3
  },
  {
    name: 'Наставник',
    iconName: 'proAgent',
    circleToFull: 4
  }
]
