export default {
  shapes: [7, 6, 4, 5, 3, 1, 8],
  offset: {
    mobile: 10
  },
  outlines: [
    {
      id: 7,
      rotation: 45,
      desktop: {
        x: 296.28,
        y: 205
      },
      tablet: {
        x: 293.14,
        y: 192
      },
      mobile: {
        x: 206.85,
        y: 138
      }
    },
    {
      id: 6,
      rotation: 135,
      desktop: {
        x: 422.92,
        y: 332.28
      },
      tablet: {
        x: 405.71,
        y: 305.14
      },
      mobile: {
        x: 291.28,
        y: 222.85
      }
    },
    {
      id: 4,
      rotation: 180,
      desktop: {
        x: 263,
        y: 156
      },
      tablet: {
        x: 264,
        y: 149
      },
      mobile: {
        x: 187,
        y: 106
      }
    },
    {
      id: 5,
      rotation: 225,
      desktop: {
        x: 294.64,
        y: 269.28
      },
      tablet: {
        x: 291.57,
        y: 249.14
      },
      mobile: {
        x: 207.43,
        y: 181.85
      }
    },
    {
      id: 3,
      rotation: 0,
      desktop: {
        x: 326,
        y: 66
      },
      tablet: {
        x: 319,
        y: 69
      },
      mobile: {
        x: 228,
        y: 46
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 231,
        y: 110.64
      },
      tablet: {
        x: 235,
        y: 108.57
      },
      mobile: {
        x: 165,
        y: 76.43
      }
    },
    {
      id: 8,
      rotation: 270,
      desktop: {
        x: 218,
        y: 376
      },
      tablet: {
        x: 221,
        y: 345
      },
      mobile: {
        x: 153,
        y: 252
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 218,
        y: 66
      },
      tablet: {
        x: 263.28,
        y: 108.28
      },
      mobile: {
        x: 157,
        y: 46
      }
    },
    {
      desktop: {
        x: 218,
        y: 156
      },
      tablet: {
        x: 224,
        y: 69
      },
      mobile: {
        x: 186.20999999999998,
        y: 75.21000000000001
      }
    },
    {
      desktop: {
        x: 262.82,
        y: 110.93
      },
      tablet: {
        x: 224,
        y: 149
      },
      mobile: {
        x: 186.20999999999998,
        y: 55.21
      }
    },
    {
      desktop: {
        x: 262.82,
        y: 142.45999999999998
      },
      tablet: {
        x: 263.28,
        y: 109.72
      },
      mobile: {
        x: 228.64,
        y: 55.21
      }
    },
    {
      desktop: {
        x: 294.18,
        y: 142.45999999999998
      },
      tablet: {
        x: 263.28,
        y: 136.85
      },
      mobile: {
        x: 228.64,
        y: 75.36
      }
    },
    {
      desktop: {
        x: 231,
        y: 205.64
      },
      tablet: {
        x: 290.72,
        y: 136.85
      },
      mobile: {
        x: 258,
        y: 46
      }
    },
    {
      desktop: {
        x: 295.64,
        y: 205.64
      },
      tablet: {
        x: 235,
        y: 192.57
      },
      mobile: {
        x: 258,
        y: 106
      }
    },
    {
      desktop: {
        x: 169,
        y: 332.28
      },
      tablet: {
        x: 292.57,
        y: 192.57
      },
      mobile: {
        x: 228.64,
        y: 76.64
      }
    },
    {
      desktop: {
        x: 261.72,
        y: 332.28
      },
      tablet: {
        x: 180,
        y: 305.14
      },
      mobile: {
        x: 228.64,
        y: 97.64
      }
    },
    {
      desktop: {
        x: 218,
        y: 376
      },
      tablet: {
        x: 260.86,
        y: 305.14
      },
      mobile: {
        x: 208.07,
        y: 97.64
      }
    },
    {
      desktop: {
        x: 308,
        y: 376
      },
      tablet: {
        x: 221,
        y: 345
      },
      mobile: {
        x: 249.85,
        y: 139.43
      }
    },
    {
      desktop: {
        x: 351.72,
        y: 332.28
      },
      tablet: {
        x: 301,
        y: 345
      },
      mobile: {
        x: 207.85,
        y: 139.43
      }
    },
    {
      desktop: {
        x: 422.91999999999996,
        y: 332.28
      },
      tablet: {
        x: 340.86,
        y: 305.14
      },
      mobile: {
        x: 291.28,
        y: 222.85
      }
    },
    {
      desktop: {
        x: 296.28,
        y: 205.64
      },
      tablet: {
        x: 405.71000000000004,
        y: 305.14
      },
      mobile: {
        x: 242.15,
        y: 222.85
      }
    },
    {
      desktop: {
        x: 358.28,
        y: 205.64
      },
      tablet: {
        x: 293.14,
        y: 192.57
      },
      mobile: {
        x: 213,
        y: 252
      }
    },
    {
      desktop: {
        x: 295.1,
        y: 142.45999999999998
      },
      tablet: {
        x: 348.14,
        y: 192.57
      },
      mobile: {
        x: 153,
        y: 252
      }
    },
    {
      desktop: {
        x: 326.46000000000004,
        y: 142.45999999999998
      },
      tablet: {
        x: 292.42,
        y: 136.85
      },
      mobile: {
        x: 182.15,
        y: 222.85
      }
    },
    {
      desktop: {
        x: 326.46000000000004,
        y: 111.71000000000001
      },
      tablet: {
        x: 319.85,
        y: 136.85
      },
      mobile: {
        x: 122,
        y: 222.85
      }
    },
    {
      desktop: {
        x: 371,
        y: 156
      },
      tablet: {
        x: 319.85,
        y: 109.85
      },
      mobile: {
        x: 205.43,
        y: 139.43
      }
    },
    {
      desktop: {
        x: 371,
        y: 66
      },
      tablet: {
        x: 359,
        y: 149
      },
      mobile: {
        x: 165,
        y: 139.43
      }
    },
    {
      desktop: {
        x: 326.46000000000004,
        y: 110.78999999999999
      },
      tablet: {
        x: 359,
        y: 69
      },
      mobile: {
        x: 206.79000000000002,
        y: 97.64
      }
    },
    {
      desktop: {
        x: 326.46000000000004,
        y: 78.82
      },
      tablet: {
        x: 319.85,
        y: 108.15
      },
      mobile: {
        x: 186.20999999999998,
        y: 97.64
      }
    },
    {
      desktop: {
        x: 262.82,
        y: 78.82
      },
      tablet: {
        x: 319.85,
        y: 80.28
      },
      mobile: {
        x: 186.20999999999998,
        y: 76.78999999999999
      }
    },
    {
      desktop: {
        x: 262.82,
        y: 110.57
      },
      tablet: {
        x: 263.28,
        y: 80.28
      },
      mobile: {
        x: 157,
        y: 106
      }
    }
  ]
};
