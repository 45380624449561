import PropTypes from 'prop-types';

export default {
  gift: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string,
  }),
  pointsGot: PropTypes.shape({
    skills: PropTypes.shape({
      focus: PropTypes.number,
      memory: PropTypes.number,
      imagination: PropTypes.number,
    }),
    game: PropTypes.number,
  }).isRequired,
  pointsTotal: PropTypes.shape({
    focus: PropTypes.number,
    memory: PropTypes.number,
    imagination: PropTypes.number,
    game: PropTypes.number,
  }).isRequired,
  pointsOf: PropTypes.shape({
    focus: PropTypes.number,
    memory: PropTypes.number,
    imagination: PropTypes.number,
    game: PropTypes.number,
  }).isRequired,
  currentLevel: PropTypes.number.isRequired,
};
