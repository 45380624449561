export default {
  image: require('~/static/trainers/funnyPoints/mouse.png'),
  width: 800,
  height: 919,
  offsetX: 10,
  offsetY: 15,
  points: [
    { id: 1,  x: -104, y: 468 },
    { id: 2,  x: -111, y: 433 },
    { id: 3,  x: -175, y: 420 },
    { id: 4,  x: -229, y: 381 },
    { id: 5,  x: -261, y: 320 },
    { id: 6,  x: -277, y: 240 },
    { id: 7,  x: -266, y: 178 },
    { id: 8,  x: -228, y: 125 },
    { id: 9,  x: -174, y: 94  },
    { id: 10, x: -110, y: 89  },
    { id: 11, x: -56,  y: 110 },
    { id: 12, x: -17,  y: 165 },
    { id: 13, x: -4,   y: 220 },
    { id: 14, x: -6,   y: 281 },
    { id: 15, x: 30,   y: 284 },
    { id: 16, x: 34,   y: 218 },
    { id: 17, x: 69,   y: 150 },
    { id: 18, x: 134,  y: 109 },
    { id: 19, x: 213,  y: 110 },
    { id: 20, x: 279,  y: 148 },
    { id: 21, x: 309,  y: 208 },
    { id: 22, x: 319,  y: 278 },
    { id: 23, x: 311,  y: 342 },
    { id: 24, x: 279,  y: 392 },
    { id: 25, x: 227,  y: 425 },
    { id: 26, x: 165,  y: 434 },
    { id: 27, x: 109,  y: 416 },
    { id: 28, x: 90,   y: 447 },
    { id: 29, x: 58,   y: 477 },
    { id: 30, x: 79,   y: 548 },
    { id: 31, x: 94,   y: 612 },
    { id: 32, x: 87,   y: 673 },
    { id: 33, x: 44,   y: 715 },
    { id: 34, x: -11,  y: 717 },
    { id: 35, x: -51,  y: 693 },
    { id: 36, x: -73,  y: 645 },
    { id: 37, x: -67,  y: 589 },
    { id: 38, x: -44,  y: 545 },
    { id: 39, x: -20,  y: 501 },
    { id: 40, x: -67,  y: 499 }
  ]
};
