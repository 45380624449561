import letters3 from './baseWords/letters3';
import letters4 from './baseWords/letters4';
import letters5 from './baseWords/letters5';
import letters6 from './baseWords/letters6';
import letters7 from './baseWords/letters7';
import letters8 from './baseWords/letters8';

export default {
  1: letters3,
  2: letters3,
  3: letters4,
  4: letters4,
  5: letters5,
  6: letters5,
  7: letters6,
  8: letters6,
  9: letters7,
  10: letters8
};
