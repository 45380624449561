import React from 'react';
import { Redirect } from 'react-router-dom';

import PageNotFound from '~/containers/PageNotFound/PageNotFound';
import MissionsEndPage from '~/pages/MissionsEndPage/MissionsEndPage';
import MainPage from '~/pages/MainPage/MainPage';
import TrainersPage from '~/pages/TrainersPage/TrainersPage';
import LocationsPage from '~/pages/LocationsPage/LocationsPage';
import LocationPage from '~/pages/LocationPage/LocationPage';
import MissionPage  from '~/pages/MissionPage/MissionPage';
import ProfilePage  from '~/pages/ProfilePage/ProfilePage';
import PolicyPage from '~/pages/PolicyPage/PolicyPage';
import GenerateTokensPage from '~/pages/GenerateTokensPage/GenerateTokensPage'

import HasRoute from '~/containers/HasRoute/HasRoute';

export default [
  {
    exact: true,
    path: '/',
    component: MainPage
  },
  {
    exact: true,
    path: '/trainers/',
    component: TrainersPage
  },
  {
    exact: true,
    path: '/trainers/:trainerSlug/',
    component: HasRoute(MissionPage)
  },
  {
    exact: true,
    path: '/locations/',
    render: () => <LocationsPage theme="white" />
  },
  {
    exact: true,
    path: '/locations/:locationSlug/',
    component: HasRoute(LocationPage)
  },
  {
    path: '/locations/:locationSlug/:missionSlug/',
    component: HasRoute(MissionPage)
  },
  {
    path: '/profile/',
    component: ProfilePage
  },
  {
    path: '/policy/',
    component: PolicyPage
  },
  {
    path: '/missions-end/',
    component: MissionsEndPage
  },
  {
    path: '/admin/generateTokens/',
    component: GenerateTokensPage
  },
  {
    path: '/s/:token',
    render: () => <Redirect to="/" />
  },
  {
    component: PageNotFound
  }
];
