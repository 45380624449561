export default {
  shapes: [ 5, 3, 4, 1, 8, 7, 6 ],
  offset: {
    mobile: 17
  },
  outline: [
    {
      desktop: { x: 163, y: 99 },
      tablet: { x: 176, y: 93 },
      mobile: { x: 119, y: 64 },
    },
    {
      desktop: { x: 163, y: 189 },
      tablet: { x: 176, y: 173},
      mobile: { x: 119, y: 124 },
    },
    {
      desktop: { x: 253, y: 189 },
      tablet: { x: 256, y: 173 },
      mobile: { x: 179, y: 124 },
    },
    {
      desktop: { x: 208, y: 234 },
      tablet: { x: 216, y: 213 },
      mobile: { x: 150, y: 154 },
    },
    {
      desktop: { x: 118, y: 324 },
      tablet: { x: 136, y: 293 },
      mobile: { x: 90, y: 214 },
    },
    {
      desktop: { x: 208, y: 414 },
      tablet: { x: 216, y: 373 },
      mobile: { x: 150, y: 274 },
    },
    {
      desktop: { x: 208, y: 234 },
      tablet: { x: 216, y: 213 },
      mobile: { x: 150, y: 154 },
    },
    {
      desktop: { x: 298, y: 234 },
      tablet: { x: 296, y: 213 },
      mobile: { x: 210, y: 154 },
    },
    {
      desktop: { x: 298, y: 360.3 },
      tablet: { x: 296, y: 326.2 },
      mobile: { x: 210, y: 238.8 },
    },
    {
      desktop: { x: 425.3, y: 360.3 },
      tablet: { x: 409.2, y: 326.2 },
      mobile: { x: 294.8, y: 238.8 },
    },
    {
      desktop: { x: 298.5, y: 233.5 },
      tablet: { x: 296, y: 213 },
      mobile: { x: 210, y: 154 },
    },
    {
      desktop: { x: 342.2, y: 189.8 },
      tablet: { x: 335.7, y: 173.3 },
      mobile: { x: 239.8, y: 124.2 },
    },
    {
      desktop: { x: 405.5, y: 189.8 },
      tablet: { x: 391.8, y: 173.3 },
      mobile: { x: 281.6, y: 124.2 },
    },
    {
      desktop: { x: 405.5, y: 126.2 },
      tablet: { x: 391.8, y: 116.7 },
      mobile: { x: 281.6, y: 81.7 },
    },
    {
      desktop: { x: 468.5, y: 63.2 },
      tablet: { x: 447.8, y: 60.7 },
      mobile: { x: 323.6, y: 39.8 },
    },
    {
      desktop: { x: 404.8, y: 63.2 },
      tablet: { x: 391.3, y: 60.7 },
      mobile: { x: 281.2, y: 39.8 },
    },
    {
      desktop: { x: 404.8, y: 126.2 },
      tablet: { x: 391.3, y: 116.6 },
      mobile: { x: 281.2, y: 81.7 },
    },
    {
      desktop: { x: 342.2, y: 126.2 },
      tablet: { x: 335.7, y: 116.6 },
      mobile: { x: 239.8, y: 81.7 },
    },
    {
      desktop: { x: 342, y: 63 },
      tablet: { x: 335.7, y: 60.7 },
      mobile: { x: 239.8, y: 39.8 },
    },
    {
      desktop: { x: 278.6, y: 63.2 },
      tablet: { x: 279.2, y: 60.7 },
      mobile: { x: 197.4, y: 39.8 },
    },
    {
      desktop: { x: 341.8, y: 126.4 },
      tablet: { x: 335.3, y: 116.8 },
      mobile: { x: 239.2, y: 81.6 },
    },
    {
      desktop: { x: 341.8, y: 189 },
      tablet: { x: 335.3, y: 173 },
      mobile: { x: 239.2, y: 124 },
    },
    {
      desktop: { x: 253, y: 189 },
      tablet: { x: 256, y: 173 },
      mobile: { x: 179, y: 124 },
    },
  ],
  outlines: [
    {
      desktop: { x: 253, y: 99 },
      tablet: { x: 256, y: 93 },
      mobile: { x: 179, y: 64 },
      rotation: 90
    },
    {
      desktop: { x: 374, y: 95 },
      tablet: { x: 364, y: 89 },
      mobile: { x: 261, y: 61 },
      rotation: 135
    },
    {
      desktop: { x: 437, y: 31 },
      tablet: { x: 420, y: 32 },
      mobile: { x: 303, y: 18 },
      rotation: 45
    },
    {
      desktop: { x: 310, y: 158 },
      tablet: { x: 307, y: 145 },
      mobile: { x: 218, y: 103 },
      rotation: 315
    },
    {
      desktop: { x: 208, y: 234 },
      tablet: { x: 216, y: 213 },
      mobile: { x: 150, y: 154 },
      rotation: 270
    },
    {
      desktop: { x: 425, y: 360 },
      tablet: { x: 409, y: 326 },
      mobile: { x: 295, y: 239 },
      rotation: 135
    },
    {
      desktop: { x: 208, y: 414 },
      tablet: { x: 216, y: 373 },
      mobile: { x: 150, y: 274 },
      rotation: 180
    }
  ]
};
