export default {
  shapes: [ 3, 1, 4, 5, 2, 6, 7 ],
  offset: {
    tablet: 20,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 189, y: 77.5 },
      tablet: { x: 196.3, y: 71.7 },
      mobile: { x: 134.2, y: 54.8 },
    },
    {
      desktop: { x: 189, y: 141 },
      tablet: { x: 196.3, y: 128.3 },
      mobile: { x: 134.2, y: 97.2 },
    },
    {
      desktop: { x: 229, y: 141 },
      tablet: { x: 237.2, y: 128.3 },
      mobile: { x: 164.3, y: 97.2 },
    },
    {
      desktop: { x: 263, y: 175 },
      tablet: { x: 260.8, y: 152 },
      mobile: { x: 188.2, y: 121.1 },
    },
    {
      desktop: { x: 263, y: 238 },
      tablet: { x: 264.2, y: 211.8 },
      mobile: { x: 188.2, y: 163.2 },
    },
    {
      desktop: { x: 291, y: 266 },
      tablet: { x: 293.7, y: 241.3 },
      mobile: { x: 205.5, y: 180.5 },
    },
    {
      desktop: { x: 165, y: 392.2 },
      tablet: { x: 180.8, y: 354.2 },
      mobile: { x: 121.2, y: 264.8 },
    },
    {
      desktop: { x: 418, y: 392.2 },
      tablet: { x: 407.2, y: 354.2 },
      mobile: { x: 290.8, y: 264.8 },
    },
    {
      desktop: { x: 322.5, y: 300.5 },
      tablet: { x: 320.8, y: 267.7 },
      mobile: { x: 230.7, y: 204.7 },
    },
    {
      desktop: { x: 326.2, y: 237.5 },
      tablet: { x: 320.8, y: 211.5 },
      mobile: { x: 230.7, y: 163.3 },
    },
    {
      desktop: { x: 291.5, y: 202.7 },
      tablet: { x: 293.4, y: 183.7 },
      mobile: { x: 206.1, y: 138.7 },
    },
    {
      desktop: { x: 353.5, y: 140.7 },
      tablet: { x: 348.8, y: 128.3 },
      mobile: { x: 247.6, y: 97.2 },
    },
    {
      desktop: { x: 395, y: 140.7 },
      tablet: { x: 390.7, y: 128.3 },
      mobile: { x: 278.8, y: 97.2 },
    },
    {
      desktop: { x: 395, y: 77.5 },
      tablet: { x: 390.7, y: 71.7 },
      mobile: { x: 278.8, y: 54.8 },
    },
    {
      desktop: { x: 333, y: 139.5 },
      tablet: { x: 334.9, y: 127.6 },
      mobile: { x: 237.2, y: 96.4 },
    },
    {
      desktop: { x: 293, y: 139.5 },
      tablet: { x: 293.4, y: 127.6 },
      mobile: { x: 206.5, y: 96.4 },
    },
    {
      desktop: { x: 337, y: 96},
      tablet: { x: 333, y: 88 },
      mobile: { x: 236, y: 67 },
    },
    {
      desktop: { x: 292, y: 51 },
      tablet: { x: 293, y: 48 },
      mobile: { x: 206, y: 37 },
    },
    {
      desktop: { x: 247, y: 96 },
      tablet: { x: 253, y: 88 },
      mobile: { x: 176, y: 67 },
    },
    {
      desktop: { x: 290.5, y: 139.5 },
      tablet: { x: 292.6, y: 127.6 },
      mobile: { x: 205.5, y: 96.4 },
    },
    {
      desktop: { x: 251, y: 139.5 },
      tablet: { x: 252, y: 127.5 },
      mobile: { x: 176, y: 96.5 },
    },
  ],
  outlines: [
    {
      desktop: { x: 157, y: 109 },
      tablet: { x: 168, y: 99 },
      mobile: { x: 113, y: 76 },
      rotation: 315
    },
    {
      desktop: { x: 247, y: 51 },
      tablet: { x: 253, y: 47 },
      mobile: { x: 176, y: 37 },
      rotation: 0
    },
    {
      desktop: { x: 364, y: 173 },
      tablet: { x: 362, y: 157 },
      mobile: { x: 258, y: 118 },
      rotation: 225
    },
    {
      desktop: { x: 291, y: 76 },
      tablet: { x: 293, y: 71 },
      mobile: { x: 206, y: 54 },
      rotation: 45
    },
    {
      desktop: { x: 231, y: 206 },
      tablet: { x: 235, y: 183 },
      mobile: { x: 167, y: 142 },
      rotation: 315
    },
    {
      desktop: { x: 292, y: 265 },
      tablet: { x: 294, y: 242 },
      mobile: { x: 206, y: 180 },
      rotation: 45
    },
    {
      desktop: { x: 419, y: 392 },
      tablet: { x: 407, y: 354 },
      mobile: { x: 291, y: 265 },
      rotation: 135
    }
  ]
};
