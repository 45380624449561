import React from 'react';

import Icon from '~/components/Icon/Icon';

import { ZOOM_LEVELS } from '../constants';

/* eslint-disable */
import minus from './assets/minus.svg';
import plus from './assets/plus.svg';
/* eslint-enable */

import './actions.scss';

const Actions = (props) => {
  return (
    <div className="actions">
      { renderPlus(props) }
      { renderMinus(props) }
    </div>
  )
}

const renderPlus = (props) => {
  const { scale, zoomPlus, isActive } = props;
  return (
    <button
      className="actions__button"
      disabled={ scale === ZOOM_LEVELS[ZOOM_LEVELS.length - 1] || !isActive }
      onClick={ zoomPlus }
    >
      <Icon name="plus" className="actions__plus" />
    </button>
  );
}

const renderMinus = (props) => {
  const { scale, zoomMinus, isActive } = props;
  return (
    <button
      className="actions__button"
      disabled={ scale === ZOOM_LEVELS[0] || !isActive }
      onClick={ zoomMinus }
    >
      <Icon name="minus" className="actions__minus" />
    </button>
  );
}

export default Actions;
