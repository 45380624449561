import React, { useState } from 'react'
import SkillProgress from '~/components/SkillProgress/SkillProgress'
import classNames from 'classnames'

import './skillsProgress.scss'

/* eslint-disable */
import focus from '~/static/icon/skills/focus.svg'
import imagination from '~/static/icon/skills/imagination.svg'
import logic from '~/static/icon/skills/logic.svg'
import memory from '~/static/icon/skills/memory.svg'
import mind from '~/static/icon/skills/mind.svg'
/* eslint-enable */

const SkillsProgress = ({ skills }) => {
  const [ currentSkillType, setCurrentSkillType ] = useState('imagination')

  const handleHover = (e) => {
    const { skill } = e.currentTarget.dataset;
    setCurrentSkillType(skill);
  }

  const renderSkills = () => {
    return Object.keys(skills).map((item, index) => {
      let skill = skills[item];
      let itemClass = classNames('skills-progress__item', {
        [`skills-progress__item_selected`]:  currentSkillType === item
      })

      return (
        <li onMouseOver={ handleHover } data-skill={ item } className={ itemClass } key={ index }>
          <SkillProgress percent={ skill.value/skill.max } skill={ item } />
        </li>
      )
    });
  };

  let currentSkill = skills[currentSkillType];
  const skillInfoClass = classNames('skills-progress__skill-info', {
    [`skills-progress__skill-info_${ currentSkillType }`]: currentSkillType
  })

  return (
    <div className="skills-progress">
      <header className="skills-progress__header">
        <div className="skills-progress__caption">
          Развитие навыков
        </div>

        <div className={ skillInfoClass }>
          <span className="skills-progress__earned">
            { currentSkill.name }: { currentSkill.value }
          </span>
          <span className="skills-progress__all">
            &nbsp;/&nbsp;{ currentSkill.max }
          </span>
        </div>
      </header>

      <ul className="skills-progress__list">
        { renderSkills() }
      </ul>
    </div>
  )
}

export default SkillsProgress
