import embrace from '../figures/embrace';
import embrace2 from '../figures/embrace2';
import walkingMan from '../figures/walkingMan';
import walkingMan2 from '../figures/walkingMan2';
import horse from '../figures/horse';
import sittingMan from '../figures/sittingMan';
import rabbit from '../figures/rabbit';
import beef from '../figures/beef';
import kickingMan from '../figures/kickingMan';
import runningMan from '../figures/runningMan';
import runningMan2 from '../figures/runningMan2';
import runningMan3 from '../figures/runningMan3';
import turtle from '../figures/turtle';
import goose from '../figures/goose';
import dog from '../figures/dog';
import horse2 from '../figures/horse2';

export default [
  embrace,
  walkingMan,
  walkingMan2,
  horse,
  sittingMan,
  rabbit,
  beef,
  kickingMan,
  runningMan3,
  turtle,
  goose,
  dog,
  embrace2,
  runningMan,
  runningMan2,
  horse2
];
