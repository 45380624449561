import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import ReactPlayer from "react-player";

import Button from "~/components/Button/Button";
import Icon from "~/components/Icon/Icon";

import './video-popup.scss';

ReactModal.setAppElement('#root');

const VideoPopup = ({ isPopupShow, videoAnnounceUrl, cyButton, startsImmediately, onSkipButtonClick }) => {
  const modalVideoStyles = {
    content: {
      background: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding: 0,
      borderRadius: 0,
      maxWidth: '1000px',
      overflowY: 'initial',
      overflowX: 'initial',
    },
  };

  const skip = () => {
    onSkipButtonClick(startsImmediately)
  }

  return (
    <ReactModal
      isOpen={ isPopupShow }
      style={ modalVideoStyles }
      className="popupContent"
      overlayClassName="popupOverlay"
    >
      <ReactPlayer
        url={ videoAnnounceUrl }
        width="100%"
        height={ document.documentElement.clientHeight - 80 }
        className="video-popup__player"
      />
      <div className="video-popup__skip">
        <Button
          onClick={ skip }
          theme="skip-video"
          className="video-popup__button"
          cy={ cyButton }
        >
          <Fragment>
            <Icon name="close" className="video-popup__icon button__icon" />
            <span className="button__text video-popup__text">Пропустить видео-инструкцию</span>
          </Fragment>
        </Button>
      </div>
    </ReactModal>
  );
};

VideoPopup.propTypes = {
  videoAnnounceUrl: PropTypes.string.isRequired,
  onSkipButtonClick: PropTypes.func.isRequired,
  isPopupShow: PropTypes.bool.isRequired,
  startsImmediately: PropTypes.bool,
};

VideoPopup.defaultProps = {
  startsImmediately: false,
};

export default VideoPopup;
