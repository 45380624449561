export default [
  'абрикос', 'автобус', 'автомат', 'акробат', 'алфавит', 'анекдот', 'бабочка', 'бабушка', 'барабан', 'бассейн',
  'батарея', 'берлога', 'блокнот', 'бродяга', 'букварь', 'варежка', 'вариант', 'великан', 'верблюд', 'ветеран',
  'ветчина', 'вешалка', 'витамин', 'водолаз', 'водолей', 'водопад', 'воробей', 'воронка', 'восторг', 'вспышка',
  'встреча', 'вторник', 'галстук', 'гармонь', 'гимнаст', 'голубцы', 'дворник', 'девочка', 'девушка', 'дельфин',
  'деревня', 'детство', 'джунгли', 'диктант', 'дневник', 'доброта', 'ежевика', 'жалость', 'женщина', 'жилетка',
  'журавль', 'завтрак', 'задание', 'заколка', 'зоопарк', 'игрушка', 'изумруд', 'интерес', 'кабачок', 'кальмар',
  'капитан', 'капуста', 'кенгуру', 'колбаса', 'колодец', 'коляска', 'комната', 'конверт', 'конкурс', 'конфета',
  'копилка', 'корабль', 'кукушка', 'леденец', 'леопард', 'лимонад', 'линейка', 'ловушка', 'лужайка', 'лягушка',
  'магазин', 'мальчик', 'маршрут', 'медведь', 'мизинец', 'милиция', 'миллион', 'миндаль', 'молоток', 'морковь',
  'мужчина', 'муравей', 'мухомор', 'невеста', 'носорог', 'охотник', 'очередь', 'ошейник', 'палатка', 'паспорт',
  'паутина', 'педиатр', 'пекарня', 'пианино', 'пирожок', 'планета', 'планшет', 'пломбир', 'повидло', 'поганка',
  'подарок', 'поделка', 'подпись', 'подъезд', 'правило', 'природа', 'пустыня', 'пушинка', 'пшеница', 'пылесос',
  'пятница', 'радость', 'ракушка', 'рассвет', 'рассказ', 'резинка', 'реклама', 'рисунок', 'родинка', 'розетка',
  'рубашка', 'русалка', 'самокат', 'сарафан', 'свадьба', 'свисток', 'свобода', 'серебро', 'сиденье', 'скандал',
  'сметана', 'снегирь', 'совесть', 'сосиска', 'ссадина', 'стадион', 'станция', 'старуха', 'столица', 'стоянка',
  'стрелка', 'стрижка', 'суббота', 'счастье', 'сюрприз', 'таблица', 'таракан', 'тарелка', 'тележка', 'телефон',
  'тетрадь', 'тефтеля', 'товарищ', 'трактор', 'трамвай', 'тюльпан', 'учебник', 'учитель', 'фабрика', 'хулиган',
  'царство', 'чебурек', 'человек', 'чемодан', 'четверг', 'шампунь', 'экзамен', 'энергия', 'ярмарка', 'ящерица',
  'веревка', 'гололед', 'детеныш', 'лепешка', 'ребенок', 'самолет', 'теленок'
];
