const DEFAULT_SIZE = 9;

export default ({ seconds }) => {
  const tooltipContent = `Перед тобой большой квадрат со сторонами 9×9 клеток, разбитый на 9 квадратов поменьше — со
                          сторонами 3×3 клетки. <br>
                          В каждом из маленьких квадратов заранее пропечатано несколько цифр от 1 до 9.<br>
                          Твоя задача: заполнить пустые клетки так, чтобы в каждом квадрате 3×3 был полный набор цифр
                          от 1 до 9. И при этом так, чтобы в рядах из 9 клеток цифры не повторялись.`;
  return {
    size: DEFAULT_SIZE,
    timer: [seconds * 1000],
    tooltipContent,
    winnerPoints: {
      focus: 1,
      mind: 2
    }
  };
}
