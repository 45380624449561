import * as types from './constants';
import { skillLevelLabels } from './levels';

import * as trainerActions from '~/store/trainers/actions';
import * as pageActions from '~/store/page/actions';

import { getTrainersArray } from '~/store/trainers/selector';
import { levelMaxValueSelector } from './selector';

import apiClient from '~/services/api-client';
import popupNames from '~/services/popup-names';
import trainersLevelUp from '~/services/trainers-level-up';

export const saveMissionProgress = (data) => {
  return (dispatch, getState) => {
    const { profile } = getState();
    const payload = apiClient.saveMissionProgress({ ...data, profileToken: profile.token });

    return dispatch({
      type: types.SAVE_MISSION_PROGRESS,
      payload
    }).catch((error) => console.log(error));
  }
};

export const saveMissionParams = (params, missionId) => {
  return (dispatch, getState) => {
    const { trainerIds, isSkillLevels } = params;
    const { profile } = getState();
    const { skills, missions } = profile;
    const currentMission = missions[missionId];

    if ((isSkillLevels && currentMission && currentMission.skillLevels) ||
        (trainerIds && currentMission && currentMission.trainerIds)) {
      return;
    }

    let newTrainerIds;

    if (trainerIds) {
      newTrainerIds = currentMission && currentMission.trainerIds ? currentMission.trainerIds : trainerIds;
    } else {
      newTrainerIds = currentMission ? currentMission.trainerIds : null;
    }

    let newSkillLevels;
    const skillLevels = Object.keys(skills).reduce((acc, skill) => ({ ...acc, [skill]: skills[skill].level }), {});

    if (isSkillLevels) {
      newSkillLevels = currentMission && currentMission.skillLevels ? currentMission.skillLevels : skillLevels;
    } else {
      newSkillLevels = currentMission ? currentMission.skillLevels : null;
    }

    const newMissions = { ...missions, [missionId]: { trainerIds: newTrainerIds, skillLevels: newSkillLevels } };
    const payload = apiClient.saveMissionParams({ missions: newMissions, profileToken: profile.token });

    return dispatch({
      type: types.SAVE_MISSION_PARAMS,
      payload
    }).catch((error) => console.log(error));
  }
};

export const upgradeSkill = (skill) => {
  return (dispatch, getState) => {
    const { skills } = getState().profile;
    const currentSkill = skills[skill];
    const { success, level } = currentSkill;
    const isMaxSkillSuccess = success === types.MAX_SKILL_SUCCESS;
    const newSuccessCount = isMaxSkillSuccess ? 0 : success + 1;
    const newLevel = isMaxSkillSuccess ? level + 1 : level;

    if (isMaxSkillSuccess) {
      const trainers = getTrainersArray(getState());
      const upgradeTrainers = trainersLevelUp(trainers, skill);

      dispatch(trainerActions.levelUpTrainers(upgradeTrainers));
    }

    return dispatch({
      type: types.UPGRADE_SKILL,
      payload: {
        skill,
        success: newSuccessCount,
        level: newLevel
      }
    });
  };
};

export const downgrateSkill = (skill) => ({
  type: types.DOWNGRATE_SKILL,
  payload: skill
});

export const saveRunnedTrainer = (payload) => ({
  type: types.SAVE_RUNNED_TRAINER,
  payload
});

export const setPrevSkills = (skills) => ({
  type: types.SAVE_PREV_SKILLS,
  payload: skills
});

export const saveTrainerProgress = (data) => {
  return async (dispatch, getState) => {
    const { profile } = getState();
    const maxPoints = levelMaxValueSelector(getState());
    const { token, points, skills: profileSkills, skillLevel, gameLevel } = profile;
    const { isInMission, skills } = data;
    const payload = apiClient.saveTrainerProgress({ ...data, profileToken: token });

    if (isInMission && skills && maxPoints <= points + 1) {
      await dispatch(pageActions.pushPopup(popupNames.levelUp));
      window.dataLayer.push({
        event: 'up',
        eventCategory: 'user_prop',
        eventAction: 'lvl_up',
        eventValue: gameLevel + 1
      });
    }

    if (skills) {
      const isStatusUp = Object.keys(profileSkills).every((skill) => {
        let currentPoints = 0;
        const currentSkill = skills[skill];
        const currentSkillProfile = profileSkills[skill];
        const { value, max } = currentSkillProfile;

        if(currentSkill) {
          currentPoints = currentSkill;
        }

        return value + currentPoints >= max;
      });

      if (isStatusUp) {
        dispatch(setPrevSkills(profileSkills));
        dispatch(pageActions.pushPopup(popupNames.statusUp));
        window.dataLayer.push({
          event: 'up',
          eventCategory: 'user_prop',
          eventAction: 'get_status',
          eventLabel: skillLevelLabels[skillLevel + 1]
        });
      }
    }

    return dispatch({
      type: types.SAVE_TRAINER_PROGRESS,
      payload
    }).catch((error) => console.log(error));
  }
};

export const fetchProfile = (token) => {
  return (dispatch) => {
    const payload = apiClient.fetchProfile(token);

    return dispatch({
      type: types.FETCH_PROFILE,
      payload
    }).catch((error) => console.log(error));
  }
};
