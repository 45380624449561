import React from 'react';

import Button from '~/components/Button/Button';
import LevelScale from '~/components/LevelScale/LevelScale';

import './missions-end-level.scss';

const MissionsEndLevel = (props) => {
  const { profile, levelMaxValue } = props;
  const { points, gameLevel } = profile;

  return (
    <div className="missions-end-level">
      <div className="missions-end-level__holder">
        <div className="missions-end-level__title">
          Зарабатывай баллы, чтобы повысить свой уровень!
        </div>
        <LevelScale level={ gameLevel } points={ points } maxPoints={ levelMaxValue } />
        <Button className="button_center button_size-m missions-end-level__button" theme="blue" link="/locations/">
          выбрать миссию
        </Button>
      </div>
    </div>
  );
}

export default MissionsEndLevel;
