export default {
  image: require('~/static/trainers/funnyPoints/stump.png'),
  width: 800,
  height: 919,
  offsetY: 10,
  points: [
    { id: 1,  x: -215, y: 414 },
    { id: 2,  x: -190, y: 647 },
    { id: 3,  x: -300, y: 743 },
    { id: 4,  x: -146, y: 702 },
    { id: 5,  x: -145, y: 802 },
    { id: 6,  x: -30,  y: 721 },
    { id: 7,  x: 93,   y: 711 },
    { id: 8,  x: 207,  y: 760 },
    { id: 9,  x: 150,  y: 666 },
    { id: 10, x: 157,  y: 460 }
  ]
}
