import content from '../groups';

const IMAGES_IN_PACK = 5;

const getImageData = (id, index) => {
  return { id: index, src: require(`~/static/trainers/findSpy/${ id }-${ index }.png`) };
};

export default function (groupCount) {
  const groupContent = content.sort(() => Math.random() - 0.5).slice(0, groupCount);
  const newContent = groupContent.map((item) => {
    const newImages = [ ...Array(IMAGES_IN_PACK) ]
      .map((_, index) => getImageData(item.id, index + 1))
      .sort(() => Math.random() - 0.5);
    return  { ...item, images: newImages };
  });

  return newContent;
};
