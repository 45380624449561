import { SET_POPUP, SET_QUEUE } from './constants';

import * as pageActions from '~/store/page/actions';

export function setPopup(popupName) {
  return {
    type: SET_POPUP,
    payload: {
      popupName
    }
  };
}

export function pushPopup(popupName) {
  return (dispatch, getState) => {
    const { page } = getState();
    const { popupQueue } = page;
    const newQueue = [ ...popupQueue ];
    
    newQueue.push(popupName);
    const payload = new Promise((resolve) => resolve(newQueue));

    return dispatch({
      type: SET_QUEUE,
      payload
    }).catch((error) => console.log(error));
  };
}

export function popPopup() {
  return (dispatch, getState) => {
    const { page } = getState();
    const { popupQueue } = page;
    const newQueue = [ ...popupQueue ];
    const popupName = newQueue.pop();
    const payload = new Promise((resolve) => resolve(newQueue));

    dispatch(pageActions.setPopup(popupName || null));

    return dispatch({
      type: SET_QUEUE,
      payload
    }).catch((error) => console.log(error));
  };
}
