import PropTypes from 'prop-types';

export default {
  isActive: PropTypes.bool.isRequired,
  onEnded: PropTypes.func.isRequired,
  trainerParams: PropTypes.shape({
    levels: PropTypes.shape({
      timer: PropTypes.arrayOf(PropTypes.number),
      rememberTimer: PropTypes.arrayOf(PropTypes.number),
    }),
    timer: PropTypes.oneOfType([ PropTypes.number, PropTypes.array ]),
    difficultyLevel: PropTypes.number,
  }).isRequired,
  changeStage: PropTypes.func,
};
