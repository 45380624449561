import figure25 from '../figures/figure25';
import figure26 from '../figures/figure26';
import figure27 from '../figures/figure27';
import figure28 from '../figures/figure28';
import figure29 from '../figures/figure29';
import figure30 from '../figures/figure30';
import figure43 from '../figures/figure43';
import figure46 from '../figures/figure46';
import figure49 from '../figures/figure49';
import dolphin from '../figures/dolphin';
import figure14 from '../figures/figure14';
import figure15 from '../figures/figure15';
import figure16 from '../figures/figure16';
import figure17 from '../figures/figure17';
import figure18 from '../figures/figure18';
import figure19 from '../figures/figure19';

export default [
  figure25,
  figure26,
  figure27,
  figure28,
  figure29,
  figure30,
  figure43,
  figure46,
  figure49,
  dolphin,
  figure14,
  figure15,
  figure16,
  figure17,
  figure18,
  figure19
];
