import React, { useEffect, useState } from 'react';
import propTypes from './prop-types';
import cx from 'classnames';

import { STEP_REMEMBER, STEP_TEST } from '~/utils/constants';
import usePrevious from '~/hooks/usePrevious';

import './TrainerLongMemoryTask.scss';

const TrainerLongMemoryTask = (props) => {
  const { onStartCb, setTrainerStage, isTimerActive, onFinishCb, config } = props;
  const [isActive, setActive] = useState(true);
  const [isFinish, setFinish] = useState(false);
  const prevIsTimerActive = usePrevious(isTimerActive);

  useEffect(() => {
    onStartCb();
    setTrainerStage(STEP_REMEMBER);
  }, []);

  useEffect(() => {
    if (prevIsTimerActive && !isTimerActive && isActive) {
      setActive(false);
      setFinish(true);
      onFinishCb({ hasShownLongMemoryTask: true });
    }
  }, [isTimerActive]);

  useEffect(() => {
    isFinish && setTrainerStage(STEP_TEST);
  }, [isFinish]);

  return (
    <section className="trainer-long-memory-task">
      <div className="trainer-long-memory-task__container">
        <div className="trainer-long-memory-task__task">
          { config.text }
        </div>
        <div className="trainer-long-memory-task__holder-items">
          { renderItems(config) }
        </div>
      </div>
    </section>
  );
};

const renderItems = ({ items }) => {
  const classes = cx('trainer-long-memory-task__item', {
    'trainer-long-memory-task__item_single': items.length === 1
  });

  return (
    items.map((item, index) => (
      <div className={ classes } key={ index }>
        <div className="trainer-long-memory-task__image">
          <img className="trainer-long-memory-task__img" src={ item.image } alt={ item.text } />
        </div>
      </div>
    ))
  );
};

TrainerLongMemoryTask.propTypes = propTypes;

export default TrainerLongMemoryTask;
