export default {
  texts: {
    description: [
      'Хороший спецагент умеет вычислять, сколько метров от одной крыши до другой и можно ли прыгнуть на навес, убегая от преследования. Для этого следует оттачивать навык пространственного воображения.',
      'Наши спецагенты в уме представляют улицы города, чтобы определить самый короткий путь к цели. Поэтому в нужное время они всегда в нужном месте! Обладать такими навыками тебе поможет пространственное воображение. Развивай его — и ты всегда будешь быстрее всех!',
      'Иногда, чтобы распутать какой-то код или решить сложную головоломку, нужно посмотреть на неё под другим углом. Спецагент умеет представлять любой объект в трёхмерной проекции. Попробуй и ты!',
      'Наши агенты очень быстро передвигаются по улицам мегаполиса благодаря навыку пространственного воображения. Ведь только суперагенты могут представить карту города и построить самый короткий маршрут. Научись этому и ты!'
    ],
    losing: [
      'Не все кубики посчитаны! В следующий раз внимательнее посмотри на картинку. Помни: не все кубики видны!',
      'Попробуй ещё раз! Всё обязательно получится!'
    ],
    victory: [
      'И это был правильный ответ! Отличный результат. Тебя ждут новые интересные миссии!',
      'Молодец! Ты спаравился. Ты точно посчитал все кубики!'
    ]
  },
  textsInMission: {
    description: [
      'Враг не дремлет! Тебе необходимо получить сведения из вражеского штаба. Посчитай на вражеском складе количество коробок с боеприпасами. Помни, что есть коробки, которые скрыты от глаз!',
      'Во вражеский штаб привезли коробки с боеприпасами. Тебе необходимо раздобыть информацию об их количестве! Помни, что есть коробки, которые скрыты от глаз!'
    ],
    losing: [
      'Не получилось... Помни, что мы не всегда видим всё. А чтобы больше не было таких промахов, мистер Фоксман ожидает тебя в тренажёрном зале.'
    ],
    victory: [
      'Отличный результат! Теперь мы знаем новую информацию про вражеские объекты!',
      'Молодец! Теперь мы опережаем наших врагов!'
    ]
  }
};
