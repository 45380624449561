import React from 'react';
import cx from 'classnames';

const Letter = (props) => {
  const { letterIndex, letter, wordIndex, answered, onDragStartHandler, onLetterClickHandler,
          onDragEndHandler, show } = props;
  const style = show ? {} : { visibility: 'visible' };

  return (
    <div className="trainer-anagram__letter-container">
      {
        letter && (
          <div
            className={ cx("trainer-anagram__letter-wrapper", {
              'trainer-anagram__letter-wrapper_correct': answered,
              'trainer-anagram__letter-wrapper_incorrect': answered
            }) }
            draggable={ !answered }
            onDragStart={ event => onDragStartHandler(event, wordIndex, letterIndex) }
            onClick={ event => onLetterClickHandler(event, wordIndex, letterIndex) }
            onDragEnd={ onDragEndHandler }
            style={style}
          >
            <span className="trainer-anagram__letter-text">
              { letter }
            </span>
          </div>
        )
      }
      <div className="trainer-anagram__empty-slot" />
    </div>
  );
}

export default Letter;
