import texts from './texts';
import levels from './levels';
import levelsInMission from './levelsInMission';

export default  {
  id: 22,
  type: 'intellectualWorkouts',
  eventCategory: 'Questions',
  videoAnnounceUrl: null,
  isCenteredContent: true,
  tooltipAnnounce: 'Любой спецагент должен обладать большим количеством знаний во многих областях. Проверь свои знания!',
  previewUrl: require('~/static/image/trainerPreviews/intellectualWorkouts.png'),
  params: {
    ...texts,
    name: 'Скоростное мышление агента',
    hasTimer: true,
    startsImmediately: true,
    maxSkills:  {
      mind: 2,
      memory: 1
    },
    isShowStage: true,
    skills: [ 'memory', 'mind' ],
    mainSkill: 'mind',
    levels,
    levelsInMission
  }
};
