import figure53 from '../figures/figure53';
import figure56 from '../figures/figure56';
import figure61 from '../figures/figure61';
import figure64 from '../figures/figure64';
import figure67 from '../figures/figure67';
import figure70 from '../figures/figure70';
import figure73 from '../figures/figure73';
import figure76 from '../figures/figure76';
import figure130 from '../figures/figure130';
import figure133 from '../figures/figure133';
import figure138 from '../figures/figure138';
import figure20 from '../figures/figure20';
import figure21 from '../figures/figure21';
import figure22 from '../figures/figure22';
import figure23 from '../figures/figure23';
import figure24 from '../figures/figure24';

export default [
  figure53,
  figure56,
  figure61,
  figure64,
  figure67,
  figure70,
  figure73,
  figure76,
  figure130,
  figure133,
  figure138,
  figure20,
  figure21,
  figure22,
  figure23,
  figure24
];
