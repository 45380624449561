export default {
  image: require('~/static/trainers/funnyPoints/bear.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  offsetY: 20,
  points: [
    { id: 1,  x: -88,  y: 341 },
    { id: 2,  x: -74,  y: 308 },
    { id: 3,  x: -73,  y: 265 },
    { id: 4,  x: -65,  y: 223 },
    { id: 5,  x: -79,  y: 197 },
    { id: 6,  x: -63,  y: 170 },
    { id: 7,  x: -40,  y: 173 },
    { id: 8,  x: -11,  y: 152 },
    { id: 9,  x: 31,   y: 139 },
    { id: 10, x: 82,   y: 142 },
    { id: 11, x: 122,  y: 166 },
    { id: 12, x: 145,  y: 159 },
    { id: 13, x: 163,  y: 178 },
    { id: 14, x: 155,  y: 207 },
    { id: 15, x: 181,  y: 276 },
    { id: 16, x: 197,  y: 364 },
    { id: 17, x: 219,  y: 384 },
    { id: 18, x: 246,  y: 480 },
    { id: 19, x: 261,  y: 576 },
    { id: 20, x: 241,  y: 674 },
    { id: 21, x: 207,  y: 702 },
    { id: 22, x: 187,  y: 687 },
    { id: 23, x: 183,  y: 616 },
    { id: 24, x: 136,  y: 677 },
    { id: 25, x: 143,  y: 740 },
    { id: 26, x: 133,  y: 803 },
    { id: 27, x: 47,   y: 814 },
    { id: 28, x: 37,   y: 766 },
    { id: 29, x: 22,   y: 724 },
    { id: 30, x: -31,  y: 722 },
    { id: 31, x: -45,  y: 768 },
    { id: 32, x: -48,  y: 819 },
    { id: 33, x: -132, y: 814 },
    { id: 34, x: -147, y: 743 },
    { id: 35, x: -139, y: 662 },
    { id: 36, x: -161, y: 608 },
    { id: 37, x: -170, y: 552 },
    { id: 38, x: -165, y: 499 },
    { id: 39, x: -150, y: 456 },
    { id: 40, x: -128, y: 412 }
  ]
};
