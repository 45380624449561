export default (field, shape) => {
  const { x: x0, y: y0, width: w0, height: h0 } = field;
  const x1 = x0 + w0;
  const y1 = y0 + h0;
  const { x: x2, y: y2, width: w1, height: h1 } = shape.getClientRect();
  const x3 = x2 + w1;
  const y3 = y2 + h1;

  if (x2 >= x0 && x3 <= x1 && y2 >= y0 && y3 <= y1) {
    return true;
  }

  return false;
};
