export default {
  texts: {
    description: [
      'Любой спецагент должен понимать не только буквальный смысл сказанного, но и скрытый. Данные упражнения помогут тебе понимать переносный смысл фразы. Например: «он висит у меня на хвосте».',
      'Наши спецагенты запоминают все инструкции профессора Фоксмана дословно и в любой момент могут вспомнить и воспроизвести, не изменив ни единого слова. Научись этому и ты!'
    ],
    losing: [
      'Ты совсем немного ошибся! Повтори попытку, и всё точно получится!',
      'Спецагентом может стать лишь тот, кто много тренируется! Попробуй ещё раз!'
    ],
    victory: [
      'Отличный результат! Новая миссия для тебя уже готова!',
      'Теперь все фразы на своих местах! В миссии тебе этот навык точно пригодится!'
    ]
  },
  textsInMission: {
    description: [
      'Сейчас тебе предстоит запомнить все фразы, а потом воспроизвести их дословно. Если допустишь ошибку хоть в одном слове, задание будет провалено!',
      'Тебе нужно запомнить очень важные фразы. Будь внимателен. Если допустишь ошибку, задание будет считаться невыполненным!'
    ],
    losing: [
      'Эх... Немного перепутал! Мистер Фоксман приготовил для тебя такое задание в тренажёрном зале. Подготовься, и всё получится!',
      'У тебя обязательно получится, но вначале потренируйся в тренажёрном зале.'
    ],
    victory: [
      'Молодец, ты запомнил все фразы! Настоящий спецагент!',
      'Только настоящий спецагент справляется с этим заданием! Продолжай, у тебя отлично получается!'
    ]
  }
};
