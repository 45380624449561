import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import FetchContainer from '~/containers/FetchContainer/FetchContainer';
import listRoutes from '~/services/get-routes';

const renderRoutes = () => {
  return listRoutes.map((item, index) => <Route { ...item } key={ index } />);
};

const routes = () => (
  <FetchContainer>
    <Switch>
      { renderRoutes() }
    </Switch>
  </FetchContainer>
);

export default hot(module)(routes);
