import PropTypes from 'prop-types';

export default {
  missionStatus: PropTypes.arrayOf(PropTypes.string),
  trainers: PropTypes.objectOf(PropTypes.shape({})),
  setShownLongMemoryTask: PropTypes.func.isRequired,
  goToNextTrainer: PropTypes.func.isRequired,
  currentTrainerIndex: PropTypes.number.isRequired,
  hasShownLongMemoryTask: PropTypes.bool.isRequired,
  longMemory: PropTypes.shape({}),
  isInMission: PropTypes.bool,
  replace: PropTypes.func.isRequired,
  locationSlug: PropTypes.string,
  missionSlug: PropTypes.string
};
