import { FETCH_TRAINERS, CHANGE_TRAINERS_FILTER, GENERATE_CURRENT_MISSION, LEVEL_UP_TRAINERS } from './constants';

import * as entityNormalize from '~/utils/entityNormalize';
import trainersFilter from '~/services/get-trainers-filter';

const initialState = {
  isPending: false,
  isFulfilled: false,
  isRejected: false,
  data: {},
  ids: [],
  hasMore: true,
  trainersFilter,
  count: {},
  currentMission: []
};

function trainers(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${ FETCH_TRAINERS }_PENDING`:
      return { ...state,
        isPending: true
      };

    case `${ FETCH_TRAINERS }_FULFILLED`:
      const { list, count, allCount } = payload;
      const entity = entityNormalize.toObject(list);
      const { data, ids } = entity;

      return {
        ...state,
        isPending: false,
        isFulfilled: true,
        data: { ...state.data, ...data },
        ids: [ ...state.ids, ...ids ],
        hasMore: ids.length > 1,
        allCount,
        count
      };

    case `${ FETCH_TRAINERS }_REJECTED`:
      return { ...state,
        isRejected: true,
        isPending: false,
        error: payload
      };

    case CHANGE_TRAINERS_FILTER:
      return { ...state, trainersFilter: payload };

    case GENERATE_CURRENT_MISSION:
      return { ...state, currentMission: payload };

    case LEVEL_UP_TRAINERS: {
      const entity = entityNormalize.toObject(payload);
      const { data } = entity;

      return {
        ...state,
        data: { ...state.data, ...data }
      };
    }

    default:
      return state;
  }
};

export default trainers;
