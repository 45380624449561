export default {
  shapes: [ 3, 4, 1, 7, 5, 6, 8 ],
  offset: {
    mobile: 15
  },
  outline: [
    {
      desktop: { x: 99, y: 123.2 },
      tablet: { x: 121, y: 116 },
      mobile: { x: 78, y: 81 },
    },
    {
      desktop: { x: 142.8, y: 166.8 },
      tablet: { x: 160.3, y: 155.2 },
      mobile: { x: 107.2, y: 110.2 },
    },
    {
      desktop: { x: 142.8, y: 203.8 },
      tablet: { x: 160.3, y: 184.3 },
      mobile: { x: 107.2, y: 128.2 },
    },
    {
      desktop: { x: 206, y: 203.8 },
      tablet: { x: 216, y: 184.3 },
      mobile: { x: 149, y: 128.2 },
    },
    {
      desktop: { x: 206, y: 352 },
      tablet: { x: 216, y: 315 },
      mobile: { x: 149, y: 231 },
    },
    {
      desktop: { x: 269, y: 289 },
      tablet: { x: 273, y: 258 },
      mobile: { x: 191, y: 189 },
    },
    {
      desktop: { x: 359, y: 379 },
      tablet: { x: 353, y: 338 },
      mobile: { x: 251, y: 249 },
    },
    {
      desktop: { x: 359, y: 199 },
      tablet: { x: 353, y: 180 },
      mobile: { x: 251, y: 130 },
    },
    {
      desktop: { x: 422.6, y: 262.7 },
      tablet: { x: 408.6, y: 235.5 },
      mobile: { x: 293.4, y: 172.4 },
    },
    {
      desktop: { x: 486.3, y: 262.7 },
      tablet: { x: 465, y: 235.5 },
      mobile: { x: 336, y: 172.4 },
    },
    {
      desktop: { x: 422.6, y: 199 },
      tablet: { x: 408.6, y: 179 },
      mobile: { x: 292.9, y: 129.5 },
    },
    {
      desktop: { x: 232.6, y: 198.6 },
      tablet: { x: 239.6, y: 178.6 },
      mobile: { x: 167.4, y: 129.4 },
    },
    {
      desktop: { x: 206.5, y: 172.5 },
      tablet: { x: 216.8, y: 155.8 },
      mobile: { x: 149.6, y: 111.6 },
    },
    {
      desktop: { x: 206.5, y: 171.5 },
      tablet: { x: 216.8, y: 155.2 },
      mobile: { x: 149.6, y: 110.4 },
    },
    {
      desktop: { x: 251, y: 127 },
      tablet: { x: 256, y: 116 },
      mobile: { x: 179, y: 81 },
    },
    {
      desktop: { x: 206, y: 82 },
      tablet: { x: 216, y: 76 },
      mobile: { x: 149, y: 51 },
    },
    {
      desktop: { x: 206, y: 140.2 },
      tablet: { x: 216, y: 127.7 },
      mobile: { x: 149, y: 85.7 },
    },
    {
      desktop: { x: 144, y: 140.2 },
      tablet: { x: 161, y: 127.7 },
      mobile: { x: 108, y: 85.7 },
    },
    {
      desktop: { x: 144, y: 78 },
      tablet: { x: 161, y: 76 },
      mobile: { x: 108, y: 51 },
    },
  ],
  outlines: [
    {
      desktop: { x: 99, y: 78 },
      tablet: { x: 121, y: 76 },
      mobile: { x: 78, y: 51 },
      rotation: 0
    },
    {
      desktop: { x: 251, y: 172 },
      tablet: { x: 256, y: 156 },
      mobile: { x: 179, y: 111 },
      rotation: 180
    },
    {
      desktop: { x: 111, y: 172 },
      tablet: { x: 132, y: 156 },
      mobile: { x: 86, y: 107 },
      rotation: 315
    },
    {
      desktop: { x: 206, y: 172 },
      tablet: { x: 216, y: 155 },
      mobile: { x: 149, y: 111 },
      rotation: 0
    },
    {
      desktop: { x: 296, y: 135 },
      tablet: { x: 296, y: 122 },
      mobile: { x: 209, y: 87 },
      rotation: 45
    },
    {
      desktop: { x: 359, y: 379 },
      tablet: { x: 354, y: 338 },
      mobile: { x: 252, y: 249 },
      rotation: 180
    },
    {
      desktop: { x: 358, y: 199 },
      tablet: { x: 353, y: 179 },
      mobile: { x: 252, y: 129 },
      rotation: 315
    }
  ]
}
