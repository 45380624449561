import React, { Component } from 'react';
import propTypes from './prop-types';

import Tabs from '~/components/Tabs/Tabs';
import PanAndZoomWrapper from './PanAndZoomWrapper';
import Points from './Points';
import Lines from './Lines';
import Actions from './Actions';

import getRandomIndex from '~/services/get-random-index';

import { ANSWER } from '~/utils/constants';
import { ZOOM_LEVELS, MOBILE_WIDTH } from './constants';

import './TrainerFunnyPoints.scss';

class TrainerFunnyPoints extends Component {
  state = {
    isActive: true,
    clickedPoints: [],
    resultMode: false,
    currentFigure: null,
    scale: 0.7,
    isMobile: false,
    answerTab: ANSWER,
    success: false
  };

  componentWillMount = () => {
    const { params } = this.props;
    const { difficultyParams } = params;
    const currentFigure = this.getRandomFigure(difficultyParams);

    if (document.documentElement.clientWidth < MOBILE_WIDTH) {
      this.setState({ isMobile: true, scale: 0.4 });
    }

    if (!currentFigure.points || !currentFigure.points.length) {
      this.points = [];
      return;
    }

    this.points = currentFigure.points;
    this.setState({ currentFigure });
  };

  componentDidUpdate(prevProps) {
    const { isRefresh, isTimerActive } = this.props;
    const { currentFigure, isActive, isMobile, clickedPoints } = this.state;

    /** Завершаем этап тестирования по завершению времени */
    if (prevProps.isTimerActive && !isTimerActive && isActive) {
      this.setState({ isActive: false, scale: isMobile ? 0.4 : 0.7 }, () => {
          /** Отмечаем неправильно поставленные точки */
          this.setState({
            clickedPoints: clickedPoints.map((item, index) => {
              if (item.x !== currentFigure.points[index].x || item.y !== currentFigure.points[index].y) {
                return { ...item, wrong: true };
              }
              return item;
            }),
          });
          this.finishTrainer(null, 'fail', 'timerTick', 'next_force');
        },
      );
    }

    if (prevProps.isRefresh !== isRefresh) {
      this.setState({
        isActive: true,
        clickedPoints: [],
        resultMode: false,
        scale: 1,
        isMobile: false
      })
    }

  }

  getRandomFigure = ({ figures }) => figures[getRandomIndex(figures)];

  finishTrainer = (skills, reason, event='click', eventAction='next_self') => {
    const { onFinishCb, handleDataLayer } = this.props;

    handleDataLayer({ eventAction, event, stageName: 'page2_solve' });
    onFinishCb({ victory: !!skills, reason, skills });
  };

  pointClickHandler = point => {
    const { params } = this.props;
    const { difficultyParams } = params;
    const { winnerPoints } = difficultyParams;
    const { currentFigure, isMobile } = this.state;

    this.setState(
      prevState => ({
        clickedPoints: [
          ...prevState.clickedPoints,
          { ...point },
        ],
      }),
      () => {
        if (this.state.clickedPoints.length === currentFigure.points.length) {
          this.setState({ isActive: false });
          /** Проверяем порядок точек */
          if (JSON.stringify(this.state.clickedPoints) === JSON.stringify(currentFigure.points)) {
            this.finishTrainer(winnerPoints, 'complete');
            this.setState({ success: true, scale: isMobile ? 0.4 : 0.7 })
          } else {
            /** Отмечаем неправильно поставленные точки */
            this.setState({
              clickedPoints: this.state.clickedPoints.map((item, index) => {
                if (item.x !== currentFigure.points[index].x || item.y !== currentFigure.points[index].y) {
                  return {
                    ...item,
                    wrong: true,
                  };
                }
                return item;
              }),
              scale: isMobile ? 0.4 : 0.7
            });

            this.finishTrainer(null, 'fail');
          }
        }
      },
    );
  };

  zoomPlus = () => {
    const { scale } = this.state;
    this.setState({ scale: ZOOM_LEVELS.find((item) => item > scale) || ZOOM_LEVELS[ZOOM_LEVELS.length - 1] });
  };

  zoomMinus = () => {
    const { scale } = this.state;
    this.setState({ scale: [ ...ZOOM_LEVELS ].reverse().find((item) => item < scale) || ZOOM_LEVELS[0] });
  };

  toggleResultMode = () => this.setState((prevState) => ({ resultMode: !prevState.resultMode }));

  changeAnswerTab = (tab) => {
    this.setState({ answerTab: tab });

    if (tab !== this.state.answerTab) {
      this.toggleResultMode();
    }
  };

  render() {
    const { isActive, clickedPoints, resultMode, scale, currentFigure, success, answerTab, isMobile } = this.state;
    const { width, height, image, offsetX, offsetY } = currentFigure;
    const style = { width, height };

    return (
      <section className="trainer-funny-points">
        <div className="trainer-funny-points__container">
          {
            !isActive &&
            <div className="trainer-funny-points__tabs">
              <Tabs onClick={ this.changeAnswerTab } isCorrect={ success } selected={ answerTab }/>
            </div>
          }

          <PanAndZoomWrapper isActive={ isActive } scale={ scale } width={ width } height={ height }
                             isMobile={ isMobile } offsetX={ offsetX } offsetY={ offsetY }>
            <div className="trainer-funny-points__image" style={ style }>
              <img src={ image } alt="Веселые точки" className="trainer-funny-points__img"/>

              <Points points={ this.points } clickedPoints={ clickedPoints } resultMode={ resultMode }
                      isActive={ isActive } answerTab={ answerTab } pointClickHandler={ this.pointClickHandler }/>

              <Lines resultMode={ resultMode } difficultyParams={ currentFigure } clickedPoints={ clickedPoints }/>
            </div>
          </PanAndZoomWrapper>

          <Actions scale={ scale } zoomPlus={ this.zoomPlus } zoomMinus={ this.zoomMinus } isActive={ isActive }/>
        </div>
      </section>
    );
  }
}

TrainerFunnyPoints.propTypes = propTypes;

export default TrainerFunnyPoints;
