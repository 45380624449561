import React from 'react';
import cx from 'classnames';

import footerItems from './services/get-footer';

import './projects.scss';

const Projects = ({ isFooter }) => {
  const projectsClass = cx('projects', {
    'projects_footer': isFooter
  });

  return (
    <div className={ projectsClass }>
      <ul className="projects__list">
        { renderItems() }
      </ul>
    </div>
  );
};

const renderItems = () => {
  return footerItems.map((item, index) => (
    <li className="projects__item" key={ index }>
      <a className="projects__link" target="_blank" rel="noopener noreferrer" href={ item.url }>
        { item.title }
      </a>
    </li>
  ));
};

export default Projects;
