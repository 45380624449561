export default (blocksArray, blocksInLine) => {
  const correctCardsOrder = [];
  for (let m = 0; m < blocksArray.length / blocksInLine; m++) {
    correctCardsOrder.push(blocksArray.slice(m * blocksInLine, (m + 1) * blocksInLine));
  }

  // filling starting cards order
  const currentCardsOrder = [];
  for (let i = 0; i < correctCardsOrder.length; i++) {
    currentCardsOrder[i] = [...correctCardsOrder[i]].fill(-1);
  }
  return { correctCardsOrder, currentCardsOrder };
};
