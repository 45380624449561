export default {
  shapes: [ 1, 4, 8, 6, 7, 3, 5 ],
  offset: {
    tablet: 5,
    mobile: 15
  },
  outline: [
    {
      desktop: {
        x: 237,
        y: 99
      },
      tablet: {
        x: 239,
        y: 91
      },
      mobile: {
        x: 168,
        y: 63
      }
    },
    {
      desktop: {
        x: 192,
        y: 144
      },
      tablet: {
        x: 199,
        y: 131
      },
      mobile: {
        x: 198,
        y: 93
      }
    },
    {
      desktop: {
        x: 226,
        y: 178
      },
      tablet: {
        x: 230,
        y: 162
      },
      mobile: {
        x: 168,
        y: 123
      }
    },
    {
      desktop: {
        x: 181,
        y: 223.27
      },
      tablet: {
        x: 190,
        y: 202
      },
      mobile: {
        x: 181,
        y: 136
      }
    },
    {
      desktop: {
        x: 181,
        y: 313
      },
      tablet: {
        x: 190,
        y: 282
      },
      mobile: {
        x: 240.21,
        y: 136
      }
    },
    {
      desktop: {
        x: 226,
        y: 358
      },
      tablet: {
        x: 230,
        y: 322
      },
      mobile: {
        x: 240.21,
        y: 94.21
      }
    },
    {
      desktop: {
        x: 406,
        y: 358
      },
      tablet: {
        x: 390,
        y: 322
      },
      mobile: {
        x: 282.64,
        y: 94.21
      }
    },
    {
      desktop: {
        x: 340,
        y: 292
      },
      tablet: {
        x: 340,
        y: 272
      },
      mobile: {
        x: 241,
        y: 136
      }
    },
    {
      desktop: {
        x: 340,
        y: 202.28
      },
      tablet: {
        x: 340,
        y: 192.14
      },
      mobile: {
        x: 241,
        y: 196
      }
    },
    {
      desktop: {
        x: 403.46,
        y: 138.82
      },
      tablet: {
        x: 395.85,
        y: 136.28
      },
      mobile: {
        x: 282,
        y: 237
      }
    },
    {
      desktop: {
        x: 339.64,
        y: 139
      },
      tablet: {
        x: 339.28,
        y: 136.28
      },
      mobile: {
        x: 162,
        y: 237
      }
    },
    {
      desktop: {
        x: 339.82,
        y: 202
      },
      tablet: {
        x: 339.28,
        y: 192
      },
      mobile: {
        x: 132,
        y: 207
      }
    },
    {
      desktop: {
        x: 250,
        y: 202
      },
      tablet: {
        x: 260,
        y: 192
      },
      mobile: {
        x: 132,
        y: 147
      }
    },
    {
      desktop: {
        x: 237,
        y: 189
      },
      tablet: {
        x: 239,
        y: 171
      },
      mobile: {
        x: 162,
        y: 117
      }
    },
    {
      desktop: {
        x: 282,
        y: 144
      },
      tablet: {
        x: 279,
        y: 131
      },
      mobile: {
        x: 138,
        y: 93
      }
    }
  ],
  outlines: [
    {
      desktop: { x: 192, y: 99 },
      tablet: { x: 199, y: 91 },
      mobile: { x: 138, y: 63 },
      rotation: 0
    },
    {
      desktop: { x: 181, y: 178 },
      tablet: { x: 190, y: 162 },
      mobile: { x: 132, y: 117 },
      rotation: 0
    },
    {
      desktop: { x: 181, y: 223 },
      tablet: { x: 190, y: 202 },
      mobile: { x: 132, y: 147 },
      rotation: 0
    },
    {
      desktop: { x: 226, y: 178 },
      tablet: { x: 230, y: 162 },
      mobile: { x: 162, y: 117 },
      rotation: 0
    },
    {
      desktop: { x: 226, y: 358 },
      tablet: { x: 230, y: 322 },
      mobile: { x: 162, y: 237 },
      rotation: 270
    },
    {
      desktop: { x: 371.64, y: 107 },
      tablet: { x: 367.57, y: 108 },
      mobile: { x: 261.43, y: 73 },
      rotation: 45
    },
    {
      desktop: { x: 250, y: 292 },
      tablet: { x: 260, y: 272 },
      mobile: { x: 181, y: 196 },
      rotation: 270
    }
  ]
};
