export default {
  shapes: [4, 5, 1, 2, 7, 3, 6],
  outlines: [
    {
      id: 4,
      rotation: 180,
      desktop: {
        x: 241,
        y: 327
      },
      tablet: {
        x: 263,
        y: 310
      },
      mobile: {
        x: 189,
        y: 227
      }
    },
    {
      id: 5,
      rotation: 225,
      desktop: {
        x: 364.3,
        y: 214.28
      },
      tablet: {
        x: 363,
        y: 212.4
      },
      mobile: {
        x: 259.5,
        y: 161.3
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 143,
        y: 186.64
      },
      tablet: {
        x: 166,
        y: 175.57
      },
      mobile: {
        x: 112,
        y: 122.43
      }
    },
    {
      id: 2,
      rotation: 180,
      desktop: {
        x: 428,
        y: 284.5
      },
      tablet: {
        x: 419.5,
        y: 275.77
      },
      mobile: {
        x: 302,
        y: 208.8
      }
    },
    {
      id: 7,
      rotation: 315,
      desktop: {
        x: 175,
        y: 217.64
      },
      tablet: {
        x: 194.2,
        y: 203.57
      },
      mobile: {
        x: 133.2,
        y: 143.43
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 239.5,
        y: 111
      },
      tablet: {
        x: 250.9,
        y: 107.3
      },
      mobile: {
        x: 175.7,
        y: 71.4
      }
    },
    {
      id: 6,
      rotation: 225,
      desktop: {
        x: 301.64,
        y: 276.92
      },
      tablet: {
        x: 306.5,
        y: 268.9
      },
      mobile: {
        x: 217.3,
        y: 203.64
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 301.64,
        y: 149.68
      },
      tablet: {
        x: 363,
        y: 99.26
      },
      mobile: {
        x: 175.64,
        y: 101.34
      }
    },
    {
      desktop: {
        x: 364.3,
        y: 87
      },
      tablet: {
        x: 306.5,
        y: 155.76
      },
      mobile: {
        x: 175.64,
        y: 143.43
      }
    },
    {
      desktop: {
        x: 428,
        y: 150.68
      },
      tablet: {
        x: 306.5,
        y: 203.57
      },
      mobile: {
        x: 217.22,
        y: 143.43
      }
    },
    {
      desktop: {
        x: 428,
        y: 239.5
      },
      tablet: {
        x: 250.85000000000002,
        y: 203.57
      },
      mobile: {
        x: 217.22,
        y: 118.75
      }
    },
    {
      desktop: {
        x: 383,
        y: 284.5
      },
      tablet: {
        x: 250.85000000000002,
        y: 147.24
      },
      mobile: {
        x: 259.5,
        y: 76.45
      }
    },
    {
      desktop: {
        x: 383,
        y: 195.56
      },
      tablet: {
        x: 210.9,
        y: 107.30000000000001
      },
      mobile: {
        x: 302,
        y: 118.93
      }
    },
    {
      desktop: {
        x: 302.28,
        y: 276.28
      },
      tablet: {
        x: 170.9,
        y: 147.3
      },
      mobile: {
        x: 302,
        y: 178.8
      }
    },
    {
      desktop: {
        x: 302.28,
        y: 344.92
      },
      tablet: {
        x: 194.28,
        y: 147.3
      },
      mobile: {
        x: 272,
        y: 208.8
      }
    },
    {
      desktop: {
        x: 240.06,
        y: 282.7
      },
      tablet: {
        x: 194.28,
        y: 203.65
      },
      mobile: {
        x: 272,
        y: 148.82
      }
    },
    {
      desktop: {
        x: 196,
        y: 327
      },
      tablet: {
        x: 223,
        y: 232.37
      },
      mobile: {
        x: 218.05,
        y: 202.77
      }
    },
    {
      desktop: {
        x: 196,
        y: 238.64
      },
      tablet: {
        x: 223,
        y: 310
      },
      mobile: {
        x: 218.05,
        y: 228.28
      }
    },
    {
      desktop: {
        x: 174.82,
        y: 217.5
      },
      tablet: {
        x: 261.82,
        y: 271.18
      },
      mobile: {
        x: 187.89,
        y: 198.11
      }
    },
    {
      desktop: {
        x: 174.82,
        y: 156
      },
      tablet: {
        x: 307.34000000000003,
        y: 316.7
      },
      mobile: {
        x: 159,
        y: 227
      }
    },
    {
      desktop: {
        x: 149.5,
        y: 156
      },
      tablet: {
        x: 307.34000000000003,
        y: 268.06
      },
      mobile: {
        x: 159,
        y: 169.23000000000002
      }
    },
    {
      desktop: {
        x: 194.25,
        y: 111
      },
      tablet: {
        x: 379.5,
        y: 195.9
      },
      mobile: {
        x: 133.21,
        y: 143.47
      }
    },
    {
      desktop: {
        x: 238.45999999999998,
        y: 154.97
      },
      tablet: {
        x: 379.5,
        y: 275.77
      },
      mobile: {
        x: 133.21,
        y: 101.4
      }
    },
    {
      desktop: {
        x: 238.45999999999998,
        y: 217.64
      },
      tablet: {
        x: 419.5,
        y: 235.76999999999998
      },
      mobile: {
        x: 115.7,
        y: 101.4
      }
    },
    {
      desktop: {
        x: 301.64,
        y: 217.64
      },
      tablet: {
        x: 419.5,
        y: 155.76
      },
      mobile: {
        x: 145.7,
        y: 71.4
      }
    }
  ]
};
