import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '~/components/Icon/Icon';

import fox from './assets/fox.png';

import './tooltip.scss';

/* eslint-disable */
import play from '~/static/icon/play.svg';
/* eslint-enable */

const Tooltip = (props) => {
  const { content, openVideo, isMiniTooltip, handleHelpClick } = props;
  const [ isOpen, setTooltip ] = useState(false);
  const toggleTooltip = () => {
    if (!isOpen) {
      handleHelpClick();
    }
    setTooltip(!isOpen);
  };
  const classesTooltip = cx('tooltip__content', {
    'tooltip__content_mini': isMiniTooltip
  });

  return (
    <div className="tooltip">
      <button className="tooltip__button" onClick={ toggleTooltip } type="button">
        <Icon name="question" className="tooltip__button-icon" />
        <span className="tooltip__button-text">помощь</span>
      </button>
      {
        isOpen && (
          <div className={ classesTooltip } >
            <div className="tooltip__holder">
              <div className="tooltip__fox">
                <img alt="fox" src={ fox } className="tooltip__image" />
              </div>

              <div>
                <div className="tooltip__text" dangerouslySetInnerHTML={{ __html: content }} />
                {
                  openVideo &&
                  <button onClick={ openVideo } className="tooltip__video">
                    <div className="tooltip__play">
                      <Icon name="play" className="tooltip__play-icon" />
                    </div>

                    <div className="tooltip__play-text">
                      Посмотреть видео-инструкцию
                    </div>
                  </button>
                }
              </div>

            </div>

            <button className="tooltip__content-close" type="button" onClick={ toggleTooltip }>
              <Icon name="close" className="tooltip__content-close-icon"/>
            </button>
          </div>
        )
      }
    </div>
  )
}

Tooltip.propTypes = {
  content: PropTypes.string,
  openVideo: PropTypes.func
};

export default Tooltip;
