import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import propTypes from './prop-types';

import Button from '~/components/Button/Button';
import DynamicContent from '~/components/DynamicContent/DynamicContent';
import Tabs from '~/components/Tabs/Tabs';
import RememberStep from './RememberStep';
import TestStep from './TestStep';

import { STEP_TEST, STEP_REMEMBER, BUTTON_NEXT, BUTTON_CHECK, ANSWER } from '~/utils/constants';

import getContentStyle from '~/services/get-content-style';
import getShuffleArray from '~/services/get-shuffle-array';
import getCardsOrder from './utils/getCardsOrder';
import packNames from './utils/getPackNames';

import * as locationsActions from '~/store/locations/actions';

import './utils/dndPolyfill';
import './trainerDynamicMemory.scss';

const MAX_STEP_TEST = 3;

const TrainerDynamicMemory = (props) => {
  const { params, step, resultButton, isTimerActive, setTrainerStage, onStartCb, onFinishCb,
          onStageChange, onTimeEnd, widthWorkspace, heightWorkspace, handleDataLayer } = props;
  const { difficultyParams } = params;
  const { cardsInLine } = difficultyParams;
  const patterns = getShuffleArray(difficultyParams.patterns, MAX_STEP_TEST);

  const panelRef = useRef(null);
  const [ widthContent, setWidthContent ] = useState(0);
  const [ heightContent, setHeightContent ] = useState(0);
  const [ matrixImages, setMatrixImages ] = useState([]);
  const [ countStage, setCountStage ] = useState(1);
  const [ isFinish, setFinish ] = useState(false);
  const [ correctCardsOrder, setCorrectCardsOrder ] = useState([]);
  const [ currentCardsOrder, setCurrentCardsOrder ] = useState([]);
  const [ stepsLeft, setStepsLeft ] = useState(patterns[0].length);
  const [ wins, setWins ] = useState(0);
  const [ isWin, setWin ] = useState(false);
  const [ result, setResult ] = useState(false);
  const [ isClickNextButton, setClickNextButton ] = useState(false);
  const [ isShowResult, setIsShowResult ] = useState(false);
  const [ selectedItem, setSelecteditem ] = useState(null);
  const [ answerTab, setAnswerTab ] = useState(ANSWER);
  const changeAnswerTab = (tab) => setAnswerTab(tab);
  const isRemember = step === STEP_REMEMBER;
  const isLast = countStage === MAX_STEP_TEST * 2;

  useEffect(() => {
    setRandomPack();
    setTrainerStage(STEP_REMEMBER);
    generateCards();
  }, []);

  useEffect(() => {
    if (!isTimerActive) {
      if (!isClickNextButton) {
        setDataLayerForce();
      }

      if (isRemember) {
        goToTestStage();
      } else {
        checkStageVictory();
      }

      setClickNextButton(false);
    }
  }, [ isTimerActive ]);

  const setRandomPack = () => {
    const packName = getShuffleArray(packNames)[0];
    const matrixImages = params.matrixImages(packName).slice(0, MAX_STEP_TEST);

    setMatrixImages(matrixImages);
  };

  const generateCards = () => {
    const cardsOrder = { ...getCardsOrder(patterns[0], cardsInLine) };
    const { correctCardsOrder, currentCardsOrder } = cardsOrder;

    setCorrectCardsOrder(correctCardsOrder);
    setCurrentCardsOrder(currentCardsOrder);
  };

  const renderRememberStep = () => {
    return (
      <div className="trainer-dynamic-memory__step-holder" style={ getCardsStyle() }>
        <RememberStep correctCardsOrder={ correctCardsOrder } matrixImages={ matrixImages }
                      heightWorkspace={ heightWorkspace } widthWorkspace={ widthWorkspace }/>
      </div>
    );
  };

  const renderTestStep = () => {
    return (
      <div className="trainer-dynamic-memory__step-holder" style={ getCardsStyle() }>
        <TestStep correctCardsOrder={ correctCardsOrder } currentCardsOrder={ currentCardsOrder }
                  matrixImages={ matrixImages } answerTab={ answerTab } selectedItem={ selectedItem }
                  onDragStartHandler={ onDragStartHandler } onDndDragStartHandler={ onDndDragStartHandler }
                  onIconClickHandler={ onIconClickHandler } onCardDropHandler={ onCardDropHandler }
                  onCardDragLeaveHandler={ onCardDragLeaveHandler } onCardClickHandler={ onCardClickHandler }
                  onCardDragOverHandler={ onCardDragOverHandler } isFinish={ isFinish }
                  isShowResult={ isShowResult }/>
      </div>
    );
  };

  const onCardDragOverHandler = (event) => {
    event.preventDefault();
    event.target.classList.add('ondragover');
  };

  const onCardDragLeaveHandler = (event) => {
    event.preventDefault();
    event.target.classList.remove('ondragover');
  };

  const onCardDropHandler = (event, hIndex, vIndex) => {
    const newCardsOrder = [ ...currentCardsOrder ];
    const minusStep = (newCardsOrder[hIndex][vIndex] === -1);
    const value = Number(event.dataTransfer.getData('value'));

    event.preventDefault();
    if(value > -1) {
      event.target.classList.remove('ondragover');
      newCardsOrder[hIndex][vIndex] = value;
      setCurrentCardsOrder(newCardsOrder);
      if (minusStep && stepsLeft) {
        setStepsLeft(stepsLeft - 1);
      }
    }
  };

  const onDndDragStartHandler = (event, iconItem) => {
    event.dataTransfer.setData('value', '' + iconItem);
  }

  const onDragStartHandler = (event, hIndex, vIndex) => {
    const iconItem = currentCardsOrder[hIndex][vIndex];

    event.dataTransfer.setData('value', '' + iconItem);
  }

  const onIconClickHandler = (e, item) => {
    if (selectedItem !== item) {
      setSelecteditem(item);
    }
  };

  const onCardClickHandler = (hIndex, vIndex) => {
    if (!selectedItem) {
      return;
    }
    const newCardsOrder = [ ...currentCardsOrder ];

    if (stepsLeft && newCardsOrder[hIndex][vIndex] === -1) {
      setStepsLeft(stepsLeft - 1);
    }
    newCardsOrder[hIndex][vIndex] = selectedItem;
    setCurrentCardsOrder(newCardsOrder);
  };

  const checkTrainerVictory = (wins) => {
    const { difficultyParams } = params;
    const { winnerPoints } = difficultyParams;
    let reason = 'fail';
    let skills = null;

    if (wins === MAX_STEP_TEST) {
      reason = 'complete';
      skills = winnerPoints;
    }

    onFinishCb({ victory: Boolean(skills), reason, skills });
  };

  const checkStageVictory = () => {
    let result = false;
    let lastWins = wins;

    if (JSON.stringify(currentCardsOrder) === JSON.stringify(correctCardsOrder)) {
      setWins(wins + 1);
      setWin(true);
      lastWins += 1;
      result = true;
    } else {
      setWin(false);
    }

    setSelecteditem(null);
    setIsShowResult(true);
    setFinish(true);
    setResult(result);
    onTimeEnd();

    if (isLast) {
      checkTrainerVictory(lastWins);
    }
  }

  const onStepChangeHandler = () => {
    onStageChange(countStage + 1);
    setCountStage(countStage + 1);
    generateCards();
    setIsShowResult(false);
    setTrainerStage(STEP_REMEMBER);
    setAnswerTab(ANSWER);
    setSelecteditem(null);
    setFinish(false);
    onStartCb();
  };

  const goToTestStage = () => {
    setCountStage(countStage + 1);
    setFinish(false);
    setStepsLeft(patterns[0].length);
    setTrainerStage(STEP_TEST);
    onStartCb();
  };

  const setDataLayer = (solve) => {
    const numberStep = Math.ceil(countStage / 2);
    let nameStepData = `page2A_task${ numberStep }`;
    let eventAction = 'next_self';

    if (solve) {
      nameStepData = `page2B_solve${ numberStep }`;
    }

    if (!solve && !isRemember) {
      eventAction = 'next';

      if (answerTab !== ANSWER || isWin) {
        nameStepData = `page3A_check${ numberStep }_success`;
      } else {
        nameStepData = `page3B_check${ numberStep }_fail`;
      }
    }

    handleDataLayer({ eventAction, stageName: nameStepData });
  };

  const setDataLayerForce = () => {
    const numberStep = Math.ceil(countStage / 2);
    let nameStepData = isRemember ? 'A_task' : 'B_solve';

    handleDataLayer({ event: 'timerTick', eventAction: 'next_force', stageName: nameStepData, stageLevel: numberStep });
  }

  const renderButton = () => {
    let text = BUTTON_NEXT;
    let onClick = () => {
      onTimeEnd();
      onStageChange(countStage);
      setDataLayer();
      setClickNextButton(true);
    };

    if (!isRemember && !isFinish) {
      text = BUTTON_CHECK;
      onClick = () => {
        onTimeEnd();
        setDataLayer(true);
        setClickNextButton(true);
      };
    }

    if (!isRemember && isFinish) {
      text = BUTTON_NEXT;
      onClick = () => {
        onStepChangeHandler();
        setDataLayer();
        setClickNextButton(false);
      };
    }

    return (
      <div className="trainer-dynamic-memory__check-answers">
        <Button className="trainer-dynamic-memory__button button_center" onClick={ onClick }>
          { text }
        </Button>
      </div>
    )
  }

  const steps = {
    [ STEP_REMEMBER ]: renderRememberStep,
    [ STEP_TEST ]: renderTestStep
  }

  const setContentSize = ({ width, height }) => {
    setWidthContent(width);
    setHeightContent(height);
  };

  const getCardsStyle = () => getContentStyle({ padding: 0, widthContent, heightContent, aspectRatioContent: cardsInLine / currentCardsOrder.length });

  const renderDndPanel = () => {
    return (
      <div ref={ panelRef } className="trainer-dynamic-memory__panel" style={ { opacity: isShowResult ? 0 : 1 } }>
        {
          matrixImages.map((item, index) => {
            return renderDnd({ item, index, isFinish, selectedItem, onDndDragStartHandler, onIconClickHandler })
          })
        }
      </div>
    )
  };

  return (
    <section className="trainer-dynamic-memory">
      { !isRemember && renderDndPanel() }
      {
        isShowResult &&
        <div className="trainer-dynamic-memory__tabs">
          <Tabs onClick={ changeAnswerTab } isCorrect={ result } selected={ answerTab } />
        </div>
      }
      <DynamicContent widthWorkspace={ widthWorkspace } heightWorkspace={ heightWorkspace }
                      setSize={ setContentSize } refs={ [ panelRef ] }>
        { steps[step]() }
      </DynamicContent>

      {
        !resultButton &&
        !(step === STEP_TEST && stepsLeft > 0 && isTimerActive) &&
        renderButton()
      }
    </section>
  )
}

const renderDnd = (props) => {
  const { item, index, isFinish, selectedItem, onDndDragStartHandler, onIconClickHandler } = props;
  const classNameDnd = cx('trainer-dynamic-memory__panel-card', {
    'trainer-dynamic-memory__panel-card_active' : selectedItem === index + 1
  });
  const handlerDrag = !isFinish ? (event) => onDndDragStartHandler(event, index + 1) : null;
  const handlerClick = !isFinish ? (event) => onIconClickHandler(event, index + 1) : null;

  return (
    <div className={ classNameDnd } draggable={ !isFinish } onDragStart={ handlerDrag }
         onClick={ handlerClick } key={ index }>
      <img src={ item } alt={ `Карточка номер ${ index + 1 }` } className="trainer-dynamic-memory__panel-image" />
    </div>
  );
};

const mapDispatchToProps = {
  setTrainerStage: locationsActions.setTrainerStage,
};

const mapStateToProps = state => ({
  step: state.locations.currentTrainerStage
});

TrainerDynamicMemory.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(TrainerDynamicMemory);
