import gifts from '~/shared/gifts';

export default {
  id: 4,
  name: 'Московский Кремль',
  longMemory: true,
  description: 'Символ российской столицы – Московский Кремль.',
  partingWords: 'Для выполнения новых интригующих заданий тебе точно пригодится набор агента-новичка. Мистер Фоксман специально для тебя подготовил новую миссию. Задания становятся всё сложнее, но и твои навыки растут. Пройди миссию, и получишь очень важный предмет – очки спецагента! ',
  story: `Символ российской столицы — Московский Кремль — невероятно интересная и прекрасная древняя крепость. Возведённый для защиты от врагов, в наши дни он стал самым узнаваемым символом не только Москвы, но и всей России.
          Информация для спецагента:
          1. Именно он является самой древней постройкой в Москве.
          2. Каждая из звёзд, венчающих кремлёвские башни, весит больше тонны.
          3. Московский Кремль — самая крупная во всей Европе крепость.`,
  textLosing: 'В этот раз не получилось! Потренируй навыки в тренажёрном зале, и в следующий раз ты точно справишься!',
  textPartially: 'К сожалению, не все задания миссии были выполнены. Но настоящий спецагент никогда не опускает руки – потренируйся и возвращайся! В следующий раз обязательно получится!',
  textSuccess: 'Отлично! Теперь в твоём наборе есть очки спецагента. Они снабжены всеми функциями, которые необходимы для выполнения миссий. В очках ты выглядишь как настоящий спецагент!',
  gift: gifts.glasses,
  imgs: [
    require('~/static/locations/moscow/kremlin_980х590.jpg'),
  ]
};
