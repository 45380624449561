import butterfly from '../figures/butterfly';
import palm from '../figures/palm';
import soda from '../figures/soda';
import dino from '../figures/dino';
import dino2 from '../figures/dino2';
import tiger from '../figures/tiger';
import piranha from '../figures/piranha';
import frog from '../figures/frog';
import polter from '../figures/polter';
import bird from '../figures/bird';

export default [
  butterfly,
  palm,
  soda,
  dino,
  tiger,
  piranha,
  dino2,
  frog,
  polter,
  bird
];
