import React from 'react';
import cx from 'classnames';

import Ok from '~/components/Ok/Ok';
import Fail from '~/components/Fail/Fail';

import './intellectual-workouts-controls.scss';

const IntellectualWorkoutsControls = (props) => {
  const { currentAnswerIndex, isWin, setWin, isFinish, incrementWins, onTimeEnd,
          setCurrentAnswerIndex, setFinish, answer } = props;

  const renderControls = () => {
    return getControls().map((control, index) => {
      const { name, handler } = control;
      const isCorrect = currentAnswerIndex === index && isWin;
      const isIncorrect = currentAnswerIndex === index && !isWin;
      const classesControl = cx('intellectual-workouts-controls__item', {
        'intellectual-workouts-controls__item_correct': isCorrect,
        'intellectual-workouts-controls__item_incorrect': isIncorrect,
        'intellectual-workouts-controls__item_result': isFinish && currentAnswerIndex !== index && handler(answer),
        'intellectual-workouts-controls__item_disabled': isFinish
      });
      const handlerClick = () => {
        if (isFinish) {
          return;
        }

        if (handler(answer)) {
          incrementWins();
          setWin(true);
        }

        setCurrentAnswerIndex(index);
        setFinish(true);
        onTimeEnd();
      };

      return (
        <div className={ classesControl } key={ index } onClick={ handlerClick }>
          { isCorrect && <Ok /> }
          { isIncorrect && <Fail /> }
          { name }
        </div>
      );
    });
  };

  return (
    <div className="intellectual-workouts-controls">
      { renderControls() }
    </div>
  );
};

const getControls = () => [
  {
    name: 'Да',
    handler: (answer) => answer
  },
  {
    name: 'Нет',
    handler: (answer) => !answer
  },
  {
    name: 'Не знаю',
    handler: () => false
  }
];

export default IntellectualWorkoutsControls;
