const checkMaxCountNumber = ({ field, number }) => {
  let count = 0;

  field.forEach((row) => {
    row.forEach((item) => {
      if(item.value === number || item === number) {
        count++;
      }
    });
  });

  return count === field.length;
};

export default (row, col, field, numbers) => {
  let options = [];

  for (let number of numbers) {
    const selected = field[row][col].value === number;
    const option = {
      value: number,
      selected,
      active: selected ? true : !checkMaxCountNumber({ field, number }),
      marked: field[row][col].notes.has(number)
    };
    options.push(option);
  }

  return options;
};
