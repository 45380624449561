export default {
  shapes: [ 5, 6, 7, 4, 1, 8, 3 ],
  offset: {
    desktop: 60,
    tablet: 60,
    mobile: 60
  },
  outline: [
    {
      desktop: { x: 137.6, y: 229.4 },
      tablet: { x: 155.6, y: 207.5 },
      mobile: { x: 103.5, y: 150.5 },
    },
    {
      desktop: { x: 137.6, y: 356.6 },
      tablet: { x: 155.6, y: 320.5 },
      mobile: { x: 103.5, y: 235.4},
    },
    {
      desktop: { x: 201.3, y: 293 },
      tablet: { x: 212.1, y: 264 },
      mobile: { x: 145.8, y: 193 },
    },
    {
      desktop: { x: 380.3, y: 293 },
      tablet: { x: 370.4, y: 264 },
      mobile: { x: 264.6, y: 193 },
    },
    {
      desktop: { x: 317.2, y: 356.2 },
      tablet: { x: 314.7, y: 319.7 },
      mobile: { x: 222.8, y: 234.8 },
    },
    {
      desktop: { x: 381, y: 356 },
      tablet: { x: 371.3, y: 319.7 },
      mobile: { x: 265.2, y: 234.8 },
    },
    {
      desktop: { x: 380.8, y: 294 },
      tablet: { x: 371.3, y: 264.3 },
      mobile: { x: 265.2, y: 193.2 },
    },
    {
      desktop: { x: 415.8, y: 328.8 },
      tablet: { x: 404.3, y: 297.3 },
      mobile: { x: 289.2, y: 217.2 },
    },
    {
      desktop: { x: 479.5, y: 328.8 },
      tablet: { x: 459.9, y: 297.3 },
      mobile: { x: 331.7, y: 217.2 },
    },
    {
      desktop: { x: 479.5, y: 265.9 },
      tablet: { x: 459.9, y: 240.3 },
      mobile: { x: 331.7, y: 174.2 },
    },
    {
      desktop: { x: 417, y: 203.4 },
      tablet: { x: 403, y: 183.5 },
      mobile: { x: 290, y: 132.6 },
    },
    {
      desktop: { x: 417, y: 113 },
      tablet: { x: 403, y: 105 },
      mobile: { x: 290, y: 73.1 },
    },
    {
      desktop: { x: 372, y: 68 },
      tablet: { x: 363, y: 65 },
      mobile: { x: 260, y: 44 },
    },
    {
      desktop: { x: 372, y: 158 },
      tablet: { x: 363, y: 145 },
      mobile: { x: 260, y: 104 },
    },
    {
      desktop: { x: 416.7, y: 202.8 },
      tablet: { x: 401.8, y: 183.8 },
      mobile: { x: 289, y: 133.1 },
    },
    {
      desktop: { x: 291.3, y: 202.7 },
      tablet: { x: 291.2, y: 183.8 },
      mobile: { x: 204.9, y: 133.1 },
    },
    {
      desktop: { x: 201.3, y: 293 },
      tablet: { x: 211.6, y: 263.5 },
      mobile: { x: 145.4, y: 192.6 },
    },
  ],
  outlines: [
    {
      desktop: { x: 74, y: 293 },
      tablet: { x: 99, y: 264 },
      mobile: { x: 60, y: 193 },
      rotation: 315
    },
    {
      desktop: { x: 201, y: 293 },
      tablet: { x: 211, y: 264 },
      mobile: { x: 144, y: 193 },
      rotation: 270
    },
    {
      desktop: { x: 289, y: 203 },
      tablet: { x: 291, y: 184 },
      mobile: { x: 205, y: 133 },
      rotation: 315
    },
    {
      desktop: { x: 384, y: 234 },
      tablet: { x: 376, y: 213 },
      mobile: { x: 268, y: 154 },
      rotation: 315
    },
    {
      desktop: { x: 385, y: 298 },
      tablet: { x: 375, y: 269 },
      mobile: { x: 269, y: 197 },
      rotation: 315
    },
    {
      desktop: { x: 372, y: 68 },
      tablet: { x: 364, y: 65 },
      mobile: { x: 260, y: 45 },
      rotation: 0
    },
    {
      desktop: { x: 349, y: 388 },
      tablet: { x: 342, y: 349 },
      mobile: { x: 244, y: 257 },
      rotation: 225
    }
  ]
};
