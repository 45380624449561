import React from 'react';

import './ok-button.scss';

const OkButton = (props) => {
  const { onClick } = props;

  return (
    <button className="ok-button" onClick={ onClick }>
      OK
    </button>
  )
}

export default OkButton;
