import React from 'react';
import Geo from '~/components/Geo/Geo';
import Button from '~/components/Button/Button'

import './openMapLocations.scss'

const OpenMapLocations = () => {
  const handleClick = () => {
    window.dataLayer.push({ event: 'click', eventCategory: 'Locations', eventAction: 'GoMap', eventLabel: 'main' });
  };

  return (
    <div className="open-map-locations">
      <div className="open-map-locations__left">
        <div className="open-map-locations__side-mask"></div>
      </div>

      <div className="open-map-locations__card">
        <div className="open-map-locations__mask"></div>

        <div className="open-map-locations__box">
          <div className="open-map-locations__info">
            <p className="open-map-locations__text">
              Выполняя миссии, ты узнаешь о разных городах и странах. Посмотри карту и начни играть!
            </p>

            <div className="open-map-locations__link">
              <Button link="/locations/" onClick={ handleClick }>
                открыть карту
              </Button>
            </div>
          </div>
        </div>

        <div className="open-map-locations__geo">
          <Geo location="лондон" />
        </div>
      </div>

      <div className="open-map-locations__right">
        <div className="open-map-locations__geo">
          <Geo location="москва" />
        </div>

        <div className="open-map-locations__side-mask"></div>
      </div>
    </div>
  );
}

export default OpenMapLocations
