import React, { Fragment } from 'react';
import Icon from '~/components/Icon/Icon';
import cx from 'classnames';

import './missionCardDesc.scss';

/* eslint-disable */
import completed from './assets/completed.svg';
/* eslint-enable */

const MissionCardDesc = ({ skillsView, completed, description, trainerCount, locked }) => {
  let allEarnedSkillsCount = 0;
  let allPossibleSkillsCount = 0;

  Object.keys(skillsView).forEach(item => {
    allEarnedSkillsCount += skillsView[item].value;
    allPossibleSkillsCount += skillsView[item].max;
  })

  return (
    <div className={ cx("mission-card-desc", {
      "mission-card-desc_locked": locked
    }) }>
      { !completed ? description
        :
        <Fragment>
          <div className="mission-card-desc__completed">
            <div className="mission-card-desc__completed-icon-box">
              <Icon name="completed" className="mission-card-desc__completed-icon" />
            </div>

            <span className="mission-card-desc__completed-text">
              выполнена
            </span>
          </div>

          <div className="mission-card-desc__values">
            <div className="mission-card-desc__tasks">
              <span className="mission-card-desc__value">{ trainerCount }</span>
              <span className="mission-card-desc__all">&nbsp;/&nbsp;{ trainerCount }</span>
              <span className="mission-card-desc__label">&nbsp;правильных заданий</span>
            </div>

            <div className="mission-card-desc__points">
              <span className="mission-card-desc__value">{ allEarnedSkillsCount }</span>
              <span className="mission-card-desc__all">&nbsp;/&nbsp;{ allPossibleSkillsCount }</span>
              <span className="mission-card-desc__label">&nbsp;навыковых баллов</span>
            </div>
          </div>
        </Fragment>
      }
    </div>
  )
}

export default MissionCardDesc;
