import React from 'react';
import cx from 'classnames';

import './ok.scss';

const Ok = ({ className }) => {
  const classes = cx('ok', className);
  return (
    <div className={ classes }>
      <span className="ok__icon" />
    </div>
  );
}

export default Ok;
