export default {
  image: require('~/static/trainers/funnyPoints/clock.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -61,  y: 294 },
    { id: 2,  x: -83,  y: 294 },
    { id: 3,  x: -95,  y: 334 },
    { id: 4,  x: -139, y: 333 },
    { id: 5,  x: -176, y: 547 },
    { id: 6,  x: -153, y: 571 },
    { id: 7,  x: -127, y: 572 },
    { id: 8,  x: -141, y: 613 },
    { id: 9,  x: -114, y: 613 },
    { id: 10, x: -110, y: 666 }
  ]
}
