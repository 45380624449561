export default [
  {
    sequence: [10, 12, 14, 16, 18],
    answer: 20,
    actions: ['+2']
  },
  {
    sequence: [10, 13, 16, 19, 22],
    answer: 25,
    actions: ['+3']
  },
  {
    sequence: [10, 14, 18, 22, 26],
    answer: 30,
    actions: ['+4']
  },
  {
    sequence: [10, 15, 20, 25, 30],
    answer: 35,
    actions: ['+5']
  },
  {
    sequence: [10, 16, 22, 28, 34],
    answer: 40,
    actions: ['+6']
  },
  {
    sequence: [10, 17, 24, 31, 38],
    answer: 45,
    actions: ['+7']
  },
  {
    sequence: [10, 18, 26, 34, 42],
    answer: 50,
    actions: ['+8']
  },
  {
    sequence: [10, 19, 28, 37, 46],
    answer: 55,
    actions: ['+9']
  },
  {
    sequence: [10, 20, 30, 40, 50],
    answer: 60,
    actions: ['+10']
  },
  {
    sequence: [11, 13, 15, 17, 19],
    answer: 21,
    actions: ['+2']
  },
  {
    sequence: [11, 14, 17, 20, 23],
    answer: 26,
    actions: ['+3']
  },
  {
    sequence: [11, 15, 19, 23, 27],
    answer: 31,
    actions: ['+4']
  },
  {
    sequence: [11, 16, 21, 26, 31],
    answer: 36,
    actions: ['+5']
  },
  {
    sequence: [11, 17, 23, 29, 35],
    answer: 41,
    actions: ['+6']
  },
  {
    sequence: [11, 18, 25, 32, 39],
    answer: 46,
    actions: ['+7']
  },
  {
    sequence: [11, 19, 27, 35, 43],
    answer: 51,
    actions: ['+8']
  },
  {
    sequence: [11, 20, 29, 38, 47],
    answer: 56,
    actions: ['+9']
  },
  {
    sequence: [11, 21, 31, 41, 51],
    answer: 61,
    actions: ['+10']
  },
  {
    sequence: [12, 14, 16, 18, 20],
    answer: 22,
    actions: ['+2']
  },
  {
    sequence: [12, 15, 17, 21, 24],
    answer: 27,
    actions: ['+3']
  },
  {
    sequence: [12, 16, 18, 24],
    answer: 28,
    actions: ['+4']
  },
  {
    sequence: [12, 17, 19, 27],
    answer: 32,
    actions: ['+5']
  },
  {
    sequence: [12, 18, 20, 30],
    answer: 36,
    actions: ['+6']
  },
  {
    sequence: [12, 19, 21, 33],
    answer: 40,
    actions: ['+7']
  },
  {
    sequence: [12, 20, 22, 36],
    answer: 44,
    actions: ['+8']
  },
  {
    sequence: [12, 21, 23, 39],
    answer: 48,
    actions: ['+9']
  },
  {
    sequence: [12, 22, 24, 42],
    answer: 52,
    actions: ['+10']
  },
  {
    sequence: [13, 15, 17, 19],
    answer: 21,
    actions: ['+2']
  },
  {
    sequence: [13, 16, 19, 22],
    answer: 25,
    actions: ['+3']
  },
  {
    sequence: [13, 17, 21, 25],
    answer: 29,
    actions: ['+4']
  },
  {
    sequence: [13, 18, 23, 28],
    answer: 33,
    actions: ['+5']
  },
  {
    sequence: [13, 19, 25, 31],
    answer: 37,
    actions: ['+6']
  },
  {
    sequence: [13, 20, 27, 34],
    answer: 41,
    actions: ['+7']
  },
  {
    sequence: [13, 21, 29, 37],
    answer: 45,
    actions: ['+8']
  },
  {
    sequence: [13, 22, 31, 40],
    answer: 49,
    actions: ['+9']
  },
  {
    sequence: [13, 23, 33, 43],
    answer: 53,
    actions: ['+10']
  },
  {
    sequence: [14, 16, 18, 20],
    answer: 22,
    actions: ['+2']
  },
  {
    sequence: [14, 17, 20, 23],
    answer: 26,
    actions: ['+3']
  },
  {
    sequence: [14, 18, 22, 26],
    answer: 30,
    actions: ['+4']
  },
  {
    sequence: [14, 19, 24, 29],
    answer: 34,
    actions: ['+5']
  },
  {
    sequence: [14, 20, 26, 32],
    answer: 38,
    actions: ['+6']
  },
  {
    sequence: [14, 21, 28, 35],
    answer: 42,
    actions: ['+7']
  },
  {
    sequence: [14, 22, 30, 38],
    answer: 46,
    actions: ['+8']
  },
  {
    sequence: [14, 23, 32, 41],
    answer: 50,
    actions: ['+9']
  },
  {
    sequence: [14, 24, 34, 44],
    answer: 54,
    actions: ['+10']
  },
  {
    sequence: [15, 17, 19, 21],
    answer: 23,
    actions: ['+2']
  },
  {
    sequence: [15, 18, 21, 24],
    answer: 27,
    actions: ['+3']
  },
  {
    sequence: [15, 19, 23, 27],
    answer: 31,
    actions: ['+4']
  },
  {
    sequence: [15, 20, 25, 30],
    answer: 35,
    actions: ['+5']
  },
  {
    sequence: [15, 21, 27, 33],
    answer: 39,
    actions: ['+6']
  },
  {
    sequence: [15, 22, 29, 36],
    answer: 43,
    actions: ['+7']
  },
  {
    sequence: [15, 23, 31, 39],
    answer: 47,
    actions: ['+8']
  },
  {
    sequence: [15, 24, 33, 42],
    answer: 51,
    actions: ['+9']
  },
  {
    sequence: [15, 25, 35, 45],
    answer: 55,
    actions: ['+10']
  },
  {
    sequence: [16, 18, 20, 22],
    answer: 24,
    actions: ['+2']
  },
  {
    sequence: [16, 19, 22, 25],
    answer: 28,
    actions: ['+3']
  },
  {
    sequence: [16, 20, 24, 28],
    answer: 32,
    actions: ['+4']
  },
  {
    sequence: [16, 21, 26, 31],
    answer: 36,
    actions: ['+5']
  },
  {
    sequence: [16, 22, 28, 34],
    answer: 40,
    actions: ['+6']
  },
  {
    sequence: [16, 23, 30, 37],
    answer: 44,
    actions: ['+7']
  },
  {
    sequence: [16, 24, 32, 40],
    answer: 48,
    actions: ['+8']
  },
  {
    sequence: [16, 25, 34, 43],
    answer: 52,
    actions: ['+9']
  },
  {
    sequence: [16, 26, 36, 46],
    answer: 56,
    actions: ['+10']
  },
  {
    sequence: [17, 19, 21, 23],
    answer: 25,
    actions: ['+2']
  },
  {
    sequence: [17, 20, 23, 26],
    answer: 29,
    actions: ['+3']
  },
  {
    sequence: [17, 21, 25, 29],
    answer: 33,
    actions: ['+4']
  },
  {
    sequence: [17, 22, 27, 32],
    answer: 37,
    actions: ['+5']
  },
  {
    sequence: [17, 23, 29, 35],
    answer: 41,
    actions: ['+6']
  },
  {
    sequence: [17, 24, 31, 38],
    answer: 45,
    actions: ['+7']
  },
  {
    sequence: [17, 25, 33, 41],
    answer: 49,
    actions: ['+8']
  },
  {
    sequence: [17, 26, 35, 44],
    answer: 53,
    actions: ['+9']
  },
  {
    sequence: [17, 27, 37, 47],
    answer: 57,
    actions: ['+10']
  },
  {
    sequence: [18, 20, 22, 24],
    answer: 26,
    actions: ['+2']
  },
  {
    sequence: [18, 21, 24, 27],
    answer: 30,
    actions: ['+3']
  },
  {
    sequence: [18, 22, 26, 30],
    answer: 34,
    actions: ['+4']
  },
  {
    sequence: [18, 23, 28, 33],
    answer: 38,
    actions: ['+5']
  },
  {
    sequence: [18, 24, 30, 36],
    answer: 42,
    actions: ['+6']
  },
  {
    sequence: [18, 25, 32, 39],
    answer: 46,
    actions: ['+7']
  },
  {
    sequence: [18, 26, 34, 42],
    answer: 50,
    actions: ['+8']
  },
  {
    sequence: [18, 27, 36, 45],
    answer: 54,
    actions: ['+9']
  },
  {
    sequence: [18, 28, 38, 48],
    answer: 58,
    actions: ['+10']
  },
  {
    sequence: [19, 21, 23, 25],
    answer: 27,
    actions: ['+2']
  },
  {
    sequence: [19, 22, 25, 28],
    answer: 31,
    actions: ['+3']
  },
  {
    sequence: [19, 23, 27, 31],
    answer: 35,
    actions: ['+4']
  },
  {
    sequence: [19, 24, 29, 34],
    answer: 39,
    actions: ['+5']
  },
  {
    sequence: [19, 25, 31, 37],
    answer: 43,
    actions: ['+6']
  },
  {
    sequence: [19, 26, 33, 40],
    answer: 47,
    actions: ['+7']
  },
  {
    sequence: [19, 27, 35, 43],
    answer: 51,
    actions: ['+8']
  },
  {
    sequence: [19, 28, 37, 46],
    answer: 55,
    actions: ['+9']
  },
  {
    sequence: [19, 29, 39, 49],
    answer: 59,
    actions: ['+10']
  },
  {
    sequence: [20, 22, 24, 26],
    answer: 28,
    actions: ['+2']
  },
  {
    sequence: [20, 23, 26, 29],
    answer: 32,
    actions: ['+3']
  },
  {
    sequence: [20, 24, 28, 32],
    answer: 36,
    actions: ['+4']
  },
  {
    sequence: [20, 25, 30, 35],
    answer: 40,
    actions: ['+5']
  },
  {
    sequence: [20, 26, 32, 38],
    answer: 44,
    actions: ['+6']
  },
  {
    sequence: [20, 27, 34, 41],
    answer: 48,
    actions: ['+7']
  },
  {
    sequence: [20, 28, 36, 44],
    answer: 52,
    actions: ['+8']
  },
  {
    sequence: [20, 29, 38, 47],
    answer: 56,
    actions: ['+9']
  },
  {
    sequence: [20, 30, 40, 50],
    answer: 60,
    actions: ['+10']
  },
  {
    sequence: [21, 23, 25, 27],
    answer: 29,
    actions: ['+2']
  },
  {
    sequence: [21, 24, 27, 30],
    answer: 33,
    actions: ['+3']
  },
  {
    sequence: [21, 25, 29, 33],
    answer: 37,
    actions: ['+4']
  },
  {
    sequence: [21, 26, 31, 36],
    answer: 41,
    actions: ['+5']
  },
  {
    sequence: [21, 27, 33, 39],
    answer: 45,
    actions: ['+6']
  },
  {
    sequence: [21, 28, 35, 42],
    answer: 49,
    actions: ['+7']
  },
  {
    sequence: [21, 29, 37, 45],
    answer: 53,
    actions: ['+8']
  },
  {
    sequence: [21, 30, 39, 48],
    answer: 57,
    actions: ['+9']
  },
  {
    sequence: [21, 31, 41, 51],
    answer: 61,
    actions: ['+10']
  },
  {
    sequence: [22, 24, 26, 28],
    answer: 30,
    actions: ['+2']
  },
  {
    sequence: [22, 25, 28, 31],
    answer: 34,
    actions: ['+3']
  },
  {
    sequence: [22, 26, 30, 34],
    answer: 38,
    actions: ['+4']
  },
  {
    sequence: [22, 27, 32, 37],
    answer: 42,
    actions: ['+5']
  },
  {
    sequence: [22, 28, 34, 40],
    answer: 46,
    actions: ['+6']
  },
  {
    sequence: [22, 29, 36, 43],
    answer: 50,
    actions: ['+7']
  },
  {
    sequence: [22, 30, 38, 46],
    answer: 54,
    actions: ['+8']
  },
  {
    sequence: [22, 31, 40, 49],
    answer: 58,
    actions: ['+9']
  },
  {
    sequence: [22, 32, 42, 52],
    answer: 62,
    actions: ['+10']
  },
  {
    sequence: [23, 25, 27, 29],
    answer: 31,
    actions: ['+2']
  },
  {
    sequence: [23, 26, 29, 32],
    answer: 35,
    actions: ['+3']
  },
  {
    sequence: [23, 27, 31, 35],
    answer: 39,
    actions: ['+4']
  },
  {
    sequence: [23, 28, 33, 38],
    answer: 43,
    actions: ['+5']
  },
  {
    sequence: [23, 29, 35, 41],
    answer: 47,
    actions: ['+6']
  },
  {
    sequence: [23, 30, 37, 44],
    answer: 51,
    actions: ['+7']
  },
  {
    sequence: [23, 31, 39, 47],
    answer: 55,
    actions: ['+8']
  },
  {
    sequence: [23, 32, 41, 50],
    answer: 59,
    actions: ['+9']
  },
  {
    sequence: [23, 33, 43, 53],
    answer: 63,
    actions: ['+10']
  },
  {
    sequence: [24, 26, 28, 30],
    answer: 32,
    actions: ['+2']
  },
  {
    sequence: [24, 27, 30, 33],
    answer: 36,
    actions: ['+3']
  },
  {
    sequence: [24, 28, 32, 36],
    answer: 40,
    actions: ['+4']
  },
  {
    sequence: [24, 29, 34, 39],
    answer: 44,
    actions: ['+5']
  },
  {
    sequence: [24, 30, 36, 42],
    answer: 48,
    actions: ['+6']
  },
  {
    sequence: [24, 31, 38, 45],
    answer: 52,
    actions: ['+7']
  },
  {
    sequence: [24, 32, 40, 48],
    answer: 56,
    actions: ['+8']
  },
  {
    sequence: [24, 33, 42, 51],
    answer: 60,
    actions: ['+9']
  },
  {
    sequence: [24, 34, 44, 54],
    answer: 64,
    actions: ['+10']
  },
  {
    sequence: [25, 27, 29, 31],
    answer: 33,
    actions: ['+2']
  },
  {
    sequence: [25, 28, 31, 34],
    answer: 37,
    actions: ['+3']
  },
  {
    sequence: [25, 29, 33, 37],
    answer: 41,
    actions: ['+4']
  },
  {
    sequence: [25, 30, 35, 40],
    answer: 45,
    actions: ['+5']
  },
  {
    sequence: [25, 31, 37, 43],
    answer: 49,
    actions: ['+6']
  },
  {
    sequence: [25, 32, 39, 46],
    answer: 53,
    actions: ['+7']
  },
  {
    sequence: [25, 33, 41, 49],
    answer: 57,
    actions: ['+8']
  },
  {
    sequence: [25, 34, 43, 52],
    answer: 61,
    actions: ['+9']
  },
  {
    sequence: [25, 35, 45, 55],
    answer: 65,
    actions: ['+10']
  },
  {
    sequence: [26, 28, 30, 32],
    answer: 34,
    actions: ['+2']
  },
  {
    sequence: [26, 29, 32, 35],
    answer: 38,
    actions: ['+3']
  },
  {
    sequence: [26, 30, 34, 38],
    answer: 42,
    actions: ['+4']
  },
  {
    sequence: [26, 31, 36, 41],
    answer: 46,
    actions: ['+5']
  },
  {
    sequence: [26, 32, 38, 44],
    answer: 50,
    actions: ['+6']
  },
  {
    sequence: [26, 33, 40, 47],
    answer: 54,
    actions: ['+7']
  },
  {
    sequence: [26, 34, 42, 50],
    answer: 58,
    actions: ['+8']
  },
  {
    sequence: [26, 35, 44, 53],
    answer: 62,
    actions: ['+9']
  },
  {
    sequence: [26, 36, 46, 56],
    answer: 66,
    actions: ['+10']
  },
  {
    sequence: [27, 29, 31, 33],
    answer: 35,
    actions: ['+2']
  },
  {
    sequence: [27, 30, 33, 36],
    answer: 39,
    actions: ['+3']
  },
  {
    sequence: [27, 31, 35, 39],
    answer: 43,
    actions: ['+4']
  },
  {
    sequence: [27, 32, 37, 42],
    answer: 47,
    actions: ['+5']
  },
  {
    sequence: [27, 33, 39, 45],
    answer: 51,
    actions: ['+6']
  },
  {
    sequence: [27, 34, 41, 48],
    answer: 55,
    actions: ['+7']
  },
  {
    sequence: [27, 35, 43, 51],
    answer: 59,
    actions: ['+8']
  },
  {
    sequence: [27, 36, 45, 54],
    answer: 63,
    actions: ['+9']
  },
  {
    sequence: [27, 37, 47, 57],
    answer: 67,
    actions: ['+10']
  },
  {
    sequence: [28, 30, 32, 34],
    answer: 36,
    actions: ['+2']
  },
  {
    sequence: [28, 31, 34, 37],
    answer: 40,
    actions: ['+3']
  },
  {
    sequence: [28, 32, 36, 40],
    answer: 44,
    actions: ['+4']
  },
  {
    sequence: [28, 33, 38, 43],
    answer: 48,
    actions: ['+5']
  },
  {
    sequence: [28, 34, 40, 46],
    answer: 52,
    actions: ['+6']
  },
  {
    sequence: [28, 35, 42, 49],
    answer: 56,
    actions: ['+7']
  },
  {
    sequence: [28, 36, 44, 52],
    answer: 60,
    actions: ['+8']
  },
  {
    sequence: [28, 37, 46, 55],
    answer: 64,
    actions: ['+9']
  },
  {
    sequence: [28, 38, 48, 58],
    answer: 68,
    actions: ['+10']
  },
  {
    sequence: [29, 31, 33, 35],
    answer: 37,
    actions: ['+2']
  },
  {
    sequence: [29, 32, 35, 38],
    answer: 41,
    actions: ['+3']
  },
  {
    sequence: [29, 33, 37, 41],
    answer: 45,
    actions: ['+4']
  },
  {
    sequence: [29, 34, 39, 44],
    answer: 49,
    actions: ['+5']
  },
  {
    sequence: [29, 35, 41, 47],
    answer: 53,
    actions: ['+6']
  },
  {
    sequence: [29, 36, 43, 50],
    answer: 57,
    actions: ['+7']
  },
  {
    sequence: [29, 37, 45, 53],
    answer: 61,
    actions: ['+8']
  },
  {
    sequence: [29, 38, 47, 56],
    answer: 65,
    actions: ['+9']
  },
  {
    sequence: [29, 39, 49, 59],
    answer: 69,
    actions: ['+10']
  },
  {
    sequence: [30, 32, 34, 36],
    answer: 38,
    actions: ['+2']
  },
  {
    sequence: [30, 33, 36, 39],
    answer: 42,
    actions: ['+3']
  },
  {
    sequence: [30, 34, 38, 42],
    answer: 46,
    actions: ['+4']
  },
  {
    sequence: [30, 35, 40, 45],
    answer: 50,
    actions: ['+5']
  },
  {
    sequence: [30, 36, 42, 48],
    answer: 54,
    actions: ['+6']
  },
  {
    sequence: [30, 37, 44, 51],
    answer: 58,
    actions: ['+7']
  },
  {
    sequence: [30, 38, 46, 54],
    answer: 62,
    actions: ['+8']
  },
  {
    sequence: [30, 39, 48, 57],
    answer: 66,
    actions: ['+9']
  },
  {
    sequence: [30, 40, 50, 60],
    answer: 70,
    actions: ['+10']
  },
  {
    sequence: [31, 33, 35, 37],
    answer: 39,
    actions: ['+2']
  },
  {
    sequence: [31, 34, 37, 40],
    answer: 43,
    actions: ['+3']
  },
  {
    sequence: [31, 35, 39, 43],
    answer: 47,
    actions: ['+4']
  },
  {
    sequence: [31, 36, 41, 46],
    answer: 51,
    actions: ['+5']
  },
  {
    sequence: [31, 37, 43, 49],
    answer: 55,
    actions: ['+6']
  },
  {
    sequence: [31, 38, 45, 52],
    answer: 59,
    actions: ['+7']
  },
  {
    sequence: [31, 39, 47, 55],
    answer: 63,
    actions: ['+8']
  },
  {
    sequence: [31, 40, 49, 58],
    answer: 67,
    actions: ['+9']
  },
  {
    sequence: [31, 41, 51, 61],
    answer: 71,
    actions: ['+10']
  },
  {
    sequence: [32, 34, 36, 38],
    answer: 40,
    actions: ['+2']
  },
  {
    sequence: [32, 35, 38, 41],
    answer: 44,
    actions: ['+3']
  },
  {
    sequence: [32, 36, 40, 44],
    answer: 48,
    actions: ['+4']
  },
  {
    sequence: [32, 37, 42, 47],
    answer: 52,
    actions: ['+5']
  },
  {
    sequence: [32, 38, 44, 50],
    answer: 56,
    actions: ['+6']
  },
  {
    sequence: [32, 39, 46, 53],
    answer: 60,
    actions: ['+7']
  },
  {
    sequence: [32, 40, 48, 56],
    answer: 64,
    actions: ['+8']
  },
  {
    sequence: [32, 41, 50, 59],
    answer: 68,
    actions: ['+9']
  },
  {
    sequence: [32, 42, 52, 62],
    answer: 72,
    actions: ['+10']
  },
  {
    sequence: [33, 35, 37, 39],
    answer: 41,
    actions: ['+2']
  },
  {
    sequence: [33, 36, 39, 42],
    answer: 45,
    actions: ['+3']
  },
  {
    sequence: [33, 37, 41, 45],
    answer: 49,
    actions: ['+4']
  },
  {
    sequence: [33, 38, 43, 48],
    answer: 53,
    actions: ['+5']
  },
  {
    sequence: [33, 39, 45, 51],
    answer: 57,
    actions: ['+6']
  },
  {
    sequence: [33, 40, 47, 54],
    answer: 61,
    actions: ['+7']
  },
  {
    sequence: [33, 41, 49, 57],
    answer: 65,
    actions: ['+8']
  },
  {
    sequence: [33, 42, 51, 60],
    answer: 69,
    actions: ['+9']
  },
  {
    sequence: [33, 43, 53, 63],
    answer: 73,
    actions: ['+10']
  },
  {
    sequence: [34, 36, 38, 40],
    answer: 42,
    actions: ['+2']
  },
  {
    sequence: [34, 37, 40, 43],
    answer: 46,
    actions: ['+3']
  },
  {
    sequence: [34, 38, 42, 46],
    answer: 50,
    actions: ['+4']
  },
  {
    sequence: [34, 39, 44, 49],
    answer: 54,
    actions: ['+5']
  },
  {
    sequence: [34, 40, 46, 52],
    answer: 58,
    actions: ['+6']
  },
  {
    sequence: [34, 41, 48, 55],
    answer: 62,
    actions: ['+7']
  },
  {
    sequence: [34, 42, 50, 58],
    answer: 66,
    actions: ['+8']
  },
  {
    sequence: [34, 43, 52, 61],
    answer: 70,
    actions: ['+9']
  },
  {
    sequence: [34, 44, 54, 64],
    answer: 74,
    actions: ['+10']
  },
  {
    sequence: [35, 37, 39, 41],
    answer: 43,
    actions: ['+2']
  },
  {
    sequence: [35, 38, 41, 44],
    answer: 47,
    actions: ['+3']
  },
  {
    sequence: [35, 39, 43, 47],
    answer: 51,
    actions: ['+4']
  },
  {
    sequence: [35, 40, 45, 50],
    answer: 55,
    actions: ['+5']
  },
  {
    sequence: [35, 41, 47, 53],
    answer: 59,
    actions: ['+6']
  },
  {
    sequence: [35, 42, 49, 56],
    answer: 63,
    actions: ['+7']
  },
  {
    sequence: [35, 43, 51, 59],
    answer: 67,
    actions: ['+8']
  },
  {
    sequence: [35, 44, 53, 62],
    answer: 71,
    actions: ['+9']
  },
  {
    sequence: [35, 45, 55, 65],
    answer: 75,
    actions: ['+10']
  },
  {
    sequence: [36, 38, 40, 42],
    answer: 44,
    actions: ['+2']
  },
  {
    sequence: [36, 39, 42, 45],
    answer: 48,
    actions: ['+3']
  },
  {
    sequence: [36, 40, 44, 48],
    answer: 52,
    actions: ['+4']
  },
  {
    sequence: [36, 41, 46, 51],
    answer: 56,
    actions: ['+5']
  },
  {
    sequence: [36, 42, 48, 54],
    answer: 60,
    actions: ['+6']
  },
  {
    sequence: [36, 43, 50, 57],
    answer: 64,
    actions: ['+7']
  },
  {
    sequence: [36, 44, 52, 60],
    answer: 68,
    actions: ['+8']
  },
  {
    sequence: [36, 45, 54, 63],
    answer: 72,
    actions: ['+9']
  },
  {
    sequence: [36, 46, 56, 66],
    answer: 76,
    actions: ['+10']
  },
  {
    sequence: [37, 39, 41, 43],
    answer: 45,
    actions: ['+2']
  },
  {
    sequence: [37, 40, 43, 46],
    answer: 49,
    actions: ['+3']
  },
  {
    sequence: [37, 41, 45, 49],
    answer: 53,
    actions: ['+4']
  },
  {
    sequence: [37, 42, 47, 52],
    answer: 57,
    actions: ['+5']
  },
  {
    sequence: [37, 43, 49, 55],
    answer: 61,
    actions: ['+6']
  },
  {
    sequence: [37, 44, 51, 58],
    answer: 65,
    actions: ['+7']
  },
  {
    sequence: [37, 45, 53, 61],
    answer: 69,
    actions: ['+8']
  },
  {
    sequence: [37, 46, 55, 64],
    answer: 73,
    actions: ['+9']
  },
  {
    sequence: [37, 47, 57, 67],
    answer: 77,
    actions: ['+10']
  },
  {
    sequence: [38, 40, 42, 44],
    answer: 46,
    actions: ['+2']
  },
  {
    sequence: [38, 41, 44, 47],
    answer: 50,
    actions: ['+3']
  },
  {
    sequence: [38, 42, 46, 50],
    answer: 54,
    actions: ['+4']
  },
  {
    sequence: [38, 43, 48, 53],
    answer: 58,
    actions: ['+5']
  },
  {
    sequence: [38, 44, 50, 56],
    answer: 62,
    actions: ['+6']
  },
  {
    sequence: [38, 45, 52, 59],
    answer: 66,
    actions: ['+7']
  },
  {
    sequence: [38, 46, 54, 62],
    answer: 70,
    actions: ['+8']
  },
  {
    sequence: [38, 47, 56, 65],
    answer: 74,
    actions: ['+9']
  },
  {
    sequence: [38, 48, 58, 68],
    answer: 78,
    actions: ['+10']
  },
  {
    sequence: [39, 41, 43, 45],
    answer: 47,
    actions: ['+2']
  },
  {
    sequence: [39, 42, 45, 48],
    answer: 51,
    actions: ['+3']
  },
  {
    sequence: [39, 43, 47, 51],
    answer: 55,
    actions: ['+4']
  },
  {
    sequence: [39, 44, 49, 54],
    answer: 59,
    actions: ['+5']
  },
  {
    sequence: [39, 45, 51, 57],
    answer: 63,
    actions: ['+6']
  },
  {
    sequence: [39, 46, 53, 60],
    answer: 67,
    actions: ['+7']
  },
  {
    sequence: [39, 47, 55, 63],
    answer: 71,
    actions: ['+8']
  },
  {
    sequence: [39, 48, 57, 66],
    answer: 75,
    actions: ['+9']
  },
  {
    sequence: [39, 49, 59, 69],
    answer: 79,
    actions: ['+10']
  },
  {
    sequence: [40, 42, 44, 46],
    answer: 48,
    actions: ['+2']
  },
  {
    sequence: [40, 43, 46, 49],
    answer: 52,
    actions: ['+3']
  },
  {
    sequence: [40, 44, 48, 52],
    answer: 56,
    actions: ['+4']
  },
  {
    sequence: [40, 45, 50, 55],
    answer: 60,
    actions: ['+5']
  },
  {
    sequence: [40, 46, 52, 58],
    answer: 64,
    actions: ['+6']
  },
  {
    sequence: [40, 47, 54, 61],
    answer: 68,
    actions: ['+7']
  },
  {
    sequence: [40, 48, 56, 64],
    answer: 72,
    actions: ['+8']
  },
  {
    sequence: [40, 49, 58, 67],
    answer: 76,
    actions: ['+9']
  },
  {
    sequence: [40, 50, 60, 70],
    answer: 80,
    actions: ['+10']
  },
  {
    sequence: [41, 43, 45, 47],
    answer: 49,
    actions: ['+2']
  },
  {
    sequence: [41, 44, 47, 50],
    answer: 53,
    actions: ['+3']
  },
  {
    sequence: [41, 45, 49, 53],
    answer: 57,
    actions: ['+4']
  },
  {
    sequence: [41, 46, 51, 56],
    answer: 61,
    actions: ['+5']
  },
  {
    sequence: [41, 47, 53, 59],
    answer: 65,
    actions: ['+6']
  },
  {
    sequence: [41, 48, 55, 62],
    answer: 69,
    actions: ['+7']
  },
  {
    sequence: [41, 49, 57, 65],
    answer: 73,
    actions: ['+8']
  },
  {
    sequence: [41, 50, 59, 68],
    answer: 77,
    actions: ['+9']
  },
  {
    sequence: [41, 51, 61, 71],
    answer: 81,
    actions: ['+10']
  },
  {
    sequence: [42, 44, 46, 48],
    answer: 50,
    actions: ['+2']
  },
  {
    sequence: [42, 45, 48, 51],
    answer: 54,
    actions: ['+3']
  },
  {
    sequence: [42, 46, 50, 54],
    answer: 58,
    actions: ['+4']
  },
  {
    sequence: [42, 47, 52, 57],
    answer: 62,
    actions: ['+5']
  },
  {
    sequence: [42, 48, 54, 60],
    answer: 66,
    actions: ['+6']
  },
  {
    sequence: [42, 49, 56, 63],
    answer: 70,
    actions: ['+7']
  },
  {
    sequence: [42, 50, 58, 66],
    answer: 74,
    actions: ['+8']
  },
  {
    sequence: [42, 51, 60, 69],
    answer: 78,
    actions: ['+9']
  },
  {
    sequence: [42, 52, 62, 72],
    answer: 82,
    actions: ['+10']
  },
  {
    sequence: [43, 45, 47, 49],
    answer: 51,
    actions: ['+2']
  },
  {
    sequence: [43, 46, 49, 52],
    answer: 55,
    actions: ['+3']
  },
  {
    sequence: [43, 47, 51, 55],
    answer: 59,
    actions: ['+4']
  },
  {
    sequence: [43, 48, 53, 58],
    answer: 63,
    actions: ['+5']
  },
  {
    sequence: [43, 49, 55, 61],
    answer: 67,
    actions: ['+6']
  },
  {
    sequence: [43, 50, 57, 64],
    answer: 71,
    actions: ['+7']
  },
  {
    sequence: [43, 51, 59, 67],
    answer: 75,
    actions: ['+8']
  },
  {
    sequence: [43, 52, 61, 70],
    answer: 79,
    actions: ['+9']
  },
  {
    sequence: [43, 53, 63, 73],
    answer: 83,
    actions: ['+10']
  },
  {
    sequence: [44, 46, 48, 50],
    answer: 52,
    actions: ['+2']
  },
  {
    sequence: [44, 47, 50, 53],
    answer: 56,
    actions: ['+3']
  },
  {
    sequence: [44, 48, 52, 56],
    answer: 60,
    actions: ['+4']
  },
  {
    sequence: [44, 49, 54, 59],
    answer: 64,
    actions: ['+5']
  },
  {
    sequence: [44, 50, 56, 62],
    answer: 68,
    actions: ['+6']
  },
  {
    sequence: [44, 51, 58, 65],
    answer: 72,
    actions: ['+7']
  },
  {
    sequence: [44, 52, 60, 68],
    answer: 76,
    actions: ['+8']
  },
  {
    sequence: [44, 53, 62, 71],
    answer: 80,
    actions: ['+9']
  },
  {
    sequence: [44, 54, 64, 74],
    answer: 84,
    actions: ['+10']
  },
  {
    sequence: [45, 47, 49, 51],
    answer: 53,
    actions: ['+2']
  },
  {
    sequence: [45, 48, 51, 54],
    answer: 57,
    actions: ['+3']
  },
  {
    sequence: [45, 49, 53, 57],
    answer: 61,
    actions: ['+4']
  },
  {
    sequence: [45, 50, 55, 60],
    answer: 65,
    actions: ['+5']
  },
  {
    sequence: [45, 51, 57, 63],
    answer: 69,
    actions: ['+6']
  },
  {
    sequence: [45, 52, 59, 66],
    answer: 73,
    actions: ['+7']
  },
  {
    sequence: [45, 53, 61, 69],
    answer: 77,
    actions: ['+8']
  },
  {
    sequence: [45, 54, 63, 72],
    answer: 81,
    actions: ['+9']
  },
  {
    sequence: [45, 55, 65, 75],
    answer: 85,
    actions: ['+10']
  },
  {
    sequence: [46, 48, 50, 52],
    answer: 54,
    actions: ['+2']
  },
  {
    sequence: [46, 49, 52, 55],
    answer: 58,
    actions: ['+3']
  },
  {
    sequence: [46, 50, 54, 58],
    answer: 62,
    actions: ['+4']
  },
  {
    sequence: [46, 51, 56, 61],
    answer: 66,
    actions: ['+5']
  },
  {
    sequence: [46, 52, 58, 64],
    answer: 70,
    actions: ['+6']
  },
  {
    sequence: [46, 53, 60, 67],
    answer: 74,
    actions: ['+7']
  },
  {
    sequence: [46, 54, 62, 70],
    answer: 78,
    actions: ['+8']
  },
  {
    sequence: [46, 55, 64, 73],
    answer: 82,
    actions: ['+9']
  },
  {
    sequence: [46, 56, 66, 76],
    answer: 86,
    actions: ['+10']
  },
  {
    sequence: [47, 49, 51, 53],
    answer: 55,
    actions: ['+2']
  },
  {
    sequence: [47, 50, 53, 56],
    answer: 59,
    actions: ['+3']
  },
  {
    sequence: [47, 51, 55, 59],
    answer: 63,
    actions: ['+4']
  },
  {
    sequence: [47, 52, 57, 62],
    answer: 67,
    actions: ['+5']
  },
  {
    sequence: [47, 53, 59, 65],
    answer: 71,
    actions: ['+6']
  },
  {
    sequence: [47, 54, 61, 68],
    answer: 75,
    actions: ['+7']
  },
  {
    sequence: [47, 55, 63, 71],
    answer: 79,
    actions: ['+8']
  },
  {
    sequence: [47, 56, 65, 74],
    answer: 83,
    actions: ['+9']
  },
  {
    sequence: [47, 57, 67, 77],
    answer: 87,
    actions: ['+10']
  },
  {
    sequence: [48, 50, 52, 54],
    answer: 56,
    actions: ['+2']
  },
  {
    sequence: [48, 51, 54, 57],
    answer: 60,
    actions: ['+3']
  },
  {
    sequence: [48, 52, 56, 60],
    answer: 64,
    actions: ['+4']
  },
  {
    sequence: [48, 53, 58, 63],
    answer: 68,
    actions: ['+5']
  },
  {
    sequence: [48, 54, 60, 66],
    answer: 72,
    actions: ['+6']
  },
  {
    sequence: [48, 55, 62, 69],
    answer: 76,
    actions: ['+7']
  },
  {
    sequence: [48, 56, 64, 72],
    answer: 80,
    actions: ['+8']
  },
  {
    sequence: [48, 57, 66, 75],
    answer: 84,
    actions: ['+9']
  },
  {
    sequence: [48, 58, 68, 78],
    answer: 88,
    actions: ['+10']
  },
  {
    sequence: [49, 51, 53, 55],
    answer: 57,
    actions: ['+2']
  },
  {
    sequence: [49, 52, 55, 58],
    answer: 61,
    actions: ['+3']
  },
  {
    sequence: [49, 53, 57, 61],
    answer: 65,
    actions: ['+4']
  },
  {
    sequence: [49, 54, 59, 64],
    answer: 69,
    actions: ['+5']
  },
  {
    sequence: [49, 55, 61, 67],
    answer: 73,
    actions: ['+6']
  },
  {
    sequence: [49, 56, 63, 70],
    answer: 77,
    actions: ['+7']
  },
  {
    sequence: [49, 57, 65, 73],
    answer: 81,
    actions: ['+8']
  },
  {
    sequence: [49, 58, 67, 76],
    answer: 85,
    actions: ['+9']
  },
  {
    sequence: [49, 59, 69, 79],
    answer: 89,
    actions: ['+10']
  },
  {
    sequence: [50, 52, 54, 56],
    answer: 58,
    actions: ['+2']
  },
  {
    sequence: [50, 53, 56, 59],
    answer: 62,
    actions: ['+3']
  },
  {
    sequence: [50, 54, 58, 62],
    answer: 66,
    actions: ['+4']
  },
  {
    sequence: [50, 55, 60, 65],
    answer: 70,
    actions: ['+5']
  },
  {
    sequence: [50, 56, 62, 68],
    answer: 74,
    actions: ['+6']
  },
  {
    sequence: [50, 57, 64, 71],
    answer: 78,
    actions: ['+7']
  },
  {
    sequence: [50, 58, 66, 74],
    answer: 82,
    actions: ['+8']
  },
  {
    sequence: [50, 59, 68, 77],
    answer: 86,
    actions: ['+9']
  },
  {
    sequence: [50, 60, 70, 80],
    answer: 90,
    actions: ['+10']
  },
  {
    sequence: [51, 53, 55, 57],
    answer: 59,
    actions: ['+2']
  },
  {
    sequence: [51, 54, 57, 60],
    answer: 63,
    actions: ['+3']
  },
  {
    sequence: [51, 55, 59, 63],
    answer: 67,
    actions: ['+4']
  },
  {
    sequence: [51, 56, 61, 66],
    answer: 71,
    actions: ['+5']
  },
  {
    sequence: [51, 57, 63, 69],
    answer: 75,
    actions: ['+6']
  },
  {
    sequence: [51, 58, 65, 72],
    answer: 79,
    actions: ['+7']
  },
  {
    sequence: [51, 59, 67, 75],
    answer: 83,
    actions: ['+8']
  },
  {
    sequence: [51, 60, 69, 78],
    answer: 87,
    actions: ['+9']
  },
  {
    sequence: [51, 61, 71, 81],
    answer: 91,
    actions: ['+10']
  },
  {
    sequence: [52, 54, 56, 58],
    answer: 60,
    actions: ['+2']
  },
  {
    sequence: [52, 55, 58, 61],
    answer: 64,
    actions: ['+3']
  },
  {
    sequence: [52, 56, 60, 64],
    answer: 68,
    actions: ['+4']
  },
  {
    sequence: [52, 57, 62, 67],
    answer: 72,
    actions: ['+5']
  },
  {
    sequence: [52, 58, 64, 70],
    answer: 76,
    actions: ['+6']
  },
  {
    sequence: [52, 59, 66, 73],
    answer: 80,
    actions: ['+7']
  },
  {
    sequence: [52, 60, 68, 76],
    answer: 84,
    actions: ['+8']
  },
  {
    sequence: [52, 61, 70, 79],
    answer: 88,
    actions: ['+9']
  },
  {
    sequence: [52, 62, 72, 82],
    answer: 92,
    actions: ['+10']
  },
  {
    sequence: [53, 55, 57, 59],
    answer: 61,
    actions: ['+2']
  },
  {
    sequence: [53, 56, 59, 62],
    answer: 65,
    actions: ['+3']
  },
  {
    sequence: [53, 57, 61, 65],
    answer: 69,
    actions: ['+4']
  },
  {
    sequence: [53, 58, 63, 68],
    answer: 73,
    actions: ['+5']
  },
  {
    sequence: [53, 59, 65, 71],
    answer: 77,
    actions: ['+6']
  },
  {
    sequence: [53, 60, 67, 74],
    answer: 81,
    actions: ['+7']
  },
  {
    sequence: [53, 61, 69, 77],
    answer: 85,
    actions: ['+8']
  },
  {
    sequence: [53, 62, 71, 80],
    answer: 89,
    actions: ['+9']
  },
  {
    sequence: [53, 63, 73, 83],
    answer: 93,
    actions: ['+10']
  },
  {
    sequence: [54, 56, 58, 60],
    answer: 62,
    actions: ['+2']
  },
  {
    sequence: [54, 57, 60, 63],
    answer: 66,
    actions: ['+3']
  },
  {
    sequence: [54, 58, 62, 66],
    answer: 70,
    actions: ['+4']
  },
  {
    sequence: [54, 59, 64, 69],
    answer: 74,
    actions: ['+5']
  },
  {
    sequence: [54, 60, 66, 72],
    answer: 78,
    actions: ['+6']
  },
  {
    sequence: [54, 61, 68, 75],
    answer: 82,
    actions: ['+7']
  },
  {
    sequence: [54, 62, 70, 78],
    answer: 86,
    actions: ['+8']
  },
  {
    sequence: [54, 63, 72, 81],
    answer: 90,
    actions: ['+9']
  },
  {
    sequence: [54, 64, 74, 84],
    answer: 94,
    actions: ['+10']
  },
  {
    sequence: [55, 57, 59, 61],
    answer: 63,
    actions: ['+2']
  },
  {
    sequence: [55, 58, 61, 64],
    answer: 67,
    actions: ['+3']
  },
  {
    sequence: [55, 59, 63, 67],
    answer: 71,
    actions: ['+4']
  },
  {
    sequence: [55, 60, 65, 70],
    answer: 75,
    actions: ['+5']
  },
  {
    sequence: [55, 61, 67, 73],
    answer: 79,
    actions: ['+6']
  },
  {
    sequence: [55, 62, 69, 76],
    answer: 83,
    actions: ['+7']
  },
  {
    sequence: [55, 63, 71, 79],
    answer: 87,
    actions: ['+8']
  },
  {
    sequence: [55, 64, 73, 82],
    answer: 91,
    actions: ['+9']
  },
  {
    sequence: [55, 65, 75, 85],
    answer: 95,
    actions: ['+10']
  },
  {
    sequence: [56, 58, 60, 62],
    answer: 64,
    actions: ['+2']
  },
  {
    sequence: [56, 59, 62, 65],
    answer: 68,
    actions: ['+3']
  },
  {
    sequence: [56, 60, 64, 68],
    answer: 72,
    actions: ['+4']
  },
  {
    sequence: [56, 61, 66, 71],
    answer: 76,
    actions: ['+5']
  },
  {
    sequence: [56, 62, 68, 74],
    answer: 80,
    actions: ['+6']
  },
  {
    sequence: [56, 63, 70, 77],
    answer: 84,
    actions: ['+7']
  },
  {
    sequence: [56, 64, 72, 80],
    answer: 88,
    actions: ['+8']
  },
  {
    sequence: [56, 65, 74, 83],
    answer: 92,
    actions: ['+9']
  },
  {
    sequence: [56, 66, 76, 86],
    answer: 96,
    actions: ['+10']
  },
  {
    sequence: [57, 59, 61, 63],
    answer: 65,
    actions: ['+2']
  },
  {
    sequence: [57, 60, 63, 66],
    answer: 69,
    actions: ['+3']
  },
  {
    sequence: [57, 61, 65, 69],
    answer: 73,
    actions: ['+4']
  },
  {
    sequence: [57, 62, 67, 72],
    answer: 77,
    actions: ['+5']
  },
  {
    sequence: [57, 63, 69, 75],
    answer: 81,
    actions: ['+6']
  },
  {
    sequence: [57, 64, 71, 78],
    answer: 85,
    actions: ['+7']
  },
  {
    sequence: [57, 65, 73, 81],
    answer: 89,
    actions: ['+8']
  },
  {
    sequence: [57, 66, 75, 84],
    answer: 93,
    actions: ['+9']
  },
  {
    sequence: [57, 67, 77, 87],
    answer: 97,
    actions: ['+10']
  },
  {
    sequence: [58, 60, 62, 64],
    answer: 66,
    actions: ['+2']
  },
  {
    sequence: [58, 61, 64, 67],
    answer: 70,
    actions: ['+3']
  },
  {
    sequence: [58, 62, 66, 70],
    answer: 74,
    actions: ['+4']
  },
  {
    sequence: [58, 63, 68, 73],
    answer: 78,
    actions: ['+5']
  },
  {
    sequence: [58, 64, 70, 76],
    answer: 82,
    actions: ['+6']
  },
  {
    sequence: [58, 65, 72, 79],
    answer: 86,
    actions: ['+7']
  },
  {
    sequence: [58, 66, 74, 82],
    answer: 90,
    actions: ['+8']
  },
  {
    sequence: [58, 67, 76, 85],
    answer: 94,
    actions: ['+9']
  },
  {
    sequence: [58, 68, 78, 88],
    answer: 98,
    actions: ['+10']
  },
  {
    sequence: [59, 61, 63, 65],
    answer: 67,
    actions: ['+2']
  },
  {
    sequence: [59, 62, 65, 68],
    answer: 71,
    actions: ['+3']
  },
  {
    sequence: [59, 63, 67, 71],
    answer: 75,
    actions: ['+4']
  },
  {
    sequence: [59, 64, 69, 74],
    answer: 79,
    actions: ['+5']
  },
  {
    sequence: [59, 65, 71, 77],
    answer: 83,
    actions: ['+6']
  },
  {
    sequence: [59, 66, 73, 80],
    answer: 87,
    actions: ['+7']
  },
  {
    sequence: [59, 67, 75, 83],
    answer: 91,
    actions: ['+8']
  },
  {
    sequence: [59, 68, 77, 86],
    answer: 95,
    actions: ['+9']
  },
  {
    sequence: [59, 69, 79, 89],
    answer: 99,
    actions: ['+10']
  },
  {
    sequence: [60, 62, 64, 66],
    answer: 68,
    actions: ['+2']
  },
  {
    sequence: [60, 63, 66, 69],
    answer: 72,
    actions: ['+3']
  },
  {
    sequence: [60, 64, 68, 72],
    answer: 76,
    actions: ['+4']
  },
  {
    sequence: [60, 65, 70, 75],
    answer: 80,
    actions: ['+5']
  },
  {
    sequence: [60, 66, 72, 78],
    answer: 84,
    actions: ['+6']
  },
  {
    sequence: [60, 67, 74, 81],
    answer: 88,
    actions: ['+7']
  },
  {
    sequence: [60, 68, 76, 84],
    answer: 92,
    actions: ['+8']
  },
  {
    sequence: [60, 69, 78, 87],
    answer: 96,
    actions: ['+9']
  },
  {
    sequence: [60, 70, 80, 90],
    answer: 100,
    actions: ['+10']
  },
  {
    sequence: [70, 72, 74, 76],
    answer: 78,
    actions: ['+2']
  },
  {
    sequence: [70, 73, 76, 79],
    answer: 82,
    actions: ['+3']
  },
  {
    sequence: [70, 74, 78, 82],
    answer: 86,
    actions: ['+4']
  },
  {
    sequence: [70, 75, 80, 85],
    answer: 90,
    actions: ['+5']
  },
  {
    sequence: [70, 76, 82, 88],
    answer: 94,
    actions: ['+6']
  },
  {
    sequence: [70, 77, 84, 91],
    answer: 98,
    actions: ['+7']
  },
  {
    sequence: [70, 78, 86, 94],
    answer: 102,
    actions: ['+8']
  },
  {
    sequence: [70, 79, 88, 97],
    answer: 106,
    actions: ['+9']
  },
  {
    sequence: [70, 80, 90, 100],
    answer: 110,
    actions: ['+10']
  }
];
