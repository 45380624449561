import { SET_POPUP, SET_QUEUE } from './constants';

const initialState = {
  popupName: null,
  popupQueue: []
};

function page(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_POPUP:
      return { ...state, popupName: payload.popupName };

    case `${ SET_QUEUE }_FULFILLED`:
      return { ...state, popupQueue: payload }

    default:
      return state;
  }
}

export default page;
