export default {
  shapes: [ 5, 2, 1, 6, 7, 4, 3 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 150, y: 77 },
      tablet: { x: 164, y: 69 },
      mobile: { x: 112, y: 48 }
    },
    {
      desktop: { x: 150, y: 167 },
      tablet: { x: 164, y: 149 },
      mobile: { x: 112, y: 108 }
    },
    {
      desktop: { x: 219.82, y: 167 },
      tablet: { x: 229.28, y: 149 },
      mobile: { x: 157.21, y: 108 }
    },
    {
      desktop: { x: 219.82, y: 230.46 },
      tablet: { x: 229.28, y: 204.85 },
      mobile: { x: 157.21, y: 149.64 }
    },
    {
      desktop: { x: 283.46, y: 230.46 },
      tablet: { x: 285, y: 204.85 },
      mobile: { x: 199, y: 149.64 }
    },
    {
      desktop: { x: 283.46, y: 263.72 },
      tablet: { x: 285, y: 234.72 },
      mobile: { x: 199, y: 172.79 }
    },
    {
      desktop: { x: 220, y: 327.18 },
      tablet: { x: 229, y: 290.72 },
      mobile: { x: 157, y: 214.79 }
    },
    {
      desktop: { x: 283.46, y: 327 },
      tablet: { x: 285, y: 290.72 },
      mobile: { x: 199, y: 214.79 }
    },
    {
      desktop: { x: 283.46, y: 380.82 },
      tablet: { x: 285, y: 345.14 },
      mobile: { x: 199, y: 253.85 }
    },
    {
      desktop: { x: 246, y: 418.28 },
      tablet: { x: 252, y: 378.14 },
      mobile: { x: 174, y: 278.85 }
    },
    {
      desktop: { x: 372.71, y: 418.28 },
      tablet: { x: 365.14, y: 378.14 },
      mobile: { x: 258.15, y: 278.85 }
    },
    {
      desktop: { x: 418, y: 372.75 },
      tablet: { x: 405, y: 338 },
      mobile: { x: 288, y: 249 }
    },
    {
      desktop: { x: 373.28, y: 328.28 },
      tablet: { x: 365.14, y: 298.14 },
      mobile: { x: 258.85, y: 219.85 }
    },
    {
      desktop: { x: 373.28, y: 291.18 },
      tablet: { x: 365.14, y: 266.12 },
      mobile: { x: 258.85, y: 193.85 }
    },
    {
      desktop: { x: 283.46, y: 201 },
      tablet: { x: 285.85, y: 186.85 },
      mobile: { x: 199.64, y: 134.64 }
    },
    {
      desktop: { x: 283.46, y: 166.82 },
      tablet: { x: 285.85, y: 148.28 },
      mobile: { x: 199.64, y: 107.21 }
    },
    {
      desktop: { x: 240.21, y: 166.82 },
      tablet: { x: 243.72, y: 148.28 },
      mobile: { x: 171.79, y: 107.21 }
    },
    {
      desktop: { x: 284, y: 124 },
      tablet: { x: 283, y: 109 },
      mobile: { x: 201, y: 78 }
    },
    {
      desktop: { x: 284, y: 33 },
      tablet: { x: 283, y: 29 },
      mobile: { x: 201, y: 18 }
    },
    {
      desktop: { x: 239, y: 79 },
      tablet: { x: 243, y: 69 },
      mobile: { x: 171, y: 48 }
    },
    {
      desktop: { x: 239, y: 166 },
      tablet: { x: 243, y: 148 },
      mobile: { x: 171, y: 107 }
    }
  ],
  outlines: [
    {
      desktop: { x: 240, y: 77 },
      tablet: { x: 244, y: 69 },
      mobile: { x: 172, y: 48 },
      rotation: 90
    },
    {
      desktop: { x: 239, y: 33 },
      tablet: { x: 243, y: 29 },
      mobile: { x: 171, y: 18 },
      rotation: 0
    },
    {
      desktop: { x: 188, y: 198.64 },
      tablet: { x: 201, y: 176.57 },
      mobile: { x: 136, y: 128.43 },
      rotation: 315
    },
    {
      desktop: { x: 283.46, y: 201 },
      tablet: { x: 285, y: 186 },
      mobile: { x: 199, y: 134 },
      rotation: 0
    },
    {
      desktop: { x: 373.28, y: 291 },
      tablet: { x: 365.14, y: 265 },
      mobile: { x: 258.85, y: 194 },
      rotation: 45
    },
    {
      desktop: { x: 418, y: 418 },
      tablet: { x: 405, y: 378 },
      mobile: { x: 288, y: 279 },
      rotation: 180
    },
    {
      desktop: { x: 251.82, y: 359 },
      tablet: { x: 257.28, y: 319 },
      mobile: { x: 178.21, y: 236 },
      rotation: 225
    }
  ]
};
