export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 289.14,
        y: 100.14
      },
      tablet: {
        x: 280.28,
        y: 102.85
      },
      mobile: {
        x: 195.21,
        y: 71.64
      }
    },
    {
      desktop: {
        x: 279.82,
        y: 109.46
      },
      tablet: {
        x: 288.57,
        y: 94.57
      },
      mobile: {
        x: 201.43,
        y: 65.43
      }
    },
    {
      desktop: {
        x: 279.64,
        y: 46
      },
      tablet: {
        x: 313.5,
        y: 119.5
      },
      mobile: {
        x: 221.5,
        y: 85.5
      }
    },
    {
      desktop: {
        x: 343.46,
        y: 45.82
      },
      tablet: {
        x: 234,
        y: 199
      },
      mobile: {
        x: 163,
        y: 144
      }
    },
    {
      desktop: {
        x: 334.14,
        y: 55.14
      },
      tablet: {
        x: 154,
        y: 199
      },
      mobile: {
        x: 102,
        y: 144
      }
    },
    {
      desktop: {
        x: 379,
        y: 100
      },
      tablet: {
        x: 194,
        y: 239
      },
      mobile: {
        x: 133,
        y: 175
      }
    },
    {
      desktop: {
        x: 334,
        y: 145
      },
      tablet: {
        x: 194,
        y: 397
      },
      mobile: {
        x: 133,
        y: 293
      }
    },
    {
      desktop: {
        x: 361,
        y: 172
      },
      tablet: {
        x: 274,
        y: 397
      },
      mobile: {
        x: 193,
        y: 293
      }
    },
    {
      desktop: {
        x: 450,
        y: 172
      },
      tablet: {
        x: 234,
        y: 357
      },
      mobile: {
        x: 163,
        y: 263
      }
    },
    {
      desktop: {
        x: 405.23,
        y: 217
      },
      tablet: {
        x: 234,
        y: 279
      },
      mobile: {
        x: 163,
        y: 203
      }
    },
    {
      desktop: {
        x: 316,
        y: 217
      },
      tablet: {
        x: 314,
        y: 199
      },
      mobile: {
        x: 221,
        y: 145
      }
    },
    {
      desktop: {
        x: 226,
        y: 307
      },
      tablet: {
        x: 393,
        y: 199
      },
      mobile: {
        x: 282,
        y: 145
      }
    },
    {
      desktop: {
        x: 226,
        y: 397
      },
      tablet: {
        x: 433,
        y: 159
      },
      mobile: {
        x: 312,
        y: 115
      }
    },
    {
      desktop: {
        x: 271,
        y: 442
      },
      tablet: {
        x: 354,
        y: 159
      },
      mobile: {
        x: 252,
        y: 115
      }
    },
    {
      desktop: {
        x: 181,
        y: 442
      },
      tablet: {
        x: 328.5,
        y: 133.5
      },
      mobile: {
        x: 231.5,
        y: 94.5
      }
    },
    {
      desktop: {
        x: 181,
        y: 262
      },
      tablet: {
        x: 368,
        y: 94
      },
      mobile: {
        x: 261,
        y: 65
      }
    },
    {
      desktop: {
        x: 136,
        y: 217
      },
      tablet: {
        x: 328.57,
        y: 54.57
      },
      mobile: {
        x: 231.43,
        y: 35.43
      }
    },
    {
      desktop: {
        x: 226,
        y: 217
      },
      tablet: {
        x: 336.85,
        y: 46.28
      },
      mobile: {
        x: 237.64,
        y: 29.21
      }
    },
    {
      desktop: {
        x: 316,
        y: 127
      },
      tablet: {
        x: 280.28,
        y: 46.28
      },
      mobile: {
        x: 195.21,
        y: 29.21
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 181,
        y: 262,
        rotation: -0
      },
      tablet: {
        x: 194,
        y: 238,
        rotation: -0
      },
      mobile: {
        x: 133,
        y: 174,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 316,
        y: 217,
        rotation: -90
      },
      tablet: {
        x: 314,
        y: 199,
        rotation: -90
      },
      mobile: {
        x: 222,
        y: 144,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 226,
        y: 217,
        rotation: 90
      },
      tablet: {
        x: 234,
        y: 199,
        rotation: 90
      },
      mobile: {
        x: 162,
        y: 145,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 271,
        y: 352,
        rotation: -90
      },
      tablet: {
        x: 274,
        y: 317,
        rotation: -90
      },
      mobile: {
        x: 193,
        y: 233,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 311.64,
        y: 14,
        rotation: -45
      },
      tablet: {
        x: 308.57,
        y: 18,
        rotation: -45
      },
      mobile: {
        x: 216.43,
        y: 8,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 360,
        y: 217,
        rotation: 90
      },
      tablet: {
        x: 353,
        y: 199,
        rotation: 90
      },
      mobile: {
        x: 252,
        y: 145,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 289,
        y: 55,
        rotation: 0
      },
      tablet: {
        x: 288,
        y: 54,
        rotation: 0
      },
      mobile: {
        x: 201,
        y: 35,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
