export default (blocksArray, blocksInLine) => {
  const correctImagesOrder = [];
  for (let m = 0; m < blocksArray.length / blocksInLine; m++) {
    correctImagesOrder.push(blocksArray.slice(m * blocksInLine, (m + 1) * blocksInLine));
  }

  // filling starting cards order
  const currentImagesOrder = [];
  for (let i = 0; i < correctImagesOrder.length; i++) {
    currentImagesOrder[i] = [...correctImagesOrder[i]].fill(-1);
  }
  return { correctImagesOrder, currentImagesOrder };
};
