export default {
  shapes: [4, 7, 6, 1, 5, 3, 8],
  offset: {
    tablet: 30,
    mobile: 35
  },
  outlines: [
    {
      id: 4,
      rotation: 270,
      desktop: {
        x: 311,
        y: 146
      },
      tablet: {
        x: 293,
        y: 136
      },
      mobile: {
        x: 196.64,
        y: 94
      }
    },
    {
      id: 7,
      rotation: 270,
      desktop: {
        x: 240,
        y: 209
      },
      tablet: {
        x: 232,
        y: 193
      },
      mobile: {
        x: 152.64,
        y: 139
      }
    },
    {
      id: 6,
      rotation: 225,
      desktop: {
        x: 239.64,
        y: 335.92
      },
      tablet: {
        x: 232.57,
        y: 305.71
      },
      mobile: {
        x: 153.07,
        y: 223.28
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 271,
        y: 259
      },
      tablet: {
        x: 261,
        y: 235
      },
      mobile: {
        x: 176.64,
        y: 168
      }
    },
    {
      id: 5,
      rotation: 225,
      desktop: {
        x: 355.64,
        y: 166.28
      },
      tablet: {
        x: 332.57,
        y: 153.14
      },
      mobile: {
        x: 227.07,
        y: 106.85
      }
    },
    {
      id: 3,
      rotation: 315,
      desktop: {
        x: 270,
        y: 365.82
      },
      tablet: {
        x: 265,
        y: 334
      },
      mobile: {
        x: 181.64,
        y: 244
      }
    },
    {
      id: 8,
      rotation: 45,
      desktop: {
        x: 240.46,
        y: 271
      },
      tablet: {
        x: 232.85,
        y: 249
      },
      mobile: {
        x: 153.64,
        y: 180.2
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 355.64,
        y: 39
      },
      tablet: {
        x: 332.56,
        y: 40
      },
      mobile: {
        x: 227.06,
        y: 22
      }
    },
    {
      desktop: {
        x: 292,
        y: 102.64
      },
      tablet: {
        x: 276,
        y: 96.57
      },
      mobile: {
        x: 184.64,
        y: 64.43
      }
    },
    {
      desktop: {
        x: 312.65,
        y: 102.64
      },
      tablet: {
        x: 293.56,
        y: 96.57
      },
      mobile: {
        x: 197.06,
        y: 64.43
      }
    },
    {
      desktop: {
        x: 329.54999999999995,
        y: 119.45
      },
      tablet: {
        x: 311,
        y: 114
      },
      mobile: {
        x: 212.14,
        y: 79.5
      }
    },
    {
      desktop: {
        x: 239.64,
        y: 209.35
      },
      tablet: {
        x: 232.56,
        y: 192.38
      },
      mobile: {
        x: 153.06,
        y: 138.57
      }
    },
    {
      desktop: {
        x: 239.64,
        y: 271.82
      },
      tablet: {
        x: 232.56,
        y: 249.28
      },
      mobile: {
        x: 153.06,
        y: 180.77
      }
    },
    {
      desktop: {
        x: 176.82,
        y: 334.64
      },
      tablet: {
        x: 176.28,
        y: 305.57
      },
      mobile: {
        x: 111.21,
        y: 222.63
      }
    },
    {
      desktop: {
        x: 176.82,
        y: 398.28
      },
      tablet: {
        x: 176.28,
        y: 362.14
      },
      mobile: {
        x: 111.21,
        y: 265.05
      }
    },
    {
      desktop: {
        x: 271.28,
        y: 304.28
      },
      tablet: {
        x: 262.13,
        y: 276.14
      },
      mobile: {
        x: 177.49,
        y: 198.85
      }
    },
    {
      desktop: {
        x: 301.82,
        y: 334.82
      },
      tablet: {
        x: 293.28,
        y: 307.28
      },
      mobile: {
        x: 202.85,
        y: 224.21
      }
    },
    {
      desktop: {
        x: 302,
        y: 397.82
      },
      tablet: {
        x: 293.28,
        y: 362.28
      },
      mobile: {
        x: 202.85,
        y: 265.21000000000004
      }
    },
    {
      desktop: {
        x: 365.46,
        y: 397.64
      },
      tablet: {
        x: 349.85,
        y: 362.28
      },
      mobile: {
        x: 245.27999999999997,
        y: 265.21000000000004
      }
    },
    {
      desktop: {
        x: 316.40999999999997,
        y: 348.59
      },
      tablet: {
        x: 301.78,
        y: 314.22
      },
      mobile: {
        x: 207.35,
        y: 227.29
      }
    },
    {
      desktop: {
        x: 361,
        y: 304
      },
      tablet: {
        x: 341,
        y: 275
      },
      mobile: {
        x: 236.64,
        y: 198
      }
    },
    {
      desktop: {
        x: 316.28,
        y: 259.28
      },
      tablet: {
        x: 302.13,
        y: 236.14
      },
      mobile: {
        x: 207.49,
        y: 168.85
      }
    },
    {
      desktop: {
        x: 366.56,
        y: 209
      },
      tablet: {
        x: 345.27,
        y: 193
      },
      mobile: {
        x: 237.33999999999997,
        y: 139
      }
    },
    {
      desktop: {
        x: 420,
        y: 209
      },
      tablet: {
        x: 392,
        y: 193
      },
      mobile: {
        x: 272.64,
        y: 139
      }
    },
    {
      desktop: {
        x: 356.62,
        y: 145.62
      },
      tablet: {
        x: 334,
        y: 135
      },
      mobile: {
        x: 227.14,
        y: 93.5
      }
    },
    {
      desktop: {
        x: 399.37,
        y: 102.64
      },
      tablet: {
        x: 372.43,
        y: 96.57
      },
      mobile: {
        x: 256.21,
        y: 64.43
      }
    },
    {
      desktop: {
        x: 419.28,
        y: 102.64
      },
      tablet: {
        x: 389.13,
        y: 96.57
      },
      mobile: {
        x: 269.49,
        y: 64.43
      }
    }
  ]
};
