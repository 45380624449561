export default {
  image: require('~/static/trainers/funnyPoints/iceCreme.png'),
  width: 800,
  height: 919,
  offsetX: 15,
  points: [
    { id: 1,  x: -89,  y: 559 },
    { id: 2,  x: -128, y: 552 },
    { id: 3,  x: -251, y: 567 },
    { id: 4,  x: -316, y: 464 },
    { id: 5,  x: -277, y: 385 },
    { id: 6,  x: -192, y: 368 },
    { id: 7,  x: -197, y: 300 },
    { id: 8,  x: -148, y: 251 },
    { id: 9,  x: -85,  y: 266 },
    { id: 10, x: -66,  y: 200 },
    { id: 11, x: 19,   y: 153 },
    { id: 12, x: 177,  y: 234 },
    { id: 13, x: 175,  y: 352 },
    { id: 14, x: 233,  y: 342 },
    { id: 15, x: 326,  y: 406 },
    { id: 16, x: 289,  y: 520 },
    { id: 17, x: 222,  y: 523 },
    { id: 18, x: 224,  y: 589 },
    { id: 19, x: 170,  y: 612 },
    { id: 20, x: 119,  y: 581 }
  ]
};
