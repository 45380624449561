import React from 'react';
import OpenMapLocations from '~/components/OpenMapLocations/OpenMapLocations';

import './openMap.scss'

const OpenMap = () => {
  return (
    <section className="open-map">
      <h2 className="open-map__title">
        В школе тебя ждут увлекательные миссии по всему миру
      </h2>

      <div className="open-map__holder">
        <OpenMapLocations />
      </div>
    </section>
  );
}

export default OpenMap
