import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import Page from '~/containers/Page/Page';
import TrainerCard from '~/components/TrainerCard/TrainerCard';

import detectDevice from '~/services/detect-device';

import { BIG_SIZE, MIDDLE_SIZE, MINI_SIZE } from './constants';

import * as trainersActions from '~/store/trainers/actions';
import { getFilteredTrainersArray } from '~/store/trainers/selector';

import './trainersPage.scss';

const TrainersPage = (props) => {
  const { trainersFilter, changeFilter, trainers, count } = props;
  const [ trainersForView, setTrainersForView ] = useState(trainers);
  const { isMobile, width } = detectDevice();
  const classesCard = cx('trainers-page__trainer-card', {
    'trainers-page__trainer-card_mobile': isMobile
  });

  useEffect(() => {
    setTrainersForView(trainers);
  }, [ trainersFilter ]);

  const onFilterClickHandler = (value) => {
    const isAllProperty = value === 'all';
    let currentFilters = {};
    setTrainersForView([]);

    Object.keys(trainersFilter).forEach((filter) => {
      currentFilters[filter] = { ...trainersFilter[filter] };
    });

    if (isAllProperty) {
      Object.keys(currentFilters).forEach((filter) => {
        if (filter === 'all') {
          currentFilters[filter].active = true;
        } else {
          currentFilters[filter].active = false;
        }
      });
    } else {
      currentFilters[value].active = !currentFilters[value].active;
      const countSkills = Object.keys(currentFilters).filter((filter) => currentFilters[filter].active);

      if (countSkills.length) {
        currentFilters.all.active = false;
      } else {
        currentFilters.all.active = true;
      }
    }

    changeFilter(currentFilters);
  };

  const renderFilter = (property, index) => {
    const isAllProperty = property === 'all';
    let className = 'trainers-page__filter-button';

    if (!trainersFilter.all.active || isAllProperty) {
      className += trainersFilter[property].active ? `_${property}` : '';
    }

    return (
      <button
        className={ className }
        onClick={ () => onFilterClickHandler(property) }
        key={ index }
        type="button"
      >
        <span className="trainers-page__filter-name">{ trainersFilter[property].text }</span>
        { !isAllProperty && trainersFilter && (
          <span className={`trainers-page__filter-count trainers-page__filter-count_${property}`}>
            { count[property] || 0 }
          </span>
        )}
      </button>
    )
  }

  const items = trainersForView.map((item, index) => {
    const { id, type, previewUrl, tooltipAnnounce, params, eventCategory } = item;
    const { name, maxSkills } = params;

    return (
      <TrainerCard
        className={ classesCard }
        key={ index }
        id={ id }
        type={ type }
        title={ name }
        skillPoints={ maxSkills }
        previewUrl={ previewUrl }
        tooltipAnnounce={ tooltipAnnounce }
        isMobile={ isMobile }
        eventCategory={ eventCategory }
        mobileHeight={ width > BIG_SIZE ? MIDDLE_SIZE : MINI_SIZE }
      />
    );
  });

  return (
    <Page>
      <div className="trainers-page">
        <div className="trainers-page__container">
          <h1 className="trainers-page__title">Тренажеры</h1>
          <nav className="trainers-page__filter">
            {
              Object.keys(trainersFilter).map((property, index) => renderFilter(property, index))
            }
          </nav>
          <div className="trainers-page__preview-cards-container">{ items }</div>
        </div>
      </div>
    </Page>
  );
}

function mapStateToProps(state) {
  return {
    // longMemory нельзя запускать из каталога
    count: state.trainers.count,
    trainers: getFilteredTrainersArray(state).filter((item) => item.type !== 'longMemory'),
    trainersFilter: state.trainers.trainersFilter
  };
}

const mapDispatchToProps = {
  changeFilter: trainersActions.changeFilter
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainersPage);
