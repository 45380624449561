import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

const templateLevels = {
  1: {
    pairs: 4,
    movesLimit: 8,
    timer: 45
  },
  2: {
    pairs: 6,
    movesLimit: 12,
    timer: 50
  },
  3: {
    pairs: 8,
    movesLimit: 16,
    timer: 60
  }
};

export default getLevels(templateLevels, getLevelParams);
