const shuffleWord = (currentWord) => {
  let k, x;
  for (let j = 0; j < currentWord.length; j++) {
    k = Math.floor(Math.random() * (j + 1));
    x = currentWord[k];
    currentWord[k] = currentWord[j];
    currentWord[j] = x;
  }

  return currentWord;
}

export default (wordsArray) => {
  let currentWord;
  let shuffledWordsArray = [];
  let currentWordsArray = [];
  for (let i = 0; i < wordsArray.length; i++) {
    currentWord = shuffleWord([...wordsArray[i]]);

    while (currentWord.join('') === wordsArray[i]) {
      currentWord = shuffleWord([...wordsArray[i]]);
    }

    shuffledWordsArray.push(currentWord);
    currentWordsArray.push((new Array(currentWord.length)).fill(false));
  }

  return { shuffledWordsArray, currentWordsArray };
}
