export default {
  shapes: [4, 5, 1, 7, 3, 6, 2],
  offset: {
    mobile: 10
  },
  outlines: [
    {
      id: 4,
      rotation: 135,
      desktop: {
        x: 284.46,
        y: 323.64
      },
      tablet: {
        x: 283.85,
        y: 285.7
      },
      mobile: {
        x: 192.64,
        y: 226.43
      }
    },
    {
      id: 5,
      rotation: 180,
      desktop: {
        x: 236,
        y: 248
      },
      tablet: {
        x: 239,
        y: 218.5
      },
      mobile: {
        x: 159,
        y: 176
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 191,
        y: 158
      },
      tablet: {
        x: 199,
        y: 138.5
      },
      mobile: {
        x: 129,
        y: 116
      }
    },
    {
      id: 7,
      rotation: 270,
      desktop: {
        x: 189,
        y: 292
      },
      tablet: {
        x: 199,
        y: 257.5
      },
      mobile: {
        x: 129,
        y: 206
      }
    },
    {
      id: 3,
      rotation: 180,
      desktop: {
        x: 450.2,
        y: 225
      },
      tablet: {
        x: 429,
        y: 202.5
      },
      mobile: {
        x: 303,
        y: 168
      }
    },
    {
      id: 6,
      rotation: 315,
      desktop: {
        x: 279,
        y: 202
      },
      tablet: {
        x: 277,
        y: 177.07
      },
      mobile: {
        x: 188.62,
        y: 146.58
      }
    },
    {
      id: 2,
      rotation: 225,
      desktop: {
        x: 177.5,
        y: 190.3
      },
      tablet: {
        x: 187.28,
        y: 167.14
      },
      mobile: {
        x: 120.21,
        y: 137.85
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 146,
        y: 158.16
      },
      tablet: {
        x: 272.14,
        y: 82.28
      },
      mobile: {
        x: 141.43,
        y: 74.21
      }
    },
    {
      desktop: {
        x: 209.32,
        y: 94.84
      },
      tablet: {
        x: 215.57,
        y: 82.28
      },
      mobile: {
        x: 99,
        y: 116.5
      }
    },
    {
      desktop: {
        x: 272.96,
        y: 94.84
      },
      tablet: {
        x: 159,
        y: 138.8
      },
      mobile: {
        x: 99,
        y: 176
      }
    },
    {
      desktop: {
        x: 209.8,
        y: 158
      },
      tablet: {
        x: 159,
        y: 218.5
      },
      mobile: {
        x: 129,
        y: 146
      }
    },
    {
      desktop: {
        x: 236,
        y: 158
      },
      tablet: {
        x: 199,
        y: 178.5
      },
      mobile: {
        x: 159,
        y: 176
      }
    },
    {
      desktop: {
        x: 280,
        y: 202
      },
      tablet: {
        x: 238.5,
        y: 218
      },
      mobile: {
        x: 129.39,
        y: 205.59999999999997
      }
    },
    {
      desktop: {
        x: 405.2,
        y: 202
      },
      tablet: {
        x: 199.04,
        y: 257.45
      },
      mobile: {
        x: 171.43,
        y: 247.64
      }
    },
    {
      desktop: {
        x: 405.2,
        y: 135
      },
      tablet: {
        x: 255.57,
        y: 313.98
      },
      mobile: {
        x: 171.43,
        y: 206
      }
    },
    {
      desktop: {
        x: 450.2,
        y: 179.75
      },
      tablet: {
        x: 255.57,
        y: 257.5
      },
      mobile: {
        x: 248.04,
        y: 206
      }
    },
    {
      desktop: {
        x: 406.28,
        y: 223.91
      },
      tablet: {
        x: 357.43,
        y: 257.5
      },
      mobile: {
        x: 273.47,
        y: 231.43
      }
    },
    {
      desktop: {
        x: 406.28,
        y: 329.28
      },
      tablet: {
        x: 390.14,
        y: 290.2
      },
      mobile: {
        x: 273.47,
        y: 167.51999999999998
      }
    },
    {
      desktop: {
        x: 369,
        y: 292
      },
      tablet: {
        x: 390.14,
        y: 201.36
      },
      mobile: {
        x: 303,
        y: 138
      }
    },
    {
      desktop: {
        x: 252.45999999999998,
        y: 292
      },
      tablet: {
        x: 429,
        y: 162.5
      },
      mobile: {
        x: 273,
        y: 108
      }
    },
    {
      desktop: {
        x: 252.64,
        y: 355.46000000000004
      },
      tablet: {
        x: 389,
        y: 122.5
      },
      mobile: {
        x: 273,
        y: 146.57999999999998
      }
    },
    {
      desktop: {
        x: 189.09,
        y: 291.90999999999997
      },
      tablet: {
        x: 389,
        y: 177.06
      },
      mobile: {
        x: 189.57999999999998,
        y: 146.57999999999998
      }
    },
    {
      desktop: {
        x: 234.5,
        y: 246.5
      },
      tablet: {
        x: 277.57,
        y: 177.06
      },
      mobile: {
        x: 159,
        y: 116
      }
    },
    {
      desktop: {
        x: 191,
        y: 203
      },
      tablet: {
        x: 239,
        y: 138.5
      },
      mobile: {
        x: 142.07,
        y: 116
      }
    },
    {
      desktop: {
        x: 146,
        y: 248
      },
      tablet: {
        x: 215.92000000000002,
        y: 138.5
      },
      mobile: {
        x: 183.85,
        y: 74.21
      }
    }
  ]
};
