export default {
  shapes: [ 3, 7, 6, 2, 5, 4, 1 ],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 35
  },
  outline: [
    {
      desktop: {
        x: 229.82,
        y: 174
      },
      tablet: {
        x: 264,
        y: 119
      },
      mobile: {
        x: 182,
        y: 85
      }
    },
    {
      desktop: {
        x: 217,
        y: 174
      },
      tablet: {
        x: 224,
        y: 159
      },
      mobile: {
        x: 152,
        y: 115
      }
    },
    {
      desktop: {
        x: 261.75,
        y: 129
      },
      tablet: {
        x: 235.28,
        y: 159
      },
      mobile: {
        x: 161.21,
        y: 115
      }
    },
    {
      desktop: {
        x: 307,
        y: 174
      },
      tablet: {
        x: 235.28,
        y: 214.21
      },
      mobile: {
        x: 161.21,
        y: 156
      }
    },
    {
      desktop: {
        x: 293.46,
        y: 174
      },
      tablet: {
        x: 215.5,
        y: 214.21
      },
      mobile: {
        x: 149.21,
        y: 156
      }
    },
    {
      desktop: {
        x: 293.46,
        y: 236.28
      },
      tablet: {
        x: 159.28,
        y: 158
      },
      mobile: {
        x: 107.21,
        y: 114
      }
    },
    {
      desktop: {
        x: 320.69,
        y: 236.28
      },
      tablet: {
        x: 159.28,
        y: 214.5
      },
      mobile: {
        x: 107.21,
        y: 156.42
      }
    },
    {
      desktop: {
        x: 302,
        y: 218
      },
      tablet: {
        x: 238.78,
        y: 294
      },
      mobile: {
        x: 166.8,
        y: 216
      }
    },
    {
      desktop: {
        x: 393,
        y: 218
      },
      tablet: {
        x: 398.71,
        y: 294
      },
      mobile: {
        x: 285.27,
        y: 216
      }
    },
    {
      desktop: {
        x: 436.65,
        y: 262.64
      },
      tablet: {
        x: 455.14,
        y: 237.57
      },
      mobile: {
        x: 327.85,
        y: 173.43
      }
    },
    {
      desktop: {
        x: 475.28,
        y: 262.64
      },
      tablet: {
        x: 422.57,
        y: 237.57
      },
      mobile: {
        x: 303.43,
        y: 173.43
      }
    },
    {
      desktop: {
        x: 411.65,
        y: 326.28
      },
      tablet: {
        x: 383,
        y: 198
      },
      mobile: {
        x: 274,
        y: 144
      }
    },
    {
      desktop: {
        x: 232,
        y: 326.28
      },
      tablet: {
        x: 303,
        y: 198
      },
      mobile: {
        x: 214,
        y: 144
      }
    },
    {
      desktop: {
        x: 142,
        y: 236.3
      },
      tablet: {
        x: 319.21,
        y: 214.21
      },
      mobile: {
        x: 226,
        y: 156
      }
    },
    {
      desktop: {
        x: 141.82,
        y: 173.28
      },
      tablet: {
        x: 291.85,
        y: 214.21
      },
      mobile: {
        x: 203.64,
        y: 156
      }
    },
    {
      desktop: {
        x: 204.82,
        y: 236.28
      },
      tablet: {
        x: 291.85,
        y: 159
      },
      mobile: {
        x: 203.64,
        y: 115
      }
    },
    {
      desktop: {
        x: 229.82,
        y: 236.28
      },
      tablet: {
        x: 304,
        y: 159
      },
      mobile: {
        x: 212,
        y: 115
      }
    }
  ],
  outlines: [
    {
      desktop: { x: 110, y: 205.1 },
      tablet: { x: 131, y: 186.28 },
      mobile: { x: 86, y: 135.21 },
      rotation: 315
    },
    {
      desktop: { x: 322, y: 236.28 },
      tablet: { x: 319, y: 214.21 },
      mobile: { x: 226.79, y: 156 },
      rotation: 90
    },
    {
      desktop: { x: 232, y: 326.28 },
      tablet: { x: 239, y: 294 },
      mobile: { x: 166, y: 216 },
      rotation: 270
    },
    {
      desktop: { x: 302, y: 263 },
      tablet: { x: 303, y: 238 },
      mobile: { x: 214, y: 174 },
      rotation: 270
    },
    {
      desktop: { x: 411.64, y: 199 },
      tablet: { x: 398.57, y: 181 },
      mobile: { x: 285.43, y: 131 },
      rotation: 45
    },
    {
      desktop: { x: 307, y: 129 },
      tablet: { x: 304, y: 119 },
      mobile: { x: 212, y: 85 },
      rotation: 90
    },
    {
      desktop: { x: 198, y: 204.92 },
      tablet: { x: 207, y: 186.57 },
      mobile: { x: 140, y: 135.43 },
      rotation: 315
    }
  ]
};
