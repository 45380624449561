import level1 from './baseImages/level1';
import level2 from './baseImages/level2';
import level3 from './baseImages/level3';
import level4 from './baseImages/level4';

export default {
  1: level1,
  2: level2,
  3: level3,
  4: level4
};
