export default {
  image: require('~/static/trainers/funnyPoints/eggplant.png'),
  width: 800,
  height: 919,
  offsetY: 20,
  points: [
    { id: 1,  x: 130,  y: 263 },
    { id: 2,  x: 73,   y: 362 },
    { id: 3,  x: -61,  y: 454 },
    { id: 4,  x: -213, y: 474 },
    { id: 5,  x: -330, y: 580 },
    { id: 6,  x: -296, y: 732 },
    { id: 7,  x: -166, y: 800 },
    { id: 8,  x: 75,   y: 765 },
    { id: 9,  x: 241,  y: 618 },
    { id: 10, x: 304,  y: 335 },
  ]
}
