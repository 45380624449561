import texts from './texts';
import levels from './levels';

export default  {
  id: 24,
  type: 'spatial',
  eventCategory: 'box',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Пространственное воображение — навык, который поможет тебе в самых сложных спецзаданиях!',
  previewUrl: require('~/static/image/trainerPreviews/spatial.jpg'),
  params: {
    ...texts,
    name: 'Потайной ящик',
    hasTimer: true,
    startsImmediately: true,
    maxSkills:  {
      mind: 1,
      imagination: 2
    },
    stageAmount: 3,
    skills: [ 'imagination', 'mind' ],
    mainSkill: 'imagination',
    isShowStage: true,
    levels
  }
};
