export default {
  shapes: [ 1, 5, 7, 6, 2, 4, 3 ],
  offset: {
    tablet: 10,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 262, y: 35 },
      tablet: { x: 266, y: 39 },
      mobile: { x: 185, y: 23 },
    },
    {
      desktop: { x: 217, y: 80 },
      tablet: { x: 226, y: 79 },
      mobile: { x: 155, y: 53 },
    },
    {
      desktop: { x: 261.5, y: 124.5 },
      tablet: { x: 265.5, y: 118.6 },
      mobile: { x: 184.5, y: 82.4 },
    },
    {
      desktop: { x: 134.4, y: 124.5 },
      tablet: { x: 153.4, y: 118.6 },
      mobile: { x: 100.6, y: 82.4 },
    },
    {
      desktop: { x: 229.8, y: 219.7 },
      tablet: { x: 233.8, y: 198.8 },
      mobile: { x: 165, y: 147 },
    },
    {
      desktop: { x: 229.8, y: 256.3 },
      tablet: { x: 233.8, y: 231 },
      mobile: { x: 165, y: 172 },
    },
    {
      desktop: { x: 185, y: 302 },
      tablet: { x: 194, y: 271 },
      mobile: { x: 136, y: 201 },
    },
    {
      desktop: { x: 185, y: 391.4 },
      tablet: { x: 194, y: 350.5 },
      mobile: { x: 136, y: 260.5 },
    },
    {
      desktop: { x: 166.5, y: 409.8 },
      tablet: { x: 176.7, y: 367.7 },
      mobile: { x: 123.8, y: 272.8 },
    },
    {
      desktop: { x: 230.2, y: 409.8 },
      tablet: { x: 233.3, y: 367.7 },
      mobile: { x: 166.3, y: 272.8 },
    },
    {
      desktop: { x: 230.2, y: 346 },
      tablet: { x: 233.3, y: 312 },
      mobile: { x: 166.3, y: 232 },
    },
    {
      desktop: { x: 356.5, y: 346 },
      tablet: { x: 346.4, y: 312 },
      mobile: { x: 249.5, y: 232 },
    },
    {
      desktop: { x: 420.2, y: 408.8 },
      tablet: { x: 402.7, y: 368.3 },
      mobile: { x: 291.8, y: 274.2 },
    },
    {
      desktop: { x: 420.2, y: 345 },
      tablet: { x: 402.7, y: 311.8 },
      mobile: { x: 291.8, y: 231.8 },
    },
    {
      desktop: { x: 356.5, y: 345 },
      tablet: { x: 346.8, y: 311.8 },
      mobile: { x: 249.8, y: 231.8 },
    },
    {
      desktop: { x: 288, y: 277 },
      tablet: { x: 290, y: 255 },
      mobile: { x: 203, y: 185 },
    },
    {
      desktop: { x: 288, y: 99 },
      tablet: { x: 290, y: 95 },
      mobile: { x: 203, y: 65 },
    },
    {
      desktop: { x: 307, y: 80 },
      tablet: { x: 306, y: 79 },
      mobile: { x: 215, y: 53 },
    },
  ],
  outlines: [
    {
      desktop: { x: 217, y: 35 },
      tablet: { x: 226, y: 39 },
      mobile: { x: 155, y: 23 },
      rotation: 0
    },
    {
      desktop: { x: 198, y: 61 },
      tablet: { x: 210, y: 62 },
      mobile: { x: 143, y: 40 },
      rotation: 45
    },
    {
      desktop: { x: 288, y: 278 },
      tablet: { x: 290, y: 255 },
      mobile: { x: 203, y: 185 },
      rotation: 180
    },
    {
      desktop: { x: 357, y: 346 },
      tablet: { x: 347, y: 312 },
      mobile: { x: 250, y: 232 },
      rotation: 135
    },
    {
      desktop: { x: 185, y: 256 },
      tablet: { x: 194, y: 231 },
      mobile: { x: 136, y: 171 },
      rotation: 0
    },
    {
      desktop: { x: 199, y: 440 },
      tablet: { x: 205, y: 396 },
      mobile: { x: 145, y: 294 },
      rotation: 225
    },
    {
      desktop: { x: 452, y: 377 },
      tablet: { x: 431, y: 340 },
      mobile: { x: 313, y: 253 },
      rotation: 135
    }
  ]
}
