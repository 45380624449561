import React from 'react';
import Greeting from '~/components/Greeting/Greeting';

import './hero.scss';

import foxman from '~/static/image/foxman.png';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__holder">
        <img className="hero__fox" alt="foxman" src={ foxman } />

        <div className="hero__greeting">
          <Greeting />
        </div>
      </div>
    </section>
  );
}

export default Hero
