const MAX_STAGE_AMOUNT = 8;

export default ({ seconds }) => {
  const tooltipContent = `<b>1.</b> Посмотри на числовой ряд.
                          <br><b>2.</b> Найди закономерность.
                          <br><b>3.</b> Впиши следующее число.`;
  return {
    timer: new Array(MAX_STAGE_AMOUNT).fill(seconds * 1000),
    tooltipContent,
    isMiniTooltip: true,
    winnerPoints: {
      logic: 2,
      mind: 1
    }
  };
}
