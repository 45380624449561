export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    tablet: 5,
    mobile: 15
  },
  outline: [
    {
      desktop: {
        x: 199,
        y: 98
      },
      tablet: {
        x: 131,
        y: 95
      },
      mobile: {
        x: 85,
        y: 66
      }
    },
    {
      desktop: {
        x: 199,
        y: 188
      },
      tablet: {
        x: 171.3,
        y: 135.3
      },
      mobile: {
        x: 115,
        y: 96
      }
    },
    {
      desktop: {
        x: 163.5,
        y: 223.5
      },
      tablet: {
        x: 211,
        y: 95.61
      },
      mobile: {
        x: 145,
        y: 66
      }
    },
    {
      desktop: {
        x: 208.64,
        y: 268.64
      },
      tablet: {
        x: 211,
        y: 175
      },
      mobile: {
        x: 145,
        y: 126
      }
    },
    {
      desktop: {
        x: 335.63,
        y: 268.64
      },
      tablet: {
        x: 180.5,
        y: 205.5
      },
      mobile: {
        x: 122.5,
        y: 148.5
      }
    },
    {
      desktop: {
        x: 380,
        y: 313
      },
      tablet: {
        x: 214.57,
        y: 239.57
      },
      mobile: {
        x: 149.43,
        y: 175.43
      }
    },
    {
      desktop: {
        x: 470,
        y: 313
      },
      tablet: {
        x: 327.59,
        y: 239.57
      },
      mobile: {
        x: 234.42,
        y: 175.43
      }
    },
    {
      desktop: {
        x: 514,
        y: 358
      },
      tablet: {
        x: 367,
        y: 279
      },
      mobile: {
        x: 264,
        y: 205
      }
    },
    {
      desktop: {
        x: 245,
        y: 358
      },
      tablet: {
        x: 447,
        y: 279
      },
      mobile: {
        x: 323,
        y: 205
      }
    },
    {
      desktop: {
        x: 271.18,
        y: 331.82
      },
      tablet: {
        x: 487,
        y: 319
      },
      mobile: {
        x: 353,
        y: 235
      }
    },
    {
      desktop: {
        x: 252.36,
        y: 313
      },
      tablet: {
        x: 247,
        y: 319
      },
      mobile: {
        x: 174,
        y: 235
      }
    },
    {
      desktop: {
        x: 73,
        y: 313
      },
      tablet: {
        x: 270.22,
        y: 295.78
      },
      mobile: {
        x: 191.29,
        y: 217.71
      }
    },
    {
      desktop: {
        x: 153.5,
        y: 232.5
      },
      tablet: {
        x: 259.43,
        y: 285
      },
      mobile: {
        x: 181.57,
        y: 208
      }
    },
    {
      desktop: {
        x: 109,
        y: 188
      },
      tablet: {
        x: 100,
        y: 285
      },
      mobile: {
        x: 62,
        y: 208
      }
    },
    {
      desktop: {
        x: 109,
        y: 99
      },
      tablet: {
        x: 170.5,
        y: 214.5
      },
      mobile: {
        x: 114.5,
        y: 155.5
      }
    },
    {
      desktop: {
        x: 153.75,
        y: 143.5
      },
      tablet: {
        x: 131,
        y: 175
      },
      mobile: {
        x: 85,
        y: 126
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 245,
        y: 358,
        rotation: 90
      },
      tablet: {
        x: 247,
        y: 319,
        rotation: 90
      },
      mobile: {
        x: 174,
        y: 235,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 73,
        y: 313,
        rotation: 90
      },
      tablet: {
        x: 100,
        y: 285,
        rotation: 90
      },
      mobile: {
        x: 62,
        y: 208,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 271.64,
        y: 205,
        rotation: -45
      },
      tablet: {
        x: 270.57,
        y: 183,
        rotation: -45
      },
      mobile: {
        x: 191.43,
        y: 133,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 154,
        y: 98,
        rotation: 0
      },
      tablet: {
        x: 171,
        y: 95.61,
        rotation: 0
      },
      mobile: {
        x: 115,
        y: 66,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 154,
        y: 189,
        rotation: 180
      },
      tablet: {
        x: 171,
        y: 175,
        rotation: 180
      },
      mobile: {
        x: 115,
        y: 126,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 379,
        y: 358,
        rotation: 90
      },
      tablet: {
        x: 367,
        y: 319,
        rotation: 90
      },
      mobile: {
        x: 263,
        y: 235,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 109,
        y: 143,
        rotation: 0
      },
      tablet: {
        x: 131,
        y: 135,
        rotation: 0
      },
      mobile: {
        x: 85,
        y: 96,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
