import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

import GreatButtonPopup from '~/components/GreatButtonPopup/GreatButtonPopup';
import Skill from '~/components/Skill/Skill';
import Icon from '~/components/Icon/Icon';

import skillConfig from '~/shared/skillConfig';
import getStatusIcon from '~/services/get-status-icon';
import timeout from '~/services/timeout';

import './status-up-panel.scss';

const StatusUpPanel = (props) => {
  const { prevStatus, status, prevSkills, skills } = props;
  const skillNames = Object.keys(skills);

  const [ currentSkills, setCurrentSkills ] = useState(prevSkills);
  const [ currentStatus, setCurrentStatus ] = useState(prevStatus);
  const [ isCurrenText, setCurrentText ] = useState(false);
  const text = isCurrenText ?
              'Продолжай развиваться, чтобы получить следущий статус!' :
              'Фокси, ты прокачал навыки на максимум!';
  const statusIcon = getStatusIcon(currentStatus);
  const panelAnimation = useSpring({
    from: { opacity: 1 },
    to: async (next) => {
      await next({ opacity: 0 });
      setCurrentSkills(skills);
      setCurrentText(true);
      await timeout(1200);
      await next({ opacity: 1 });
    },
    delay: 1400
  });
  const titleAnimation = useSpring({
    from: { opacity: 1 },
    to: async (next) => {
      await next({ opacity: 0 });
      setCurrentStatus(status);
      await timeout(600);
      await next({ opacity: 1 });
    },
    delay: 1400
  });
  const buttonAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 3500
  });

  useEffect(() => {
    setTimeout(() => {
      const newSkills = skillNames.reduce((acc, skill) => ({ ...acc, [skill]: { ...prevSkills[skill], value: prevSkills[skill].max } }), {});
      setCurrentSkills(newSkills);
    }, 1400);
  }, []);

  const renderSkill = (skill, type) => {
    const { value, max } = skill;
    const { gradientColors, names, colors } = skillConfig;

    return (
      <Skill
        className="status-up-panel__skill"
        classNameCircle="status-up-panel__skill-circle"
        classNameCircleHolder="status-up-panel__skill-holder"
        key={ type }
        name={ names[type] }
        value={ value }
        max={ max }
        startColor={ gradientColors[type][0] }
        endColor={ gradientColors[type][1] }
        type={ type }
        amountColor={ colors[type] }
        isAnimated={ isCurrenText }
      />
    );
  }

  return (
    <div className="status-up-panel">
      <div className="status-up-panel__circle" />
      <animated.div className="status-up-panel__title" style={ titleAnimation }>
        <Icon className="status-up-panel__icon" name={ statusIcon } />
        { currentStatus }
      </animated.div>
      <animated.div style={ panelAnimation }>
        <div className="status-up-panel__sub-title">{ text }</div>
        <div className="status-up-panel__skills">
          { skillNames.map((skill) => renderSkill(currentSkills[skill], skill)) }
        </div>
      </animated.div>
      <animated.div style={ buttonAnimation }>
        <GreatButtonPopup className="status-up-panel__button" text="Отлично!"/>
      </animated.div>
    </div>
  );
};

export default StatusUpPanel;
