import React, { memo } from 'react';
import { Line } from 'react-konva';

import * as Sizes from '../constants';
import * as Colors from '../constants/colors';

const FigureOutline = (props) => {
  const { outline, isOver, isShowRight } = props;
  const isMultiline = outline.length < Sizes.MAX_COUNT_OUTLINES;

  const points = outline.reduce((acc, item) => [ ...acc, item.x, item.y ], []);
  const styleOutline = {
    stroke: isOver && isShowRight ? Colors.OUTLINE_ACTIVE_COLOR : Colors.OUTLINE_COLOR,
    dash: Sizes.DASH_SIZE,
    closed: true
  };

  return isMultiline ? renderMultiline({ ...props, styleOutline }) : <Line points={ points } { ...styleOutline } />;
};

const renderMultiline = (props) => {
  const { outline, styleOutline } = props;

  return (
    outline.map((item, index) => {
      const points = item.reduce((acc, item) => [ ...acc, item.x, item.y ], []);

      return <Line points={ points } key={ index } { ...styleOutline } />
    })
  );
};

export default memo(FigureOutline);
