import React from 'react';

import './menu.scss';
// eslint-disable-next-line
import close from './assets/close.svg';

import Icon from '~/components/Icon/Icon'
import Logo from '~/components/Logo/Logo'
import Contacts from '~/components/Contacts/Contacts'
import CreatedBy from '~/components/CreatedBy/CreatedBy'
import Socials from '~/components/Socials/Socials'
import Projects from '~/components/Projects/Projects'
import Navigation from '~/components/Navigation/Navigation'

const Menu = ({ onRequestClose }) => {
  return (
    <div className="menu">
      <button onClick={ onRequestClose } type="button" className='menu__close'>
        <Icon name="close" className="menu__close-icon" />
      </button>

      <div className="menu__main">
        <div className="menu__logo">
          <Logo />
        </div>

        <div className="menu__navigation">
          <Navigation isHeader={ false } onRequestClose={ onRequestClose } />
        </div>

        <div className="menu__projects">
          <header className="menu__projects-header">
            наши проекты
          </header>

          <div className="menu__projects-box">
            <Projects  />
          </div>
        </div>

        <div className="menu__socials">
          <Socials />
        </div>

        <div className="menu__contacts">
          <Contacts />
        </div>

        <div className="menu__created-by">
          <CreatedBy />
        </div>
      </div>
    </div>
  );
}

export default Menu
