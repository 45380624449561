import React from 'react';

import './mnemonic-remember.scss';

const MnemonicRemember = (props) => {
  return (
    <div className="mnemonic-remember">
      { renderCards(props) }
    </div>
  );
};

const renderCards = ({ images }) => {
  return images.map((cardsRow, index) => (
    <div className="mnemonic-remember__cards-row" key={ index }>
      { cardsRow.map((item, itemIndex) => renderCard(item, itemIndex)) }
    </div>
  ));
};

const renderCard = (item, index) => (
  <div className='mnemonic-remember__card' key={ index }>
    <img className='mnemonic-remember__icon' src={ item } alt='item' />
  </div>
);

export default MnemonicRemember;
