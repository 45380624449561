import londonEye from './missions/london-eye';
import bigBan from './missions/big-ban';
import towerBridge from './missions/tower-bridge';

export default   {
  id: 1,
  slug: 'london',
  distance: 25,
  city: 'Лондон',
  accusativeCity: 'Лондон',
  country: 'Великобритания',
  missions: {
    londonEye,
    bigBan,
    towerBridge
  },
  background: require('~/static/locations/london/london.jpeg'),
  images: [
    require('~/static/locations/london/london-about.png')
  ],
  description: 'Лондон — это столица Великобритании и одновременно сердце школы спецагентов. Выполняя задания мистера Фоксмана, ты здорово прокачаешь свои супернавыки и станешь отличником Секретной школы!',
  infoDescription: 'Лондон — это столица и крупнейший город Соединённого Королевства Великобритании и Северной Ирландии. Административно образует регион Население составляет 8,6 млн человек (2015 год) — это третий по величине город Европы.',
  locked: false
};
