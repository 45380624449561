import React from 'react';
import classNames from 'classnames'
import { Link } from 'react-router-dom';

import './confidential.scss'

const Confidential = ({ isFooter }) => {
  let confidentialClass = classNames('confidential', {
    'confidential_footer': isFooter
  })

  return(
    <div className={confidentialClass}>
      <Link className="confidential__link" to='/policy/'>
        Политика конфиденциальности
      </Link>

      <div className="confidential__year">
        {`© IQ 007, 2017-${new Date().getFullYear()}`}
      </div>
    </div>
  )
}

export default Confidential
