import content from './content';

import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

const templateLevels = {
  1: { seconds: 60 },
  2: { seconds: 120 },
  3: { seconds: 180 },
  4: { seconds: 240 },
  5: { seconds: 300 }
};

export default getLevels(templateLevels, getLevelParams, { name: 'figures', data: content });
