export default {
  image: require('~/static/trainers/funnyPoints/garnet.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  offsetY: 30,
  points: [
    { id: 1,  x: -3,   y: 267 },
    { id: 2,  x: -134, y: 294 },
    { id: 3,  x: -244, y: 426 },
    { id: 4,  x: -251, y: 614 },
    { id: 5,  x: -142, y: 771 },
    { id: 6,  x: 63,   y: 819 },
    { id: 7,  x: 213,  y: 745 },
    { id: 8,  x: 301,  y: 583 },
    { id: 9,  x: 266,  y: 389 },
    { id: 10, x: 146,  y: 290 }
  ]
}
