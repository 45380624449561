export default {
  image: require('~/static/trainers/funnyPoints/radio.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: 147,  y: 142 },
    { id: 2,  x: 147,  y: 778 },
    { id: 3,  x: -125, y: 778 },
    { id: 4,  x: -125, y: 522 },
    { id: 5,  x: -112, y: 402 },
    { id: 6,  x: -69,  y: 341 },
    { id: 7,  x: 9,    y: 315 },
    { id: 8,  x: 88,   y: 341 },
    { id: 9,  x: 126,  y: 402 },
    { id: 10, x: 148,  y: 522 }
  ]
}
