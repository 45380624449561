import PropTypes from 'prop-types';

export default {
  trainer: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      textVictory: PropTypes.string,
      gift: PropTypes.shape({
        name: PropTypes.string,
      }),
      skillPoints: PropTypes.shape({}),
      difficultyParams: PropTypes.shape({
        gamePoints: PropTypes.number,
      }),
    }),
  }).isRequired,
  isPopupShow: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  profile: PropTypes.shape()
};
