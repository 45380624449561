export default {
  shapes: [ 5, 1, 7, 6, 8, 3, 4 ],
  offset: {
    mobile: 25
  },
  outline: [
    {
      desktop: { x: 207, y: 423 },
      tablet: { x: 214, y: 381 },
      mobile: { x: 146, y: 281 },
    },
    {
      desktop: { x: 297, y: 423 },
      tablet: { x: 294, y: 381 },
      mobile: { x: 206, y: 281 },
    },
    {
      desktop: { x: 273, y: 399.1 },
      tablet: { x: 275, y: 362 },
      mobile: { x: 190, y: 265 },
    },
    {
      desktop: { x: 273, y: 311 },
      tablet: { x: 275, y: 284 },
      mobile: { x: 190, y: 204.5 },
    },
    {
      desktop: { x: 325, y: 363 },
      tablet: { x: 320, y: 329 },
      mobile: { x: 227, y: 242 },
    },
    {
      desktop: { x: 325, y: 419 },
      tablet: { x: 320, y: 370 },
      mobile: { x: 227, y: 268 },
    },
    {
      desktop: { x: 370, y: 374 },
      tablet: { x: 360, y: 330 },
      mobile: { x: 257, y: 238 },
    },
    {
      desktop: { x: 325.3, y: 329.3 },
      tablet: { x: 321.1, y: 291},
      mobile: { x: 227.8, y: 208.8 },
    },
    {
      desktop: { x: 325.3, y: 110.7 },
      tablet: { x: 321.1, y: 106 },
      mobile: { x: 227.8, y: 73.2 },
    },
    {
      desktop: { x: 338.5, y: 97.5 },
      tablet: { x: 334.5, y: 92.5 },
      mobile: { x: 239, y: 62 },
    },
    {
      desktop: { x: 363, y: 122 },
      tablet: { x: 355, y: 113 },
      mobile: { x: 256, y: 79 },
    },
    {
      desktop: { x: 363, y: 32 },
      tablet: { x: 355, y: 33 },
      mobile: { x: 256, y: 19 },
    },
    {
      desktop: { x: 273, y: 32 },
      tablet: { x: 275, y: 33 },
      mobile: { x: 196, y: 19 },
    },
    {
      desktop: { x: 293.5, y: 52.5 },
      tablet: { x: 294.5, y: 52.5 },
      mobile: { x: 209, y: 32 },
    },
    {
      desktop: { x: 249, y: 97 },
      tablet: { x: 255, y: 92 },
      mobile: { x: 179, y: 62 },
    },
    {
      desktop: { x: 293.5, y: 141.5 },
      tablet: { x: 294.5, y: 131.5 },
      mobile: { x: 209, y: 92 },
    },
    {
      desktop: { x: 198.5, y: 236.5 },
      tablet: { x: 208.5, y: 217.5 },
      mobile: { x: 143, y: 158 },
    },
    {
      desktop: { x: 228, y: 266 },
      tablet: { x: 235, y: 244 },
      mobile: { x: 160, y: 175 },
    },
    {
      desktop: { x: 228, y: 355 },
      tablet: { x: 235, y: 323 },
      mobile: { x: 160, y: 235 },
    },
    {
      desktop: { x: 251.4, y: 378.4 },
      tablet: { x: 253.5, y: 341.5 },
      mobile: { x: 176, y: 251  },
    },
  ],
  outlines: [
    {
      desktop: { x: 273, y: 122 },
      tablet: { x: 275, y: 113 },
      mobile: { x: 196, y: 79 },
      rotation: 270
    },
    {
      desktop: { x: 249, y: 52 },
      tablet: { x: 255, y: 52 },
      mobile: { x: 179, y: 32 },
      rotation: 0
    },
    {
      desktop: { x: 325, y: 109 },
      tablet: { x: 321, y: 105 },
      mobile: { x: 228, y: 73 },
      rotation: 45
    },
    {
      desktop: { x: 198, y: 236 },
      tablet: { x: 208, y: 217 },
      mobile: { x: 143, y: 158 },
      rotation: 315
    },
    {
      desktop: { x: 228, y: 265 },
      tablet: { x: 235, y: 243 },
      mobile: { x: 160, y: 175 },
      rotation: 0
    },
    {
      desktop: { x: 297, y: 378 },
      tablet: { x: 294, y: 341 },
      mobile: { x: 206, y: 251 },
      rotation: 90
    },
    {
      desktop: { x: 369, y: 419 },
      tablet: { x: 360, y: 370 },
      mobile: { x: 257, y: 268 },
      rotation: 180
    }
  ]
};
