export default {
  shapes: [ 1, 6, 7, 2, 5, 4, 3 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 247, y: 22 },
      tablet: { x: 256, y: 26 },
      mobile: { x: 176, y: 15 }
    },
    {
      desktop: { x: 202, y: 67 },
      tablet: { x: 216, y: 66 },
      mobile: { x: 146, y: 45 }
    },
    {
      desktop: { x: 247, y: 112 },
      tablet: { x: 256, y: 106 },
      mobile: { x: 176, y: 75 }
    },
    {
      desktop: { x: 264.65, y: 94.35 },
      tablet: { x: 269.57, y: 92.43 },
      mobile: { x: 187.43, y: 63.57 }
    },
    {
      desktop: { x: 264.64, y: 220.64 },
      tablet: { x: 269.57, y: 202.57 },
      mobile: { x: 187.43, y: 147.43 }
    },
    {
      desktop: { x: 208, y: 277.28 },
      tablet: { x: 223, y: 249.14 },
      mobile: { x: 153, y: 181.85 }
    },
    {
      desktop: { x: 249.18, y: 277.28 },
      tablet: { x: 249.72, y: 249.14 },
      mobile: { x: 174.79, y: 181.85 }
    },
    {
      desktop: { x: 187, y: 339.46 },
      tablet: { x: 194, y: 304.85 },
      mobile: { x: 133, y: 223.64 }
    },
    {
      desktop: { x: 231.05, y: 339.95 },
      tablet: { x: 233.14, y: 304.85 },
      mobile: { x: 162.36, y: 223.64 }
    },
    {
      desktop: { x: 187, y: 384.25 },
      tablet: { x: 194, y: 344 },
      mobile: { x: 133, y: 253 }
    },
    {
      desktop: { x: 232, y: 429 },
      tablet: { x: 234, y: 384 },
      mobile: { x: 163, y: 283 }
    },
    {
      desktop: { x: 232, y: 339.96 },
      tablet: { x: 234, y: 304.85 },
      mobile: { x: 163, y: 223.64 }
    },
    {
      desktop: { x: 334.82, y: 340.64 },
      tablet: { x: 336.28, y: 304.57 },
      mobile: { x: 237.21, y: 223.43 }
    },
    {
      desktop: { x: 335, y: 403.82 },
      tablet: { x: 336.28, y: 359.57 },
      mobile: { x: 237.21, y: 264.43 }
    },
    {
      desktop: { x: 398.46, y: 403.64 },
      tablet: { x: 392.85, y: 359.57 },
      mobile: { x: 279.64 , y: 264.43 }
    },
    {
      desktop: { x: 335.46, y: 340.64 },
      tablet: { x: 337.85, y: 304.57 },
      mobile: { x: 238.64, y: 223.43 }
    },
    {
      desktop: { x: 376.28, y: 340.64 },
      tablet: { x: 362.14, y: 304.57 },
      mobile: { x: 258.85, y: 223.43 }
    },
    {
      desktop: { x: 312.92, y: 277.28 },
      tablet: { x: 306.71, y: 249.14 },
      mobile: { x: 217.28, y: 181.85 }
    },
    {
      desktop: { x: 335.28, y: 277.28 },
      tablet: { x: 336.14, y: 249.14 },
      mobile: { x: 237.85, y: 181.85 }
    },
    {
      desktop: { x: 335.28, y: 150.28 },
      tablet: { x: 336.14, y: 137.14 },
      mobile: { x: 237.85, y: 97.85 }
    },
    {
      desktop: { x: 391.92, y: 93.64 },
      tablet: { x: 382.71, y: 90.57 },
      mobile: { x: 272.28, y: 63.43 }
    },
    {
      desktop: { x: 265.35, y: 93.65 },
      tablet: { x: 271.43, y: 90.57 },
      mobile: { x: 187.43, y: 63.57 }
    },
    {
      desktop: { x: 292, y: 67 },
      tablet: { x: 296, y: 66 },
      mobile: { x: 206, y: 45 }
    }
  ],
  outlines: [
    {
      desktop: { x: 202, y: 22 },
      tablet: { x: 216, y: 26 },
      mobile: { x: 146, y: 15 },
      rotation: 0
    },
    {
      desktop: { x: 264.64, y: 220.92 },
      tablet: { x: 269.57, y: 203.71 },
      mobile: { x: 187.43, y: 148.28 },
      rotation: 225
    },
    {
      desktop: { x: 335.28, y: 150 },
      tablet: { x: 336.14, y: 136 },
      mobile: { x: 237.85, y: 97 },
      rotation: 45
    },
    {
      desktop: { x: 282.46, y: 244 },
      tablet: { x: 278.85, y: 220 },
      mobile: { x: 196.64, y: 160 },
      rotation: 45
    },
    {
      desktop: { x: 312.64, y: 404.28 },
      tablet: { x: 305.57, y: 361.14 },
      mobile: { x: 216.43, y: 265.85 },
      rotation: 225
    },
    {
      desktop: { x: 187, y: 339 },
      tablet: { x: 194, y: 304 },
      mobile: { x: 133, y: 223 },
      rotation: 0
    },
    {
      desktop: { x: 303, y: 371.82 },
      tablet: { x: 308, y: 331.28 },
      mobile: { x: 216, y: 243.21 },
      rotation: 315
    }
  ]
};
