export default {
  shapes: [7, 6, 4, 2, 5, 3, 1],
  offset: {
    tablet: 20,
    mobile: 15
  },
  outlines: [
    {
      id: 7,
      rotation: 270,
      desktop: {
        x: 195,
        y: 331
      },
      tablet: {
        x: 205,
        y: 302
      },
      mobile: {
        x: 141,
        y: 225
      }
    },
    {
      id: 6,
      rotation: 225,
      desktop: {
        x: 283.64,
        y: 243.92
      },
      tablet: {
        x: 284.57,
        y: 222.71
      },
      mobile: {
        x: 200.43,
        y: 166.28
      }
    },
    {
      id: 4,
      rotation: 0,
      desktop: {
        x: 329,
        y: 153
      },
      tablet: {
        x: 325,
        y: 142
      },
      mobile: {
        x: 231,
        y: 105
      }
    },
    {
      id: 2,
      rotation: 270,
      desktop: {
        x: 150,
        y: 243
      },
      tablet: {
        x: 166,
        y: 222
      },
      mobile: {
        x: 111,
        y: 165
      }
    },
    {
      id: 5,
      rotation: 135,
      desktop: {
        x: 348.28,
        y: 179.64
      },
      tablet: {
        x: 342.14,
        y: 165.57
      },
      mobile: {
        x: 243.85,
        y: 123.43
      }
    },
    {
      id: 3,
      rotation: 315,
      desktop: {
        x: 342,
        y: 183.82
      },
      tablet: {
        x: 336,
        y: 170.28
      },
      mobile: {
        x: 239,
        y: 126.21
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 284,
        y: 197
      },
      tablet: {
        x: 285,
        y: 182
      },
      mobile: {
        x: 201,
        y: 135
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 284,
        y: 116.64
      },
      tablet: {
        x: 365,
        y: 302
      },
      mobile: {
        x: 201,
        y: 165
      }
    },
    {
      desktop: {
        x: 410.92,
        y: 116.64
      },
      tablet: {
        x: 325,
        y: 262
      },
      mobile: {
        x: 141,
        y: 165
      }
    },
    {
      desktop: {
        x: 374.69,
        y: 152.87
      },
      tablet: {
        x: 365,
        y: 222
      },
      mobile: {
        x: 111,
        y: 135
      }
    },
    {
      desktop: {
        x: 437.46,
        y: 215.64
      },
      tablet: {
        x: 365,
        y: 198.57
      },
      mobile: {
        x: 170.57,
        y: 135
      }
    },
    {
      desktop: {
        x: 374,
        y: 215.82
      },
      tablet: {
        x: 420.85,
        y: 198.57
      },
      mobile: {
        x: 159,
        y: 123.43
      }
    },
    {
      desktop: {
        x: 374,
        y: 241.97
      },
      tablet: {
        x: 364.8,
        y: 142.5
      },
      mobile: {
        x: 201,
        y: 81.43
      }
    },
    {
      desktop: {
        x: 330,
        y: 286
      },
      tablet: {
        x: 397.71000000000004,
        y: 109.57
      },
      mobile: {
        x: 285.28,
        y: 81.43
      }
    },
    {
      desktop: {
        x: 375,
        y: 331
      },
      tablet: {
        x: 285,
        y: 109.57
      },
      mobile: {
        x: 261,
        y: 105.7
      }
    },
    {
      desktop: {
        x: 195,
        y: 331
      },
      tablet: {
        x: 229,
        y: 165.57
      },
      mobile: {
        x: 302.64,
        y: 147.43
      }
    },
    {
      desktop: {
        x: 283,
        y: 243
      },
      tablet: {
        x: 245.43,
        y: 182
      },
      mobile: {
        x: 261,
        y: 147.43
      }
    },
    {
      desktop: {
        x: 195,
        y: 243
      },
      tablet: {
        x: 166,
        y: 182
      },
      mobile: {
        x: 261,
        y: 165
      }
    },
    {
      desktop: {
        x: 150,
        y: 198
      },
      tablet: {
        x: 206,
        y: 222
      },
      mobile: {
        x: 231,
        y: 195
      }
    },
    {
      desktop: {
        x: 239.36,
        y: 198
      },
      tablet: {
        x: 285,
        y: 222
      },
      mobile: {
        x: 261,
        y: 225
      }
    },
    {
      desktop: {
        x: 221,
        y: 179.64
      },
      tablet: {
        x: 205,
        y: 302
      },
      mobile: {
        x: 141,
        y: 225
      }
    }
  ]
};
