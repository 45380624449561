export default {
  shapes: [4, 8, 7, 6, 1, 5, 3],
  offset: {
    tablet: 15,
    mobile: 20
  },
  outlines: [
    {
      id: 4,
      rotation: 0,
      desktop: {
        x: 185,
        y: 267
      },
      tablet: {
        x: 199,
        y: 241
      },
      mobile: {
        x: 122,
        y: 190
      }
    },
    {
      id: 8,
      rotation: 90,
      desktop: {
        x: 424,
        y: 254
      },
      tablet: {
        x: 410,
        y: 230
      },
      mobile: {
        x: 282,
        y: 178
      }
    },
    {
      id: 7,
      rotation: 270,
      desktop: {
        x: 244,
        y: 254
      },
      tablet: {
        x: 250,
        y: 230
      },
      mobile: {
        x: 162,
        y: 179
      }
    },
    {
      id: 6,
      rotation: 225,
      desktop: {
        x: 207.64,
        y: 290.92
      },
      tablet: {
        x: 218.57,
        y: 262.71
      },
      mobile: {
        x: 138.43,
        y: 204.28
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 333,
        y: 119
      },
      tablet: {
        x: 329,
        y: 110
      },
      mobile: {
        x: 221,
        y: 90
      }
    },
    {
      id: 5,
      rotation: 270,
      desktop: {
        x: 378,
        y: 209
      },
      tablet: {
        x: 368,
        y: 190
      },
      mobile: {
        x: 251,
        y: 150
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 210,
        y: 120
      },
      tablet: {
        x: 219,
        y: 110
      },
      mobile: {
        x: 139,
        y: 89
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 378,
        y: 119
      },
      tablet: {
        x: 369,
        y: 110
      },
      mobile: {
        x: 79,
        y: 119
      }
    },
    {
      desktop: {
        x: 468,
        y: 119
      },
      tablet: {
        x: 329.43,
        y: 149.57
      },
      mobile: {
        x: 109,
        y: 89
      }
    },
    {
      desktop: {
        x: 468,
        y: 209
      },
      tablet: {
        x: 218.57,
        y: 149.57
      },
      mobile: {
        x: 139.5,
        y: 119.43
      }
    },
    {
      desktop: {
        x: 423,
        y: 164
      },
      tablet: {
        x: 179,
        y: 110
      },
      mobile: {
        x: 221.57,
        y: 119.43
      }
    },
    {
      desktop: {
        x: 378.5,
        y: 208.5
      },
      tablet: {
        x: 139,
        y: 150
      },
      mobile: {
        x: 251,
        y: 90
      }
    },
    {
      desktop: {
        x: 424,
        y: 254
      },
      tablet: {
        x: 218.57,
        y: 150
      },
      mobile: {
        x: 311,
        y: 90
      }
    },
    {
      desktop: {
        x: 379,
        y: 299
      },
      tablet: {
        x: 218.57,
        y: 261.43
      },
      mobile: {
        x: 311,
        y: 150
      }
    },
    {
      desktop: {
        x: 289,
        y: 299
      },
      tablet: {
        x: 199,
        y: 281
      },
      mobile: {
        x: 281,
        y: 120
      }
    },
    {
      desktop: {
        x: 334,
        y: 254
      },
      tablet: {
        x: 239,
        y: 321
      },
      mobile: {
        x: 252,
        y: 149
      }
    },
    {
      desktop: {
        x: 244.56,
        y: 254
      },
      tablet: {
        x: 239,
        y: 242.27
      },
      mobile: {
        x: 281.5,
        y: 178.5
      }
    },
    {
      desktop: {
        x: 230,
        y: 268.56
      },
      tablet: {
        x: 251.27,
        y: 230
      },
      mobile: {
        x: 252,
        y: 208
      }
    },
    {
      desktop: {
        x: 230,
        y: 357
      },
      tablet: {
        x: 330,
        y: 230
      },
      mobile: {
        x: 192,
        y: 208
      }
    },
    {
      desktop: {
        x: 185,
        y: 312.25
      },
      tablet: {
        x: 290,
        y: 270
      },
      mobile: {
        x: 221,
        y: 179
      }
    },
    {
      desktop: {
        x: 207.64,
        y: 289.49
      },
      tablet: {
        x: 370,
        y: 270
      },
      mobile: {
        x: 163.71,
        y: 179
      }
    },
    {
      desktop: {
        x: 207.64,
        y: 165
      },
      tablet: {
        x: 410,
        y: 230
      },
      mobile: {
        x: 152,
        y: 190.71
      }
    },
    {
      desktop: {
        x: 120,
        y: 165
      },
      tablet: {
        x: 369.5,
        y: 189.5
      },
      mobile: {
        x: 152,
        y: 250
      }
    },
    {
      desktop: {
        x: 164.75,
        y: 120
      },
      tablet: {
        x: 408.5,
        y: 150.5
      },
      mobile: {
        x: 122,
        y: 220
      }
    },
    {
      desktop: {
        x: 208.63,
        y: 163.64
      },
      tablet: {
        x: 448,
        y: 190
      },
      mobile: {
        x: 138.43,
        y: 203.57
      }
    },
    {
      desktop: {
        x: 333.36,
        y: 163.64
      },
      tablet: {
        x: 448,
        y: 110
      },
      mobile: {
        x: 138.43,
        y: 119
      }
    }
  ]
};
