import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Icon from '~/components/Icon/Icon'
import LocationAboutPopup from '~/components/LocationAboutPopup/LocationAboutPopup';

import popupNames from '~/services/popup-names';

import { getPopupName } from '~/store/page/selector';

import * as pageActions from '~/store/page/actions';

// eslint-disable-next-line
import light from '~/static/icon/light.svg'; import arrow from '~/components/LocationCard/assets/arrow.svg';

import './locationDescription.scss';

const LocationDescription = (props) => {
  const { location, setPopup, popupName } = props;
  const { description, accusativeCity } = location;

  const isModalOpen = popupName === popupNames.locationAbout;
  const setLocationPopup = () => setPopup(popupNames.locationAbout);
  const closeHandler = () => setPopup(null);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  }, [ isModalOpen ]);

  return (
    <div className="location-description">
      <header className="location-description__header">
        <Link className="location-description__link" to='/locations/'>
          <Icon name="arrow" className="location-description__arrow" />

          <span className="location-description__link-text">
            назад на карту
          </span>
        </Link>

        <button onClick={ setLocationPopup } className="location-description__about">
          <span className="location-description__about-text">
            узнать о локации
          </span>

          <Icon name="light" className="location-description__light" />
        </button>
      </header>

      <h1 className="location-description__title">Добро пожаловать в { accusativeCity }!</h1>
      <p className="location-description__text">{ description }</p>

      <LocationAboutPopup isOpen={ isModalOpen } closeHandler={ closeHandler } location={ location }/>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    popupName: getPopupName(state)
  };
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDescription);
