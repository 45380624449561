import React from 'react';
import PropTypes from 'prop-types';

import Icon from '~/components/Icon/Icon';

/* eslint-disable */
import giveUp from './assets/giveUp.svg';
/* eslint-enable */

import './give-up.scss';

const GiveUp = ({ onClick }) => {
  return (
    <button type="button" className="give-up" onClick={ onClick }>
      <Icon name="giveUp" className="give-up__icon" />
      <div className="give-up__text">
        сдаться
      </div>
    </button>
  );
}

GiveUp.propTypes = {
  onClick: PropTypes.func
};

export default GiveUp;


