export default {
  image: require('~/static/trainers/funnyPoints/tiger.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  offsetY: 20,
  points: [
    { id: 1,  x: -155, y: 503 },
    { id: 2,  x: -185, y: 592 },
    { id: 3,  x: -229, y: 693 },
    { id: 4,  x: -269, y: 774 },
    { id: 5,  x: -239, y: 777 },
    { id: 6,  x: -197, y: 734 },
    { id: 7,  x: -158, y: 675 },
    { id: 8,  x: -136, y: 634 },
    { id: 9,  x: -97,  y: 652 },
    { id: 10, x: -48,  y: 655 },
    { id: 11, x: -57,  y: 705 },
    { id: 12, x: -73,  y: 761 },
    { id: 13, x: -89,  y: 805 },
    { id: 14, x: -51,  y: 808 },
    { id: 15, x: -12,  y: 762 },
    { id: 16, x: 19,   y: 705 },
    { id: 17, x: 34,   y: 655 },
    { id: 18, x: 91,   y: 641 },
    { id: 19, x: 151,  y: 606 },
    { id: 20, x: 205,  y: 661 },
    { id: 21, x: 249,  y: 758 },
    { id: 22, x: 279,  y: 727 },
    { id: 23, x: 262,  y: 650 },
    { id: 24, x: 203,  y: 563 },
    { id: 25, x: 247,  y: 493 },
    { id: 26, x: 280,  y: 420 },
    { id: 27, x: 296,  y: 343 },
    { id: 28, x: 300,  y: 255 },
    { id: 29, x: 279,  y: 164 },
    { id: 30, x: 230,  y: 94  }
  ]
};
