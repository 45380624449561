import React, { Component } from 'react';
import propTypes from './prop-types';
import shuffleWords from './utils/shuffle';

import Button from '~/components/Button/Button';
import Word from './Word';
import Letter from './Letter';

import getShuffleArray from '~/services/get-shuffle-array';

import { BUTTON_CHECK } from '~/utils/constants';

import './TrainerAnagram.scss';

class TrainerAnagram extends Component {
  state = {
    shuffledWordsArray: [],
    currentWordsArray: [],
    targetWordsArray: [],
    stepsLeft: 0,
    answered: false,
    show: false,
    showResultButton: false
  };

  componentDidMount() {
    this.generateWords();
  };

  componentDidUpdate(prevProps, prevState) {
    const { isRefresh, isTimerActive, handleDataLayer } = this.props;
    const { answered, stepsLeft, currentWordsArray } = this.state;

    if (prevProps.isTimerActive && !isTimerActive && !answered) {
      this.checkVictory();
      handleDataLayer({ event: 'timerTick', eventAction: 'next_force', stageName: 'page2_solve' });
    }

    if (prevProps.isRefresh !== isRefresh) {
      this.generateWords();
      this.setState({ show: true });
    }

    if (prevState.stepsLeft !== stepsLeft && stepsLeft <= 0) {
      const isNotFull = currentWordsArray.some((item) => item.includes(false));
      if (!isNotFull) {
        this.setState({ showResultButton: true });
      }
    }
  };

  generateWords = () => {
    const { params } = this.props;
    const { difficultyParams } = params;
    const { wordsArray, wordsAmount } = difficultyParams;
    const targetWordsArray = getShuffleArray(wordsArray, wordsAmount);

    this.setState({
      ...shuffleWords(targetWordsArray),
      targetWordsArray,
      stepsLeft: targetWordsArray.reduce((acc, item) => acc + item.length, 0),
      answered: false
    });
  }

  onDragStartHandler = (event, wordIndex, letterIndex) => {
    const text = event.target.firstChild.innerText;
    const value = JSON.stringify({ wordIndex, letterIndex, text, isShuffledArray: true });

    this.state.show && this.setState({ show: false });
    this.activeItem = event.target;
    event.dataTransfer.setData('value', value);
  };

  onDragStartEmptyHandler = (event, wordIndex, letterIndex) => {
    const text = event.target.firstChild.innerText;
    const value = JSON.stringify({ wordIndex, letterIndex, text });

    this.emptyDragging = true;
    event.dataTransfer.setData('value', value);
  };

  onDragEndEmptyHandler = () => {
    this.emptyDragging = false;
  };

  onDragEndHandler = () => {
    this.activeItem = null;
  };

  onDragOverHandler = (event) => {
    event.preventDefault();
    event.target.classList.add('ondragover');
  };

  onDragLeaveHandler = (event) => {
    event.preventDefault();
    event.target.classList.remove('ondragover');
  };

  onDropHandler = (event, wordIndex, gameCardIndex) => {
    event.target.classList.remove('ondragover');
    const transferedObject = JSON.parse(event.dataTransfer.getData('value'));
    const { wordIndex: wordIndexTransfered, letterIndex } = transferedObject;

    if (wordIndex !== wordIndexTransfered) {
      return;
    }

    let { currentWordsArray, shuffledWordsArray, stepsLeft } = this.state;
    if (wordIndex !== 0 && gameCardIndex !== 0 && !(wordIndex && gameCardIndex)) {
      return;
    }

    if (this.activeItem && currentWordsArray[wordIndex][gameCardIndex]) {
      if (!currentWordsArray[wordIndex][gameCardIndex]) {
        this.setState({ stepsLeft: stepsLeft - 1 });
      }
      this.swapItems(currentWordsArray, wordIndex, gameCardIndex,
                     shuffledWordsArray, wordIndexTransfered, letterIndex);
    } else {
      if (this.emptyDragging && !currentWordsArray[wordIndex][gameCardIndex] && transferedObject) {
        let { currentWordsArray } = this.state;
        currentWordsArray[wordIndexTransfered][letterIndex] = false;
      }
      this.emptyDragging = false;

      if (this.activeItem) {
        this.activeItem.style.visibility = 'hidden';
      }

      if (currentWordsArray[wordIndex][gameCardIndex] && transferedObject) {
        this.swapItems(currentWordsArray, wordIndex, gameCardIndex,
                       currentWordsArray, wordIndexTransfered, letterIndex);
      } else {
        if (!currentWordsArray[wordIndex][gameCardIndex]) {
          this.setState({ stepsLeft: stepsLeft - 1 });
        }
        currentWordsArray[wordIndex][gameCardIndex] = transferedObject.text;
      }
    }

    this.setState({ currentWordsArray, shuffledWordsArray });
  };

  finishTrainer = (skills, reason) => {
    const { onFinishCb } = this.props;
    onFinishCb({ victory: !!skills, reason, skills });
  };

  checkVictory = () => {
    this.setState({ answered: true });

    const { params } = this.props;
    const { mistakesAvailable, difficultyParams } = params;
    const { winnerPoints } = difficultyParams;
    const { currentWordsArray, targetWordsArray } = this.state;
    let mistakesLeft = mistakesAvailable;

    for (let i = 0; i < targetWordsArray.length; i++) {
      if (!this.checkWordCorrectness(currentWordsArray[i], targetWordsArray[i])) {
        mistakesLeft--;
      }
    }

    let reason = 'fail';
    let skills = null;

    if (mistakesAvailable - mistakesLeft === 0) {
      reason = 'complete';
      skills = winnerPoints;
    }

    this.finishTrainer(skills, reason);
  };

  checkWordCorrectness = (composedWord, correctWord) => {
    for (let i = 0; i < correctWord.length; i++) {
      if (correctWord[i].toLowerCase() !== (composedWord[i] ? composedWord[i].toLowerCase() : null)) {
        return false;
      }
    }
    return true;
  };

  swapItems = (arr1, wordIndex1, letterIndex1, arr2, wordIndex2, letterIndex2) => {
    [
      arr1[wordIndex1][letterIndex1],
      arr2[wordIndex2][letterIndex2]
    ] = [
      arr2[wordIndex2][letterIndex2],
      arr1[wordIndex1][letterIndex1]
    ];
  };

  onLetterClickHandler = (event, wordIndex, letterIndex, isEmptySwap) => {
    const currentTarget = event.target && event.target.closest('.trainer-anagram__letter-wrapper');
    let { currentWordsArray, shuffledWordsArray, answered } = this.state;

    if (answered) {
      return;
    }

    if (this.activeClickedElement) {
      let arr1 = currentWordsArray;
      let arr2 = currentWordsArray;
      const { isEmptySwap: isEmptySwapClicked, letterIndex: letterIndexClicked,
              target, wordIndex: wordIndexClicked } = this.activeClickedElement;
      const childrenTarget = target.querySelector('.trainer-anagram__letter-text');
      const skipActiveLetter = childrenTarget === event.target ||
                               (!isEmptySwapClicked && !isEmptySwap) ||
                               target === event.target;

      if (skipActiveLetter) {
        target.classList.remove('active-icon');
        this.activeClickedElement = null;
        return;
      }

      if (wordIndex !== wordIndexClicked) {
        return;
      }

      if (isEmptySwapClicked && !isEmptySwap) {
        arr1 = shuffledWordsArray;
      }

      if(!isEmptySwapClicked) {
        arr2 = shuffledWordsArray;
      }

      this.swapItems(arr1, wordIndex, letterIndex, arr2, wordIndexClicked, letterIndexClicked);

      currentTarget && currentTarget.classList.remove('active-icon');
      target.classList.remove('active-icon');
      this.activeClickedElement = null;

      this.setState({ currentWordsArray, shuffledWordsArray });
    }
    else {
      currentTarget && currentTarget.classList.add('active-icon');
      this.activeClickedElement = { target: currentTarget, wordIndex, letterIndex, isEmptySwap };
    }
  };

  onEmptyLetterClickHandler = (event, wordIndex, letterIndex) => {
    if (!this.activeClickedElement) {
      return;
    }

    const { wordIndex: wordIndexClicked, isEmptySwap, letterIndex: letterIndexClicked } = this.activeClickedElement;

    if (wordIndexClicked !== wordIndex) {
      return;
    }

    let { currentWordsArray, shuffledWordsArray, stepsLeft } = this.state;
    let swapArr = currentWordsArray;

    if (!isEmptySwap) {
      swapArr = shuffledWordsArray;
    }

    if (!currentWordsArray[wordIndex][letterIndex]) {
      this.setState({ stepsLeft: stepsLeft - 1 });
    }

    this.swapItems(currentWordsArray, wordIndex, letterIndex, swapArr, wordIndexClicked, letterIndexClicked);
    this.activeClickedElement = null;
    this.setState({ shuffledWordsArray, currentWordsArray });
  };

  renderPart = (letterArray, wordIndex, word) => {
    const { targetWordsArray } = this.state;
    const isCorrectWord = word.join('').toLowerCase() === targetWordsArray[wordIndex];

    return (
      <div className="trainer-anagram__word-container" key={ wordIndex }>
        <div className="trainer-anagram__letters-row">
          { letterArray.map((letter, letterIndex) => this.renderLetter(letterIndex, letter, wordIndex)) }
        </div>
        <div className="trainer-anagram__empty-letter-row">
          { word.map((gameCard, gameCardIndex) => this.renderWord(gameCardIndex, wordIndex, gameCard, isCorrectWord)) }
        </div>
      </div>
    );
  };

  renderLetter = (letterIndex, letter, wordIndex) => {
    const { answered, show } = this.state;
    return (
      <Letter key={ letterIndex } answered={ answered } letterIndex={ letterIndex } letter={ letter }
              show={ show } wordIndex={ wordIndex } onDragStartHandler={ this.onDragStartHandler }
              onLetterClickHandler={ this.onLetterClickHandler } onDragEndHandler={ this.onDragEndHandler } />
    );
  };

  renderWord = (gameCardIndex, wordIndex, gameCard, isCorrectWord) => {
    const { answered, targetWordsArray } = this.state;
    return (
      <Word answered={ answered } key={ gameCardIndex } gameCardIndex={ gameCardIndex } wordIndex={ wordIndex }
            letterArray={ targetWordsArray[wordIndex] } gameCard={ gameCard } onDropHandler={ this.onDropHandler }
            onDragStartEmptyHandler={ this.onDragStartEmptyHandler } onLetterClickHandler={ this.onLetterClickHandler }
            onEmptyLetterClickHandler={ this.onEmptyLetterClickHandler } onDragEndHandler={ this.onDragEndHandler }
            onDragOverHandler={ this.onDragOverHandler } onDragLeaveHandler={ this.onDragLeaveHandler }
            isCorrectWord={ isCorrectWord } />
    );
  };

  endTrainer = () => {
    this.checkVictory();
    this.props.handleDataLayer({ eventAction: 'next_self', stageName: 'page2_solve' });
  };

  render() {
    const { isTimerActive } = this.props;
    const { shuffledWordsArray, currentWordsArray, showResultButton } = this.state;

    return(
      <section className="trainer-anagram">
        <div className="trainer-anagram__container">
          <div className="trainer-anagram__game-container">
            {
              shuffledWordsArray.map((letterArray, wordIndex) => this.renderPart(letterArray, wordIndex, currentWordsArray[wordIndex]))
            }
          </div>
        </div>
        {
          isTimerActive && showResultButton && (
            <div className="trainer-anagram__check-answers">
              <Button className="trainer-anagram__button button_center" onClick={ this.endTrainer }>
                { BUTTON_CHECK }
              </Button>
            </div>
          )
        }
      </section>
    );
  };
};

TrainerAnagram.propTypes = propTypes;

export default TrainerAnagram;
