export default {
  shapes: [ 5, 8, 6, 1, 3, 7, 4 ],
  offset: {
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 178.64, y: 19 },
      tablet: { x: 197, y: 25 },
      mobile: { x: 135, y: 18 },
    },
    {
      desktop: { x: 268.64, y: 19 },
      tablet: { x: 277, y: 25 },
      mobile: { x: 195, y: 18 },
    },
    {
      desktop: { x: 268.64, y: 108 },
      tablet: { x: 277, y: 104.72  },
      mobile: { x: 195, y: 77.29 },
    },
    {
      desktop: { x: 269, y: 108.36 },
      tablet: { x: 277, y: 104.72  },
      mobile: { x: 195, y: 77.29 },
    },
    {
      desktop: { x: 269, y: 197.72 },
      tablet: {  x: 277, y: 184.43 },
      mobile: { x: 195, y: 136.57 },
    },
    {
      desktop: { x: 350.28, y: 279 },
      tablet: { x: 349.14, y: 256.57 },
      mobile: { x: 248.85, y: 190.43 },
    },
    {
      desktop: { x: 413.92, y: 279 },
      tablet: { x: 405.71, y: 256.57 },
      mobile: { x: 291.28, y: 190.43 },
    },
    {
      desktop: { x: 309.64, y: 383.28  },
      tablet: { x: 316.57, y: 345.71 },
      mobile: { x: 223.43, y: 258.28  },
    },
     {
      desktop: { x: 309.82, y: 445.82 },
      tablet: { x: 316.57, y: 401.57 },
      mobile: { x: 223.43, y: 300.43 },
    },
    {
      desktop: { x: 246, y: 446 },
      tablet: { x: 260, y: 401.57 },
      mobile: { x: 181, y: 300.43 },
    },
    {
      desktop: { x: 286.64, y: 405.36 },
      tablet: { x: 292.57, y: 369 },
      mobile: { x: 206.43, y: 275 },
    },
    {
      desktop: { x: 286.64, y: 342.82 },
      tablet: { x: 292.57, y: 312.85 },
      mobile: { x: 206.43, y: 232.64 },
    },
    {
      desktop: { x: 248.54, y: 342.82 },
      tablet: { x: 276.15, y: 312.85 },
      mobile: { x: 194.36, y: 232.64 },
    },
    {
      desktop: { x: 204.25, y: 387.36 },
      tablet: { x: 237, y: 352 },
      mobile: { x: 165, y: 262 },
    },
    {
      desktop: { x: 159, y: 342.36 },
      tablet: { x: 197, y: 312 },
      mobile: { x: 135, y: 232 },
    },
    {
      desktop: { x: 223.82, y: 342.36 },
      tablet: { x: 236.28, y: 312 },
      mobile: { x: 164.21, y: 232 },
    },
    {
      desktop: { x: 223.82, y: 279.64 },
      tablet: { x: 236.28, y: 256.28 },
      mobile: { x: 164.21, y: 190.21 },
    },
    {
      desktop: { x: 223.64, y: 279.64 },
      tablet: { x: 236.57, y: 256.28 },
      mobile: { x: 164.43, y: 190.21 },
    },
    {
      desktop: { x: 223.64, y: 152.36 },
      tablet: { x: 236.57, y: 144 },
      mobile: { x: 164.43, y: 106 },
    },
    {
      desktop: { x: 224, y: 152.72 },
      tablet: { x: 237, y: 144.43 },
      mobile: { x: 165, y: 106.57 },
    },
    {
      desktop: { x: 224, y: 64.36 },
      tablet: { x: 237, y: 65 },
      mobile: { x: 165, y: 48 },
    }
  ],
  outlines: [
    {
      desktop: { x: 178.64, y: 109 },
      tablet: { x: 197, y: 105 },
      mobile: { x: 135, y: 78 },
      rotation: 270
    },
    {
      desktop: { x: 224, y: 63.36 },
      tablet: { x: 237, y: 65 },
      mobile: { x: 165, y: 47 },
      rotation: 0
    },
    {
      desktop: { x: 350.92, y: 279.64 },
      tablet: { x: 349.71, y: 257.14 },
      mobile: { x: 249.28, y: 190.85 },
      rotation: 135
    },
    {
      desktop: { x: 192, y: 311 },
      tablet: { x: 208, y: 284.57 },
      mobile: { x: 143, y: 211.43 },
      rotation: 315
    },
    {
      desktop: { x: 159, y: 387.36 },
      tablet: { x: 197, y: 352 },
      mobile: { x: 135, y: 262 },
      rotation: 270
    },
    {
      desktop: { x: 286.64, y: 406.28 },
      tablet: { x: 292.57, y: 369.71 },
      mobile: { x: 206.43, y: 275.28 },
      rotation: 225
    },
    {
      desktop: { x: 277.82, y: 477.82 },
      tablet: { x: 288.28, y: 429.85 },
      mobile: { x: 202.21, y: 321.64 },
      rotation: 225
    }
  ]
};
