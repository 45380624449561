import React from 'react'

const Icon = ({ className, name }) => (
  <svg
    style={ { pointerEvents: 'none' } }
    className={ className }
  >
    <use xlinkHref={`#${ name }`} />
  </svg>
);

export default Icon
