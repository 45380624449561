import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import NotAuthPage from '~/pages/NotAuthPage/NotAuthPage';

import getQueryVariable from '~/services/get-query-variable';

import * as locationsActions from '~/store/locations/actions';
import * as trainersActions from '~/store/trainers/actions';
import * as profileActions from '~/store/profile/actions';

const FetchContainer = (props) => {
  const { fetchLocations, fetchTrainers, fetchProfile, children, location,
          isLocationsFulfilled, isTrainersFulfilled, currentId, profile } = props;
  const { search, pathname } = location;
  const isAdmin = pathname === '/admin/generateTokens/';
  const isShort = pathname.includes('/s/');
  const token = isShort ? pathname.split('/s/')[1] : getQueryVariable('token');

  const fetchRequests = async (token) => {
    await fetchProfile(token);
    fetchLocations();
    fetchTrainers();
  };

  useEffect(() => {
    fetchRequests(token);
  }, []);

  useEffect(() => {
    if (token && token !== profile.token) {
      fetchRequests(token);
    }
  }, [ search ]);

  useEffect(() => {
    if (currentId) {
      window.dataLayer.push({ event: 'auth', eventCategory: 'user_session', eventAction: 'session_start' });
    }
  }, [ currentId ]);

  return isLocationsFulfilled && isTrainersFulfilled ? (currentId || isAdmin) ? children : <NotAuthPage /> : null;
};

const mapDispatchToProps = {
  fetchLocations: locationsActions.fetchLocations,
  fetchTrainers: trainersActions.fetchTrainers,
  fetchProfile: profileActions.fetchProfile
};

function mapStateToProps(state) {
  const { locations, trainers, profile } = state;
  const { id: currentId } = profile;

  return {
    isLocationsFulfilled: locations.isFulfilled,
    isTrainersFulfilled: trainers.isFulfilled,
    currentId,
    profile
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FetchContainer));
