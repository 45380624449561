import React from 'react';
import cx from 'classnames';

import propTypes from './prop-types';

import Button from '~/components/Button/Button';
import Icon from '~/components/Icon/Icon';

// eslint-disable-next-line
import part from './assets/part.svg';

import foxman from '~/static/image/foxman.png'
import scooter from './assets/images/scooter.png';
import './trainer-screen.scss';

const TrainerScreen = (props) => {
  const { label, title, content, gift, buttonText, exitButtonText, buttonOnClick, exitButtonOnClick,
          buttonClassName, children, isMission, cyButton, cy, partially, videoPreview, onClick } = props;
  const buttonClasses = cx( 'button_center', {
    'trainer-screen__button': !partially,
    'trainer-screen__button-lose': partially,
    [buttonClassName]: buttonClassName,
  });
  const trainerClesses = cx('trainer-screen', {
    'trainer-screen_mission': isMission
  });
  const handleClick = () => onClick('video', true);
  const theme = buttonText ? 'grey-link' : null;

  return (
    <div className={ trainerClesses } data-cy={ cy }>
      <div className="trainer-screen__holder">
        <div className="trainer-screen__part">
          <Icon name="part" className="trainer-screen__part-icon" />
        </div>

        { label && <div className="trainer-screen__label">{ label }</div> }
        { title && <h2 className="trainer-screen__title">{ title }</h2>}
        {
          content && (
            <div className="trainer-screen__content" dangerouslySetInnerHTML={{ __html: content }} />
          )
        }
        { children && <div className="trainer-screen__content">{ children }</div> }
        {
          gift && (
            <div className="trainer-screen__gift-container">
              <img className="trainer-screen__gift-preview" alt="" src={ getGiftSrcByName(gift) } />
            </div>
          )
        }
        {
          buttonText && (
            <Button className={ buttonClasses } onClick={ buttonOnClick } cy={ cyButton }>
              { buttonText }
            </Button>
          )
        }
        {
          exitButtonText && (
            <Button className="button_center trainer-screen__button trainer-screen__button_exit button_size-m"
                    onClick={ exitButtonOnClick } theme={ theme }>
              { exitButtonText }
            </Button>
          )
        }
        {
          videoPreview && (
            <button onClick={ handleClick } className="trainer-screen__show-video">
              Смотреть видео-инструкцию
            </button>
          )
        }
        { renderFoxman() }
      </div>
    </div>
  );
};

const getGiftSrcByName = (name) => {
  switch(name) {
    case 'scooter':
      return scooter;
    default:
      return scooter;
  }
};

const renderFoxman = () => (
  <div className="trainer-screen__foxman">
    <img src={ foxman } className="trainer-screen__foxman-img" alt="foxman" />
  </div>
);

TrainerScreen.propTypes = propTypes;

export default TrainerScreen;
