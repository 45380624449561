export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    desktop: 50,
    tablet: 50,
    mobile: 50
  },
  outline: [
    {
      desktop: {
        x: 279.28,
        y: 58.82
      },
      tablet: {
        x: 393.14,
        y: 113.71
      },
      mobile: {
        x: 198.43,
        y: 38.21
      }
    },
    {
      desktop: {
        x: 215.82,
        y: 122.28
      },
      tablet: {
        x: 336.71,
        y: 57.28
      },
      mobile: {
        x: 156.21,
        y: 80.43
      }
    },
    {
      desktop: {
        x: 215.82,
        y: 185.92
      },
      tablet: {
        x: 280.71,
        y: 57.28
      },
      mobile: {
        x: 156.21,
        y: 122.85
      }
    },
    {
      desktop: {
        x: 279.28,
        y: 122.46
      },
      tablet: {
        x: 224.28,
        y: 113.71
      },
      mobile: {
        x: 198.43,
        y: 80.64
      }
    },
    {
      desktop: {
        x: 342.64,
        y: 122.46
      },
      tablet: {
        x: 224.28,
        y: 170.28
      },
      mobile: {
        x: 240.43,
        y: 80.64
      }
    },
    {
      desktop: {
        x: 342.64,
        y: 155
      },
      tablet: {
        x: 280.71,
        y: 113.85
      },
      mobile: {
        x: 240.43,
        y: 104.57
      }
    },
    {
      desktop: {
        x: 298,
        y: 199.89
      },
      tablet: {
        x: 336.57,
        y: 113.85
      },
      mobile: {
        x: 211,
        y: 134
      }
    },
    {
      desktop: {
        x: 343,
        y: 244.64
      },
      tablet: {
        x: 336.57,
        y: 162.57
      },
      mobile: {
        x: 240,
        y: 163
      }
    },
    {
      desktop: {
        x: 343,
        y: 301.11
      },
      tablet: {
        x: 297,
        y: 202.14
      },
      mobile: {
        x: 240,
        y: 199.21
      }
    },
    {
      desktop: {
        x: 280.28,
        y: 301.28
      },
      tablet: {
        x: 337,
        y: 242.14
      },
      mobile: {
        x: 199.64,
        y: 199.21
      }
    },
    {
      desktop: {
        x: 216.64,
        y: 237.64
      },
      tablet: {
        x: 337,
        y: 273.43
      },
      mobile: {
        x: 156.43,
        y: 156
      }
    },
    {
      desktop: {
        x: 216.64,
        y: 364.92
      },
      tablet: {
        x: 281.85,
        y: 273.43
      },
      mobile: {
        x: 156.43,
        y: 240.85
      }
    },
    {
      desktop: {
        x: 343.72,
        y: 364.92
      },
      tablet: {
        x: 224.57,
        y: 216.14
      },
      mobile: {
        x: 241,
        y: 241
      }
    },
    {
      desktop: {
        x: 433,
        y: 275.64
      },
      tablet: {
        x: 224.57,
        y: 329.28
      },
      mobile: {
        x: 300,
        y: 182
      }
    },
    {
      desktop: {
        x: 343,
        y: 185.6
      },
      tablet: {
        x: 337.65,
        y: 329.5
      },
      mobile: {
        x: 240.65,
        y: 122.65
      }
    },
    {
      desktop: {
        x: 406.28,
        y: 122.28
      },
      tablet: {
        x: 417,
        y: 250.14
      },
      mobile: {
        x: 282.85,
        y: 80.43
      }
    },
    {
      desktop: {
        x: 342.83,
        y: 58.82
      },
      tablet: {
        x: 336.86,
        y: 170
      },
      mobile: {
        x: 240.64,
        y: 38.21
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 279.46,
        y: 58.64,
        rotation: -45
      },
      tablet: {
        x: 280.85,
        y: 57.14,
        rotation: -45
      },
      mobile: {
        x: 198.64,
        y: 38,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 343.92,
        y: 364.92,
        rotation: -135
      },
      tablet: {
        x: 337.71,
        y: 329.28,
        rotation: -135
      },
      mobile: {
        x: 241.28,
        y: 240.85,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 343,
        y: 185.64,
        rotation: 0
      },
      tablet: {
        x: 337,
        y: 170.14,
        rotation: 0
      },
      mobile: {
        x: 240,
        y: 122,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 279,
        y: 122.28,
        rotation: 45
      },
      tablet: {
        x: 280,
        y: 113.71,
        rotation: 45
      },
      mobile: {
        x: 198,
        y: 80.43,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 375.48,
        y: 333.1,
        rotation: -135
      },
      tablet: {
        x: 365.85,
        y: 301.71,
        rotation: -135
      },
      mobile: {
        x: 261.64,
        y: 220.43,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 298,
        y: 154.64,
        rotation: 0
      },
      tablet: {
        x: 297,
        y: 162.14,
        rotation: -0
      },
      mobile: {
        x: 211,
        y: 104,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 247.36,
        y: 90.64,
        rotation: 45
      },
      tablet: {
        x: 252,
        y: 85.57,
        rotation: 45
      },
      mobile: {
        x: 177,
        y: 59.43,
        rotation: 45
      },
      rotation: 315
    }
  ]
};
