export default ({ timer, amount }) => {
  const tooltipContent = `Среди картинок одна – лишняя. Найди её.`;
  return {
    timer: new Array(amount).fill(timer * 1000),
    tooltipContent,
    isMiniTooltip: true,
    stageAmount: amount,
    amount,
    winnerPoints: {
      mind: 1,
      logic: 2
    }
  };
};
