export default {
  image: require('~/static/trainers/funnyPoints/pig.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -54,  y: 599 },
    { id: 2,  x: -106, y: 563 },
    { id: 3,  x: -160, y: 510 },
    { id: 4,  x: -205, y: 452 },
    { id: 5,  x: -253, y: 393 },
    { id: 6,  x: -313, y: 335 },
    { id: 7,  x: -314, y: 281 },
    { id: 8,  x: -285, y: 213 },
    { id: 9,  x: -247, y: 252 },
    { id: 10, x: -206, y: 234 },
    { id: 11, x: -154, y: 200 },
    { id: 12, x: -90,  y: 188 },
    { id: 13, x: -18,  y: 219 },
    { id: 14, x: 41,   y: 182 },
    { id: 15, x: 80,   y: 179 },
    { id: 16, x: 122,  y: 205 },
    { id: 17, x: 175,  y: 176 },
    { id: 18, x: 136,  y: 306 },
    { id: 19, x: 92,   y: 337 },
    { id: 20, x: 18,   y: 326 },
    { id: 21, x: 32,   y: 367 },
    { id: 22, x: 84,   y: 385 },
    { id: 23, x: 146,  y: 375 },
    { id: 24, x: 209,  y: 381 },
    { id: 25, x: 261,  y: 417 },
    { id: 26, x: 290,  y: 467 },
    { id: 27, x: 295,  y: 535 },
    { id: 28, x: 283,  y: 599 },
    { id: 29, x: 253,  y: 658 },
    { id: 30, x: 231,  y: 700 },
    { id: 31, x: 208,  y: 745 },
    { id: 32, x: 184,  y: 782 },
    { id: 33, x: 175,  y: 758 },
    { id: 34, x: 150,  y: 780 },
    { id: 35, x: 185,  y: 685 },
    { id: 36, x: 162,  y: 631 },
    { id: 37, x: 117,  y: 638 },
    { id: 38, x: 22,   y: 629 },
    { id: 39, x: 22,   y: 695 },
    { id: 40, x: 27,   y: 772 }
  ]
};
