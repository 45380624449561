export default {
  image: require('~/static/trainers/funnyPoints/deer.png'),
  width: 800,
  height: 919,
  offsetX: 30,
  points: [
    { id: 1,  x: 63,   y: 569 },
    { id: 2,  x: 32,   y: 601 },
    { id: 3,  x: -11,  y: 612 },
    { id: 4,  x: -44,  y: 628 },
    { id: 5,  x: -90,  y: 664 },
    { id: 6,  x: -144, y: 651 },
    { id: 7,  x: -165, y: 615 },
    { id: 8,  x: -147, y: 567 },
    { id: 9,  x: -108, y: 521 },
    { id: 10, x: -90,  y: 464 },
    { id: 11, x: -65,  y: 433 },
    { id: 12, x: -61,  y: 343 },
    { id: 13, x: -39,  y: 263 },
    { id: 14, x: 6,    y: 176 },
    { id: 15, x: 127,  y: 60 },
    { id: 16, x: 31,   y: 189 },
    { id: 17, x: -1,   y: 282 },
    { id: 18, x: -16,  y: 364 },
    { id: 19, x: -13,  y: 413 },
    { id: 20, x: 20,   y: 412 },
    { id: 21, x: 51,   y: 326 },
    { id: 22, x: 96,   y: 249 },
    { id: 23, x: 150,  y: 184 },
    { id: 24, x: 260,  y: 97 },
    { id: 25, x: 171,  y: 199 },
    { id: 26, x: 123,  y: 278 },
    { id: 27, x: 81,   y: 370 },
    { id: 28, x: 69,   y: 425 },
    { id: 29, x: 110,  y: 456 },
    { id: 30, x: 159,  y: 475 },
    { id: 31, x: 236,  y: 473 },
    { id: 32, x: 188,  y: 524 },
    { id: 33, x: 136,  y: 530 },
    { id: 34, x: 98,   y: 514 },
    { id: 35, x: 108,  y: 575 },
    { id: 36, x: 136,  y: 625 },
    { id: 37, x: 211,  y: 672 },
    { id: 38, x: 170,  y: 744 },
    { id: 39, x: 119,  y: 794 },
    { id: 40, x: 56,   y: 830 }
  ]
};
