import Konva from 'konva';

import cloneArray from './cloneArray';
import { getWidthField } from './index';
import { MAX_COUNT_OUTLINES } from '../constants';

const minXOutlines = (outlines) => Math.min(...outlines.map(item => item.x));

export default (defaultOutlines, defaultShapes, defaultOutline, offset, screenType) => {
  const currentOffset = offset && offset[screenType] ? offset[screenType] : 0;
  const group = new Konva.Group();
  const isMultiline = defaultOutline.length < MAX_COUNT_OUTLINES;

  let outlines = cloneArray(defaultOutlines).map((item, index) => {
    const { rotation } = item;
    const { id } = defaultShapes[index];
    const result = {
      ...item[screenType],
      rotation, id,
      points: defaultShapes[index][screenType],
      isEmpty: true
    };

    group.add(new Konva.Line(result));
    return result;
  });

  const xOutlines = group.getClientRect().x;
  group.removeChildren();

  let outline = !isMultiline ?
                cloneArray(defaultOutline).map(item => item[screenType]) :
                cloneArray(defaultOutline.map((item) => cloneArray(Object.values(item)).map(item => item[screenType])));

  group.add(new Konva.Line({ points: !isMultiline ?
                             outline.reduce((acc, item) => [ ...acc, item.x, item.y ], []) :
                             Object.values(outline[0]).reduce((acc, item) => [ ...acc, item.x, item.y ], [])
  }));

  const minX = minXOutlines(outlines);
  const minXOutline = minXOutlines(isMultiline ? Object.values(outline[0]) : outline);
  const left = (getWidthField() - group.getClientRect().width) * 0.5 - currentOffset;
  const diff = minX - xOutlines;

  outlines = outlines.map(item => {
    item.x = item.x - minX + left + diff;
    return item;
  });

  if (isMultiline) {
    outline = outline.map((item) => {
      return Object.values(item).map((innerItem) => {
        innerItem.x -= minXOutline - left - Math.abs(xOutlines - minXOutline);
        return innerItem;
      });
    });
  } else {
    outline = outline.map(item => {
      item.x -= minXOutline - left - Math.abs(xOutlines - minXOutline);
      return item;
    });
  }

  return { outlines, outline };
};
