export default {
  shapes: [ 2, 1, 6, 7, 5, 3, 4 ],
  offset: {
    desktop: 60,
    tablet: 50,
    mobile: 50
  },
  outline: [
    {
      desktop: { x: 206, y: 36 },
      tablet: { x: 216, y: 29 },
      mobile: { x: 150, y: 19 }
    },
    {
      desktop: { x: 252, y: 81 },
      tablet: { x: 256, y: 69 },
      mobile: { x: 180, y: 49 }
    },
    {
      desktop: { x: 339.68, y: 81 },
      tablet: { x: 334.28, y: 69 },
      mobile: { x: 238.21, y: 49 }
    },
    {
      desktop: { x: 339.68, y: 143.6 },
      tablet: { x: 334.28, y: 123.85 },
      mobile: { x: 238.21, y: 89.64 }
    },
    {
      desktop: { x: 213.64, y: 269.65 },
      tablet: { x: 222.57, y: 235.56 },
      mobile: { x: 154.4, y: 173.45 }
    },
    {
      desktop: { x: 213.64, y: 396.64 },
      tablet: { x: 222.57, y: 348.57 },
      mobile: { x: 154.43, y: 258.43 }
    },
    {
      desktop: { x: 339.64, y: 396.64 },
      tablet: { x: 334.57, y: 348.57 },
      mobile: { x: 238.43, y: 258.43 }
    },
    {
      desktop: { x: 339.46, y: 332.64 },
      tablet: { x: 334.57, y: 292.28 },
      mobile: { x: 238.43, y: 216.21 }
    },
    {
      desktop: { x: 277.74, y: 332.81 },
      tablet: { x: 278.99, y: 292.28 },
      mobile: { x: 196.57, y: 216.14 }
    },
    {
      desktop: { x: 340, y: 270.56 },
      tablet: { x: 335, y: 236.27 },
      mobile: { x: 238, y: 174.71 }
    },
    {
      desktop: { x: 340, y: 308 },
      tablet: { x: 335, y: 263 },
      mobile: { x: 238, y: 195 }
    },
    {
      desktop: { x: 385, y: 262.75 },
      tablet: { x: 375, y: 223 },
      mobile: { x: 268, y: 165 }
    },
    {
      desktop: { x: 340.28, y: 218.28 },
      tablet: { x: 335.14, y: 183.14 },
      mobile: { x: 238.85, y: 135.85 }
    },
    {
      desktop: { x: 340.28, y: 144.32 },
      tablet: { x: 335.14, y: 124.85 },
      mobile: { x: 238.85, y: 90.64 }
    },
    {
      desktop: { x: 403.32, y: 144.32 },
      tablet: { x: 390.85, y: 124.85 },
      mobile: { x: 280.64, y: 90.64 }
    },
    {
      desktop: { x: 403.32, y: 80.68 },
      tablet: { x: 390.85, y: 68.28 },
      mobile: { x: 280.64, y: 48.21 }
    },
    {
      desktop: { x: 340.69, y: 80.68 },
      tablet: { x: 335.28, y: 68.28 },
      mobile: { x: 239.21, y: 48.21 }
    },
    {
      desktop: { x: 297, y: 36 },
      tablet: { x: 296, y: 29 },
      mobile: { x: 210, y: 19 }
    }
  ],
  outlines: [
    {
      desktop: { x: 206, y: 81 },
      tablet: { x: 216, y: 69 },
      mobile: { x: 150, y: 49 },
      rotation: 270
    },
    {
      desktop: { x: 307.86, y: 112.5 },
      tablet: { x: 306, y: 96.57 },
      mobile: { x: 217, y: 69.43 },
      rotation: 315
    },
    {
      desktop: { x: 340.28, y: 143 },
      tablet: { x: 335.14, y: 123 },
      mobile: { x: 238.85, y: 89 },
      rotation: 45
    },
    {
      desktop: { x: 213.64, y: 396.92 },
      tablet: { x: 222.57, y: 348.71 },
      mobile: { x: 154.43, y: 258.28 },
      rotation: 225
    },
    {
      desktop: { x: 276.64, y: 460.28 },
      tablet: { x: 278.57, y: 405.14 },
      mobile: { x: 196.43, y: 300.85 },
      rotation: 225
    },
    {
      desktop: { x: 371.46, y: 364.64 },
      tablet: { x: 362.85, y: 320.57 },
      mobile: { x: 259.64, y: 237.43 },
      rotation: 135
    },
    {
      desktop: { x: 385, y: 308 },
      tablet: { x: 375, y: 263 },
      mobile: { x: 268, y: 195 },
      rotation: 180
    }
  ]
};
