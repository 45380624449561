export default {
  colors: {
    logic: "#F39C19",
    focus: "#E24067",
    memory: "#2DB764",
    imagination: "#8F6DBB",
    mind: '#479CFF'
  },
  gradientColors: {
    logic: [ '#f99313', '#e8dcc2' ],
    focus: [ '#e41a4f', '#efcfd9' ],
    memory: [ '#30b461', '#c4efd1' ],
    imagination: [ '#7025e3', '#c3b5f0' ],
    mind: [ '#489eff', '#cfdfef' ]
  },
  iconNames: {
    logic: "bubbleLogic",
    focus: "bubbleFocus",
    memory: "bubbleMemory",
    imagination: "bubbleImagination",
    mind: "bubbleMind"
  },
  iconNamesPuzzle: {
    logic: "bubbleLogicPuzzle",
    focus: "bubbleFocusEye",
    memory: "bubbleMemoryBrain",
    imagination: "bubbleImaginationShine",
    mind: "bubbleMindLight"
  },
  names: {
    logic: "Логика",
    focus: "Внимание",
    memory: "Память",
    imagination: "Воображение",
    mind: "Мышление"
  }
}
