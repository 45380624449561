export default {
  image: require('~/static/trainers/funnyPoints/amanita.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -22,  y: 335 },
    { id: 2,  x: -203, y: 343 },
    { id: 3,  x: -266, y: 313 },
    { id: 4,  x: -194, y: 207 },
    { id: 5,  x: -5,   y: 124 },
    { id: 6,  x: 207,  y: 156 },
    { id: 7,  x: 311,  y: 245 },
    { id: 8,  x: 275,  y: 300 },
    { id: 9,  x: 174,  y: 320 },
    { id: 10, x: 72,   y: 329 },
  ]
}
