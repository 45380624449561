import React from 'react';
import { connect } from 'react-redux';

import ResultSkill from '~/components/ResultSkill/ResultSkill';
import GiftGot from '~/components/GiftGot/GiftGot';
import Icon from '~/components/Icon/Icon';

import './missionResults.scss';

/* eslint-disable */
import focus from '~/static/icon/skills/focus.svg';
import imagination from '~/static/icon/skills/imagination.svg';
import logic from '~/static/icon/skills/logic.svg';
import memory from '~/static/icon/skills/memory.svg';
import mind from '~/static/icon/skills/mind.svg';
import closeView from '~/static/icon/close-view.svg';
/* eslint-enable */

const MissionResults = (props) => {
  const { data, onClose, skills, profile } = props;
  const { completedMissions } = profile;
  const { id } = data;
  const currentMissionProgress = completedMissions[id];
  const earnedGamePoints = Object.keys(currentMissionProgress).length + 1;

  return (
    <div className="mission-results">
      <button onClick={onClose} type="button" className='mission-results__close-btn'>
        <Icon name="close-view" className="mission-results__close-icon" />
      </button>

      <div className="mission-results__header">
        <div className="mission-results__title">
          Результаты <br /> миссии
        </div>

        <div className="mission-results__name">
          { data.name }
        </div>
      </div>

      <div className="mission-results__info">
        <div className="mission-results__rating">
          <div className="mission-results__label">Игровой рейтинг</div>
          <div className="mission-results__value">
            +{ earnedGamePoints } балла из { earnedGamePoints }
          </div>
        </div>

        <div className="mission-results__gift">
          <div>
            <div className="mission-results__label">Получен предмет</div>
            <div className="mission-results__value">
              { data.gift.name }
            </div>
          </div>

          <div className="mission-results__gift-box">
            <GiftGot gift={ data.gift } isName={ false } withContainer={ true }/>
          </div>
        </div>

        <ul className="mission-results__skills">
          { renderSkills(skills) }
        </ul>
      </div>
    </div>
  );
};

const renderSkills = (skills) => {
  const skillArray = Object.keys(skills);

  return skillArray.map((item, index) => {
    const { value, title, max } = skills[item];

    return (
      <li className="mission-results__skill" key={ index }>
        <ResultSkill value={ value } type={ item } title={ title } allPoints={ max }/>
      </li>
    );
  });
};

const mapStateToProps = (state) => ({
  profile: state.profile
});

export default connect(mapStateToProps)(MissionResults);
