export default {
  image: require('~/static/trainers/funnyPoints/microscope.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  offsetY: 10,
  points: [
    { id: 1,  x: 8,    y: 440 },
    { id: 2,  x: 34,   y: 479 },
    { id: 3,  x: 139,  y: 430 },
    { id: 4,  x: 77,   y: 369 },
    { id: 5,  x: 69,   y: 104 },
    { id: 6,  x: -45,  y: 110 },
    { id: 7,  x: -25,  y: 330 },
    { id: 8,  x: -93,  y: 355 },
    { id: 9,  x: -132, y: 317 },
    { id: 10, x: -186, y: 314 },
    { id: 11, x: -240, y: 397 },
    { id: 12, x: -211, y: 573 },
    { id: 13, x: -68,  y: 693 },
    { id: 14, x: -106, y: 751 },
    { id: 15, x: -165, y: 734 },
    { id: 16, x: -159, y: 795 },
    { id: 17, x: -27,  y: 814 },
    { id: 18, x: 262,  y: 795 },
    { id: 19, x: 281,  y: 720 },
    { id: 20, x: 45,   y: 753 }
  ]
};
