import levels from './levels'
import baseShapes from "./baseShapes"
import texts from './texts';

export default {
  id: 11,
  type: 'tangram',
  eventCategory: 'Tangram',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Расставь все фигуры так, чтобы детали не накладывались друг на друга и в контуре заполнилось всё пространство.',
  previewUrl: require('~/static/image/trainerPreviews/tangram.png'),
  params: {
    ...texts,
    name: 'Танграм',
    hasTimer: true,
    startsImmediately: true,
    notMaxWidth: true,
    maxSkills: {
      focus: 3,
      mind: 1
    },
    skills: [ 'focus', 'mind' ],
    mainSkill: 'focus',
    levels,
    baseShapes
  }
}
