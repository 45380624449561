import figure1 from '../figures/figure1';
import figure2 from '../figures/figure2';
import figure3 from '../figures/figure3';
import figure4 from '../figures/figure4';
import figure5 from '../figures/figure5';
import figure6 from '../figures/figure6';
import figure7 from '../figures/figure7';
import figure8 from '../figures/figure8';
import figure9 from '../figures/figure9';
import figure10 from '../figures/figure10';
import figure11 from '../figures/figure11';
import figure12 from '../figures/figure12';
import figure13 from '../figures/figure13';
import figure125 from '../figures/figure125';

export default [
  figure1,
  figure2,
  figure3,
  figure4,
  figure5,
  figure6,
  figure125,
  figure7,
  figure8,
  figure9,
  figure10,
  figure11,
  figure12,
  figure13,
];
