export default {
  shapes: [4, 5, 1, 6, 2, 7, 3],
  offset: {
    mobile: 20,
    tablet: 20
  },
  outlines: [
    {
      id: 4,
      rotation: 135,
      desktop: {
        x: 383.1,
        y: 138.9
      },
      tablet: {
        x: 363.51,
        y: 132.57
      },
      mobile: {
        x: 264.64,
        y: 93.43
      }
    },
    {
      id: 5,
      rotation: 180,
      desktop: {
        x: 251,
        y: 354
      },
      tablet: {
        x: 246.2,
        y: 327
      },
      mobile: {
        x: 177,
        y: 240.7
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 319.3,
        y: 329
      },
      tablet: {
        x: 307,
        y: 298.57
      },
      mobile: {
        x: 222,
        y: 219.43
      }
    },
    {
      id: 6,
      rotation: 45,
      desktop: {
        x: 288.28,
        y: 226.7
      },
      tablet: {
        x: 279.14,
        y: 214
      },
      mobile: {
        x: 201.85,
        y: 155.8
      }
    },
    {
      id: 2,
      rotation: 135,
      desktop: {
        x: 383.1,
        y: 202.46
      },
      tablet: {
        x: 363.44,
        y: 187.85
      },
      mobile: {
        x: 264.65,
        y: 135.64
      }
    },
    {
      id: 7,
      rotation: 135,
      desktop: {
        x: 414.92,
        y: 297.28
      },
      tablet: {
        x: 391.8,
        y: 270.5
      },
      mobile: {
        x: 285.9,
        y: 198.85
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 367,
        y: 63
      },
      tablet: {
        x: 346.31,
        y: 65
      },
      mobile: {
        x: 252,
        y: 43
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 351,
        y: 108
      },
      tablet: {
        x: 306.31,
        y: 65
      },
      mobile: {
        x: 192,
        y: 73
      }
    },
    {
      desktop: {
        x: 367,
        y: 108
      },
      tablet: {
        x: 266.31,
        y: 105
      },
      mobile: {
        x: 222,
        y: 43
      }
    },
    {
      desktop: {
        x: 321.75,
        y: 63
      },
      tablet: {
        x: 278.59,
        y: 105
      },
      mobile: {
        x: 252,
        y: 73
      }
    },
    {
      desktop: {
        x: 277,
        y: 108
      },
      tablet: {
        x: 278.65999999999997,
        y: 214.47
      },
      mobile: {
        x: 243.43,
        y: 73
      }
    },
    {
      desktop: {
        x: 287.64,
        y: 108
      },
      tablet: {
        x: 246.14,
        y: 247
      },
      mobile: {
        x: 243.44,
        y: 156.39
      }
    },
    {
      desktop: {
        x: 287.64,
        y: 227.34
      },
      tablet: {
        x: 166.2,
        y: 247
      },
      mobile: {
        x: 285.64,
        y: 198.6
      }
    },
    {
      desktop: {
        x: 250.98000000000002,
        y: 264
      },
      tablet: {
        x: 166.2,
        y: 327.14
      },
      mobile: {
        x: 285.64,
        y: 240.64
      }
    },
    {
      desktop: {
        x: 161,
        y: 264
      },
      tablet: {
        x: 279.14,
        y: 327.14
      },
      mobile: {
        x: 243.20999999999998,
        y: 240.64
      }
    },
    {
      desktop: {
        x: 161,
        y: 353.98
      },
      tablet: {
        x: 279.14,
        y: 270.5
      },
      mobile: {
        x: 243.20999999999998,
        y: 198.85
      }
    },
    {
      desktop: {
        x: 288.28,
        y: 353.98
      },
      tablet: {
        x: 335.28,
        y: 270.5
      },
      mobile: {
        x: 201.85,
        y: 198.85
      }
    },
    {
      desktop: {
        x: 288.28,
        y: 297.28
      },
      tablet: {
        x: 335.28,
        y: 326.85
      },
      mobile: {
        x: 201.85,
        y: 240.65
      }
    },
    {
      desktop: {
        x: 351.12,
        y: 297.28
      },
      tablet: {
        x: 391.85,
        y: 326.85
      },
      mobile: {
        x: 117,
        y: 240.65
      }
    },
    {
      desktop: {
        x: 351.12,
        y: 360.82
      },
      tablet: {
        x: 391.85,
        y: 270.5
      },
      mobile: {
        x: 117,
        y: 180.7
      }
    },
    {
      desktop: {
        x: 414.76,
        y: 360.82
      },
      tablet: {
        x: 335.15999999999997,
        y: 213.86
      },
      mobile: {
        x: 176.95,
        y: 180.7
      }
    },
    {
      desktop: {
        x: 414.76,
        y: 297.11
      },
      tablet: {
        x: 335.22,
        y: 105
      },
      mobile: {
        x: 201.05,
        y: 156.61
      }
    },
    {
      desktop: {
        x: 351.28,
        y: 233.64
      },
      tablet: {
        x: 346.31,
        y: 105
      },
      mobile: {
        x: 201.01,
        y: 73
      }
    }
  ]
};
