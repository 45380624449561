export default (trainers, skill) => {
  const trainersByCurrentSkill = trainers.filter((item) => item.params.mainSkill === skill);
  const result = trainersByCurrentSkill.map((trainer) => {
    const item = {
      ...trainer,
      params: {
        ...trainer.params,
        difficultyLevel: trainer.params.difficultyLevel + 1
      }
    };

    return item;
  });

  return result;
};
