import React from 'react';
import Avatar from '~/components/Avatar/Avatar';
import { connect } from 'react-redux';
import Icon from '~/components/Icon/Icon';
import LevelScale from '~/components/LevelScale/LevelScale'
import SkillsProgress from '~/components/SkillsProgress/SkillsProgress'
import Button from '~/components/Button/Button'
import { skillLevelNameSelector, levelMaxValueSelector } from '~/store/profile/selector';
import { linkLastOpenMissionSelector } from '~/store/locations/selector';
import { Link } from 'react-router-dom';

import './skillWidget.scss';

// eslint-disable-next-line
import settings from './assets/settings.svg';

const SkillWidget = (props) => {
  const { levelMaxValue, profile, skillLevelName, gameLink } = props;
  const { name, gameLevel, points, skills } = profile;
  const handleClick = () => {
    window.dataLayer.push({ event: 'click', eventCategory: 'Mission', eventAction: 'play', eventLabel: 'main' });
  };

  return (
    <div className="skill-widget">
      <header className="skill-widget__header">
        <div className="skill-widget__avatar-box">
          <div className="skill-widget__avatar">
            <Avatar />
          </div>
        </div>

        <div className="skill-widget__personal">
          <div className="skill-widget__name">
            { name }
          </div>

          <div className="skill-widget__level-name">
            { skillLevelName }
          </div>
        </div>

        <Link
          className="skill-widget__settings"
          to="/profile/"
        >
          <Icon name="settings" className="skill-widget__settings-icon" />
        </Link>
      </header>

      <div className="skill-widget__main">
        <div className="skill-widget__level-scale">
          <LevelScale level={ gameLevel } points={ points } maxPoints={ levelMaxValue } isShort={ true } />
        </div>

        <div className="skill-widget__skills">
          <SkillsProgress skills={ skills } />
        </div>

        <div className="skill-widget__game">
          <Button link={ gameLink } onClick={ handleClick }>
            играть
          </Button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    skillLevelName: skillLevelNameSelector(state),
    levelMaxValue: levelMaxValueSelector(state),
    gameLink: linkLastOpenMissionSelector(state)
  };
}

export default connect(mapStateToProps)(SkillWidget);
