import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import locations from './locations/reducer';
import trainers from './trainers/reducer';
import profile from './profile/reducer';
import page from './page/reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  locations,
  trainers,
  profile,
  page
})
