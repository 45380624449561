export default ({ params }, missionId, { missions }) => {
  const { difficultyLevel, levels, levelsInMission, mainSkill } = params;
  const arrayLevels = Object.keys(levels);
  const maxLevel = arrayLevels[arrayLevels.length - 1];

  let difficultyParams;

  if (missionId && missions[missionId]) {
    const { skillLevels } = missions[missionId];
    const currentLevel = levelsInMission && levelsInMission[skillLevels[mainSkill]] ? levelsInMission[skillLevels[mainSkill]] :
                         skillLevels[mainSkill] < maxLevel ? skillLevels[mainSkill] : maxLevel;

    difficultyParams = { ...levels[currentLevel], currentLevel };
  } else {
    const currentLevel = levelsInMission && levelsInMission[difficultyLevel] ? levelsInMission[difficultyLevel] :
                         difficultyLevel <= maxLevel ? difficultyLevel : maxLevel;

    difficultyParams = { ...levels[currentLevel], currentLevel };
  }

  return difficultyParams;
};
