export default {
  shapes: [ 5, 2, 1, 4, 3, 7, 6 ],
  offset: {
    desktop: 60,
    tablet: 60,
    mobile: 60
  },
  outline: [
    {
      desktop: { x: 138.64, y: 182.36 },
      tablet: { x: 153.57, y: 155 },
      mobile: { x: 108.5, y: 116 },
    },
    {
      desktop: { x: 138.64, y: 309.64 },
      tablet: { x: 153.57, y: 268.14 },
      mobile: { x: 108.5, y: 200.85 },
    },
    {
      desktop: { x: 201.96, y: 246.32 },
      tablet: { x: 209.85, y: 211.85 },
      mobile: { x: 150.63, y: 158.63 },
    },
    {
      desktop: { x: 247.64, y: 291 },
      tablet: { x: 249, y: 251 },
      mobile: { x: 180, y: 188 },
    },
    {
      desktop: { x: 336.64, y: 291 },
      tablet: { x: 329, y: 251 },
      mobile: { x: 240, y: 188 },
    },
    {
      desktop: { x: 380.64, y: 247 },
      tablet: { x: 369, y: 211 },
      mobile: { x: 270, y: 158 },
    },
    {
      desktop: { x: 380.64, y: 425 },
      tablet: { x: 369, y: 371 },
      mobile: { x: 270, y: 278 },
    },
    {
      desktop: { x: 470.64, y: 335 },
      tablet: { x: 449, y: 291 },
      mobile: { x: 330, y: 218 },
    },
    {
      desktop: { x: 381.26, y: 245.62 },
      tablet: { x: 369, y: 211 },
      mobile: { x: 270, y: 158 },
    },
    {
      desktop: { x: 424.65, y: 202 },
      tablet: { x: 409, y: 171 },
      mobile: { x: 300, y: 128 },
    },
    {
      desktop: { x: 471.64, y: 202 },
      tablet: { x: 449, y: 171 },
      mobile: { x: 330, y: 128 },
    },
    {
      desktop: { x: 426.39, y: 157 },
      tablet: { x: 409, y: 131 },
      mobile: { x: 300, y: 98 },
    },
    {
      desktop: { x: 382.63, y: 201 },
      tablet: { x: 369, y: 171 },
      mobile: { x: 270, y: 128 },
    },
    {
      desktop: { x: 336.92, y: 201 },
      tablet: { x: 330.14, y: 171  },
      mobile: { x: 240, y: 128 },
    },
    {
      desktop: { x: 336.92, y: 74.64 },
      tablet: { x: 330.14, y: 59.57 },
      mobile: { x: 239.85, y: 43.43 },
    },
    {
      desktop: { x: 209.64, y: 74.64 },
      tablet: { x: 217, y: 59.57  },
      mobile: { x: 155, y: 43.43 },
    },
    {
      desktop: { x: 336.3, y: 201.3 },
      tablet: { x: 328.72, y: 171.28 },
      mobile: { x: 239.79, y: 128.21 },
    },
    {
      desktop: { x: 291.64, y: 246 },
      tablet: { x: 289, y: 211 },
      mobile: { x: 210, y: 158 },
    },
    {
      desktop: { x: 202.28, y: 246 },
      tablet: { x: 209.57, y: 211 },
      mobile: { x: 150.5, y: 158 }
    }
  ],
  outlines: [
    {
      desktop: { x: 75, y: 246 },
      tablet: { x: 97, y: 211.57 },
      mobile: { x: 66, y: 158.43 },
      rotation: 315
    },
    {
      desktop: { x: 201.64, y: 291 },
      tablet: { x: 209, y: 251 },
      mobile: { x: 150, y: 188 },
      rotation: 270
    },
    {
      desktop: { x: 291.64, y: 201 },
      tablet: { x: 289, y: 171 },
      mobile: { x: 210, y: 128 },
      rotation: 0
    },
    {
      desktop: { x: 335.64, y: 246 },
      tablet: { x: 329, y: 211 },
      mobile: { x: 240, y: 158 },
      rotation: 270
    },
    {
      desktop: { x: 471.64, y: 157 },
      tablet: { x: 449, y: 131 },
      mobile: { x: 330, y: 98 },
      rotation: 90
    },
    {
      desktop: { x: 209.64, y: 74.64 },
      tablet: { x: 217, y: 59.57 },
      mobile: { x: 155, y: 43.43 },
      rotation: 315
    },
    {
      desktop: { x: 380.64, y: 245 },
      tablet: { x: 369, y: 211 },
      mobile: { x: 270, y: 158 },
      rotation: 0
    }
  ]
};
