import React from 'react';

import fox from './assets/fox.png';

import './missions-end-top.scss';

const MissionsEndTop = () => {
  return (
    <div className="missions-end-top">
      <div className="missions-end-top__holder">
        <div className="missions-end-top__fox">
          <img alt="fox" src={ fox } className="missions-end-top__image" />
        </div>
        <div className="missions-end-top__holder-text">
          <div className="missions-end-top__title">Новых миссий пока нет!</div>
          <div className="missions-end-top__text">
            Агент, ты отлично поработал!
            <br/>
            Сейчас для тебя нет новых миссий, но ты можешь потренировать свои навыки спецагента в тренировочном зале
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionsEndTop;
