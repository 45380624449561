import levels from './levels';
import texts from './texts';

export default {
  id: 1,
  type: 'sudoku',
  eventCategory: 'Sudoku',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Судоку развивает мышление и память — навыки, которые необходимы любому спецагенту.',
  previewUrl: require('~/static/image/trainerPreviews/sudoku.png'),
  params: {
    ...texts,
    name: 'Судоку',
    hasTimer: true,
    startsImmediately: true,
    maxSkills: {
      focus: 1,
      mind: 2
    },
    skills: [ 'focus', 'mind' ],
    mainSkill: 'mind',
    levels
  }
}
