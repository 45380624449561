import React from 'react';

import TooltipButton from '~/components/TooltipButton/TooltipButton';
import Skill from '~/components/Skill/Skill';
import Button from '~/components/Button/Button';

import popupNames from '~/services/popup-names';
import skillConfig from '~/shared/skillConfig';

import './missions-end-skills.scss';

const MissionsEndSkills = (props) => {
  const { profile, skillLevelName, nextSkillLevelName, setPopup } = props;
  const { skills } = profile;
  const skillNames = Object.keys(skills);
  const handlerClick = () => setPopup(popupNames.profileSkills);

  return (
    <div className="missions-end-skills">
      <div className="missions-end-skills__holder">
        {
          nextSkillLevelName &&
          <div className="missions-end-skills__title">
            Продолжай развивать свои навыки и получи статус «{ nextSkillLevelName }»
          </div>
        }
        <div className="missions-end-skills__header">
          <div className="missions-end-skills__params">
            <div className="missions-end-skills__param">
              <div className="missions-end-skills__label">Текущий статус</div>
              <div className="missions-end-skills__status">
                <span>{ skillLevelName }</span>
                <div className="missions-end-skills__status-button">
                  <TooltipButton iconName='show-tooltip' onClick={ handlerClick }/>
                </div>
              </div>
            </div>
            {
              nextSkillLevelName &&
              <div className="missions-end-skills__param">
                <div className="missions-end-skills__label">Следующий статус</div>
                <div className="missions-end-skills__next-status">{ nextSkillLevelName }</div>
              </div>
            }
          </div>
        </div>
        <div className="missions-end-skills__skills">
          { skillNames.map((skill) => renderSkill(skills[skill], skill)) }
        </div>
        <Button className="button_center button_size-m" link="/trainers/">
          открыть тренажеры
        </Button>
      </div>
    </div>
  );
}

const renderSkill = (skill, type) => {
  const { value, max } = skill;
  const { gradientColors, names, colors } = skillConfig;

  return (
    <Skill
      className="missions-end-skills__skill"
      classNameCircle="missions-end-skills__skill-circle"
      classNameCircleHolder="missions-end-skills__skill-holder"
      key={ type }
      name={ names[type] }
      value={ value }
      max={ max }
      startColor={ gradientColors[type][0] }
      endColor={ gradientColors[type][1] }
      type={ type }
      amountColor={ colors[type] }
      notFilter={ true }
    />
  );
}

export default MissionsEndSkills;
