import { TABLET, MOBILE, DESKTOP } from '../constants/screens';
import * as Sizes from '../constants';

export default () => {
  const widthClient = document.documentElement.clientWidth;
  let screenType = DESKTOP;

  if (widthClient < Sizes.TABLET && widthClient >= Sizes.MOBILE) {
    screenType = TABLET;
  }

  if (widthClient < Sizes.MOBILE) {
    screenType = MOBILE;
  }

  return screenType;
};
