import React from 'react';

import GiftGot from '~/components/GiftGot/GiftGot';

import './locationItems.scss';

const LocationItems = ({ gifts }) => {
  const renderItem = (gift) => (
    <li key={ gift.id } className="location-items__item">
      <GiftGot gift={ gift } isName={ false } withContainer={ true }/>
    </li>
  );

  return (
    <ul className="location-items">
      { gifts.map((item) => renderItem(item)) }
    </ul>
  );
}

export default LocationItems;
