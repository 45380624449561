import React from 'react';
import { connect } from 'react-redux';
import { getLocationsArray } from '~/store/locations/selector'
import LocationCard from '~/components/LocationCard/LocationCard'

import './locations.scss';

const Locations = ({ locations }) => {
  const renderItems = () => {
    return locations.map((item, index) => (
      <li className="locations__item" key={index}>
        <LocationCard data={item} />
      </li>
    ))
  }

  return (
    <ul className="locations">
      { renderItems() }
    </ul>
  );
}

function mapStateToProps(state) {
  return {
    locations: getLocationsArray(state),
    isFulfilled: state.locations.isFulfilled
  };
}

export default connect(mapStateToProps)(Locations);
