import texts from './texts';
import levels from './levels';

import getContent from './services/getContent';
import getShuffleArray from '~/services/get-shuffle-array';

export default  {
  id: 7,
  type: 'dynamicMemory',
  eventCategory: 'DynamicMemory',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Спецагентам нужно держать в голове много информации одновременно. Чтобы в ней не запутаться, развивай динамическую память!',
  previewUrl: require('~/static/image/trainerPreviews/dynamic.png'),
  params: {
    ...texts,
    name: 'Динамическая память',
    hasTimer: true,
    startsImmediately: true,
    maxSkills:  {
      logic: 2,
      memory: 1
    },
    skills: [ 'logic', 'memory' ],
    mainSkill: 'logic',
    stageAmount: 3,
    subStageAmount: 2,
    isShowStage: true,
    levels,
    matrixImages: (packName) => getShuffleArray(getContent(packName))
  }
}
