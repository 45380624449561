import React from 'react';
import cx from 'classnames';

import Fail from '~/components/Fail/Fail';
import Ok from '~/components/Ok/Ok';

import './option.scss';

const Option = (props) => {
  const { id, text, selectedOptions, isCheck, selectOption, question } = props;
  // наш ответ на текущий вопрос
  const answerId = Number(selectedOptions[question.id]);
  // выбрана ли эта карточка
  const isSelected = answerId === id;
  // правильно ли мы ответили на текущий вопрос
  const isCorrectAnswer = answerId === question.answerId;
  // текущая карточка - наш правильный ответ
  const correctChoice = isCheck && isSelected && isCorrectAnswer;
  // текущая карточка - наш неправильный ответ
  const inCorrectChoice = isCheck && isSelected && !isCorrectAnswer;
  // текущая карточка - правильный ответ на вопрос
  const isCorrectOption = isCheck && question.answerId === id;
  const classesOption = cx('option', {
    'option_selected': isSelected,
    'option_correct': isCorrectOption,
    'option_incorrect-choice': inCorrectChoice,
    'option_correct-choice': correctChoice,
    'option_check': isCheck
  });

  return (
    <div className="option__holder">
      <div className={ classesOption }>
        { correctChoice && <Ok /> }
        { inCorrectChoice && <Fail /> }

        <button type="button" className='option__btn' onClick={ () => selectOption(question.id, id) }>
          { text }
        </button>
      </div>
    </div>
  );
};

export default Option;
