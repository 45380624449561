import PropTypes from 'prop-types'

export default {
  label: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
  exitButtonText: PropTypes.string,
  exitButtonOnClick: PropTypes.func,
};
