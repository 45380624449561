export default {
  image: require('~/static/trainers/funnyPoints/pike.png'),
  width: 800,
  height: 919,
  points: [
    { id: 1,  x: -313, y: 144 },
    { id: 2,  x: -210, y: 170 },
    { id: 3,  x: -118, y: 167 },
    { id: 4,  x: -27,  y: 130 },
    { id: 5,  x: 71,   y: 143 },
    { id: 6,  x: 159,  y: 211 },
    { id: 7,  x: 267,  y: 190 },
    { id: 8,  x: 307,  y: 371 },
    { id: 9,  x: 252,  y: 401 },
    { id: 10, x: 230,  y: 515 },
    { id: 11, x: 171,  y: 607 },
    { id: 12, x: 63,   y: 673 },
    { id: 13, x: 1,    y: 769 },
    { id: 14, x: -116, y: 777 },
    { id: 15, x: -1,   y: 679 },
    { id: 16, x: -168, y: 690 },
    { id: 17, x: -72,  y: 611 },
    { id: 18, x: 37,   y: 640 },
    { id: 19, x: 91,   y: 547 },
    { id: 20, x: 88,   y: 435 }
  ]
};
