import skills from '~/store/profile/skills';

export default (trainerIds, trainers, earnedSkills) => {
  let result = {};

  trainerIds.forEach((id) => {
    const currentTrainer = trainers[id];
    const { maxSkills } = currentTrainer.params;

    Object.keys(maxSkills).forEach((skill) => {
      if (result[skill]) {
        result[skill].max += maxSkills[skill];
      }
      else {
        result[skill] = {
          max: maxSkills[skill],
          title: skills[skill].name
        };
      }
    });
  });

  Object.keys(earnedSkills).forEach((skill) => {
    if (result[skill]) {
      result[skill].value = earnedSkills[skill];
    } else {
      result[skill] = {
        value: earnedSkills[skill]
      };
    }
  });

  return result;
};
