export default ({ timer }) => {
  const tooltipContent = `<b>1.</b> Прочитай слово.<br/>
                        <b>2.</b> Из букв данного слова составь новые слова.<br/>
                        <b>3.</b> Помни, слова должны быть только существительными в начальной форме. <br/>`;
  return {
    timer: [ timer * 1000 ],
    tooltipContent,
    winnerPoints: {
      mind: 2,
      logic: 1
    }
  };
};
