import React from 'react'
import PageNotFound from '~/containers/PageNotFound/PageNotFound'
import { connect } from 'react-redux';

export default (Component) => {
  const HasRoute = ({ locations, trainers, match }) => {
    const { params } = match;
    const { missionSlug, locationSlug, trainerSlug } = params;
    let hasRoute = false

    if (missionSlug) {
      if (locations[locationSlug] && locations[locationSlug].missions[missionSlug]) {
        hasRoute = true
      }
    } else if (locationSlug && locations[locationSlug]) {
      hasRoute = true
    } else if (trainerSlug && trainers[trainerSlug] && trainers[trainerSlug].type !== 'longMemory') {
      hasRoute = true
    }

    return (
      hasRoute ?
      <Component match={ match } /> :
      <PageNotFound />
    )
  }

  function mapStateToProps(state) {
    return {
      locations: state.locations.data,
      trainers: state.trainers.data
    };
  }

  return connect(mapStateToProps)(HasRoute);
}
