export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    tablet: 5,
    mobile: 15
  },
  outline: [
    {
      desktop: {
        x: 349,
        y: 99
      },
      tablet: {
        x: 385,
        y: 51
      },
      mobile: {
        x: 276,
        y: 33
      }
    },
    {
      desktop: {
        x: 394,
        y: 53
      },
      tablet: {
        x: 345,
        y: 91
      },
      mobile: {
        x: 246,
        y: 63
      }
    },
    {
      desktop: {
        x: 394,
        y: 144
      },
      tablet: {
        x: 345,
        y: 170
      },
      mobile: {
        x: 246,
        y: 122
      }
    },
    {
      desktop: {
        x: 349.36,
        y: 187.64
      },
      tablet: {
        x: 265.43,
        y: 249.57
      },
      mobile: {
        x: 186.57,
        y: 181.43
      }
    },
    {
      desktop: {
        x: 476.28,
        y: 187.64
      },
      tablet: {
        x: 207,
        y: 249.57
      },
      mobile: {
        x: 143,
        y: 181.43
      }
    },
    {
      desktop: {
        x: 412.96,
        y: 250.96
      },
      tablet: {
        x: 207,
        y: 170
      },
      mobile: {
        x: 143,
        y: 122
      }
    },
    {
      desktop: {
        x: 439,
        y: 277
      },
      tablet: {
        x: 167,
        y: 210
      },
      mobile: {
        x: 113,
        y: 152
      }
    },
    {
      desktop: {
        x: 325.56,
        y: 277
      },
      tablet: {
        x: 127,
        y: 170
      },
      mobile: {
        x: 83,
        y: 122
      }
    },
    {
      desktop: {
        x: 198.64,
        y: 403.92
      },
      tablet: {
        x: 127,
        y: 250
      },
      mobile: {
        x: 83,
        y: 182
      }
    },
    {
      desktop: {
        x: 198.64,
        y: 278.36
      },
      tablet: {
        x: 167,
        y: 290
      },
      mobile: {
        x: 113,
        y: 212
      }
    },
    {
      desktop: {
        x: 155,
        y: 322
      },
      tablet: {
        x: 206.57,
        y: 250.43
      },
      mobile: {
        x: 142.43,
        y: 182.57
      }
    },
    {
      desktop: {
        x: 110.62,
        y: 277.62
      },
      tablet: {
        x: 206.57,
        y: 362.71
      },
      mobile: {
        x: 142.43,
        y: 266.28
      }
    },
    {
      desktop: {
        x: 110.62,
        y: 187
      },
      tablet: {
        x: 319.27,
        y: 250
      },
      mobile: {
        x: 226.71,
        y: 182
      }
    },
    {
      desktop: {
        x: 155.37,
        y: 231.5
      },
      tablet: {
        x: 425,
        y: 250
      },
      mobile: {
        x: 306,
        y: 182
      }
    },
    {
      desktop: {
        x: 199.62,
        y: 187
      },
      tablet: {
        x: 401.85,
        y: 226.85
      },
      mobile: {
        x: 288.64,
        y: 164.64
      }
    },
    {
      desktop: {
        x: 199.62,
        y: 276.64
      },
      tablet: {
        x: 458.14,
        y: 170.57
      },
      mobile: {
        x: 330.85,
        y: 122.43
      }
    },
    {
      desktop: {
        x: 259.36,
        y: 276.64
      },
      tablet: {
        x: 345.43,
        y: 170.57
      },
      mobile: {
        x: 246.57,
        y: 122.43
      }
    },
    {
      desktop: {
        x: 349,
        y: 187
      },
      tablet: {
        x: 385,
        y: 131
      },
      mobile: {
        x: 276,
        y: 93
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 259,
        y: 277,
        rotation: 90
      },
      tablet: {
        x: 265,
        y: 250,
        rotation: 90
      },
      mobile: {
        x: 186,
        y: 182,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 198.64,
        y: 403.92,
        rotation: 135
      },
      tablet: {
        x: 206.57,
        y: 362.71,
        rotation: 135
      },
      mobile: {
        x: 142.43,
        y: 266.28,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 412.64,
        y: 124,
        rotation: -45
      },
      tablet: {
        x: 401.57,
        y: 114,
        rotation: -45
      },
      mobile: {
        x: 288.43,
        y: 80,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 154.62,
        y: 187,
        rotation: 0
      },
      tablet: {
        x: 167,
        y: 170,
        rotation: 0
      },
      mobile: {
        x: 113,
        y: 122,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 155.62,
        y: 277,
        rotation: 180
      },
      tablet: {
        x: 167,
        y: 250,
        rotation: 180
      },
      mobile: {
        x: 113,
        y: 182,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 349,
        y: 53,
        rotation: -0
      },
      tablet: {
        x: 345,
        y: 51,
        rotation: -0
      },
      mobile: {
        x: 246,
        y: 33,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 110,
        y: 232,
        rotation: 0
      },
      tablet: {
        x: 127,
        y: 210,
        rotation: 0
      },
      mobile: {
        x: 83,
        y: 152,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
