import React, { useState, useEffect } from 'react';

import Tabs from '~/components/Tabs/Tabs';
import Button from '~/components/Button/Button';
import IntellectualWorkoutsControls from './IntellectualWorkoutsControls/IntellectualWorkoutsControls';

import getShuffleArray from '~/services/get-shuffle-array';

import { BUTTON_NEXT } from '~/utils/constants';

import './trainer-intellectual-workouts.scss';

const TrainerIntellectualWorkouts = (props) => {
  const { params, isTimerActive, onStartCb, onFinishCb, onStageChange, onTimeEnd, handleDataLayer } = props;
  const { difficultyParams } = params;
  const { amount, questions, winnerPoints } = difficultyParams;

  const [ wins, setWins ] = useState(0);
  const [ isWin, setWin ] = useState(false);
  const [ isFinish, setFinish ] = useState(false);
  const [ currentQuestions, setCurrentQuestions ] = useState([]);
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ currentAnswerIndex, setCurrentAnswerIndex ] = useState(null);

  const currentQuestion = currentQuestions[currentIndex] || {};
  const { text, answer } = currentQuestion;

  const nextQuestion = () => setCurrentIndex(currentIndex + 1);
  const incrementWins = () => setWins(wins + 1);
  const isLast = amount === currentIndex + 1;
  const isRenderButton = isFinish && !isLast;

  useEffect(() => {
    const newCurrentQuestions = getShuffleArray(questions, amount);
    setCurrentQuestions(newCurrentQuestions);
  }, []);

  useEffect(() => {
    if (!isTimerActive && isLast) {
      checkVictory();
    }

    if (!isTimerActive && currentAnswerIndex === null) {
      handleDataLayer({
        event: 'timerTick',
        eventAction: 'next_force',
        stageName: 'page2_solve',
        stageLevel: currentIndex + 1
      });
      setFinish(true);
      setWin(false);
    }

    if (!isTimerActive && currentAnswerIndex !== null) {
      handleDataLayer({ stageName: 'page2_solve', eventAction: 'next_auto', stageLevel: currentIndex + 1 });
    }
  }, [ currentIndex, isTimerActive ]);

  const checkVictory = () => {
    let reason = 'complete';
    let skills = winnerPoints;

    if (amount !== wins) {
      reason = 'fail';
      skills = null;
    }

    onFinishCb({ victory: Boolean(skills), reason, skills });
  };

  const renderButton = () => {
    const handlerClick = () => {
      setFinish(false);
      setWin(false);
      setCurrentAnswerIndex(null);
      handleDataLayer({ stageName: 'page3_check', stageLevel: currentIndex + 1 });

      if (!isLast) {
        onStageChange();
        onStartCb();
        nextQuestion();
      }
    };

    return (
      <div className="trainer-intellectual-workouts__next">
        <Button className="button_center" onClick={ handlerClick }>
          { BUTTON_NEXT }
        </Button>
      </div>
    );
  };

  return (
    currentQuestion &&
    <div className="trainer-intellectual-workouts">
      {
        isFinish && isWin &&
        <div className="trainer-intellectual-workouts__tabs">
          <Tabs isCorrect={ true }/>
        </div>
      }
      <div className="trainer-intellectual-workouts__title">{ text }</div>
      <IntellectualWorkoutsControls currentAnswerIndex={ currentAnswerIndex } isWin={ isWin } setWin={ setWin }
                                    setCurrentAnswerIndex={ setCurrentAnswerIndex } onTimeEnd={ onTimeEnd }
                                    isFinish={ isFinish } incrementWins={ incrementWins } setFinish={ setFinish }
                                    answer={ answer }/>
      { isRenderButton && renderButton({ nextQuestion, setFinish, setCurrentAnswerIndex, ...props }) }
    </div>
  );
};

export default TrainerIntellectualWorkouts;
