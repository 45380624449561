import React from 'react';
import cx from 'classnames';

import EditCell from '../EditCell';

import { MARGIN } from '../constants';

import './zone.scss';

const Zone = (props) => {
  const { sizeZone, verticalOffset, horizontalOffset, field, setCurrentCell, isOver,
          currentValue, currentCol, currentRow, sizeField } = props;
  const currentHorizontalOffset = horizontalOffset * sizeZone;
  const currentVerticalOffset = verticalOffset * sizeZone;
  const widthZone = sizeField / sizeZone - MARGIN;

  const currentZone = () => {
    let result = [];
    const limitHorizontal = currentHorizontalOffset + sizeZone;
    const limitVertical = currentVerticalOffset + sizeZone;

    for (let i = currentHorizontalOffset; i < limitHorizontal; i++) {
      let rows = [];

      for (let j = currentVerticalOffset; j < limitVertical; j++) {
        rows.push(field[i][j]);
      }
      result.push(rows);
    }

    return result;
  };

  const zoneStyle = { width: `${ widthZone }px` };

  const setCurrentOffsets = ({ currentCol, currentRow }) => {
    if (typeof(currentCol) === 'number' && typeof(currentRow) === 'number') {
      currentRow += currentHorizontalOffset;
      currentCol += currentVerticalOffset;
    }
    setCurrentCell({ currentCol, currentRow });
  };

  const renderRow = (row, rowIndex) => {
    return row.map((item, index) => {
      const className = cx('zone__cell', {
        'zone__cell_similar': currentValue === item,
        'zone_radius-top-left': rowIndex === 0 && index === 0,
        'zone_radius-top-right': rowIndex === 0 && index === sizeZone - 1,
        'zone_radius-bottom-left': rowIndex === sizeZone - 1 && index === 0,
        'zone_radius-bottom-right': rowIndex === sizeZone - 1 && index === sizeZone - 1,
      });
      const isActive = currentCol === index + currentVerticalOffset && currentRow === rowIndex + currentHorizontalOffset;
      const isEditCell = item.value !== undefined;
      const cellSize = widthZone / sizeZone - MARGIN * 0.5;
      const cellStyle = { width: `${ cellSize }px`, height: `${ cellSize }px` };

      return (
        isEditCell ?
        <EditCell key={ index } rowIndex={ rowIndex } index={ index } sizeZone={ sizeZone } item={ item }
                  isOver={ isOver } currentValue={ currentValue } isActive={ isActive }
                  setCurrentCell={ setCurrentOffsets } cellSize={ cellSize }/> :
        <div key={ index } className={ className } style={ cellStyle }>{ item }</div>
      );
    });
  }

  const renderZone = () => field.length && currentZone().map((row, rowIndex) => renderRow(row, rowIndex));

  return (
    <div className="zone" style={ zoneStyle }>
      { renderZone() }
    </div>
  );
};

export default Zone;
