const properties = [ 'padding-left', 'padding-top', 'padding-bottom', 'padding-right',
                     'margin-left',  'margin-top',  'margin-bottom',  'margin-right',
                     'width',  'height' ];
const mapProperties = {
  'padding-left': 'paddingLeft',
  'padding-top': 'paddingTop',
  'padding-bottom': 'paddingBottom',
  'padding-right': 'paddingRight',
  'margin-left': 'marginLeft',
  'margin-top': 'marginTop',
  'margin-bottom': 'marginBottom',
  'margin-right': 'marginRight',
  'width': 'width',
  'height': 'height'
};

export default (node) => {
  const getPropertyValue = (name) => window.getComputedStyle(node).getPropertyValue(name).slice(0, -2);

  return properties.reduce((result, name) => ({ ...result, [mapProperties[name]]: Number(getPropertyValue(name)) }), {});
};
