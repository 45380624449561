import gifts from '~/shared/gifts';

export default {
  id: 5,
  name: 'Останкинская башня',
  longMemory: true,
  description: 'Одно из самых узнаваемых сооружений Москвы – Останкинская башня.',
  partingWords: 'Твои навыки становятся всё совершеннее, что даёт тебе возможность браться за новые миссии. Миссии проходят в разных точках нашего мира, и там не всегда прекрасная погода. Поэтому тебе точно пригодится такой предмет, как зонт. Пройди новую миссию, и зонт спецагента будет в твоём наборе!',
  story: `В свое время Останкинская башня считалась самым высоким зданием в мире.
          Информация для спецагента:
          1. При сильном ветре амплитуда отклонения верхней точки башни доходит до 10 метров.
          2. Из-за своей высоты башня очень часто становится объектом для удара молний. Всего за год бывает до 50 таких случаев.
          3. В башне отсутствует привычная нумерация этажей. Этаж носит название «отметка», а ее номер соответствует высоте расположения уровня.
          4. Сорок пять этажей башни соответствуют обычному зданию, состоящему из ста восьмидесяти этажей.`,
  textLosing: 'Эх... Миссия не пройдена. Но Мистер Фоксман всегда готов помочь своим ученикам. Он ждёт тебя в тренажёрном зале, чтобы ты потренировал свои навыки! После тренировок обязательно возвращайся. Миссия должна быть выполнена!',
  textPartially: 'Не все задания получилось пройти. Мистер Фоксман ожидает тебя в тренажёрном зале, чтобы потренировать необходимые навыки и пройти миссию!',
  textSuccess: 'Миссия выполнена! Мистер Фоксман гордится твоими успехами. Теперь выполнять миссии станет ещё проще. Зонт спецагента уже в твоём наборе агента!',
  gift: gifts.umbrella,
  imgs: [
    require('~/static/locations/moscow/ostankino_980х590.jpg'),
  ]
};
