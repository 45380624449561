import texts from './texts';
import levels from './levels';

export default {
  id: 9,
  type: 'anagram',
  eventCategory: 'Anagram',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Видеть в разобранном целое под силу только спецагентам. Научись составлять из отдельных букв целые слова!',
  previewUrl: require('~/static/image/trainerPreviews/anagram.png'),
  params: {
    ...texts,
    name: 'Анаграмма',
    hasTimer: true,
    startsImmediately: true,
    mistakesAvailable: 1,
    maxSkills: {
      mind: 2,
      logic: 1
    },
    skills: [ 'mind', 'logic' ],
    mainSkill: 'mind',
    levels
  }
}
