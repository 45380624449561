export default [
  {
    src: require(`~/static/trainers/spatial/level4/1.png`),
    answer: 18
  },
  {
    src: require(`~/static/trainers/spatial/level4/2.png`),
    answer: 23
  },
  {
    src: require(`~/static/trainers/spatial/level4/3.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level4/4.png`),
    answer: 13
  },
  {
    src: require(`~/static/trainers/spatial/level4/5.png`),
    answer: 14
  },
  {
    src: require(`~/static/trainers/spatial/level4/6.png`),
    answer: 18
  },
  {
    src: require(`~/static/trainers/spatial/level4/7.png`),
    answer: 14
  },
  {
    src: require(`~/static/trainers/spatial/level4/8.png`),
    answer: 15
  },
  {
    src: require(`~/static/trainers/spatial/level4/9.png`),
    answer: 14
  },
  {
    src: require(`~/static/trainers/spatial/level4/10.png`),
    answer: 13
  },
  {
    src: require(`~/static/trainers/spatial/level4/11.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level4/12.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level4/13.png`),
    answer: 10
  },
  {
    src: require(`~/static/trainers/spatial/level4/14.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level4/15.png`),
    answer: 13
  },
  {
    src: require(`~/static/trainers/spatial/level4/16.png`),
    answer: 13
  },
  {
    src: require(`~/static/trainers/spatial/level4/17.png`),
    answer: 11
  },
  {
    src: require(`~/static/trainers/spatial/level4/18.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level4/19.png`),
    answer: 8
  },
  {
    src: require(`~/static/trainers/spatial/level4/20.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level4/21.png`),
    answer: 19
  },
  {
    src: require(`~/static/trainers/spatial/level4/22.png`),
    answer: 12
  },
  {
    src: require(`~/static/trainers/spatial/level4/23.png`),
    answer: 17
  },
  {
    src: require(`~/static/trainers/spatial/level4/24.png`),
    answer: 13
  },
  {
    src: require(`~/static/trainers/spatial/level4/25.png`),
    answer: 12
  }
];
