const MIN_PREVIEW_COUNT = 3;
const MAX_PREVIEW_COUNT = 4;

export default () => {
  const { clientWidth } = document.documentElement;

  if (clientWidth < 340 || (clientWidth > 428 && clientWidth < 440) ||
     (clientWidth > 625 && clientWidth < 686) || (clientWidth > 792 && clientWidth < 1200)) {
    return MIN_PREVIEW_COUNT;
  }

  return MAX_PREVIEW_COUNT;
};
