export default {
  texts: {
    description: [
      'Взглянул – и всё запомнил? Это доступно самым крутым спецагентам. Хочешь обладать такими же способностями – развивай зрительную память!',
      'Ведение наблюдений – это обычные будни спецагентов. Чтобы всё быстро и точно фиксировать, надо развивать зрительную память. Выполняй упражнения – и будешь запоминать всё гораздо быстрее!'
    ],
    losing: [
      'Потренируйся ещё. Пока что данный навык плохо прокачан!',
      'В следующий раз точно всё получится! Попробуй ещё раз!'
    ],
    victory: [
      'Ура! Ты справился. Для тебя уже готова новая миссия!',
      'Так держать! Теперь ты готов к важным заданиям от мистера Фоксмана!'
    ]
  },
  textsInMission: {
    description: [
      'Тебе необходимо доставить в штаб-квартиру важные документы. Забрать ты их сможешь, только когда найдешь каждой картинке пару. Работай как можно быстрее! Когда время закончится, все документы самоликвидируются.',
      'Для выполнения данного задания тебе пригодится тайное оружие спецагента – отличная память. Используй его, чтобы запомнить и найти каждой картинке пару. Помни: время бежит очень быстро!'
    ],
    losing: [
      'Не все пары были собраны. Но не стоит расстраиваться – потренируй навык в тренажёрном зале, и всё точно получится!',
      'Память очень важна для спецагента. Потренируй этот навык в тренажёрном зале!'
    ],
    victory: [
      'Как ты быстро всё запомнил! Отличный спецагент в наших рядах!',
      'Все пары собраны! Отличный результат. Новые задания ждут тебя!'
    ]
  }
};
