import PropTypes from 'prop-types';

export default {
  params: PropTypes.shape({
    difficultyParams: PropTypes.object,
    levels: PropTypes.objectOf(PropTypes.shape({
      blocksQuantity: PropTypes.number,
      rememberTimer: PropTypes.number,
      timer: PropTypes.arrayOf(PropTypes.number),
      gamePoints: PropTypes.number,
      tooltipContent: PropTypes.string,
      textAnnounce: PropTypes.string,
    })),
    skillPoints: PropTypes.object,
  }).isRequired,
  isTimerActive: PropTypes.bool.isRequired,
  onStartCb: PropTypes.func.isRequired,
  onFinishCb: PropTypes.func.isRequired,
};
