import React, { useState, useEffect, useRef, useMemo } from 'react';
import cx from 'classnames';

import Tabs from '~/components/Tabs/Tabs';
import Button from '~/components/Button/Button';
import NumberPanel from '~/components/NumberPanel/NumberPanel';
import DynamicContent from '~/components/DynamicContent/DynamicContent';

import getShuffleArray from '~/services/get-shuffle-array';

import { BUTTON_NEXT, BUTTON_CHECK } from '~/utils/constants';

import './trainer-number-series.scss';

const TrainerNumberSeries = (props) => {
  const { params, isTimerActive, onStartCb, onFinishCb, onStageChange, handleDataLayer,
          onTimeEnd, widthWorkspace, heightWorkspace } = props;
  const { difficultyParams, stageAmount } = params;
  const { content, winnerPoints } = difficultyParams;

  const panelRef = useRef(null);
  const [ wins, setWins ] = useState(0);
  const [ isWin, setWin ] = useState(false);
  const [ isFinish, setFinish ] = useState(false);
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ currentAnswer, setCurrentAnswer ] = useState('');
  const currentNumbersSeries = useMemo(() => getShuffleArray(content, stageAmount), []);

  const currentNumbers = currentNumbersSeries[currentIndex] || {};
  const { sequence, actions, answer } = currentNumbers;
  const generateCurrentSequence = () => sequence.reduce((acc, item, index) => [ ...acc, { number: item, action: actions[index % actions.length] } ], []);
  const currentSequence = useMemo(generateCurrentSequence, [ currentIndex ]);

  const isLast = stageAmount === currentIndex + 1;
  const isRenderButton = (!isFinish && currentAnswer) || (isFinish && !isLast);
  const isShowAction = !isWin && isFinish;
  const endNumber = isFinish ? answer : '?';
  const handlerChange = (value) => setCurrentAnswer(value);
  const classesTitle = cx('trainer-number-series__title', {
    'trainer-number-series__title_ended': isFinish
  });

  useEffect(() => {
    if (!isTimerActive && !isFinish) {
      finishStage();
      handleDataLayer({
        stageName: 'page2_solve',
        event: 'timerTick',
        eventAction: 'next_force',
        stageLevel: currentIndex + 1
      });
    }

    if (!isTimerActive && isLast) {
      const isRightAnswer = answer === Number(currentAnswer);
      checkVictory(isFinish ? 0 : Number(isRightAnswer));
    }
  }, [ isTimerActive ]);

  const checkVictory = (lastWin) => {
    let reason = 'complete';
    let skills = winnerPoints;

    if (stageAmount !== wins + lastWin) {
      reason = 'fail';
      skills = null;
    }

    onFinishCb({ victory: Boolean(skills), reason, skills });
  };

  const finishStage = () => {
    const win = answer === Number(currentAnswer);
    const winsAmount = win ? wins + 1 : wins;
    setFinish(true);
    setWin(win);
    setWins(winsAmount);
    onTimeEnd();
  };

  const startStage = () => {
    onStageChange();
    setFinish(false);
    setCurrentAnswer('');
    setCurrentIndex(currentIndex + 1);
    onStartCb();
  };

  const renderButton = () => {
    let text = BUTTON_CHECK;
    let handleClick = () => {
      finishStage();
      handleDataLayer({ stageName: 'page2_solve', stageLevel: currentIndex + 1 });
    };

    if (isFinish) {
      text = BUTTON_NEXT;
      handleClick = () => {
        startStage();
        handleDataLayer({ stageName: 'page3_check', stageLevel: currentIndex + 1 });
      };
    }

    return (
      <div className="trainer-number-series__next">
        <Button className="button_center" onClick={ handleClick }>
          { text }
        </Button>
      </div>
    );
  };

  return (
    <section className="trainer-number-series">
      {
        isWin && isFinish &&
        <div className="trainer-number-series__tabs">
          <Tabs isCorrect={ true }/>
        </div>
      }
      <DynamicContent widthWorkspace={ widthWorkspace } heightWorkspace={ heightWorkspace } refs={ [ panelRef ] }>
        <div className={ classesTitle }>Продолжи числовой ряд</div>
        <div className="trainer-number-series__numbers">
          { renderSequence(currentSequence, isShowAction) }
          { renderEndNumber(endNumber, isFinish ) }
        </div>
      </DynamicContent>
      <div ref={ panelRef } className="trainer-number-series__panel">
        <NumberPanel onChange={ handlerChange } currentValue={ currentAnswer } isFinish={ isFinish } isWin={ isWin }/>
      </div>
      { isRenderButton && renderButton() }
    </section>
  );
};

const renderSequence = (sequence, isShowAction) => {
  return sequence.map((item, index) => {
    const { number, action } = item;
    return (
      <div className="trainer-number-series__number-holder" key={ index }>
        { isShowAction && <div className="trainer-number-series__action">{ action }</div> }
        <div className="trainer-number-series__number">{ number }</div>
      </div>
    );
  });
};

const renderEndNumber = (item, isFinish) => {
  const classesNumber = cx('trainer-number-series__number', {
    'trainer-number-series__number_quest': !isFinish,
    'trainer-number-series__number_ended': isFinish
  });

  return (
    <div className="trainer-number-series__number-holder">
      <div className={ classesNumber }>{ item }</div>
    </div>
  );
};

export default TrainerNumberSeries;
