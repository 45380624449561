export const SAVE_MISSION_PROGRESS = 'SAVE_MISSION_PROGRESS';
export const SAVE_TRAINER_PROGRESS = 'SAVE_TRAINER_PROGRESS';
export const SAVE_RUNNED_TRAINER = 'SAVE_RUNNED_TRAINER';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const UPGRADE_SKILL = 'UPGRADE_SKILL';
export const DOWNGRATE_SKILL = 'DOWNGRATE_SKILL';
export const SAVE_MISSION_PARAMS = 'SAVE_MISSION_PARAMS';
export const SAVE_PREV_SKILLS = 'SAVE_PREV_SKILLS';

export const MAX_SKILL_SUCCESS = 2;
