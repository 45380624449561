export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    tablet: 8,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 122.62,
        y: 48
      },
      tablet: {
        x: 141,
        y: 129
      },
      mobile: {
        x: 108,
        y: 110
      }
    },
    {
      desktop: {
        x: 167.37,
        y: 92.5
      },
      tablet: {
        x: 141,
        y: 49
      },
      mobile: {
        x: 108,
        y: 50
      }
    },
    {
      desktop: {
        x: 211.62,
        y: 48
      },
      tablet: {
        x: 180.5,
        y: 88.5
      },
      mobile: {
        x: 137,
        y: 79
      }
    },
    {
      desktop: {
        x: 211.62,
        y: 138.35
      },
      tablet: {
        x: 220,
        y: 49
      },
      mobile: {
        x: 166,
        y: 50
      }
    },
    {
      desktop: {
        x: 222,
        y: 128
      },
      tablet: {
        x: 220,
        y: 127
      },
      mobile: {
        x: 166,
        y: 108.3
      }
    },
    {
      desktop: {
        x: 311,
        y: 128
      },
      tablet: {
        x: 230,
        y: 117
      },
      mobile: {
        x: 173.14,
        y: 101
      }
    },
    {
      desktop: {
        x: 401.28,
        y: 218.24
      },
      tablet: {
        x: 309,
        y: 117
      },
      mobile: {
        x: 233,
        y: 101
      }
    },
    {
      desktop: {
        x: 401.28,
        y: 344.28
      },
      tablet: {
        x: 389.14,
        y: 197.14
      },
      mobile: {
        x: 292.86,
        y: 160.86
      }
    },
    {
      desktop: {
        x: 274,
        y: 344.28
      },
      tablet: {
        x: 389.14,
        y: 309.14
      },
      mobile: {
        x: 292.85,
        y: 245.85
      }
    },
    {
      desktop: {
        x: 311,
        y: 307.28
      },
      tablet: {
        x: 276,
        y: 309.14
      },
      mobile: {
        x: 208,
        y: 245.85
      }
    },
    {
      desktop: {
        x: 311,
        y: 254.64
      },
      tablet: {
        x: 309,
        y: 276.14
      },
      mobile: {
        x: 233,
        y: 220.85
      }
    },
    {
      desktop: {
        x: 248,
        y: 191.64
      },
      tablet: {
        x: 309,
        y: 229.57
      },
      mobile: {
        x: 233,
        y: 185.43
      }
    },
    {
      desktop: {
        x: 266.64,
        y: 173
      },
      tablet: {
        x: 253,
        y: 173.57
      },
      mobile: {
        x: 191,
        y: 143.43
      }
    },
    {
      desktop: {
        x: 177,
        y: 173
      },
      tablet: {
        x: 269.57,
        y: 157
      },
      mobile: {
        x: 203.43,
        y: 131
      }
    },
    {
      desktop: {
        x: 167,
        y: 183
      },
      tablet: {
        x: 191,
        y: 157
      },
      mobile: {
        x: 145,
        y: 131
      }
    },
    {
      desktop: {
        x: 122.5,
        y: 138.5
      },
      tablet: {
        x: 180,
        y: 168
      },
      mobile: {
        x: 137,
        y: 139
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 312,
        y: 128,
        rotation: -90
      },
      tablet: {
        x: 310,
        y: 117,
        rotation: -90
      },
      mobile: {
        x: 234,
        y: 101,
        rotation: -90
      },
      rotation: 90
    },
    {
      desktop: {
        x: 311,
        y: 128,
        rotation: 0
      },
      tablet: {
        x: 309,
        y: 117,
        rotation: 0
      },
      mobile: {
        x: 233,
        y: 101,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 401.28,
        y: 217,
        rotation: -45
      },
      tablet: {
        x: 389.14,
        y: 196,
        rotation: -45
      },
      mobile: {
        x: 292.85,
        y: 161,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 375.28,
        y: 191.64,
        rotation: -135
      },
      tablet: {
        x: 366.14,
        y: 173.57,
        rotation: -135
      },
      mobile: {
        x: 275.85,
        y: 143.43,
        rotation: -135
      },
      rotation: 135
    },
    {
      desktop: {
        x: 166.62,
        y: 48,
        rotation: 0
      },
      tablet: {
        x: 180,
        y: 49,
        rotation: 0
      },
      mobile: {
        x: 136,
        y: 50,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 167.62,
        y: 138,
        rotation: 180
      },
      tablet: {
        x: 181,
        y: 129,
        rotation: 180
      },
      mobile: {
        x: 138,
        y: 110,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 122,
        y: 93,
        rotation: 0
      },
      tablet: {
        x: 140,
        y: 88,
        rotation: 0
      },
      mobile: {
        x: 107,
        y: 79,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
