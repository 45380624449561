import axios from 'axios';

class FetchService {
  constructor(config) {
    this.instance = axios.create(config);
  }

  request(url, options = {}) {
    const config = {
      url,
      ...options,
    };

    return new Promise((resolve, reject) => {
      if (!url) reject(new Error('URL parameter required'));
      return this.instance
        .request(config)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    }).catch(FetchService.handleError);
  }

  static handleError(error) {
    if (error.response && error.response.data.errors) {
      throw error.response.data.errors.error;
    } else if (error.request) {
      throw new Error(`No response was received for ${error.request.url}`);
    }
    if (error.config) {
      // console.error(error.config);
    }

    throw error;
  }
}

export default FetchService;
