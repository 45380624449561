import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring'
import cx from 'classnames';

import Icon from '~/components/Icon/Icon';

/* eslint-disable */
import bubblePoints from './assets/bubblePoints.svg';
import bubblePointsDark from './assets/bubblePointsDark.svg';
/* eslint-enable */

import './pointsGot.scss';

const PointsGot = ({ points, total, pointsOf, currentLevel, isGiftPopup }) => {
  let timer = null;

  useEffect(() => {
    setTimeout(() => {
      setStart(true)
    }, 1000)
  }, []);

  const [isStart, setStart] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    }
  })

  const barAnimation = useSpring({ width: !isStart ? '0%' : `${ total / pointsOf * 100}%)` })
  const numberAnimation = useSpring({ from: { val: 0 }, to: { val: !isStart ? 0 : points }, delay: '1000' })

  return (
    <div className={ cx('points-got', {
      'points-got_gift-popup': isGiftPopup
    }) }>
      <div className="points-got__points">
        <Icon className="points-got__icon" name="bubblePoints" />
        {
          isGiftPopup && <Icon className="points-got__icon points-got__icon_2" name="bubblePointsDark" />
        }
        <span className="points-got__value">
          <span className="points-got__plus">
            +
          </span>
          <animated.span className="points-got__number">{ numberAnimation.val.interpolate(val => Math.floor(val)) }</animated.span>
        </span>
      </div>
      <div className="points-got__bar">
        <animated.div
          className={cx('points-got__bar-fill', {
            'points-got__bar-fill_over90': (total / pointsOf) * 100 > 90,
          })}
          style={ barAnimation }
        />
        <div className="points-got__bar-value">
          <span className="points-got__bar-value-cur">{ total }</span>
          <span className="points-got__bar-value-dev">/</span>
          <span className="points-got__bar-value-all">{ pointsOf } баллов</span>
        </div>

        {
          isGiftPopup && <div className="points-got__level">{ currentLevel } уровень</div>
        }
      </div>

      {
        !isGiftPopup && <div className="points-got__level">{ currentLevel } уровень</div>
      }

    </div>
  )
}

export default PointsGot;
