export default {
  shapes: [4, 5, 1, 7, 3, 6, 8],
  offset: {
    mobile: 20
  },
  outlines: [
    {
      id: 4,
      rotation: 315,
      desktop: {
        x: 147.54,
        y: 316.82
      },
      tablet: {
        x: 164,
        y: 286.28
      },
      mobile: {
        x: 111,
        y: 210.21
      }
    },
    {
      id: 5,
      rotation: 0,
      desktop: {
        x: 248.54,
        y: 138
      },
      tablet: {
        x: 254,
        y: 127
      },
      mobile: {
        x: 177,
        y: 90
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 306.54,
        y: 170.64
      },
      tablet: {
        x: 305,
        y: 155.57
      },
      mobile: {
        x: 215,
        y: 111.43
      }
    },
    {
      id: 7,
      rotation: 225,
      desktop: {
        x: 211.18,
        y: 265.92
      },
      tablet: {
        x: 220.57,
        y: 240.71
      },
      mobile: {
        x: 152.43,
        y: 175.28
      }
    },
    {
      id: 3,
      rotation: 315,
      desktop: {
        x: 369.54,
        y: 170.82
      },
      tablet: {
        x: 361,
        y: 155.4
      },
      mobile: {
        x: 257.43,
        y: 111.5
      }
    },
    {
      id: 6,
      rotation: 180,
      desktop: {
        x: 212,
        y: 318.46
      },
      tablet: {
        x: 221,
        y: 287
      },
      mobile: {
        x: 153,
        y: 210
      }
    },
    {
      id: 8,
      rotation: 315,
      desktop: {
        x: 274.54,
        y: 227.82
      },
      tablet: {
        x: 277,
        y: 206.28
      },
      mobile: {
        x: 194,
        y: 149.21
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 338.54,
        y: 202.45999999999998
      },
      tablet: {
        x: 389.45,
        y: 127.28
      },
      mobile: {
        x: 152.57,
        y: 90.43
      }
    },
    {
      desktop: {
        x: 338.54,
        y: 228.15999999999997
      },
      tablet: {
        x: 445.97,
        y: 183.32999999999998
      },
      mobile: {
        x: 93,
        y: 150
      }
    },
    {
      desktop: {
        x: 401.82,
        y: 291.46
      },
      tablet: {
        x: 334,
        y: 183.85
      },
      mobile: {
        x: 132.21,
        y: 189.21
      }
    },
    {
      desktop: {
        x: 338.18,
        y: 291.46
      },
      tablet: {
        x: 334,
        y: 206.71
      },
      mobile: {
        x: 132.21,
        y: 231.43
      }
    },
    {
      desktop: {
        x: 274.72,
        y: 228
      },
      tablet: {
        x: 390.14,
        y: 262.85
      },
      mobile: {
        x: 174.64,
        y: 231.43
      }
    },
    {
      desktop: {
        x: 249.1,
        y: 228
      },
      tablet: {
        x: 333.57,
        y: 262.85
      },
      mobile: {
        x: 153,
        y: 209.79000000000002
      }
    },
    {
      desktop: {
        x: 212,
        y: 265.09999999999997
      },
      tablet: {
        x: 277.72,
        y: 207
      },
      mobile: {
        x: 153,
        y: 174.71
      }
    },
    {
      desktop: {
        x: 212,
        y: 317.64
      },
      tablet: {
        x: 254.26999999999998,
        y: 207
      },
      mobile: {
        x: 177.70999999999998,
        y: 150
      }
    },
    {
      desktop: {
        x: 243,
        y: 348.64
      },
      tablet: {
        x: 221,
        y: 240.26999999999998
      },
      mobile: {
        x: 194.79000000000002,
        y: 150
      }
    },
    {
      desktop: {
        x: 179.54,
        y: 348.82
      },
      tablet: {
        x: 221,
        y: 286.71000000000004
      },
      mobile: {
        x: 236.43,
        y: 191.64
      }
    },
    {
      desktop: {
        x: 179.36,
        y: 285.82
      },
      tablet: {
        x: 248.85,
        y: 314.56
      },
      mobile: {
        x: 278.85,
        y: 191.64
      }
    },
    {
      desktop: {
        x: 122,
        y: 228.45999999999998
      },
      tablet: {
        x: 192.28,
        y: 314.56
      },
      mobile: {
        x: 237,
        y: 149.79000000000002
      }
    },
    {
      desktop: {
        x: 211.82,
        y: 138.64
      },
      tablet: {
        x: 192.28,
        y: 258.28
      },
      mobile: {
        x: 237,
        y: 132.64
      }
    },
    {
      desktop: {
        x: 401,
        y: 138.82
      },
      tablet: {
        x: 141,
        y: 207
      },
      mobile: {
        x: 321.07,
        y: 132.71
      }
    },
    {
      desktop: {
        x: 465,
        y: 202.64
      },
      tablet: {
        x: 220.4,
        y: 127.6
      },
      mobile: {
        x: 278.55,
        y: 90.2
      }
    }
  ]
};
