export default {
  shapes: [ 8, 7, 6, 5, 4, 3, 1 ],
  offset: {
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: {
        x: 203,
        y: 117
      },
      tablet: {
        x: 333,
        y: 68
      },
      mobile: {
        x: 237,
        y: 45
      }
    },
    {
      desktop: {
        x: 248,
        y: 72
      },
      tablet: {
        x: 253,
        y: 68
      },
      mobile: {
        x: 177,
        y: 45
      }
    },
    {
      desktop: {
        x: 338,
        y: 72
      },
      tablet: {
        x: 213,
        y: 108
      },
      mobile: {
        x: 147,
        y: 75
      }
    },
    {
      desktop: {
        x: 337,
        y: 339
      },
      tablet: {
        x: 253,
        y: 148
      },
      mobile: {
        x: 177,
        y: 105
      }
    },
    {
      desktop: {
        x: 383,
        y: 385
      },
      tablet: {
        x: 253,
        y: 307
      },
      mobile: {
        x: 177,
        y: 225
      }
    },
    {
      desktop: {
        x: 203,
        y: 385
      },
      tablet: {
        x: 213,
        y: 347
      },
      mobile: {
        x: 147,
        y: 255
      }
    },
    {
      desktop: {
        x: 248,
        y: 340
      },
      tablet: {
        x: 373,
        y: 347
      },
      mobile: {
        x: 267,
        y: 255
      }
    },
    {
      desktop: {
        x: 248,
        y: 162
      },
      tablet: {
        x: 333,
        y: 307
      },
      mobile: {
        x: 237,
        y: 225
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 293,
        y: 117,
        rotation: 0
      },
      tablet: {
        x: 293,
        y: 108,
        rotation: 0
      },
      mobile: {
        x: 207,
        y: 75,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 203,
        y: 385,
        rotation: 90
      },
      tablet: {
        x: 213,
        y: 347,
        rotation: 90
      },
      mobile: {
        x: 147,
        y: 255,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 248,
        y: 161,
        rotation: 0
      },
      tablet: {
        x: 253,
        y: 148,
        rotation: 0
      },
      mobile: {
        x: 177,
        y: 105,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 248,
        y: 162,
        rotation: 90
      },
      tablet: {
        x: 253,
        y: 148,
        rotation: 90
      },
      mobile: {
        x: 177,
        y: 105,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 248,
        y: 116,
        rotation: 0
      },
      tablet: {
        x: 253,
        y: 108,
        rotation: 0
      },
      mobile: {
        x: 177,
        y: 75,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 292,
        y: 251,
        rotation: 0
      },
      tablet: {
        x: 293,
        y: 228,
        rotation: -0
      },
      mobile: {
        x: 207,
        y: 165,
        rotation: -0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 203,
        y: 72,
        rotation: 0
      },
      tablet: {
        x: 213,
        y: 68,
        rotation: 0
      },
      mobile: {
        x: 147,
        y: 45,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
