export default {
  image: require('~/static/trainers/funnyPoints/animalHand.png'),
  width: 800,
  height: 919,
  points: [
    { id: 1,  x: 174,  y: 606 },
    { id: 2,  x: 192,  y: 543 },
    { id: 3,  x: 168,  y: 442 },
    { id: 4,  x: 181,  y: 348 },
    { id: 5,  x: 114,  y: 320 },
    { id: 6,  x: 89,   y: 408 },
    { id: 7,  x: 63,   y: 251 },
    { id: 8,  x: -10,  y: 260 },
    { id: 9,  x: 3,    y: 379 },
    { id: 10, x: -65,  y: 276 },
    { id: 11, x: -137, y: 317 },
    { id: 12, x: -73,  y: 430 },
    { id: 13, x: -155, y: 368 },
    { id: 14, x: -208, y: 418 },
    { id: 15, x: -139, y: 502 },
    { id: 16, x: -200, y: 487 },
    { id: 17, x: -221, y: 549 },
    { id: 18, x: -117, y: 601 },
    { id: 19, x: -19,  y: 685 },
    { id: 20, x: 37,   y: 697 }
  ]
};
