import React, { Fragment } from 'react';

import CircleProgress from '~/components/CircleProgress/CircleProgress';
import PossiblePoints from '~/components/PossiblePoints/PossiblePoints';
import TrainerSkillBubble from '~/components/TrainerSkillBubble/TrainerSkillBubble';

import './trainerSkillResult.scss';

const TrainerSkillResult = ({ skill, skillOf, total, pointsOf, name, color, iconName, iconNamePuzzle, isMission, type }) => {
  const bubble = () => {
    return <TrainerSkillBubble
      iconName={ iconName }
      iconNamePuzzle={ iconNamePuzzle }
      skill={ skill }
      isMission={ isMission }
    />
  }

  const progress = () => {
    return (
      <div className="trainer-skill-result__progress">
        <CircleProgress
          isAnimated={ true }
          value={(1 - total / pointsOf) * 100}
          color={ color }
          className="trainer-skill-result__progress-bar"
        />
        <span
          className="trainer-skill-result__progress-value"
          style={{ color }}
          data-cy-skill={ type }
          data-cy-skill-total={ total }
          data-cy-skill-earned={ skill }
        >
        { total }
        </span>
      </div>
    )
  }

  const title = () => {
    return (
      <p
        className="trainer-skill-result__name"
        style={{ color }}
      >
        { name }
      </p>
    )
  }

  const possiblePoints = (isWarning, skill, skillOf) => {
    return (
      <PossiblePoints
        points={ skill }
        total={ skillOf }
        warning={ isWarning }
      />
    )
  }

  return (
    <div className="trainer-skill-result">
      { isMission ?
        <Fragment>
          { title() }
          { progress() }
          { bubble() }
          { skillOf && possiblePoints(skillOf !== skill, skill, skillOf) }
        </Fragment>
        :
        <Fragment>
          { bubble() }
          { progress() }
          { title() }
        </Fragment>
      }
    </div>
  )
}

export default TrainerSkillResult;
