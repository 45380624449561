export default {
  shapes: [5, 7, 6, 2, 1, 3, 4],
  offset: {
    mobile: 40,
    tablet: 20,
    desktop: 30
  },
  outlines: [
    {
      id: 5,
      rotation: 225,
      desktop: {
        x: 218,
        y: 245.3
      },
      tablet: {
        x: 236.57,
        y: 213.14
      },
      mobile: {
        x: 165.43,
        y: 151.85
      }
    },
    {
      id: 7,
      rotation: 315,
      desktop: {
        x: 154,
        y: 181.64
      },
      tablet: {
        x: 180,
        y: 155.57
      },
      mobile: {
        x: 123,
        y: 108.43
      }
    },
    {
      id: 6,
      rotation: 225,
      desktop: {
        x: 280.64,
        y: 344.92
      },
      tablet: {
        x: 292.57,
        y: 314.71
      },
      mobile: {
        x: 207.43,
        y: 234.28
      }
    },
    {
      id: 2,
      rotation: 135,
      desktop: {
        x: 249.99,
        y: 276.46
      },
      tablet: {
        x: 266.14,
        y: 240.85
      },
      mobile: {
        x: 186.85,
        y: 171.64
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 312,
        y: 186.64
      },
      tablet: {
        x: 321,
        y: 173.57
      },
      mobile: {
        x: 228,
        y: 128.43
      }
    },
    {
      id: 3,
      rotation: 90,
      desktop: {
        x: 434,
        y: 110
      },
      tablet: {
        x: 429,
        y: 106
      },
      mobile: {
        x: 309,
        y: 78
      }
    },
    {
      id: 4,
      rotation: 0,
      desktop: {
        x: 324,
        y: 255
      },
      tablet: {
        x: 338,
        y: 229
      },
      mobile: {
        x: 240,
        y: 171
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 434,
        y: 155
      },
      tablet: {
        x: 181.28,
        y: 155.29
      },
      mobile: {
        x: 123.21,
        y: 109.21000000000001
      }
    },
    {
      desktop: {
        x: 407.46,
        y: 155
      },
      tablet: {
        x: 181.28,
        y: 212.57
      },
      mobile: {
        x: 123.21,
        y: 150.43
      }
    },
    {
      desktop: {
        x: 407.46,
        y: 218.1
      },
      tablet: {
        x: 237.85,
        y: 269.14
      },
      mobile: {
        x: 165.64,
        y: 192.85
      }
    },
    {
      desktop: {
        x: 369,
        y: 256.56
      },
      tablet: {
        x: 237.85,
        y: 213.42000000000002
      },
      mobile: {
        x: 165.64,
        y: 151.07
      }
    },
    {
      desktop: {
        x: 369,
        y: 345
      },
      tablet: {
        x: 292.56,
        y: 268.14
      },
      mobile: {
        x: 207.42,
        y: 192.85
      }
    },
    {
      desktop: {
        x: 324.65999999999997,
        y: 300.9
      },
      tablet: {
        x: 292.56,
        y: 314.71000000000004
      },
      mobile: {
        x: 207.42,
        y: 234.28
      }
    },
    {
      desktop: {
        x: 280.64,
        y: 344.91999999999996
      },
      tablet: {
        x: 338.13,
        y: 269.14
      },
      mobile: {
        x: 240.35,
        y: 201.35
      }
    },
    {
      desktop: {
        x: 280.64,
        y: 308.28
      },
      tablet: {
        x: 378,
        y: 309
      },
      mobile: {
        x: 270,
        y: 231
      }
    },
    {
      desktop: {
        x: 218.14999999999998,
        y: 245.79
      },
      tablet: {
        x: 378,
        y: 229.28
      },
      mobile: {
        x: 270,
        y: 171.70999999999998
      }
    },
    {
      desktop: {
        x: 217.45999999999998,
        y: 308.99
      },
      tablet: {
        x: 405.85,
        y: 201.5
      },
      mobile: {
        x: 291.64,
        y: 150
      }
    },
    {
      desktop: {
        x: 153.82,
        y: 245.35
      },
      tablet: {
        x: 405.85,
        y: 146
      },
      mobile: {
        x: 291.64,
        y: 108
      }
    },
    {
      desktop: {
        x: 154.5,
        y: 181.5
      },
      tablet: {
        x: 429,
        y: 146
      },
      mobile: {
        x: 309,
        y: 108
      }
    },
    {
      desktop: {
        x: 218,
        y: 118.02
      },
      tablet: {
        x: 389,
        y: 106
      },
      mobile: {
        x: 279,
        y: 78
      }
    },
    {
      desktop: {
        x: 281.28,
        y: 181.3
      },
      tablet: {
        x: 349.28,
        y: 145.72
      },
      mobile: {
        x: 249.20999999999998,
        y: 107.78999999999999
      }
    },
    {
      desktop: {
        x: 281.28,
        y: 217.64
      },
      tablet: {
        x: 349.28,
        y: 201.57
      },
      mobile: {
        x: 249.20999999999998,
        y: 149.43
      }
    },
    {
      desktop: {
        x: 343.82,
        y: 217.64
      },
      tablet: {
        x: 293.13,
        y: 201.57
      },
      mobile: {
        x: 207.85,
        y: 149.43
      }
    },
    {
      desktop: {
        x: 343.82,
        y: 155.15
      },
      tablet: {
        x: 293.13,
        y: 156.5
      },
      mobile: {
        x: 207.85,
        y: 109.5
      }
    },
    {
      desktop: {
        x: 388.75,
        y: 110
      },
      tablet: {
        x: 236.56,
        y: 100
      },
      mobile: {
        x: 165.42,
        y: 67
      }
    }
  ]
};
