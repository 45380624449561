import React from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';

function Popup(props) {
  const { isOpen, children, className, overlayClass } = props;
  const overlayClasses = cx('popupOverlay', overlayClass);

  return (
    <ReactModal className={ className } isOpen={ isOpen } overlayClassName={ overlayClasses }>
      { children }
    </ReactModal>
  );
};

export default Popup;
