import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/lib/integration/react';

import GlobalPopups from './components/GlobalPopups/GlobalPopups';

import createStore from './store';
import Routes from './routes';

const history = createBrowserHistory();
const store = createStore(history);
const persistor = persistStore(store);

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
      <GlobalPopups />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
