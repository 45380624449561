export default {
  shapes: [7, 4, 5, 3, 1, 2, 6],
  offset: {
    mobile: 15,
    tablet: 10
  },
  outlines: [
    {
      id: 7,
      rotation: 90,
      desktop: {
        x: 430.46,
        y: 210.46
      },
      tablet: {
        x: 425,
        y: 182
      },
      mobile: {
        x: 306,
        y: 138
      }
    },
    {
      id: 4,
      rotation: 315,
      desktop: {
        x: 397.6,
        y: 242.3
      },
      tablet: {
        x: 396,
        y: 210.28
      },
      mobile: {
        x: 284,
        y: 159.21
      }
    },
    {
      id: 5,
      rotation: 225,
      desktop: {
        x: 295.64,
        y: 381.28
      },
      tablet: {
        x: 305.57,
        y: 334.14
      },
      mobile: {
        x: 216.43,
        y: 251.85
      }
    },
    {
      id: 3,
      rotation: 270,
      desktop: {
        x: 162,
        y: 256
      },
      tablet: {
        x: 185.43,
        y: 222.43
      },
      mobile: {
        x: 126,
        y: 168
      }
    },
    {
      id: 1,
      rotation: 0,
      desktop: {
        x: 206,
        y: 210
      },
      tablet: {
        x: 225,
        y: 182
      },
      mobile: {
        x: 156,
        y: 138
      }
    },
    {
      id: 2,
      rotation: 270,
      desktop: {
        x: 73,
        y: 256
      },
      tablet: {
        x: 106,
        y: 223
      },
      mobile: {
        x: 66,
        y: 168
      }
    },
    {
      id: 6,
      rotation: 270,
      desktop: {
        x: 218,
        y: 211
      },
      tablet: {
        x: 236,
        y: 183
      },
      mobile: {
        x: 170,
        y: 138
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 397.46,
        y: 210.45999999999998
      },
      tablet: {
        x: 316,
        y: 103
      },
      mobile: {
        x: 290,
        y: 138
      }
    },
    {
      desktop: {
        x: 429.42,
        y: 210.45999999999998
      },
      tablet: {
        x: 395,
        y: 182
      },
      mobile: {
        x: 230,
        y: 78
      }
    },
    {
      desktop: {
        x: 493.06,
        y: 274.12
      },
      tablet: {
        x: 424.28,
        y: 182
      },
      mobile: {
        x: 170,
        y: 138
      }
    },
    {
      desktop: {
        x: 429.6,
        y: 274.3
      },
      tablet: {
        x: 480.85,
        y: 238.57
      },
      mobile: {
        x: 66,
        y: 138
      }
    },
    {
      desktop: {
        x: 429.42,
        y: 211.5
      },
      tablet: {
        x: 424.28,
        y: 238.57
      },
      mobile: {
        x: 96,
        y: 168
      }
    },
    {
      desktop: {
        x: 341.28,
        y: 299.64
      },
      tablet: {
        x: 424.28,
        y: 182.72
      },
      mobile: {
        x: 156,
        y: 168
      }
    },
    {
      desktop: {
        x: 359.28,
        y: 317.64
      },
      tablet: {
        x: 345.78,
        y: 261.22
      },
      mobile: {
        x: 185.70999999999998,
        y: 197.70999999999998
      }
    },
    {
      desktop: {
        x: 232,
        y: 317.64
      },
      tablet: {
        x: 362.14,
        y: 277.57
      },
      mobile: {
        x: 174,
        y: 209.43
      }
    },
    {
      desktop: {
        x: 250.32,
        y: 299.32
      },
      tablet: {
        x: 249,
        y: 277.57
      },
      mobile: {
        x: 258.85,
        y: 209.43
      }
    },
    {
      desktop: {
        x: 207,
        y: 256
      },
      tablet: {
        x: 264.78,
        y: 261.78
      },
      mobile: {
        x: 246.71,
        y: 197.29000000000002
      }
    },
    {
      desktop: {
        x: 118,
        y: 256
      },
      tablet: {
        x: 226,
        y: 223
      },
      mobile: {
        x: 305.21000000000004,
        y: 138.79
      }
    },
    {
      desktop: {
        x: 73,
        y: 211
      },
      tablet: {
        x: 146,
        y: 223
      },
      mobile: {
        x: 305.21000000000004,
        y: 180.43
      }
    },
    {
      desktop: {
        x: 218,
        y: 211
      },
      tablet: {
        x: 106,
        y: 183
      },
      mobile: {
        x: 347.64,
        y: 180.43
      }
    },
    {
      desktop: {
        x: 308,
        y: 121
      },
      tablet: {
        x: 236.57,
        y: 182.43
      },
      mobile: {
        x: 305.21000000000004,
        y: 138
      }
    }
  ]
};
