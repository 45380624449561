import React, { useEffect } from 'react';

import scrollTop from '~/services/scroll-top';

import './success-screen.scss';

const SuccessScreen = ({ children, text }) => {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className="success-screen">
      <div className="success-screen__holder">
        {
          text &&
          <p className="success-screen__text">
            { text }
          </p>
        }
        { children }
      </div>
    </div>
  )
};

export default SuccessScreen;
