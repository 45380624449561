export default ({ seconds, figures }) => {
  const tooltipContent = 'Выбирая цифры от меньшего к большему, дорисуй изображение по точкам.';
  return {
    timer: [seconds * 1000],
    figures,
    tooltipContent,
    isMiniTooltip: true,
    winnerPoints: {
      focus: 1,
      imagination: 2
    }
  };
}
