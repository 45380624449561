import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

const templateLevels = {
  1: {
    timer: 30,
    amount: 5
  },
  2: {
    timer: 25,
    amount: 5
  },
  3: {
    timer: 30,
    amount: 7
  },
  4: {
    timer: 25,
    amount: 7
  },
  5: {
    timer: 25,
    amount: 9
  },
  6: {
    timer: 20,
    amount: 9
  },
  7: {
    timer: 25,
    amount: 11
  },
  8: {
    timer: 20,
    amount: 11
  },
  9: {
    timer: 20,
    amount: 13
  },
  10: {
    timer: 15,
    amount: 13
  }
};

export default getLevels(templateLevels, getLevelParams);
