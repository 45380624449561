export default {
  image: require('~/static/trainers/funnyPoints/palm.png'),
  width: 800,
  height: 919,
  offsetX: 15,
  offsetY: 10,
  points: [
    { id: 1,  x: -42,  y: 293 },
    { id: 2,  x: -104, y: 332 },
    { id: 3,  x: -180, y: 439 },
    { id: 4,  x: -223, y: 575 },
    { id: 5,  x: -275, y: 391 },
    { id: 6,  x: -182, y: 275 },
    { id: 7,  x: -48,  y: 269 },
    { id: 8,  x: -176, y: 221 },
    { id: 9,  x: -344, y: 274 },
    { id: 10, x: -259, y: 167 },
    { id: 11, x: -127, y: 153 },
    { id: 12, x: -27,  y: 255 },
    { id: 13, x: -39,  y: 159 },
    { id: 14, x: -119, y: 89  },
    { id: 15, x: 7,    y: 128 },
    { id: 16, x: -9,   y: 263 },
    { id: 17, x: 118,  y: 167 },
    { id: 18, x: 265,  y: 148 },
    { id: 19, x: 342,  y: 222 },
    { id: 20, x: 106,  y: 212 },
    { id: 21, x: -4,   y: 285 },
    { id: 22, x: 140,  y: 240 },
    { id: 23, x: 251,  y: 285 },
    { id: 24, x: 274,  y: 430 },
    { id: 25, x: 145,  y: 345 },
    { id: 26, x: -19,  y: 292 },
    { id: 27, x: 60,   y: 412 },
    { id: 28, x: 38,   y: 583 },
    { id: 29, x: 6,    y: 426 },
    { id: 30, x: -30,  y: 318 }
  ]
};
