import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createRootReducer from './createRootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['profile']
};

export default (history) => {
  const rootReducer =  createRootReducer(history);
  const pReducer = persistReducer(persistConfig, rootReducer);

  return createStore(
    pReducer,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        promiseMiddleware()
      )
    )
  );
}
