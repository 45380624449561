import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import timeout from '~/services/timeout';

import './level-up-scale.scss';

const LevelUpScale = (props) => {
  const { maxPoints, prevMaxPoints } = props;
  const prevPoints = prevMaxPoints - 1;
  const points = 0;

  const [ isEmptyScale, setEmptyScale ] = useState(false);
  const progressAnimation = useSpring({
    from: { width: `${ prevPoints / prevMaxPoints * 100 }%` },
    to: async (next) => {
      await next({ width: '100%' });
      await timeout(1000);
      setEmptyScale(true);
    },
    delay: 1000
  });
  const pointsAnimation = useSpring({
    from: { val: prevPoints },
    to: { val: prevMaxPoints },
    delay: 1000
  });
  const style = isEmptyScale ? { width: 0 } : progressAnimation;

  return (
    <div className="level-up-scale">
      <div className="level-up-scale__points">
        <animated.span>
          { isEmptyScale ? points : pointsAnimation.val.interpolate((val) => Math.floor(val)) }
        </animated.span>
        <span> / </span>
        { isEmptyScale ? maxPoints : prevMaxPoints }
        <span className="level-up-scale__points-text"> баллов</span>
      </div>
      <animated.div className="level-up-scale__progress" style={ style }/>
    </div>
  );
};

export default LevelUpScale;
