import gifts from '~/shared/gifts';

export default {
  id: 9,
  name: '«Птичье гнездо»',
  longMemory: true,
  description: '«Птичье гнездо» – национальный стадион в Пекине. Это одна из масштабных построек в мире.',
  partingWords: 'Агент, ты становишься примером для новых агентов, которые ещё не достигли такого уровня, как ты! Время заработать высокотехнологичное оборудование – ноутбук спецагента. С его помощью ты всегда будешь на связи со штабом, а ноутбук защищён от возможного взлома противников.',
  story: `Стадион «Птичье гнездо» был построен в 2008 году. Он был открыт для проведения Летних Олимпийских игр.
          Информация для спецагента:
          1. На момент постройки это была самая сложная стальная конструкция в мире, наибольшая по размерам.
          2. Первоначально стадион задумывался в форме колыбели, которая символизировала бы комфорт и безопасность. Но он стал больше напоминать птичье гнездо в готовом виде. Так олимпийский объект и был назван.`,
  textLosing: 'Миссия не пройдена! Секретные навыки надо тренировать, чтобы всё получалось с первого раза. Потренируйся на тренажёрах и возвращайся!',
  textPartially: 'Не все задания получились сразу! Потренируйся в тренажёрном зале и обязательно возвращайся. Помни: важно развивать секретные навыки, они тебе всегда пригодятся!',
  textSuccess: 'Агент, в Китае ты завершил все миссии! Твои навыки достигли невероятных значений, но ни один спецагент на этом не остановится. Тебя ждут новые интересные задания в новой локации. Предметы, которые хранятся в твоём наборе агента, помогут тебе выполнять новые миссии. Вперёд! Мистер Фоксман ждёт тебя в новой локации!',
  gift: gifts.laptop,
  imgs: [
    require('~/static/locations/pekin/beijing-national-stadium_980x590.jpg'),
  ]
};
