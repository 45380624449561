import PropTypes from 'prop-types';

export default {
  trainer: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isPopupShow: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  exitButton: PropTypes.shape({
    text: PropTypes.string,
    handler: PropTypes.func,
  }),
};
