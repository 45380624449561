import React from 'react';
import cx from 'classnames';

const Word = (props) => {
  const { gameCardIndex, wordIndex, gameCard, onDragStartEmptyHandler, onDropHandler, onLetterClickHandler,
          onEmptyLetterClickHandler, onDragEndHandler, onDragOverHandler, onDragLeaveHandler, answered,
          letterArray, isCorrectWord } = props;
  const correctLetter = letterArray[gameCardIndex];
  const isCorrect = gameCard && correctLetter.toUpperCase() === gameCard.toUpperCase();
  const handlerDrop = (event) => onDropHandler(event, wordIndex, gameCardIndex);

  const renderCard = () => {
    const handlerDragStart = (event) => onDragStartEmptyHandler(event, wordIndex, gameCardIndex);
    const handlerClick = (event) => onLetterClickHandler(event, wordIndex, gameCardIndex, true);
    const classesCard = cx("trainer-anagram__letter-wrapper", {
      "trainer-anagram__letter-wrapper_correct": gameCard && answered && isCorrect,
      "trainer-anagram__letter-wrapper_incorrect": gameCard && answered && !isCorrect
    });

    return (
      <div className={ classesCard } draggable={ !answered } onDragStart={ handlerDragStart } onDrop={ handlerDrop }
          onDragEnd={ onDragEndHandler } onClick={ handlerClick } onDragOver={ onDragOverHandler }>
        <span className="trainer-anagram__letter-text">
          { gameCard }
        </span>
      </div>
    );
  };

  return (
    <div className="trainer-anagram__empty-letter-container">
      <div className="trainer-anagram__letter-container">
        { gameCard && renderCard() }
      </div>

      <div className="trainer-anagram__empty-letter" key={ gameCardIndex } onDragOver={ onDragOverHandler }
           onDragLeave={ onDragLeaveHandler } onDrop={ handlerDrop }
           onClick={ event => onEmptyLetterClickHandler(event, wordIndex, gameCardIndex) }/>

      {
        answered && !isCorrectWord &&
        <div className="trainer-anagram__answer-letter">
          { correctLetter.toUpperCase() }
        </div>
      }
    </div>
  )
}

export default Word;
