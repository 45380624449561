export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    mobile: 15
  },
  outline: [
    {
      desktop: {
        x: 303.62,
        y: 23
      },
      tablet: {
        x: 224,
        y: 26
      },
      mobile: {
        x: 154,
        y: 21
      }
    },
    {
      desktop: {
        x: 303.62,
        y: 113.35
      },
      tablet: {
        x: 264,
        y: 66
      },
      mobile: {
        x: 184,
        y: 51
      }
    },
    {
      desktop: {
        x: 276,
        y: 141
      },
      tablet: {
        x: 304,
        y: 26
      },
      mobile: {
        x: 214,
        y: 21
      }
    },
    {
      desktop: {
        x: 276,
        y: 319
      },
      tablet: {
        x: 304,
        y: 106
      },
      mobile: {
        x: 214,
        y: 81
      }
    },
    {
      desktop: {
        x: 249.82,
        y: 345.18
      },
      tablet: {
        x: 277,
        y: 133
      },
      mobile: {
        x: 195.21,
        y: 99.79
      }
    },
    {
      desktop: {
        x: 267.64,
        y: 363
      },
      tablet: {
        x: 277,
        y: 292
      },
      mobile: {
        x: 195.21,
        y: 217.79
      }
    },
    {
      desktop: {
        x: 357,
        y: 363
      },
      tablet: {
        x: 253.78,
        y: 315.22
      },
      mobile: {
        x: 177.71,
        y: 235.29
      }
    },
    {
      desktop: {
        x: 401,
        y: 408
      },
      tablet: {
        x: 269.57,
        y: 331
      },
      mobile: {
        x: 189.43,
        y: 247
      }
    },
    {
      desktop: {
        x: 186,
        y: 408.64
      },
      tablet: {
        x: 349,
        y: 331
      },
      mobile: {
        x: 249,
        y: 247
      }
    },
    {
      desktop: {
        x: 186,
        y: 230
      },
      tablet: {
        x: 389,
        y: 371
      },
      mobile: {
        x: 279,
        y: 277
      }
    },
    {
      desktop: {
        x: 258.5,
        y: 157.5
      },
      tablet: {
        x: 197,
        y: 371.57
      },
      mobile: {
        x: 135,
        y: 277.4
      }
    },
    {
      desktop: {
        x: 214.62,
        y: 113.6
      },
      tablet: {
        x: 197,
        y: 212
      },
      mobile: {
        x: 135,
        y: 159.17
      }
    },
    {
      desktop: {
        x: 214.62,
        y: 23
      },
      tablet: {
        x: 263.5,
        y: 145.5
      },
      mobile: {
        x: 183.61,
        y: 110.61
      }
    },
    {
      desktop: {
        x: 259.37,
        y: 67.5
      },
      tablet: {
        x: 224,
        y: 106
      },
      mobile: {
        x: 154,
        y: 81
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 276,
        y: 320,
        rotation: -180
      },
      tablet: {
        x: 277,
        y: 292,
        rotation: 180
      },
      mobile: {
        x: 195.21,
        y: 219,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 186,
        y: 229,
        rotation: 0
      },
      tablet: {
        x: 197,
        y: 212,
        rotation: 0
      },
      mobile: {
        x: 135,
        y: 158,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 249.64,
        y: 472.28,
        rotation: 135
      },
      tablet: {
        x: 253.57,
        y: 428.14,
        rotation: 135
      },
      mobile: {
        x: 177.43,
        y: 319.85,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 258.62,
        y: 23,
        rotation: 0
      },
      tablet: {
        x: 264,
        y: 26,
        rotation: 0
      },
      mobile: {
        x: 184,
        y: 21,
        rotation: 0
      },
      rotation: 0
    },
    {
      desktop: {
        x: 259.62,
        y: 113,
        rotation: 180
      },
      tablet: {
        x: 264,
        y: 106,
        rotation: 180
      },
      mobile: {
        x: 184,
        y: 81,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 266,
        y: 408,
        rotation: 90
      },
      tablet: {
        x: 269,
        y: 371,
        rotation: 90
      },
      mobile: {
        x: 189,
        y: 277,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 214,
        y: 68,
        rotation: 0
      },
      tablet: {
        x: 224,
        y: 66,
        rotation: 0
      },
      mobile: {
        x: 154,
        y: 51,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
