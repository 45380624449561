export default {
  shapes: [7, 6, 4, 5, 3, 1, 8],
  offset: {
    mobile: 20,
    tablet: 10
  },
  outlines: [
    {
      id: 7,
      rotation: 315,
      desktop: {
        x: 220,
        y: 226
      },
      tablet: {
        x: 240,
        y: 206.42
      },
      mobile: {
        x: 167,
        y: 149.43
      }
    },
    {
      id: 6,
      rotation: 45,
      desktop: {
        x: 347.28,
        y: 100
      },
      tablet: {
        x: 353.28,
        y: 94
      },
      mobile: {
        x: 251.85,
        y: 65
      }
    },
    {
      id: 4,
      rotation: 90,
      desktop: {
        x: 266,
        y: 226
      },
      tablet: {
        x: 281,
        y: 205.85
      },
      mobile: {
        x: 198,
        y: 149
      }
    },
    {
      id: 5,
      rotation: 0,
      desktop: {
        x: 382.2,
        y: 106
      },
      tablet: {
        x: 385,
        y: 99.85
      },
      mobile: {
        x: 276,
        y: 69
      }
    },
    {
      id: 3,
      rotation: 45,
      desktop: {
        x: 440.64,
        y: 163.8
      },
      tablet: {
        x: 436.57,
        y: 150.85
      },
      mobile: {
        x: 314.43,
        y: 107
      }
    },
    {
      id: 1,
      rotation: 315,
      desktop: {
        x: 314.5,
        y: 227.64
      },
      tablet: {
        x: 324,
        y: 207.42
      },
      mobile: {
        x: 230,
        y: 149.43
      }
    },
    {
      id: 8,
      rotation: 270,
      desktop: {
        x: 87,
        y: 271
      },
      tablet: {
        x: 121,
        y: 245.85
      },
      mobile: {
        x: 78,
        y: 179
      }
    }
  ],
  outline: [
    {
      desktop: {
        x: 347.28,
        y: 100
      },
      tablet: {
        x: 353.28,
        y: 179.14
      },
      mobile: {
        x: 251.85,
        y: 128.21
      }
    },
    {
      desktop: {
        x: 221.28,
        y: 226
      },
      tablet: {
        x: 353.28,
        y: 94
      },
      mobile: {
        x: 251.85,
        y: 65
      }
    },
    {
      desktop: {
        x: 132,
        y: 226
      },
      tablet: {
        x: 241,
        y: 206
      },
      mobile: {
        x: 167.85,
        y: 149
      }
    },
    {
      desktop: {
        x: 87,
        y: 271
      },
      tablet: {
        x: 161,
        y: 205.85
      },
      mobile: {
        x: 108,
        y: 149
      }
    },
    {
      desktop: {
        x: 265,
        y: 271
      },
      tablet: {
        x: 121,
        y: 245.85
      },
      mobile: {
        x: 78,
        y: 179
      }
    },
    {
      desktop: {
        x: 347.28,
        y: 353.28
      },
      tablet: {
        x: 279.43,
        y: 245.85
      },
      mobile: {
        x: 196.57,
        y: 179
      }
    },
    {
      desktop: {
        x: 347.28,
        y: 259.46000000000004
      },
      tablet: {
        x: 353.14,
        y: 319.56
      },
      mobile: {
        x: 251.85,
        y: 234.28
      }
    },
    {
      desktop: {
        x: 408.5,
        y: 259.46000000000004
      },
      tablet: {
        x: 353.14,
        y: 235.71
      },
      mobile: {
        x: 251.85,
        y: 170.64
      }
    },
    {
      desktop: {
        x: 472.2,
        y: 195.88
      },
      tablet: {
        x: 408.25,
        y: 235.71
      },
      mobile: {
        x: 293,
        y: 170.64
      }
    },
    {
      desktop: {
        x: 472.2,
        y: 106
      },
      tablet: {
        x: 465,
        y: 179
      },
      mobile: {
        x: 336,
        y: 127.5
      }
    },
    {
      desktop: {
        x: 382.38,
        y: 195.82
      },
      tablet: {
        x: 465,
        y: 99.85
      },
      mobile: {
        x: 336,
        y: 69
      }
    },
    {
      desktop: {
        x: 347.28,
        y: 195.82
      },
      tablet: {
        x: 385.72,
        y: 179.14
      },
      mobile: {
        x: 276.78999999999996,
        y: 128.21
      }
    }
  ]
};
