export default {
  shapes: [ 5, 8, 1, 6, 7, 3, 4 ],
  offset: {
    desktop: 60,
    tablet: 55,
    mobile: 55
  },
  outline: [
    {
      desktop: { x: 322.64, y: 32 },
      tablet: { x: 318.57, y: 38 },
      mobile: { x: 226.43, y: 18 }
    },
    {
      desktop: { x: 259, y: 95.64 },
      tablet: { x: 262, y: 94.57 },
      mobile: { x: 184, y: 60.43 }
    },
    {
      desktop: { x: 321.82, y: 158.46 },
      tablet: { x: 318.28, y: 150.85 },
      mobile: { x: 226.21, y: 102.64 }
    },
    {
      desktop: { x: 321.82, y: 203.18 },
      tablet: { x: 318.28, y: 189.72 },
      mobile: { x: 226.21, y: 135.79 }
    },
    {
      desktop: { x: 231.64, y: 293.27 },
      tablet: { x: 239.57, y: 268.43 },
      mobile: { x: 167.43, y: 194.59 }
    },
    {
      desktop: { x: 231.64, y: 419.28 },
      tablet: { x: 239.57, y: 382.14 },
      mobile: { x: 167.43, y: 279.85 }
    },
    {
      desktop: { x: 358.92, y: 419.28 },
      tablet: { x: 352.71, y: 382.14 },
      mobile: { x: 252.28, y: 279.85 }
    },
    {
      desktop: { x: 322, y: 382.36 },
      tablet: { x: 319, y: 348.43 },
      mobile: { x: 227, y: 254.57 }
    },
    {
      desktop: { x: 322, y: 310.24 },
      tablet: { x: 319, y: 287.43 },
      mobile: { x: 227, y: 203.63 }
    },
    {
      desktop: { x: 367, y: 355 },
      tablet: { x: 358, y: 326.43 },
      mobile: { x: 257.18, y: 233.82 }
    },
    {
      desktop: { x: 367, y: 265.76 },
      tablet: { x: 358, y: 245.62 },
      mobile: { x: 257.18, y: 174.18 }
    },
    {
      desktop: { x: 322.46, y: 221.46 },
      tablet: { x: 319.28, y: 206.85 },
      mobile: { x: 227.64, y: 144.64 }
    },
    {
      desktop: { x: 385.46, y: 221.46 },
      tablet: { x: 374.85, y: 206.85 },
      mobile: { x: 268.64, y: 144.64 }
    },
    {
      desktop: { x: 385.46, y: 157.82 },
      tablet: { x: 374.85, y: 150.28 },
      mobile: { x: 268.64, y: 102.21 }
    },
    {
      desktop: { x: 323.28, y: 157.82 },
      tablet: { x: 319.14, y: 150.28 },
      mobile: { x: 226.85, y: 102.21 }
    },
    {
      desktop: { x: 385.46, y: 95.64 },
      tablet: { x: 374.85, y: 94.57 },
      mobile: { x: 269.25, y: 59.82 }
    },
    {
      desktop: { x: 385.46, y: 32 },
      tablet: { x: 374.85, y: 38 },
      mobile: { x: 269.25, y: 18 }
    },
    {
      desktop: { x: 322.64, y: 94.82 },
      tablet: { x: 318.57, y: 94.28 },
      mobile: { x: 226.43, y: 60.21 }
    }
  ],
  outlines: [
    {
      desktop: { x: 386.28, y: 95.64 },
      tablet: { x: 375.14, y: 94.57 },
      mobile: { x: 268.85, y: 60.43 },
      rotation: 135
    },
    {
      desktop: { x: 385.46, y: 32 },
      tablet: { x: 374.85, y: 38 },
      mobile: { x: 268.64, y: 18 },
      rotation: 45
    },
    {
      desktop: { x: 290, y: 189.64 },
      tablet: { x: 290, y: 178.57 },
      mobile: { x: 205, y: 123.43 },
      rotation: 315
    },
    {
      desktop: { x: 322, y: 383 },
      tablet: { x: 319, y: 349 },
      mobile: { x: 227, y: 255 },
      rotation: 180
    },
    {
      desktop: { x: 358.92, y: 419.28 },
      tablet: { x: 352.71, y: 382.14 },
      mobile: { x: 252.28, y: 279.85 },
      rotation: 135
    },
    {
      desktop: { x: 367, y: 311 },
      tablet: { x: 358.43, y: 286 },
      mobile: { x: 257, y: 204 },
      rotation: 180
    },
    {
      desktop: { x: 322, y: 265 },
      tablet: { x: 318, y: 246.43 },
      mobile: { x: 227.18, y: 173.82 },
      rotation: 0
    }
  ]
};
