import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { Link } from 'react-router-dom';

import Logo from '~/components/Logo/Logo';
import MenuPopup from '~/components/MenuPopup/MenuPopup';
import Navigation from '~/components/Navigation/Navigation';
import ProfileWidget from '~/components/ProfileWidget/ProfileWidget';
import Icon from '~/components/Icon/Icon';

import popupNames from '~/services/popup-names';

import { getPopupName } from '~/store/page/selector';

import * as pageActions from '~/store/page/actions';

// eslint-disable-next-line
import burger from './assets/burger.svg';

import './header.scss';

const Header = (props) => {
  const { theme, isMain, popupName, setPopup } = props;
  const setLocationPopup = () => setPopup(popupNames.menu);
  const closeHandler = () => setPopup(null);

  const isModalOpen = popupName === popupNames.menu;
  const headerClass = classNames('header', {
    [`header_${ theme }`]: theme,
    'header_main': isMain
  });

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  }, [ isModalOpen ]);

  return (
    <header className={ headerClass }>
      <div className="header__holder">
        <Link className="header__logo" to='/'>
          <Logo />
        </Link>

        <div className="header__navigation" >
          <Navigation isHeader={ true } theme={ theme } />
        </div>

        <div className="header__profile">
          <ProfileWidget />
        </div>

        <button onClick={ setLocationPopup } type="button" className='header__menu'>
          <Icon name="burger" className="header__burger" />
        </button>

        <MenuPopup closeHandler={ closeHandler } isOpen={ isModalOpen } />
      </div>
    </header>
  )
}

function mapStateToProps(state) {
  return {
    isMain: state.router.location.pathname === '/',
    popupName: getPopupName(state)
  };
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
