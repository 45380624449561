import React, { useState, useEffect, useRef } from 'react';
import propTypes from './prop-types';

import BaseWord from './BaseWord';
import Word from './Word';
import WordsPanel from './WordsPanel';
import Tabs from '~/components/Tabs/Tabs';
import DynamicContent from '~/components/DynamicContent/DynamicContent';

import getShuffleArray from '~/services/get-shuffle-array';

import './trainer-secret-code.scss';

const WORD_COUNT = 7;
const stageName = 'page2_solve';

const TrainerSecretCode = (props) => {
  const { params, isTimerActive, onFinishCb, widthWorkspace, heightWorkspace, handleDataLayer } = props;
  const { difficultyParams } = params;
  const { winnerPoints } = difficultyParams;

  const baseWordPattern = getShuffleArray(difficultyParams.patterns, 1);

  const panelRef = useRef(null);
  const [ userWords, setUserWords ] = useState([]);
  const [ baseWord ] = useState(baseWordPattern[0]);
  const [ letterIndexes, setLetterIndexes ] = useState([]);
  const [ currentUserWord, setCurrentUserWord ] = useState('');
  const [ hasPattern, setHasPattern ] = useState(false);
  const [ userWordIndex, setUserWordIndex ] = useState(0);
  const [ needCheck, setNeedCheck ] = useState(false);

  const showSuccessTab = needCheck && userWordIndex === WORD_COUNT;

  useEffect(() => {
    generateWordsSpace();
  }, []);

  useEffect(() => {
    if (isTimerActive && userWordIndex === WORD_COUNT) {
      checkVictory(true);
      handleDataLayer({ stageName, eventAction: 'next_auto' });
    }

    if (!isTimerActive && userWordIndex !== WORD_COUNT) {
      checkVictory(false);
      handleDataLayer({ stageName, event: 'timerTick', eventAction: 'next_force' });
    }
  }, [ userWordIndex, isTimerActive ]);

  const generateWordsSpace = () => {
    const words = [ ...Array(WORD_COUNT).fill(undefined).map((item, index ) => ({ id: `user-word-${ index }`, value: '' })) ];
    setUserWords(words);
  }

  const onClickLettersHandler = (index) => {
    const newIndexes = [ ...letterIndexes, index];
    const userWord = getUserWord(newIndexes);
    setLetterIndexes(newIndexes);
    setCurrentUserWord(userWord);
    checkWordPattern(userWord);
  }

  const onEraseHandler = () => {
    let newIndexes = []
    if(letterIndexes.length > 1) {
      newIndexes = [ ...letterIndexes ].slice(0, -1);
    }
    const userWord = getUserWord(newIndexes);
    setLetterIndexes(newIndexes);
    setCurrentUserWord(userWord);
    checkWordPattern(userWord);
  }

  const getUserWord = (indexes) => {
    return indexes.map((item) => baseWord.base[item]).join('');
  }

  const checkWord = (word) => {
    const patterns = baseWord.words;
    return patterns.includes(word);
  }

  const checkWordExists = (word) => {
    return userWords.find((item) => item.value === word);
  }

  const checkWordPattern = (userWord) => {
    const isWordExists = checkWordExists(userWord);
    if(isWordExists) {
      setHasPattern(false);
      return;
    }
    const hasWordPattern = checkWord(userWord);
    if(hasWordPattern) {
      setHasPattern(true);
    } else {
      if(hasPattern) {
        setHasPattern(false);
      }
    }
  }

  const onOkButtonClickHandler = () => {
    const newUserWords = [ ...userWords ];
    newUserWords[userWordIndex].value = currentUserWord;
    setUserWords(newUserWords);
    setLetterIndexes([]);
    setCurrentUserWord('');
    setHasPattern(false);
    setUserWordIndex(userWordIndex + 1);
  }

  const checkVictory = (isVictory) => {
    setNeedCheck(true);
    let reason = 'complete';
    let skills = winnerPoints;

    if(!isVictory) {
      reason = 'fail';
      skills = null;
    }
    onFinishCb({ victory: Boolean(skills), reason, skills });
  }

  return (
    <section className="trainer-secret-code">
      { showSuccessTab && renderTabResult() }
      <div className="trainer-secret-code__container">
        <DynamicContent widthWorkspace={ widthWorkspace } heightWorkspace={ heightWorkspace } refs={ [ panelRef ] }>
          <div className="trainer-secret-code__words">
            { renderWords({ userWords, needCheck }) }
          </div>
        </DynamicContent>
        <div ref={ panelRef } className="trainer-secret-code__bottom">
          {
            !needCheck &&
            <div className="trainer-secret-code__words-panel">
              <WordsPanel
                indexes={ letterIndexes }
                onEraseHandler={ onEraseHandler }
                word={ currentUserWord }
                hasPattern={ hasPattern }
                onOkButtonClickHandler={ onOkButtonClickHandler }/>
            </div>
          }
          <div className="trainer-secret-code__base-word">
            <BaseWord
              word={ baseWord.base }
              onClick={ onClickLettersHandler }
              disabledLetters={ letterIndexes }
              isClickProhibited={ needCheck }/>
          </div>
        </div>
      </div>
    </section>
  )
}

const renderWords = (props) => {
  const { userWords, needCheck } = props;
  return userWords.map((item) => <Word word={ item.value } key={ item.id } needCheck={ needCheck }/>);
}

const renderTabResult = () => {
  return (
    <div className="trainer-secret-code__tabs">
      <Tabs onClick={ null } isCorrect={ true } selected={ 'answer' } />
    </div>
  );
}

TrainerSecretCode.propTypes = propTypes;

export default TrainerSecretCode;
