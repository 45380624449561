export default function (duration, timePassed) {
  const ms = duration - timePassed;
  const percent = (timePassed / duration) * 100;
  let s = Math.floor((ms / 1000) % 60);
  let m = Math.floor(ms / 1000 / 60);

  if (s < 10) s = '0' + s;
  if (m < 10) m = '0' + m;

  return {
    minutes: `${m || '00'}`,
    seconds: `${s || '00'}`,
    percent
  };
};
