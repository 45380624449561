import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

const templateLevels = {
  1: {
    timer: [30, 40],
    amount: 6,
    cardsInLine: 3
  },
  2: {
    timer: [35, 45],
    amount: 9,
    cardsInLine: 3
  },
  3: {
    timer: [40, 50],
    amount: 12,
    cardsInLine: 4
  },
  4: {
    timer: [45, 55],
    amount: 15,
    cardsInLine: 5
  },
  5: {
    timer: [45, 50],
    amount: 15,
    cardsInLine: 5
  },
  6: {
    timer: [40, 45],
    amount: 15,
    cardsInLine: 5
  }
};

export default getLevels(templateLevels, getLevelParams);
