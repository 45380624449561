export default {
  belt: {
    id: 'belt',
    name: 'Пояс',
    eventLabel: 'poyas',
    image: require('~/static/image/belt.png'),
    disableImage: require('~/static/image/belt_disable.png')
  },
  watch: {
    id: 'watch',
    name: 'Часы',
    eventLabel: 'chasi',
    image: require('~/static/image/watch.png'),
    disableImage: require('~/static/image/watch_disable.png')
  },
  scooter: {
    id: 'scooter',
    name: 'Самокат',
    eventLabel: 'samokat',
    image: require('~/static/image/scooter.png'),
    disableImage: require('~/static/image/scooter_disable.png')
  },
  laptop: {
    id: 'laptop',
    name: 'Ноутбук',
    eventLabel: 'noutbuk',
    image: require('~/static/image/laptop.png'),
    disableImage: require('~/static/image/laptop_disable.png')
  },
  glasses: {
    id: 'glasses',
    name: 'Очки',
    eventLabel: 'ochki',
    image: require('~/static/image/glasses.png'),
    disableImage: require('~/static/image/glasses_disable.png')
  },
  shoes: {
    id: 'shoes',
    name: 'Ботинки',
    eventLabel: 'botinki',
    image: require('~/static/image/shoes.png'),
    disableImage: require('~/static/image/shoes_disable.png')
  },
  // TODO: добавить при новых миссиях
  // skateboard: {
  //   id: 'skateboard',
  //   name: 'Скейтборд',
  //   image: require('~/static/image/skateboard.png'),
  //   disableImage: require('~/static/image/skateboard_disable.png')
  // },
  ring: {
    id: 'ring',
    name: 'Кольцо',
    eventLabel: 'kolco',
    image: require('~/static/image/ring.png'),
    disableImage: require('~/static/image/ring_disable.png')
  },
  cloak: {
    id: 'cloak',
    name: 'Плащ',
    eventLabel: 'plash',
    image: require('~/static/image/cloak.png'),
    disableImage: require('~/static/image/cloak_disable.png')
  },
  umbrella: {
    id: 'umbrella',
    name: 'Зонт',
    eventLabel: 'zont',
    image: require('~/static/image/umbrella.png'),
    disableImage: require('~/static/image/umbrella_disable.png')
  }
}
