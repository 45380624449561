import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import MissionCardSlider from '~/components/MissionCardSlider/MissionCardSlider';
import Button from '~/components/Button/Button';
import MissionResults from '~/components/MissionResults/MissionResults';
import Icon from '~/components/Icon/Icon';
import MissionCardDesc from '~/components/MissionCardDesc/MissionCardDesc';

import { trainersSelectorData } from '~/store/trainers/selector';
import calculateSkillView from './utils/calculateSkillView';

/* eslint-disable */
import play from '~/static/icon/play.svg';
import light from '~/static/icon/light.svg';
import cross from '~/static/icon/cross.svg';
/* eslint-enable */

import './missionCard.scss';

const MissionCard = (props) => {
  const { data, completed, completedMissions, slug, trainers, missions, locationSlug } = props;
  const trainerIds = missions[data.id] && missions[data.id].trainerIds ? missions[data.id].trainerIds : [];
  const [ isStoryOpen, setStory ] = useState(false);
  const [ isResultsOpen, setResults ] = useState(false);

  const switchStory = () => setStory(!isStoryOpen);
  const switchResults = () => setResults(!isResultsOpen);

  const renderPlayButtons = () => {
    const { locked } = data;
    let inner = null

    if(locked) {
      inner = <Fragment> миссия недоступна</Fragment>
    }
    else if(completed) {
      inner = <Fragment> пройти заново </Fragment>
    } else {
      inner = <Fragment>
        начать миссию
        <Icon name="play" className="mission-card__play-icon" />
      </Fragment>
    }

    return (
      <Fragment>
        <Button
          locked={ locked }
          replay={ completed }
          link={ `/locations/${ locationSlug }/${ slug }` }
        >
          { inner }
        </Button>

        {
          completed ?
          <div className="mission-card__result-btn-mobile">
            <Button onClick={ switchResults } result={ true }>
              смотреть результаты
            </Button>
          </div>
          : null
        }
      </Fragment>
    )
  }

  const renderDesc = (skillsView) => {
    const { description, locked } = data;

    return <MissionCardDesc
      skillsView={ skillsView }
      completed={ completed }
      description={ description }
      trainerCount={ trainerIds.length }
      locked={ locked }
    />
  }

  const renderMissionResults = (skillsView) => {
    return (
      <ReactCSSTransitionGroup
        transitionName="slide-left"
        transitionEnterTimeout={ 400 }
        transitionLeaveTimeout={ 400 }
        transitionLeave={ true }
      >
        {
         isResultsOpen &&
          <div className="mission-card__result">
            <MissionResults
              onClose={ switchResults }
              data={ data }
              skills={ skillsView }
            />
          </div>
        }
      </ReactCSSTransitionGroup>
    )
  }

  let missionClass = cx('mission-card', {
    'mission-card_locked': data.locked,
    'mission-card_completed': !!completed
  })

  let resultBtnClass = cx('mission-card__result-btn', {
    'mission-card__result-btn_close': isResultsOpen
  })

  let skillsView = {};
  if (completed) {
    skillsView = calculateSkillView(trainerIds, trainers, completedMissions[data.id]);
  }

  return (
    <Fragment>
      <div className="mission-card__slider">
        <MissionCardSlider
          id={ data.id }
          locked={ data.locked }
          imgs={ data.imgs }
        />
      </div>

      <div className={ missionClass }>
        {
          completed && renderMissionResults(skillsView)
        }

        <div className="mission-card__holder">
          <ReactCSSTransitionGroup
            transitionName="slide-down"
            transitionEnterTimeout={ 400 }
            transitionLeaveTimeout={ 400 }
            transitionLeave={ true }
          >
            {
              isStoryOpen &&
              <div className="mission-card__story">
                { data.story }

                <button onClick={ switchStory } className="mission-card__close">
                  <Icon name="cross" className="mission-card__close-icon" />
                </button>
              </div>
            }
          </ReactCSSTransitionGroup>

          <button onClick={ switchStory } className="mission-card__about">
            <Icon name="light" className="mission-card__light-icon"/>
          </button>

          <div className="mission-card__info">
            <h2 className="mission-card__name">
              <div className="mission-card__mission-word">
                Миссия:&nbsp;
              </div>
              { data.name }
            </h2>

            { renderDesc(skillsView) }

            <div className="mission-card__play">
              { renderPlayButtons() }
            </div>
          </div>

          {
            completed &&
            <Fragment>
              <button onClick={ switchResults } className={ resultBtnClass }>
                <div className="mission-card__result-arrow"></div>
              </button>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  trainers: trainersSelectorData(state),
  completedMissions: state.profile.completedMissions,
  missions: state.profile.missions
});

export default connect(mapStateToProps)(MissionCard);
