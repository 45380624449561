export default {
  image: require('~/static/trainers/funnyPoints/apple.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -4,   y: 399 },
    { id: 2,  x: 4,    y: 304 },
    { id: 3,  x: 31,   y: 221 },
    { id: 4,  x: -8,   y: 219 },
    { id: 5,  x: -22,  y: 388 },
    { id: 6,  x: -126, y: 387 },
    { id: 7,  x: -240, y: 476 },
    { id: 8,  x: -241, y: 637 },
    { id: 9,  x: -171, y: 740 },
    { id: 10, x: -27,  y: 782 },
  ]
}
