import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import Icon from '~/components/Icon/Icon';
import LevelScale from '~/components/LevelScale/LevelScale';
import Achievement from '~/components/Achievement/Achievement';
import TooltipButton from '~/components/TooltipButton/TooltipButton';

import * as pageActions from '~/store/page/actions';

import popupNames from '~/services/popup-names';

/* eslint-disable */
import angle from './assets/angle.svg';
import icon0 from './assets/skillLevels/icon0.svg';
import icon1 from './assets/skillLevels/icon1.svg';
import icon2 from './assets/skillLevels/icon2.svg';
import icon3 from './assets/skillLevels/icon3.svg';
import icon4 from './assets/skillLevels/icon4.svg';
/* eslint-enable */

import './profile-points.scss';

const ProfilePoints = (props) => {
  const { gameLevel, points, skillLevelName, maxPoints, skillLevel, setPopup } = props;
  const toogleTooltip = () => setPopup(popupNames.profileLevel);
  const toogleSkillsTooltip = () => setPopup(popupNames.profileSkills);
  const isBig = useMediaQuery({ minWidth: 429 });

  const renderAngles = (nums) => (
    nums.map(num => (
      <div
        className={ cx('profile-points__angle', {
          [`profile-points__angle_${num}`]: num
        }) }
        key={ num }
      >
        <Icon name="angle" className="profile-points__angle-icon" />
      </div>
    ))
  )

  return (
    <div className="profile-points">
      <div className="profile-points__holder">
        <div className="profile-points__points">
          <header className="profile-points__header">
            <div className="profile-points__level-holder">
              <div className="profile-points__level">
                { gameLevel } { isBig ? 'уровень' : 'ур.' }
              </div>

              <div className="profile-points__button">
                <TooltipButton
                  iconName='show-tooltip'
                  onClick={ toogleTooltip }
                />
              </div>
            </div>


            <div className="profile-points__value">
              { points } / { maxPoints } баллов
            </div>
          </header>

          <div className="profile-points__scale">
            <LevelScale
              points={ points }
              maxPoints={ maxPoints }
              isInProfile={ true }
              isShort={ true }
            />
          </div>
        </div>

        <div className="profile-points__status">
          <header className="profile-points__status-header">статус</header>

          <div className="profile-points__status-value">
            <div className="profile-points__status-title">
              { skillLevelName }
            </div>

            <div className="profile-points__button">
              <TooltipButton
                iconName='show-tooltip'
                onClick={ toogleSkillsTooltip }
              />
            </div>
          </div>

          <div className="profile-points__status-level">
            <Icon name={ `icon${ skillLevel }` } className="profile-points__status-icon" />
          </div>
        </div>

        <div className="profile-points__achievements">
          <header className="profile-points__achievements-header">последние достижения</header>

          <ul className="profile-points__achievements-list">
            <li className="profile-points__achievements-item">
              <Achievement iconName='star' />
            </li>
            <li className="profile-points__achievements-item">
              <Achievement iconName='horse' />
            </li>
          </ul>
        </div>
      </div>

      {
        renderAngles([ 1, 2, 3, 4 ])
      }
    </div>
  )
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(null, mapDispatchToProps)(ProfilePoints);
