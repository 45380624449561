import React, { useState, useEffect, useRef } from 'react';

import Tabs from '~/components/Tabs/Tabs';
import Button from '~/components/Button/Button';
import NumberPanel from '~/components/NumberPanel/NumberPanel';
import DynamicContent from '~/components/DynamicContent/DynamicContent';

import getShuffleArray from '~/services/get-shuffle-array';
import getContentStyle from '~/services/get-content-style';

import { BUTTON_NEXT, BUTTON_CHECK } from '~/utils/constants';

import './trainer-spatial.scss';

const TrainerSpatial = (props) => {
  const { params, isTimerActive, onStartCb, onFinishCb, onStageChange, handleDataLayer,
          onTimeEnd, widthWorkspace, heightWorkspace } = props;
  const { difficultyParams, stageAmount } = params;
  const { content, winnerPoints } = difficultyParams;

  const titleRef = useRef(null);
  const panelRef = useRef(null);
  const [ widthContent, setWidthContent ] = useState(0);
  const [ heightContent, setHeightContent ] = useState(0);
  const [ aspectRatio, setAspectRatio ] = useState(0);
  const [ wins, setWins ] = useState(0);
  const [ isWin, setWin ] = useState(false);
  const [ isFinish, setFinish ] = useState(false);
  const [ currentImages ] = useState(getShuffleArray(content, stageAmount));
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ currentAnswer, setCurrentAnswer ] = useState('');

  const currentImage = currentImages[currentIndex] || {};
  const { src, answer } = currentImage;

  const isLast = stageAmount === currentIndex + 1;
  const isRenderButton = (!isFinish && currentAnswer) || (isFinish && !isLast);
  const isCorrect = isWin && isFinish;
  const isIncorrect = !isWin && isFinish;
  const handlerChange = (value) => setCurrentAnswer(value);

  useEffect(() => {
    if (!isTimerActive && !isFinish) {
      handleDataLayer({
        stageName: 'page2_solve',
        event: 'timerTick',
        eventAction: 'next_force',
        stageLevel: currentIndex + 1
      });
      finishStage();
    }

    if (!isTimerActive && isLast) {
      const isRightAnswer = answer === Number(currentAnswer);
      checkVictory(isFinish ? 0 : Number(isRightAnswer));
    }
  }, [ isTimerActive ]);

  const checkVictory = (lastWin) => {
    let reason = 'complete';
    let skills = winnerPoints;

    if (stageAmount !== wins + lastWin) {
      reason = 'fail';
      skills = null;
    }

    onFinishCb({ victory: Boolean(skills), reason, skills });
  };

  const finishStage = () => {
    const win = answer === Number(currentAnswer);
    const winsAmount = win ? wins + 1 : wins;
    setFinish(true);
    setWin(win);
    setWins(winsAmount);
    onTimeEnd();
  };

  const startStage = () => {
    onStageChange();
    setFinish(false);
    setCurrentAnswer('');
    setCurrentIndex(currentIndex + 1);
    onStartCb();
  };

  const renderButton = () => {
    let text = BUTTON_CHECK;
    let handleClick = () => {
      finishStage();
      handleDataLayer({ stageName: 'page2_solve', stageLevel: currentIndex + 1 });
    };

    if (isFinish) {
      text = BUTTON_NEXT;
      handleClick = () => {
        startStage();
        handleDataLayer({ stageName: 'page3_check', stageLevel: currentIndex + 1 });
      };
    }

    return (
      <div className="trainer-spatial__next">
        <Button className="button_center" onClick={ handleClick }>
          { text }
        </Button>
      </div>
    );
  };

  const onLoadImage = ({ target }) => {
    const { naturalWidth, naturalHeight } = target;
    const aspectRatioImage = naturalWidth / naturalHeight;

    setAspectRatio(aspectRatioImage);
  };

  const setContentSize = ({ width, height }) => {
    setWidthContent(width);
    setHeightContent(height);
  };

  const getImageStyle = (padding) => getContentStyle({ padding, widthContent, heightContent, aspectRatioContent: aspectRatio });

  return (
    <section className="trainer-spatial">
      { isCorrect && <Tabs isCorrect={ true }/> }
      <div ref={ titleRef } className="trainer-spatial__title">Сколько здесь кубиков?</div>
      <DynamicContent widthWorkspace={ widthWorkspace } heightWorkspace={ heightWorkspace }
                      refs={ [ titleRef, panelRef ] } setSize={ setContentSize }>
        <div style={ getImageStyle() } className="trainer-spatial__image-holder">
          <img style={ getImageStyle(40) } onLoad={ onLoadImage } className="trainer-spatial__image" src={ src }
               alt={ `Картинка для части ${ currentIndex + 1 }` }/>
        </div>
      </DynamicContent>
      { isIncorrect && renderCorrectAnswer(answer) }
      <div ref={ panelRef } className="trainer-spatial__panel">
        <NumberPanel onChange={ handlerChange } currentValue={ currentAnswer } isFinish={ isFinish } isWin={ isWin }/>
      </div>
      { isRenderButton && renderButton() }
    </section>
  );
};

const renderCorrectAnswer = (answer) => (
  <div className="trainer-spatial__correct-answer">
    Правильный ответ: { answer }
  </div>
);

export default TrainerSpatial;
