export default [
  {
    base: 'арифметика',
    words: [
      'арифметик',
      'фермата',
      'материк',
      'метрика',
      'ритмика',
      'матерка',
      'кариам',
      'мирика',
      'фармак',
      'китара',
      'ракита',
      'карета',
      'ракета',
      'аматер',
      'камера',
      'трефка',
      'фертик',
      'кармат',
      'кифара',
      'фатеи',
      'кефир',
      'матка',
      'карма',
      'макар',
      'марка',
      'рамка',
      'афера',
      'тариф',
      'маета',
      'ерика',
      'имрет',
      'карат',
      'карта',
      'катар',
      'тараф',
      'кафир',
      'рафик',
      'факир',
      'фиакр',
      'акрит',
      'ферми',
      'трефа',
      'актер',
      'катер',
      'терка',
      'митра',
      'тимар',
      'рифма',
      'фирма',
      'акмит',
      'арека',
      'керма',
      'мерка',
      'макет',
      'метка',
      'ферма',
      'трема',
      'тиара',
      'крем',
      'мера',
      'рема',
      'мате',
      'мета',
      'тема',
      'арак',
      'арка',
      'кара',
      'рака',
      'кафа',
      'трек',
      'кмет',
      'рита',
      'мрак',
      'икар',
      'икра',
      'маки',
      'факт',
      'мирт',
      'ритм',
      'метр',
      'тире',
      'треф',
      'ферт',
      'фетр',
      'фата',
      'арат',
      'тафа',
      'фарт',
      'икта',
      'мари',
      'мира',
      'рами',
      'каре',
      'раек',
      'река',
      'кафе',
      'феак',
      'ерик',
      'ирит',
      'фита',
      'маар',
      'мара',
      'фема',
      'март',
      'трам',
      'арфа',
      'фара',
      'кета',
      'кафр',
      'фрак',
      'карт',
      'трак',
      'кер',
      'афт',
      'рама',
      'фат',
      'тир',
      'риф',
      'кир',
      'кри',
      'икт',
      'кит',
      'тик',
      'рет',
      'мат',
      'мер',
      'ара',
      'ера',
      'фет',
      'тиф',
      'каф',
      'акр',
      'арк',
      'кар',
      'рак',
      'акт',
      'кат',
      'кам',
      'мак',
      'миф',
      'мир',
      'аир',
      'фа',
      'ат',
      'ми',
      'ар',
      'ер',
      'ре',
      'ме'
    ]
  },
  {
    base: 'вентилятор',
    words: [
      'ветрило',
      'тенорит',
      'отнятие',
      'ретинол',
      'триолет',
      'лорнет',
      'отенит',
      'тритон',
      'ливрея',
      'винтер',
      'витрен',
      'неврит',
      'тротил',
      'теория',
      'неволя',
      'тетрил',
      'неолит',
      'роевня',
      'олеин',
      'релин',
      'тенор',
      'отлет',
      'торет',
      'литер',
      'ответ',
      'ровня',
      'ливер',
      'велит',
      'левит',
      'нилот',
      'отлив',
      'титло',
      'нетто',
      'интер',
      'нория',
      'норит',
      'ритон',
      'ортит',
      'торит',
      'теин',
      'вето',
      'лето',
      'отел',
      'тело',
      'орел',
      'тлен',
      'линт',
      'енол',
      'отит',
      'терн',
      'трен',
      'риял',
      'литр',
      'трон',
      'вино',
      'воин',
      'овин',
      'винт',
      'твин',
      'вилт',
      'ливр',
      'тетя',
      'тире',
      'ветр',
      'теля',
      'вено',
      'нерв',
      'тент',
      'титр',
      'илот',
      'тиол',
      'торт',
      'трот',
      'тоня',
      'явор',
      'енот',
      'воля',
      'тори',
      'трио',
      'ория',
      'роля',
      'лори',
      'влет',
      'онер',
      'тир',
      'вор',
      'ров',
      'рея',
      'рет',
      'вол',
      'лов',
      'тля',
      'ион',
      'рот',
      'рев',
      'нот',
      'тон',
      'лев',
      'ил',
      'ля',
      'яр',
      'ре',
      'ор'
    ]
  },
  {
    base: 'АКТИВНОСТЬ',
    words: [
      'САН',
      'АНТ',
      'ИВА',
      'ИКС',
      'ИСК',
      'ИКТ',
      'КИТ',
      'ТИК',
      'КОН',
      'НОК',
      'ИОТ',
      'ОСТ',
      'ОСЬ',
      'ВАН',
      'ИНЬ',
      'НИТ',
      'ИОН',
      'АСК',
      'САК',
      'АКТ',
      'КАТ',
      'КИН',
      'КОТ',
      'ТОК',
      'ОСК',
      'СОК',
      'ОСА',
      'ТИС',
      'ВИС',
      'ТИВ',
      'НОС',
      'СОН',
      'НОТ',
      'ТОН',
      'АТ',
      'АС',
      'ОВ',
      'СИОКИНО',
      'ВОНЬ',
      'НОВЬ',
      'ТИНА',
      'АНИС',
      'САНИ',
      'СКАТ',
      'КИСА',
      'КАИН',
      'ВОСК',
      'СКОВ',
      'СВАН',
      'ВАН',
      'НАВЬ',
      'АИСТ',
      'САТИ',
      'ТИАС',
      'ТАТ',
      'НОА',
      'ТСТАВНИК',
      'НАСТОВИК',
      'ТАВОТНИК',
      'СТОТИНКА',
      'ТАИНСТВО',
      'ТИТОВКА',
      'КАНОИСТ',
      'ОСТАНКИ',
      'СКОТИНА',
      'ОВСИНКА',
      'ТАКОНИТ',
      'ТАНКИСТ',
      'СНИТОК',
      'СОТНИК',
      'ТОКСИН',
      'ВНОСКА',
      'СНОВКА',
      'ОКТАНТ',
      'СВИНКА',
      'ВАТНИК',
      'КВИНТА',
      'СИНЬКА',
      'СВИТКА',
      'НАТИСК',
      'ОТТИСК',
      'СТАВОК',
      'СВИТОК',
      'СТАТИВ',
      'ВИКОНТ',
      'АКОНИТ',
      'КАТИОН',
      'ТОНИКА',
      'СТАНОК',
      'ТАКСОН',
      'АКСОН',
      'НОСАК',
      'НОСКА',
      'ОКТАН',
      'НОТИС',
      'СВИТА',
      'САТИН',
      'СТАТЬ',
      'НОСИК',
      'ОНИКС',
      'ТОНИК',
      'ВИСКА',
      'СИВКА',
      'АКТИВ',
      'ОТКАТ',
      'ИКОТА',
      'КОАТИ',
      'КИСТА',
      'ТАКСИ',
      'ТИТАН',
      'СОТКА',
      'ТОСКА',
      'КВОТА',
      'ОКИСЬ',
      'ИСТОК',
      'СТОИК',
      'ТАВОТ',
      'ВИТОК',
      'КИВОТ',
      'ОСИНА',
      'НИКСА',
      'САНКИ',
      'СИКАН',
      'АКТИН',
      'АНТИК',
      'НИТКА',
      'ТАКИН',
      'ИКОНА',
      'ОВИСТ',
      'ВИСОК',
      'СОВИК',
      'ТВИСТ',
      'СКАНЬ',
      'СОВКА',
      'СКОТТ',
      'КОСТЬ',
      'КВАНТ',
      'КИСТЬ',
      'АТТИК',
      'НОВИК',
      'НАВИС',
      'ВАТИН',
      'ТКАНЬ',
      'ОСТИТ',
      'СОТА',
      'ТОСА',
      'СТОН',
      'КАНТ',
      'ТАНК',
      'КАНО',
      'КАОН',
      'ВАТТ',
      'ТАТЬ',
      'НОТА',
      'НАОС',
      'ТАКТ',
      'ОТИТ',
      'СИНЬ',
      'КИОТ',
      'СКИТ',
      'ВИНА',
      'НИВА',
      'СКОТ',
      'СТОК',
      'ВИНО',
      'ВОИН',
      'ОВИН',
      'ВИНТ',
      'ТВИН',
      'ВИКА',
      'НИТЬ',
      'КОНЬ',
      'СОВА',
      'КВАС',
      'ТОСТ',
      'ИОТА',
      'СВАТ',
      'СТАВ',
      'КОСА',
      'КОАТ',
      'ИКТА',
      'ВИСТ',
      'СИТО',
      'СОТИ',
      'НАСТ',
      'СТАН',
      'ОСТЬ',
      'ИНОК'
    ]
  },
  {
    base: 'ПЛАТЕЛЬЩИК',
    words: [
      'КАПИТЕЛЬ',
      'ПАЛЬЩИК',
      'ЛЕТАЛЬ',
      'ЛЕПЩИК',
      'ПИЩАЛЬ',
      'ПЛАТЬЕ',
      'КАПЕЛЬ',
      'КАПИЩЕ',
      'КИТЕЛЬ',
      'ПЛИТКА',
      'ПЛЕТКА',
      'ПИТЬЕ',
      'ПЛЕТЬ',
      'КЕЛЬТ',
      'КЛЕТЬ',
      'ЩИПКА',
      'ЩЕТКА',
      'ЛЕПТА',
      'ЛИТКА',
      'ТАЛИК',
      'ИЛЬКА',
      'АЛКИЛ',
      'ПИЛКА',
      'ПЕЛИТ',
      'ТЕЛИК',
      'КЛЕЩИ',
      'ПЬЕТА',
      'ПАКЕТ',
      'ЩЕПКА',
      'ЛЕПКА',
      'ПЛАКЕ',
      'ЩЕЛКА',
      'ЛЕТКА',
      'ТЕЛКА',
      'ПИКЕТ',
      'ТАЛЬК',
      'АПЛИТ',
      'ПЛИТА',
      'ЛИТЬЕ',
      'ЛИПА',
      'ПАЛИ',
      'ПИЛА',
      'КЛИП',
      'КИЛЬ',
      'КАЛИ',
      'КИЛА',
      'КИПА',
      'ПИКА',
      'ПАЛЬ',
      'ПЛАЩ',
      'ПЛАТ',
      'ЛЕЩ',
      'ЩЕЛЬ',
      'ПИТА',
      'ПИЩА',
      'ТЕЩА',
      'АЛЬТ',
      'ТАЛЬ',
      'ПАКТ',
      'ЩЕПА',
      'ИКТА',
      'ЕЛКА',
      'ПАЕК',
      'КЕПИ',
      'ПИКЕ',
      'ТИЛЛ',
      'КЛЕЩ',
      'ЩЕЛК',
      'ЛЕТА',
      'ЩЕКА',
      'КЕТА',
      'ПЕК',
      'ЛЕК',
      'ПЬЕ',
      'ИКТ',
      'КИТ',
      'ТИК',
      'КИП',
      'ПИК',
      'КИЛ',
      'ЛИК',
      'ТИП',
      'ЕЛЬ',
      'ЛЬЕ',
      'ЛЕЩ',
      'ЛЕТ',
      'ПАЛ',
      'ЛАТ',
      'ТАЛ',
      'АКТ',
      'КАТ',
      'КАП',
      'ПАК',
      'КАЛ',
      'ЛАК',
      'ПАТ',
      'ЩИТ',
      'ЛИТ',
      'АИЛ',
      'АТ',
      'ПА',
      'ИЛ',
      'ПИ',
      'ЩИ'
    ]
  },
  {
    base: 'ТРАЕКТОРИЯ',
    words: [
      'РЕКТОРАТ',
      'ТЕРРАКОТ',
      'ТРАКТОР',
      'ТРАКТИР',
      'ОТТИРКА',
      'РИКОТТА',
      'РЕАКТОР',
      'РЕТОРКА',
      'АРТЕРИЯ',
      'РАРИТЕТ',
      'КОТЯРА',
      'АРТРИТ',
      'ТЕОРИЯ',
      'РИКОТА',
      'ТЕРАКТ',
      'РЕКТОР',
      'КОТЯТА',
      'КАРТЕР',
      'КРАТЕР',
      'ТРАКТ',
      'ТЕТКА',
      'ОТКАТ',
      'РЕТРО',
      'ТРОЯК',
      'КАРРИ',
      'КОРЕЯ',
      'РОКЕР',
      'РОТИК',
      'КРОТА',
      'АКТЕР',
      'АРТЕК',
      'КАТЕР',
      'ТЕРКА',
      'ТЕРРА',
      'ТЕАТР',
      'ТРЕК',
      'ТАКТ',
      'ОТИТ',
      'РОКИ',
      'ИКЕА',
      'АРКИ',
      'ИКАР',
      'ИКРА',
      'ИРАК',
      'КАИР',
      'КАРИ',
      'АРТО',
      'РОТА',
      'ТАРО',
      'ТЕТЯ',
      'ТИРЕ',
      'КРОТ',
      'ЯРКО',
      'ТРОЕ',
      'КОРА',
      'ТАКО',
      'ТОРТ',
      'КАРЕ',
      'РЕКА',
      'АРИЯ',
      'ТРОЯ',
      'ТИР',
      'ТРИ',
      'КИТ',
      'ТИК',
      'РИО',
      'АРТ',
      'РАЯ',
      'АКР',
      'РАК',
      'АКТ',
      'РОТ',
      'КОТ',
      'ТОК',
      'ТОТ',
      'ЯК',
      'ЯР',
      'АР'
    ]
  },
  {
    base: 'ЖУРАВЛЕНОК',
    words: [
      'ОВРАЖЕК',
      'КРУЖЕВО',
      'ЖАРКОЕ',
      'КОЖУРА',
      'ВУЛКАН',
      'УЛОВКА',
      'КОНУРА',
      'ОРАКУЛ',
      'ЖЕРНОВ',
      'ЖАРЕВО',
      'ЖУРНАЛ',
      'КОРАН',
      'КРОНА',
      'НОРКА',
      'КОРАЛ',
      'НЕРКА',
      'КОЛЕР',
      'КРЕОЛ',
      'ВЕНОК',
      'КОВЕР',
      'ЛЕЖАК',
      'ЛЕЖКА',
      'ВОЖАК',
      'РУЛОН',
      'КЛОУН',
      'КОЛУН',
      'КУЛОН',
      'УКЛОН',
      'ЖЕРЛО',
      'ВОКАЛ',
      'ЛУЖОК',
      'ЛОЖКА',
      'НОЖКА',
      'КУРАЖ',
      'ВАЛУН',
      'ВОЛАН',
      'ВОЛНА',
      'ЖЕВОК',
      'РУКАВ',
      'ЛАРЕК',
      'ОКЕАН',
      'РЕВУН',
      'ЛЕВАК',
      'ЛУНКА',
      'ЖАНР',
      'ОРЕЛ',
      'КРАН',
      'ВНУК',
      'ЛУЖА',
      'ЖАЛО',
      'ЛОЖА',
      'НОРА',
      'КРЕН',
      'КЛОН',
      'УКОР',
      'УРОК',
      'ЖАКО',
      'КОЖА',
      'РУНО',
      'УРОН',
      'ВРУН',
      'ЛОЖЕ',
      'АЖУР',
      'КРОН',
      'УКОЛ',
      'ВЕНА',
      'НЕРВ',
      'РОЖА',
      'ЛАВР',
      'ОВАЛ',
      'УВАР',
      'РУНА',
      'УРАН',
      'УРНА',
      'КОРА',
      'УЛОВ',
      'ЕЛКА',
      'КАРЕ',
      'РЕКА',
      'КЛАН',
      'ЛУНА',
      'ВЕКО',
      'КЛЕВ',
      'КЛЕН',
      'КОРЖ',
      'КРОВ',
      'ЖЕНА',
      'НРАВ',
      'РУКА',
      'ВОЛК',
      'КЕН',
      'ЛЕК',
      'ВОР',
      'РОВ',
      'АКР',
      'АРК',
      'КАР',
      'РАК',
      'КАЛ',
      'ЛАК',
      'РОК',
      'ВАЛ',
      'НОЖ',
      'ЛУК',
      'КОЛ',
      'ВЕК',
      'ЖУК',
      'РЕВ',
      'ЛЕВ',
      'УЖ',
      'АР',
      'РЕ',
      'ЕЖ',
      'ОР'
    ]
  },
  {
    base: 'ЛИТЕРАТУРА',
    words: [
      'ТРАТУАР',
      'ЛАУРЕАТ',
      'ТЕАТРАЛ',
      'ЛИТЕРА',
      'РИТУАЛ',
      'УРЕТРА',
      'УТРАТА',
      'ТУАЛЕТ',
      'АРТРИТ',
      'АРЕАЛ',
      'ТРИАЛ',
      'ЛАТТЕ',
      'ТРАУР',
      'ЛАУРА',
      'ТИТУЛ',
      'ТРАТА',
      'РУЛЕТ',
      'ТЕАТР',
      'ЛАРИ',
      'ЛИРА',
      'ТАТУ',
      'ЛИТР',
      'ТИРЕ',
      'ТАРА',
      'ТИТР',
      'УРАЛ',
      'РЕАЛ',
      'ЛАТЕ',
      'ТРИ',
      'ЛЕТ',
      'АРТ',
      'ИЛ',
      'РЕ'
    ]
  },
  {
    base: 'ДЕКЛАРАЦИЯ',
    words: [
      'РЕДАКЦИЯ',
      'РАДИКАЛ',
      'РЕАКЦИЯ',
      'АКАЦИЯ',
      'ЯДРИЦА',
      'ЛЕКЦИЯ',
      'АРЕАЛ',
      'ДИЛЕР',
      'ЛИДЕР',
      'КРАЛЯ',
      'ЛИКЕР',
      'ИДЕАЛ',
      'РАЦИЯ',
      'ДРАКА',
      'АКЦИЯ',
      'ЛАРЕЦ',
      'ЛАРЕК',
      'АРИЕЦ',
      'ЦЕДРА',
      'ИДЕЯ',
      'ЛЕДИ',
      'ЛИРА',
      'РИАЛ',
      'АРКА',
      'КАРА',
      'ЛАДА',
      'РАДА',
      'ЦИКЛ',
      'ИКАР',
      'ИКРА',
      'КЕДР',
      'ЦИРК',
      'КАДР',
      'ЕЛКА',
      'КАРЕ',
      'РЕКА',
      'ДАЯК',
      'КЛАД',
      'РЕАЛ',
      'АРИЯ',
      'РЯД',
      'АКР',
      'РАК',
      'КАЛ',
      'ЛАК',
      'ДАР',
      'ЛАД',
      'ЕДА',
      'ЛЕД',
      'ЯД',
      'ИЛ',
      'ЯК',
      'ЛЯ',
      'ЯР',
      'АД',
      'РЕ'
    ]
  },
  {
    base: 'ЛИКВИДАТОР',
    words: [
      'ЛИТРОВКА',
      'ДИКТОР',
      'ДРОТИК',
      'ЛИКВОР',
      'ОЛИВКА',
      'ОЛИВА',
      'ЛОДКА',
      'ОКЛАД',
      'РОЛИК',
      'АКТИВ',
      'ВАЛИК',
      'ВИЛКА',
      'ИКОТА',
      'ВИЛОК',
      'КВОТА',
      'ВОДКА',
      'РАДИО',
      'ВОКАЛ',
      'АВТОР',
      'ОТВАР',
      'РВОТА',
      'ТОВАР',
      'ВИТОК',
      'ДРОВА',
      'ОТЛИВ',
      'ОТВАЛ',
      'КВАДР',
      'ВКЛАД',
      'ДВОР',
      'ЛИРА',
      'РИАЛ',
      'ЛИТР',
      'КИВИ',
      'ИКРА',
      'РОТА',
      'ТАРО',
      'ИДОЛ',
      'ТОЛК',
      'ОРДА',
      'КОРТ',
      'КРОТ',
      'КАДР',
      'ОВАЛ',
      'ЛОРД',
      'ДИВА',
      'ВОДА',
      'КОРА',
      'КЛАД',
      'ТРИО',
      'КРОВ',
      'ВОЛК',
      'ТРАК',
      'ТВИД',
      'ДИВО',
      'ТИР',
      'ВОР',
      'РОВ',
      'ВАР',
      'ИВА',
      'КИТ',
      'ТИК',
      'ЛОВ',
      'ВИД',
      'РОД',
      'ЛАТ',
      'ЛАР',
      'КОД',
      'ОДА',
      'АКР',
      'РАК',
      'АКТ',
      'ЛАК',
      'РОТ',
      'КОТ',
      'ТОК',
      'РОК',
      'ВАЛ',
      'ДАР',
      'ЛАД',
      'КОЛ',
      'ИЛ',
      'АР',
      'АД',
      'ОР'
    ]
  },
  {
    base: 'ГУБЕРНАТОР',
    words: [
      'ТРЕНОГА',
      'ГОРБУН',
      'УТРОБА',
      'РОТАНГ',
      'ТЕНОР',
      'БАГОР',
      'БЕТОН',
      'ТАБОР',
      'ТОРБА',
      'РЕТРО',
      'БУРАН',
      'ОТРУБ',
      'ГРАНТ',
      'ТРАУР',
      'БАРОН',
      'НАБОР',
      'БАТОН',
      'БЕГУН',
      'ТРУБА',
      'АГЕНТ',
      'АРГОН',
      'ОРГАН',
      'БУТАН',
      'ТАБУН',
      'НАБЕГ',
      'ТРОНА',
      'НУТРО',
      'РЕБРО',
      'ТАНГО',
      'ГРУНТ',
      'БУТОН',
      'БУГОР',
      'ГОРЕ',
      'БРАТ',
      'БАНТ',
      'НОТА',
      'НОРА',
      'НЕГР',
      'ТЕРН',
      'УГОН',
      'ОБЕТ',
      'БУНТ',
      'РОТА',
      'ТАРО',
      'РУНО',
      'УРОН',
      'ТРОН',
      'УБОР',
      'БОРТ',
      'ГОРА',
      'ГЕРБ',
      'ГОРБ',
      'ГРОБ',
      'РАГУ',
      'РУГА',
      'УГАР',
      'РУНА',
      'УРАН',
      'УРНА',
      'ГОРН',
      'ГУБА',
      'ТАБУ',
      'ТУБА',
      'НЕБО',
      'ГРОТ',
      'ТОРГ',
      'ЕНОТ',
      'НЕГА',
      'УТРО',
      'НОГА',
      'РАНГ',
      'НУГА',
      'АУТ',
      'БЕГ',
      'БАР',
      'БРА',
      'РАБ',
      'БОГ',
      'РОГ',
      'ГЕН',
      'РОТ',
      'БОР',
      'ТОН',
      'РЕ',
      'ОР'
    ]
  },
  {
    base: 'АСТРОНОМИЯ',
    words: [
      'АСТРОНОМ',
      'РОМАНИСТ',
      'ТРЯСИНА',
      'МОНИТОР',
      'СТОРОНА',
      'РОМАНС',
      'НАРОСТ',
      'ИСТОМА',
      'МАНТИЯ',
      'МАТРОС',
      'СИРОТА',
      'ТИРАН',
      'СМОТР',
      'ТРАНС',
      'МИНОР',
      'ОСИНА',
      'МАНТО',
      'НОРМА',
      'РОМАН',
      'СТОН',
      'РОСТ',
      'СОРТ',
      'ТОРС',
      'ТРОС',
      'АТРИ',
      'РОСА',
      'РЯСА',
      'РОТА',
      'ТАРО',
      'ТРОН',
      'МОСТ',
      'ТМИН',
      'НАСТ',
      'СТАН',
      'ТИНА',
      'АНИС',
      'САНИ',
      'АТОМ',
      'МАРТ',
      'АРИЯ',
      'АИСТ',
      'СТАЯ',
      'ТИР',
      'ТРИ',
      'РИС',
      'АРТ',
      'СОМ',
      'РОМ',
      'ИМЯ',
      'СОР',
      'РОТ',
      'МИР',
      'ОСА',
      'НОС',
      'СОН',
      'АС',
      'СИ'
    ]
  },
  {
    base: 'ЭКСКАВАТОР',
    words: [
      'СТВОРКА',
      'ЭКВАТОР',
      'КРАСОТА',
      'КОСАТКА',
      'ОКРАСКА',
      'КАРАТЭ',
      'ЭСКОРТ',
      'СТАВКА',
      'КАРКАС',
      'КРАСКА',
      'СОКРАТ',
      'СТРОКА',
      'СТВОР',
      'АСТРА',
      'КАТОК',
      'КОРКА',
      'КАРАТ',
      'КАРТА',
      'СОТКА',
      'ТОСКА',
      'КВОТА',
      'ОТВАР',
      'ТОВАР',
      'ОКРАС',
      'ОСКАР',
      'КОВКА',
      'ВРАТА',
      'ТРАВА',
      'КАКАО',
      'КАСКА',
      'КРАСА',
      'ВАТА',
      'РОСТ',
      'СОРТ',
      'ТОРС',
      'ТРОС',
      'АРКА',
      'РОСА',
      'РОТА',
      'ТАРО',
      'СКОТ',
      'СТОК',
      'СОВА',
      'АВТО',
      'КВАС',
      'СРОК',
      'КРОТ',
      'ТАРА',
      'КОРА',
      'КОСА',
      'АКВА',
      'СКАТ',
      'КВОТ',
      'КРОВ',
      'ВОСК',
      'РАСА',
      'КРАТ',
      'ТРАК',
      'ВОР',
      'РОВ',
      'СЭР',
      'АРТ',
      'СТО',
      'СОР',
      'РОТ',
      'КОТ',
      'СОК',
      'ОСА',
      'АС',
      'ОР'
    ]
  },
  {
    base: 'ГИМНАСТИКА',
    words: [
      'ГИМНАСТКА',
      'ГИМНАСТ',
      'МИСТИКА',
      'СТАКАН',
      'МИТИНГ',
      'МИСТИК',
      'МАГНИТ',
      'МАНГА',
      'МАНКА',
      'МАСКА',
      'САМКА',
      'ТАГАН',
      'САТИН',
      'АКИТА',
      'КНИГА',
      'КИСТА',
      'ТАКСИ',
      'ГАМАК',
      'КАМИН',
      'ТИСКИ',
      'КАСТА',
      'ТАКСА',
      'САНКИ',
      'НИТКА',
      'НИТКИ',
      'АСТМА',
      'КАНАТ',
      'НАКАТ',
      'ТАНК',
      'САГА',
      'АГАТ',
      'НАСТ',
      'КИСА',
      'МИНА',
      'АИСТ',
      'ИКС',
      'ИСК',
      'КИТ',
      'АС'
    ]
  },
  {
    base: 'БЛАЖЕНСТВО',
    words: [
      'СЕНОВАЛ',
      'СНОБ',
      'ЖАЛО',
      'САЛО',
      'БАНТ',
      'СТАЖ',
      'НОТА',
      'ОБЕТ',
      'ЛОЖЕ',
      'СЛОТ',
      'СТОЛ',
      'ВЕНА',
      'СОВА',
      'АВТО',
      'ЖЕСТ',
      'ОВЕН',
      'БЛАТ',
      'ОВАЛ',
      'СЛОН',
      'БЕТА',
      'НАСТ',
      'СВЕТ',
      'НЕБО',
      'ЕНОТ',
      'ЖАБО',
      'ЖЕНА',
      'СЕНО',
      'БОС',
      'ОСА',
      'НОС',
      'СОН',
      'ВЕС',
      'ЛЕВ',
      'АС',
      'ЕЖ'
    ]
  }
];
