export default {
  shapes: [ 7, 6, 5, 4, 3, 2, 1 ],
  offset: {
    desktop: 30,
    tablet: 30,
    mobile: 35
  },
  outline: [
    {
      desktop: {
        x: 303.38,
        y: 130
      },
      tablet: {
        x: 304,
        y: 40
      },
      mobile: {
        x: 216,
        y: 25
      }
    },
    {
      desktop: {
        x: 303.38,
        y: 41
      },
      tablet: {
        x: 344,
        y: 80
      },
      mobile: {
        x: 246,
        y: 55
      }
    },
    {
      desktop: {
        x: 347.6,
        y: 85
      },
      tablet: {
        x: 424,
        y: 80
      },
      mobile: {
        x: 306,
        y: 55
      }
    },
    {
      desktop: {
        x: 438,
        y: 85
      },
      tablet: {
        x: 360.14,
        y: 143.86
      },
      mobile: {
        x: 257.07,
        y: 103.93
      }
    },
    {
      desktop: {
        x: 369.28,
        y: 154.1
      },
      tablet: {
        x: 360.14,
        y: 255.12
      },
      mobile: {
        x: 257.07,
        y: 187.64
      }
    },
    {
      desktop: {
        x: 369.28,
        y: 281.28
      },
      tablet: {
        x: 302.92,
        y: 312.35
      },
      mobile: {
        x: 214.57,
        y: 230.14
      }
    },
    {
      desktop: {
        x: 306.1,
        y: 344.46
      },
      tablet: {
        x: 359.14,
        y: 368.57
      },
      mobile: {
        x: 256.85,
        y: 272.43
      }
    },
    {
      desktop: {
        x: 369.28,
        y: 407.64
      },
      tablet: {
        x: 247.27,
        y: 368.57
      },
      mobile: {
        x: 172.21,
        y: 272.43
      }
    },
    {
      desktop: {
        x: 242.83,
        y: 407.64
      },
      tablet: {
        x: 190.28,
        y: 311.57
      },
      mobile: {
        x: 130.21,
        y: 230.43
      }
    },
    {
      desktop: {
        x: 180.53,
        y: 345.35
      },
      tablet: {
        x: 190.28,
        y: 255
      },
      mobile: {
        x: 130.21,
        y: 188
      }
    },
    {
      desktop: {
        x: 179.82,
        y: 281
      },
      tablet: {
        x: 246.57,
        y: 311.28
      },
      mobile: {
        x: 172.43,
        y: 230.21
      }
    },
    {
      desktop: {
        x: 242.64,
        y: 343.82
      },
      tablet: {
        x: 246.57,
        y: 256.58
      },
      mobile: {
        x: 172.43,
        y: 187.64
      }
    },
    {
      desktop: {
        x: 242.64,
        y: 280.64
      },
      tablet: {
        x: 343.57,
        y: 159.57
      },
      mobile: {
        x: 245.53,
        y: 114.53
      }
    },
    {
      desktop: {
        x: 348.33,
        y: 174.95
      },
      tablet: {
        x: 304,
        y: 120
      },
      mobile: {
        x: 216,
        y: 85
      }
    }
  ],
  outlines: [
    {
      desktop: {
        x: 242.64,
        y: 407.92,
        rotation: 135
      },
      tablet: {
        x: 246.57,
        y: 368.71,
        rotation: 135
      },
      mobile: {
        x: 172.43,
        y: 272.28,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 369.28,
        y: 154,
        rotation: -45
      },
      tablet: {
        x: 360.14,
        y: 143,
        rotation: -45
      },
      mobile: {
        x: 257.07,
        y: 103,
        rotation: -45
      },
      rotation: 45
    },
    {
      desktop: {
        x: 305.64,
        y: 471.28,
        rotation: 135
      },
      tablet: {
        x: 302.57,
        y: 425.14,
        rotation: 135
      },
      mobile: {
        x: 214.43,
        y: 314.85,
        rotation: 135
      },
      rotation: 225
    },
    {
      desktop: {
        x: 348,
        y: 130,
        rotation: 90
      },
      tablet: {
        x: 344,
        y: 120,
        rotation: 90
      },
      mobile: {
        x: 246,
        y: 85,
        rotation: 90
      },
      rotation: 270
    },
    {
      desktop: {
        x: 348.38,
        y: 131,
        rotation: 180
      },
      tablet: {
        x: 344,
        y: 120,
        rotation: 180
      },
      mobile: {
        x: 246,
        y: 85,
        rotation: 180
      },
      rotation: 180
    },
    {
      desktop: {
        x: 148,
        y: 312.82,
        rotation: 45
      },
      tablet: {
        x: 162,
        y: 283.28,
        rotation: 45
      },
      mobile: {
        x: 109,
        y: 209.21,
        rotation: 45
      },
      rotation: 315
    },
    {
      desktop: {
        x: 303.38,
        y: 85,
        rotation: 0
      },
      tablet: {
        x: 304,
        y: 80,
        rotation: 0
      },
      mobile: {
        x: 216,
        y: 55,
        rotation: 0
      },
      rotation: 0
    }
  ]
};
