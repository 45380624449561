import React, { Component } from 'react';
import propTypes from './prop-types';
import cx from 'classnames';

import getShuffleArray from '~/services/get-shuffle-array';
import getContentStyle from '~/services/get-content-style';

import { MAX_CARD_IN_LINE } from './constants';

import Icon from '~/components/Icon/Icon';
import MovesCounter from '~/components/MovesCounter/MovesCounter';
import DynamicContent from '~/components/DynamicContent/DynamicContent';

// eslint-disable-next-line
import answer from './assets/answer.svg';

import './trainerMemo.scss';

class TrainerMemo extends Component {
  state = {
    isActive: true,
    cards: this.createCardsArray(),
    openCards: [],
    correctPairs: [],
    movesCount: 0,
    isCheck: false,
    widthContent: 0,
    heightContent: 0
  };

  componentDidUpdate(prevProps, prevState) {
    const { params, isRefresh, handleDataLayer } = this.props;
    const { difficultyParams } = params;
    const { winnerPoints } = difficultyParams;

    /** Начинаем игру при первом клике на карточку */
    if (prevProps.isTimerActive === false && this.state.openCards.length === 1) {
      this.props.onStartCb();
    }

    /** Проверяем открытые карточки если их 2 */
    if (prevState.openCards !== this.state.openCards && this.state.openCards.length === 2) {
      this.checkEqualCard(this.state.openCards);
    }

    /** Завершаем игру если кончились ходы */
    if (this.state.movesCount >= difficultyParams.movesLimit && this.state.isActive) {
      this.finishTrainer(null, 'fail');
      this.setState({ isActive: false });
      handleDataLayer({ eventAction: 'next_force', event: 'timerTick', stageName: 'page2_solve' });
    }

    /** Завершаем игру если кончилось время */
    if (prevProps.isTimerActive && !this.props.isTimerActive && this.state.isActive) {
      this.finishTrainer(null, 'fail');
      this.setState({ isActive: false });
      handleDataLayer({ eventAction: 'next_force', event: 'timerTick', stageName: 'page2_solve' });
    }

    /** Завершаем игру если все карточки открыты */
    if (this.state.correctPairs.length === difficultyParams.pairs && this.state.isActive) {
      this.finishTrainer(winnerPoints, 'complete');
      this.setState({ isActive: false });
      this.props.onShowPopup();
      handleDataLayer({ stageName: 'page2_solve' });
    }

    if(prevProps.isRefresh !== isRefresh) {
      this.setState({
        isActive: true,
        cards: this.createCardsArray(),
        openCards: [],
        correctPairs: [],
        movesCount: 0,
        isCheck: false
      })
    }
  }

  static randomShuffle(array) {
    let currentIndex = array.length;

    while (0 !== currentIndex) {
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      const temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    let result = [];

    for(let i = 0; i < array.length / MAX_CARD_IN_LINE; i++) {
      const minIndex = i * MAX_CARD_IN_LINE;
      result.push(array.slice(minIndex, minIndex + MAX_CARD_IN_LINE));
    }

    return result;
  }

  createCardsArray() {
    const { params } = this.props;
    const { matrixImages, difficultyParams } = params;
    const count = difficultyParams.pairs;

    const arrayOfCard = getShuffleArray(matrixImages, count).reduce((acc, image, i) => {
      const card = {
        id: i,
        originalId: i,
        image,
        isCorrect: false,
        isOpen: false,
      };
      const duplicateCard = { ...card, id: i + count };

      return [...acc, card, duplicateCard];
    }, []);

    return TrainerMemo.randomShuffle(arrayOfCard);
  }

  patchCardData = (ids, key, value) => {
    const normalizeIds = typeof ids === 'number' ? [ids] : ids;

    this.setState((state) => {
      return {
        cards: state.cards.map((row) => {
          return row.map((card) => {
            if (normalizeIds.includes(card.id)) {
              return {
                ...card,
                [key]: value,
              };
            }

            return card;
          });
        })
      };
    });
  };

  handleClickOnCard = (card) => {
    const { openCards, isActive } = this.state;
    if (card.isOpen || openCards.length >= 2 || !isActive) {
      return;
    }

    this.patchCardData(card.id, 'isOpen', true);
    this.addCardToOpenCards(card);
  };

  incrementMoveCount = () => {
    this.setState({ movesCount: this.state.movesCount + 1 });
  };

  addCardToOpenCards = (card) => {
    this.setState(state => ({ openCards: [...state.openCards, card] }));
  };

  clearOpenCards = () => {
    this.setState({ openCards: [] });
  };

  checkEqualCard = (cards) => {
    setTimeout(() => {
      if (cards[0].originalId === cards[1].originalId) {
        this.pushCardsToCorrectPairs([cards[0], cards[1]]);
        this.patchCardData([cards[0].id, cards[1].id], 'isCorrect', true);
      } else {
        this.patchCardData([cards[0].id, cards[1].id], 'isOpen', false);
      }
      this.clearOpenCards();
      this.incrementMoveCount();
    }, 1000);
  };

  pushCardsToCorrectPairs = (pair) => {
    this.setState(state => {
      return {
        correctPairs: [...state.correctPairs, pair],
      }
    });
  };

  finishTrainer = (skills, reason) => {
    const { onFinishCb } = this.props;
    onFinishCb({ victory: !!skills, reason, skills });

    if (reason === 'fail') {
      this.setState({ isCheck: true })
    }
  };

  renderCard = (card) => {
    const classesCard = cx('trainer-memo__card', {
      'trainer-memo__card_flip': card.isOpen || this.state.isCheck,
      'trainer-memo__card_correct': card.isCorrect,
      'trainer-memo__card_disable': !this.state.isActive,
    });

    return (
      <button
        className={ classesCard }
        key={ card.id }
        onClick={ () => this.handleClickOnCard(card) }
        type="button"
        data-cy="card"
        data-cy-id={ card.originalId }
        data-cy-open={ card.isOpen }
      >
        <div className="trainer-memo__card-inner">
          <div className="trainer-memo__card-front">
            <Icon name="answer" className="trainer-memo__card-icon" />
          </div>
          <div className="trainer-memo__card-back">
            <img alt={`Карточка номер ${card.id + 1}`} src={ card.image } className="trainer-memo__back-image" />
          </div>
        </div>
      </button>
    );
  };

  setContentSize = ({ width, height }) => this.setState({ widthContent: width, heightContent: height });

  getCardsStyle = () => {
    const { params } = this.props;
    const { widthContent, heightContent } = this.state;
    const { difficultyParams } = params;
    const { pairs } = difficultyParams;
    const lines = pairs * 2 / MAX_CARD_IN_LINE;
    const style = getContentStyle({ padding: 0, widthContent, heightContent, aspectRatioContent: MAX_CARD_IN_LINE / lines });

    return style;
  }

  renderCards = () => (
    <div className="trainer-memo__grid" style={ this.getCardsStyle() }>
      {
        this.state.cards.map((row) => {
          return (
            <div className="trainer-memo__row">
              { row.map((card) => this.renderCard(card)) }
            </div>
          );
        })
      }
    </div>
  );

  render() {
    const { params, widthWorkspace, heightWorkspace } = this.props;
    const { movesCount } = this.state;
    const { difficultyParams } = params;
    const count = difficultyParams.movesLimit - Math.floor(movesCount);

    return (
      <section className="trainer-memo">
        <DynamicContent widthWorkspace={ widthWorkspace } heightWorkspace={ heightWorkspace } setSize={ this.setContentSize }>
          { this.renderCards() }
        </DynamicContent>

        <div className="trainer-memo__counter">
          <MovesCounter count={ count } />
        </div>
      </section>
    )
  }
}

TrainerMemo.propTypes = propTypes;

export default TrainerMemo;
