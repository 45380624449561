import React from 'react';
import cx from 'classnames';

import Letter from '../Letter';

import './base-word.scss';

const BaseWord = (props) => {
  const { word, onClick, disabledLetters, isClickProhibited } = props;
  const lettersArray = word.split('');

  const checkDisabled = (index) => {
    return disabledLetters.includes(index);
  }
  const wordClasses = cx('base-word', {
    'base-word_disabled': isClickProhibited
  })

  return (
    <div className={ wordClasses }>
      <div className="base-word__letters">
        {
          lettersArray.map((item, index) =>
            <Letter
              key={ `letter-${ index }` }
              letter={ item }
              isDisabled={ checkDisabled(index) }
              onClick={ onClick.bind(null, index) }/>)
        }
      </div>
    </div>
  )
}

export default BaseWord;
