export default [
  {
    id: 1,
    image: require('~/static/trainers/longMemory/london/tower-bridge.png'),
    name: 'Тауэрский мост',
    text: 'В 1894 г. на церемонии открытия Тауэрского моста присутствовали принц Эдуард Уэльский и его жена. Передовая разработка для того времени позволяла разводить мост всего за 1 минуту. Внутри башен провели лифты. В настоящее время в галереях моста открыты экспозиции его истории, можно спуститься в машинное отделение моста и посмотреть на подъемные механизмы. Тауэрский мост сам по себе отличная смотровая площадка, он очень красив ночью в гирляндах подсветки.',
  },
  {
    id: 2,
    image: require('~/static/trainers/longMemory/london/big-ban.png'),
    name: 'Биг-Бен',
    text: 'Биг-Бен – это колокол, весящий 13,5 тонн и расположенный за циферблатами, однако очень часто так называют всю башню, ставшую символом Лондона. Биг-Бен расположен на башне святого Стефана – знаменитейшей части Вестминстерского дворца.',
  },
  {
    id: 3,
    image: require('~/static/trainers/longMemory/london/london-eye-2.png'),
    name: 'Колесо Обозрения «Лондонский глаз»',
    text: 'Лондонский глаз – третье по высоте колесо обозрения в мире. С высоты 135 метров (около 45 этажей) открывается вид практически на весь Лондон. Колесо построено в честь наступления нового тысячелетия. Лондонский глаз имеет 32 полностью закрытые и кондиционируемые кабинки-капсулы, сделанные в форме яйца. Капсулы символизируют собой 32 пригорода Лондона. Каждая десятитонная капсула может принять до 25 пассажиров. Колесо делает полный круг за 30 минут.',
  },
  {
    id: 4,
    image: require('~/static/trainers/longMemory/london/buckingham-palace.png'),
    name: 'Букингемский дворец',
    text: 'Букингемский дворец – это официальная резиденция Ее Величества королевы в Лондоне. Здесь есть свое отделение полиции, два почтовых офиса, госпиталь, бассейн, бар – почти целый город. В самом дворце 755 комнат, среди них официальный рабочий кабинет королевы.',
  },
  {
    id: 5,
    image: require('~/static/trainers/longMemory/london/madame-museum.png'),
    name: 'Музей мадам Тюссо',
    text: 'Музей мадам Тюссо – это самый знаменитый музей восковых фигур в мире. В нем содержатся более 400 фигур звезд, известных политиков, спортсменов, членов королевских семей, а также преступников. Экспонаты в комнате ужасов бросают вызов посетителям, испытывая их страхи. Музей мадам Тюссо – один из немногих, где экспонаты можно потрогать руками.',
  },
  {
    id: 6,
    image: require('~/static/trainers/longMemory/london/millennium-dome.png'),
    name: 'Купол тысячелетия',
    text: 'В 1995 году в Великобритании было построено одно из самых грандиозных лондонских зданий — Купол тысячелетия. О его масштабах можно судить по тому, что из космоса видны только два сооружения на Земле: Великая Китайская стена и Купол тысячелетия.',
  },
  {
    id: 7,
    image: require('~/static/trainers/longMemory/london/millennium-bridge.png'),
    name: 'Миллениум мост',
    text: 'Мост «Миллениум» был построен над Темзой в честь нового тысячелетия. Прозвище «Шаткий мост» появилось из-за того, что после открытия обнаружилось качание, которое устранили за полтора года. На этом мосту проходили съемки кинофильма «Гарри Поттер: Принц-полукровка».',
  },
  {
    id: 8,
    image: require('~/static/trainers/longMemory/london/nelsons-column.png'),
    name: 'Колонна Нельсона',
    text: 'Памятник вице-адмиралу Горацио Нельсону в виде высокой колонны расположен в Лондоне. Нельсон был выдающейся личностью, профессионалом военного дела, который защищал собственную страну и покорял новые земли. Нельсон погиб в Трафальгарском сражении в 1805 году, и именно поэтому скульптуру установили на одноименной Трафальгарской площади Лондона.',
  },
  {
    id: 9,
    image: require('~/static/trainers/longMemory/london/globus-theatre.png'),
    name: 'Театр «Глобус»',
    text: 'Первый театр построили актеры труппы, к которой принадлежал Уильям Шекспир, в 1599 году. «Глобус» был построен как большой круглый театр под открытым небом. Сверху театр выглядел как пончик. Знаменитая на весь мир фраза «Весь мир — театр» появилась именно тут. Современный, построенный заново театр был открыт в 1997 году, в 200 метрах от первоначального сооружения. Показ спектаклей идет каждое лето, в остальное время в нем проходят экскурсии.',
  },
  {
    id: 10,
    image: require('~/static/trainers/longMemory/london/albert-hall.png'),
    name: 'Альберт-холл',
    text: 'Интересный факт: на постройку здания его создатели Фоук и Скотт собирали деньги путем продажи билетов. Каждый купивший у них билет по цене 100 фунтов получал право приходить на все мероприятия в Альберт-холле в течение 999 лет. До сих пор некоторые приходят по этим билетам, купленным их предками в далеком XIX веке. Здесь проходят всевозможные культурные мероприятия: концерты, оперы, балеты, премьеры кинофильмов и другие события, как коммерческие, так и благотворительные.',
  },
  {
    id: 11,
    image: require('~/static/trainers/longMemory/london/london-college.png'),
    name: 'Университетский колледж Лондона',
    text: 'В рейтинге самых известных университетов мира Университетский колледж Лондона входит в десятку лучших. Он состоит в Золотом треугольнике, представляющем собой группу самых элитных британских университетов: Оксфордский университет, Кембриджский университет и Университетский колледж Лондона.',
  },
  {
    id: 12,
    image: require('~/static/trainers/longMemory/london/st-pauls-cathedral.png'),
    name: 'Собор Святого Павла',
    text: 'Собор является государственной церковью Англии и резиденцией Епископа Лондонского. До 1962 года Собор был самым высоким зданием в Лондоне — 111 метров. Виды на достопримечательность защищены законом — ни одна новая постройка не должна их закрывать. Собор - одна из главных визитных карточек Лондона и часто появляется в рекламе для туристов.',
  },
  {
    id: 13,
    image: require('~/static/trainers/longMemory/london/skyscraper-mary-ex-30.png'),
    name: 'Небоскреб Мэри-Экс 30',
    text: 'Лондонский 40-этажный небоскреб Мэри-Экс назван так в честь улицы, где расположен. Однако местные жители прозвали его «огурец», так как он построен из зеленоватого стекла и имеет схожую с овощем форму. На фоне старинных зданий центра Лондона это здание выглядит как корабль пришельцев. Уникальное строение небоскреба позволяет тратить на обслуживание здания вполовину меньше электроэнергии по сравнению с другими. Но это самое дорогое офисное здание в Великобритании.',
  },
  {
    id: 14,
    image: require('~/static/trainers/longMemory/london/sherlock-holmes-museum.png'),
    name: 'Музей Шерлока Холмса',
    text: 'Сэр Артур Конан-Дойль создал литературного героя — детектива Шерлока Холмса с его верным другом доктором Ватсоном. Именно им Лондон обязан самым известным адресом: Бейкер-стрит, 221b. Кстати, во время написания произведений этого адреса еще не было: Бейкер-стрит в те времена заканчивалась на сотом номере домов. Уже в 1990 г. на Бейкер-стрит открыли частный Музей Шерлока Холмса, ставший одним из самых популярных в Лондоне.',
  },
  {
    id: 15,
    image: require('~/static/trainers/longMemory/london/st-pancras-station.png'),
    name: 'Вокзал Сент-Панкрас',
    text: 'Строительство вокзала продолжалось всего 2 года – с 1866 по 1868 год. За это время были потрачены колоссальные суммы на покупку земли, освобождение территории от домов бедняков, старой церкви и кладбища, заключение в трубу подземной реки Флит. После реставрации 2007 года вокзал Сент-Панкрас стал суперсовременным железнодорожным терминалом, где есть все для комфортного пребывания посетителей.',
  },
  {
    id: 16,
    image: require('~/static/trainers/longMemory/london/wembley-stadium.png'),
    name: 'Стадион «Уэмбли»',
    text: 'Новый «Уэмбли» — футбольный стадион, открывшийся в 2007 году в Лондоне на месте старого стадиона «Уэмбли». Сегодня, это самый дорогой и второй по величине крытый стадион в Европе. Он вмещает 90 000 болельщиков. Кроме футбола на стадионе проводятся матчи по регби и американскому футболу, часто проводятся концерты мировых звезд.',
  },
  {
    id: 17,
    image: require('~/static/trainers/longMemory/london/bus-and-phone-booth.png'),
    name: 'Телефонная будка и двухэтажный автобус',
    text: 'Красная телефонная будка в классическом стиле — это один из символов Лондона, впрочем как и двухэтажный красный автобус — самый популярный вид общественного транспорта после метро. Любовь лондонцев к красному объясняется особенностями климата. В непогоду яркие объекты лучше видны.',
  },
  {
    id: 18,
    image: require('~/static/trainers/longMemory/london/harrods-store.png'),
    name: 'Универмаг «Хэрродс»',
    text: 'Предприятие было основано в 1824 году и началось с небольшой бакалейной лавки с двумя продавцами. Пережив с тех пор пожар и перепродажи, на сегодняшний день это самый известный универмаг Лондона. Он считается одним из самых больших и модных универмагов мира. В нем работает более 5 000 человек. Очень эффектно ночное освещение фасада, состоящее из 12 000 лампочек.',
  },
  {
    id: 19,
    image: require('~/static/trainers/longMemory/london/london-zoo.png'),
    name: 'Лондонский зоопарк',
    text: 'В 1920-е годы канадский полк оставил в зоопарке свой талисман — черную медведицу по кличке Виннипег. Позже в зоопарк пришел писатель Алан Милн со своим сыном Кристофером Робином. Ребенку так понравилась медведица, что он решил назвать так же своего плюшевого мишку. Так  родилась знаменитая сказка о медведе с опилками в голове. В наши дни это один из самых крупных зоопарков в мире. Сейчас в нем проживает около 17 000 животных.',
  },
  {
    id: 20,
    image: require('~/static/trainers/longMemory/london/ship-museum.png'),
    name: 'Музей-корабль «Катти Сарк»',
    text: 'Корабль «Катти Сарк» в конце 19 века снабжал жителей Лондона одним из их любимых продуктов — чаем. Он был самым быстрым в свое время. В наше время этот 963-тонный музей-корабль очень популярен, ведь его подняли на три метра над землей. Гости могут прогуляться прямо под ним.',
  }
];
