export default [
  'аквариум', 'антилопа', 'аллергия', 'баклажан', 'балерина', 'богатырь', 'бумеранг', 'бурундук', 'ватрушка',
  'винегрет', 'виноград', 'винтовка', 'владелец', 'внимание', 'водитель', 'волейбол', 'воротник', 'встряска',
  'гарантия', 'гардероб', 'гербарий', 'геркулес', 'гирлянда', 'гороскоп', 'горошина', 'грузовик', 'гусеница',
  'движение', 'двоечник', 'детектив', 'единорог', 'животное', 'каникулы', 'капелька', 'карандаш', 'карнавал',
  'кастрюля', 'квартира', 'коктейль', 'комиссия', 'компания', 'контроль', 'конфетка', 'кормушка', 'королева',
  'красавец', 'креветка', 'кристалл', 'крокодил', 'кувшинка', 'кузнечик', 'кукуруза', 'культура', 'лабиринт',
  'ласточка', 'лампочка', 'линолеум', 'листопад', 'луковица', 'любимчик', 'мандарин', 'мармелад', 'мартышка',
  'медицина', 'микрофон', 'младенец', 'мудрость', 'нагрузка', 'обезьяна', 'осьминог', 'официант', 'охранник',
  'пельмень', 'петрушка', 'пирожное', 'писатель', 'пистолет', 'пиццерия', 'праздник', 'проблема', 'продавец',
  'простыня', 'прохлада', 'психолог', 'родитель', 'розыгрыш', 'романтик', 'рукавица', 'саксофон', 'салатник',
  'салфетка', 'сапожник', 'светофор', 'сентябрь', 'скакалка', 'скамейка', 'скатерть', 'сладость', 'смешинка',
  'снеговик', 'снегопад', 'снежинка', 'солнышко', 'сосулька', 'староста', 'стержень', 'стрекоза', 'таблетка',
  'талисман', 'туловище', 'уважение', 'ударение', 'указание', 'фантазия', 'фокусник', 'футболка', 'характер',
  'хлебница', 'хлопушка', 'хоккеист', 'художник', 'царапина', 'чаепитие', 'черепаха', 'читатель', 'школьник',
  'щедрость', 'вертолет', 'матрешка', 'фантазер', 'цыпленок', 'черемуха'
];
