export default {
  shapes: [ 3, 6, 1, 5, 4, 7, 2 ],
  offset: {
    desktop: 10,
    tablet: 5,
    mobile: 20
  },
  outline: [
    {
      desktop: { x: 160, y: 112.64 },
      tablet: { x: 175, y: 105.57 },
      mobile: { x: 119, y: 71.43 },
    },
    {
      desktop: { x: 223.64, y: 49 },
      tablet: { x: 231.57, y: 49 },
      mobile: { x: 161.43, y: 29 },
    },
    {
      desktop: { x: 287.28, y: 112.64 },
      tablet: { x: 288.14, y: 105.57  },
      mobile: { x: 203.85, y: 71.43 },
    },
    {
      desktop: { x: 286.82, y: 112.64 },
      tablet: {  x: 287.85, y: 105.57 },
      mobile: { x: 203.64, y: 71.43 },
    },
    {
      desktop: { x: 286.82, y: 175.82 },
      tablet: { x: 287.85, y: 161.85 },
      mobile: { x: 203.64, y: 112.64 },
    },
    {
      desktop: { x: 376, y: 265 },
      tablet: { x: 366.28, y: 240.28 },
      mobile: { x: 262.21, y: 171.21 },
    },
    {
      desktop: { x: 375.91, y: 265.09  },
      tablet: { x: 366.28, y: 240 },
      mobile: { x: 262.21, y: 171  },
    },
     {
      desktop: { x: 439.17, y: 328.35 },
      tablet: { x: 422.85, y: 296.57 },
      mobile: { x: 304.64, y: 213.43 },
    },
    {
      desktop: { x: 438.46, y: 391.28 },
      tablet: { x: 422.85, y: 353.14 },
      mobile: { x: 304.64, y: 255.85 },
    },
    {
      desktop: { x: 375.53, y: 328.35 },
      tablet: { x: 366.28, y: 296.57 },
      mobile: { x: 262.21, y: 213.43 },
    },
    {
      desktop: { x: 374.84, y: 266.16 },
      tablet: { x: 366.28, y: 241.72 },
      mobile: { x: 262.21, y: 172.79 },
    },
    {
      desktop: { x: 286.76, y: 354.24 },
      tablet: { x: 287.57, y: 320.43 },
      mobile: { x: 203.43, y: 231.57 },
    },
    {
      desktop: { x: 286.82, y: 374.46 },
      tablet: { x: 287.57, y: 336.57 },
      mobile: { x: 203.43, y: 249.43 },
    },
    {
      desktop: { x: 223, y: 374.64 },
      tablet: { x: 231, y: 336.57 },
      mobile: { x: 161, y: 249.43 },
    },
    {
      desktop: { x: 286, y: 311.64 },
      tablet: { x: 287, y: 280.57 },
      mobile: { x: 203, y: 207.43 },
    },
    {
      desktop: { x: 286, y: 302.64 },
      tablet: { x: 287, y: 274.57 },
      mobile: { x: 203, y: 197.43 },
    },
    {
      desktop: { x: 204.36, y: 221 },
      tablet: { x: 214.43, y: 202 },
      mobile: { x: 148.57, y: 143 },
    },
    {
      desktop: { x: 115, y: 221 },
      tablet: { x: 135, y: 202 },
      mobile: { x: 89, y: 143 },
    },
    {
      desktop: { x: 159.56, y: 176.19 },
      tablet: { x: 174.72, y: 162.28 },
      mobile: { x: 119, y: 113 },
    },
    {
      desktop: { x: 159, y: 175.64 },
      tablet: { x: 174, y: 161.57 },
      mobile: { x: 119.43, y: 113.43 },
    },
    {
      desktop: { x: 223.18, y: 175.64 },
      tablet: { x: 231.28, y: 161.57 },
      mobile: { x: 161.21, y: 113.43 },
    },
    {
      desktop: { x: 223.18, y: 112.64 },
      tablet: { x: 231.28, y: 105.57 },
      mobile: { x: 161.21, y: 71.43 },
    }
  ],
  outlines: [
    {
      desktop: { x: 205, y: 176 },
      tablet: { x: 215, y: 162 },
      mobile: { x: 149, y: 113 },
      rotation: 90
    },
    {
      desktop: { x: 159, y: 175.64 },
      tablet: { x: 174, y: 161.57 },
      mobile: { x: 119, y: 113.43 },
      rotation: 315
    },
    {
      desktop: { x: 191.36, y: 144 },
      tablet: { x: 203, y: 133.57 },
      mobile: { x: 140, y: 92.43 },
      rotation: 315
    },
    {
      desktop: { x: 223.64, y: 176.28 },
      tablet: { x: 231.57, y: 162.14 },
      mobile: { x: 161.43, y: 113.85 },
      rotation: 225
    },
    {
      desktop: { x: 254.82, y: 406.46 },
      tablet: { x: 259.28, y: 364.85 },
      mobile: { x: 182.21, y: 270.64 },
      rotation: 225
    },
    {
      desktop: { x: 286, y: 175 },
      tablet: { x: 287, y: 161 },
      mobile: { x: 203, y: 112 },
      rotation: 0
    },
    {
      desktop: { x: 343, y: 295.82 },
      tablet: { x: 338, y: 268.28 },
      mobile: { x: 241, y: 192.21 },
      rotation: 315
    },
  ]
};
