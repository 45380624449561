import React from 'react';
import cx from 'classnames';
import Icon from '~/components/Icon/Icon';

import './trainerCardSkill.scss';

const TrainerCardSkill = ({ skill, points, name, hovered }) => (
  <div
    className={ cx('trainer-card-skill', `trainer-card-skill_${skill}`, {
      'trainer-card-skill_hovered': hovered
    }) }
  >
    <div className="trainer-card-skill__wrapper">
      <Icon name={ `${skill}` } className="trainer-card-skill__icon trainer-card-skill__content"  />
      <span className="trainer-card-skill__value trainer-card-skill__content">{ points }</span>
    </div>
    <span className="trainer-card-skill__name">
      { name }
    </span>
  </div>
)

export default TrainerCardSkill;
