export default {
  image: require('~/static/trainers/funnyPoints/butterfly.png'),
  width: 800,
  height: 919,
  offsetX: 25,
  points: [
    { id: 1,  x: 33,  y: 405 },
    { id: 2,  x: 63,  y: 381 },
    { id: 3,  x: 90,  y: 353 },
    { id: 4,  x: 116, y: 315 },
    { id: 5,  x: 135, y: 273 },
    { id: 6,  x: 157, y: 231 },
    { id: 7,  x: 195, y: 193 },
    { id: 8,  x: 236, y: 174 },
    { id: 9,  x: 285, y: 184 },
    { id: 10, x: 313, y: 218 },
    { id: 11, x: 323, y: 263 },
    { id: 12, x: 322, y: 305 },
    { id: 13, x: 312, y: 350 },
    { id: 14, x: 295, y: 383 },
    { id: 15, x: 276, y: 412 },
    { id: 16, x: 252, y: 439 },
    { id: 17, x: 228, y: 465 },
    { id: 18, x: 209, y: 506 },
    { id: 19, x: 200, y: 549 },
    { id: 20, x: 178, y: 580 },
    { id: 21, x: 143, y: 595 },
    { id: 22, x: 101, y: 596 },
    { id: 23, x: 135, y: 621 },
    { id: 24, x: 155, y: 655 },
    { id: 25, x: 158, y: 700 },
    { id: 26, x: 140, y: 735 },
    { id: 27, x: 103, y: 749 },
    { id: 28, x: 57,  y: 721 },
    { id: 29, x: 38,  y: 685 },
    { id: 30, x: 33,  y: 625 }
  ]
};
