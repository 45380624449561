import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './movesCounter.scss';

const MovesCounter = ({ className, count }) => {
  return (
    <div
      className={cx('moves-counter', {
        [className]: className,
        'moves-counter_gray': count < 1,
      })}
    >
      <div className="moves-counter__label">ходов</div>
      <div className="moves-counter__value">{count}</div>
    </div>
  )
};

MovesCounter.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
};

MovesCounter.defaultProps = {
  className: null,
};

export default MovesCounter;
