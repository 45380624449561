import React, { useState, useRef } from 'react';
import { Line, Group } from 'react-konva';

import Arrow from '../Arrow';

const ShapeTransform = (props) => {
  const shape = useRef(null);
  const [ arrowPosition, setArrowPosition ] = useState({});

  const { setSelected, index, isSelected, onDragEnd, onDragMove, isOver, params, rotate } = props;
  const handlerDragStart = () => {
    setCursor('grabbing');
    setSelected(false);
  };
  const handlerDragEnd = (e) => {
    setSelected(index);
    onDragEnd(e);
    const { x, y } = shape.current.getAttrs();
    setArrowPosition({ x, y });
  };
  const handlerMouseOver = () => setCursor('grab');
  const handlerMouseLeave = () => setCursor('default');

  return (
    <Group>
      <Line ref={ shape } onDragMove={ onDragMove } onDragEnd={ handlerDragEnd } onDragStart={ handlerDragStart }
            onMouseOver={ handlerMouseOver } onMouseLeave={ handlerMouseLeave } { ...getStyle(props) } />
      {
        isSelected === index && !isOver && params.isActive &&
        <Arrow setCursor={ setCursor } rotate={ rotate } defaultPosition={ arrowPosition } />
      }
    </Group>
  );
};

const getStyle = (props) => {
  const { params, isSelected, isOver, index } = props;
  let style = params;

  if (isSelected === index && !isOver) {
    style = { ...style, stroke: '#00A4EA', strokeWidth: 2 };
  }
  return style;
};

const setCursor = (name) => {
  document.body.style.cursor = name;
};

export default ShapeTransform;
