export default [
  {
    text: 'Кукушка лает? ',
    answer: false
  },
  {
    text: 'У человека 2 руки? ',
    answer: true
  },
  {
    text: 'Автобус больше мотоцикла ',
    answer: true
  },
  {
    text: 'Утром надо умываться? ',
    answer: true
  },
  {
    text: 'У коня 4 ноги? ',
    answer: true
  },
  {
    text: 'На руке человека 25 пальцев? ',
    answer: false
  },
  {
    text: 'На одной руке человека 5 пальцев? ',
    answer: true
  },
  {
    text: 'Читать книги интересно? ',
    answer: true
  },
  {
    text: 'В полнолуние Луна круглая? ',
    answer: true
  },
  {
    text: 'Юрий Гагарин – первый в мире космонавт? ',
    answer: true
  },
  {
    text: 'Арбуз – это ягода? ',
    answer: true
  },
  {
    text: 'Ель зеленая всегда? ',
    answer: true
  },
  {
    text: 'Один больше двух? ',
    answer: false
  },
  {
    text: 'Два больше одного? ',
    answer: true
  },
  {
    text: 'Два равно трем? ',
    answer: false
  },
  {
    text: 'Три больше двух? ',
    answer: true
  },
  {
    text: 'Десять меньше двадцати? ',
    answer: true
  },
  {
    text: 'Один больше ста? ',
    answer: false
  },
  {
    text: 'Днем светло, а ночью темно? ',
    answer: true
  },
  {
    text: 'Один рубль больше десяти рублей? ',
    answer: false
  },
  {
    text: 'Бобры беззубые? ',
    answer: false
  },
  {
    text: 'Растения нужно беречь? ',
    answer: true
  },
  {
    text: 'В одном метре 100 сантиметров? ',
    answer: true
  },
  {
    text: 'В одном сантиметре 10 миллиметров? ',
    answer: true
  },
  {
    text: 'Дуб вырастает из желудя? ',
    answer: true
  },
  {
    text: 'Нельзя ломать ветки кустов и деревьев? ',
    answer: true
  },
  {
    text: 'Морковь полезно грызть свежую? ',
    answer: true
  },
  {
    text: 'Крыжовник – это ягода? ',
    answer: true
  },
  {
    text: 'Лимонад – это напиток? ',
    answer: true
  },
  {
    text: 'Мед создают пчелы? ',
    answer: true
  },
  {
    text: 'Мед создают медведи? ',
    answer: false
  },
  {
    text: 'Пчелы любят мед? ',
    answer: true
  },
  {
    text: 'Овощ растет на земле? ',
    answer: true
  },
  {
    text: 'Фрукт растет на дереве? ',
    answer: true
  },
  {
    text: 'Пирог с яблоками – это шарлотка? ',
    answer: true
  },
  {
    text: 'Редька полезная? ',
    answer: true
  },
  {
    text: 'Смородина бывает красная, черная и белая? ',
    answer: true
  },
  {
    text: 'Яблоко – полезный фрукт? ',
    answer: true
  },
  {
    text: 'Мармелад готовят из фруктов? ',
    answer: true
  },
  {
    text: 'Фрукт – это сочный съедобный плод дерева или кустарника? ',
    answer: true
  },
  {
    text: 'Варенье – это сладкое кушанье из ягод и фруктов? ',
    answer: true
  },
  {
    text: 'В решете нельзя носить воду? ',
    answer: true
  },
  {
    text: 'Океаны мира называются: Атлантический, Индийский, Тихий, Южный, Северный Ледовитый? ',
    answer: true
  },
  {
    text: 'Океаны мира называются: Желтый, Красный, Южный Ледовитый, Громкий? ',
    answer: false
  },
  {
    text: 'Кактус колючий, кролик пушистый? ',
    answer: true
  },
  {
    text: 'Заяц прыгает, ёжик бегает? ',
    answer: true
  },
  {
    text: 'Маленькие тигрята – забавные «милашки», а взрослый тигр – опасный зверь? ',
    answer: true
  },
  {
    text: 'Во сне ребенок растет? ',
    answer: true
  },
  {
    text: 'Пассажирам самолета можно заходить в кабину пилотов? ',
    answer: false
  },
  {
    text: 'В самолете можно открыть окно? ',
    answer: false
  },
  {
    text: 'На подводной лодке можно открыть окно? ',
    answer: false
  },
  {
    text: 'У самолета нет шасси? Самолет садится на живот? ',
    answer: false
  },
  {
    text: 'У березы есть сережки? ',
    answer: true
  },
  {
    text: 'У дуба есть сережки? ',
    answer: false
  },
  {
    text: 'У яблони есть сережки? ',
    answer: false
  },
  {
    text: 'Киты бывают в реке Волге? ',
    answer: false
  },
  {
    text: 'Блины пекут на пеньке? ',
    answer: false
  },
  {
    text: 'В лесу живут белые медведи? ',
    answer: false
  },
  {
    text: 'В лесу можно от дерева зарядить мобильный телефон? ',
    answer: false
  },
  {
    text: 'Бывают ли у деревьев воздушные корни? ',
    answer: true
  },
  {
    text: 'В небе летают слоны? ',
    answer: false
  },
  {
    text: 'Бывают мосты разводные? ',
    answer: true
  },
  {
    text: 'В воздухе летают подводные лодки? ',
    answer: false
  },
  {
    text: 'Кошки видят в темноте? ',
    answer: true
  },
  {
    text: 'Солнце нас греет? ',
    answer: true
  },
  {
    text: 'На солнце нам становится холоднее? ',
    answer: false
  },
  {
    text: 'Пластмассовый пакет растворится в воде? ',
    answer: false
  },
  {
    text: 'Можно в реку кидать мусор? ',
    answer: false
  },
  {
    text: 'Можно ли назвать один цветок букетом? ',
    answer: false
  },
  {
    text: 'Груша – это овощ? ',
    answer: false
  },
  {
    text: 'Страус умеет летать? ',
    answer: false
  },
  {
    text: 'Попугаи бывают очень красивых окрасов? ',
    answer: true
  },
  {
    text: 'Апельсиновый сок полезный? ',
    answer: true
  },
  {
    text: 'Бывает солнечное затмение? ',
    answer: true
  },
  {
    text: 'Бывает лунное затмение? ',
    answer: true
  },
  {
    text: 'Женщины любят украшения? ',
    answer: true
  },
  {
    text: 'В оврагах бывают туманы? ',
    answer: true
  },
  {
    text: 'Из колодца достают воду ведром? ',
    answer: true
  },
  {
    text: 'Когда Солнце всходит на небе,то становится светло? ',
    answer: true
  },
  {
    text: 'Есть 4 части света: север, юг, запад, восток? ',
    answer: true
  },
  {
    text: 'Вороны иногда нападают на людей? ',
    answer: true
  },
  {
    text: 'Галоши – это обувь? ',
    answer: true
  },
  {
    text: 'Облака бывают перистые, кучевые? ',
    answer: true
  },
  {
    text: 'Железная дорога – это опасная зона? ',
    answer: true
  },
  {
    text: 'Поезд двигается по рельсам? ',
    answer: true
  },
  {
    text: 'Поезд делает остановки на станциях? ',
    answer: true
  },
  {
    text: 'Люди, вошедшие в транспорт, называются «зайцами»? ',
    answer: false
  },
  {
    text: 'Человек, дающий в автобусе пассажирам билеты на проезд, называется кондуктор? ',
    answer: true
  },
  {
    text: 'Воспитанный человек часто говорит слова «спасибо» и «пожалуйста»? ',
    answer: true
  },
  {
    text: 'В транспорте мужчины уступают место женщинам и детям? ',
    answer: true
  },
  {
    text: 'Белка умеет прыгать с ветки на ветку? ',
    answer: true
  },
  {
    text: 'Белка умеет по дереву бегать вниз головой? ',
    answer: true
  },
  {
    text: 'Медведи умеют лазить по деревьям? ',
    answer: true
  },
  {
    text: 'Медведи умеют ловить рыбу? ',
    answer: true
  },
  {
    text: 'Медведи умеют прыгать с ветки на ветку? ',
    answer: false
  },
  {
    text: 'Осы выедают спелый виноград, оставив только шкурку? ',
    answer: true
  },
  {
    text: 'Осы любят мясо? ',
    answer: true
  },
  {
    text: 'Место укуса осы надо смазать соком луковицы? ',
    answer: true
  },
  {
    text: 'Колорадский жук любит поедать зелень картошки и баклажан? ',
    answer: true
  },
  {
    text: 'У сушки и баранки в центре всегда дырка? ',
    answer: true
  },
  {
    text: 'В зеленых шишках сосны много полезных веществ? ',
    answer: true
  },
  {
    text: 'Кровь бывает жидкая и густая? ',
    answer: true
  },
  {
    text: 'Донор – это человек, отдающий свою кровь для больных людей? ',
    answer: true
  },
  {
    text: 'Есть растущие камни? ',
    answer: true
  },
  {
    text: 'Если шарик заполнить гелием (газом), то он взлетит? ',
    answer: true
  },
  {
    text: 'Пастух – это тот, кто пасет коз, коров, баранов? ',
    answer: true
  },
  {
    text: 'Пастух – это тот, кого пасут козы? ',
    answer: false
  },
  {
    text: 'Раскрытый над человеком зонтик защищает от дождя? ',
    answer: true
  },
  {
    text: 'На мокром полу можно поскользнуться и упасть? ',
    answer: true
  },
  {
    text: 'Кукушка подкладывает свои яйца в чужие гнезда? ',
    answer: true
  },
  {
    text: 'Мох растет на северной стороне дерева? ',
    answer: true
  },
  {
    text: 'Гусь сухим из воды выходит? ',
    answer: true
  },
  {
    text: 'Двадцать – это три десятка? ',
    answer: false
  },
  {
    text: 'Тридцать – это три десятка? ',
    answer: true
  },
  {
    text: 'Два прибавить два – получится два? ',
    answer: false
  },
  {
    text: 'Из бокала пьют суп? ',
    answer: false
  },
  {
    text: 'Рыбы плавают в воде? ',
    answer: true
  },
  {
    text: 'Воланчик нужен для игры в бадминтон? ',
    answer: true
  },
  {
    text: 'Шашки бывают черные и белые? ',
    answer: true
  },
  {
    text: 'Игра в шахматы тренирует мозг? ',
    answer: true
  },
  {
    text: 'Конь в шахматах ходит буквой «Г»? ',
    answer: true
  },
  {
    text: 'У коровы один хвост? ',
    answer: true
  },
  {
    text: 'Конь бегает галопом? ',
    answer: true
  },
  {
    text: 'Слон любит купаться? ',
    answer: true
  },
  {
    text: 'В небе летают слоны? ',
    answer: false
  },
  {
    text: 'В лесу живут птицы? ',
    answer: true
  },
  {
    text: 'Некоторые попугаи могут говорить? ',
    answer: true
  },
  {
    text: 'Ножом режут хлеб? ',
    answer: true
  },
  {
    text: 'Дельфин боится воды? ',
    answer: false
  },
  {
    text: 'Луна – это спутник Земли? ',
    answer: true
  },
  {
    text: 'Кошки умеют мяукать? ',
    answer: true
  },
  {
    text: 'Собаки умеют лаять? ',
    answer: true
  },
  {
    text: 'Кошки умеют хрюкать? ',
    answer: false
  },
  {
    text: 'Понедельник – это первый день недели? ',
    answer: true
  },
  {
    text: 'С горки зимой катаются на санках? ',
    answer: true
  },
  {
    text: 'У треугольника пять углов? ',
    answer: false
  },
  {
    text: 'У четырехугольника десять сторон? ',
    answer: false
  },
  {
    text: 'Градусником измеряют вес? ',
    answer: false
  },
  {
    text: 'Градусником измеряют температуру? ',
    answer: true
  },
  {
    text: 'Одна тысяча – это два миллиона? ',
    answer: false
  },
  {
    text: 'Звуки [а], [о], [у], [ы] – это согласные? ',
    answer: false
  },
  {
    text: 'Буква «Б» – это последняя буква в алфавите? ',
    answer: false
  },
  {
    text: 'Курица летает? ',
    answer: false
  },
  {
    text: 'Компьютерный пароль можно взломать? ',
    answer: true
  },
  {
    text: 'Свиньи не могут смотреть в небо? ',
    answer: true
  },
  {
    text: 'Слон не может прыгать? ',
    answer: true
  },
  {
    text: 'Группа птиц называется стая? ',
    answer: true
  },
  {
    text: 'Группа рыб называется косяк? ',
    answer: true
  },
  {
    text: 'Группа коров называется стадо? ',
    answer: true
  },
  {
    text: 'Группа медуз называется смаком? ',
    answer: true
  },
  {
    text: 'Группа коров называется стая? ',
    answer: false
  },
  {
    text: 'Медуза на берегу испаряется? ',
    answer: true
  },
  {
    text: 'Лоси отлично плавают? ',
    answer: true
  },
  {
    text: 'Мед никогда не портится? ',
    answer: true
  },
  {
    text: '1+2-3+4 = 5? ',
    answer: false
  },
  {
    text: 'Тюльпан – это насекомое? ',
    answer: false
  },
  {
    text: 'Хризантема – это порода кошки? ',
    answer: false
  },
  {
    text: 'Метрами и литрами измеряют тело человека? ',
    answer: false
  },
  {
    text: 'Четверг и пятница – это время суток? ',
    answer: false
  },
  {
    text: 'Огурец и свекла – это фрукты? ',
    answer: false
  },
  {
    text: 'Карась и щука – это птицы? ',
    answer: false
  },
  {
    text: 'Смородина – это фрукт? ',
    answer: false
  },
  {
    text: 'Время года перед летом – осень? ',
    answer: false
  },
  {
    text: 'Время года перед зимой – весна? ',
    answer: false
  },
  {
    text: 'Время года после зимы – осень? ',
    answer: false
  },
  {
    text: 'Время года после лета – весна? ',
    answer: false
  },
  {
    text: 'Профессия Айболита – пират? ',
    answer: false
  },
  {
    text: 'В русском алфавите 28 букв? ',
    answer: false
  },
  {
    text: 'Изюм – это сушеный арбуз? ',
    answer: false
  },
  {
    text: 'Одежда для подушки – это пододеяльник? ',
    answer: false
  },
  {
    text: 'Площадка для бокса называется сцена? ',
    answer: false
  },
  {
    text: 'Баба Яга жила в зоопарке? ',
    answer: false
  },
  {
    text: 'Согласный звук можно петь? ',
    answer: false
  },
  {
    text: 'Марина – это сладкая ягода? ',
    answer: false
  },
  {
    text: 'Яйцо больше курицы? ',
    answer: false
  },
  {
    text: 'Уж ядовитый? ',
    answer: false
  },
  {
    text: 'Один больше двух? ',
    answer: false
  },
  {
    text: 'Умеет куковать петух? ',
    answer: false
  },
  {
    text: 'Есть два крыла у самолета? ',
    answer: true
  },
  {
    text: 'Опасно бегать по болоту? ',
    answer: true
  },
  {
    text: 'На дубе желуди растут? ',
    answer: true
  },
  {
    text: 'Зимой ландыши цветут? ',
    answer: false
  },
  {
    text: 'Блины пекут на сковородке? ',
    answer: true
  },
  {
    text: 'В лесу живут селедки? ',
    answer: false
  },
  {
    text: 'Ноль похож на букву «О»? ',
    answer: true
  },
  {
    text: 'Летать умеют обезьяны? ',
    answer: false
  },
  {
    text: 'Днем светит солнышко? ',
    answer: true
  },
  {
    text: 'Ночью светит солнышко? ',
    answer: false
  },
  {
    text: 'Утенок боится воды? ',
    answer: false
  },
  {
    text: 'Поросенок умеет хрюкать? ',
    answer: true
  },
  {
    text: 'Весь день в работе муравей? ',
    answer: true
  },
  {
    text: 'Зверюшек лечит Бармалей? ',
    answer: false
  },
  {
    text: 'Теплой водой умываются кошки? ',
    answer: false
  },
  {
    text: 'Для еды нужны вилки и ложки? ',
    answer: true
  },
  {
    text: 'Воробьи зимой улетают на юг? ',
    answer: false
  },
  {
    text: 'Красиво поют соловьи? ',
    answer: true
  },
  {
    text: 'Пилой забивают гвозди? ',
    answer: false
  },
  {
    text: 'Мячом играют в домино? ',
    answer: false
  },
  {
    text: 'У лягушки длинный хвост? ',
    answer: false
  },
  {
    text: 'Десять – это один десяток? ',
    answer: true
  },
  {
    text: 'Неделя начинается со вторника? ',
    answer: false
  },
  {
    text: 'У двух собак два хвоста? ',
    answer: true
  },
  {
    text: 'У рубашки три рукава? ',
    answer: false
  },
  {
    text: 'Ноль меньше трех? ',
    answer: true
  },
  {
    text: 'Неделя заканчивается субботой? ',
    answer: false
  },
  {
    text: 'У круга два угла? ',
    answer: false
  },
  {
    text: 'У большого льва пять лап? ',
    answer: false
  },
  {
    text: 'У маленького льва две лапы? ',
    answer: false
  },
  {
    text: 'У курицы два хвоста? ',
    answer: false
  },
  {
    text: 'Градусником измеряют длину? ',
    answer: false
  },
  {
    text: '«Я» – первая буква в алфавите? ',
    answer: false
  },
  {
    text: 'Имена людей пишутся с заглавной буквы? ',
    answer: true
  },
  {
    text: 'Год начинается в марте? ',
    answer: false
  },
  {
    text: 'Земля квадратная? ',
    answer: false
  },
  {
    text: 'У квадрата четыре угла? ',
    answer: true
  },
  {
    text: 'Весами измеряют температуру? ',
    answer: false
  },
  {
    text: 'Есть ли числа больше, чем тысяча? ',
    answer: true
  },
  {
    text: '«А» – последняя буква в алфавите? ',
    answer: false
  },
  {
    text: 'Звук [м] – гласный? ',
    answer: false
  },
  {
    text: 'Названия городов пишутся с большой буквы? ',
    answer: true
  },
  {
    text: 'Имена пишутся с большой буквы? ',
    answer: true
  },
  {
    text: 'Вода – это жидкость? ',
    answer: true
  },
  {
    text: 'В русском алфавите 33 буквы? ',
    answer: true
  }
];
