import React, { Fragment } from 'react';
import GiftRay from '~/components/GiftRay/GiftRay';
import cx from 'classnames';

import './giftRays.scss';

const GiftRays = () => {
  const renderRay = (num) => {
    return (
      <div
        key={ num }
        className={cx('gift-rays__item', {
          [`gift-rays__item_${num}`]: num
        })}>
        <GiftRay />
      </div>
    )
  }

  const renderPart = () => {
    return [...Array(4)].map((item, index) => renderRay(index + 1))
  }

  return (
    <Fragment>
      <div className="gift-rays gift-rays_1">
        {
          renderPart()
        }
      </div>

      <div className="gift-rays gift-rays_2">
        {
          renderPart()
        }
      </div>
    </Fragment>
  )
}

export default GiftRays;
