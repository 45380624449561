import React from 'react';
import avatar from './assets/avatar.png';

import './avatar.scss';

const Avatar = () => {
  return (
    <div className="avatar">
      <img className="avatar__img" alt="avatar" src={avatar} />
    </div>
  );
}

export default Avatar
