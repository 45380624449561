import getLevelParams from './services/getLevelParams';
import getLevels from '~/services/get-levels';

import content from './content';

const templateLevels = {
  1: { seconds: 10 },
  2: { seconds: 10 },
  3: { seconds: 10 },
  4: { seconds: 20 }
};

export default getLevels(templateLevels, getLevelParams, { name: 'content', data: content });
