export default {
  shapes: [ 1, 7, 6, 4, 5, 2, 3 ],
  offset: {
    mobile: 13
  },
  outline: [
    {
      desktop: { x: 303, y: 48 },
      tablet: { x: 302, y: 47 },
      mobile: { x: 213, y: 30 },
    },
    {
      desktop: { x: 258, y: 93 },
      tablet: { x: 262, y: 87 },
      mobile: { x: 183, y: 60 },
    },
    {
      desktop: { x: 303, y: 138 },
      tablet: { x: 302, y: 127 },
      mobile: { x: 213, y: 90 },
    },
    {
      desktop: { x: 213, y: 138 },
      tablet: { x: 222, y: 127 },
      mobile: { x: 152.5, y: 90 },
    },
    {
      desktop: { x: 303, y: 228 },
      tablet: { x: 302, y: 207 },
      mobile: { x: 213, y: 150 },
    },
    {
      desktop: { x: 213.3, y: 317.7 },
      tablet: { x: 222.5, y: 286.5 },
      mobile: { x: 153.5, y: 209.5 },
    },
    {
      desktop: { x: 193.7, y: 317.7 },
      tablet: { x: 204.5, y: 286.5 },
      mobile: { x: 140.5, y: 209.5 },
    },
    {
      desktop: { x: 149, y: 273 },
      tablet: { x: 165, y: 247 },
      mobile: { x: 111, y: 180 },
    },
    {
      desktop: { x: 104, y: 318 },
      tablet: { x: 125, y: 287 },
      mobile: { x: 81, y: 210 },
    },
    {
      desktop: { x: 149.5, y: 318 },
      tablet: { x: 165.4, y: 287 },
      mobile: { x: 111.6, y: 210 },
    },
    {
      desktop: { x: 213, y: 381.3 },
      tablet: { x: 221.6, y: 343.2 },
      mobile: { x: 153.4, y: 251.8 },
    },
    {
      desktop: { x: 276.3, y: 318 },
      tablet: { x: 277.7, y: 287 },
      mobile: { x: 195.3, y: 210 },
    },
    {
      desktop: { x: 304, y: 318 },
      tablet: { x: 302, y: 287 },
      mobile: { x: 213, y: 210 },
    },
    {
      desktop: { x: 348, y: 363 },
      tablet: { x: 342, y: 327 },
      mobile: { x: 243, y: 240 },
    },
    {
      desktop: { x: 393, y: 363 },
      tablet: { x: 382, y: 327 },
      mobile: { x: 273, y: 240 },
    },
    {
      desktop: { x: 438.2, y: 408 },
      tablet: { x: 422, y: 367 },
      mobile: { x: 303, y: 270 },
    },
    {
      desktop: { x: 483, y: 363 },
      tablet: { x: 462, y: 327 },
      mobile: { x: 333, y: 240 },
    },
    {
      desktop: { x: 439, y: 363 },
      tablet: { x: 422, y: 327 },
      mobile: { x: 303, y: 240 },
    },
    {
      desktop: { x: 303, y: 228 },
      tablet: { x: 302, y: 207 },
      mobile: { x: 213, y: 150 },
    },
    {
      desktop: { x: 393, y: 138 },
      tablet: { x: 382, y: 127 },
      mobile: { x: 273, y: 90 },
    },
    {
      desktop: { x: 303, y: 138 },
      tablet: { x: 302, y: 127 },
      mobile: { x: 213, y: 90 },
    },
    {
      desktop: { x: 348, y: 93 },
      tablet: { x: 342, y: 87 },
      mobile: { x: 243, y: 60 },
    },
  ],
  outlines: [
    {
      desktop: { x: 258, y: 48 },
      tablet: { x: 262, y: 47 },
      mobile: { x: 183, y: 30 },
      rotation: 0
    },
    {
      desktop: { x: 393, y: 138 },
      tablet: { x: 382, y: 127 },
      mobile: { x: 273, y: 90 },
      rotation: 90
    },
    {
      desktop: { x: 213, y: 318 },
      tablet: { x: 222, y: 287 },
      mobile: { x: 153, y: 210 },
      rotation: 270
    },
    {
      desktop: { x: 194, y: 273 },
      tablet: { x: 205, y: 247 },
      mobile: { x: 141, y: 180 },
      rotation: 90
    },
    {
      desktop: { x: 213, y: 254 },
      tablet: { x: 221, y: 230 },
      mobile: { x: 153, y: 167 },
      rotation: 45
    },
    {
      desktop: { x: 439, y: 318 },
      tablet: { x: 422, y: 287 },
      mobile: { x: 303, y: 210 },
      rotation: 90
    },
    {
      desktop: { x: 393, y: 408 },
      tablet: { x: 382, y: 367 },
      mobile: { x: 273, y: 270 },
      rotation: 270
    }
  ]
}
