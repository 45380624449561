import { createSelector } from 'reselect';
import * as entityNormalize from '~/utils/entityNormalize';

const trainersSelector = (state) => state.trainers;

const trainersFilterSelector = ({ trainers }) => {
  const filters = trainers.trainersFilter;

  if (!(trainers && filters)) {
    return [];
  }

  if (filters.all && filters.all.active) {
    return trainers;
  }

  let { data } = trainers;
  const activeFilters = Object.keys(filters).filter((property) => filters[property].active);
  const ids = Object.keys(data).reduce((acc, id) => {
    const { skills } = data[id].params;
    const isGood = skills.some((skillProperty) => activeFilters.some((filterProperty) => filterProperty === skillProperty));

    if (isGood) {
      acc.push(id);
    }

    return acc;
  }, []);

  return { ...trainers, ids };
};

export const trainersSelectorData = (state) => state.trainers.data;
export const getCurrentMission = (state) => state.trainers.currentMission;
export const getMissionInProfile = (state, { missionId }) => state.profile.missions[missionId];

export const getTrainersArray = createSelector(
  trainersSelector,
  (trainers) => {
    return entityNormalize.toArray(trainers.data, trainers.ids);
  }
);

export const getFilteredTrainersArray = createSelector(
  trainersFilterSelector,
  (trainers) => {
    return entityNormalize.toArray(trainers.data, trainers.ids);
  }
);

export const getTrainersBySkill = createSelector(
  getTrainersArray,
  trainersSelectorData,
  getMissionInProfile,
  (trainers, trainersData, missionInProfile) => {
    let result = {};

    trainers.forEach((item) => {
      if (!item.isLongMemory) {
        const { mainSkill, levels, levelsInMission } = item.params;
        const arrayLevels = Object.keys(levelsInMission || levels);
        const maxCurrentTrainerLevel = arrayLevels[arrayLevels.length - 1];

        // Создаем объект, разделяющий тренажеры по навыкам
        if (result[mainSkill]) {
          // Запоминаем для каждого навыка тренажеры, входящие в него и максимальный его возможный уровень
          const { items, maxLevel } = result[mainSkill];
          result[mainSkill].items = [ ...items, item.id ];

          if (maxLevel && maxCurrentTrainerLevel > maxLevel) {
            result[mainSkill].maxLevel = maxCurrentTrainerLevel;
          }
        }
        else {
          result[mainSkill] = {
            items: [ item.id ],
            maxLevel: maxCurrentTrainerLevel
          };
        }
      }
    });

    Object.keys(result).forEach((skill) => {
      let trainersByProfileLevel = {};

      if (missionInProfile) {
        const skillLevelInMission = missionInProfile.skillLevels[skill];
        // Определяем, есть ли тренажеры, которые удовлетворяют текущему уровню навыка из текущей миссии
        trainersByProfileLevel = result[skill].items.filter((id) => {
          const { params } = trainersData[id];
          const { levels, levelsInMission } = params;
          const arrayLevels = Object.keys(levelsInMission || levels);
          const maxCurrentTrainerLevel = arrayLevels[arrayLevels.length - 1];

          return maxCurrentTrainerLevel >= skillLevelInMission;
        });
      }
      else {
        // Определяем, есть ли тренажеры, которые удовлетворяют текущему уровню навыка из профиля
        trainersByProfileLevel = result[skill].items.filter((id) => {
          const { params } = trainersData[id];
          const { difficultyLevel, levels, levelsInMission } = params;
          const arrayLevels = Object.keys(levelsInMission || levels);
          const maxCurrentTrainerLevel = arrayLevels[arrayLevels.length - 1];

          return maxCurrentTrainerLevel >= difficultyLevel;
        });
      }

      // Не найдя таких, выявляем самые сложные в текущей навыковой группе
      if (!trainersByProfileLevel.length) {
        trainersByProfileLevel = result[skill].items.filter((id) => {
          const { maxLevel } = result[skill];
          const { params } = trainersData[id];
          const { levels, levelsInMission } = params;
          const arrayLevels = Object.keys(levelsInMission || levels);
          const maxCurrentTrainerLevel = arrayLevels[arrayLevels.length - 1];

          return maxCurrentTrainerLevel >= maxLevel;
        });
      }

      result[skill] = trainersByProfileLevel;
    });

    return result;
  }
);
