import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';

import Icon from '~/components/Icon/Icon';
import Skill from '~/components/Skill/Skill';
import TooltipButton from '../TooltipButton/TooltipButton';

import * as pageActions from '~/store/page/actions';

import skillConfig from '~/shared/skillConfig';
import popupNames from '~/services/popup-names';

/* eslint-disable */
import sideForm from '~/static/icon/side-form.svg';
/* eslint-enable */

import './profile-skills.scss';

const ProfileSkills = (props) => {
  const { skills, skillLevelName, nextSkillLevelName, setPopup } = props;
  const toogleSkillsTooltip = () => setPopup(popupNames.profileSkills);
  const isBig = useMediaQuery({ minWidth: 686 });
  const isMiddle = useMediaQuery({ minWidth: 429, maxWidth: 685 });
  const isSmall = !isBig && !isMiddle;

  const nextTitle = () => (
    <div className="profile-skills__label profile-skills__next-label">
      следующий статус
    </div>
  )

  const nextStatus = () => (
    <div className="profile-skills__next-status profile-skills__status profile-skills__status">
      { nextSkillLevelName }
    </div>
  )

  const renderHeader = () => (
    <header className="profile-skills__header">
      <div className="profile-skills__labels">
        <div className="profile-skills__label profile-skills__current-label">
          текущий статус
        </div>
        { nextSkillLevelName && !isSmall && nextTitle() }
      </div>

      <div className="profile-skills__statuses">
        <div className="profile-skills__current-holder">
          <div className="profile-skills__current-status profile-skills__status">
            { skillLevelName }
          </div>

          <div className="profile-points__button">
            <TooltipButton
              iconName='show-tooltip'
              onClick={ toogleSkillsTooltip }
            />
          </div>
        </div>
        { nextSkillLevelName && !isSmall && nextStatus() }
      </div>
    </header>
  )

  const renderSideForms = (amount) => (
    amount.map(num => (
      <div
        className={ cx('profile-skills__form', {
          [`profile-skills__form_${num}`]: num
        }) }
        key={ num }
      >
        <Icon name="side-form" className="profile-skills__form-icon" />
      </div>
    ))
  )

  const renderSkill = (skill, type) => {
    const { value, max } = skill;
    const { gradientColors, names, colors } = skillConfig;

    return (
      <Skill
        className="profile-skills__item"
        classNameCircle="profile-skills__item-circle"
        classNameCircleHolder="profile-skills__item-holder"
        key={ type }
        name={ names[type] }
        value={ value }
        max={ max }
        startColor={ gradientColors[type][0] }
        endColor={ gradientColors[type][1] }
        type={ type }
        amountColor={ colors[type] }
      />
    )
  }

  const skillNames = Object.keys(skills);

  return (
    <div className="profile-skills">
      <div className="profile-skills__holder">
        { renderHeader() }

        <div className="profile-skills__list">
          { skillNames.map(skill => renderSkill(skills[skill], skill)) }
        </div>

        { isSmall && nextTitle() }
        { isSmall && nextStatus() }

        <p className="profile-skills__desc">
          Улучшай свои навыки в тренажерном зале или проходя миссии, чтобы заполнить все круги и получить новый статус!
        </p>
      </div>

      { renderSideForms([ 1, 2 ]) }
    </div>
  );
}

const mapDispatchToProps = {
  setPopup: pageActions.setPopup
};

export default connect(null, mapDispatchToProps)(ProfileSkills);
