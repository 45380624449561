import React from 'react';

import Icon from '~/components/Icon/Icon';

import detectDevice from '~/services/detect-device';

/* eslint-disable */
import logo from './assets/logo.svg';
import logoMobile from './assets/logoMobile.svg';
/* eslint-enable */

import './logo.scss';

const Logo = () => {
  const { isMobile } = detectDevice();
  const name = isMobile ? 'logoMobile' : 'logo';

  return <Icon className="logo" name={ name }/>;
}

export default Logo;
