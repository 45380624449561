import React from 'react';
import Icon from '~/components/Icon/Icon'

// eslint-disable-next-line
import ray from './assets/ray.svg';

const GiftRay = () => {
  return (
    <Icon name="ray" className="gift-ray" />
  )
}

export default GiftRay;
