import accuracy from './accuracy';
import anagram from './anagram';
import dynamicMemory from './dynamicMemory';
import funnyPoints from './funnyPoints';
import longMemory from './longMemory';
import memo from './memo';
import sudoku from './sudoku';
import tangram from './tangram';
import mnemonic from './mnemonic';
import intellectualWorkouts from './intellectualWorkouts';
import secretCode from './secretCode';
import findSpy from './findSpy';
import spatial from './spatial';
import numberSeries from './numberSeries';

export default [
  accuracy,
  anagram,
  dynamicMemory,
  funnyPoints,
  longMemory,
  memo,
  sudoku,
  tangram,
  mnemonic,
  intellectualWorkouts,
  secretCode,
  findSpy,
  spatial,
  numberSeries
];
