import React, { Component } from 'react';

import Zone from './Zone';
import Button from '~/components/Button/Button';
import Tabs from '~/components/Tabs/Tabs';
import TabNumber from './TabNumber';
import DynamicContent from '~/components/DynamicContent/DynamicContent';

import { ANSWER, stageName } from './constants';
import { DESKTOP } from './constants/screens';

import getContentStyle from '~/services/get-content-style';
import getRandomIndex from '~/services/get-random-index';
import generateField from './services/generate-field';
import generateNumbers from './services/generate-numbers';
import getScreenType from './services/getScreenType';

import './trainerSudoku.scss';

export default class TrainerSudoku extends Component {
  state = {
    // Текущее состояние поля
    currentField: [],
    // Размер стороны судоку
    size: 0,
    // Размер района судоку
    sizeZone: 0,
    // Числа, используемые в судоку
    numbers: [],
    // Режим записи в ячейку
    isEntry: true,
    // Индекс столбца выбранной ячеки
    currentCol: null,
    // Индекс строки выбранной ячеки
    currentRow: null,
    // Количество каждого числа в судоку
    countNumbers: null,
    // Конец игры
    isOver: false,
    // Размер экрана юзера
    screenType: DESKTOP,
    // Победил ли игрок
    isWin: false,
    // Ссылка для нижней панели
    panelRef: null,
    widthContent: 0,
    heightContent: 0
  }

  componentDidMount() {
    const { difficultyParams } = this.props.params;
    const { size } = difficultyParams;
    const sizeZone = Math.sqrt(size);
    const screenType = getScreenType();
    const numbers = generateNumbers(size);

    this.setState({ size, sizeZone, screenType, numbers });
  }

  componentDidUpdate(prevProps, prevState) {
    const { size, countNumbers, isOver, numbers } = this.state;
    const { isTimerActive, params, isRefresh, handleDataLayer } = this.props;
    const { difficultyParams } = params;
    const { patterns } = difficultyParams;

    // Сброс данных при проходе тренажера заново
    if (prevProps.isRefresh !== isRefresh) {
      const randomPattern = patterns[getRandomIndex(patterns)];
      const newField = generateNumbers(randomPattern, numbers);

      this.generateCountNumbers(newField);
      this.setState({ isEntry: true, currentField: newField, isOver: false });
    }

    // Генерация случайного поля
    if (prevState.size !== size) {
      const randomPattern = patterns[getRandomIndex(patterns)];
      const newField = generateField(randomPattern, numbers);

      this.generateCountNumbers(newField);
      this.setState({ currentField: newField });
    }

    // Проверка на максимальную сумму чисел в игре
    if (prevState.countNumbers !== countNumbers) {
      const maxSum = size * size;
      const currentSum = Object.values(countNumbers).reduce((sum, item) => Number.isNaN(item) ? sum : item + sum, 0);
      if (maxSum === currentSum) {
        handleDataLayer({ stageName, eventAction: 'next_auto' });
        this.setState({ isOver: true });
      }
    }

    // Проверка на окончание таймера
    if (prevProps.isTimerActive && !isTimerActive && !isOver) {
      handleDataLayer({ stageName, eventAction: 'next_force', event: 'timerTick' });
      this.setState({ isOver: true });
    }

    // Проверка на окончание игры
    if (!prevState.isOver && isOver) {
      this.checkVictory();
    }
  }

  // Клонирование матрицы
  cloneArr = (arr) => [ ...arr.map(item => [ ...item ]) ];

  // Генерация объекта количества каждого числа в судоку
  generateCountNumbers = (field) => {
    const { numbers } = this.state;
    let countNumbers = numbers.reduce((result, item) => ({ ...result, [item]: 0 }));

    field.forEach(row => {
      row.forEach(item => {
        if (typeof(item) === 'number') {
          if (countNumbers[item]) {
            countNumbers[item]++;
          }
          else {
            countNumbers[item] = 1;
          }
        }
      })
    })

    this.setState({ countNumbers });
  }

  // Проверка условия победы
  checkVictory = () => {
    const { currentField, size } = this.state;
    const sizeZone = Math.sqrt(size);
    const { difficultyParams } = this.props.params;
    const { winnerPoints } = difficultyParams;
    let reason = 'complete';
    let skills = winnerPoints;
    let isWin = true;

    // TODO: переделать это безобразие :)
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        const { value } = currentField[i][j];
        let rightValue = true;

        if (value) {
          for (let row = 0; row < size; row++) {
            if(j !== row && currentField[i][row] === value) {
              rightValue = false;
              break;
            }
          }

          if (rightValue) {
            for (let col = 0; col < size; col++) {
              if(i !== col && currentField[col][j] === value) {
                rightValue = false;
                break;
              }
            }
          }

          if (rightValue) {
            const startCol = Math.floor(i / sizeZone) * sizeZone;
            const startRow = Math.floor(j / sizeZone) * sizeZone;

            for (let col = startCol; col < startCol + sizeZone; col++) {
              for (let row = startRow; row < startRow + sizeZone; row++) {
                if(j !== row && i !== col && currentField[col][row] === value) {
                  rightValue = false;
                  break;
                }
              }
            }
          }

          currentField[i][j].rightValue = rightValue;
        }

        if ((rightValue && value === null) || !rightValue) {
          reason = 'fail';
          skills = null;
          isWin = false;
        }
      }
    }

    this.setState({ currentField, isWin });
    this.props.onFinishCb({ victory: Boolean(skills), reason, skills });
  }

  // Генерация индексов начала района по горизонтали и вертикали
  generateObjectForZones = () => {
    const { sizeZone } = this.state;
    const result = [];

    for (let i = 0; i < sizeZone; i++) {
      for (let j = 0; j < sizeZone; j++) {
        result.push({
          horizontalOffset: i,
          verticalOffset: j
        });
      }
    }

    return result;
  }

  // Вставка нового числа в ячейку или в заметку
  pasteNumber = (cell) => {
    const { value } = cell;
    const { countNumbers, size, currentField, currentRow, currentCol, isEntry } = this.state;
    const prevValue = currentField[currentRow][currentCol].value;
    const countValue = countNumbers[value] + 1;
    const countPrevValue = countNumbers[prevValue] - 1;
    let active;

    if (countValue >= size && isEntry) {
      active = false;
    }
    else if ((value && prevValue && isEntry) || (!isEntry && !value && prevValue)) {
      active = true;
    }

    const field = this.editOptions(cell, active);

    this.setState({ currentField: field });
    if (isEntry || (!isEntry && !value && prevValue)) {
      this.setState(() => ({
        countNumbers: {
          ...countNumbers,
          [value]: countValue,
          [prevValue]: countPrevValue
        }
      }));
    }
  }

  // Удаление числа из ячейки или заметки
  removeNumber = (cell) => {
    const { countNumbers, currentField, currentRow, currentCol, isEntry } = this.state;
    const prevValue = currentField[currentRow][currentCol].value;
    const countValue = countNumbers[prevValue] - 1;
    let active;

    if (isEntry) {
      active = true;
    }
    const field = this.editOptions(cell, active);

    this.setState({ currentField: field });
    if (isEntry) {
      this.setState(() => ({
        countNumbers: {
          ...countNumbers,
          [prevValue]: countValue
        }
      }));
    }
  }

  // Изменение опций ячейки в поле
  editOptions = (cell, active) => {
    const { value } = cell;
    const { currentField, currentCol, currentRow } = this.state;
    const field = this.cloneArr(currentField);
    const prevValue = field[currentRow][currentCol].value;
    const currentValue = active ? prevValue : value;
    const isBoolean = val => val === false || val === true || val instanceof Boolean;

    field[currentRow][currentCol] = cell;

    if (isBoolean(active)) {
      field.forEach(row => {
        row.forEach(item => {
          if (item.options) {
            item.options.forEach(option => {
              if (option.value === currentValue && !option.selected) {
                option.active = active;
              }
              if (value && prevValue && option.value === prevValue) {
                option.active = true;
              }
              if (!active && item.notes && value === option.value) {
                option.marked = false;
              }
            });
          }
          if(!active && item.notes) {
            item.notes.delete(value);
          }
        });
      });
    }

    return field;
  }

  // Отрисовка поля
  renderField = (sizeField) => {
    const { sizeZone, currentField, isOver, currentRow, currentCol, screenType } = this.state;
    const currentValue = currentRow !== null ? currentField[currentRow][currentCol].value : null;
    const setCurrentCell = ({ currentCol, currentRow }) => this.setState({ currentCol, currentRow });

    return this.generateObjectForZones().map((item, index) => {
      const { verticalOffset, horizontalOffset } = item;
      return (
        <Zone key={ index } sizeZone={ sizeZone } verticalOffset={ verticalOffset } currentCol={ currentCol }
              field={ currentField } isOver={ isOver } currentRow={ currentRow } screenType={ screenType }
              currentValue={ currentValue } setCurrentCell={ setCurrentCell } horizontalOffset={ horizontalOffset }
              sizeField={ sizeField }/>
      );
    })
  }

  // Отрисовка кнопки "Завершить задание"
  renderEndButton = () => (
    <Button className="trainer-sudoku__button" theme="blue" onClick={ () => this.setState({ isOver: true }) }>
      Завершить
    </Button>
  )

  createPanelRef = (panelRef) => {
    this.setState({ panelRef });
  }

  // Отрисовка таба с выбором чисел
  renderTabNumber = (isTabNumber) => {
    const { isEntry, currentField, currentCol, currentRow, screenType } = this.state;
    const switcherHandler = (isEntry) => this.setState({ isEntry });
    const style = { opacity: Number(isTabNumber) };
    const defaultCell = {
      value: null,
      notes: new Set(),
      options: []
    };
    const cell = isTabNumber ? currentField[currentRow][currentCol] : defaultCell;

    return (
      <div ref={ this.createPanelRef } style={ style } className="trainer-sudoku__tab-number">
        <TabNumber isEntry={ isEntry } screenType={ screenType } cell={ cell }
                   removeNumber={ this.removeNumber } pasteNumber={ this.pasteNumber } switcher={ switcherHandler } />
      </div>
    );
  }

  // Отрисовка таба с результатами
  renderTabResult = () => {
    return (
      <div className="trainer-sudoku__tabs">
        <Tabs onClick={ null } isCorrect={ true } selected={ ANSWER } />
      </div>
    );
  }

  setContentSize = ({ width, height }) => this.setState({ widthContent: width, heightContent: height });

  render() {
    const { currentCol, currentRow, isOver, isWin, panelRef, currentField, widthContent, heightContent } = this.state;
    const { widthWorkspace, heightWorkspace } = this.props;
    const style = getContentStyle({ padding: 0, widthContent, heightContent, aspectRatioContent: 1 });
    const isTabNumber = currentCol !== null && currentRow !== null && !isOver;
    const handleResize = (params) => {
      if (!widthContent || !heightContent) {
        this.setContentSize(params);
      }
    };

    return (
      <div className="trainer-sudoku">
        { isWin && this.renderTabResult() }
        <DynamicContent widthWorkspace={ widthWorkspace } heightWorkspace={ heightWorkspace }
                        setSize={ handleResize } refs={ [ panelRef ] }>
          <div className="trainer-sudoku__field" style={ style }>
            { this.renderField(Number(style.width.slice(0, -2))) }
          </div>
        </DynamicContent>
        { currentField.length && this.renderTabNumber(isTabNumber) }
        { !isOver && this.renderEndButton() }
      </div>
    )
  }
}
