import React from 'react';
import cx from 'classnames';

import Fail from '~/components/Fail/Fail';
import Ok from '~/components/Ok/Ok';

import { ANSWER } from '~/utils/constants';


import './test-step.scss';

const TestStep = (props) => {
  const { currentCardsOrder, correctCardsOrder, answerTab } = props;
  const cards = answerTab === ANSWER ? currentCardsOrder : correctCardsOrder;

  return (
    <div className="test-step__cards">
      { cards.map((cardsRow, index) => renderRow({ ...props, index, cardsRow })) }
    </div>
  );
};

const renderRow = (props) => {
  const { cardsRow, index, answerTab } = props;
  return (
    <div className="test-step__cards-row" key={ index }>
      { cardsRow.map((item, itemIndex) => renderCard({ ...props, item, hIndex: index, vIndex: itemIndex, answerTab })) }
    </div>
  );
};

const renderCard = (props) => {
  const { item, hIndex, vIndex, currentCardsOrder, isShowResult, correctCardsOrder, answerTab, isFinish,
          onCardDropHandler, onDragStartHandler, onCardClickHandler, onCardDragLeaveHandler,
          onCardDragOverHandler, matrixImages } = props;
  const isRight = currentCardsOrder[hIndex][vIndex] === correctCardsOrder[hIndex][vIndex];
  const isEmpty = item === -1;
  const isCorrect = isShowResult && isRight;
  const isIncorrect = isShowResult && !isRight;
  const classNameCard = cx('test-step__card', {
    'test-step__card_empty': isEmpty,
    'test-step__card_correct': isCorrect,
    'test-step__card_incorrect': isIncorrect,
    'test-step__card_check': answerTab !== ANSWER
  });
  const handlerDrop = !isFinish ? (event) => onCardDropHandler(event, hIndex, vIndex) : null;
  const handlerDrag = !isEmpty && !isFinish ? (event) => onDragStartHandler(event, hIndex, vIndex) : null;
  const handlerClick = !isFinish ? () => onCardClickHandler(hIndex, vIndex) : null;

  return (
    <div data-index={ hIndex } className={ classNameCard } key={ `${hIndex}_${vIndex}` }
         onDragOver={ onCardDragOverHandler } onDragLeave={ onCardDragLeaveHandler } onDrop={ handlerDrop }
         onClick={ handlerClick } onDragStart={ handlerDrag } draggable={ !isEmpty && !isFinish }>
      { isCorrect && answerTab === ANSWER && <Ok /> }
      { isIncorrect && answerTab === ANSWER && <Fail /> }
      {
        item === -1 ?
        <div className="test-step__empty" /> :
        <div className="test-step__image" style={ { backgroundImage: `url(${ matrixImages[item - 1] })` } }/>
      }
    </div>
  )
};

export default TestStep;
