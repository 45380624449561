export default {
  levelUp: 'level-up',
  statusUp: 'status-up',
  giveUp: 'give-up',
  gift: 'gift',
  locationAbout: 'location-about',
  profileLevel: 'profile-level',
  profileSkills: 'profile-skills',
  menu: 'menu'
};
