import texts from './texts';
import levels from './levels';

export default  {
  id: 25,
  type: 'numberSeries',
  eventCategory: 'NumbersLine',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Спецагенту важно уметь быстро просчитывать ситуацию на несколько шагов перед. Развивай навык мышления и будешь всегда находить выход из самых сложных ситуаций.',
  isCenteredContent: true,
  previewUrl: require('~/static/image/trainerPreviews/numberSeries.jpg'),
  params: {
    ...texts,
    name: 'По следам спецагента',
    hasTimer: true,
    startsImmediately: true,
    maxSkills:  {
      mind: 1,
      logic: 2
    },
    stageAmount: 8,
    skills: [ 'logic', 'mind' ],
    mainSkill: 'logic',
    isShowStage: true,
    levels
  }
};
