import React, { useEffect, useState } from 'react';

import getContentZone from './services/get-content-zone';

import './dynamic-content.scss';

const DynamicContent = (props) => {
  const { children, widthWorkspace, heightWorkspace, setSize, refs } = props;

  const [ widthContent, setWidthContent ] = useState(0);
  const [ heightContent, setHeightContent ] = useState(0);

  useEffect(() => {
    const { width, height } = getContentZone({ widthWorkspace, heightWorkspace }, refs);

    setWidthContent(width);
    setHeightContent(height);

    if (setSize && (!refs || refs.every((ref) => ref))) {
      setSize({ width, height });
    }
  }, [ widthWorkspace, refs ]);

  const getZoneStyle = () => ({ width: `${ widthContent }px`, height: `${ heightContent }px` });

  return (
    <div style={ getZoneStyle() } className="dynamic-content">
      { children }
    </div>
  );
};

export default DynamicContent;
