import React from 'react';

import PointsGot from '~/components/PointsGot/PointsGot'
import GiftGot from '~/components/GiftGot/GiftGot'
import TrainerSkillResult from '~/components/TrainerSkillResult/TrainerSkillResult'
import TrainerResultBox from '~/components/TrainerResultBox/TrainerResultBox'
import Advice from '~/components/Advice/Advice'

import skillConfig from '~/shared/skillConfig'
import propTypes from './propTypes'

/* eslint-disable */
import bubbleFocus from './assets/bubbleFocus.svg';
import bubbleMemory from './assets/bubbleMemory.svg';
import bubbleImagination from './assets/bubbleImagination.svg';
import bubbleLogic from './assets/bubbleLogic.svg';
import bubbleMind from './assets/bubbleMind.svg';

import bubbleFocusEye from './assets/bubbleFocusEye.svg';
import bubbleMemoryBrain from './assets/bubbleMemoryBrain.svg';
import bubbleImaginationShine from './assets/bubbleImaginationShine.svg';
import bubbleLogicPuzzle from './assets/bubbleLogicPuzzle.svg';
import bubbleMindLight from './assets/bubbleMindLight.svg';
/* eslint-enable */

import './resultPanel.scss';

const ResultPanel = (props) => {
  const { pointsGot, pointsTotal, pointsOf, gift, currentLevel, isMission,
          maxSkillsForMission, endMission, isWinMission, adviceSkills } = props;
  const isShowAdvice = endMission && Boolean(adviceSkills.length);

  const renderSkillBox = () => (
    <TrainerResultBox title="Развитие навыков">
      <div className="result-panel__develop">
        {
          Object.keys(pointsGot.skills).map((item, index) => {
            return (
              <TrainerSkillResult isMission={ endMission } key={ index } skill={ pointsGot.skills[item] }
                                  skillOf={ maxSkillsForMission && maxSkillsForMission[item] }
                                  total={ pointsTotal[item] } pointsOf={ pointsOf[item] }
                                  name={ skillConfig.names[item] } color={ skillConfig.colors[item] }
                                  iconName={ skillConfig.iconNames[item] } type={ item }
                                  iconNamePuzzle={ skillConfig.iconNamesPuzzle[item] }/>
            )
          })
        }
      </div>

      { isShowAdvice && <Advice skills={ adviceSkills }/> }
    </TrainerResultBox>
  );

  return (
    <div className="result-panel">
      <div className="result-panel__inner">
        { renderSkillBox() }
        {
          gift && isWinMission &&
          <TrainerResultBox isSmall={ true } title="Получен предмет">
            <div className="result-panel__gift-got">
              <GiftGot gift={ gift } isName={ true } withContainer={ true }/>
            </div>
          </TrainerResultBox>
        }
        {
          isMission &&
          <TrainerResultBox isSmall={ true } title="Получено баллов">
            <PointsGot points={ pointsGot.game } total={ pointsTotal.game } pointsOf={ pointsOf.game }
                       currentLevel={ currentLevel }/>
          </TrainerResultBox>
        }
      </div>
    </div>
  );
};

ResultPanel.propTypes = propTypes;

export default ResultPanel;
