import React from 'react';

import './footer.scss';

import Projects from '~/components/Projects/Projects'
import Logo from '~/components/Logo/Logo'
import Contacts from '~/components/Contacts/Contacts'
import Socials from '~/components/Socials/Socials'
import CreatedBy from '~/components/CreatedBy/CreatedBy'
import Confidential from '~/components/Confidential/Confidential'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__holder">
        <div className="footer__projects">
          <Projects isFooter={true} />
        </div>

        <div className="footer__group">
          <div className="footer__logo">
            <div className="footer__logo-holder">
              <Logo />
            </div>
          </div>

          <div className="footer__contacts">
            <Contacts isFooter={true} />
          </div>
        </div>

        <div className="footer__socials">
          <Socials isFooter={true} />
        </div>

        <div className="footer__line">
          <div className="footer__created-by">
            <CreatedBy isFooter={true} />
          </div>

          <div className="footer__confidential">
            <Confidential isFooter={true} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
