import React from 'react';
import Icon from '~/components/Icon/Icon'

import './swiperArrow.scss'

// eslint-disable-next-line
import swiperArrow from './assets/swiper-arrow.svg';

const SwiperArrow = (props) => {
  // const arrowStyle = {
  //   'fill': `${ props.color }`
  // }

  return (
    <Icon name="swiper-arrow" className="swiper-arrow" />
  )
}

export default SwiperArrow
