export default {
  image: require('~/static/trainers/funnyPoints/glasses.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -245, y: 649 },
    { id: 2,  x: -213, y: 686 },
    { id: 3,  x: -137, y: 688 },
    { id: 4,  x: -95,  y: 648 },
    { id: 5,  x: -61,  y: 605 },
    { id: 6,  x: -14,  y: 605 },
    { id: 7,  x: 21,   y: 648 },
    { id: 8,  x: 62,   y: 688 },
    { id: 9,  x: 138,  y: 686 },
    { id: 10, x: 178,  y: 652 }
  ]
}
