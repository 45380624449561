import PropTypes from 'prop-types';

export default {
  params: PropTypes.shape({
    difficultyParams: PropTypes.object,
    levels: PropTypes.objectOf(
      PropTypes.shape({
        correctOptionId: PropTypes.number,
        questions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            options: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.number,
                image: PropTypes.string,
              }),
            ),
          }),
        ),
        movesLimit: PropTypes.number,
      }),
    ),
    skillPoints: PropTypes.object,
  }).isRequired,
  isTimerActive: PropTypes.bool.isRequired,
  onStartCb: PropTypes.func.isRequired,
  onFinishCb: PropTypes.func.isRequired,
  onShowPopup: PropTypes.func.isRequired,
  longMemoryTaskData: PropTypes.shape({ }).isRequired
};
