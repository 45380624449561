import levels from './levels';
import texts from './texts';

export default {
  id: 8,
  type: 'funnyPoints',
  eventCategory: 'FunPoints',
  videoAnnounceUrl: null,
  tooltipAnnounce: 'Спецагент должен быстро планировать маршруты своего движения. Развивай этот навык – он тебе точно пригодится!',
  previewUrl: require('~/static/image/trainerPreviews/dots.png'),
  params: {
    ...texts,
    name: 'Весёлые точки',
    hasTimer: true,
    startsImmediately: true,
    notMaxWidth: true,
    maxSkills: {
      focus: 1,
      imagination: 2
    },
    skills: [ 'focus', 'imagination' ],
    mainSkill: 'imagination',
    levels
  }
}
