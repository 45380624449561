export default ({ timer, amount, cardsInLine }) => {
  const tooltipContent = `<b>1.</b> Рассмотри картинки и запомни их последовательность. Для этого соедини первую картинку
                          со второй, вторую с третьей, третью с четвертой и т. д. таким образом, чтобы получился смешной рассказ.
                          (Например: бабочка каталась на черепахе, черепаха съела малину, малина росла на носороге и т. д.).<br/>
                          <b>2.</b> Проговаривая рассказ, расположи картинки в том же порядке.`
  return {
    timer: timer.map((item) => item * 1000),
    amount,
    cardsInLine,
    tooltipContent,
    winnerPoints: {
      memory: 2,
      imagination: 1
    }
  };
};
