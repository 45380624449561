import PropTypes from 'prop-types';

export default {
  trainer: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      gift: PropTypes.shape({
        name: PropTypes.string,
      }),
      difficultyParams: PropTypes.object,
      levels: PropTypes.shape({}),
    }),
  }).isRequired,
  isPopupShow: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
