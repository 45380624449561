export default {
  image: require('~/static/trainers/funnyPoints/frog.png'),
  width: 800,
  height: 919,
  offsetX: 20,
  points: [
    { id: 1,  x: -313, y: 683 },
    { id: 2,  x: -274, y: 651 },
    { id: 3,  x: -310, y: 582 },
    { id: 4,  x: -309, y: 531 },
    { id: 5,  x: -272, y: 530 },
    { id: 6,  x: -272, y: 488 },
    { id: 7,  x: -261, y: 447 },
    { id: 8,  x: -245, y: 409 },
    { id: 9,  x: -223, y: 374 },
    { id: 10, x: -222, y: 310 },
    { id: 11, x: -191, y: 266 },
    { id: 12, x: -144, y: 271 },
    { id: 13, x: -114, y: 303 },
    { id: 14, x: -73,  y: 288 },
    { id: 15, x: -20,  y: 278 },
    { id: 16, x: 42,   y: 279 },
    { id: 17, x: 94,   y: 289 },
    { id: 18, x: 136,  y: 303 },
    { id: 19, x: 166,  y: 271 },
    { id: 20, x: 213,  y: 267 },
    { id: 21, x: 244,  y: 310 },
    { id: 22, x: 246,  y: 374 },
    { id: 23, x: 268,  y: 409 },
    { id: 24, x: 283,  y: 448 },
    { id: 25, x: 295,  y: 489 },
    { id: 26, x: 295,  y: 532 },
    { id: 27, x: 332,  y: 532 },
    { id: 28, x: 332,  y: 584 },
    { id: 29, x: 296,  y: 652 },
    { id: 30, x: 335,  y: 685 }
  ]
};
