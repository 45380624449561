export default (res, skills) => {
  const { list } = res;

  const result = list.map((item) => {
    const { mainSkill } = item.params;
    const { level } = skills[mainSkill];
    const resultItem = {
      ...item,
      params: {
        ...item.params,
        difficultyLevel: level
      }
    };

    return resultItem;
  });

  return { ...res, list: result };
};
