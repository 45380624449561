import { STEP_TEST } from '~/utils/constants';

export default (params) => {
  const { currentLevel, step, amountStages, isTimerActive, activeStage, isInMission, subStageAmount } = params;
  const missionSuffix = isInMission ? '_mission' : '';
  const end = `${ currentLevel }${ missionSuffix }`;
  const currentStage = subStageAmount ? Math.ceil(activeStage / subStageAmount) : activeStage;
  let eventLabel = `page3_check_lvl${ end }`;

  if (amountStages === 2) {
    if (step === STEP_TEST && isTimerActive) {
      eventLabel = `page2B_solve_lvl${ end }`;
    }

    if (step === STEP_TEST && !isTimerActive) {
      eventLabel = `page3A_check_lvl${ end }`;
    }

    if (step !== STEP_TEST) {
      eventLabel = `page2A_task_lvl${ end }`;
    }
  }

  if (amountStages === 1 && isTimerActive) {
    eventLabel = `page2_solve_lvl${ end }`;
  }

  if (amountStages > 2) {
    if (step === STEP_TEST && isTimerActive) {
      eventLabel = `page2B_solve${ currentStage }_lvl${ end }`;
    }

    if (step === STEP_TEST && !isTimerActive) {
      eventLabel = `page3A_check${ currentStage }_lvl${ end }`;
    }

    if (step !== STEP_TEST) {
      eventLabel = `page2A_task${ currentStage }_lvl${ end }`;
    }
  }

  return eventLabel;
};
