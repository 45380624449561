import React from 'react';

import './breadcrumbs.scss';

const Breadcrumbs = ({ missionName, locationName, trainerName }) => (
  <div className="breadcrumbs">
    {
      missionName &&
      <div className="breadcrumbs__mission">
        <span className="breadcrumbs__city">{ locationName } / </span>
        <mark className="breadcrumbs__name">Миссия: { missionName }</mark>
      </div>
    }
    {
      trainerName &&
      <div className="breadcrumbs__mission">
        <span className="breadcrumbs__city">{ trainerName } </span>
      </div>
    }
  </div>
)

export default Breadcrumbs;
