export const OUTLINE_COLOR = '#AFBDE6';
export const OUTLINE_ACTIVE_COLOR = '#4C62A0';
export const ROTATE_STROKE_COLOR = '#929AAA';
export const SHAPE_BORDER_COLOR = '#00A4EA';
export const SHAPE_SHADOW_COLOR = 'rgba(0, 164, 234, 0.8)';
export const WHITE_COLOR = '#FFF';
export const BORDER_WIN_COLOR = '#00C159';
export const BORDER_RIGHT_COLOR = '#A0A6B9';
export const BORDER_LOSE_COLOR = '#FF3509';
export const FIELD_SHADOW_COLOR = 'rgba(0, 38, 96, 0.11)';
