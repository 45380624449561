export default [
  { id: 1,  answerId: 2 },
  { id: 2,  answerId: 5 },
  { id: 3,  answerId: 2 },
  { id: 4,  answerId: 2 },
  { id: 5,  answerId: 3 },
  { id: 6,  answerId: 4 },
  { id: 7,  answerId: 1 },
  { id: 8,  answerId: 1 },
  { id: 9,  answerId: 2 },
  { id: 10, answerId: 3 },
  { id: 11, answerId: 3 },
  { id: 12, answerId: 2 },
  { id: 13, answerId: 2 },
  { id: 14, answerId: 3 },
  { id: 15, answerId: 5 },
  { id: 16, answerId: 2 },
  { id: 17, answerId: 1 },
  { id: 18, answerId: 2 },
  { id: 19, answerId: 4 },
  { id: 20, answerId: 5 },
  { id: 21, answerId: 4 },
  { id: 22, answerId: 5 },
  { id: 23, answerId: 2 },
  { id: 24, answerId: 3 },
  { id: 25, answerId: 3 },
  { id: 26, answerId: 5 },
  { id: 27, answerId: 3 },
  { id: 28, answerId: 5 },
  { id: 29, answerId: 5 },
  { id: 30, answerId: 1 },
  { id: 31, answerId: 5 },
  { id: 32, answerId: 4 },
  { id: 33, answerId: 3 },
  { id: 34, answerId: 1 },
  { id: 35, answerId: 4 },
  { id: 36, answerId: 2 },
  { id: 37, answerId: 4 },
  { id: 38, answerId: 2 },
  { id: 39, answerId: 5 },
  { id: 40, answerId: 5 },
  { id: 41, answerId: 4 },
  { id: 42, answerId: 5 },
  { id: 43, answerId: 2 },
  { id: 44, answerId: 4 },
  { id: 45, answerId: 5 },
  { id: 46, answerId: 1 },
  { id: 47, answerId: 1 },
  { id: 48, answerId: 1 },
  { id: 49, answerId: 5 },
  { id: 50, answerId: 1 },
  { id: 51, answerId: 3 },
  { id: 52, answerId: 5 },
  { id: 53, answerId: 4 },
  { id: 54, answerId: 2 },
  { id: 55, answerId: 3 },
  { id: 56, answerId: 4 },
  { id: 57, answerId: 3 },
  { id: 58, answerId: 5 },
  { id: 59, answerId: 2 },
  { id: 60, answerId: 2 },
  { id: 61, answerId: 2 },
  { id: 62, answerId: 5 },
  { id: 63, answerId: 1 },
  { id: 64, answerId: 4 },
  { id: 65, answerId: 3 },
  { id: 66, answerId: 3 },
  { id: 67, answerId: 2 },
  { id: 68, answerId: 4 },
  { id: 69, answerId: 1 },
  { id: 70, answerId: 2 },
  { id: 71, answerId: 5 },
  { id: 72, answerId: 3 },
  { id: 73, answerId: 1 },
  { id: 74, answerId: 2 },
  { id: 75, answerId: 3 },
  { id: 76, answerId: 1 },
  { id: 77, answerId: 2 },
  { id: 78, answerId: 2 },
  { id: 79, answerId: 4 },
  { id: 80, answerId: 3 },
  { id: 81, answerId: 5 },
  { id: 82, answerId: 1 },
  { id: 83, answerId: 2 },
  { id: 84, answerId: 5 },
  { id: 85, answerId: 2 },
  { id: 86, answerId: 3 },
  { id: 87, answerId: 3 },
  { id: 88, answerId: 5 },
  { id: 89, answerId: 2 },
  { id: 90, answerId: 4 },
  { id: 91, answerId: 4 },
  { id: 92, answerId: 4 },
  { id: 93, answerId: 5 },
  { id: 94, answerId: 2 },
  { id: 95, answerId: 4 },
  { id: 96, answerId: 5 },
  { id: 97, answerId: 1 },
  { id: 98, answerId: 4 },
  { id: 99, answerId: 5 },
  { id: 100, answerId: 4 },
  { id: 101, answerId: 2 },
  { id: 102, answerId: 5 },
  { id: 103, answerId: 1 },
  { id: 104, answerId: 5 },
  { id: 105, answerId: 3 },
  { id: 106, answerId: 4 },
  { id: 107, answerId: 4 },
  { id: 108, answerId: 5 }
];
